import { jsx as _jsx } from "react/jsx-runtime";
import { Box, FormControlLabel, Switch } from '@mui/material';
import { useEffect } from 'react';
import { USER_SUB_TYPES } from 'common/user.constants';
import useAppStore from '@/store/appStore';
import { useIsImpersonatedUser } from '@/hooks/useIsImpersonatedUser';
import { useUserInfo } from '@/hooks/useUserInfo';
export const EnableUpdateToggle = () => {
    var _a, _b;
    const { data } = useUserInfo();
    const isImpUser = useIsImpersonatedUser();
    const setState = useAppStore((s) => s.setState);
    const updatesEnabled = useAppStore((s) => s.updatesEnabled);
    const onChange = () => setState({ updatesEnabled: !updatesEnabled });
    useEffect(() => {
        var _a;
        if (isImpUser) {
            setState({
                updatesEnabled: ((_a = data === null || data === void 0 ? void 0 : data.fintaryAdmin) === null || _a === void 0 ? void 0 : _a.subType) === USER_SUB_TYPES.csm,
            });
        }
    }, [(_a = data === null || data === void 0 ? void 0 : data.fintaryAdmin) === null || _a === void 0 ? void 0 : _a.subType, isImpUser, setState]);
    if (!isImpUser)
        return null;
    if (((_b = data === null || data === void 0 ? void 0 : data.fintaryAdmin) === null || _b === void 0 ? void 0 : _b.subType) === USER_SUB_TYPES.csm)
        return null;
    return (_jsx(Box, { sx: { display: 'flex', justifyContent: 'center', mb: 0.25 }, children: _jsx(FormControlLabel, { control: _jsx(Switch, { onChange: onChange, checked: updatesEnabled, size: "small" }), label: "Enable updates" }) }));
};
