import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Typography, Grid, Divider, Paper } from '@mui/material';
import CriteriaActionsViewer from './CriteriaActionsViewer';
const DataUpdateGroupViewer = ({ dataUpdateData, fields, }) => {
    var _a;
    const dataEntity = ((_a = dataUpdateData[0]) === null || _a === void 0 ? void 0 : _a.data_entity) || '';
    return (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { m: 1 }, children: _jsxs(Typography, { variant: "body2", children: ["Data update for ", dataEntity] }) }), _jsxs(Box, { sx: { p: 2 }, children: [_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(Typography, { variant: "body2", children: "Criteria" }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(Typography, { variant: "body2", children: "Actions" }) })] }), _jsx(Divider, { sx: { mb: 1 } }), dataUpdateData.map((data) => (_jsx(Paper, { sx: { mb: 0.5, p: 0.5 }, children: _jsx(Grid, { container: true, spacing: 2, children: data.data_update_criteria.map((criterion, index) => (_jsxs(React.Fragment, { children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(CriteriaActionsViewer, { fieldData: criterion.data_update_criteria, rowData: { data_entity: dataEntity }, fields: fields }) }), _jsx(Grid, { item: true, xs: 6, children: data.data_update_actions[index] && (_jsx(CriteriaActionsViewer, { fieldData: data.data_update_actions[index].data_update_actions, rowData: { data_entity: dataEntity }, fields: fields })) })] }, criterion.id))) }) }, data.id)))] })] }));
};
export default DataUpdateGroupViewer;
