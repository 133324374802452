import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
const EmptyState = ({ button, description, image, padding = 2, size = 'medium', title, type = 'page', }) => {
    let imageWidth;
    let imageHeight;
    let variant;
    switch (size) {
        case 'small':
            imageWidth = 40;
            imageHeight = 40;
            variant = 'h6';
            break;
        case 'medium':
            imageWidth = 60;
            imageHeight = 60;
            variant = 'h6';
            break;
        case 'large':
            imageWidth = 100;
            imageHeight = 100;
            variant = 'h4';
            break;
        default:
            imageWidth = 60;
            imageHeight = 60;
            variant = 'h5';
            break;
    }
    if (type === 'page') {
        return (_jsxs(Box, { style: { transform: 'translate(-50%, -50%)' }, position: "absolute", top: "50%", left: "50%", textAlign: "center", children: [image && (_jsx(Box, { mb: title || description ? 2 : 0, width: `${imageWidth}%`, height: `${imageHeight}%`, children: image })), title && (_jsx(Box, { mb: !description && button ? 2 : 0.5, children: _jsx(Typography, { variant: variant, children: title }) })), description && (_jsx(Box, { mb: button && 3, children: _jsx(Typography, { variant: "body1", children: description }) })), button && button] }));
    }
    if (type === 'card') {
        return (_jsxs(Box, { padding: padding, textAlign: "center", children: [image && (_jsx(Box, { clone: true, mb: title || description ? 2 : 0, width: `${imageWidth}%`, height: `${imageHeight}%`, component: image.type, children: image })), title && (_jsx(Box, { mb: !description && button ? 2 : 0, children: _jsx(Typography, { variant: variant, children: title }) })), description && (_jsx(Box, { mb: button && 2, children: _jsx(Typography, { variant: "body1", children: description }) })), button && button] }));
    }
    return null;
};
EmptyState.propTypes = {
    type: PropTypes.string,
    size: PropTypes.string,
    padding: PropTypes.number,
    image: PropTypes.element,
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.element,
};
export default EmptyState;
