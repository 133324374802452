import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LaunchOutlined } from '@mui/icons-material';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material';
import Formatter from 'common/Formatter';
import React from 'react';
import { Link } from 'react-router-dom';
const CustomerPolicies = ({ data, field,
// Setter,
 }) => {
    var _a;
    const customerPolicies = (_a = data === null || data === void 0 ? void 0 : data.report_data) !== null && _a !== void 0 ? _a : [];
    console.log(customerPolicies);
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { variant: "subtitle1", children: "Policies" }), _jsx(TableContainer, { children: _jsxs(Table, { children: [customerPolicies.length > 0 && (_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Policy ID" }), _jsx(TableCell, { children: "Product name" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Effective date" }), _jsx(TableCell, {})] }) })), _jsx(TableBody, { children: customerPolicies.map((policy) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: policy.policy_id }), _jsx(TableCell, { children: policy.product_name }), _jsx(TableCell, { children: policy.policy_status }), _jsx(TableCell, { children: Formatter.date(policy.effective_date) }), _jsx(TableCell, { children: _jsx(IconButton, { component: Link, to: `/policies?id=${policy.str_id}`, target: "_blank", sx: {
                                                opacity: 0.5,
                                                '&:hover': { opacity: 1 },
                                                color: '#2196f3',
                                            }, children: _jsx(LaunchOutlined, {}) }) })] }, policy.str_id))) })] }) })] }));
};
export default CustomerPolicies;
