import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useMemo } from 'react';
export const GlobalSnackbarContext = createContext({
    globalSnackbarConfig: {
        open: false,
        message: '',
        severity: 'info',
        autoHideDuration: 8000,
    },
    setGlobalSnackbarConfig: (e) => { },
});
const GlobalSnackbarProvider = ({ children }) => {
    const [globalSnackbarConfig, setGlobalSnackbarConfig] = useState({
        open: false,
        message: '',
        severity: 'info',
        autoHideDuration: 8000,
    });
    const store = useMemo(() => {
        return {
            globalSnackbarConfig,
            setGlobalSnackbarConfig,
        };
    }, [globalSnackbarConfig]);
    return (_jsx(GlobalSnackbarContext.Provider, { value: store, children: children }));
};
export default GlobalSnackbarProvider;
