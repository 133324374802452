import axios from 'axios';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import {
  ApiCustomerResponse,
  CreateCustomerFromPolicies,
  Customer,
} from 'common/types/customer';
import { Pagination } from 'common/types/pagination';

import { API_PATHS } from './path';

export const useCustomerNames = (params: Pagination) => {
  return useQuery<ApiCustomerResponse>({
    queryKey: [API_PATHS.report_data_customer_names, params],
    queryFn: async () => {
      return axios
        .get(API_PATHS.report_data_customer_names, {
          params,
        })
        .then((res) => res.data);
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });
};

export const useCreateCustomersFromPolicies = () => {
  return useMutation({
    mutationKey: [API_PATHS.customer_create_from_policies],
    mutationFn: (data: CreateCustomerFromPolicies[]) => {
      return axios.post(API_PATHS.customer_create_from_policies, data);
    },
  });
};

export const useSearchCustomerName = ({ q }: { q: string }) => {
  return useQuery<Customer[]>({
    queryKey: [API_PATHS.customer_search_name, { q }],
    queryFn: async () => {
      return axios
        .get(API_PATHS.customer_search_name, { params: { q } })
        .then((res) => res.data);
    },
    enabled: !!q,
  });
};
