export var DocumentTypes;
(function (DocumentTypes) {
    DocumentTypes["STATEMENT"] = "statement";
    DocumentTypes["REPORT"] = "report";
    DocumentTypes["COMPGRID"] = "compgrid";
})(DocumentTypes || (DocumentTypes = {}));
export const DocumentTypeLabels = {
    [DocumentTypes.STATEMENT]: 'Commission statement',
    [DocumentTypes.REPORT]: 'Policy report',
};
