import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close, DeleteOutline, GroupAdd, InfoOutlined, } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import API from '@/services/API';
import { LoadingContext } from '@/contexts/LoadingContext';
import useSnackbar from '@/contexts/useSnackbar';
const AgentsAdd = ({ open, setOpen }) => {
    const [agents, setAgents] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const { showSnackbar } = useSnackbar();
    const [agentHeadDef] = useState([
        {
            field: 'checkbox',
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            showDisabledCheckboxes: true,
            pinned: 'left',
            valueFormatter: () => '',
            width: 48,
            minWidth: 24,
            cellStyle: { padding: '0px 12px' },
        },
        {
            field: 'name',
            headerName: 'Agent Name',
            // Check if the value includes "," if so, reverse the order
            valueGetter: (params) => {
                const { first_name, last_name } = params.data;
                return first_name.includes(',')
                    ? `${last_name} ${first_name.replace(',', '')}`
                    : `${first_name} ${last_name}`;
            },
        },
        {
            field: 'first_name',
            headerName: 'First Name',
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
        },
        {
            field: 'agent_code',
            headerName: 'Agent Code',
        },
        {
            field: 'email',
            headerName: 'Email',
            editable: true,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            editable: true,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            editable: true,
        },
        {
            headerName: 'Actions',
            field: '',
            cellRenderer: (params) => {
                return (_jsx(IconButton, { onClick: () => {
                        const target = params.api.getRowNode(params.rowIndex);
                        const { str_id } = target.data;
                        setAgents((prev) => {
                            const result = [...prev];
                            const index = result.findIndex((row) => row.str_id === str_id);
                            result.splice(index, 1);
                            return result;
                        });
                    }, children: _jsx(DeleteOutline, {}) }));
            },
        },
    ]);
    const { setLoadingConfig } = useContext(LoadingContext);
    const reportDataRef = useRef(null);
    const [{ isLoading, data: reportData }] = API.getBasicQueryAll([
        'report_data/agents',
    ]);
    const contactsPoster = API.getMutation('report_data/agents', 'POST');
    useEffect(() => {
        return () => {
            setAgents([]);
            setSelectedAgents([]);
        };
    }, []);
    useEffect(() => {
        setLoadingConfig({
            loading: isLoading,
            message: 'Generating...',
        });
    }, [isLoading]);
    useEffect(() => {
        if (!isLoading && reportData.data) {
            // Filter out the data which is already generated to agents
            setAgents(reportData.data);
        }
    }, [isLoading, reportData]);
    const handleClose = () => {
        setAgents([]);
        setSelectedAgents([]);
        setOpen(false);
    };
    const handleOk = () => {
        setLoadingConfig({
            loading: true,
            message: 'Creating agents...',
        });
        const params = {
            agents: selectedAgents,
        };
        contactsPoster.mutate(params, {
            onSuccess: () => {
                setOpen({
                    open: false,
                    refresh: true,
                });
                setLoadingConfig({
                    loading: false,
                    message: '',
                });
            },
            onError: (error) => {
                showSnackbar(error, 'error');
                setLoadingConfig({
                    loading: false,
                    message: '',
                });
            },
        });
    };
    // Select rows
    const onSelectionChanged = useCallback(() => {
        var _a;
        const selectedRows = ((_a = reportDataRef.current) === null || _a === void 0 ? void 0 : _a.api.getSelectedRows()) || [];
        setSelectedAgents(selectedRows);
    }, []);
    return (_jsxs(Dialog, { open: open, fullScreen: true, sx: { padding: '20px' }, children: [_jsx(DialogTitle, { children: _jsxs("header", { className: "flex justify-between items-center", children: [_jsxs(Box, { className: "flex justify-between items-center", children: [_jsx(GroupAdd, {}), _jsx(Box, { className: "pl-2", children: "Bulk add agents" })] }), _jsx(Box, { className: "group p-2 cursor-pointer hover:text-blue-600", onClick: handleClose, children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) })] }) }), _jsx(DialogContent, { dividers: true, children: _jsx(Box, { sx: {
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        gap: 2,
                        alignItems: 'center',
                    }, children: _jsxs(Box, { sx: {
                            height: '100%',
                            flex: 3,
                            display: 'flex',
                            flexDirection: 'column',
                        }, children: [_jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between', pb: 2 }, children: ["Agents from policy data", agents.length ? (_jsx(Tooltip, { title: "Double-click cell to edit", placement: "left", children: _jsx(InfoOutlined, { sx: { color: '#aaa' } }) })) : ('')] }), _jsx("div", { style: {
                                    width: '100%',
                                    height: '100%',
                                    border: '1px solid #eee',
                                    flex: 1,
                                }, className: "ag-theme-material", children: _jsx(AgGridReact, { headerHeight: 40, rowData: agents, ref: reportDataRef, columnDefs: agentHeadDef, rowSelection: 'multiple', suppressRowClickSelection: true, 
                                    // IsRowSelectable={(row) => !row.data.report_contacts}
                                    onSelectionChanged: onSelectionChanged, defaultColDef: {
                                        flex: 1,
                                        minWidth: 180,
                                        resizable: true,
                                    } }) })] }) }) }), _jsxs(DialogActions, { children: [_jsx(Button, { autoFocus: true, onClick: handleClose, children: "Cancel" }), _jsx(Button, { onClick: handleOk, variant: "contained", disabled: !selectedAgents.length, children: "Add agents" })] })] }));
};
export default AgentsAdd;
