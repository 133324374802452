import * as Sentry from '@sentry/react';

import firebase, { analytics, auth, firestore } from '@/firebase';
import { LOCAL_STORAGE_KEYS } from '@/constants/account';

interface Authentication {
  signUpWithEmailAddressAndPassword: (
    emailAddress: string,
    password: string
  ) => Promise<any>;
  signIn: (emailAddress: string, password: string) => Promise<any>;
  signInLinkToEmail: (emailAddress: string) => Promise<any>;
  signInWithEmailLink: (
    emailAddress: string,
    emailLink: string
  ) => Promise<any>;
  signInWithAuthProvider: (provider: any) => Promise<any>;
  signOut: () => Promise<any>;
  resetPassword: (emailAddress: string) => Promise<any>;
  sendEmailVerification: () => Promise<any>;
}
const authentication: Authentication = {
  signUpWithEmailAddressAndPassword: (emailAddress, password) => {
    return new Promise((resolve, reject) => {
      if (!emailAddress || !password) {
        reject(new Error('No e-mail address or password'));

        return;
      }

      if (auth.currentUser) {
        reject(new Error('No current user'));

        return;
      }

      auth
        .createUserWithEmailAndPassword(emailAddress, password)
        .then((value) => {
          const { user } = value;

          if (!user) {
            reject(new Error('No user'));

            return;
          }

          const { uid } = user;

          if (!uid) {
            reject(new Error('No UID'));

            return;
          }

          const userDocumentReference = firestore.collection('users').doc(uid);

          userDocumentReference
            .set({}, { merge: true })
            .then(() => {
              analytics.logEvent('sign_up', {
                method: 'password',
              });

              resolve(user);
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  signIn: (emailAddress, password) => {
    return new Promise((resolve, reject) => {
      if (!emailAddress || !password) {
        reject(new Error('No e-mail address or password'));

        return;
      }

      if (auth.currentUser) {
        reject(new Error('No current user'));

        return;
      }

      auth
        .signInWithEmailAndPassword(emailAddress, password)
        .then((value) => {
          const { user } = value;

          if (!user) {
            reject(new Error('No user'));

            return;
          }

          const { uid } = user;

          if (!uid) {
            reject(new Error('No UID'));

            return;
          }

          const userDocumentReference = firestore.collection('users').doc(uid);

          userDocumentReference
            .get({ source: 'server' })
            .then((value) => {
              if (value.exists) {
                analytics.logEvent('login', {
                  method: 'password',
                });

                resolve(user);
              } else {
                userDocumentReference
                  .set({}, { merge: true })
                  .then(() => {
                    analytics.logEvent('login', {
                      method: 'password',
                    });

                    resolve(user);
                  })
                  .catch((reason) => {
                    reject(reason);
                  });
              }
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  signInLinkToEmail: (emailAddress) => {
    return new Promise((resolve, reject) => {
      if (!emailAddress) {
        reject(new Error('No e-mail address'));

        return;
      }

      if (auth.currentUser) {
        reject(new Error('No current user'));

        return;
      }

      const url = process.env.REACT_APP_HOMEPAGE;

      if (typeof url === 'undefined') {
        Sentry.captureException(
          'REACT_APP_HOMEPAGE environment variable is undefined'
        );
        throw new Error('REACT_APP_HOMEPAGE environment variable is undefined');
      }

      const actionCodeSettings = {
        url: url,
        handleCodeInApp: true,
      };

      auth
        .sendSignInLinkToEmail(emailAddress, actionCodeSettings)
        .then((value) => {
          analytics.logEvent('send_sign_in_link_to_email');

          localStorage.setItem('emailAddress', emailAddress);

          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  signInWithEmailLink: (emailAddress, emailLink) => {
    return new Promise((resolve, reject) => {
      if (!emailAddress || !emailLink) {
        reject(new Error('No e-mail address or e-mail link'));

        return;
      }

      if (auth.currentUser) {
        reject(new Error('No current user'));

        return;
      }

      auth
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          analytics.logEvent('login', {
            method: 'email-link',
          });

          localStorage.removeItem('emailAddress');

          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  signInWithAuthProvider: (provider) => {
    return new Promise((resolve, reject) => {
      if (!provider) {
        reject(new Error('No provider'));

        return;
      }

      const authProvider = new firebase.auth.OAuthProvider(provider.id);
      const { scopes } = provider;

      if (scopes) {
        scopes.forEach((scope) => {
          authProvider.addScope(scope);
        });
      }

      if (auth.currentUser) {
        reject(new Error('No current user'));

        return;
      }

      auth
        .signInWithPopup(authProvider)
        .then((value) => {
          const { user } = value;

          if (!user) {
            reject(new Error('No user'));

            return;
          }

          const { uid } = user;

          if (!uid) {
            reject(new Error('No UID'));

            return;
          }

          const userDocumentReference = firestore.collection('users').doc(uid);

          userDocumentReference
            .get({ source: 'server' })
            .then((value) => {
              if (value.exists) {
                analytics.logEvent('login', {
                  method: provider.id,
                });

                resolve(user);
              } else {
                userDocumentReference
                  .set({}, { merge: true })
                  .then(() => {
                    analytics.logEvent('login', {
                      method: provider.id,
                    });

                    resolve(user);
                  })
                  .catch((reason) => {
                    reject(reason);
                  });
              }
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  signOut: () => {
    return new Promise((resolve, reject) => {
      // Check sso token in local storage
      const ssoToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ssoToken);
      if (ssoToken) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.ssoToken);
        analytics.logEvent('sign_out');
        resolve('sign out');
        return;
      }

      const { currentUser } = auth;

      if (!currentUser) {
        reject(new Error('No current user'));

        return;
      }

      auth
        .signOut()
        .then((value) => {
          analytics.logEvent('sign_out');

          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  resetPassword: (emailAddress) => {
    return new Promise((resolve, reject) => {
      if (!emailAddress) {
        reject(new Error('No e-mail address'));

        return;
      }

      if (auth.currentUser) {
        reject(new Error('No current user'));

        return;
      }

      auth
        .sendPasswordResetEmail(emailAddress)
        .then((value) => {
          analytics.logEvent('reset_password');

          resolve(value);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  sendEmailVerification: () => {
    return new Promise((resolve, reject) => {
      const { currentUser } = auth;

      if (!currentUser) {
        reject(new Error('No current user'));

        return;
      }

      currentUser
        .sendEmailVerification()
        .then((response) => {
          analytics.logEvent('send_email_verification');

          resolve(response);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
};

export default authentication;
