var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import validator from 'validator';
import DataView from '@/components/DataView';
import MultiSelect from '@/components/molecules/MultiSelect';
import UploadAccountLogo from '@/components/SettingsView/UploadAccountLogo';
import useUploadStorageFile from '@/contexts/useUploadStorageFile';
import { auth } from '@/firebase';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';
const userData = {
    label: 'User info',
    table: 'users',
    editable: false,
    validateData: (data) => validator.isEmail(data === null || data === void 0 ? void 0 : data.email),
    fields: [
        {
            id: 'email',
            label: 'Email',
            default: (_a = auth === null || auth === void 0 ? void 0 : auth.currentUser) === null || _a === void 0 ? void 0 : _a.email, // Null on load, set below
            readOnly: true,
            validator: (val) => validator.isEmail(val || ''),
        },
        {
            id: 'first_name',
            label: 'First name',
        },
        {
            id: 'last_name',
            label: 'Last name',
        },
        {
            id: 'phone',
            label: 'Phone',
            validator: (val) => validator.isMobilePhone(val || '', 'en-US') || val === '',
        },
    ],
};
const GeneralSettings = () => {
    var _a, _b;
    const impersonatedUser = JSON.parse(localStorage.getItem('customLoginUser') || '{}');
    const { data: csms, isFetched } = API.getBasicQuery('users/get_fintary_csms');
    const [accountLogoFile, setAccountLogoFile] = useState(null);
    const { selectedAccount } = useAccountStore();
    const { userRole } = useRoleStore();
    const { uploadFilesToStorage } = useUploadStorageFile();
    const accountData = useMemo(() => {
        return {
            label: 'Account info',
            table: `accounts?accId=${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId}`,
            editable: false,
            fields: [
                {
                    id: 'name',
                    label: 'Account name',
                },
                {
                    id: 'description',
                    label: 'Account description',
                },
                {
                    id: 'short_name',
                    label: 'Account identifier (unique short name)',
                },
                {
                    id: 'auto_share_commission_report',
                    label: 'Share commission report on approval',
                    type: 'boolean',
                    enabled: userRole === Roles.ACCOUNT_ADMIN,
                },
                {
                    id: 'auto_email_commission_report',
                    label: 'Email commission report to agent upon approval',
                    type: 'boolean',
                    enabled: userRole === Roles.ACCOUNT_ADMIN,
                },
                // We should consider create a new settings tab for this kind of account global settings
                {
                    id: 'only_paid_commissions_for_producers',
                    type: 'boolean',
                    label: 'Show only paid/approved commissions to producers',
                    enabled: userRole === Roles.ACCOUNT_ADMIN,
                },
                {
                    id: 'comp_grids_enabled',
                    type: 'boolean',
                    label: 'Enable comp grids',
                    enabled: userRole === Roles.ACCOUNT_ADMIN,
                },
                {
                    id: 'accounting_transactions_enabled',
                    type: 'boolean',
                    label: 'Enable accounting transactions',
                    enabled: userRole === Roles.ACCOUNT_ADMIN,
                },
                {
                    id: 'per_agent_payout_status',
                    type: 'boolean',
                    label: 'Enable individual agent payout status',
                    enabled: userRole === Roles.ACCOUNT_ADMIN,
                },
                {
                    id: 'white_label_mode',
                    label: 'Company logo',
                    type: 'boolean',
                    enabled: userRole === Roles.ACCOUNT_ADMIN,
                },
                {
                    id: 'logo_url',
                    label: 'Account logo',
                    type: 'custom',
                    render: (field, row, setter) => (_jsx(UploadAccountLogo, { onChange: (logo_url, file) => {
                            setAccountLogoFile(file);
                            setter(Object.assign(Object.assign({}, row), { logo_url }));
                        }, data: row }, "logo_url")),
                    enabled: userRole === Roles.ACCOUNT_ADMIN,
                },
                { id: 'divider', type: 'divider', access: 'admin', label: 'Admin' },
                {
                    id: 'str_id',
                    label: 'Account ID',
                    access: 'admin',
                    readOnly: true,
                },
                {
                    id: 'mode',
                    label: 'Mode',
                    type: 'select',
                    options: ['default', 'insurance'],
                    access: 'admin',
                },
                {
                    id: 'type',
                    label: 'Type',
                    access: 'admin',
                    type: 'select',
                    options: [
                        { label: '🧪 Test', id: 'fintary' },
                        { label: '👪 Life', id: 'life' },
                        { label: '🧑‍⚕️ Health', id: 'health' },
                        { label: '🏡 P&C', id: 'p&c' },
                    ],
                },
                {
                    id: 'csms',
                    label: 'CSM(s)',
                    access: 'admin',
                    type: 'custom',
                    render: (field, row, setter) => (_jsx(MultiSelect, { label: "CSM(s)", values: csms, selectedValues: row.csms ? Object.values(row.csms) : [], setSelectedValues: (newValues) => setter(Object.assign(Object.assign({}, row), { csms: newValues })), formatter: Formatter.contact, valuer: (csms) => JSON.stringify(csms), enableSearch: false, paginate: false, sx: {
                            width: '100%',
                            '.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                            },
                            '.MuiSelect-select': {
                                color: 'black',
                            },
                            '.MuiSvgIcon-root': {
                                fill: 'grey !important',
                            },
                            '.MuiInputLabel-root': {
                                color: 'grey',
                            },
                        } }, "csms")),
                },
                {
                    id: 'status',
                    label: 'Status',
                    access: 'admin',
                    type: 'select',
                    options: [
                        { label: '✅ Active', id: 'active' },
                        { label: '⏳ Onboarding', id: 'onboarding' },
                        { label: '👀 Demo', id: 'demo' },
                        { label: '❌ Closed', id: 'closed' },
                    ],
                },
            ].filter((field) => field.enabled !== false),
        };
    }, [selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId, userRole, csms]);
    userData.fields.filter((field) => field.id === 'email')[0].default =
        (_a = impersonatedUser === null || impersonatedUser === void 0 ? void 0 : impersonatedUser.email) !== null && _a !== void 0 ? _a : (_b = auth === null || auth === void 0 ? void 0 : auth.currentUser) === null || _b === void 0 ? void 0 : _b.email;
    const dataCallback = (accountData) => __awaiter(void 0, void 0, void 0, function* () {
        if (accountData.logo_url && accountLogoFile) {
            try {
                yield uploadFilesToStorage(accountData.storage_logo_url, accountLogoFile);
                setAccountLogoFile(null);
            }
            catch (error) {
                console.log('Error uploading file', error);
            }
        }
    });
    return (_jsxs(Box, { children: [_jsx(Box, { sx: { height: '300px' }, children: _jsx(DataView, { dataDesc: userData, formModeOnly: true, embed: true }) }), _jsx(Box, { sx: { height: '300px' }, children: _jsx(DataView, { dataDesc: accountData, formModeOnly: true, embed: true, dataCallback: dataCallback, readOnly: userRole !== Roles.ACCOUNT_ADMIN }) })] }));
};
export default GeneralSettings;
