import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton } from '@mui/material';
import DataView from '@/components/DataView';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';
const View = () => {
    const { userRole } = useRoleStore();
    const { data: compGrids, isLoading: isLoadingCompGrids } = API.getBasicQuery('comp-grids', 'is_dynamic_select=true');
    const dataDesc = {
        label: 'Comp grid products',
        table: 'comp-grids/products',
        editable: true,
        copyable: true,
        bulkAdd: true,
        fields: [
            {
                id: 'comp_grid_id',
                label: 'Comp grid',
                type: 'dynamic-select',
                table: 'comp-grids',
                field: 'id',
                formatter: Formatter.getDynamicSelectFormatter(),
                optionFormatter: (o) => o === null || o === void 0 ? void 0 : o.name,
                optionValuer: (o) => o.id,
                required: true,
                bulkAddSelect: true,
            },
            { id: 'name', label: 'Name' },
            { id: 'type', label: 'Type' },
            { id: 'notes', label: 'Notes' },
            {
                id: 'company_product_ids',
                label: 'Products',
                type: 'dynamic-select',
                multiple: true,
                table: 'companies/products',
                queryParamName: 'comp_grid_id',
                queryParamValue: 'comp_grid_id',
                field: 'id',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals)) {
                        if ((_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((item) => item.id === val)) === null || _a === void 0 ? void 0 : _a.product_name) {
                            const dynamicFormatter = Formatter.getDynamicSelectFormatter((o) => o === null || o === void 0 ? void 0 : o.product_name);
                            const result = dynamicFormatter(val, collectionVals);
                            return result;
                        }
                        else {
                            return Formatter.statusChip('Missing products', {
                                mapping: {
                                    ['Missing products']: 'yellow',
                                },
                            });
                        }
                    }
                    else {
                        return _jsx(Skeleton, {});
                    }
                },
                optionFormatter: (o) => `${o.company.company_name} - ${o === null || o === void 0 ? void 0 : o.product_name}`,
                optionValuer: (o) => o === null || o === void 0 ? void 0 : o.id,
                bulkAddSelect: true,
            },
            {
                id: 'created_at',
                label: 'Created',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
            },
            {
                id: 'updated_at',
                label: 'Last updated',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
            },
        ],
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            contains_products: {
                id: 'contains_products',
                label: 'Contains products',
                query: {
                    company_product_ids: 'contains_products',
                },
            },
            missing_products: {
                id: 'missing_products',
                label: 'Missing products',
                query: {
                    company_product_ids: 'missing_products',
                },
            },
        },
        filterConfigs: {
            comp_grid_id: { type: 'select', label: 'Comp grids', options: {} },
        },
    };
    if (Array.isArray(compGrids) && compGrids.length > 0) {
        compGrids.forEach((compGrid, i) => {
            dataDesc.filterConfigs.comp_grid_id.options[compGrid.id] = {
                id: compGrid.id,
                str_id: compGrid.str_id,
                label: compGrid.name,
                query: { comp_grid_id: compGrid.id },
            };
        });
    }
    const rolesWithEditAccess = [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST];
    return (_jsx(DataView, { headingOffset: 124, dataDesc: dataDesc, readOnly: !userRole || !rolesWithEditAccess.includes(userRole), viewOnly: !userRole || !rolesWithEditAccess.includes(userRole), enablePagination: true }));
};
export default View;
