import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, IconButton } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';
import Formatter from '@/services/Formatter';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
// Hardcoded only for account admin role, by now we don't have any customer who wants this page to be shown to producers or data specialists
const ACL = { [Roles.ACCOUNT_ADMIN]: true };
const createUrlWithParams = (baseUrl, paramName, paramValue) => {
    const availableQueryParams = new URLSearchParams(window.location.search);
    // Exclude agent related parameters
    availableQueryParams.delete('agent_group');
    availableQueryParams.delete('agent_type');
    const contactParam = `${paramName}=${paramValue}`;
    return `${baseUrl}?${contactParam}&${availableQueryParams.toString()}`;
};
const dataSpec = {
    label: 'Agent production and commissions',
    table: 'statement_data/by_agents',
    filters: {
        product_type: {
            label: 'Product type',
        },
        agent_group: {
            label: 'Agent group',
        },
        agent_type: {
            label: 'Agent type',
        },
    },
    fields: {
        agent_name: { id: 'agent_name', label: 'Agent', enabled: true },
        policies_count: {
            id: 'policies_count',
            label: 'Policies',
            type: 'number',
            enabled: true,
            visible: true,
            formatter: (val, row) => {
                const url = createUrlWithParams('/policies', 'contacts', row.agent_str_id);
                return (_jsxs("span", { children: [val, _jsx(IconButton, { href: url, sx: { opacity: 0, '&:hover': { opacity: 1 } }, children: _jsx(Launch, { sx: { height: 16, width: 16 } }) })] }));
            },
        },
        premiums: {
            id: 'premiums',
            label: 'Premiums',
            type: 'currency',
            enabled: true,
            visible: true,
            formatter: (val, row) => {
                const url = createUrlWithParams('/policies', 'contacts', row.agent_str_id);
                return (_jsxs("span", { children: [Formatter.currency(val), _jsx(IconButton, { href: url, sx: { opacity: 0, '&:hover': { opacity: 1 } }, children: _jsx(Launch, { sx: { height: 16, width: 16 } }) })] }));
            },
        },
        commissions: {
            id: 'commissions',
            label: 'Commissions',
            type: 'currency',
            enabled: true,
            formatter: (val, row) => {
                const url = createUrlWithParams('/commissions', 'contacts', row.agent_str_id);
                return (_jsxs("span", { children: [Formatter.currency(val), _jsx(IconButton, { href: url, sx: { opacity: 0, '&:hover': { opacity: 1 } }, children: _jsx(Launch, { sx: { height: 16, width: 16 } }) })] }));
            },
        },
    },
};
const CommissionsByAgent = () => {
    const { userRole } = useRoleStore();
    if (!userRole || !(userRole in ACL))
        return _jsx(Box, { children: "No access" });
    return (_jsx(EnhancedDataView, { dataSpec: dataSpec, rowKey: "agent_id", hideAdd: true, hideSelectedCount: true, enableMultiSelect: false, enableEdit: false }));
};
export default CommissionsByAgent;
