import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Add, RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, } from '@mui/material';
import { clone } from 'lodash-es';
import { useMemo, useState } from 'react';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import FieldMatcher from '@/components/molecules/FieldMatcher';
import API from '@/services/API';
import Statements from '@/services/Statements';
import Validator from '@/services/Validator';
import { isNill } from '@/services/tools';
import { useAccountStore } from '@/store';
import Formatter from '@/services/Formatter';
import DynamicSelect from '@/components/DataForm/DynamicSelect';
const emptyRow = {
    comp_grid_criteria_id: '',
    product_id: '',
    issue_age_min: '',
    issue_age_max: '',
    transaction_type: '',
    compensation_type: '',
    commission_rate: '',
    notes_filter: '',
    notes_filter_method: '',
    payer_level_name: '',
    payee_level_name: '',
    payer_level_rate: '',
    payee_level_rate: '',
    carrier_grid_level: '',
    carrier_grid_commission_rate: '',
    calculation_method: '',
    limit_to_received: false,
};
const AgentCommissionScheduleConfig = ({ fieldId, companyId, compGridId, payerLevelId, payeeLevelId, data: rowData, setter, readOnly = false, }) => {
    var _a, _b, _c, _d;
    const [criteriaAddMode, setCriteriaAddMode] = useState('single');
    const { selectedAccount } = useAccountStore();
    const { data: contactsData } = API.getBasicQuery('contacts');
    const { data: incentiveTiers } = API.getBasicQuery('schedules/agents/incentive_tiers', `company_id=${companyId !== null && companyId !== void 0 ? companyId : ''}`);
    const rowDataProductTypes = rowData === null || rowData === void 0 ? void 0 : rowData.product_type;
    const productTypesQuery = rowDataProductTypes
        ? rowDataProductTypes
            .map((type) => `&product_type=${encodeURIComponent(type)}`)
            .join('')
        : '';
    const { data: criteria } = API.getBasicQuery('comp-grids/criteria', `${companyId ? `&company_id=${companyId}` : ''}${compGridId ? `&comp_grid_id=${compGridId}` : ''}${productTypesQuery}&is_dynamic_select=true`);
    const { data: payerRates } = API.getBasicQuery('comp-grids/rates', `comp_grid_level_id=${payerLevelId !== null && payerLevelId !== void 0 ? payerLevelId : ''}&is_dynamic_select=true`);
    const { data: payeeRates } = API.getBasicQuery('comp-grids/rates', `comp_grid_level_id=${payeeLevelId !== null && payeeLevelId !== void 0 ? payeeLevelId : ''}&is_dynamic_select=true`);
    const isNewCompGrids = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.compGridsEnabled;
    const statements = new Statements('insurance');
    const statementFields = Object.values(statements.fields);
    // const incentiveTiers = [
    //   { name: 'Tier 1', min: 0, max: 100000, rate: 0.1 },
    //   { name: 'Tier 2', min: 100001, max: 200000, rate: 0.15 },
    //   { name: 'Tier 2', min: 100001, max: 200000, rate: 0.2 },
    // ];
    const calcMethods = useMemo(() => {
        var _a;
        return ({
            compGrid: {
                name: 'Pay commission to grid level',
                description: '',
                fields: isNewCompGrids
                    ? []
                    : [
                        {
                            id: 'commission_rate',
                            label: 'Commission rate',
                            tip: 'Commission rate for agent',
                            validator: Validator.commissionRate,
                            dataType: 'percentage',
                        },
                        {
                            id: 'receivable_rate',
                            label: 'Receivable rate',
                            tip: 'Receivable rate for agency',
                            validator: Validator.commissionRate,
                            dataType: 'percentage',
                        },
                    ],
            },
            compGridLevel: isNewCompGrids
                ? {
                    name: 'Share override to grid level',
                    description: 'Payout % is set to be the difference between override received and override set by carriers between levels in commission grids.',
                    fields: [],
                }
                : {
                    name: 'Share override to grid level',
                    description: 'Payout % is set to be the difference between override received and override set by carriers between levels in commission grids.',
                    fields: [
                        [
                            {
                                id: 'payer_level_name',
                                label: 'Carrier grid level',
                                dataType: 'text',
                            },
                            {
                                id: 'payer_level_rate',
                                label: 'Payer rate',
                                validator: Validator.commissionRate,
                                dataType: 'percentage',
                            },
                        ],
                        [
                            {
                                id: 'payee_level_name',
                                label: 'House payout grid level',
                                dataType: 'text',
                            },
                            {
                                id: 'payee_level_rate',
                                label: 'Payee rate',
                                validator: Validator.commissionRate,
                                dataType: 'percentage',
                            },
                        ],
                        {
                            id: 'carrier_grid_override_rate',
                            label: 'Carrier grid override rate',
                            validator: Validator.commissionRate,
                            dataType: 'percentage',
                            // ReadOnly: true,
                            // compute: (row) => +row.payer_level_rate - +row.payee_level_rate,
                        },
                    ],
                },
            payoutRate: {
                name: 'Pay a set rate',
                description: 'Payout rate is a fixed number or up to a fixed number. For example, brokerage pays 15% regardless of received override.',
                fields: [
                    {
                        id: 'payout_rate',
                        label: 'Payout rate',
                        tip: '',
                        validator: Validator.commissionRate,
                        dataType: 'percentage',
                    },
                    {
                        id: 'limit_to_received',
                        label: 'Up to received commission',
                        tip: '',
                        type: 'boolean',
                    },
                ],
            },
            payHouseRate: {
                name: 'Pay house rate',
                description: '',
                fields: [],
            },
            payOverrideUpToTotalRate: {
                name: 'Pay override up to total rate',
                description: '',
                fields: [],
            },
            keepRate: isNewCompGrids
                ? {
                    name: 'Keep a set rate',
                    description: 'Brokerage keeps a set rate and/or a percentage of received commission and pays out the remainder from received override.',
                    fields: [
                        {
                            id: 'basis',
                            label: 'Calculation basis',
                            type: 'select',
                            options: [
                                { id: 'commission_grid', name: 'Commission grid' },
                                { id: 'received_rate', name: 'Received rate' },
                            ],
                            validator: (v) => v,
                        },
                        // [
                        //   {
                        //     id: 'payer_level_rate',
                        //     label: 'Payer rate',
                        //     validator: Validator.commissionRate,
                        //     dataType: 'percentage',
                        //     readOnly: true,
                        //     condition: (row) => row.basis === 'commission_grid',
                        //   },
                        //   {
                        //     id: 'payee_level_rate',
                        //     label: 'Payee rate',
                        //     validator: Validator.commissionRate,
                        //     dataType: 'percentage',
                        //     readOnly: true,
                        //     condition: (row) => row.basis === 'commission_grid',
                        //   },
                        // ],
                        // {
                        //   id: 'carrier_grid_override_rate',
                        //   label: 'Carrier grid override rate',
                        //   validator: Validator.commissionRate,
                        //   dataType: 'percentage',
                        //   readOnly: true,
                        //   condition: (row) => row.basis === 'commission_grid',
                        //   compute: (row) => +row.payer_level_rate - +row.payee_level_rate,
                        // },
                        {
                            id: 'basis_divider',
                            field: _jsx(Divider, {}),
                            type: 'custom',
                            condition: (row) => row.basis === 'commission_grid',
                        },
                        {
                            id: 'keep_rate',
                            label: 'Flat rate',
                            validator: Validator.commissionRate,
                            dataType: 'percentage',
                        },
                        { id: 'operator', field: _jsx(Add, {}), type: 'custom' },
                        [
                            {
                                id: 'plus_rate',
                                label: 'Percentage',
                                validator: Validator.commissionRate,
                                dataType: 'percentage',
                            },
                            {
                                id: 'percent_method',
                                label: 'Method',
                                dataType: 'text',
                                type: 'select',
                                validator: (v) => v,
                                options: [
                                    { id: 'received', name: 'of received' },
                                    { id: 'remainder', name: 'of remainder' },
                                ],
                            },
                        ],
                    ],
                }
                : {
                    name: 'Keep a set rate',
                    description: 'Brokerage keeps a set rate and/or a percentage of received commission and pays out the remainder from received override.',
                    fields: [
                        {
                            id: 'basis',
                            label: 'Calculation basis',
                            type: 'select',
                            options: [
                                { id: 'commission_grid', name: 'Commission grid' },
                                { id: 'received_rate', name: 'Received rate' },
                            ],
                            validator: (v) => v,
                        },
                        [
                            {
                                id: 'payer_level_name',
                                label: 'Carrier grid level',
                                dataType: 'text',
                                condition: (row) => row.basis === 'commission_grid',
                            },
                            {
                                id: 'payer_level_rate',
                                label: 'Payer rate',
                                validator: Validator.commissionRate,
                                dataType: 'percentage',
                                condition: (row) => row.basis === 'commission_grid',
                            },
                        ],
                        [
                            {
                                id: 'payee_level_name',
                                label: 'House payout grid level',
                                dataType: 'text',
                                condition: (row) => row.basis === 'commission_grid',
                            },
                            {
                                id: 'payee_level_rate',
                                label: 'Payee rate',
                                validator: Validator.commissionRate,
                                dataType: 'percentage',
                                condition: (row) => row.basis === 'commission_grid',
                            },
                        ],
                        {
                            id: 'carrier_grid_override_rate',
                            label: 'Carrier grid override rate',
                            validator: Validator.commissionRate,
                            dataType: 'percentage',
                            condition: (row) => row.basis === 'commission_grid',
                            // ReadOnly: true,
                            // compute: (row) => +row.payer_level_rate - +row.payee_level_rate,
                        },
                        {
                            id: 'basis_divider',
                            field: _jsx(Divider, {}),
                            type: 'custom',
                            condition: (row) => row.basis === 'commission_grid',
                        },
                        {
                            id: 'keep_rate',
                            label: 'Flat rate',
                            validator: Validator.commissionRate,
                            dataType: 'percentage',
                        },
                        { id: 'operator', field: _jsx(Add, {}), type: 'custom' },
                        [
                            {
                                id: 'plus_rate',
                                label: 'Percentage',
                                validator: Validator.commissionRate,
                                dataType: 'percentage',
                            },
                            {
                                id: 'percent_method',
                                label: 'Method',
                                dataType: 'text',
                                type: 'select',
                                validator: (v) => v,
                                options: [
                                    { id: 'received', name: 'of received' },
                                    { id: 'remainder', name: 'of remainder' },
                                ],
                            },
                        ],
                    ],
                },
            overrideSplit: {
                name: 'Split percentage',
                description: 'Brokerage splits the override with agencies. For example, a 60/40 split means 60% of override is shared to downlines and the brokerage keeps 40%.',
                fields: [
                    {
                        id: 'split',
                        label: 'Split percentage',
                        validator: Validator.commissionRate,
                        dataType: 'percentage',
                    },
                ],
            },
            carrierGridSplitRemainder: {
                name: 'Pay to a grid level then split percentage',
                description: 'Brokerage pays producers up to level indicated in carrier grids then splits the rest of the override with agency.',
                fields: [
                    {
                        id: 'agent',
                        label: 'Agent',
                        type: 'select',
                        options: (_a = contactsData === null || contactsData === void 0 ? void 0 : contactsData.data) !== null && _a !== void 0 ? _a : [],
                    },
                    {
                        id: 'carrier_grid_level',
                        label: 'Carrier grid level',
                    },
                    {
                        id: 'split',
                        label: 'Split percentage',
                        validator: Validator.commissionRate,
                        dataType: 'percentage',
                    },
                    {
                        id: 'carrier_grid_override_rate',
                        label: 'Carrier grid override rate',
                        validator: Validator.commissionRate,
                        dataType: 'percentage',
                    },
                ],
            },
            payoutRateIncentiveTiers: {
                name: 'Pay based on incentive tiers',
                description: '',
                fields: incentiveTiers === null || incentiveTiers === void 0 ? void 0 : incentiveTiers.map((tier, i) => ({
                    id: tier.name,
                    label: `${tier.name} rate`,
                    dataType: 'percentage',
                })),
            },
        });
    }, [incentiveTiers, contactsData]);
    const { data: _productData, isLoading: isLoadingProducts, isFetched: isFetchedProducts, } = API.getBasicQuery('companies/products', companyId ? `company_id=${companyId}` : undefined);
    const productData = _productData === null || _productData === void 0 ? void 0 : _productData.data;
    const { data: productOptionData, isLoading: isLoadingProductOptions, isFetched: isFetchedProductOptions, } = API.getBasicQuery('companies/products/options', companyId ? `company_id=${companyId}` : undefined);
    const { data: compensationTypes, isLoading: isLoadingCompensationTypes, isFetched: isFetchedCompensationTypes, } = API.getBasicQuery('statement_data/compensation_type');
    const { data: statementTransactionTypes, isLoading: isLoadingStatementTransactionTypes, isFetched: isFetchedStatementTransactionTypes, } = API.getBasicQuery('statement_data/transaction_type');
    const { data: reportTransactionTypes, isLoading: isLoadingReportTransactionTypes, isFetched: isFetchedReportTransactionTypes, } = API.getBasicQuery('report_data/transaction_type');
    const transactionTypes = Array.from(new Set([
        ...((_a = statementTransactionTypes === null || statementTransactionTypes === void 0 ? void 0 : statementTransactionTypes.values) !== null && _a !== void 0 ? _a : []),
        ...((_b = reportTransactionTypes === null || reportTransactionTypes === void 0 ? void 0 : reportTransactionTypes.values) !== null && _b !== void 0 ? _b : []),
    ]));
    const data = !rowData[fieldId] ||
        (Array.isArray(rowData[fieldId]) && rowData[fieldId].length === 0)
        ? [clone(emptyRow)]
        : rowData[fieldId];
    data.forEach((datum) => {
        if (isNewCompGrids &&
            Array.isArray(payerRates === null || payerRates === void 0 ? void 0 : payerRates.data) &&
            Array.isArray(payeeRates === null || payeeRates === void 0 ? void 0 : payeeRates.data)) {
            const payerRate = payerRates.data.find((r) => { var _a; return r.comp_grid_criterion.id === ((_a = datum.comp_grid_criteria_id) === null || _a === void 0 ? void 0 : _a[0]); });
            if (payerRate) {
                datum.payer_level_rate = payerRate.rate;
            }
            const payeeRate = payeeRates.data.find((r) => { var _a; return r.comp_grid_criterion.id === ((_a = datum.comp_grid_criteria_id) === null || _a === void 0 ? void 0 : _a[0]); });
            if (payeeRate) {
                datum.payee_level_rate = payeeRate.rate;
            }
            if (payerRate && payeeRate) {
                datum.carrier_grid_override_rate = payerRate.rate - payeeRate.rate;
            }
        }
    });
    const unusedCriteria = Array.isArray(criteria === null || criteria === void 0 ? void 0 : criteria.data)
        ? ((_d = (_c = criteria === null || criteria === void 0 ? void 0 : criteria.data) === null || _c === void 0 ? void 0 : _c.filter((criterion) => !(data === null || data === void 0 ? void 0 : data.find((datum) => Array.isArray(datum.comp_grid_criteria_id)
            ? datum.comp_grid_criteria_id.includes(criterion.id)
            : datum.comp_grid_criteria_id === criterion.id)))) !== null && _d !== void 0 ? _d : [])
        : [];
    const getHandleChange = (index, subFieldId, opts = {
        isDynamicSelect: false,
        multiple: false,
        boolean: false,
    }) => (e) => {
        var _a, _b;
        const newDatums = [...data];
        const value = opts.isDynamicSelect
            ? e === null || e === void 0 ? void 0 : e[subFieldId]
            : ((_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : e);
        if (opts.multiple) {
            if (value.includes('')) {
                newDatums[index][subFieldId] = '';
            }
            else if (typeof value === 'string') {
                newDatums[index][subFieldId] = value.split(',');
            }
            else {
                newDatums[index][subFieldId] = value;
            }
        }
        else {
            newDatums[index][subFieldId] = opts.boolean ? e.target.checked : value;
        }
        if (['payee_level_rate', 'payer_level_rate'].includes(subFieldId) &&
            !isNill(newDatums[index].payee_level_rate) &&
            !isNill(newDatums[index].payer_level_rate) &&
            !Number.isNaN(+newDatums[index].payee_level_rate) &&
            !Number.isNaN(+newDatums[index].payer_level_rate)) {
            const newCarrierGridOverrideRate = +newDatums[index].payer_level_rate -
                +newDatums[index].payee_level_rate;
            newDatums[index].carrier_grid_override_rate =
                newCarrierGridOverrideRate;
        }
        setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newDatums }));
    };
    const getCalcFormField = (datum, i, field, payerRates, payeeRates, width = 270, readOnly = false) => {
        var _a, _b;
        return typeof field.condition === 'function' && !field.condition(datum) ? null : (_jsx(Tooltip, { title: field.tip, children: field.type === 'custom' ? (_jsx(Box, { sx: {
                    mt: 1,
                    width,
                    textAlign: 'center',
                }, children: field.field })) : field.type === 'boolean' ? (_jsx(Box, { sx: { mb: 1, ml: 1 }, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: datum[field.id], onChange: getHandleChange(i, field.id, {
                            multiple: false,
                            boolean: true,
                        }), readOnly: field.readOnly }), label: field.label, disabled: readOnly || field.readOnly }) }, field.id)) : field.type === 'select' ? (_jsxs(FormControl, { sx: { mr: 1, width, mt: 1 }, error: (_a = (typeof field.validator === 'function' &&
                    !field.validator(datum[field.id]))) !== null && _a !== void 0 ? _a : false, disabled: readOnly || field.readOnly, children: [_jsx(InputLabel, { id: `${field.id}-label`, children: field.label }), _jsxs(Select, { labelId: `${field.id}-label`, label: field.label, value: datum[field.id], onChange: getHandleChange(i, field.id), readOnly: field.readOnly, children: [_jsx(MenuItem, { value: '', children: "\u00A0" }), (_b = field.options) === null || _b === void 0 ? void 0 : _b.sort().map((v) => {
                                var _a;
                                return (_jsx(MenuItem, { value: v.id, children: (_a = v.name) !== null && _a !== void 0 ? _a : Formatter.contact(v, {
                                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                    }) }, v.id));
                            })] })] })) : (_jsx(TextField, { label: field.label, value: datum[field.id], onChange: getHandleChange(i, field.id), error: typeof field.validator === 'function' &&
                    !field.validator(datum[field.id], {
                        required: false,
                    }), sx: { mr: 1, width, mt: 1 }, InputProps: datum[field.id]
                    ? {
                        endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: field.dataType === 'percentage' ? '%' : '' })),
                    }
                    : {}, disabled: readOnly || field.readOnly })) }, field.id));
    };
    return isLoadingProducts ||
        isLoadingProductOptions ||
        isLoadingStatementTransactionTypes ||
        isLoadingReportTransactionTypes ||
        isLoadingCompensationTypes ? (_jsx(LoadingCircle, { size: 32 })) : isFetchedProducts &&
        isFetchedProductOptions &&
        isFetchedCompensationTypes &&
        isFetchedStatementTransactionTypes &&
        isFetchedReportTransactionTypes ? (_jsxs(Box, { sx: { mb: 1, width: '100%' }, children: [data === null || data === void 0 ? void 0 : data.map((datum, i) => {
                var _a, _b, _c, _d, _e, _f;
                return (_jsxs(Box, { sx: {
                        width: '100%',
                        mb: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                    }, children: [_jsx(Box, { sx: {
                                mt: 0.25,
                                pl: 1.5,
                                pt: 0.5,
                                pb: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                borderStyle: 'solid',
                                borderColor: 'silver',
                                borderWidth: 1,
                                borderRadius: 4,
                                width: '100%',
                                backgroundColor: '#2196f30a',
                            }, children: _jsxs(Box, { sx: {
                                    display: 'flex',
                                    mb: 1,
                                    justifyContent: 'space-between',
                                }, children: [_jsx(Box, { sx: { display: 'flex' }, children: _jsxs(Box, { sx: { minWidth: 575 }, children: [_jsx(Typography, { variant: "body2", children: "Match criteria" }), isNewCompGrids && Array.isArray(criteria === null || criteria === void 0 ? void 0 : criteria.data) && (_jsx(Box, { sx: { my: 1 }, children: _jsx(DynamicSelect, { field: {
                                                            id: 'comp_grid_criteria_id',
                                                            label: 'Comp grid criteria',
                                                            table: 'comp-grids/criteria',
                                                            multiple: true,
                                                            formatter: Formatter.getDynamicSelectFormatter(Formatter.compGridCriterion),
                                                            optionFormatter: Formatter.compGridCriterion,
                                                            optionValuer: (o) => o.id,
                                                            type: 'dynamic-select',
                                                        }, formattedData: datum, oldData: {}, setNewData: getHandleChange(i, 'comp_grid_criteria_id', { isDynamicSelect: true, multiple: true }), dynamicSelects: { 'comp-grids/criteria': criteria }, readOnly: readOnly, disabled: readOnly, fullWidth: true }) })), !isNewCompGrids && (_jsxs(Box, { children: [_jsxs(Box, { sx: { display: 'flex', mt: 0.5 }, children: [_jsx(Tooltip, { title: "Carrier product. If unspecified, filter does not apply.", placement: "left", children: _jsxs(FormControl, { sx: { mr: 1, width: 240 }, disabled: readOnly, children: [_jsx(InputLabel, { id: datum.id, children: "Product" }), _jsxs(Select, { label: "Product", labelId: datum.id, value: Array.isArray(datum.product_id)
                                                                                    ? datum.product_id
                                                                                    : [], onChange: getHandleChange(i, 'product_id', {
                                                                                    multiple: true,
                                                                                }), 
                                                                                // Sx={{ width: 260 }}
                                                                                multiple: true, children: [_jsx(MenuItem, { value: '', children: "\u00A0" }), productData === null || productData === void 0 ? void 0 : productData.map((product) => (_jsx(MenuItem, { value: product.id, children: product.product_name }, product.id)))] })] }) }), _jsx(Tooltip, { title: "Carrier product option. If unspecified, filter does not apply.", placement: "left", children: _jsxs(FormControl, { sx: { mr: 1, width: 240 }, disabled: readOnly, children: [_jsx(InputLabel, { id: datum.id, children: "Option" }), _jsxs(Select, { label: "Option", labelId: datum.id, value: Array.isArray(datum.product_option_id)
                                                                                    ? datum.product_option_id
                                                                                    : [], onChange: getHandleChange(i, 'product_option_id', {
                                                                                    multiple: true,
                                                                                }), 
                                                                                // Sx={{ width: 260 }}
                                                                                multiple: true, children: [_jsx(MenuItem, { value: '', children: "\u00A0" }), (_a = productOptionData === null || productOptionData === void 0 ? void 0 : productOptionData.data) === null || _a === void 0 ? void 0 : _a.map((product_option) => {
                                                                                        var _a;
                                                                                        return (_jsx(MenuItem, { value: product_option.id, children: `${(_a = product_option === null || product_option === void 0 ? void 0 : product_option.product) === null || _a === void 0 ? void 0 : _a.product_name} - ${product_option.name}` }, product_option.id));
                                                                                    })] })] }) })] }), _jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Tooltip, { title: "Minimum issue age, inclusive. If unspecified, filter does not apply.", children: _jsx(TextField, { label: "Min age", value: datum.issue_age_min, onChange: getHandleChange(i, 'issue_age_min'), error: !Validator.age(datum.issue_age_min, {
                                                                            required: false,
                                                                        }), sx: { width: 90 }, InputProps: {
                                                                            sx: {
                                                                                borderTopRightRadius: 0,
                                                                                borderBottomRightRadius: 0,
                                                                            },
                                                                        }, disabled: readOnly }) }), _jsx(Tooltip, { title: "Maximum issue age, inclusive. If unspecified, filter does not apply.", children: _jsx(TextField, { label: "Max age", value: datum.issue_age_max, onChange: getHandleChange(i, 'issue_age_max'), error: !Validator.age(datum.issue_age_max, {
                                                                            required: false,
                                                                        }), sx: { ml: '-1px', mr: 1, width: 90 }, InputProps: {
                                                                            sx: {
                                                                                borderTopLeftRadius: 0,
                                                                                borderBottomLeftRadius: 0,
                                                                            },
                                                                        }, disabled: readOnly }) }), _jsx(Tooltip, { title: "Transaction type in commission records. If unspecified, filter does not apply. '(Blank)' matches commission records with blank transaction type.", placement: "right", children: (transactionTypes === null || transactionTypes === void 0 ? void 0 : transactionTypes.length) > 0 ? (_jsxs(FormControl, { sx: { mr: 1 }, disabled: readOnly, children: [_jsx(InputLabel, { id: `${datum.id}-transactionType`, children: "Transaction type" }), _jsxs(Select, { label: "Transaction type", labelId: `${datum.id}-transactionType`, value: datum.transaction_type, onChange: getHandleChange(i, 'transaction_type'), sx: { minWidth: 160, width: 160 }, children: [_jsx(MenuItem, { value: '', children: "\u00A0" }), _jsx(MenuItem, { value: '(Blank)', children: "(Blank)" }), datum.transaction_type &&
                                                                                        !(transactionTypes === null || transactionTypes === void 0 ? void 0 : transactionTypes.includes(datum.transaction_type)) && (_jsx(MenuItem, { value: datum.transaction_type, children: _jsx(Tooltip, { title: "This value is not present in any commission records", placement: "right", children: _jsx("span", { children: `${datum.transaction_type}*` }) }) })), transactionTypes === null || transactionTypes === void 0 ? void 0 : transactionTypes.map((transactionType) => (_jsx(MenuItem, { value: transactionType, children: transactionType }, transactionType)))] })] })) : (_jsx(TextField, { label: "Transaction type", value: datum.transaction_type, onChange: getHandleChange(i, 'transaction_type'), sx: { mr: 1, width: 160 }, disabled: readOnly })) }), _jsx(Tooltip, { title: "Compensation type in commission records. If unspecified, filter does not apply. '(Blank)' matches commission records with blank compensation type.", placement: "right", children: ((_b = compensationTypes === null || compensationTypes === void 0 ? void 0 : compensationTypes.values) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (_jsxs(FormControl, { sx: { mr: 1 }, disabled: readOnly, children: [_jsx(InputLabel, { id: `${datum.id}-compensationTypes`, children: "Compensation type" }), _jsxs(Select, { label: "Compensation type  ", labelId: `${datum.id}-compensationTypes`, value: datum.compensation_type, onChange: getHandleChange(i, 'compensation_type'), sx: { minWidth: 160, width: 160 }, children: [_jsx(MenuItem, { value: '', children: "\u00A0" }), _jsx(MenuItem, { value: '(Blank)', children: "(Blank)" }), datum.compensation_type &&
                                                                                        !((_c = compensationTypes === null || compensationTypes === void 0 ? void 0 : compensationTypes.values) === null || _c === void 0 ? void 0 : _c.includes(datum.compensation_type)) && (_jsx(MenuItem, { value: datum.compensation_type, children: _jsx(Tooltip, { title: "This value is not present in any commission records", placement: "right", children: _jsx("span", { children: `${datum.compensation_type}*` }) }) })), (_d = compensationTypes === null || compensationTypes === void 0 ? void 0 : compensationTypes.values) === null || _d === void 0 ? void 0 : _d.map((compensationType) => (_jsx(MenuItem, { value: compensationType, children: compensationType }, compensationType)))] })] })) : (_jsx(TextField, { label: "Compensation type", value: datum.compensation_type, onChange: getHandleChange(i, 'compensation_type'), sx: { mr: 1, width: 160 }, disabled: readOnly })) })] })] })), !readOnly && (_jsx(Box, { sx: { mt: 1, maxWidth: 800 }, children: _jsx(FieldMatcher, { value: datum.match_criteria, setValue: (e) => {
                                                            getHandleChange(i, 'match_criteria')(e);
                                                        }, fields: statementFields, addLabel: "Add criteria" }) }))] }) }), _jsx(Divider, { orientation: "vertical", flexItem: true, sx: { ml: 1, mr: 2 } }), _jsx(Box, { sx: { minWidth: 280 }, children: _jsxs(Box, { children: [_jsx(Typography, { variant: "body2", children: "Payout calculation" }), _jsx(Box, { sx: { mt: 0.5, mr: 1 }, children: _jsx(Tooltip, { title: "", placement: "left", children: _jsxs(FormControl, { sx: { mr: 1, width: '100%' }, disabled: readOnly, children: [_jsx(InputLabel, { id: "calcMethodLabel", children: "Calculation method" }), _jsxs(Select, { label: "Calculation method", labelId: "calcMethodLabel", value: datum.calculation_method, onChange: getHandleChange(i, 'calculation_method'), children: [_jsx(MenuItem, { value: '', children: "\u00A0" }), Object.entries(calcMethods).map(([k, v]) => (_jsx(MenuItem, { value: k, children: _jsx(Tooltip, { placement: "right", title: v.description, children: _jsx("span", { children: v.name }) }, k) }, k)))] })] }) }) }), _jsx(Divider, { sx: { mt: 1, ml: 1, mr: 2 } }), _jsx(Box, { children: datum.calculation_method &&
                                                        ((_f = (_e = calcMethods[datum.calculation_method]) === null || _e === void 0 ? void 0 : _e.fields) === null || _f === void 0 ? void 0 : _f.map((field, fieldIndex) => Array.isArray(field) ? (_jsx(Box, { children: field
                                                                .map((subField) => getCalcFormField(datum, i, subField, payerRates, payeeRates, 130, readOnly))
                                                                .filter((v) => v) }, fieldIndex)) : (getCalcFormField(datum, i, field, payerRates, payeeRates, undefined, readOnly)))) })] }) })] }) }), _jsx(Box, { children: _jsx(IconButton, { onClick: () => {
                                    const newdatums = [...data];
                                    newdatums.splice(i, 1);
                                    setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                                }, disabled: readOnly, children: _jsx(RemoveCircleOutline, {}) }) })] }, i));
            }), _jsx(Button, { onClick: () => {
                    setter((prevState) => {
                        const updatedArray = prevState[fieldId]
                            ? [...prevState[fieldId], clone(emptyRow)]
                            : [clone(emptyRow)];
                        return Object.assign(Object.assign({}, prevState), { [fieldId]: updatedArray });
                    });
                }, children: "Add rule" }), companyId && unusedCriteria.length > 0 && (_jsxs(Box, { sx: { mt: 0.5 }, children: [_jsxs(Typography, { variant: "body2", children: ["\uD83D\uDCA1 Comp grid criteria without rules (", unusedCriteria.length, ")"] }), _jsxs(Box, { sx: { ml: 4 }, children: [_jsx(Typography, { variant: "caption", children: unusedCriteria.map((criterion) => (_jsx(Box, { children: Formatter.compGridCriterion(criterion) }))) }), _jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsxs(ToggleButtonGroup, { color: "primary", exclusive: true, sx: { height: 32, mr: 1 }, value: criteriaAddMode, onChange: (e, v) => setCriteriaAddMode(v), children: [_jsx(ToggleButton, { value: "single", sx: { borderRadius: 16, lineHeight: 'unset' }, children: "As single rule" }), _jsx(ToggleButton, { value: "separate", sx: { borderRadius: 16, lineHeight: 'unset' }, children: "As separate rules" })] }), _jsx(Button, { onClick: () => {
                                            const criteriaSeparateRules = unusedCriteria.map((criterion) => (Object.assign(Object.assign({}, clone(emptyRow)), { comp_grid_criteria_id: [criterion.id] })));
                                            const criteriaSingleRule = [
                                                Object.assign(Object.assign({}, clone(emptyRow)), { comp_grid_criteria_id: unusedCriteria.map((c) => c.id) }),
                                            ];
                                            if (criteriaAddMode === 'separate') {
                                                setter((prevState) => {
                                                    const updatedArray = prevState[fieldId]
                                                        ? [...prevState[fieldId], ...criteriaSeparateRules]
                                                        : criteriaSeparateRules;
                                                    return Object.assign(Object.assign({}, prevState), { [fieldId]: updatedArray });
                                                });
                                            }
                                            else if (criteriaAddMode === 'single') {
                                                setter((prevState) => {
                                                    const updatedArray = prevState[fieldId]
                                                        ? [...prevState[fieldId], ...criteriaSingleRule]
                                                        : criteriaSingleRule;
                                                    return Object.assign(Object.assign({}, prevState), { [fieldId]: updatedArray });
                                                });
                                            }
                                        }, children: "Add unused criteria" })] })] })] }))] }, fieldId)) : (_jsx(Box, { children: "Error loading agent commission schedule rule" }));
};
export default AgentCommissionScheduleConfig;
