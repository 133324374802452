import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { InviteFormValues } from './validator';

const Prefix = `${process.env.REACT_APP_API}/api`;
const API_PATHS = {
  invite_new_user: `${Prefix}/users/invite_new_user`,
  agentList: `${Prefix}/contacts/contacts_without_user`,
  roles: `${Prefix}/roles`,
};

const inviteNewUser = async (
  userData: InviteFormValues & { accountId: string; accountName: string }
) => {
  const response = await axios.post(API_PATHS.invite_new_user, userData);
  return response.data;
};

export const useInviteUser = () => {
  return useMutation({
    mutationKey: [API_PATHS.invite_new_user],
    mutationFn: inviteNewUser,
  });
};

export const useAgentList = (enabled = true) => {
  return useQuery<any[]>({
    queryKey: [API_PATHS.agentList],
    queryFn: () => axios.get(API_PATHS.agentList).then((res) => res.data),
    enabled,
  });
};

export const useRoles = () => {
  return useQuery<any[]>({
    queryKey: [API_PATHS.roles],
    queryFn: () => axios.get(API_PATHS.roles).then((res) => res.data),
  });
};
