const Prefix = `${process.env.REACT_APP_API}/api`;

export const API_PATHS = {
  login: `${Prefix}/login`,
  customers: `${Prefix}/customers`,
  customer_create_from_policies: `${Prefix}/customers/create_customers_policies`,
  customer_search_name: `${Prefix}/customers/search_name`,
  report_data_customer_names: `${Prefix}/report_data/customer_names`,
  bulkUpdateUpline: `${Prefix}/contacts/bulk_update_upline`,
};
