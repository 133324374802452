var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import ProcessorPlayground from './ProcessorPlayground';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
const ProcessorModal = ({ open, handleCancel, rowData, }) => {
    const [uploading, setUploading] = useState(false);
    const [showSavingMsg, setShowSavingMsg] = useState(false);
    const { data: documents, isLoading } = API.getBasicQuery('documents');
    const playgroundRef = useRef();
    const { setLoadingConfig } = useContext(LoadingContext);
    useEffect(() => {
        setLoadingConfig({
            loading: isLoading,
            message: 'Loading...',
        });
    }, [isLoading]);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setUploading(true);
        const result = yield ((_a = playgroundRef.current) === null || _a === void 0 ? void 0 : _a.submit());
        setUploading(false);
        if (result) {
            handleCancel();
        }
    });
    return (_jsxs(Dialog, { open: open, fullScreen: true, sx: { background: 'transparent', p: 1 }, onClose: handleCancel, disableEscapeKeyDown: true, children: [_jsx(DialogTitle, { children: _jsx(Box, { className: "flex items-center", children: _jsxs(Box, { className: "pl-2", children: ["Processor editor", ' ', showSavingMsg ? (_jsx("span", { className: "text-gray-500 text-sm pl-1", children: "Saving to local..." })) : ('')] }) }) }), _jsx(IconButton, { className: "group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600", onClick: handleCancel, children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                    p: 0,
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                }, children: _jsx(ProcessorPlayground, { ref: playgroundRef, rowData: rowData, documentList: documents, setShowSavingMsg: setShowSavingMsg }) }), _jsxs(DialogActions, { sx: { pt: 0, pb: 2, px: 2 }, children: [_jsx(Button, { onClick: handleCancel, children: "Cancel" }), _jsx(LoadingButton, { onClick: onSubmit, loading: uploading, variant: "contained", sx: { width: '100px' }, children: "Save" })] })] }));
};
export default ProcessorModal;
