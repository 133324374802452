import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Input, InputAdornment, InputLabel } from '@mui/material';
import { useMemo } from 'react';
import DataView from '@/components/DataView';
import KeyConfig from '@/components/reconciliation/ReconcilersView/KeyConfig';
import SimilarityConfig from '@/components/reconciliation/ReconcilersView/SimilarityConfig';
import Reports from '@/services/Reports';
import Statements from '@/services/Statements';
import { useAccountStore } from '@/store';
import { FieldTypes } from '@/types';
import OrderConfig from '@/components/reconciliation/ReconcilersView/OrderConfig';
// const beautify = require('js-beautify').js;
const ReconcilersView = () => {
    const { selectedAccount } = useAccountStore();
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const statements = useMemo(() => new Statements(mode), [mode]);
    const reports = useMemo(() => new Reports(mode), [mode]);
    const statementFields = Object.fromEntries(Object.entries(statements.fields).filter(([k, v]) => v.enabled));
    const reportFields = Object.fromEntries(Object.entries(reports.fields).filter(([k, v]) => v.enabled));
    const dataDesc = {
        label: 'Reconcilers',
        table: 'reconcilers',
        editable: true,
        copyable: true,
        fields: [
            {
                id: 'name',
                label: 'Name',
                required: true,
            },
            {
                id: 'filters',
                type: 'heading',
                label: 'Filters',
            },
            {
                id: 'report_filter',
                type: 'sub-heading',
                label: 'Policies',
            },
            {
                id: 'filter_report_params',
                label: 'Policies filter params',
                type: 'field-matcher',
                fields: Object.values(reportFields),
                formatter: (matchCriteria) => {
                    return (matchCriteria &&
                        Object.keys(matchCriteria).length > 0 &&
                        `[${matchCriteria === null || matchCriteria === void 0 ? void 0 : matchCriteria.field}] ${matchCriteria === null || matchCriteria === void 0 ? void 0 : matchCriteria.op} "${matchCriteria === null || matchCriteria === void 0 ? void 0 : matchCriteria.value}"`);
                },
            },
            {
                id: 'statement_filter',
                type: 'sub-heading',
                label: 'Commissions',
            },
            {
                id: 'filter_statement_params',
                label: 'Commissions filter params',
                type: 'field-matcher',
                fields: Object.values(statementFields),
                formatter: (matchCriteria) => {
                    return (matchCriteria &&
                        `[${matchCriteria === null || matchCriteria === void 0 ? void 0 : matchCriteria.field}] ${matchCriteria === null || matchCriteria === void 0 ? void 0 : matchCriteria.op} "${matchCriteria === null || matchCriteria === void 0 ? void 0 : matchCriteria.value}"`);
                },
            },
            { id: 'matchMethod', type: 'heading', label: 'Matcher configuration' },
            {
                id: 'method_type',
                label: 'Method type',
                type: 'select',
                required: true,
                options: [
                    { id: 'key-config', label: 'Key Config' },
                    { id: 'key-custom', label: 'Key Custom' },
                    { id: 'similarity-config', label: 'Similarity Config' },
                    { id: 'similarity-custom', label: 'Similarity Custom' },
                ],
                tip: (_jsxs("ul", { style: { paddingLeft: 12 }, children: [_jsx("li", { children: "Key: Configure a key to be generated for each policy report and commission statement record. Records with the same key will be matched." }), _jsx("li", { children: "Key Custom: Define custom code to generate a key for policy report data and commission statement data." }), _jsx("li", { children: "Similarity: Configure fields, transformers, and similarity functions for policy report and commission statement data. Similar records above threshold will be matched." }), _jsx("li", { children: "Similarity Custom: Custom code that takes a policy report record and a commission statement record and calcuate score. Result must be a number between 0-1. Scores above specified threshold will be matched." })] })),
            },
            {
                id: 'key_config_header_report',
                type: 'sub-heading',
                label: 'Policy data key',
                condition: (row) => row.method_type === 'key-config',
            },
            {
                id: 'key_config_report',
                label: 'Report key config',
                condition: (row) => row.method_type === 'key-config',
                type: 'custom',
                render: (field, row, setter) => (_jsx(KeyConfig, { fieldId: field.id, data: row, setter: setter, fields: Object.values(reportFields).sort((a, b) => a.label.localeCompare(b.label)) }, field.id)),
                visible: ['form', 'view'],
            },
            {
                id: 'key_config_header_statement',
                type: 'sub-heading',
                label: 'Commission statement key',
                condition: (row) => row.method_type === 'key-config',
            },
            {
                id: 'key_config_statement',
                label: 'Statement key config',
                condition: (row) => row.method_type === 'key-config',
                type: 'custom',
                render: (field, row, setter) => (_jsx(KeyConfig, { fieldId: field.id, data: row, setter: setter, fields: Object.values(statementFields).sort((a, b) => a.label.localeCompare(b.label)) }, field.id)),
                visible: ['form', 'view'],
            },
            {
                id: 'key_condition',
                label: 'Key condition',
                condition: (row) => row.method_type === 'key-config',
                type: 'select',
                multiple: true,
                options: [
                    { id: '', label: '' },
                    {
                        id: 'effective_date_range',
                        label: 'Effective date range + policy term',
                    },
                ],
            },
            {
                id: 'effectiveDateBuffer',
                label: 'Effective Date Buffer',
                type: FieldTypes.CUSTOM,
                condition: (row) => { var _a; return (_a = row.key_condition) === null || _a === void 0 ? void 0 : _a.includes('effective_date_range'); },
                render: (field, row, setter) => {
                    var _a, _b, _c, _d;
                    return (_jsxs(Box, { sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            mb: 1,
                            width: '100%',
                            justifyContent: 'flex-start',
                        }, children: [_jsxs(Box, { sx: {
                                    mr: 1,
                                }, children: [_jsx(InputLabel, { children: "Start buffer month" }), _jsx(Input, { type: "number", value: (_b = (_a = row.key_condition_config) === null || _a === void 0 ? void 0 : _a.effective_date_range) === null || _b === void 0 ? void 0 : _b.startBufferMonth, endAdornment: _jsx(InputAdornment, { position: "end", children: "Months" }), onChange: (e) => {
                                            var _a;
                                            return setter(Object.assign(Object.assign({}, row), { key_condition_config: Object.assign(Object.assign({}, row.key_condition_config), { effective_date_range: Object.assign(Object.assign({}, (_a = row.key_condition_config) === null || _a === void 0 ? void 0 : _a.effective_date_range), { startBufferMonth: e.target.value }) }) }));
                                        } })] }), _jsxs(Box, { children: [_jsx(InputLabel, { children: "End buffer month" }), _jsx(Input, { type: "number", value: (_d = (_c = row.key_condition_config) === null || _c === void 0 ? void 0 : _c.effective_date_range) === null || _d === void 0 ? void 0 : _d.bufferMonth, endAdornment: _jsx(InputAdornment, { position: "end", children: "Months" }), onChange: (e) => {
                                            var _a;
                                            return setter(Object.assign(Object.assign({}, row), { key_condition_config: Object.assign(Object.assign({}, row.key_condition_config), { effective_date_range: Object.assign(Object.assign({}, (_a = row.key_condition_config) === null || _a === void 0 ? void 0 : _a.effective_date_range), { bufferMonth: e.target.value }) }) }));
                                        } })] })] }));
                },
            },
            {
                id: 'key_custom_header',
                type: 'heading',
                label: 'Custom key code',
                condition: (row) => row.method_type === 'key-custom',
            },
            {
                id: 'key_config_statement',
                label: 'Statement Method',
                tip: 'Return a key used to match record with policy report data',
                default: `// (row) => (row.policy_id.toLowerCase().replaceAll('-', ''))`,
                // OnBlurFormatter: (val) => beautify(val, { indent_size: 2 }),
                condition: (row) => row.method_type === 'key-custom',
                type: 'text-multiline',
                visible: ['form', 'view'],
            },
            {
                id: 'key_config_report',
                label: 'Report Method',
                tip: 'Return a key used to match record with commission statement data',
                default: `// (row) => (row.policy_id.toLowerCase().replaceAll('-', ''))`,
                // OnBlurFormatter: (val) => beautify(val, { indent_size: 2 }),
                condition: (row) => row.method_type === 'key-custom',
                type: 'text-multiline',
                visible: ['form', 'view'],
            },
            {
                id: 'similarity_config_header',
                type: 'heading',
                label: 'Similarity configuration',
                condition: (row) => row.method_type === 'similarity-config',
            },
            {
                id: 'similarity_config',
                label: 'Similarity config',
                condition: (row) => row.method_type === 'similarity-config',
                type: 'custom',
                render: (field, row, setter) => (_jsx(SimilarityConfig, { fieldId: field.id, data: row, setter: setter, reportFields: Object.values(reportFields), statementFields: Object.values(statementFields) }, field.id)),
                visible: ['form', 'view'],
            },
            {
                id: 'similarity_custom_header',
                type: 'heading',
                label: 'Custom similarity code',
                condition: (row) => row.method_type === 'similarity-custom',
            },
            {
                id: 'similarity_config',
                label: 'Similarity custom code',
                default: `// Score - Return value is a score between 0 and 1
        (report, statement) => {
          let score = 0;
          if (report.policy_id === statement.policy_id) score += 0.1;
          if (report.policy_id.toLowerCase() === statement.policy_id.toLowerCase()) score += 0.9;
          if (report.customer_name === statement.customer_name) score += 0.3
          if (report.product_type === statement.product_type) score += 0.2;
        }`,
                // OnBlurFormatter: (val) => beautify(val, { indent_size: 2 }),
                condition: (row) => row.method_type === 'similarity-custom',
                type: 'code',
                visible: ['form', 'view'],
            },
            {
                id: 'method_threshold_match',
                label: 'Match threshold',
                condition: (row) => { var _a; return (_a = row.method_type) === null || _a === void 0 ? void 0 : _a.startsWith('similarity'); },
                visible: ['form', 'view'],
            },
            {
                id: 'method_threshold_maybe',
                label: 'Maybe threshold',
                condition: (row) => { var _a; return (_a = row.method_type) === null || _a === void 0 ? void 0 : _a.startsWith('similarity'); },
                visible: ['form', 'view'],
            },
            {
                id: 'config',
                label: 'Options',
                type: 'select',
                multiple: true,
                options: [
                    {
                        id: 'matchCommissionsWithoutPolicy',
                        label: 'Match statements without policy match',
                    },
                    {
                        id: 'oneCommissionOneAgent',
                        label: 'Only assign agent with closest split % to commission',
                    },
                ],
                condition: (row) => { var _a; return (_a = row.method_type) === null || _a === void 0 ? void 0 : _a.startsWith('key'); },
            },
            {
                id: 'order_header',
                type: 'heading',
                label: 'Order',
            },
            {
                id: 'order',
                label: 'Order',
                type: 'custom',
                tableFormatter: () => 'Order',
                render: (field, row, setter) => (_jsx(OrderConfig, { fieldId: field.id, data: row, fields: Object.values(reportFields).sort((a, b) => a.label.localeCompare(b.label)), setter: setter }, field.id)),
                visible: ['form', 'view'],
            },
            {
                id: 'notes',
                type: 'text-multiline',
                label: 'Notes',
            },
            { id: 'divider3', type: 'divider', label: 'divider' },
            {
                id: 'access',
                label: 'Access',
                type: 'select',
                default: 'account',
                options: ['account', 'global'],
                access: 'admin',
            },
            {
                id: 'updated_at',
                label: 'Last updated',
                formatter: (s) => new Date(s).toLocaleString(),
                condition: (row) => row.updated_at,
                readOnly: true,
            },
        ],
    };
    return _jsx(DataView, { dataDesc: dataDesc, hideExport: true });
};
export default ReconcilersView;
