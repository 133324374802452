import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Logout } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getLocalData } from '@/utils/localStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/account';
import { EnableUpdateToggle } from './EnableUpdateToggle';
export const LoginAsUser = ({ showAddDocument, }) => {
    const [loginAsUser, setLoginAsUser] = useState();
    useEffect(() => {
        const user = getLocalData(LOCAL_STORAGE_KEYS.customLoginUser);
        if (user) {
            setLoginAsUser(user);
        }
    }, []);
    if (!loginAsUser)
        return null;
    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: { pt: 0.5, mb: showAddDocument ? 0 : 1 }, display: "flex", flexDirection: "column", children: [_jsxs(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }, children: [_jsx(Box, { sx: { width: '100%' }, children: _jsx(Box, { children: _jsx(Divider, {}) }) }), _jsx(Typography, { variant: "body2", sx: { whiteSpace: 'nowrap', mx: 1 }, children: "Logged in as" }), _jsx(Box, { sx: { width: '100%' }, children: _jsx(Box, { children: _jsx(Divider, {}) }) })] }), _jsxs(Box, { sx: { px: 1 }, display: "flex", flexDirection: "column", children: [_jsx(Typography, { variant: "body2", sx: {
                                width: '100%',
                                fontWeight: 'medium',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                            }, children: loginAsUser.email || loginAsUser.delegatorEmail }), _jsx(EnableUpdateToggle, {}), _jsx(Button, { color: "error", startIcon: _jsx(Logout, {}), variant: "contained", onClick: () => {
                                localStorage.clear();
                                setLoginAsUser(null);
                                localStorage.setItem('triggerReload', Date.now().toString());
                                window.location.href = '/admin/accounts';
                            }, sx: { mt: 0.5 }, children: "Logout" })] })] }) }));
};
