import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, } from '@mui/material';
const FeedbackDialog = ({ open, onClose, onSubmit, }) => {
    const [currFeedback, setCurrFeedback] = useState('');
    const handleSubmit = () => {
        onSubmit(currFeedback);
        setCurrFeedback('');
        onClose();
    };
    return (_jsxs(Dialog, { open: open, onClose: onClose, maxWidth: "sm", fullWidth: true, children: [_jsx(DialogTitle, { children: "Submit feedback" }), _jsxs(DialogContent, { children: [_jsx(Typography, { variant: "body2", sx: { color: 'grey', mb: 2 }, children: "Let us know if you encounter issues or have suggestions." }), _jsx(TextField, { autoFocus: true, multiline: true, rows: 4, fullWidth: true, variant: "outlined", label: "Your feedback", value: currFeedback, onChange: (e) => setCurrFeedback(e.target.value) })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: handleSubmit, variant: "contained", color: "primary", children: "Save" })] })] }));
};
export default FeedbackDialog;
