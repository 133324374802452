import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Button, Collapse, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from '@mui/material';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Close as CloseIcon, } from '@mui/icons-material';
import { nanoid } from 'nanoid';
import { enumToSelectOptions } from 'common/utils';
import { TransactionStatuses } from 'common/constants';
import currency from 'currency.js';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
const defaultTransaction = {
    amount: '',
    status: TransactionStatuses.Draft,
    date: null,
    notes: '',
    accounting_transaction_details: [],
};
const defaultTransactionDetails = {
    amount: '',
    status: TransactionStatuses.Draft,
    date: null,
    notes: '',
};
const calculateTotalAmount = (details) => {
    return currency(details.reduce((sum, detail) => sum.add(currency(detail.amount || '0')), currency(0))).toString();
};
const updateTransaction = (transactions, transactionId, updateFn) => {
    return transactions.map((transaction) => transaction.id === transactionId ? updateFn(transaction) : transaction);
};
const updateTransactionDetail = (details, detailId, updateFn) => {
    return details.map((detail) => detail.id === detailId ? updateFn(detail) : detail);
};
const ContactsTransactionsAdd = ({ data, field, setter, }) => {
    var _a;
    const [open, setOpen] = useState({});
    const handleToggle = (id) => {
        setOpen((prev) => (Object.assign(Object.assign({}, prev), { [id]: !prev[id] })));
    };
    const contactTransactions = (_a = data === null || data === void 0 ? void 0 : data.accounting_transactions) !== null && _a !== void 0 ? _a : [];
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(TableContainer, { children: _jsxs(Table, { children: [contactTransactions.length > 0 && (_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: "Date" }), _jsx(TableCell, { children: "Total amount" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Notes" }), _jsx(TableCell, { sx: { width: 0 } })] }) })), _jsxs(TableBody, { children: [contactTransactions.map((contactTransaction) => {
                                    var _a, _b, _c;
                                    return (_jsxs(React.Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(IconButton, { size: "small", onClick: () => handleToggle(contactTransaction.id), children: open[contactTransaction.id] ? (_jsx(KeyboardArrowUpIcon, {})) : (_jsx(KeyboardArrowDownIcon, {})) }) }), _jsx(TableCell, { children: _jsx(BasicDatePicker, { label: "Date", value: contactTransaction.date, setValue: (e) => {
                                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => (Object.assign(Object.assign({}, item), { date: e }))) }));
                                                            } }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Total amount", variant: "outlined", value: calculateTotalAmount(contactTransaction.accounting_transaction_details || []), disabled: true }) }), _jsx(TableCell, { children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: "Status" }), _jsx(Select, { value: (_a = contactTransaction.status) !== null && _a !== void 0 ? _a : '', label: "Status", onChange: (e) => {
                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                var _a;
                                                                                return (Object.assign(Object.assign({}, item), { status: e.target.value, accounting_transaction_details: ((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : []).map((td) => (Object.assign(Object.assign({}, td), { status: e.target.value }))) }));
                                                                            }) }));
                                                                    }, children: enumToSelectOptions(TransactionStatuses).map((option) => (_jsx(MenuItem, { value: option.id, children: option.label }, option.id))) })] }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Notes", variant: "outlined", value: (_b = contactTransaction.notes) !== null && _b !== void 0 ? _b : '', onChange: (e) => {
                                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => (Object.assign(Object.assign({}, item), { notes: e.target.value }))) }));
                                                            }, multiline: true }) }), _jsx(TableCell, { children: _jsx(IconButton, { onClick: () => {
                                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: contactTransactions.filter((cl) => cl.id !== contactTransaction.id) }));
                                                            }, children: _jsx(CloseIcon, {}) }) })] }), _jsx(TableRow, { children: _jsx(TableCell, { style: { paddingBottom: 0, paddingTop: 0 }, colSpan: 6, children: _jsx(Collapse, { in: open[contactTransaction.id], timeout: "auto", unmountOnExit: true, children: _jsxs(Box, { sx: { width: '100%', ml: 1 }, children: [_jsx(Typography, { variant: "subtitle2", gutterBottom: true, component: "div", children: "Details" }), _jsxs(Table, { size: "small", children: [_jsx(TableHead, { children: contactTransaction.accounting_transaction_details &&
                                                                                contactTransaction.accounting_transaction_details
                                                                                    .length > 0 && (_jsxs(TableRow, { children: [_jsx(TableCell, { children: "Date" }), _jsx(TableCell, { children: "Amount" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Notes" }), _jsx(TableCell, { sx: { width: 0 } })] })) }), _jsxs(TableBody, { children: [(_c = contactTransaction.accounting_transaction_details) === null || _c === void 0 ? void 0 : _c.map((transactionDetail) => {
                                                                                    var _a, _b, _c;
                                                                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(BasicDatePicker, { label: "Date", value: transactionDetail.date, setValue: (e) => {
                                                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                                var _a, _b;
                                                                                                                return (Object.assign(Object.assign({}, item), { accounting_transaction_details: updateTransactionDetail((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : [], transactionDetail.id, (detailItem) => (Object.assign(Object.assign({}, detailItem), { date: e }))), amount: calculateTotalAmount((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []) }));
                                                                                                            }) }));
                                                                                                    } }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Amount", variant: "outlined", value: (_a = transactionDetail.amount) !== null && _a !== void 0 ? _a : '', onChange: (e) => {
                                                                                                        const newAmount = e.target.value;
                                                                                                        const updatedTransactions = updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                            var _a, _b;
                                                                                                            return (Object.assign(Object.assign({}, item), { accounting_transaction_details: updateTransactionDetail((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : [], transactionDetail.id, (detailItem) => (Object.assign(Object.assign({}, detailItem), { amount: newAmount }))), amount: calculateTotalAmount((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []) }));
                                                                                                        });
                                                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updatedTransactions }));
                                                                                                    } }) }), _jsx(TableCell, { children: _jsxs(FormControl, { children: [_jsx(InputLabel, { children: "Status" }), _jsx(Select, { value: (_b = transactionDetail.status) !== null && _b !== void 0 ? _b : '', label: "Status", onChange: (e) => {
                                                                                                                const newStatus = e.target.value;
                                                                                                                const updatedTransactions = updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                                    var _a, _b;
                                                                                                                    return (Object.assign(Object.assign({}, item), { accounting_transaction_details: updateTransactionDetail((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : [], transactionDetail.id, (detailItem) => (Object.assign(Object.assign({}, detailItem), { status: newStatus }))), amount: calculateTotalAmount((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []) }));
                                                                                                                });
                                                                                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: updatedTransactions }));
                                                                                                            }, children: enumToSelectOptions(TransactionStatuses).map((option) => (_jsx(MenuItem, { value: option.id, children: option.label }, option.id))) })] }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Notes", variant: "outlined", value: (_c = transactionDetail.notes) !== null && _c !== void 0 ? _c : '', onChange: (e) => {
                                                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                                var _a;
                                                                                                                return (Object.assign(Object.assign({}, item), { accounting_transaction_details: updateTransactionDetail((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : [], transactionDetail.id, (detailItem) => (Object.assign(Object.assign({}, detailItem), { notes: e.target.value }))) }));
                                                                                                            }) }));
                                                                                                    }, multiline: true }) }), _jsx(TableCell, { children: _jsx(IconButton, { onClick: () => {
                                                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                                var _a, _b;
                                                                                                                return (Object.assign(Object.assign({}, item), { accounting_transaction_details: (_a = item.accounting_transaction_details) === null || _a === void 0 ? void 0 : _a.filter((detailItem) => detailItem.id !==
                                                                                                                        transactionDetail.id), amount: calculateTotalAmount((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []) }));
                                                                                                            }) }));
                                                                                                    }, children: _jsx(CloseIcon, {}) }) })] }, transactionDetail.id));
                                                                                }), _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 4, children: _jsx(Button, { onClick: (e) => {
                                                                                                e.stopPropagation();
                                                                                                e.preventDefault();
                                                                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                        var _a, _b;
                                                                                                        return (Object.assign(Object.assign({}, item), { accounting_transaction_details: [
                                                                                                                ...((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : []),
                                                                                                                Object.assign({ id: nanoid() }, defaultTransactionDetails),
                                                                                                            ], amount: calculateTotalAmount([
                                                                                                                ...((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []),
                                                                                                                Object.assign({ id: nanoid() }, defaultTransactionDetails),
                                                                                                            ]) }));
                                                                                                    }) }));
                                                                                            }, children: "Add detail" }) }) })] })] })] }) }) }) })] }, contactTransaction.id));
                                }), _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 6, children: _jsx(Button, { onClick: (e) => {
                                                var _a;
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: [
                                                        ...((_a = data.accounting_transactions) !== null && _a !== void 0 ? _a : []),
                                                        Object.assign({ id: nanoid() }, defaultTransaction),
                                                    ] }));
                                            }, children: "Add transaction" }) }) })] })] }) })] }));
};
export default ContactsTransactionsAdd;
