import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, } from '@mui/material';
const AddFieldsDialog = ({ open, onClose, onConfirm, fieldsSource, }) => {
    const [selectedFields, setSelectedFields] = useState();
    const handleSelectionChange = (event) => {
        const val = event.target.value;
        const item = fieldsSource.find((item) => item.key === val);
        setSelectedFields(item);
    };
    const handleSubmit = () => {
        onConfirm(selectedFields);
    };
    return (_jsxs(Dialog, { open: open, onClose: onClose, fullWidth: true, children: [_jsx(DialogTitle, { children: "Add Fields Manually" }), _jsx(DialogContent, { children: _jsx(Box, { children: _jsx(Select, { fullWidth: true, value: selectedFields === null || selectedFields === void 0 ? void 0 : selectedFields.key, onChange: handleSelectionChange, children: fieldsSource.map((item) => (_jsx(MenuItem, { value: item.key, children: _jsxs(Box, { sx: {
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                }, children: [_jsx(Box, { children: item.label }), _jsx(Box, { sx: { color: '#aaa', fontSize: '12px' }, children: item.key })] }) }, item.key))) }) }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: handleSubmit, disabled: !selectedFields, variant: "contained", children: "Add" })] })] }));
};
export default AddFieldsDialog;
