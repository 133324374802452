import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, useMediaQuery } from '@mui/material';
import hotkeys from 'hotkeys-js';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import AppDrawer from '@/components/AppDrawer';
import EmptyState from '@/components/EmptyState';
import Onboarding from '@/components/Onboarding';
import LandingImg from '@/illustrations/landing_tp.png';
import { useAccountStore } from '@/store';
const hotkeyDefs = {
    '/admin/accounts': 'g+a',
    '/admin/users': 'g+u',
    '/commissions': 'g+c',
    '/documents': 'g+d',
    '/insights': 'g+i',
    '/policies': 'g+p',
    '/reconciliation': 'g+o',
    '/reports': 'g+r',
    '/settings': 'g+s',
    '/views': 'g+v',
};
const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);
const pathToTitle = (path) => path
    .split('/')
    .map(capitalize)
    .map((s) => s.replaceAll('-', ' '))
    .filter((s) => s)
    .join(' - ');
export default function Layout(props) {
    const { user, Header } = props;
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { selectedAccount, userState } = useAccountStore();
    const location = useLocation();
    const [slugLabel, setSlugLabel] = useState(pathToTitle(location.pathname));
    useEffect(() => {
        setSlugLabel(pathToTitle(location.pathname));
    }, [location.pathname]);
    const createHotkey = useCallback((hotkey, path) => {
        hotkeys(hotkey, (e) => {
            e.preventDefault();
            navigate(path);
        });
    }, [navigate]);
    const createHotkeys = useCallback(() => {
        Object.entries(hotkeyDefs).forEach(([k, v]) => {
            createHotkey(v, k);
        });
    }, [createHotkey]);
    useEffect(() => {
        createHotkeys();
        return () => {
            hotkeys.unbind();
        };
    }, [createHotkeys]);
    const appBarHeight = isMobile ? '56px' : '64px';
    return (_jsxs(_Fragment, { children: [_jsx(Helmet, { children: _jsxs("title", { children: ["Fintary", slugLabel && ` - ${slugLabel}`] }) }), _jsxs(Box, { className: "flex flex-col w-screen h-screen overflow-hidden", children: [_jsx(Box, { sx: { height: appBarHeight }, children: Header }), user && userState ? (userState.userOverallState !== 'active' ? (_jsx(Onboarding, { userState: userState })) : (_jsx(_Fragment, { children: selectedAccount && Object.keys(selectedAccount).length > 0 ? (_jsxs(Box, { sx: {
                                height: `calc(100vh - ${appBarHeight})`,
                                display: 'flex',
                            }, children: [_jsx(AppDrawer, {}), _jsx(Outlet, {})] })) : null }))) : (_jsx(EmptyState, { image: _jsx("img", { src: LandingImg, width: "100%" }), title: "Fintary", description: "One place for all your financial operations", size: "large" }))] })] }));
}
