var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, FormControl, IconButton, InputLabel, Menu, MenuItem, Select, Tab, Tabs, Tooltip, Typography, } from '@mui/material';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { MoreVert } from '@mui/icons-material';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './styles.css';
import { isNull } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { WidgetGroup } from 'common/constants';
import { useNavigate } from 'react-router-dom';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { LoadingContext } from 'contexts/LoadingContext';
import AddIcon from '@mui/icons-material/Add';
import { en } from 'chrono-node';
import useDashboard from './dashboardHook';
import { useRoleStore } from '@/store';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import API from '@/services/API';
import UILabels from '@/services/UILabels';
import { useAccountStore } from '@/store';
import WidgetCreator from './WidgetCreator';
import BoxWidget from './BoxWidget';
import ChartWidget from './ChartWidget';
import TableWidget from './TableWidget';
import WidgetWrapper from './WidgetWrapper';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { Roles } from '@/types';
import WidgetSelector from './WidgetSelector';
import SubPageCreator from './SubPageCreator';
import useSnackbar from '@/contexts/useSnackbar';
import { EnhancedSelect } from '../molecules/EnhancedSelect';
dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
const dateRanges = {
    thisWeek: () => ({
        start_date: dayjs().startOf('week').toDate(),
        end_date: dayjs().endOf('week').toDate(),
    }),
    lastWeek: () => ({
        start_date: dayjs().subtract(1, 'week').startOf('week').toDate(),
        end_date: dayjs().subtract(1, 'week').endOf('week').toDate(),
    }),
    thisMonth: () => ({
        start_date: dayjs().startOf('month').toDate(),
        end_date: dayjs().endOf('month').toDate(),
    }),
    lastMonth: () => ({
        start_date: dayjs().subtract(1, 'month').startOf('month').toDate(),
        end_date: dayjs().subtract(1, 'month').endOf('month').toDate(),
    }),
    thisQuarter: () => ({
        start_date: dayjs().startOf('quarter').toDate(),
        end_date: dayjs().endOf('quarter').toDate(),
    }),
    lastQuarter: () => ({
        start_date: dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
        end_date: dayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
    }),
    thisYear: () => ({
        start_date: dayjs().startOf('year').toDate(),
        end_date: dayjs().endOf('year').toDate(),
    }),
    lastYear: () => ({
        start_date: dayjs().subtract(1, 'year').startOf('year').toDate(),
        end_date: dayjs().subtract(1, 'year').endOf('year').toDate(),
    }),
    last7days: () => ({
        start_date: dayjs().subtract(7, 'days').toDate(),
        end_date: dayjs().toDate(),
    }),
    last30days: () => ({
        start_date: dayjs().subtract(30, 'days').toDate(),
        end_date: dayjs().toDate(),
    }),
    last60days: () => ({
        start_date: dayjs().subtract(60, 'days').toDate(),
        end_date: dayjs().toDate(),
    }),
    last90days: () => ({
        start_date: dayjs().subtract(90, 'days').toDate(),
        end_date: dayjs().toDate(),
    }),
};
const DrawerMode = {
    CREATE_WIDGET: 'CREATE_WIDGET',
    ADD_SHARED_WIDGET: 'ADD_SHARED_WIDGET',
    ADD_NEW_PAGE: 'ADD_NEW_PAGE',
};
const filters = {
    agentGroup: {
        label: 'Agent group',
        type: 'multi-select',
        field: 'agent_group',
        optionsKey: 'agentGroup',
        filterFunc: (val, filterVal) => filterVal === 'All' ? true : val === filterVal,
    },
    agent: {
        label: 'Agent',
        type: 'multi-select',
        field: 'agent',
        optionsKey: 'agent',
        filterFunc: (val, filterVal) => filterVal === 'All' ? true : val === filterVal,
    },
    policyStatus: {
        label: 'Policy status',
        type: 'multi-select',
        optionsKey: 'policyStatus',
        field: 'policy_status',
        filterFunc: (val, filterVal) => filterVal === 'All' ? true : val === filterVal,
    },
    productType: {
        label: 'Product type',
        type: 'multi-select',
        optionsKey: 'productType',
        field: 'product_type',
        filterFunc: (val, filterVal) => filterVal === 'All' ? true : val === filterVal,
    },
    compensationType: {
        label: 'Compensation type',
        type: 'multi-select',
        optionsKey: 'compensationType',
        field: 'compensation_type',
        filterFunc: (val, filterVal) => filterVal === 'All' ? true : val === filterVal,
    },
    tag: {
        label: 'Tag',
        type: 'multi-select',
        optionsKey: 'tag',
        field: 'tag',
        filterFunc: (val, filterVal) => filterVal === 'All' ? true : val === filterVal,
    },
    dateRange: {
        label: 'Date range',
        type: 'select',
        options: [
            { label: 'This week', value: 'thisWeek' },
            { label: 'Last week', value: 'lastWeek' },
            { label: 'This month', value: 'thisMonth' },
            { label: 'Last month', value: 'lastMonth' },
            { label: 'This quarter', value: 'thisQuarter' },
            { label: 'Last quarter', value: 'lastQuarter' },
            { label: 'This year', value: 'thisYear' },
            { label: 'Last year', value: 'lastYear' },
            { label: 'Last 7 days', value: 'last7days' },
            { label: 'Last 30 days', value: 'last30days' },
            { label: 'Last 60 days', value: 'last60days' },
            { label: 'Last 90 days', value: 'last90days' },
            { label: 'Custom', value: 'custom' },
        ],
        initialValue: 'last60days',
        sx: { width: 140 },
    },
    start_date: {
        label: 'Start date',
        type: 'date',
        field: 'effectiveDate',
        initialValue: dayjs().subtract(60, 'days').toDate(),
        filterFunc: (val, filterVal) => val >= filterVal,
    },
    end_date: {
        label: 'End date',
        type: 'date',
        field: 'effectiveDate',
        initialValue: dayjs().toDate(),
        filterFunc: (val, filterVal) => val <= filterVal,
    },
    timePeriod: {
        label: 'Time period',
        type: 'select',
        options: [
            { label: 'Day', value: 'day' },
            { label: 'Week', value: 'week' },
            { label: 'Month', value: 'month' },
        ],
        initialValue: 'Month',
        sx: { width: 100 },
    },
};
const ResponsiveGridLayout = WidthProvider(Responsive);
const Dashboard = ({ dashboardLabel, dashboardName }) => {
    var _a, _b, _c, _d, _e;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const navigate = useNavigate();
    const [sideDrawerMode, setSideDrawerMode] = useState(DrawerMode.CREATE_WIDGET);
    const [layouts, setLayouts] = useState(null);
    const [showSaveLayoutButton, setShowSaveLayoutButton] = useState(false);
    const { setLoadingConfig } = useContext(LoadingContext);
    const cols = { lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 };
    const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
    const margin = {
        lg: [10, 10],
        md: [10, 10],
        sm: [10, 10],
        xs: [10, 10],
        xxs: [10, 10],
    };
    const sidebarWidth = 500;
    const { role: [role], } = useContext(UIStateContext);
    const isFinatryAdmin = role === 'admin';
    const { userRole } = useRoleStore();
    const isAccountAdmin = userRole === Roles.ACCOUNT_ADMIN;
    const [isEditingMode, setIsEditingMode] = useState(false);
    const [widgetOnEdit, setWidgetOnEdit] = useState(null);
    const [activeView, setActiveView] = useState(userRole);
    const [searchParams, setSearchParams] = useSearchParams();
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.insights;
    const createWidgetPoster = API.getMutation('insights/preview', 'POST');
    const saveWidgetLayoutPoster = API.getMutation('insights/layout', 'POST');
    const saveWidgetPoster = API.getMutation('insights', 'POST');
    const deleter = API.getMutation('insights', 'DELETE');
    const dashboardDeleter = API.getMutation('insights/delete_dashboard', 'DELETE');
    const updateWidgetPoster = API.getMutation('insights', 'PUT');
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    let pageLabel = dashboardLabel !== null && dashboardLabel !== void 0 ? dashboardLabel : 'Insights';
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        pageLabel = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return _jsx(Navigate, { to: "/settings" });
    }
    const [widgetModel, setWidgetModel] = useState(null);
    const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
    const { showSnackbar } = useSnackbar();
    const { setPredefinedDashboardName, setPredefinedWidgetSettings } = useDashboard();
    const [widgetIdTobeDeleted, setWidgetIdTobeDeleted] = useState(null);
    const [filteredValues, setFilteredValues] = useState({
        agentGroup: [],
        agent: [],
        compensationType: [],
        tag: [],
        dateRange: 'last60days',
        end_date: dateRanges.last60days().end_date,
        policyStatus: [],
        productType: [],
        start_date: dateRanges.last60days().start_date,
        timePeriod: 'day',
    });
    // Load the filtered values from the url query string
    useEffect(() => {
        const filteredValues = {};
        Object.keys(filters).forEach((key) => {
            if (searchParams.has(key)) {
                if (filters[key].type === 'multi-select') {
                    filteredValues[key] = searchParams.getAll(key);
                }
                else {
                    filteredValues[key] = searchParams.get(key);
                }
            }
        });
        setFilteredValues((prev) => (Object.assign(Object.assign({}, prev), filteredValues)));
    }, []);
    const updateFilteredValues = (key, value) => {
        setFilteredValues(Object.assign(Object.assign({}, filteredValues), { [key]: value }));
        setSearchParams((prev) => {
            prev.set(key, value);
            return prev;
        });
    };
    const updateUrlAndQuery = (key, value, replace = false) => {
        updateUrl(key, value, replace);
        updateQuery(key, value, replace);
    };
    const clearUrlAndQuery = (key) => {
        setSearchParams((prev) => {
            prev.delete(key);
            return prev;
        });
        query = query.replace(new RegExp(`${key}=[^&]*&?`), '');
    };
    const updateUrl = (key, value, replace = false) => {
        const values = searchParams.getAll(key);
        if (replace) {
            if (searchParams.has(key) && searchParams.get(key) != value) {
                setSearchParams((prev) => {
                    prev.set(key, value);
                    return prev;
                });
                return;
            }
        }
        if (values && values.some((val) => val == value)) {
            return;
        }
        setSearchParams((prev) => {
            prev.append(key, value);
            return prev;
        });
    };
    const updateQuery = (key, value, replace = false) => {
        if (replace) {
            query = query.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`);
            return;
        }
        if (query.includes(`${key}=${value}`)) {
            return;
        }
        query = query.concat(`&${key}=${value}`);
    };
    const { data: settingsData } = API.getBasicQuery('accounts');
    const { selectedAccount } = useAccountStore();
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const labels = new UILabels(mode);
    let query = searchParams.toString();
    query.concat(`&start_date=${new Date(filteredValues.start_date)
        .toISOString()
        .substring(0, 10)}&end_date=${new Date(filteredValues.end_date)
        .toISOString()
        .substring(0, 10)}`);
    if (query[query.length - 1] !== '?') {
        query.concat(`&time_period=${filteredValues.timePeriod}`);
    }
    else {
        query.concat(`time_period=${filteredValues.timePeriod}`);
    }
    if (activeView) {
        updateUrlAndQuery('active_view', activeView, true);
    }
    const createWidget = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const agent_group = [];
        filteredValues.agentGroup.forEach((val) => {
            agent_group.push(val);
        });
        const compensation_type = [];
        filteredValues.compensationType.forEach((val) => {
            compensation_type.push(val);
        });
        const product_type = [];
        filteredValues.productType.forEach((val) => {
            product_type.push(val);
        });
        const policy_status = [];
        filteredValues.policyStatus.forEach((val) => {
            policy_status.push(val);
        });
        const response = yield createWidgetPoster.mutateAsync({
            // Build the below data object here using the query data
            agent: filteredValues.agent,
            agent_group: (agent_group === null || agent_group === void 0 ? void 0 : agent_group.length) ? agent_group : undefined,
            compensation_type: compensation_type.length
                ? compensation_type
                : undefined,
            end_date: new Date(filteredValues.end_date)
                .toISOString()
                .substring(0, 10),
            policy_status: policy_status.length ? policy_status : undefined,
            product_type: product_type.length ? product_type : undefined,
            start_date: new Date(filteredValues.start_date)
                .toISOString()
                .substring(0, 10),
            time_period: filteredValues.timePeriod,
            widgetDefinition: data,
        });
        return response;
    });
    const saveWidget = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isNull(widgetModel)) {
            return;
        }
        setLoadingConfig({
            loading: true,
            message: 'Saving widget...',
        });
        let response;
        try {
            if (isEditingMode) {
                response = yield updateWidgetPoster.mutateAsync({
                    id: widgetOnEdit.id,
                    name: widgetModel.name,
                    spec: widgetModel.spec,
                    accessRoles: widgetModel.accessRoles,
                });
            }
            else {
                response = yield saveWidgetPoster.mutateAsync({
                    name: widgetModel.name,
                    spec: widgetModel.spec,
                    accessRoles: widgetModel.accessRoles,
                });
            }
        }
        catch (e) {
            showSnackbar(`Failed to save widget: ${e.message}`, 'error');
            setLoadingConfig({
                loading: false,
            });
            return;
        }
        setLoadingConfig({
            loading: false,
        });
        switch (response.widgetGroup) {
            case WidgetGroup.BOX:
                setBoxes((prev) => [...prev, response]);
                break;
            case WidgetGroup.CHART:
                setCharts((prev) => [...prev, response]);
                break;
            case WidgetGroup.TABLE:
                setTables((prev) => [...prev, response]);
                break;
        }
    });
    const saveLayout = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingConfig({
            loading: true,
            message: 'Saving layout...',
        });
        try {
            yield saveWidgetLayoutPoster.mutateAsync({
                layout: layouts,
                dashboardId: insightsData === null || insightsData === void 0 ? void 0 : insightsData.dashboardId,
                role_id: activeView !== null && activeView !== void 0 ? activeView : userRole,
            });
        }
        catch (e) {
            showSnackbar(`Failed to save layout: ${e.message}`, 'error');
        }
        setLoadingConfig({
            loading: false,
        });
    });
    const [insightsData, setInsightsData] = useState({});
    const [widgetArray, setWidgetArray] = useState([]);
    const { isLoading: isLoadingInsights, data: result, isFetched: isFetchedInsights, } = API.getBasicQuery('insights', query);
    useEffect(() => {
        var _a, _b;
        if (isFetchedInsights) {
            setInsightsData(result);
            const layout = ((_a = result.dashboardSettings) === null || _a === void 0 ? void 0 : _a.layout)
                ? (_b = result.dashboardSettings) === null || _b === void 0 ? void 0 : _b.layout[activeView]
                : null;
            buildWidgetArray([
                ...(result && result.boxes ? result.boxes : []),
                ...(result && result.charts ? result.charts : []),
                ...(result && result.tables ? result.tables : []),
            ], layout);
        }
    }, [result]);
    const buildWidgetArray = (data, layout) => {
        const currentBreakpoint = Object.keys(breakpoints).find((breakpoint) => {
            return window.innerWidth > breakpoints[breakpoint];
        });
        const totalCols = cols[currentBreakpoint];
        let tempArray = [];
        let boxIndex = 0;
        let chartTableIndex = 0;
        data.map((item, index) => {
            const arrayItem = Object.assign(Object.assign({}, item), { i: '' + item.id });
            if (item.widgetGroup === WidgetGroup.BOX) {
                arrayItem.w = 2;
                arrayItem.h = 1;
                arrayItem.x = (boxIndex * 2) % totalCols;
                arrayItem.y = Math.floor((boxIndex * 2) / totalCols);
                boxIndex++;
            }
            else {
                arrayItem.w = 4;
                arrayItem.h = 2;
                arrayItem.x = (chartTableIndex * 4) % totalCols;
                arrayItem.y = Math.floor((boxIndex * 4 + chartTableIndex * 4) / totalCols);
                chartTableIndex++;
            }
            tempArray.push(arrayItem);
        });
        if (layout) {
            layout === null || layout === void 0 ? void 0 : layout.map((position) => {
                tempArray.map((data) => {
                    if (data.i === position.i) {
                        data.x = position.x;
                        data.y = position.y;
                        data.w = position.w;
                        data.h = position.h;
                    }
                });
            });
        }
        const lastX = tempArray.sort((a, b) => b.x - a.x)[0];
        const lastY = tempArray.sort((a, b) => b.y - a.y)[0];
        tempArray.push({
            i: 'new',
            x: (lastX ? lastX.x + lastX.w : 0) % totalCols,
            y: (lastY ? lastY.y : 0) + 1,
            w: 2,
            h: 1,
        });
        setWidgetArray(tempArray);
    };
    const handleModify = (currentLayout, allLayouts) => {
        setLayouts(currentLayout);
        setShowSaveLayoutButton(true);
    };
    const renderViewChip = (role) => {
        let label = '';
        switch (role) {
            case Roles.ACCOUNT_ADMIN:
                label = 'Account admin view';
                break;
            case Roles.PRODUCER:
                label = 'Producer view';
                break;
            case Roles.DATA_SPECIALIST:
                label = 'Data specialist view';
                break;
            default:
                return null;
        }
        return (_jsx(Chip, { sx: {
                m: 0.2,
            }, clickable: true, color: role === activeView ? 'primary' : 'default', onClick: () => setActiveView(role), label: label, variant: role === activeView ? '' : 'outlined' }));
    };
    useEffect(() => {
        if (filteredValues.dateRange !== 'custom') {
            setFilteredValues(Object.assign(Object.assign({}, filteredValues), dateRanges[filteredValues.dateRange]()));
            const start_date = dateRanges[filteredValues.dateRange]().start_date;
            const end_date = dateRanges[filteredValues.dateRange]().end_date;
            updateUrlAndQuery('start_date', start_date.toISOString().substring(0, 10), true);
            updateUrlAndQuery('end_date', end_date.toISOString().substring(0, 10), true);
        }
    }, [filteredValues.dateRange]);
    const isLoading = isLoadingInsights;
    const [boxes, setBoxes] = useState([]);
    const [charts, setCharts] = useState([]);
    const [tables, setTables] = useState([]);
    useEffect(() => {
        var _a;
        if (!widgetModel || widgetOnEdit) {
            return;
        }
        let layoutPlaceHolder = (_a = widgetArray.filter((item) => item.i === 'new')) === null || _a === void 0 ? void 0 : _a[0];
        if (!layoutPlaceHolder) {
            return;
        }
        let layout = widgetArray.filter((item) => item.i !== 'new');
        layoutPlaceHolder = Object.assign(Object.assign(Object.assign({}, layoutPlaceHolder), widgetModel), { i: 'preview' });
        layout.push(layoutPlaceHolder);
        setWidgetArray(layout);
    }, [widgetModel]);
    useEffect(() => {
        var _a, _b, _c;
        if (isFetchedInsights) {
            if (dashboardName) {
                setBoxes(insightsData === null || insightsData === void 0 ? void 0 : insightsData.boxes);
                setCharts(insightsData === null || insightsData === void 0 ? void 0 : insightsData.charts);
                setTables(insightsData === null || insightsData === void 0 ? void 0 : insightsData.tables);
            }
            else {
                setBoxes((_a = insightsData === null || insightsData === void 0 ? void 0 : insightsData.boxes) !== null && _a !== void 0 ? _a : []);
                setCharts((_b = insightsData === null || insightsData === void 0 ? void 0 : insightsData.charts) !== null && _b !== void 0 ? _b : []);
                setTables((_c = insightsData === null || insightsData === void 0 ? void 0 : insightsData.tables) !== null && _c !== void 0 ? _c : []);
            }
        }
    }, [isFetchedInsights, insightsData]);
    const filteredFilters = Object.fromEntries(Object.entries(filters).filter(([k, v]) => !['start_date', 'end_date'].includes(k) ||
        filteredValues.dateRange === 'custom'));
    const [sideDrawerOpen, setsideDrawerOpen] = useState(false);
    const openAddWidgetDialog = (mode) => {
        setSideDrawerMode(mode);
        setIsEditingMode(false);
        setWidgetOnEdit(null);
        setsideDrawerOpen(true);
    };
    const copyExistingWidget = (widget) => {
        setWidgetOnEdit(widget);
        setSideDrawerMode(DrawerMode.CREATE_WIDGET);
        setIsEditingMode(false);
        setsideDrawerOpen(true);
    };
    const editWidget = (id) => {
        const widget = [...boxes, ...charts, ...tables].find((e) => e.id === id);
        setWidgetOnEdit(Object.assign(Object.assign({}, widget.spec), { id: widget.id, spec: widget.spec }));
        setIsEditingMode(true);
        setSideDrawerMode(DrawerMode.CREATE_WIDGET);
        setsideDrawerOpen(true);
    };
    const renderDrawerContent = (mode) => {
        switch (mode) {
            case DrawerMode.CREATE_WIDGET:
                return (_jsx(WidgetCreator, { widgetOnEdit: widgetOnEdit, createWidget: createWidget, setWidgetModel: setWidgetModel }));
            case DrawerMode.ADD_SHARED_WIDGET:
                return (_jsx(WidgetSelector, { sharedWidgets: insightsData === null || insightsData === void 0 ? void 0 : insightsData.sharedWidgets, dashboardSettings: insightsData === null || insightsData === void 0 ? void 0 : insightsData.dashboardSettings, closeAddWidgetDialog: closeAddWidgetDialog, dashboardName: dashboardName }));
            case DrawerMode.ADD_NEW_PAGE:
                return (_jsx(SubPageCreator, { closeAddWidgetDialog: closeAddWidgetDialog, sharedWidgets: insightsData === null || insightsData === void 0 ? void 0 : insightsData.sharedWidgets }));
            default:
                return null;
        }
    };
    const cloneDashboard = () => {
        setPredefinedDashboardName(`${dashboardName} (copy)`);
        setPredefinedWidgetSettings(insightsData === null || insightsData === void 0 ? void 0 : insightsData.dashboardSettings);
        setsideDrawerOpen(true);
        setSideDrawerMode(DrawerMode.ADD_NEW_PAGE);
        handleMenuClose();
    };
    const deleteDashboard = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield dashboardDeleter.mutateAsync({
                dashboardName: dashboardName,
                dashboardId: insightsData === null || insightsData === void 0 ? void 0 : insightsData.dashboardId,
            });
            if (res.error) {
                showSnackbar('Failed to delete dashboard', 'error');
                return;
            }
            navigate('/insights');
        }
        catch (e) {
            showSnackbar(`Failed to delete dashboard: ${e.message}`, 'error');
        }
    });
    const deleteWidget = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield deleter.mutateAsync({ id: widgetIdTobeDeleted });
        if (res.error) {
            showSnackbar('Failed to delete widget', 'error');
            return;
        }
        setBoxes((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
        setCharts((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
        setTables((prev) => prev.filter((e) => e.id !== widgetIdTobeDeleted));
        setDeleteConfirmDialogOpen(false);
    });
    const closeAddWidgetDialog = () => {
        setsideDrawerOpen(false);
    };
    const renderWidget = (widget) => {
        var _a, _b, _c;
        const widgetGroup = widget.widgetGroup.replace(/-.*/, '');
        switch (widgetGroup) {
            case WidgetGroup.BOX:
                return (_jsx(BoxWidget, { id: widget.id, displayName: (_a = labels.getLabel('dashboard', widget.labelId)) !== null && _a !== void 0 ? _a : widget.displayName, value: widget.value }));
            case WidgetGroup.CHART:
                return (_jsx(ChartWidget, { id: widget.id, displayName: (_b = labels.getLabel('dashboard', widget.labelId)) !== null && _b !== void 0 ? _b : widget.displayName, data: widget.value }));
            case WidgetGroup.TABLE:
                return (_jsx(TableWidget, { data: widget.data, formatters: widget.formatters, id: widget.id, displayName: (_c = labels.getLabel('dashboard', widget.labelId)) !== null && _c !== void 0 ? _c : widget.displayName }));
            default:
                return null;
        }
    };
    return (_jsxs(Box, { p: 2, sx: { width: '100%', overflowY: 'scroll' }, children: [_jsxs(Drawer, { anchor: "right", variant: "persistent", open: sideDrawerOpen, onClose: closeAddWidgetDialog, sx: {
                    '& .MuiDrawer-paper': {
                        marginTop: '64px',
                        maxWidth: sidebarWidth,
                    },
                }, children: [_jsxs(Tabs, { value: sideDrawerMode, onChange: (e, value) => {
                            setSideDrawerMode(value);
                        }, children: [_jsx(Tab, { label: "Create widget", value: DrawerMode.CREATE_WIDGET }), _jsx(Tab, { label: "Config widgets", value: DrawerMode.ADD_SHARED_WIDGET }), _jsx(Tab, { label: "Add new page", value: DrawerMode.ADD_NEW_PAGE })] }), renderDrawerContent(sideDrawerMode), sideDrawerMode === DrawerMode.CREATE_WIDGET && (_jsx(Box, { sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            borderBottom: '1px solid #e0e0e0',
                            position: 'fixed',
                            bottom: '0',
                            width: '100%',
                            background: 'inherit',
                        }, children: _jsxs(Box, { children: [_jsx(Button, { variant: "outlined", onClick: () => closeAddWidgetDialog(), children: "Close" }), _jsx(Button, { variant: "contained", sx: { marginLeft: '10px' }, onClick: () => {
                                        saveWidget();
                                        closeAddWidgetDialog();
                                    }, children: "Save" })] }) }))] }), _jsxs(Box, { sx: {
                    width: sideDrawerOpen ? `calc(100% - ${sidebarWidth}px)` : '100%',
                }, children: [_jsxs(Box, { display: "flex", children: [_jsx(Typography, { variant: "h5", children: (settingsData === null || settingsData === void 0 ? void 0 : settingsData.company)
                                    ? `${settingsData === null || settingsData === void 0 ? void 0 : settingsData.company} ${(_c = ((_b = insightsData === null || insightsData === void 0 ? void 0 : insightsData.dashboardLabel) !== null && _b !== void 0 ? _b : pageLabel)) === null || _c === void 0 ? void 0 : _c.toLowerCase()}`
                                    : ((_d = insightsData === null || insightsData === void 0 ? void 0 : insightsData.dashboardLabel) !== null && _d !== void 0 ? _d : pageLabel) }), _jsxs(Box, { sx: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px',
                                    overflowX: 'scroll',
                                }, children: [(_e = Object.entries(filteredFilters)) === null || _e === void 0 ? void 0 : _e.map(([k, v]) => {
                                        var _a, _b, _c, _d, _e, _f, _g;
                                        if (v.type === 'date') {
                                            return (_jsx("div", { style: { width: '100%' }, children: _jsx(BasicDatePicker, { label: v.label, value: filteredValues[k], setValue: (e) => {
                                                        setFilteredValues(Object.assign(Object.assign({}, filteredValues), { [k]: e }));
                                                        updateUrlAndQuery(k, e, true);
                                                    }, sx: { ml: 1, width: 200 } }, v.label) }));
                                        }
                                        else if (v.type === 'multi-select') {
                                            if (k === 'agent' &&
                                                (settingsData === null || settingsData === void 0 ? void 0 : settingsData.dashboard_filter_by_agent) !== 'True')
                                                return null;
                                            if (!((_a = insightsData === null || insightsData === void 0 ? void 0 : insightsData.filterValues) === null || _a === void 0 ? void 0 : _a[v.optionsKey]) ||
                                                ((_b = insightsData === null || insightsData === void 0 ? void 0 : insightsData.filterValues) === null || _b === void 0 ? void 0 : _b[v.optionsKey].length) === 0) {
                                                return null;
                                            }
                                            return (_jsx(EnhancedSelect, { label: v.label, multiple: true, enableSearch: true, options: (_d = (_c = insightsData === null || insightsData === void 0 ? void 0 : insightsData.filterValues) === null || _c === void 0 ? void 0 : _c[v.optionsKey]) !== null && _d !== void 0 ? _d : [], value: ((_e = filteredValues[k]) === null || _e === void 0 ? void 0 : _e.length)
                                                    ? filteredValues[k]
                                                    : (_f = insightsData.filterValues) === null || _f === void 0 ? void 0 : _f[v.optionsKey], onChange: (values) => {
                                                    var _a;
                                                    clearUrlAndQuery(k);
                                                    if (values.length ===
                                                        ((_a = insightsData.filterValues) === null || _a === void 0 ? void 0 : _a[v.optionsKey].length)) {
                                                        setFilteredValues(Object.assign(Object.assign({}, filteredValues), { [k]: [] }));
                                                        return;
                                                    }
                                                    setFilteredValues(Object.assign(Object.assign({}, filteredValues), { [k]: values }));
                                                    values.forEach((value) => {
                                                        let valueToUpdate = value;
                                                        if (typeof value === 'object') {
                                                            valueToUpdate = value.id;
                                                        }
                                                        updateUrlAndQuery(k, valueToUpdate);
                                                    });
                                                }, sx: { width: 135, ml: 1 } }, v.label));
                                        }
                                        else if (v.type === 'select') {
                                            return (_jsx("div", { children: _jsxs(FormControl, { sx: Object.assign({ ml: 1, width: 135 }, v.sx), children: [_jsx(InputLabel, { children: v.label }), _jsx(Select, { value: filteredValues[k], label: v.label, onChange: (e) => {
                                                                setFilteredValues(Object.assign(Object.assign({}, filteredValues), { [k]: e.target.value }));
                                                                updateUrlAndQuery(k, e.target.value, true);
                                                            }, sx: {
                                                                '.MuiSelect-select': {
                                                                    py: 0.75,
                                                                    px: 1.5,
                                                                },
                                                            }, children: ((_g = v.options) !== null && _g !== void 0 ? _g : []).map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) })] }, v.label) }, v.label));
                                        }
                                        return null;
                                    }), _jsxs(Dialog, { style: { zIndex: 9999 }, open: deleteConfirmDialogOpen, onClose: () => setDeleteConfirmDialogOpen(false), children: [_jsx(DialogTitle, { children: 'Do you want to delete this widget?' }), _jsx(DialogContent, { children: _jsx(DialogContentText, { children: 'This action cannot be undone.' }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: () => setDeleteConfirmDialogOpen(false), children: "Disagree" }), _jsx(Button, { onClick: deleteWidget, autoFocus: true, children: "Agree" })] })] })] })] }), _jsx(Box, { children: (isFinatryAdmin || isAccountAdmin) && (_jsxs(Box, { sx: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                gap: 1,
                                mt: 1,
                                mb: 1,
                            }, children: [_jsxs(Box, { children: [renderViewChip(Roles.ACCOUNT_ADMIN), renderViewChip(Roles.PRODUCER), renderViewChip(Roles.DATA_SPECIALIST)] }), _jsxs(Box, { children: [showSaveLayoutButton && (_jsxs(Button, { variant: "contained", onClick: saveLayout, children: [' ', "Save Layout", ' '] })), _jsx(Button, { variant: "contained", sx: { marginLeft: 1, maxHeight: 4 }, onClick: () => openAddWidgetDialog(DrawerMode.CREATE_WIDGET), children: "Add" }), _jsx(IconButton, { sx: { alignSelf: 'flex-start' }, onClick: handleMenuClick, children: _jsx(MoreVert, {}) }), _jsxs(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleMenuClose, children: [_jsx(MenuItem, { onClick: deleteDashboard, children: "Delete dashboard" }, "Delete"), _jsx(MenuItem, { onClick: cloneDashboard, children: "Create copy" }, "copy")] })] })] })) }), _jsx(Box, { children: isLoading ? (_jsx(LoadingCircle, { isLoading: isLoading })) : (_jsx(_Fragment, { children: _jsx(ResponsiveGridLayout, { onLayoutChange: handleModify, verticalCompact: true, cols: cols, breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }, preventCollision: false, draggableHandle: ".dragHandle", autoSize: true, margin: margin, children: widgetArray === null || widgetArray === void 0 ? void 0 : widgetArray.map((widget, index) => {
                                    var _a, _b;
                                    return (_jsx("div", { className: "reactGridItem", "data-grid": {
                                            x: widget === null || widget === void 0 ? void 0 : widget.x,
                                            y: widget === null || widget === void 0 ? void 0 : widget.y,
                                            w: widget === null || widget === void 0 ? void 0 : widget.w,
                                            h: widget === null || widget === void 0 ? void 0 : widget.h,
                                            i: widget === null || widget === void 0 ? void 0 : widget.i,
                                            id: widget === null || widget === void 0 ? void 0 : widget.id,
                                            minW: 2,
                                            maxW: Infinity,
                                            maxH: Infinity,
                                            isDraggable: true,
                                            isResizable: true,
                                        }, children: widget.i === 'new' ? (_jsx(Box, { sx: {
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: '1px dashed #ccc',
                                                borderRadius: '5px',
                                                height: '100%',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    border: '1px dashed #000',
                                                },
                                            }, onClick: () => openAddWidgetDialog(DrawerMode.CREATE_WIDGET), children: _jsx(Tooltip, { title: "Add new widget", children: _jsx(AddIcon, { sx: {
                                                        '&:hover': {
                                                            color: 'primary.main',
                                                            cursor: 'pointer',
                                                        },
                                                    } }) }) })) : (_jsx(WidgetWrapper, { id: widget.id, sharedWidget: (_a = widget.spec) === null || _a === void 0 ? void 0 : _a.shared, displayName: (_b = labels.getLabel('dashboard', widget.labelId)) !== null && _b !== void 0 ? _b : widget.displayName, onDelete: () => {
                                                setDeleteConfirmDialogOpen(true);
                                                setWidgetIdTobeDeleted(widget.id);
                                            }, onEdit: () => editWidget(widget.id), data: widget.value, type: widget.type, widgetData: widget, onCopy: copyExistingWidget, children: renderWidget(widget) })) }, widget.i));
                                }) }) })) })] })] }));
};
export default Dashboard;
