import { useEffect, useMemo, useState } from 'react';
import API from '@/services/API';
import Statements from '@/services/Statements';
import { useAccountStore } from '@/store';
import DataTransformation from '@/services/DataTransformation';
const Normalizer = DataTransformation;
const useCommonData = (fileType, file) => {
    const { selectedAccount } = useAccountStore();
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const [dbFields, setDbFields] = useState([]);
    const model = fileType === 'report' ? 'reports' : 'statements';
    const { data: _fields, isLoading } = API.getBasicQuery('fields', `model=${model}`, !!model, {
        refetchOnWindowFocus: false,
    });
    useEffect(() => {
        if (mode && !isLoading && _fields.length) {
            const temp = _fields.filter((field) => field.enabled_for[mode]);
            setDbFields(temp);
        }
    }, [mode, _fields]);
    const statements = useMemo(() => new Statements(mode), [mode]);
    // Temp solution
    const fields = useMemo(() => {
        const res = {};
        if (mode && dbFields.length) {
            dbFields.forEach((field) => {
                res[field.key] = field;
                res[field.key].required = field.required_for[mode];
                res[field.key].enabled = field.enabled_for[mode];
                if (field.type === 'date') {
                    res[field.key].normalizer = Normalizer.normalizeDate;
                    res[field.key].formatter = Normalizer.formatDateWithoutUTC;
                }
                else if (field.type === 'currency') {
                    res[field.key].normalizer = Normalizer.normalizeCurrency;
                    res[field.key].formatter = Normalizer.formatCurrency;
                }
                else if (field.type === 'percentage') {
                    res[field.key].normalizer = Normalizer.normalizePercentage;
                    res[field.key].formatter = Normalizer.formatPercentage;
                }
                else if (field.type === 'integer') {
                    res[field.key].normalizer = Normalizer.normalizeInt;
                }
                else if (field.type === 'boolean') {
                    res[field.key].normalizer = Normalizer.normalizeBoolean;
                }
            });
        }
        return res;
    }, [dbFields, mode]);
    const requiredRows = useMemo(() => {
        if (mode && dbFields.length) {
            return dbFields
                .filter((field) => field.required_for[mode])
                .map((field) => field.key);
        }
        else {
            return [];
        }
    }, [fields, mode]);
    // Check if file is excel or csv
    const isExcel = useMemo(() => {
        if (!file) {
            return false;
        }
        return ((file === null || file === void 0 ? void 0 : file.type) === 'application/vnd.ms-excel' ||
            (file === null || file === void 0 ? void 0 : file.type) ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            (file === null || file === void 0 ? void 0 : file.name.split('.').pop()) === 'csv');
    }, [file]);
    return {
        fields,
        requiredRows,
        isExcel,
        statements,
        allFieldKeys: dbFields,
    };
};
export default useCommonData;
