var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, ToggleButton, ToggleButtonGroup, Typography, } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { AgentCommissionsStatusesLabels, CompReportDateFilter, CompReportPrefixConfig, } from 'common/globalTypes';
import MultiSelect from '@/components/molecules/MultiSelect';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import Statements from '@/services/Statements';
import { useAccountStore } from '@/store';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import useSnackbar from '@/contexts/useSnackbar';
dayjs.extend(utc);
var ReportDefaults;
(function (ReportDefaults) {
    ReportDefaults["Prefix"] = "Commissions: start_date - end_date";
})(ReportDefaults || (ReportDefaults = {}));
const CommissionPayoutReportConfig = ({ open, setOpen, refreshData = () => { }, }) => {
    var _a, _b, _c;
    const [startDate, setStartDate] = useState(dayjs.utc().startOf('day'));
    const [endDate, setEndDate] = useState(dayjs.utc().startOf('day'));
    const [agents, setAgents] = useState([]);
    const [agentGroups, setAgentGroups] = useState([]);
    const [compensationTypes, setCompensationTypes] = useState([]);
    const [contactStatuses, setContactStatuses] = useState([]);
    const [contactPayableStatuses, setContactPayableStatuses] = useState([]);
    const [agentCommissionStatuses, setAgentCommissionStatuses] = useState([]);
    const [agentCommissionStatuses2, setAgentCommissionStatuses2] = useState(Object.values(AgentCommissionsStatusesLabels));
    const [loading, setLoading] = useState(false);
    const [isAgentGroupSelectionEnabled, setIsAgentGroupSelectionEnabled] = useState(false);
    const [selectedDateFilter, setSelectedDateFilter] = useState(CompReportDateFilter.ProcessingDate);
    const [selectedReportPrefix, setSelectedReportPrefix] = useState(CompReportPrefixConfig.Default);
    const [reportPrefix, setReportPrefix] = useState(ReportDefaults.Prefix);
    const { selectedAccount } = useAccountStore();
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const statements = new Statements(mode);
    const poster = API.getMutation('saved_reports/groups/gen_commissions_report', 'POST');
    const { data: _agentList, isFetched } = API.getBasicQuery('contacts');
    const { data: _agentGroups, isFetched: _isAgentGroupsFetched } = API.getBasicQuery('contacts/groups');
    const { data: _contactOptions, isFetched: isFetchedContactOptions } = API.getBasicQuery(`contacts/options`);
    const { data: _statementDataOptions, isFetched: isFetchedStatementDataOptions, } = API.getBasicQuery(`statement_data/options`);
    const { showSnackbar } = useSnackbar();
    const agentList = (_a = _agentList === null || _agentList === void 0 ? void 0 : _agentList.data) === null || _a === void 0 ? void 0 : _a.sort((a, b) => Formatter.contact(a, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId }) >
        Formatter.contact(b, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId })
        ? 1
        : -1);
    const agentGroupList = _agentGroups === null || _agentGroups === void 0 ? void 0 : _agentGroups.sort((a, b) => a.name > b.name ? 1 : -1);
    const contactStatusesSet = new Set(_contactOptions === null || _contactOptions === void 0 ? void 0 : _contactOptions.status);
    contactStatusesSet.add('(Blank)');
    const contactStatusesList = Array.from(contactStatusesSet).sort((a, b) => {
        if (a === '(Blank)')
            return -1;
        if (b === '(Blank)')
            return 1;
        return a.localeCompare(b);
    });
    const contactPayableStatusesSet = new Set(_contactOptions === null || _contactOptions === void 0 ? void 0 : _contactOptions.payable_status);
    contactPayableStatusesSet.add('(Blank)');
    const contactPayableStatusesList = Array.from(contactPayableStatusesSet).sort((a, b) => {
        if (a === '(Blank)')
            return -1;
        if (b === '(Blank)')
            return 1;
        return a.localeCompare(b);
    });
    const statementCompensationTypesList = [
        '(Blank)',
        ...(((_b = _statementDataOptions === null || _statementDataOptions === void 0 ? void 0 : _statementDataOptions.compensation_type) === null || _b === void 0 ? void 0 : _b.sort((a, b) => a.localeCompare(b))) || []),
    ];
    const statementAgentCommissionStatusesList = [
        '(Blank)',
        ...(((_c = _statementDataOptions === null || _statementDataOptions === void 0 ? void 0 : _statementDataOptions.agent_commissions_status) === null || _c === void 0 ? void 0 : _c.sort((a, b) => a.localeCompare(b))) || []),
    ];
    const agentValuer = (val) => val.id;
    useEffect(() => {
        if (isFetched) {
            setAgents(agentList.map((agent) => agentValuer(agent)));
        }
        if (_isAgentGroupsFetched) {
            setAgentGroups(agentGroupList.map((agentGroup) => agentValuer(agentGroup)));
        }
        if (isFetchedContactOptions) {
            setContactStatuses(contactStatusesList);
            setContactPayableStatuses(contactPayableStatusesList);
        }
        if (isFetchedStatementDataOptions) {
            setCompensationTypes(statementCompensationTypesList);
            setAgentCommissionStatuses(statementAgentCommissionStatusesList.filter((status) => status.toLowerCase() !== 'paid'));
        }
    }, [
        agentList,
        isFetched,
        agentGroupList,
        _isAgentGroupsFetched,
        isFetchedContactOptions,
        isFetchedStatementDataOptions,
    ]);
    useEffect(() => {
        if (_isAgentGroupsFetched && isAgentGroupSelectionEnabled) {
            const agentGroupContacts = agentGroups.flatMap((agentGroupId) => {
                const agentGroup = agentGroupList.find((group) => group.id === agentGroupId);
                return agentGroup && Array.isArray(agentGroup.contacts)
                    ? agentGroup.contacts.map((contact) => contact.id)
                    : [];
            });
            setAgents(agentGroupContacts);
        }
        else if (isFetched) {
            setAgents(agentList.map((agent) => agentValuer(agent)));
        }
    }, [
        agentGroupList,
        _isAgentGroupsFetched,
        agentGroups,
        isAgentGroupSelectionEnabled,
    ]);
    const genReport = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        try {
            const response = yield poster.mutateAsync({
                start_date: startDate,
                end_date: endDate,
                dateFilter: selectedDateFilter,
                reportPrefix: reportPrefix === ReportDefaults.Prefix ? '' : reportPrefix,
                contacts: agents,
                compensation_types: compensationTypes,
                contact_statuses: contactStatuses,
                contact_payable_statuses: contactPayableStatuses,
                agent_commission_statuses: agentCommissionStatuses,
                agent_commission_statuses2: agentCommissionStatuses2,
                headers: JSON.stringify(Object.values(statements.fields)),
            });
            if (((_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.reports) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                showSnackbar(`${(_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.reports) === null || _d === void 0 ? void 0 : _d.length} reports generated`, 'info');
                refreshData();
            }
            else {
                showSnackbar('No reports generated', 'error');
            }
        }
        catch (error) {
            showSnackbar((error === null || error === void 0 ? void 0 : error.message) || error, 'error');
        }
    });
    return (_jsxs(Dialog, { open: open, onClose: () => setOpen(false), children: [_jsx(DialogTitle, { children: "Generate agent commission payout report" }), _jsxs(DialogContent, { sx: { width: 450 }, children: [_jsxs(Box, { sx: {
                            p: 1,
                            mt: 1,
                            borderStyle: 'solid',
                            borderColor: 'silver',
                            borderWidth: 1,
                            borderRadius: 4,
                            display: 'inline-block',
                            width: '100%',
                            backgroundColor: '#2196f30a',
                        }, children: [_jsx(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5, mb: 0.5 }, children: _jsx(Typography, { variant: "body2", children: "Report settings" }) }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'column' }, children: [_jsxs(ToggleButtonGroup, { sx: { height: '20px' }, value: selectedReportPrefix, exclusive: true, onChange: (event, reportPrefix) => {
                                            setSelectedReportPrefix(reportPrefix);
                                            if (reportPrefix === CompReportPrefixConfig.Default) {
                                                setReportPrefix(ReportDefaults.Prefix);
                                            }
                                        }, color: "primary", children: [_jsx(ToggleButton, { value: CompReportPrefixConfig.Default, sx: {
                                                    borderRadius: '12px',
                                                }, children: "Default prefix" }), _jsx(ToggleButton, { value: CompReportPrefixConfig.Custom, sx: {
                                                    borderRadius: '12px',
                                                }, children: "Custom prefix" })] }), _jsx(Box, { sx: { mt: 2 }, children: _jsx(TextField, { label: "Report prefix", value: reportPrefix, onChange: (event) => setReportPrefix(event.target.value), fullWidth: true, disabled: selectedReportPrefix === CompReportPrefixConfig.Default }) })] })] }), _jsxs(Box, { sx: {
                            p: 1,
                            mt: 1,
                            borderStyle: 'solid',
                            borderColor: 'silver',
                            borderWidth: 1,
                            borderRadius: 4,
                            display: 'inline-block',
                            width: '100%',
                            backgroundColor: '#2196f30a',
                        }, children: [_jsx(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5, mb: 0.5 }, children: _jsx(Typography, { variant: "body2", children: "Date filters" }) }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'column' }, children: [_jsxs(ToggleButtonGroup, { sx: { height: '20px' }, value: selectedDateFilter, exclusive: true, onChange: (event, selectedDateFilter) => {
                                            setSelectedDateFilter(selectedDateFilter);
                                        }, color: "primary", children: [_jsx(ToggleButton, { value: CompReportDateFilter.ProcessingDate, sx: {
                                                    borderRadius: '12px',
                                                }, children: "Processing date" }), _jsx(ToggleButton, { value: CompReportDateFilter.PaymentDate, sx: {
                                                    borderRadius: '12px',
                                                }, children: "Payment date" })] }), _jsx(Box, { sx: { mt: 2 }, children: _jsx(BasicDateRangePicker, { range: {
                                                startDate: startDate,
                                                startDateLabel: selectedDateFilter === CompReportDateFilter.ProcessingDate
                                                    ? 'Processing date start'
                                                    : 'Payment date start',
                                                endDate: endDate,
                                                endDateLabel: selectedDateFilter === CompReportDateFilter.ProcessingDate
                                                    ? 'Processing date end'
                                                    : 'Payment date end',
                                            }, onChange: (range) => {
                                                setStartDate(range.startDate);
                                                setEndDate(range.endDate);
                                            }, justify: "left" }) })] })] }), _jsxs(Box, { sx: {
                            p: 1,
                            mt: 1,
                            borderStyle: 'solid',
                            borderColor: 'silver',
                            borderWidth: 1,
                            borderRadius: 4,
                            display: 'inline-block',
                            width: '100%',
                            backgroundColor: '#2196f30a',
                        }, children: [_jsx(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5, mb: 0.5 }, children: _jsx(Typography, { variant: "body2", children: "Agent filters" }) }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'column' }, children: [_jsxs(ToggleButtonGroup, { sx: { height: '20px' }, value: isAgentGroupSelectionEnabled ? 'agentGroups' : 'agents', exclusive: true, onChange: (event, newSelectionType) => {
                                            setIsAgentGroupSelectionEnabled(newSelectionType === 'agentGroups');
                                        }, color: "primary", children: [_jsx(ToggleButton, { value: "agents", sx: {
                                                    borderRadius: '12px',
                                                }, children: "Agents" }), _jsx(ToggleButton, { value: "agentGroups", sx: {
                                                    borderRadius: '12px',
                                                }, children: "Agent groups" })] }), _jsx(Box, { sx: { mt: 1.5 }, children: isAgentGroupSelectionEnabled ? (_jsx(MultiSelect, { label: "Agent group(s)", values: agentGroupList, formatter: (contactGroup) => contactGroup.name, valuer: agentValuer, selectedValues: agentGroups, setSelectedValues: setAgentGroups, sx: { width: '100%' }, enableSearch: true })) : (_jsx(MultiSelect, { label: "Agent(s)", values: agentList, formatter: (o) => Formatter.contact(o, {
                                                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                            }), valuer: agentValuer, selectedValues: agents, setSelectedValues: setAgents, sx: { width: '100%' }, enableSearch: true, paginate: true })) }), _jsx(Box, { sx: { mt: 1.5 }, children: _jsx(MultiSelect, { label: "Agent status", values: contactStatusesList, selectedValues: contactStatuses, setSelectedValues: setContactStatuses, sx: { width: '100%' } }) }), _jsx(Box, { sx: { mt: 1.5 }, children: _jsx(MultiSelect, { label: "Agent payable status", values: contactPayableStatusesList, selectedValues: contactPayableStatuses, setSelectedValues: setContactPayableStatuses, sx: { width: '100%' } }) })] })] }), _jsxs(Box, { sx: {
                            mt: 1,
                            p: 1,
                            borderStyle: 'solid',
                            borderColor: 'silver',
                            borderWidth: 1,
                            borderRadius: 4,
                            display: 'inline-block',
                            width: '100%',
                            backgroundColor: '#2196f30a',
                        }, children: [_jsx(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5, mb: 0.5 }, children: _jsx(Typography, { variant: "body2", children: "Commission filters" }) }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'column' }, children: [_jsx(Box, { sx: { mt: 1.5 }, children: _jsx(MultiSelect, { label: "Compensation type", values: statementCompensationTypesList, selectedValues: compensationTypes, setSelectedValues: setCompensationTypes, sx: { width: '100%' } }) }), _jsx(Box, { sx: { mt: 1.5 }, children: _jsx(MultiSelect, { label: "Payout status", values: statementAgentCommissionStatusesList, selectedValues: agentCommissionStatuses, setSelectedValues: setAgentCommissionStatuses, sx: { width: '100%' } }) }), _jsx(Box, { sx: { mt: 1.5 }, children: _jsx(MultiSelect, { label: "Per agent payout status", values: Object.values(AgentCommissionsStatusesLabels), selectedValues: agentCommissionStatuses2, setSelectedValues: setAgentCommissionStatuses2, sx: { width: '100%' } }) })] })] })] }), _jsxs(DialogActions, { sx: { pt: 0 }, children: [_jsx(Button, { onClick: () => setOpen(false), children: "Cancel" }), _jsx(LoadingButton, { variant: "contained", loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setLoading(true);
                            yield genReport();
                            setLoading(false);
                            setOpen(false);
                        }), children: "Generate" })] })] }));
};
export default CommissionPayoutReportConfig;
