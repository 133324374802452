var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { javascript } from '@codemirror/lang-javascript';
import { ContentCopy } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, IconButton, InputLabel, Link, MenuItem, Select, Tooltip, Typography, } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import useBeforeUnloadPage from 'contexts/useBeforeunloadPage';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import { clone, isEqual } from 'lodash-es';
import { memo, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { Link as RouterLink, useNavigate, useSearchParams, } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import { BasicDialog } from '@/common';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import DynamicSelect from '@/components/DataForm/DynamicSelect';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import FieldMatcher from '@/components/molecules/FieldMatcher';
import RateScheduleAnnual from '@/components/molecules/RateScheduleAnnual';
import { UIStateContext } from '@/contexts/UIStateProvider';
import useSnackbar from '@/contexts/useSnackbar';
import { useUserInfo } from '@/hooks/useUserInfo';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { hasAccess } from '@/services/helpers';
import { useRoleStore } from '@/store';
import { FieldTypes } from '@/types';
import DraggableSelect from './DraggableSelect';
import FieldComponent from './FieldComponent';
import FieldRow from './FieldRow';
import NullCheckbox from './NullCheckbox';
const DataForm = ({ dataDesc = {}, fields, newData, setNewData, oldData, validateData = () => true, onCancel, onSave, onDelete, formModeOnly, embed = false, readOnly = false, fetchOnFieldChange = false, extraActions = [], currentData = {}, }) => {
    var _a;
    const [formState, setFormState] = useState({});
    const [dynamicSelects, setDynamicSelects] = useState({});
    const [showDelConfirm, setShowDelConfirm] = useState(false);
    const [_searchParams, setSearchParams] = useSearchParams({});
    const [loading, setLoading] = useState(false);
    const { data: { fintaryAdmin } = {} } = useUserInfo();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [dirty, setDirty] = useState(false);
    const { userRole } = useRoleStore();
    const dirtyFn = useCallback(() => {
        const isChange = !isEqual(newData, oldData);
        setDirty(isChange);
        return isChange;
    }, [newData, oldData]);
    useBeforeUnload(dirtyFn, 'You have unsaved changes, are you sure?');
    useBeforeUnloadPage(dirty, 'You have unsaved changes, are you sure?');
    const { role: [role], } = useContext(UIStateContext);
    const dynamicSelectsPoster = API.getMutation('dynamic_selects', 'POST', {
        gcTime: 1,
    });
    const formattedData = newData;
    const fieldIds = fields.flat().map((field) => field.id);
    const dynamicSelectVals = useMemo(() => fields
        .flat()
        .filter((field) => field.type === 'dynamic-select' || field.table)
        .map((field) => ({
        table: field.table,
        queryParamValue: fieldIds.includes(field.queryParamValue)
            ? formattedData[field.queryParamValue]
            : field.queryParamValue,
        queryParamName: field.queryParamName,
    })), [formattedData, JSON.stringify(fieldIds)]);
    useEffect(() => {
        if (dynamicSelectVals.length > 0)
            getDynamicSelects(dynamicSelectVals);
    }, [JSON.stringify(dynamicSelectVals)]);
    useEffect(() => {
        fields.flat().forEach((field) => {
            var _a, _b;
            if (field.type === FieldTypes.DYNAMIC_SELECT &&
                Array.isArray(formattedData[field.id]) &&
                ((_b = (_a = formattedData[field.id]) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id) &&
                field.id !== 'parent_relationships') {
                setNewData(Object.assign(Object.assign({}, formattedData), { [field.id]: formattedData[field.id].map((e) => e.id) }));
            }
        });
    }, [dynamicSelects]);
    useEffect(() => {
        return () => {
            dynamicSelectsPoster.abort();
        };
    }, []);
    const getDynamicSelects = (dynamicSelectVals) => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield dynamicSelectsPoster
            .mutateAsync(dynamicSelectVals)
            .catch((_err) => { });
        if (Array.isArray(data) && data.length > 0) {
            data.forEach((field) => {
                setDynamicSelects((prev) => (Object.assign(Object.assign({}, prev), field)));
            });
        }
    });
    const getFieldElement = useCallback((field) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
        if (field.type === FieldTypes.DIVIDER) {
            return field.access === 'admin' ? (_jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mb: 1,
                }, children: ["\uD83D\uDD12", _jsx(Box, { sx: { width: '100%', ml: 1 }, children: _jsx(Divider, {}) })] }, field.id)) : (_jsx(Divider, { sx: { mt: 1, mb: 1 } }, field.id));
        }
        if (field.type === FieldTypes.HEADING) {
            return (_jsx(Box, { sx: { mb: 0.5 }, children: _jsx(Typography, { variant: "h6", sx: { fontWeight: 400, fontSize: 18 }, children: field.label }) }, (_a = field.id) !== null && _a !== void 0 ? _a : field.label));
        }
        if (field.type === FieldTypes.SUB_HEADING) {
            return (_jsx(Box, { sx: { mb: 1 }, children: _jsx(Typography, { variant: "h6", sx: { fontWeight: 400, fontSize: 14, whiteSpace: 'nowrap' }, children: field.label }) }, (_b = field.id) !== null && _b !== void 0 ? _b : field.label));
        }
        if (field.type === FieldTypes.BOOLEAN) {
            return (_jsxs(FieldRow, { children: [_jsx(Box, { sx: { mb: 0, ml: 1 }, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { disabled: readOnly ||
                                    (typeof field.readOnly === 'function'
                                        ? field.readOnly(newData)
                                        : field.readOnly) ||
                                    (formattedData === null || formattedData === void 0 ? void 0 : formattedData[`${field.id}-null`]), checked: (_c = formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) !== null && _c !== void 0 ? _c : false, onChange: () => setNewData(Object.assign(Object.assign({}, formattedData), { [field.id]: !(formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) })) }), label: field.label }) }), field.enableNullCheckbox && (_jsx(NullCheckbox, { readOnly: readOnly, field: field, formattedData: formattedData, setNewData: setNewData }))] }, field.id));
        }
        if (field.type === FieldTypes.DRAGGABLE_SELECT) {
            return (_jsx(FieldRow, { children: _jsx(DraggableSelect, { data: field, field: newData, setter: setNewData }) }, field.id));
        }
        if (field.type === FieldTypes.SELECT) {
            return (_jsxs(FieldRow, { children: [_jsx(Tooltip, { title: field.tip, placement: "right", children: _jsxs(FormControl, { sx: { width: '100%' }, required: field.required, disabled: readOnly ||
                                (typeof field.readOnly === 'function'
                                    ? field.readOnly(newData)
                                    : field.readOnly), children: [_jsx(InputLabel, { id: `${field.id}-label`, children: field.label }), _jsx(Select, { labelId: `${field.id}-label`, id: field.id, open: (formState === null || formState === void 0 ? void 0 : formState[field.id]) || false, onClose: () => setFormState(Object.assign(Object.assign({}, formState), { [field.id]: false })), endAdornment: typeof field.endAdornment === 'function'
                                        ? field.endAdornment(formattedData, field, setNewData)
                                        : field.endAdornment, multiple: (_d = field.multiple) !== null && _d !== void 0 ? _d : false, onOpen: () => setFormState(Object.assign(Object.assign({}, formState), { [field.id]: true })), value: Array.isArray(formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id])
                                        ? formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]
                                        : field.multiple
                                            ? []
                                            : ((_f = (_e = formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) !== null && _e !== void 0 ? _e : field.default) !== null && _f !== void 0 ? _f : ''), label: field.label, onChange: (e) => {
                                        setNewData(Object.assign(Object.assign({}, formattedData), { [field.id]: e.target.value }));
                                    }, renderValue: (selected) => {
                                        var _a, _b;
                                        return (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: field.multiple
                                                ? selected === null || selected === void 0 ? void 0 : selected.map((value) => (_jsx(Chip, { label: typeof field.optionFormatter === 'function'
                                                        ? field.optionFormatter(value)
                                                        : value, clickable: typeof field.linker === 'function', component: typeof field.linker === 'function' ? 'a' : 'div', href: typeof field.linker === 'function'
                                                        ? field.linker(value)
                                                        : undefined }, typeof field.optionValuer === 'function'
                                                    ? field.optionValuer(value)
                                                    : value)))
                                                : ((_b = (_a = field.options.find((option) => option.id === selected)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : selected) }));
                                    }, children: (_j = (_h = (_g = field.options) === null || _g === void 0 ? void 0 : _g.sort((a, b) => field.sort === false ? undefined : a < b ? -1 : 1)) === null || _h === void 0 ? void 0 : _h.filter((option) => option !== null)) === null || _j === void 0 ? void 0 : _j.map((option) => typeof option === 'object' ? (_jsx(MenuItem, { value: option.id, children: option.label === '' ? (_jsx("span", { children: "\u00A0" })) : (option.label) }, option.id)) : (_jsx(MenuItem, { value: option, children: option === '' ? _jsx("span", { children: "\u00A0" }) : option }, option))) })] }, field.id) }), field.enableNullCheckbox && (_jsx(NullCheckbox, { readOnly: readOnly, field: field, formattedData: formattedData, setNewData: setNewData }))] }, field.id));
        }
        if (field.type === FieldTypes.DYNAMIC_SELECT) {
            if (field.id === 'contacts_agent_commission_schedule_profiles' ||
                field.id === 'contacts_agent_commission_schedule_profiles_sets' ||
                field.id === 'contacts_agent_commission_profiles_names' ||
                field.id === 'parent_relationships')
                return field.render(field, newData, setNewData, (_l = (_k = dynamicSelects[field.table]) === null || _k === void 0 ? void 0 : _k.data) !== null && _l !== void 0 ? _l : dynamicSelects[field.table]);
            return (_jsx(FieldRow, { children: _jsx(Tooltip, { title: field.tip, placement: "right", children: _jsxs(_Fragment, { children: [_jsx(DynamicSelect, { field: field, readOnly: readOnly, formattedData: formattedData, setNewData: setNewData, 
                                // @ts-ignore
                                formState: formState, 
                                // @ts-ignore
                                endAdornment: field.endAdornment, setFormState: setFormState, dynamicSelects: dynamicSelects, disabled: formattedData === null || formattedData === void 0 ? void 0 : formattedData[`${field.id}-null`], fullWidth: true, onChange: field.onChange }), field.enableNullCheckbox && (_jsx(NullCheckbox, { readOnly: readOnly, field: field, formattedData: formattedData, setNewData: setNewData }))] }) }) }, field.id));
        }
        if (field.type === FieldTypes.DATE_RANGE) {
            return (_jsxs(FieldRow, { children: [_jsx(BasicDateRangePicker, { range: {
                            startDate: (formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.startDateId])
                                ? dayjs.utc(new Date(formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.startDateId]))
                                : null,
                            endDate: (formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.endDateId])
                                ? dayjs.utc(new Date(formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.endDateId]))
                                : null,
                            startDateLabel: field.startDateLabel || 'Start Date',
                            endDateLabel: field.endDateLabel || 'End Date',
                            disableStartDatePicker: readOnly ||
                                (typeof field.readOnly === 'function'
                                    ? field.readOnly(newData)
                                    : field.readOnly) ||
                                (formattedData === null || formattedData === void 0 ? void 0 : formattedData[`${field.startDateId}-null`]),
                            disableEndDatePicker: readOnly ||
                                (typeof field.readOnly === 'function'
                                    ? field.readOnly(newData)
                                    : field.readOnly) ||
                                (formattedData === null || formattedData === void 0 ? void 0 : formattedData[`${field.endDateId}-null`]),
                            endAdornmentStartDate: typeof field.endAdornment === 'function'
                                ? field === null || field === void 0 ? void 0 : field.endAdornment(formattedData, field, setNewData)
                                : field.endAdornment,
                            endAdornmentEndDate: typeof field.endAdornment === 'function'
                                ? field === null || field === void 0 ? void 0 : field.endAdornment(formattedData, field, setNewData)
                                : field.endAdornment,
                        }, onChange: ({ startDate, endDate }) => {
                            setNewData(Object.assign(Object.assign({}, formattedData), { [field.startDateId]: startDate
                                    ? dayjs.isDayjs(startDate)
                                        ? startDate.toDate()
                                        : new Date(startDate)
                                    : null, [field.endDateId]: endDate
                                    ? dayjs.isDayjs(endDate)
                                        ? endDate.toDate()
                                        : new Date(endDate)
                                    : null }));
                        }, width: 200, mt: 1, my: 1 }), field.enableNullCheckbox && (_jsx(NullCheckbox, { readOnly: readOnly, field: field, formattedData: formattedData, setNewData: setNewData }))] }, `${field.startDateId}-${field.endDateId}`));
        }
        if (field.type === FieldTypes.DATE) {
            return (_jsxs(FieldRow, { children: [_jsx(BasicDatePicker, { label: field.label, value: (formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id])
                            ? new Date(formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id])
                            : null, setValue: (e) => {
                            setNewData(Object.assign(Object.assign({}, formattedData), { [field.id]: e }));
                        }, disabled: readOnly ||
                            (typeof field.readOnly === 'function'
                                ? field.readOnly(newData)
                                : field.readOnly) ||
                            (formattedData === null || formattedData === void 0 ? void 0 : formattedData[`${field.id}-null`]), endAdornment: typeof field.endAdornment === 'function'
                            ? field === null || field === void 0 ? void 0 : field.endAdornment(formattedData, field, setNewData)
                            : field.endAdornment }), field.enableNullCheckbox && (_jsx(NullCheckbox, { readOnly: readOnly, field: field, formattedData: formattedData, setNewData: setNewData }))] }, field.id));
        }
        if (field.type === FieldTypes.FIELD_MATCHER) {
            return (_jsx(FieldMatcher, { value: (_m = newData === null || newData === void 0 ? void 0 : newData[field.id]) !== null && _m !== void 0 ? _m : [], setValue: (e) => {
                    setNewData(Object.assign(Object.assign({}, newData), { [field.id]: e }));
                }, fields: typeof field.fieldGetter === 'function'
                    ? field.fieldGetter(newData)
                    : field.fields }, field.id));
        }
        if (field.type === FieldTypes.RATE_SCHEDULE) {
            return (_jsx(RateScheduleAnnual, { value: (_o = newData === null || newData === void 0 ? void 0 : newData[field.id]) !== null && _o !== void 0 ? _o : [], setValue: (e) => {
                    setNewData(Object.assign(Object.assign({}, newData), { [field.id]: e }));
                }, fields: field.fields }, field.id));
        }
        if (field.type === FieldTypes.CODE) {
            return (_jsx(Box, { sx: { mb: 1 }, children: _jsx(CodeMirror, { height: "350px", value: newData === null || newData === void 0 ? void 0 : newData[field.id], width: "100%", extensions: [javascript({ jsx: true })], onChange: (e) => {
                        setNewData(Object.assign(Object.assign({}, newData), { [field.id]: e }));
                    } }) }, field.id));
        }
        if (field.type === FieldTypes.CUSTOM) {
            return (_jsxs(FieldRow, { children: [field.render(field, newData, setNewData, (_q = (_p = dynamicSelects[field.table]) === null || _p === void 0 ? void 0 : _p.data) !== null && _q !== void 0 ? _q : dynamicSelects[field.table]), field.enableNullCheckbox && (_jsx(NullCheckbox, { readOnly: readOnly, field: field, formattedData: formattedData, setNewData: setNewData }))] }, field.id));
        }
        return (_jsx(FieldComponent, { field: field, newData: newData, formattedData: formattedData, setNewData: setNewData, readOnly: readOnly ||
                (typeof field.readOnly === 'function'
                    ? field.readOnly(newData)
                    : field.readOnly), navigate: navigate, oldData: oldData }, field.id));
    }, [
        dynamicSelects,
        formState,
        formattedData,
        navigate,
        newData,
        oldData,
        setNewData,
    ]);
    if (!newData)
        return null;
    const fieldsWithValidationIssues = fields.filter((field) => (field.validator instanceof Function &&
        !field.validator((newData === null || newData === void 0 ? void 0 : newData[field.id]) || '')) ||
        (field.required && !(newData === null || newData === void 0 ? void 0 : newData[field.id])));
    return (_jsxs(Box, { sx: { mt: 0.75, maxWidth: 1200, flexGrow: 1 }, children: [_jsx(BasicDialog, { title: "Delete record?", positiveLabel: "Delete", open: showDelConfirm, onClose: (val) => __awaiter(void 0, void 0, void 0, function* () {
                    if (val)
                        yield onDelete();
                    setShowDelConfirm(false);
                }), bodyComponent: _jsxs(Alert, { severity: "warning", children: ["Are you sure you want to delete this record?", _jsx("br", {}), (_a = dataDesc.onDeleteText) !== null && _a !== void 0 ? _a : ''] }) }), _jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                }, children: [!!fields.length &&
                        fields.some((field) => field.enableNullCheckbox) && (_jsxs(FieldRow, { children: [_jsx(Typography, { variant: "caption", children: "New value" }), _jsx(Typography, { variant: "caption", sx: { textAlign: 'right' }, children: "Clear data" })] })), fields.map((field) => {
                        if (Array.isArray(field)) {
                            return (_jsx(Box, { sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                }, children: field.map((subField, i) => {
                                    if (!hasAccess(subField.access, userRole, role === 'admin')) {
                                        return null;
                                    }
                                    return (_jsx(Box, { sx: i === field.length - 1
                                            ? { width: '100%' }
                                            : { width: '100%', mr: 1 }, children: getFieldElement(subField) }, subField.id));
                                }) }, field[0].id));
                        }
                        else {
                            if (!hasAccess(field.access, userRole, role === 'admin')) {
                                return null;
                            }
                            return getFieldElement(field);
                        }
                    })] }), _jsxs(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pb: 2,
                    mt: 1,
                }, children: [_jsxs(Box, { children: [newData.id && !formModeOnly && (_jsx(Button, { sx: { ml: 2, color: 'red' }, onClick: () => setShowDelConfirm(true), disabled: readOnly, children: "Delete" })), newData.id && !formModeOnly && dataDesc.copyable && (_jsx(Button, { sx: { ml: 2 }, onClick: () => {
                                    const fieldsToRemove = [
                                        'id',
                                        'str_id',
                                        'sync_id',
                                        'account_id',
                                        'uid',
                                        'created_at',
                                        'created_by',
                                        'updated_at',
                                        'updated_by',
                                        'state',
                                        'document_id',
                                        'import_id',
                                    ];
                                    dataDesc.fields.flat().forEach((field) => {
                                        if (dataDesc.table === 'statement_data' &&
                                            field.id === 'report_data_id')
                                            return;
                                        if (typeof field.readOnly === 'function'
                                            ? field.readOnly(newData)
                                            : field.readOnly) {
                                            fieldsToRemove.push(field.id);
                                        }
                                        else if (dataDesc.table === 'report_data' &&
                                            [
                                                'children_report_data',
                                                'commission_profile',
                                                'commission_profile_id',
                                                'document',
                                                'effective_date',
                                                'history',
                                                'internal_id',
                                                'reconciliation_data',
                                                'parent_data',
                                                'statement_data',
                                            ].includes(field.id)) {
                                            fieldsToRemove.push(field.id);
                                        }
                                    });
                                    const newNewData = clone(newData);
                                    fieldsToRemove.forEach((field) => {
                                        delete newNewData[field];
                                    });
                                    Object.keys(newNewData).forEach((field) => {
                                        if (!dataDesc.fields.flat().find((f) => f.id === field)) {
                                            delete newNewData[field];
                                        }
                                    });
                                    const nameFields = [
                                        'name',
                                        'company_name',
                                        'product_name',
                                        'full_name',
                                        'first_name',
                                    ];
                                    for (const field of nameFields) {
                                        if (newNewData[field]) {
                                            newNewData[field] = `Copy of ${newNewData[field]}`;
                                            break;
                                        }
                                    }
                                    setSearchParams((prev) => {
                                        prev.delete('id');
                                        return prev;
                                    });
                                    setNewData(newNewData);
                                }, disabled: readOnly, children: "Create copy" })), extraActions.map((action, index) => (_jsx(Button, { sx: { ml: 2 }, onClick: () => action.onClick(currentData), variant: action.variant || 'outlined', disabled: readOnly ||
                                    (action.disabled ? action.disabled(currentData) : false), children: action.label }, action.label)))] }), _jsxs(Box, { children: [!formModeOnly && (_jsx(Button, { sx: { mr: 2 }, onClick: onCancel, children: "Cancel" })), _jsx(Tooltip, { title: fieldsWithValidationIssues.length > 0
                                    ? `Missing or invalid fields: ${fieldsWithValidationIssues
                                        .map((field) => `"${field.label}"`)
                                        .join(', ')}`
                                    : '', children: _jsx("span", { children: _jsx(Button, { variant: "contained", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            setLoading(true);
                                            try {
                                                const res = yield onSave();
                                                if ((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.success) === false) {
                                                    showSnackbar(`Error encountered: ${(res === null || res === void 0 ? void 0 : res.message) || (res === null || res === void 0 ? void 0 : res.error)}`, 'error');
                                                }
                                            }
                                            catch (e) {
                                                showSnackbar(`Error encountered: ${
                                                // @ts-ignore
                                                e.message || e.error || 'Unknown error'}`, 'error');
                                            }
                                            finally {
                                                setLoading(false);
                                            }
                                        }), disabled: readOnly ||
                                            fieldsWithValidationIssues.length > 0 ||
                                            !validateData(newData) ||
                                            loading, children: newData.id ||
                                            newData.str_id ||
                                            newData.key ||
                                            dataDesc.saveOnly
                                            ? 'Save'
                                            : 'Add' }) }) })] })] }), !!fintaryAdmin && !embed && Object.keys(oldData).length > 0 && (_jsxs(Box, { sx: {
                    fontSize: 10,
                    pb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }, children: [_jsx(Divider, {}), _jsx(Typography, { variant: "caption", sx: { textAlign: 'center' }, children: "Debug info" }), _jsxs(Typography, { variant: "caption", children: ["Id:", ' ', _jsx(Link, { component: RouterLink, to: `${location.pathname}?id=${oldData.str_id}`, children: oldData.str_id }), _jsx(IconButton, { onClick: (e) => {
                                    copy(oldData.str_id);
                                }, children: _jsx(ContentCopy, { sx: { height: 14, width: 14 } }) }), oldData.id, _jsx(IconButton, { onClick: (e) => {
                                    copy(oldData.id);
                                }, children: _jsx(ContentCopy, { sx: { height: 14, width: 14 } }) })] }), oldData.document_id && (_jsxs(Typography, { variant: "caption", children: ["Document:", ' ', _jsx(Link, { component: RouterLink, to: `/documents?id=${oldData.document_id}`, children: oldData.document_id }), _jsx(IconButton, { onClick: (e) => {
                                    copy(oldData.document_id);
                                }, children: _jsx(ContentCopy, { sx: { height: 14, width: 14 } }) })] })), _jsxs(Typography, { variant: "caption", children: ["Created:", ' ', `${Formatter.dateTime(oldData.created_at)} by ${oldData.created_by}${oldData.created_proxied_by ? ` (via ${oldData.created_proxied_by})` : ''}`] }), _jsxs(Typography, { variant: "caption", children: ["Updated:", ' ', `${Formatter.dateTime(oldData.updated_at)} by ${oldData.updated_by}${oldData.updated_proxied_by ? ` (via ${oldData.updated_proxied_by})` : ''}`] }), oldData.state && (_jsxs(Typography, { variant: "caption", children: ["State: ", oldData.state] }))] }))] }));
};
export default memo(DataForm);
