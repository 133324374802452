var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sha256 } from 'crypto-hash';
import { nanoid } from 'nanoid';
import API from '@/services/API';
import { readFile } from '@/services/helpers';
import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import useUploadStorageFile from '@/contexts/useUploadStorageFile';
import useSnackbar from '@/contexts/useSnackbar';
import { useAccountStore } from '@/store';
const DocumentOverrideFile = ({ open, setOpen, uploadedRow, }) => {
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { selectedAccount } = useAccountStore();
    const { showSnackbar } = useSnackbar();
    const documentsPutter = API.getMutation('documents', 'PUT');
    const { uploadFilesByGenerateSignedUrl } = useUploadStorageFile();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const refreshPage = () => {
        const currentPath = '/documents';
        navigate('/');
        setTimeout(() => {
            navigate(currentPath);
        });
    };
    const uploadFile = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!file || !selectedAccount)
            return null;
        const filename = `${nanoid()}-${file.name}`;
        const filePath = `uploads/${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId}/${filename}`;
        setLoading(true);
        const fileContent = yield readFile(file);
        const fileHash = yield sha256(fileContent);
        const _ft = ((_a = filePath.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || '';
        const fileType = ['xls', 'xlsx', 'csv'].includes(_ft) ? 'spreadsheet' : _ft;
        const param = {
            id: uploadedRow.id,
            override_file_path: filePath,
            override_file_hash: fileHash,
            status: 'new',
            company_str_id: uploadedRow.company_str_id,
            override_filename: file.name,
            str_id: uploadedRow.str_id,
            file_type: fileType,
        };
        const res = yield documentsPutter.mutateAsync(param);
        yield uploadFilesByGenerateSignedUrl([
            {
                endpoint: 'documents',
                endpoint_str_id: uploadedRow.str_id,
                file_preview_type: 'override',
                file: file,
            },
        ]);
        setLoading(false);
        if (res.error) {
            Sentry.captureException(res.error);
            showSnackbar(res.error, 'error');
            return null;
        }
        setOpen(false);
        refreshPage();
    });
    const handleFileChange = (files) => {
        const _file = files[0];
        setFile(_file);
    };
    return (_jsxs("div", { children: [_jsx(Button, { variant: "outlined", color: "primary", onClick: handleClickOpen, children: "Open Form Dialog" }), _jsxs(Dialog, { open: open, onClose: handleClose, children: [_jsx(DialogTitle, { children: "Upload override file" }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                            width: '600px',
                        }, children: _jsx(FactoryFileUpload, { label: "Override file", required: true, onChange: handleFileChange }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(LoadingButton, { loading: loading, disabled: !file, sx: { width: '100px' }, onClick: uploadFile, variant: "contained", children: "Upload" })] })] })] }));
};
export default DocumentOverrideFile;
