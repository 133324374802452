export const arrayToMap = (arr, key) => (arr !== null && arr !== void 0 ? arr : []).reduce((acc, e) => {
    acc[typeof key === 'string' ? e[key] : key(e)] = e;
    return acc;
}, {});
export const enumToSelectOptions = (enumObj) => Object.entries(enumObj).map(([k, v]) => ({
    id: k,
    label: v,
}));
export const splitCustomerName = (name) => {
    if (name.includes(',')) {
        const index = name.indexOf(',');
        const first_name = name.slice(0, index);
        const last_name = name.slice(index + 1);
        return { first_name, last_name };
    }
    const [first_name, ...last_name] = name.split(/\s+/g);
    return {
        first_name,
        last_name: last_name.join(' '),
    };
};
