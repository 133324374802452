var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, } from '@mui/material';
import Formatter from 'common/Formatter';
import { useEffect, useMemo, useState } from 'react';
import { FilterSelect } from '@/common';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useAccountStore } from '@/store';
import { DocumentTypeCreatedType, DocumentTypesPriority, PriorityList, } from './enum';
const ActionModal = ({ open, handleCancel, rowData, companies, owners, processors, mappings, }) => {
    const [uploading, setUploading] = useState(false);
    const [formData, setFormData] = useState({
        carrier_name: '',
        paying_entity: '',
        owner: '',
        mapping_id: '',
        status: 'draft',
        notes: '',
        priority: DocumentTypesPriority.medium,
        processor_str_ids: [],
    });
    const [selectedProcessors, setSelectedProcessors] = useState([]);
    const { selectedAccount } = useAccountStore();
    const { showSnackbar } = useSnackbar();
    const statusList = useMemo(() => [
        {
            label: 'Draft',
            value: 'draft',
        },
        {
            label: 'In review',
            value: 'in_review',
        },
        {
            label: 'Need update',
            value: 'need_update',
        },
        {
            label: 'Approved',
            value: 'approved',
        },
    ], []);
    const documentProfilePoster = API.getMutation('document_profiles', 'POST');
    const documentProfilePutter = API.getMutation('document_profiles', 'PUT');
    useEffect(() => {
        if (rowData) {
            const { name, carrier_name, paying_entity, owner, mapping_id, status, notes, priority, processor_str_ids, } = rowData;
            setFormData({
                name,
                carrier_name,
                paying_entity,
                owner,
                mapping_id,
                status,
                notes,
                priority,
                processor_str_ids,
            });
            if (processor_str_ids && processor_str_ids.length) {
                const list = processors.filter((item) => processor_str_ids.includes(item.str_id));
                const res = list.map((item) => {
                    return {
                        label: item.name,
                        value: item.str_id,
                        data: item,
                    };
                });
                setSelectedProcessors(res);
            }
        }
    }, [rowData]);
    useEffect(() => {
        if (selectedProcessors.length) {
            const list = selectedProcessors.map((val) => val.value);
            onFormChange('processor_str_ids', list);
        }
    }, [selectedProcessors]);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setUploading(true);
        // if has row data, then it's an update
        const api = rowData ? documentProfilePutter : documentProfilePoster;
        const str_id = rowData ? rowData.str_id : undefined;
        const id = rowData ? rowData.id : undefined;
        const poster = Object.assign(Object.assign({}, formData), { paying_entity: formData.carrier_name, create_type: DocumentTypeCreatedType.manully });
        const body = Object.assign(Object.assign({}, poster), { str_id,
            id, document_str_ids: rowData.document_str_ids, create_type: rowData.create_type });
        try {
            const result = yield api.mutateAsync(rowData ? body : poster);
            setUploading(false);
            if (result.error) {
                showSnackbar(result.error, 'error');
                return;
            }
            if (result) {
                handleCancel();
            }
        }
        catch (error) {
            setUploading(false);
        }
    });
    const onFormChange = (key, value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
    };
    return (_jsxs(Dialog, { open: open, maxWidth: "lg", fullWidth: true, sx: { background: 'transparent', p: 1 }, onClose: handleCancel, disableEscapeKeyDown: true, children: [_jsx(DialogTitle, { children: _jsx(Box, { className: "flex items-center", children: "Document profile" }) }), _jsx(IconButton, { className: "group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600", onClick: handleCancel, children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                    p: 0,
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                }, children: _jsxs(Box, { sx: { width: '100%', height: '600px', p: 4 }, children: [_jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: _jsx(TextField, { label: "Name", variant: "outlined", multiline: true, minRows: 1, value: formData.name, onChange: (e) => {
                                    onFormChange('name', e.target.value);
                                } }) }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Carrier" }), _jsx(Select, { id: "select-sheet", value: formData.carrier_name, label: "Carrier", disabled: true, onChange: (e) => {
                                        onFormChange('carrier_name', e.target.value);
                                    }, children: (companies || []).map((c) => (_jsx(MenuItem, { value: c.str_id, children: c.company_name }, c.str_id))) })] }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Owner" }), _jsx(Select, { id: "select-sheet", value: formData.owner, label: "Owner", onChange: (e) => {
                                        onFormChange('owner', e.target.value);
                                    }, children: (owners || []).map((c) => (_jsx(MenuItem, { value: c.uid, children: _jsxs(Box, { sx: {
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: 1,
                                            }, children: [_jsx(Box, { sx: { flex: 1 }, children: Formatter.contact(c, {
                                                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                    }) }), _jsx(Box, { sx: { color: '#666' }, children: c.email })] }) }, c.uid))) })] }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Status" }), _jsx(Select, { id: "select-sheet", value: formData.status, label: "Status", onChange: (e) => {
                                        onFormChange('status', e.target.value);
                                    }, children: statusList.map((item) => (_jsx(MenuItem, { value: item.value, children: item.label }, item.value))) })] }), _jsx(FilterSelect, { id: "processor", lable: "Select processors", sx: { minWidth: 100, mb: 2 }, labelKey: "name", valueKey: "str_id", placeholder: "Processors", options: processors, value: selectedProcessors, onChange: setSelectedProcessors, multiple: true, getOptionLabel: (item) => {
                                return item.label || item.name || item;
                            }, renderOptionItem: (item) => {
                                return item.name;
                            } }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Priority" }), _jsx(Select, { id: "select-priority", value: formData.priority, label: "Priority", onChange: (e) => {
                                        onFormChange('priority', e.target.value);
                                    }, children: PriorityList.map((item) => (_jsx(MenuItem, { value: item.value, children: item.label }, item.value))) })] }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Mapping" }), _jsx(Select, { id: "select-sheet", value: formData.mapping_id, label: "Mapping", onChange: (e) => {
                                        onFormChange('mapping_id', e.target.value);
                                    }, children: (mappings || []).map((c) => (_jsx(MenuItem, { value: c.str_id, children: _jsx(Box, { children: c.name }) }, c.str_id))) })] }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: _jsx(TextField, { label: "Notes", multiline: true, minRows: 2, variant: "outlined", value: formData.notes, onChange: (e) => {
                                    onFormChange('notes', e.target.value);
                                } }) })] }) }), _jsxs(DialogActions, { sx: { pt: 0, pb: 2, px: 2 }, children: [_jsx(Button, { onClick: handleCancel, children: "Cancel" }), _jsx(LoadingButton, { onClick: onSubmit, loading: uploading, variant: "contained", sx: { width: '100px' }, children: "Save" })] })] }));
};
export default ActionModal;
