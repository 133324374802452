import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography, } from '@mui/material';
import { Edit } from '@mui/icons-material';
import Spreadsheet from '@/services/Spreadsheet';
import { DocumentProcessActionTypes } from '@/types';
const ProcessMappingForm = ({ selectedDataRange, rangeData, shareData, rowMapping, setSelectedDataRange, processForm, processedData, addActionCount, setProcessedData, setManualMapping, }) => {
    return (_jsxs(Box, { sx: {
            width: 288,
            mr: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        }, children: [_jsx(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                }, children: _jsxs(FormControl, { sx: { mt: 1, flex: 1 }, children: [_jsx(InputLabel, { id: "select-row", children: "Spreadsheet header row" }), _jsxs(Select, { id: "select-row", label: "Spreadsheet header row", value: selectedDataRange === null || selectedDataRange === void 0 ? void 0 : selectedDataRange.index, onChange: (e) => {
                                addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                                const currentData = rangeData.filter((a) => { var _c; return a.index === Number.parseInt(((_c = e === null || e === void 0 ? void 0 : e.target) === null || _c === void 0 ? void 0 : _c.value) || 0, 10); })[0];
                                setSelectedDataRange(currentData);
                                setProcessedData({
                                    data: currentData.data,
                                    fields: currentData.fields,
                                    version: processedData.version,
                                });
                            }, children: [rangeData.length === 0 && _jsx(MenuItem, { value: 0, children: "\u00A0" }), Object.values(rangeData).map((row) => (_jsx(MenuItem, { value: row.index, children: _jsx("span", { style: { width: 800, overflow: 'auto' }, children: `Row ${row.index + 1}: ${row.fields
                                            .filter((f) => f !== null && f !== undefined)
                                            .join(', ')}` }) }, row.index)))] })] }) }), _jsx(Divider, { sx: { mt: 2 }, variant: "middle", textAlign: "center" }), _jsx(Typography, { sx: { fontWeight: 500 }, children: "Map document columns" }), _jsx(Box, { sx: {
                    flex: 1,
                    height: '100%',
                    display: 'flex',
                    position: 'relative',
                }, children: _jsx(Box, { sx: {
                        height: '100%',
                        overflow: 'scroll',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        left: 0,
                        bottom: 0,
                    }, children: Object.entries(shareData.fields)
                        .sort(([_a, va], [_b, vb]) => {
                        if (va.required && !vb.required)
                            return -1;
                        else if (!va.required && vb.required)
                            return 1;
                        else
                            return va.label.localeCompare(vb.label);
                    })
                        .map(([k, v]) => {
                        var _c, _d, _e;
                        return (_jsxs(Box, { sx: {
                                mt: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                            }, children: [_jsxs(FormControl, { sx: { textAlign: 'left' }, children: [_jsxs(InputLabel, { children: [v.label, v.required ? ' *' : ''] }), _jsxs(Select, { label: `${v.label}${v.required ? ' *' : ''}`, id: `select-${k}`, sx: { width: 270 }, onChange: (e) => {
                                                const val = e.target.value;
                                                addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                                                setManualMapping({
                                                    [k]: val,
                                                });
                                            }, value: typeof (rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k]) === 'string'
                                                ? 'MANUAL_ENTRY'
                                                : (rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k])
                                                    ? String(rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k])
                                                    : '', disabled: processForm.mapping !== 0, children: [_jsx(MenuItem, { value: 'NULL', sx: { color: 'gray' }, children: "(None)" }), _jsx(MenuItem, { value: "MANUAL_ENTRY", children: "[Manual entry]" }), _jsx(Divider, { style: { marginTop: 0, marginBottom: 0 } }), (_c = selectedDataRange === null || selectedDataRange === void 0 ? void 0 : selectedDataRange.fields) === null || _c === void 0 ? void 0 : _c.map((userField, i) => (_jsx(MenuItem, { value: i, disabled: Object.values(rowMapping).includes(i), children: `Col ${Spreadsheet.indexToCol(i)}: ${userField}` }, i)))] })] }), typeof (rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k]) === 'string' && (_jsx(Box, { sx: { width: 270, display: 'flex', mt: 1, mb: 1 }, children: !((_d = v.options) === null || _d === void 0 ? void 0 : _d.length) ? (_jsx(TextField, { id: `${v.key}-manual-entry`, label: `${v.label} (manual entry)`, variant: "outlined", sx: {
                                            width: '100%',
                                            border: '1px dashed #ccc',
                                            borderRadius: 50,
                                        }, value: rowMapping[k] || '', onBlur: (e) => {
                                            addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                                        }, onChange: (e) => {
                                            setManualMapping({
                                                [k]: e.target.value,
                                            });
                                        }, InputProps: {
                                            endAdornment: _jsx(Edit, {}),
                                        } })) : (_jsxs(FormControl, { sx: { width: '100%' }, children: [_jsx(InputLabel, { children: `${v.label} (manual entry)` }), _jsxs(Select, { id: `${v.key}-manual-entry`, label: `${v.label} (manual entry)`, variant: "outlined", value: rowMapping[k] || '', sx: { border: '1px dashed #ccc' }, onChange: (e) => {
                                                    addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                                                    setManualMapping({
                                                        [k]: e.target.value,
                                                    });
                                                }, children: [_jsx(MenuItem, { value: 'NULL', sx: { color: 'gray' }, children: "(None)" }), (_e = v.options) === null || _e === void 0 ? void 0 : _e.map((userField, i) => (_jsx(MenuItem, { value: userField, children: userField }, userField)))] })] })) }))] }, k));
                    }) }) })] }));
};
export default ProcessMappingForm;
