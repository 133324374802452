import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
const transformObjectVal = (json, keys, fn) => json.map((item) => Object.fromEntries(Object.entries(item).map(([k, v]) => keys.includes(k) ? [k, fn(v)] : [k, v])));
const JsonToCsv = () => {
    const [csv, setCSV] = useState('');
    const [json, setJSON] = useState('[]');
    useEffect(() => {
        const jsonJson = JSON.parse(json);
        const sheet = XLSX.utils.json_to_sheet(jsonJson);
        const csv = XLSX.utils.sheet_to_csv(sheet);
        setCSV(csv);
        // const csvSheet = XLSX.read(csv, {
        //   type: 'string',
        //   raw: true,
        // });
        // const jsonRes = XLSX.utils.sheet_to_json(
        //   csvSheet.Sheets[csvSheet.SheetNames[0]],
        //   { raw: true }
        // );
        // const csvRes = XLSX.utils.json_to_sheet(jsonRes);
        // setJSON(
        //   JSON.stringify(
        //     transformObjectVal(
        //       jsonRes,
        //       ['product_id', 'product_option_id'],
        //       (vals) => vals.split(',').map((val) => Number.parseInt(val))
        //     )
        //   )
        // );
    }, [json]);
    return (_jsx(Box, { display: "flex", children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(TextField, { label: "JSON", multiline: true, minRows: 16, value: json, onChange: (event) => {
                            setJSON(event.target.value);
                        }, sx: { width: '100%' } }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(TextField, { label: "CSV/TSV", multiline: true, minRows: 16, value: csv, onChange: (event) => {
                            setCSV(event.target.value);
                        }, sx: { width: '100%' } }) })] }) }));
};
export default JsonToCsv;
