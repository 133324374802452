import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Box, FormControlLabel, Switch } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
const StickyTips = ({ data, tipsMap }, ref) => {
    const [ignoreErrors, setIgnoreErrors] = useState(false);
    const [rowTips, setRowTips] = useState([]);
    useEffect(() => {
        const rows = data === null || data === void 0 ? void 0 : data.rowData;
        if (rows) {
            const result = [];
            rows.map((row, index) => {
                const tips = Object.entries(row)
                    .map(([key, val]) => {
                    if (val && (val.toString() || '').includes('❌')) {
                        const msg = key.includes('_date')
                            ? ' should be a date (eg: 2024-01-01, 01/01/2024...)'
                            : key.includes('_rate')
                                ? ' should be a rate (eg: 0.01, 1%, (1.00%)...)'
                                : key.includes('_amount')
                                    ? ' should be a amount (eg: 100, $100, $100.00...)'
                                    : '';
                        return {
                            key,
                            val: val,
                            index,
                            msg,
                        };
                    }
                })
                    .filter(Boolean);
                result.push(...tips);
            });
            setRowTips(result);
        }
    }, [data]);
    useImperativeHandle(ref, () => ({
        ignoreErrors,
    }));
    return (_jsxs(_Fragment, { children: [Object.entries(tipsMap).length && (_jsxs(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    backgroundColor: '#eee',
                }, children: [_jsx(Box, { sx: {
                            flex: 1,
                        }, children: Object.entries(tipsMap).map(([key, val]) => {
                            return (key !== 'missingField' && val) ||
                                (key === 'missingField' && !ignoreErrors && val) ? (_jsx(Alert, { severity: "error", children: val }, key)) : null;
                        }) }), _jsx(Box, { children: Object.values(tipsMap).filter((val) => val).length > 0 && (_jsx(FormControlLabel, { control: _jsx(Switch, { checked: ignoreErrors, onChange: (evt) => {
                                    setIgnoreErrors(evt.target.checked);
                                }, inputProps: { 'aria-label': 'controlled' } }), label: "Ignore Errors" })) })] })), !!rowTips.length && (_jsx(Box, { sx: {
                    mt: 2,
                    maxHeight: '120px',
                    overflow: 'auto',
                }, children: rowTips.map((row) => {
                    return (_jsxs(Alert, { severity: "error", children: ["Invalid value: row [", row.index, "] ", row.key, " ", row.val, " [", row.msg, "]"] }, row.key + row.index));
                }) }))] }));
};
StickyTips.displayName = 'StickyTips';
export default forwardRef(StickyTips);
