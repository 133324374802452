var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, InputAdornment } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
function CustomInputAdornment(props) {
    const { endAdornment, children } = props, other = __rest(props, ["endAdornment", "children"]);
    return (_jsxs(InputAdornment, Object.assign({}, other, { sx: {
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
        }, children: [children, endAdornment] })));
}
const BasicDatePicker = (_a) => {
    var _b, _c, _d, _e, _f;
    var { label, value, setValue, sx = {}, InputProps = {}, disabled = false } = _a, otherParamas = __rest(_a, ["label", "value", "setValue", "sx", "InputProps", "disabled"]);
    return (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(DatePicker, Object.assign({ label: _jsx(Box, { sx: { lineHeight: 1 }, children: label }), timezone: 'UTC', value: ![null, undefined].includes(value)
                ? dayjs.utc(value).startOf('date')
                : null, onChange: (newValue) => {
                var _a;
                setValue((_a = newValue === null || newValue === void 0 ? void 0 : newValue.format('YYYY-MM-DD')) !== null && _a !== void 0 ? _a : null);
            } }, otherParamas, { slots: {
                inputAdornment: otherParamas.endAdornment
                    ? CustomInputAdornment
                    : undefined,
            }, slotProps: Object.assign(Object.assign({}, ((_b = otherParamas.slotProps) !== null && _b !== void 0 ? _b : {})), { textField: Object.assign(Object.assign({}, ((_d = (_c = otherParamas.slotProps) === null || _c === void 0 ? void 0 : _c.textField) !== null && _d !== void 0 ? _d : {})), { size: 'small', error: otherParamas.error, sx: Object.assign({ justifyContent: 'start', width: 180, pl: 0, '.MuiInputBase-input': {
                            py: 0.75,
                            pl: 1.5,
                        } }, sx), InputLabelProps: {
                        sx: Object.assign(Object.assign({}, (value !== null ? { top: 3 } : {})), { error: otherParamas.error }),
                    }, InputProps: Object.assign(Object.assign({}, InputProps), { error: otherParamas.error }) }), inputAdornment: Object.assign(Object.assign({}, ((_f = (_e = otherParamas.slotProps) === null || _e === void 0 ? void 0 : _e.inputAdornment) !== null && _f !== void 0 ? _f : {})), { sx: { ml: 0 }, endAdornment: otherParamas.endAdornment }), openPickerButton: {
                    sx: { p: 0.5 },
                } }), disabled: disabled })) }));
};
export default BasicDatePicker;
