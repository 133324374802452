import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box, FormControl, Input, Typography } from '@mui/material';
import useDashboard from './dashboardHook';
import WidgetSelector from './WidgetSelector';
const SubPageCreator = ({ sharedWidgets, closeAddWidgetDialog }) => {
    const { predefinedDashboardName, predefinedWidgetSettings } = useDashboard();
    const [dashboardName, setDashboardName] = useState(predefinedDashboardName !== null && predefinedDashboardName !== void 0 ? predefinedDashboardName : '');
    useEffect(() => {
        setDashboardName(predefinedDashboardName !== null && predefinedDashboardName !== void 0 ? predefinedDashboardName : '');
    }, [predefinedDashboardName]);
    return (_jsxs(Box, { sx: { padding: 2, minWidth: '400px' }, children: [_jsxs(FormControl, { fullWidth: true, children: [_jsx(Typography, { variant: "caption", children: "Dashboard name" }), _jsx(Input, { onChange: (e) => setDashboardName(e.target.value), value: dashboardName })] }), _jsx(WidgetSelector, { sharedWidgets: sharedWidgets, dashboardSettings: predefinedWidgetSettings, closeAddWidgetDialog: closeAddWidgetDialog, dashboardName: dashboardName })] }));
};
export default SubPageCreator;
