import { useState, useEffect } from 'react';
function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        // Listen for window resize events
        window.addEventListener('resize', handleResize);
        // Cleanup function
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty array ensures effect only runs on mount and unmount
    return windowSize;
}
export default useWindowSize;
