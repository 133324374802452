var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button, Typography, TextField, Grid, Switch, Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WidgetGroup } from 'common/constants';
import { LoadingContext } from 'contexts/LoadingContext';
import useSnackbar from '@/contexts/useSnackbar';
import { UIStateContext } from '@/contexts/UIStateProvider';
import BoxWidget from './BoxWidget';
import ChartWidget from './ChartWidget';
import TableWidget from './TableWidget';
import WidgetWrapper from './WidgetWrapper';
import FieldMatcher from '../molecules/FieldMatcher';
import useAccountStore from '@/store/accountStore';
import FieldAggregationManager from '../molecules/FieldAggregation/FieldAggregationManager';
const dataSources = [
    'statement',
    'report',
    'reconciliation',
    'reportDataCommissionProcessingDate',
];
const dataSourceDisplayNames = {
    statement: 'Commissions',
    report: 'Policies',
    reconciliation: 'Reconciliations',
    reportDataCommissionProcessingDate: 'Policies Processing Date',
};
const filterFieldsByDataSource = {
    statement: {
        groupFields: [
            {
                name: 'writing_carrier_name',
                type: 'string',
                displayName: 'Writing carrier name',
            },
            {
                name: 'agent_name',
                type: 'string',
                displayName: 'Agent name',
            },
            {
                name: 'contacts',
                type: 'string',
                displayName: 'Agents',
            },
            {
                name: 'customer_name',
                type: 'string',
                displayName: 'Customer name',
            },
            {
                name: 'product_type',
                type: 'string',
                displayName: 'Product type',
            },
            {
                name: 'product_name',
                type: 'string',
                displayName: 'Product name',
            },
            {
                name: 'processing_date',
                type: 'date',
                displayName: 'Processing date',
            },
            {
                name: 'compensation_type',
                type: 'string',
                displayName: 'Compensation type',
            },
            {
                name: 'carrier_name',
                type: 'string',
                displayName: 'Carrier name',
            },
        ],
        dataFields: [
            {
                name: 'commission_amount',
                type: 'number',
            },
            {
                name: 'agent_commissions.total',
                type: 'obj',
                lookupKey: 'total',
            },
            {
                name: 'agent_commissions',
                type: 'obj',
            },
            {
                name: 'premium_amount',
                type: 'number',
            },
        ],
    },
    report: {
        groupFields: [
            {
                name: 'effective_date',
                type: 'date',
                displayName: 'Effective date',
            },
            {
                name: 'agent_name',
                type: 'string',
                displayName: 'Agent name',
            },
            {
                name: 'customer_name',
                type: 'string',
                displayName: 'Customer name',
            },
            {
                name: 'product_type',
                type: 'string',
                displayName: 'Product type',
            },
            {
                name: 'product_name',
                type: 'string',
                displayName: 'Product name',
            },
            {
                name: 'writing_carrier_name',
                type: 'string',
                displayName: 'Writing carrier name',
            },
            {
                name: 'policy_status',
                type: 'string',
                displayName: 'Policy status',
                availableValues: [
                    'Active',
                    'Inactive',
                    'Active Client',
                    'Inactive Client',
                    'In Force',
                    'Prospect/Lead',
                    'duplicate',
                ],
            },
            {
                name: 'state',
                type: 'string',
                displayName: 'State',
                availableValues: ['duplicate', 'active', 'deleted'],
            },
        ],
        dataFields: [
            {
                name: 'commissionable_premium_amount',
                type: 'number',
            },
            {
                name: 'premium_amount',
                type: 'number',
            },
            {
                name: 'commissions_expected',
                type: 'number',
            },
            {
                name: 'customer_paid_premium_amount',
                type: 'number',
            },
        ],
    },
    reconciliation: {
        groupFields: [
            {
                name: 'carrier_name',
                type: 'string',
                displayName: 'Carrier name',
            },
            {
                name: 'agent_name',
                type: 'string',
                displayName: 'Agent name',
            },
            {
                name: 'customer_name',
                type: 'string',
                displayName: 'Customer name',
            },
            {
                name: 'product_type',
                type: 'string',
                displayName: 'Product type',
            },
            {
                name: 'product_name',
                type: 'string',
                displayName: 'Product name',
            },
            {
                name: 'writing_carrier_name',
                type: 'string',
                displayName: 'Writing carrier name',
            },
        ],
        dataFields: [
            {
                name: 'amount_paid',
                type: 'obj',
                lookupKey: 'amount_paid.amount_paid',
            },
            {
                name: 'aggregate_premiums',
                type: 'obj',
                lookupKey: 'aggregatePremiums.aggregatePremiums',
            },
        ],
    },
};
const widgetGroups = ['box', 'chart-donut', 'chart-line', 'chart-bar', 'table'];
const calculationValues = ['Sum', 'Count', 'Aggregate', 'Average'];
const WidgetCreator = ({ widgetOnEdit, createWidget, setWidgetModel }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const addSelector = () => {
        setDataFieldsAggregators([
            ...dataFieldsAggregators,
            {
                id: dataFieldsAggregators.length + 1,
                field: '',
                aggregation_method: '',
            },
        ]);
    };
    useEffect(() => {
        if (widgetOnEdit) {
            setSelectedDataSource(widgetOnEdit.dataSource || '');
            setSelectedCustomCode(widgetOnEdit.customCode || '');
            setSelectedWidgetType(widgetOnEdit.type || '');
            setSelectedGroupByValue(widgetOnEdit.groupBy || '');
            setSelectedDataFieldValue(widgetOnEdit.dataField || []);
            setDataFieldFilters(widgetOnEdit.dataFieldFilters || []);
            setDataFieldsAggregators(widgetOnEdit.aggregationSelectors || []);
            setGroupByFilters(widgetOnEdit.groupByFilters || []);
            setSelectedCalculationValue(widgetOnEdit.calculation || '');
            setSelectedResultFormatter(widgetOnEdit.resultFormatter || '');
            setSelectedShared(widgetOnEdit.shared || false);
            setApplyAlevoExcludedAgents(widgetOnEdit.applyAlevoExcludedAgents || false);
            setWidgetName(widgetOnEdit.name || '');
        }
        else {
            setSelectedDataSource('');
            setSelectedCustomCode('');
            setSelectedWidgetType('');
            setSelectedGroupByValue('');
            setSelectedDataFieldValue([]);
            setDataFieldFilters([]);
            setDataFieldsAggregators([]);
            setGroupByFilters([]);
            setSelectedCalculationValue('');
            setSelectedResultFormatter('');
            setSelectedShared(false);
            setApplyAlevoExcludedAgents(false);
            setWidgetName('');
        }
    }, [widgetOnEdit]);
    const removeSelector = (id) => {
        setDataFieldsAggregators(dataFieldsAggregators.filter((selector) => selector.id !== id));
    };
    const updateSelector = (id, updatedField) => {
        setDataFieldsAggregators(dataFieldsAggregators.map((selector) => selector.id === id ? Object.assign(Object.assign({}, selector), updatedField) : selector));
    };
    const [selectedDataSource, setSelectedDataSource] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.dataSource) || '');
    const [selectedCustomCode, setSelectedCustomCode] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.customCode) || '');
    const [selectedWidgetType, setSelectedWidgetType] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.type) || '');
    const [selectedGroupByValue, setSelectedGroupByValue] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.groupBy) || '');
    const [selectedDataFieldValue, setSelectedDataFieldValue] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.dataField) || []);
    const [dataFieldFilters, setDataFieldFilters] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.dataFieldFilters) || []);
    const [dataFieldsAggregators, setDataFieldsAggregators] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.aggregationSelectors) || []);
    const [groupByFilters, setGroupByFilters] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.groupByFilters) || []);
    const [selectedCalculationValue, setSelectedCalculationValue] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.calculation) || '');
    const [selectedResultFormatter, setSelectedResultFormatter] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.resultFormatter) || '');
    const { showSnackbar } = useSnackbar();
    const [selectedShared, setSelectedShared] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.shared) || false);
    const [applyAlevoExcludedAgents, setApplyAlevoExcludedAgents] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.applyAlevoExcludedAgents) || false);
    const { selectedAccount } = useAccountStore();
    const [widgetName, setWidgetName] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.name) || '');
    const [previewData, setPreviewData] = useState(null);
    const { setLoadingConfig } = useContext(LoadingContext);
    const { role: [role], } = useContext(UIStateContext);
    const isFintaryAdmin = role === 'admin';
    const isAlevoAccount = (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName) === 'Alevo';
    const handleDataFieldChange = (e) => {
        const { value } = e.target;
        setSelectedDataFieldValue(typeof value === 'string' ? value.split(',') : value);
    };
    const handleCreateWidget = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!widgetName) {
            return;
        }
        if (!selectedDataSource) {
            return;
        }
        if (!selectedWidgetType) {
            return;
        }
        if (!selectedGroupByValue &&
            dataFieldsAggregators.length == 0 &&
            !selectedCalculationValue &&
            !selectedCustomCode &&
            !selectedDataFieldValue.length &&
            !dataFieldFilters.length &&
            !groupByFilters.length) {
            return;
        }
        setLoadingConfig({
            loading: true,
            message: 'Creating widget...',
        });
        try {
            const result = yield createWidget({
                name: widgetName,
                dataSource: selectedDataSource,
                type: selectedWidgetType,
                groupBy: selectedGroupByValue,
                groupByFilters: groupByFilters,
                dataField: selectedDataFieldValue,
                dataFieldFilters: dataFieldFilters,
                applyAlevoExcludedAgents: applyAlevoExcludedAgents,
                calculation: selectedCalculationValue,
                shared: selectedShared,
                resultFormatter: selectedResultFormatter,
                aggregationSelectors: dataFieldsAggregators,
                customCode: selectedCustomCode,
            });
            setPreviewData(result);
            setWidgetModel({
                id: result.id,
                name: widgetName,
                displayName: result.displayName,
                widgetGroup: selectedWidgetType.toUpperCase(),
                value: result.value,
                type: selectedWidgetType,
                spec: {
                    dataSource: selectedDataSource,
                    name: widgetName,
                    type: selectedWidgetType,
                    groupBy: selectedGroupByValue,
                    groupByFilters: groupByFilters,
                    dataFieldFilters: dataFieldFilters,
                    applyAlevoExcludedAgents: applyAlevoExcludedAgents,
                    dataField: selectedDataFieldValue,
                    calculation: selectedCalculationValue,
                    resultFormatter: selectedResultFormatter,
                    customCode: selectedCustomCode,
                    aggregationSelectors: dataFieldsAggregators,
                    shared: selectedShared,
                },
            });
        }
        catch (error) {
            showSnackbar(`Error creating widget: ${error}`, 'error');
        }
        setLoadingConfig({
            loading: false,
        });
    });
    const selectWidget = (data) => {
        switch (data.widgetGroup) {
            case WidgetGroup.BOX:
                return BoxWidget(data);
            case WidgetGroup.TABLE:
                return TableWidget(data);
            case WidgetGroup.CHART:
                return _jsx(ChartWidget, { data: data.value });
            default:
                return null;
        }
    };
    const renderWidget = (data) => (_jsx(WidgetWrapper, { displayName: data.displayName, children: selectWidget(data) }));
    return (_jsxs(Box, { sx: { padding: 2 }, children: [_jsx(Typography, { variant: "h6", children: "Create a new widget" }), _jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsx(TextField, { sx: { flex: 1 }, value: widgetName, label: "Widget name", onChange: (e) => {
                        setWidgetName(e.target.value);
                        handleCreateWidget();
                    } }) }), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { children: "Data source" }), _jsxs(Select, { value: selectedDataSource, label: "Data source", onChange: (e) => {
                            setSelectedDataSource(e.target.value);
                            handleCreateWidget();
                        }, children: [_jsx(MenuItem, { value: "", disabled: true, children: "Select data source" }), dataSources.map((source) => (_jsx(MenuItem, { value: source, children: dataSourceDisplayNames[source] }, source)))] })] }), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { children: "Widget type" }), _jsxs(Select, { value: selectedWidgetType, label: "Widget type", onChange: (e) => {
                            setSelectedWidgetType(e.target.value);
                            handleCreateWidget();
                        }, children: [_jsx(MenuItem, { value: "", disabled: true, children: "Select widget type" }), widgetGroups.map((type) => (_jsx(MenuItem, { value: type, children: type }, type)))] })] }), isFintaryAdmin && (_jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel2-content", id: "panel2-header", children: _jsx("label", { children: "Custom code" }) }), _jsx(AccordionDetails, { children: _jsx(CodeMirror, { title: "Custom code", height: "350px", value: selectedCustomCode, width: "100%", onChange: (code) => {
                                    setSelectedCustomCode(code);
                                } }) })] }) })), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { children: "Group by field" }), _jsxs(Select, { value: selectedGroupByValue, label: "Group by field", onChange: (e) => {
                            setSelectedGroupByValue(e.target.value);
                            handleCreateWidget();
                        }, children: [_jsx(MenuItem, { value: "", disabled: true, children: "Select group by field" }), (_a = filterFieldsByDataSource[selectedDataSource]) === null || _a === void 0 ? void 0 : _a.groupFields.map((field) => (_jsx(MenuItem, { value: field.name, children: field.displayName }, field.name)))] })] }), ((_b = filterFieldsByDataSource[selectedDataSource]) === null || _b === void 0 ? void 0 : _b.groupFields) && (_jsxs(Box, { children: [_jsx("label", { children: "Group by filter" }), _jsx(FieldMatcher, { fields: (_c = filterFieldsByDataSource[selectedDataSource]) === null || _c === void 0 ? void 0 : _c.groupFields.map((field) => {
                            return {
                                id: field.name,
                                label: field.displayName,
                            };
                        }), hideUsePolicyData: true, value: groupByFilters, setValue: setGroupByFilters })] })), ((_d = filterFieldsByDataSource[selectedDataSource]) === null || _d === void 0 ? void 0 : _d.dataFields) && (_jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx("label", { children: "Data field aggregators" }), _jsx(FieldAggregationManager, { fields: (_e = filterFieldsByDataSource[selectedDataSource]) === null || _e === void 0 ? void 0 : _e.dataFields, selectors: dataFieldsAggregators, addSelector: addSelector, removeSelector: removeSelector, updateSelector: updateSelector })] })), ((_f = filterFieldsByDataSource[selectedDataSource]) === null || _f === void 0 ? void 0 : _f.dataFields) && (_jsxs(Box, { children: [_jsx("label", { children: "Data filters" }), _jsx(FieldMatcher, { fields: (_g = filterFieldsByDataSource[selectedDataSource]) === null || _g === void 0 ? void 0 : _g.dataFields.map((field) => {
                            return {
                                id: field.name,
                                label: field.name,
                            };
                        }), hideUsePolicyData: true, value: dataFieldFilters, setValue: setDataFieldFilters })] })), dataFieldsAggregators.length == 0 && (_jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { children: "Aggregation method" }), _jsxs(Select, { value: selectedCalculationValue, label: "Aggregation method", onChange: (e) => {
                            setSelectedCalculationValue(e.target.value);
                            handleCreateWidget();
                        }, children: [_jsx(MenuItem, { value: "", disabled: true, children: "Select aggregation method" }), calculationValues.map((value) => {
                                return (_jsx(MenuItem, { value: value, children: value }, value));
                            })] })] })), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx(InputLabel, { children: "Result formatter" }), _jsxs(Select, { value: selectedResultFormatter, label: "Result formatter", onChange: (e) => {
                            setSelectedResultFormatter(e.target.value);
                            handleCreateWidget();
                        }, children: [_jsx(MenuItem, { value: "", children: "None" }), _jsx(MenuItem, { value: "number", children: "Number" }), _jsx(MenuItem, { value: "currency", children: "Currency" }), _jsx(MenuItem, { value: "percentage", children: "Percentage" })] })] }), isAlevoAccount && (_jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(Typography, { children: "Apply excluded agents" }) }), _jsx(Grid, { item: true, children: _jsx(Switch, { checked: applyAlevoExcludedAgents, onChange: () => setApplyAlevoExcludedAgents(!applyAlevoExcludedAgents) }) })] }) })), isFintaryAdmin && (_jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, children: _jsxs(Typography, { children: ["Global", ' ', _jsx("span", { style: {
                                            color: 'transparent',
                                            textShadow: '0 0 0 #e8e8e8',
                                        }, children: "\uD83D\uDD12" })] }) }), _jsx(Grid, { item: true, children: _jsx(Switch, { checked: selectedShared, onChange: () => setSelectedShared(!selectedShared) }) })] }) })), _jsx("hr", {}), _jsx(Box, { mt: 2, display: 'flex', children: _jsx(Button, { variant: "contained", color: "primary", onClick: handleCreateWidget, children: "Preview" }) }), _jsx(Box, { mt: 4, mb: 4, p: 2, borderColor: "grey.400", height: 380, children: previewData && renderWidget(previewData) })] }));
};
export default WidgetCreator;
