import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Box, LinearProgress, Snackbar } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import API from '@/services/API';
export const DataProcessingTaskProgress = (props) => {
    var _a, _b;
    const { taskId, onFinish, onClose } = props;
    const isRunning = (task) => {
        return ['pending', 'processing'].includes(task === null || task === void 0 ? void 0 : task.status);
    };
    const getSeverity = (task) => {
        if (task.status === 'completed') {
            return 'success';
        }
        else if (task.status === 'error') {
            return 'error';
        }
        else if (task.status === 'pending') {
            return 'warning';
        }
        return 'info';
    };
    const queryKey = ['data_processing', taskId];
    const { data } = useQuery({
        queryKey,
        queryFn: () => API.get('data_processing', `id=${taskId}`),
        enabled: true,
        refetchInterval: (data) => {
            var _a, _b;
            if (data && ((_a = data === null || data === void 0 ? void 0 : data.state) === null || _a === void 0 ? void 0 : _a.data) && !isRunning((_b = data === null || data === void 0 ? void 0 : data.state) === null || _b === void 0 ? void 0 : _b.data[0])) {
                return false;
            }
            return 3000;
        },
    });
    useEffect(() => {
        var _a;
        if (((_a = data === null || data === void 0 ? void 0 : data[0]) === null || _a === void 0 ? void 0 : _a.status) === 'completed') {
            onFinish();
        }
    }, [data]);
    const [task] = data || [];
    if (!task) {
        return null;
    }
    const severity = getSeverity(task);
    return (_jsx(_Fragment, { children: _jsx(Snackbar, { open: true, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, children: _jsxs(Box, { children: [_jsx(Alert, { severity: severity, onClose: onClose, sx: {
                            alignContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                        }, children: _jsx(Box, { sx: {
                                minWidth: '200px',
                                padding: 0,
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center',
                            }, children: _jsxs("span", { children: ["TaskID: ", taskId, " ", _jsx("br", {}), " TaskType: ", task.type, " ", _jsx("br", {}), "TaskStatus: ", task === null || task === void 0 ? void 0 : task.status, " ", _jsx("br", {}), (task === null || task === void 0 ? void 0 : task.total) &&
                                        `Progress:
                ${((_a = task === null || task === void 0 ? void 0 : task.success) !== null && _a !== void 0 ? _a : 0) + ((_b = task === null || task === void 0 ? void 0 : task.failed) !== null && _b !== void 0 ? _b : 0)}/${task === null || task === void 0 ? void 0 : task.total}${(task === null || task === void 0 ? void 0 : task.failed) ? ` (${task === null || task === void 0 ? void 0 : task.failed} failed)` : ''}`] }) }) }), isRunning(task) ? (_jsx(Box, { sx: { width: '100%' }, children: _jsx(LinearProgress, {}) })) : null] }) }) }));
};
