import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Groups, Person } from '@mui/icons-material';
import { Avatar, Box, Card, CardHeader, Chip, IconButton, Typography, } from '@mui/material';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
const ContactCard = ({ contact, main = false, onClick }) => {
    var _a, _b, _c, _d;
    const { selectedAccount } = useAccountStore();
    const countAllChildren = (contact) => {
        var _a, _b;
        let count = (_a = contact === null || contact === void 0 ? void 0 : contact.child_relationships) === null || _a === void 0 ? void 0 : _a.length;
        (_b = contact === null || contact === void 0 ? void 0 : contact.child_relationships) === null || _b === void 0 ? void 0 : _b.forEach((childRel) => {
            if (childRel.contact)
                count += countAllChildren(childRel.contact);
        });
        return count;
    };
    const totalReports = countAllChildren(contact);
    return (_jsx(Card, { variant: "outlined", sx: {
            backgroundColor: main ? '#2096f322' : 'inherit',
            width: '300px',
            height: '100px',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }, onClick: () => onClick(contact), children: _jsx(CardHeader, { avatar: _jsx(Avatar, { children: contact ? Formatter.contactInitials(contact) : _jsx(Person, {}) }), action: _jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    ml: 1,
                }, children: [((_a = contact === null || contact === void 0 ? void 0 : contact.contact_level) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                        contact.contact_level.map((level) => (_jsx(Chip, { label: `${level.level_label}`, sx: { mb: 0.5, backgroundColor: '#2096f322' }, size: "small" }, level.id))), ((_b = contact === null || contact === void 0 ? void 0 : contact.child_relationships) === null || _b === void 0 ? void 0 : _b.length) > 0 && (_jsx(Chip, { label: "Manager", sx: { backgroundColor: '#2096f322' }, size: "small" })), ((_c = contact === null || contact === void 0 ? void 0 : contact.child_relationships) === null || _c === void 0 ? void 0 : _c.length) > 0 && (_jsx(IconButton, { children: _jsxs(Box, { display: "flex", alignItems: "center", children: [_jsx(Typography, { variant: "body2", sx: { mr: 1 }, children: ((_d = contact === null || contact === void 0 ? void 0 : contact.child_relationships) === null || _d === void 0 ? void 0 : _d.length) || 0 }), _jsx(Groups, { fontSize: "small" })] }) }))] }), title: contact
                ? Formatter.contact(contact, {
                    account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                })
                : 'No contact selected', subheader: totalReports > 0 && `Total reports: ${totalReports}` }) }));
};
export default ContactCard;
