import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, TextField, } from '@mui/material';
import API from '@/services/API';
const VertexResult = ({ open, onClose, onConfirm, json, extraction, }) => {
    const extractionPutter = API.getMutation('extractions', 'PUT');
    const [formData, setFormData] = useState({});
    useEffect(() => {
        if (json) {
            setFormData({
                jsonRes: '',
                jsonStr: json,
            });
        }
    }, [json]);
    const onFormChange = (key, value) => {
        setFormData((prev) => {
            return Object.assign(Object.assign({}, prev), { [key]: value });
        });
    };
    useEffect(() => {
        if (formData.jsonStr) {
            try {
                const res = JSON.parse(formData.jsonStr);
                onFormChange('jsonRes', JSON.stringify(res, null, 2));
            }
            catch (error) {
                onFormChange('jsonRes', error);
            }
        }
    }, [formData.jsonStr]);
    const handleSubmit = () => {
        // If exist extractionId, update extraction
        if (extraction && extraction.id) {
            try {
                const output = JSON.stringify(JSON.parse((formData === null || formData === void 0 ? void 0 : formData.jsonRes) || ''));
                const poster = [
                    JSON.stringify({
                        role: 'model',
                        parts: [
                            {
                                text: output,
                            },
                        ],
                    }),
                ];
                extractionPutter.mutateAsync({
                    id: extraction.id,
                    output: JSON.stringify(poster),
                });
            }
            catch (error) {
                console.error(`An error occurred when updating extraction: ${error}`);
            }
        }
        onConfirm((formData === null || formData === void 0 ? void 0 : formData.jsonRes) || '');
    };
    return (_jsxs(Dialog, { open: open, onClose: onClose, fullScreen: true, sx: { p: 10 }, children: [_jsx(DialogTitle, { children: "Invalid JSON Result" }), _jsx(DialogContent, { children: _jsxs(Box, { sx: {
                        display: 'flex',
                        width: '90vw',
                        height: '100%',
                        p: 2,
                        gap: 2,
                    }, children: [_jsx(Box, { sx: { flex: 1 }, children: _jsx(FormControl, { fullWidth: true, sx: { mb: 2, height: '100%' }, children: _jsx(TextField, { label: "JSON", variant: "outlined", multiline: true, minRows: 26, value: formData === null || formData === void 0 ? void 0 : formData.jsonStr, onChange: (e) => {
                                        onFormChange('jsonStr', e.target.value);
                                    } }) }) }), _jsx(Box, { sx: { flex: 1 }, children: _jsx(FormControl, { fullWidth: true, sx: { mb: 2, height: '100%' }, children: _jsx(TextField, { label: "Result", variant: "outlined", multiline: true, minRows: 26, value: formData === null || formData === void 0 ? void 0 : formData.jsonRes }) }) })] }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: handleSubmit, variant: "contained", children: "Confirm" })] })] }));
};
export default VertexResult;
