var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, TextField, } from '@mui/material';
import { cloneDeep } from 'lodash';
import { AccessTypes } from 'common/globalTypes';
import useSnackbar from '@/contexts/useSnackbar';
const DataUpdateCopyModal = ({ open, handleClose, type, selectedData, poster, refetch, }) => {
    const [newNames, setNewNames] = useState({});
    const { showSnackbar } = useSnackbar();
    const handleNameChange = (str_id, newName) => {
        setNewNames((prevNames) => (Object.assign(Object.assign({}, prevNames), { [str_id]: newName })));
    };
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        const copiedData = cloneDeep(selectedData);
        const updatedData = copiedData.map((data) => (Object.assign(Object.assign({}, data), { name: newNames[data.str_id] || data.name, access: AccessTypes.ACCOUNT })));
        const data = yield poster.mutateAsync(updatedData);
        if ((data === null || data === void 0 ? void 0 : data.length) > 0) {
            showSnackbar(`Copied!`, 'success');
            refetch();
        }
        else {
            showSnackbar(`Error copying data`, 'error');
        }
        handleClose();
    });
    return (_jsxs(Dialog, { open: open, onClose: handleClose, children: [_jsxs(DialogTitle, { children: ["Copy data update ", type] }), _jsxs(DialogContent, { children: [_jsxs(DialogContentText, { children: ["Enter a new name for copying the selected data update ", type, "."] }), selectedData.map((data) => (_jsxs(Box, { sx: { mt: 1 }, children: [_jsx(TextField, { label: "Current name", value: data.name, disabled: true, variant: "outlined", fullWidth: true, sx: { mb: 1 } }), _jsx(TextField, { label: "New name", value: newNames[data.str_id] || '', onChange: (e) => handleNameChange(data.str_id, e.target.value), variant: "outlined", fullWidth: true, sx: { mb: 1 } })] }, data.id)))] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, color: "primary", children: "Cancel" }), _jsx(Button, { onClick: handleConfirm, color: "primary", children: "Confirm" })] })] }));
};
export default DataUpdateCopyModal;
