import { useState, useEffect } from 'react';
import { mapToArray } from '@/services/helpers';
const useExtraction = () => {
    const [extraction, setExtraction] = useState('');
    const [extractTableJson, setExtractTableJson] = useState('');
    const [extractTableRaw, setExtractTableRaw] = useState('');
    const [extractionMethod, setExtractionMethod] = useState('');
    useEffect(() => {
        try {
            const _extraction = typeof extraction === 'string' && extraction
                ? JSON.parse(extraction)
                : extraction;
            if (_extraction) {
                if (['documentAI'].includes(extractionMethod)) {
                    setExtractTableRaw(_extraction);
                }
                else if (['adobeExtract'].includes(extractionMethod) ||
                    ['nanonets'].includes(extractionMethod)) {
                    setExtractTableRaw(_extraction[0]);
                }
                else {
                    // Default based on legacy
                    const tableData = _extraction.Tables;
                    let result = [];
                    if (tableData) {
                        tableData.forEach((table) => {
                            const dataArray = mapToArray(table.TableJson);
                            result = result.concat(dataArray);
                        });
                        setExtractTableRaw(_extraction);
                        setExtractTableJson(JSON.stringify(result));
                    }
                    else {
                        console.log('Data error', _extraction);
                    }
                }
            }
            else {
                setExtraction('');
                setExtractTableJson('');
                setExtractionMethod('');
            }
        }
        catch (e) {
            console.log('error', e);
        }
    }, [extraction, extractionMethod]);
    return {
        extraction,
        setExtraction,
        extractTableJson,
        extractTableRaw,
        setExtractionMethod,
    };
};
export default useExtraction;
