var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { captureException } from '@sentry/react';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
import { getMimeType } from '@/services/helpers';
export const useUploadStorageFileV2 = () => {
    const fileSignedUrlLoader = API.getMutation('storage/getSignedUrl', 'POST');
    const [uploadStatus, setUploadStatus] = useState({});
    const { showSnackbar } = useSnackbar();
    const uploadFile = (_a) => __awaiter(void 0, [_a], void 0, function* ({ endpoint, endpoint_str_id, action, file, file_preview_type, }) {
        try {
            setUploadStatus((prev) => (Object.assign(Object.assign({}, prev), { [file.name]: 'uploading' })));
            const params = {
                endpoint,
                endpoint_str_id,
                file_preview_type,
                action,
            };
            const { data: signedUrl } = yield fileSignedUrlLoader.mutateAsync(params);
            const mimeType = getMimeType(file.name);
            const headers = new Headers({
                'content-type': mimeType,
                'content-length': file.size.toString(),
            });
            const res = yield fetch(signedUrl, {
                method: 'PUT',
                headers: headers,
                body: file,
            });
            if (!res.ok) {
                showSnackbar(`Failed to upload file, error: ${res.status} ${res.statusText} `, 'error');
                setUploadStatus((prev) => (Object.assign(Object.assign({}, prev), { [file.name]: 'failed' })));
                return;
            }
            setUploadStatus((prev) => (Object.assign(Object.assign({}, prev), { [file.name]: 'done' })));
        }
        catch (error) {
            console.error(error.message || error);
            captureException(error);
            showSnackbar(`Failed to upload file: ${error}`, 'error');
            setUploadStatus((prev) => (Object.assign(Object.assign({}, prev), { [file.name]: 'failed' })));
            throw error;
        }
    });
    return { uploadFile, uploadStatus };
};
