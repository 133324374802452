var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { e } from 'mathjs';
import * as React from 'react';
const ITEM_HEIGHT = 48;
const MoreMenu = ({ actions, data, setActionLoading, sx }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (_jsxs(Box, { sx: Object.assign({}, sx), children: [_jsx(IconButton, { id: "long-button", onClick: handleClick, sx: { px: 0 }, children: _jsx(MoreVertIcon, {}) }), _jsx(Menu, { id: "long-menu", anchorEl: anchorEl, open: open, onClose: handleClose, PaperProps: {
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        // Width: '20ch',
                    },
                }, children: actions.map(({ label, onClick }) => (_jsx(MenuItem, { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                        e.stopPropagation();
                        setActionLoading(true);
                        yield onClick(data);
                        setActionLoading(false);
                        handleClose();
                    }), children: label }, label))) })] }));
};
export default MoreMenu;
