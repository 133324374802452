import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, TextField } from '@mui/material';
import API from '@/services/API';
const AutoCompleteCompanies = ({ value, onChange, inputValue, onInputChange, sx, }) => {
    const { data: _data } = API.getBasicQuery('companies');
    const data = _data === null || _data === void 0 ? void 0 : _data.data;
    return ((data === null || data === void 0 ? void 0 : data.length) && (_jsx(Autocomplete, { disablePortal: true, id: "company", sx: sx, options: data, isOptionEqualToValue: (option, value) => (option === null || option === void 0 ? void 0 : option.str_id) === (value === null || value === void 0 ? void 0 : value.str_id), renderInput: (params) => _jsx(TextField, Object.assign({}, params, { label: "Company" })), getOptionLabel: (option) => option
            ? `${option.company_name} ${(option === null || option === void 0 ? void 0 : option.company_id) ? `(${option === null || option === void 0 ? void 0 : option.company_id})` : ''}`
            : option, value: value, inputValue: inputValue, onChange: onChange, onInputChange: onInputChange })));
};
export default AutoCompleteCompanies;
