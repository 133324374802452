import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
const PDFPreview = ({ file, previewWidth, rotate = 0 }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();
    const [numPage, setNumPage] = useState(0);
    const wrapperRef = useRef(null);
    const onLoadSuccess = (res) => {
        setNumPage(res.numPages);
    };
    return (_jsx("div", { ref: wrapperRef, style: {
            padding: '20px',
            width: '100%',
            height: '100%',
            boxSizing: 'border-box',
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
        }, children: file && (_jsx(Document, { file: file, onLoadSuccess: onLoadSuccess, rotate: rotate, children: Array.from(new Array(numPage), (el, index) => (_jsx(Page, { pageNumber: index + 1, renderAnnotationLayer: false, renderTextLayer: false, devicePixelRatio: 2, width: previewWidth }, `page_${index + 1}`))) })) }));
};
export default PDFPreview;
