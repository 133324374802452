import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField, Typography, } from '@mui/material';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
const AgentCommissionSplitConfig = ({ title, data, contacts, split, setter, setterKey, dynamicSelects, }) => {
    const { selectedAccount } = useAccountStore();
    const totalPct = Object.values(split !== null && split !== void 0 ? split : {}).reduce((acc, cur) => +acc +
        (Number.isNaN(Number.parseFloat(cur)) ? 0 : Number.parseFloat(cur)), 0);
    const contactsFromSplit = new Set(Object.keys(split !== null && split !== void 0 ? split : {}));
    const contactsFromPolicy = new Set(contacts !== null && contacts !== void 0 ? contacts : []);
    const contactsWithSplits = Array.from(new Set([...contactsFromSplit, ...contactsFromPolicy]));
    return (_jsxs(Box, { sx: { mb: 1, width: '100%' }, children: [_jsx(Typography, { children: title }), contactsWithSplits.length > 0 ? (_jsxs(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }, children: [_jsx(Box, { sx: { display: 'flex' }, children: contactsWithSplits.map((contact, i) => {
                            var _a, _b;
                            return (_jsxs(Box, { sx: { mr: 1, mt: 1 }, children: [_jsx(TextField, { value: (_a = split === null || split === void 0 ? void 0 : split[contact]) !== null && _a !== void 0 ? _a : 0, onChange: (e) => {
                                            setter(Object.assign(Object.assign({}, data), { [setterKey]: Object.assign(Object.assign({}, (split !== null && split !== void 0 ? split : {})), { [contact]: e.target.value }) }));
                                        }, label: Formatter.contact((_b = dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.find((val) => val.str_id === contact)) !== null && _b !== void 0 ? _b : {}, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId }), InputProps: {
                                            endAdornment: (_jsx(InputAdornment, { position: "end", children: "%" })),
                                        }, sx: {
                                            '& .MuiOutlinedInput-root': !contactsFromPolicy.has(contact)
                                                ? { background: '#f0f0f0' }
                                                : {},
                                        }, helperText: !contactsFromPolicy.has(contact)
                                            ? 'Agent not assigend to policy'
                                            : null }, contact), _jsx(IconButton, { onClick: () => {
                                            const newData = JSON.parse(JSON.stringify(data));
                                            delete newData[setterKey][contact];
                                            setter(newData);
                                        }, sx: { mt: 0.4 }, children: _jsx(RemoveCircleOutline, {}) })] }));
                        }) }), _jsxs(Box, { sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                        }, children: [_jsx(Typography, { sx: totalPct === 100 ? {} : { color: 'red', fontWeight: 500 }, children: `Total: ${totalPct}%` }), totalPct !== 100 && (_jsx(Typography, { variant: "caption", children: "Agents split should add up to 100" }))] })] })) : (_jsx(Typography, { variant: "body2", sx: { ml: 1 }, children: "No agents attached to this policy" }))] }));
};
export default AgentCommissionSplitConfig;
