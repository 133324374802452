import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Checkbox, Popover, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';
import { FieldTypes } from '@/types';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
const MoreDateFilters = ({ title, filters, values, onSetValue }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMore = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const checkBoxComponent = (filters) => {
        const checkBox = filters.find((filter) => filter.type === FieldTypes.BOOLEAN);
        if (!checkBox) {
            console.error('Filters are missing required fields: ', { filters });
            return null;
        }
        return (_jsx(Checkbox, { checked: values.get(checkBox.filterKey) === 'true', onChange: (e) => {
                onSetValue(checkBox.filterKey, e.target.checked ? 'true' : undefined);
            } }, checkBox.filterKey));
    };
    const dateRangeComponent = (filters) => {
        const startDate = filters.find((filter) => { var _a; return (_a = filter.label) === null || _a === void 0 ? void 0 : _a.includes('start'); });
        const endDate = filters.find((filter) => { var _a; return (_a = filter.label) === null || _a === void 0 ? void 0 : _a.includes('end'); });
        if (!startDate || !endDate) {
            console.error('Filters are missing required fields: ', { filters });
            return null;
        }
        return (_jsx(BasicDateRangePicker, { range: {
                startDate: values.get(startDate.filterKey) || null,
                endDate: values.get(endDate.filterKey) || null,
                startDateLabel: startDate.label || 'Start Date',
                endDateLabel: endDate.label || 'End Date',
            }, onChange: (range) => {
                onSetValue(startDate.filterKey, range.startDate);
                onSetValue(endDate.filterKey, range.endDate);
            }, width: 200, mt: 1, my: 1 }, `${startDate.filterKey}-to-${endDate.filterKey}`));
    };
    return (_jsxs(Box, { children: [_jsx(IconButton, { id: "more-date-filters-btn", onClick: handleClick, sx: { px: 0 }, children: _jsx(MoreVertIcon, {}) }), _jsx(Popover, { id: "more-date-filter-view", open: openMore, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, sx: { ml: -36, mt: 1 }, children: _jsxs(Box, { sx: { p: 1 }, children: [_jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(Typography, { variant: "subtitle2", children: title }), _jsx(Typography, { variant: "caption", sx: { maxWidth: 60, textAlign: 'right', lineHeight: 1.2 }, children: "Include blanks" })] }), _jsx(Box, { children: filters.map((filter) => (_jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [dateRangeComponent(filter.filters), checkBoxComponent(filter.filters)] }, filter.filterFieldId))) })] }) })] }));
};
export default MoreDateFilters;
