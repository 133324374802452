import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { History } from '@mui/icons-material';
import { Avatar, Box, Button, Drawer, List, ListItem, ListItemAvatar, ListItemText, Typography, } from '@mui/material';
import 'allotment/dist/style.css';
import useSnackbar from '@/contexts/useSnackbar';
const HistoryDialog = ({ setShowHistoryList, showHistoryList, historyList, setCurrentCode, }) => {
    const { showSnackbar } = useSnackbar();
    return (_jsxs(Drawer, { anchor: "right", sx: {
            zIndex: 9999,
        }, open: showHistoryList, onClose: () => setShowHistoryList(false), children: [_jsx(Box, { className: "flex items-center justify-between p-4", children: _jsx(Typography, { variant: "h6", children: "History List" }) }), _jsx(List, { children: historyList.map((item) => (_jsxs(ListItem, { children: [_jsx(ListItemAvatar, { children: _jsx(Avatar, { sx: { bgcolor: '#2196f3' }, children: _jsx(History, {}) }) }), _jsx(ListItemText, { primary: item.name, secondary: _jsxs(_Fragment, { children: [_jsxs(Typography, { variant: "body2", color: "text.secondary", children: [item.method, "-", item.type, _jsx(Typography, { variant: "caption", color: "text.secondary", sx: { ml: 1 }, children: new Date(item.created_at).toLocaleString() })] }), _jsx(Button, { onClick: () => {
                                            setCurrentCode(item.processor);
                                            setShowHistoryList(false);
                                            showSnackbar('Successfully reverted', 'success');
                                        }, children: "Revert to" })] }) })] }, item.created_at))) })] }));
};
export default HistoryDialog;
