var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DeleteOutline, GroupAdd } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Chip, FormControlLabel, Skeleton, Tooltip, } from '@mui/material';
import CommonFormatter from 'common/Formatter';
import { AccountIds } from 'common/constants';
import { ContactPayableStatuses } from 'common/globalTypes';
import { capitalize } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { BasicDialog } from '@/common';
import DataView from '@/components/DataView';
import AgentsAdd from '@/components/contacts/ContactsView/AgentsAdd';
import CompProfilesAdd from '@/components/contacts/ContactsView/CompProfilesAdd';
import ContactMemosAdd from '@/components/contacts/ContactsView/ContactMemosAdd';
import ContactsLevelsAdd from '@/components/contacts/ContactsView/ContactsLevelsAdd';
import ContactsReferralsAdd from '@/components/contacts/ContactsView/ContactsReferralsAdd';
import ParentsHierarchyAdd from '@/components/contacts/ContactsView/ParentsHierarchyAdd';
import { LoadingContext } from '@/contexts/LoadingContext';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { FieldTypes, Roles } from '@/types';
import ContactsTransactionsAdd from './ContactsTransactionsAdd';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import DataTransformation from '@/services/DataTransformation';
import useSnackbar from '@/contexts/useSnackbar';
import { SyncedEntity } from '@/common/SyncedEntity';
import { useIsBulkAddMode } from '@/hooks/useIsBulkAddMode';
const ContactsView = () => {
    var _a, _b, _c, _d;
    const Normalizer = DataTransformation;
    const { isBulkAddMode } = useIsBulkAddMode();
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.agents;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const [showDelete, setShowDelete] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [showConfig, setShowConfig] = useState({
        open: false,
        refresh: 0,
    });
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams({});
    const { setLoadingConfig } = useContext(LoadingContext);
    const { selectedAccount } = useAccountStore();
    const { showSnackbar } = useSnackbar();
    const { data: contactGroups } = API.getBasicQuery('contacts/groups');
    const { data: contactOptions, isFetched: isFetchedContactOptions } = API.getBasicQuery('contacts/options');
    const contactsDeleter = API.getMutation('report_data/agents', 'DELETE');
    const isAccountTransactionsEnabled = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountingTransactionsEnabled;
    const renderAgentChip = (val, contact) => {
        const startDate = val.start_date ? new Date(val.start_date) : null;
        const endDate = val.end_date ? new Date(val.end_date) : null;
        const currentDate = new Date();
        const isCurrentDateInRange = (startDate === null || currentDate >= startDate) &&
            (endDate === null || currentDate <= endDate);
        const agentName = Formatter.contact(contact, {
            account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
        });
        const dateRange = CommonFormatter.dateRange(val.start_date, val.end_date);
        return (_jsx(Tooltip, { title: dateRange, children: _jsx(Chip, { label: agentName, sx: {
                    color: isCurrentDateInRange ? 'inherit' : '#757575',
                    m: 0.2,
                }, clickable: true, component: Link, to: `/agents/list?id=${contact.str_id}` }) }));
    };
    const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();
    const dataDesc = {
        label: 'Agents',
        table: 'contacts?is_detailed_view=true&hierarchy_depth=1',
        editable: true,
        copyable: true,
        bulkAdd: true,
        extraActions: [],
        fields: [
            [
                {
                    id: 'first_name',
                    label: 'First name',
                    required: true,
                    queryable: true,
                },
                {
                    id: 'last_name',
                    label: 'Last name',
                    queryable: true,
                },
                {
                    id: 'nickname',
                    label: 'Nickname',
                    queryable: true,
                },
            ],
            [
                {
                    id: 'email',
                    label: 'Email',
                    validator: (val) => val === '' || validator.isEmail(val),
                    isUnique: true,
                    existingVals: [],
                    queryable: true,
                },
                {
                    id: 'phone',
                    label: 'Phone',
                    validator: (val) => val === '' || validator.isMobilePhone(val, 'en-US'),
                    queryable: true,
                },
                { id: 'agent_code', label: 'Agent code', queryable: true },
                { id: 'bank_info', label: 'Bank info' },
            ],
            [
                {
                    id: 'contact_group_id',
                    label: 'Agent group',
                    type: 'dynamic-select',
                    table: 'contacts/groups',
                    field: 'id',
                    nullable: true,
                    queryable: true,
                    formatter: (val, collectionVals = []) => {
                        var _a, _b;
                        if (val === '')
                            return '';
                        if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                            const record = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => (datum === null || datum === void 0 ? void 0 : datum.id) === val)) === null || _a === void 0 ? void 0 : _a[0];
                            return (_b = record === null || record === void 0 ? void 0 : record.name) !== null && _b !== void 0 ? _b : '';
                        }
                        return val; // Not formatting when collectionVals is not available
                    },
                    optionFormatter: (option) => option === null || option === void 0 ? void 0 : option.name,
                    optionValuer: (option) => option === null || option === void 0 ? void 0 : option.id,
                    readOnly: (row) => ![null, undefined].includes(row === null || row === void 0 ? void 0 : row.parent_id),
                    tip: 'Group can only be configured for the root agent',
                },
                {
                    id: 'company_name',
                    label: 'Company name',
                    queryable: true,
                    nullable: true,
                },
            ],
            [
                {
                    id: 'type',
                    label: 'Type',
                    type: 'select',
                    options: ['Agent', 'Sales rep', 'IMO'],
                    strToValue: (vals) => { var _a; return (_a = vals === null || vals === void 0 ? void 0 : vals.split(',')) === null || _a === void 0 ? void 0 : _a.map((v) => v.trim()); },
                    queryable: true,
                },
                {
                    id: 'status',
                    label: 'Status',
                    type: 'select',
                    options: [
                        ...new Set([
                            '',
                            'active',
                            'archived',
                            ...((_c = contactOptions === null || contactOptions === void 0 ? void 0 : contactOptions.status) !== null && _c !== void 0 ? _c : []),
                        ]),
                    ],
                    queryable: true,
                    condition: () => isFetchedContactOptions,
                },
                {
                    id: 'payable_status',
                    label: 'Payable status',
                    queryable: true,
                    type: 'select',
                    options: [
                        ...new Set([
                            '',
                            ContactPayableStatuses.PAYABLE,
                            ContactPayableStatuses.NON_PAYABLE,
                            ...((_d = contactOptions === null || contactOptions === void 0 ? void 0 : contactOptions.agent_payable_status) !== null && _d !== void 0 ? _d : []),
                        ]),
                    ],
                    condition: () => isFetchedContactOptions,
                    formatter: (val) => capitalize(val),
                },
            ],
            {
                id: 'level',
                label: 'Grid levels',
                type: 'custom',
                tableFormatter: (field, row) => {
                    if (Array.isArray(row.contact_level)) {
                        return row.contact_level.map((level) => (_jsxs(Box, { children: [level.level_label && (_jsx(Chip, { label: level.level_label, sx: { m: 0.5 } })), level.loa && _jsx(Chip, { label: "LOA", sx: { m: 0.5 } })] }, level.id)));
                    }
                },
                render: (field, row, setter, dynamicSelects) => {
                    var _a;
                    return (_jsx(ContactsLevelsAdd, { data: row, setter: setter, field: field, isSyncedField: isSyncedField, syncedFields: (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[row === null || row === void 0 ? void 0 : row.sync_worker]) === null || _a === void 0 ? void 0 : _a.contact_levels, dynamicSelects: dynamicSelects }, "contact_levels_add"));
                },
            },
            {
                id: 'parent_relationships',
                label: 'Uplines',
                type: 'dynamic-select',
                table: 'contacts',
                multiple: true,
                formatter: (val) => {
                    if (typeof val === 'object') {
                        if (val.parent) {
                            return renderAgentChip(val, val.parent);
                        }
                        else
                            return null;
                    }
                    return val;
                },
                render: (field, row, setter, dynamicSelects) => {
                    var _a;
                    return (_jsx(ParentsHierarchyAdd, { data: row, setter: setter, field: field, syncedFields: (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[row === null || row === void 0 ? void 0 : row.sync_worker]) === null || _a === void 0 ? void 0 : _a.contact_hierarchy, isSyncedField: isSyncedField, dynamicSelects: dynamicSelects }, "parent_relationships_add"));
                },
                bulkAddUnsupported: true,
            },
            {
                id: 'child_relationships',
                label: 'Downlines',
                type: FieldTypes.SELECT,
                multiple: true,
                readOnly: true,
                readOnlyBulkAdd: true,
                linker: (val) => { var _a; return `/agents/list?id=${(_a = val === null || val === void 0 ? void 0 : val.contact) === null || _a === void 0 ? void 0 : _a.str_id}`; },
                formatter: (val) => Formatter.contact(val === null || val === void 0 ? void 0 : val.contact, {
                    account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                }),
                optionFormatter: (val) => Formatter.contact(val === null || val === void 0 ? void 0 : val.contact, {
                    account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                }),
                optionValuer: (val) => val === null || val === void 0 ? void 0 : val.id, // Not used to save
            },
            {
                id: 'contacts_agent_commission_schedule_profiles_sets',
                label: 'Comp profile sets',
                type: 'dynamic-select',
                table: 'schedules/comp-profiles/sets',
                multiple: true,
                field: 'id',
                linker: (val) => { var _a; return `/schedules/comp-profile-sets?id=${(_a = val === null || val === void 0 ? void 0 : val.agent_commission_schedule_profiles_sets) === null || _a === void 0 ? void 0 : _a.str_id}`; },
                formatter: (val, collectionVals = []) => {
                    var _a, _b;
                    const _val = typeof val === 'object'
                        ? val.agent_commission_schedule_profile_set_id
                        : val;
                    if (!Array.isArray(collectionVals))
                        return (_jsx(Skeleton, { variant: "rounded", width: 160, height: 24, sx: { m: 0.25 } }));
                    if (collectionVals.length > 0) {
                        const record = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((datum) => (datum === null || datum === void 0 ? void 0 : datum.id) === _val);
                        const name = record === null || record === void 0 ? void 0 : record.name;
                        return name !== null && name !== void 0 ? name : 'Not found';
                    }
                    else
                        return (_b = (_a = _val === null || _val === void 0 ? void 0 : _val.str_id) !== null && _a !== void 0 ? _a : _val === null || _val === void 0 ? void 0 : _val.id) !== null && _b !== void 0 ? _b : _val === null || _val === void 0 ? void 0 : _val.label;
                },
                optionValuer: (option) => {
                    return option === null || option === void 0 ? void 0 : option.id;
                },
                render: (field, row, setter, dynamicSelects) => (_jsx(CompProfilesAdd, { data: row, setter: setter, field: field, dynamicSelects: dynamicSelects, type: 'set' }, "contacts_agent_commission_schedule_profiles_sets")),
                bulkAddUnsupported: true,
            },
            {
                id: 'contacts_agent_commission_schedule_profiles',
                label: 'Custom comp profiles',
                type: 'dynamic-select',
                table: 'schedules/comp-profiles',
                multiple: true,
                field: 'id',
                linker: (val) => { var _a; return `/schedules/comp-profiles?id=${(_a = val === null || val === void 0 ? void 0 : val.agent_commission_schedule_profile) === null || _a === void 0 ? void 0 : _a.str_id}`; },
                formatter: (val, collectionVals = []) => {
                    var _a, _b;
                    const _val = typeof val === 'object'
                        ? val.agent_commission_schedule_profile_id
                        : val;
                    if (!Array.isArray(collectionVals))
                        return (_jsx(Skeleton, { variant: "rounded", width: 160, height: 24, sx: { m: 0.25 } }));
                    if (collectionVals.length > 0) {
                        const record = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((datum) => (datum === null || datum === void 0 ? void 0 : datum.id) === _val);
                        const name = record === null || record === void 0 ? void 0 : record.name;
                        return name !== null && name !== void 0 ? name : 'Not found';
                    }
                    else
                        return (_b = (_a = _val === null || _val === void 0 ? void 0 : _val.str_id) !== null && _a !== void 0 ? _a : _val === null || _val === void 0 ? void 0 : _val.id) !== null && _b !== void 0 ? _b : _val === null || _val === void 0 ? void 0 : _val.label;
                },
                optionValuer: (option) => {
                    return option === null || option === void 0 ? void 0 : option.id;
                },
                render: (field, row, setter, dynamicSelects) => (_jsx(CompProfilesAdd, { data: row, setter: setter, field: field, dynamicSelects: dynamicSelects }, "contacts_agent_commission_schedule_profiles")),
                bulkAddUnsupported: true,
            },
            {
                id: 'contact_memos',
                label: 'Memos',
                multiple: true,
                field: 'id',
                type: 'custom',
                tableFormatter: (field, _row) => field.length || '',
                render: (field, row, setter, dynamicSelects) => (_jsx(ContactMemosAdd, { data: row, setter: setter, field: field, dynamicSelects: dynamicSelects }, "contact_memos_add")),
                bulkAddUnsupported: true,
            },
            {
                id: 'contact_referrals',
                label: 'Referrals',
                multiple: true,
                field: 'id',
                type: 'custom',
                tableFormatter: (field, _row) => field.length || '',
                render: (field, row, setter, dynamicSelects) => (_jsx(ContactsReferralsAdd, { data: row, setter: setter, field: field, dynamicSelects: dynamicSelects }, "contact_referralss_add")),
                bulkAddUnsupported: true,
            },
            {
                id: 'notes',
                label: 'Notes',
                queryable: true,
            },
            {
                id: 'saved_reports',
                label: 'Saved reports',
                readOnly: true,
                readOnlyBulkAdd: true,
                type: 'select',
                multiple: true,
                formatter: (val) => {
                    if (val)
                        return (_jsx(Chip, { label: val.name, clickable: true, component: Link, to: `/reports/${val.str_id}`, target: "_blank", sx: { m: 0.1 } }, val.str_id));
                    return '';
                },
                optionFormatter: (val) => val.name,
            },
            {
                id: 'user_contact',
                label: 'Fintary access',
                formatter: (s, row) => {
                    var _a, _b;
                    if (s) {
                        const account = (_b = (_a = row === null || row === void 0 ? void 0 : row.user_contact) === null || _a === void 0 ? void 0 : _a.account_user_roles) === null || _b === void 0 ? void 0 : _b.find((account) => account.account_id === (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId));
                        if (account) {
                            return account.state;
                        }
                        else {
                            return 'No access';
                        }
                    }
                    else {
                        return 'No access';
                    }
                },
                tip: 'To grant access, go to Settings > User manager.',
                readOnly: true,
                readOnlyBulkAdd: true,
                queryable: true,
            },
            {
                id: 'str_id',
                label: 'Agent Id',
                queryable: true,
                readOnly: true,
                condition: (val) => val.str_id,
            },
        ],
        actions: [
            {
                label: 'Delete',
                type: 'iconButton',
                icon: _jsx(DeleteOutline, {}),
                onClick: (row) => __awaiter(void 0, void 0, void 0, function* () {
                    setCurrentRow(row);
                    setShowDelete(true);
                }),
            },
            {
                label: 'Synced',
                type: 'icon',
                enabled: (row) => !!row.sync_id,
                icon: _jsx(SyncedEntity, { isSynced: true, disabled: true }),
            },
        ],
        queryChips: {},
    };
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    if (isAccountTransactionsEnabled) {
        dataDesc.fields.push({
            id: 'accounting_transactions',
            label: 'Transactions',
            type: 'custom',
            tableFormatter: (field) => field.length || '',
            render: (field, row, setter) => (_jsx(ContactsTransactionsAdd, { data: row, setter: setter, field: field }, "contact_transactions_add")),
            access: [Roles.ACCOUNT_ADMIN],
        }, {
            id: 'balance',
            label: 'Balance',
            type: FieldTypes.CURRENCY,
            readOnly: true,
            formatter: Normalizer.formatCurrency,
            normalizer: Normalizer.normalizeCurrency,
            access: [Roles.ACCOUNT_ADMIN],
        });
    }
    useEffect(() => {
        if (typeof showConfig === 'boolean') {
            setShowModal(showConfig);
        }
        else {
            setRefresh(showConfig.refresh);
            setShowModal(showConfig.open);
        }
    }, [showConfig]);
    const extraActions = [
        _jsx(Button, { variant: "outlined", startIcon: _jsx(GroupAdd, {}), onClick: () => {
                // SetShowModal(true);
                setShowConfig({ open: true, refresh: 0 });
            }, sx: { mr: 1 }, children: "Create from policies" }, "pullFromPolicies"),
    ];
    // Temporary filter until we move over to EnhancedDataView
    if ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) === AccountIds.TRANSGLOBAL) {
        extraActions.unshift(_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: searchParams.get('show_inactive') === 'true' }), label: "Show inactive", sx: { mr: 1 }, onChange: (e) => {
                setSearchParams((prev) => {
                    if (e.target.checked) {
                        prev.set('show_inactive', 'true');
                    }
                    else {
                        prev.delete('show_inactive');
                    }
                    return prev;
                });
            } }, "inactiveFilter"));
    }
    if (Array.isArray(contactGroups) && contactGroups.length > 0) {
        const queryChips = [
            {
                id: '0',
                name: 'All',
                query: {},
            },
            ...(contactGroups !== null && contactGroups !== void 0 ? contactGroups : []),
        ];
        queryChips.forEach((group) => {
            if (dataDesc)
                dataDesc.queryChips[group === null || group === void 0 ? void 0 : group.id] = {
                    id: String(group === null || group === void 0 ? void 0 : group.id),
                    label: group.name,
                    query: { contact_group_id: group === null || group === void 0 ? void 0 : group.id },
                };
        });
    }
    const DelConfirmComp = ({ row }) => {
        var _a, _b;
        return (_jsx(BasicDialog, { title: "Delete agent", open: showDelete, onClose: (val) => {
                var _a, _b;
                if (val) {
                    const accountUserRole = (_b = (_a = row === null || row === void 0 ? void 0 : row.user_contact) === null || _a === void 0 ? void 0 : _a.account_user_roles) === null || _b === void 0 ? void 0 : _b.find((accountUserRole) => accountUserRole.account_id === (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId));
                    if (!row.user_contact || accountUserRole.state === 'deleted') {
                        setLoadingConfig({
                            loading: true,
                            message: 'Deleting...',
                        });
                        const params = { ids: [row.id], strIds: [row.str_id] };
                        contactsDeleter
                            .mutateAsync(params)
                            .then(() => {
                            setRefresh(refresh + 1);
                            setLoadingConfig({
                                loading: false,
                                message: '',
                                delayToClose: 1000,
                            });
                            setCurrentRow(null);
                        })
                            .catch((err) => {
                            showSnackbar(err, 'error');
                            setLoadingConfig({
                                loading: false,
                                message: '',
                            });
                        });
                    }
                    else {
                        setShowDelete(false);
                        setCurrentRow(null);
                    }
                }
                else {
                    setShowDelete(false);
                    setCurrentRow(null);
                }
            }, bodyComponent: ((_b = (_a = row === null || row === void 0 ? void 0 : row.user_contact) === null || _a === void 0 ? void 0 : _a.account_user_roles) === null || _b === void 0 ? void 0 : _b.find((account) => account.account_id === (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId)).state) === 'deleted' || !row.user_contact ? (_jsxs(Alert, { severity: "warning", children: ["Are you sure you want to delete this agent?", _jsx("br", {}), _jsx("br", {}), Formatter.contact(row, {
                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                    })] })) : (_jsxs(Alert, { severity: "warning", children: ["You can't delete the contact", ' ', Formatter.contact(row, {
                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                    }), ' ', "because it has a user associated. Please delete the user first."] })), positiveLabel: "Delete" }));
    };
    const disableSyncedFields = (field) => {
        if (dataDesc.bulkAdd && isBulkAddMode)
            field.readOnly = field.readOnlyBulkAdd || false;
        else {
            field.readOnly =
                field.readOnly ||
                    ((data) => {
                        var _a;
                        const syncedFields = (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.contacts;
                        if (syncedFields === null || syncedFields === void 0 ? void 0 : syncedFields.includes(field.id)) {
                            return isSyncedField(data, syncedFields, field.id, data.config);
                        }
                        return false;
                    });
        }
        field.endAdornment = (data, field, setNewData) => {
            var _a;
            return (_jsx(SyncEndAdornment, { syncedFields: (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.contacts, syncId: data === null || data === void 0 ? void 0 : data.sync_id, fieldId: field === null || field === void 0 ? void 0 : field.id, data: data, fieldType: field.type, onChange: (newOverrideFields) => {
                    setNewData(Object.assign(Object.assign({}, data), { config: Object.assign(Object.assign({}, (data.config || {})), { overrideFields: newOverrideFields }) }));
                } }));
        };
    };
    dataDesc.fields.forEach((field) => {
        if (Array.isArray(field)) {
            field.forEach(disableSyncedFields);
        }
        else {
            disableSyncedFields(field);
        }
    });
    return (_jsxs(_Fragment, { children: [dataDesc && (_jsx(DataView, { headingOffset: 104, dataDesc: dataDesc, extraActions: extraActions, refresh: refresh, viewOnly: viewOnly, readOnly: viewOnly, enablePagination: true })), showModal && _jsx(AgentsAdd, { open: showModal, setOpen: setShowConfig }), showDelete && currentRow && _jsx(DelConfirmComp, { row: currentRow })] }));
};
export default ContactsView;
