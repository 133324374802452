var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AccountCircle, Business, CampaignOutlined, ExpandMore, HelpOutline, LogoutOutlined, } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Typography, } from '@mui/material';
import { feedbackIntegration } from '@sentry/react';
import { useEffect, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useAccountStore } from '@/store';
import { States } from '@/types';
import { mapAccountKeys } from '@/utils/account';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
export const UserMenu = ({ onHelpClick, onSignOutClick, user, }) => {
    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    const [accountList, setAccountList] = useState([]);
    const { selectedAccount, setSelectedAccount, logoUrl, setLogoUrl, userState, } = useAccountStore();
    const { downloadFile } = useDownloadStorageFile();
    useEffect(() => {
        const accounts = userState === null || userState === void 0 ? void 0 : userState.userAccounts.map((acc) => mapAccountKeys(acc.account));
        if (accounts)
            setAccountList(accounts);
    }, [userState]);
    const overallState = userState === null || userState === void 0 ? void 0 : userState.userOverallState;
    useEffect(() => {
        if (user && overallState === States.ACTIVE) {
            const getSetUrl = () => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    if (!(selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId))
                        return;
                    const file = yield downloadFile({
                        endpoint_str_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                        file_preview_type: 'logo',
                        endpoint: 'accounts',
                    });
                    if (!file)
                        return;
                    const fileToUrl = URL.createObjectURL(file);
                    setLogoUrl(fileToUrl);
                }
                catch (e) {
                    console.error('Error getting logo url', e);
                }
            });
            if ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.whiteLabelMode) && (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId)) {
                getSetUrl();
            }
        }
    }, [selectedAccount, overallState, user]);
    const openMenu = (event) => {
        const accountAnchorEl = event.currentTarget;
        setAccountAnchorEl(accountAnchorEl);
    };
    const closeMenu = () => {
        setAccountAnchorEl(null);
    };
    const selectAccount = (account) => {
        setSelectedAccount(account);
        closeMenu();
        localStorage.setItem('triggerReload', Date.now().toString());
        window.location.reload();
    };
    const [feedbackWidget, setFeedbackWidget] = useState(null);
    const feedback = feedbackIntegration({
        autoInject: false,
        triggerLabel: 'Send feedback',
        colorScheme: 'light',
        formTitle: 'Send feedback',
        isEmailRequired: true,
        showBranding: false,
        showName: false,
        submitButtonLabel: 'Submit',
        themeLight: {
            submitBorder: '#2196f3',
            submitBackground: '#2196f3',
            submitBackgroundHover: '#2196f3',
            submitOutlineFocus: '#2196f3',
            inputOutlineFocus: '#2196f3',
        },
    });
    const menuItems = [
        {
            icon: _jsx(HelpOutline, {}),
            name: 'Help',
            onClick: onHelpClick,
        },
        {
            icon: _jsx(CampaignOutlined, {}),
            name: feedbackWidget ? 'Close feedback' : 'Open feedback',
            onClick: () => {
                if (feedbackWidget) {
                    feedbackWidget.removeFromDom();
                    setFeedbackWidget(null);
                }
                else {
                    setFeedbackWidget(feedback.createWidget());
                }
            },
        },
        {
            icon: _jsx(LogoutOutlined, {}),
            name: 'Sign out',
            onClick: onSignOutClick,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Button, { "data-testid": "log_out_button", endIcon: _jsx(ExpandMore, {}), onClick: openMenu, variant: "outlined", sx: { color: 'white' }, children: [_jsx(Typography, { sx: {
                            display: {
                                xs: 'none',
                                md: 'block',
                            },
                        }, variant: "h6", children: user === null || user === void 0 ? void 0 : user.email }), _jsx(PersonIcon, { sx: {
                            display: {
                                xs: 'inline-block',
                                md: 'none',
                            },
                        } })] }), _jsxs(Menu, { anchorEl: accountAnchorEl, open: Boolean(accountAnchorEl), onClose: closeMenu, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, sx: { py: 0 }, children: [_jsxs(MenuItem, { disabled: true, style: { opacity: 1 }, children: [_jsx(ListItemIcon, { children: _jsx(AccountCircle, {}) }), _jsx(ListItemText, { children: user === null || user === void 0 ? void 0 : user.email })] }), !!(accountList === null || accountList === void 0 ? void 0 : accountList.length) && _jsx(Divider, { variant: "middle" }, "divider"), !!(accountList === null || accountList === void 0 ? void 0 : accountList.length) && (_jsxs(Box, { children: [_jsx(Box, { display: "flex", justifyContent: "center", children: _jsx(Typography, { variant: "caption", children: accountList.length > 1 ? 'Accounts' : 'Account' }) }), accountList.map((account) => {
                                var _a;
                                return (_jsxs(MenuItem, { disabled: accountList.length === 1, onClick: () => selectAccount(account), children: [_jsx(ListItemIcon, { children: account.logoUrl && logoUrl ? (_jsx(Avatar, { alt: "Account logo", src: logoUrl, sx: { width: 24, height: 24 } })) : (_jsx(Business, {})) }), _jsx(ListItemText, { children: (_a = account.accountName) !== null && _a !== void 0 ? _a : '(unspecified)' }), (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) === account.accountId && (_jsx(CheckIcon, {}))] }, account.accountId));
                            })] }, "accountList")), _jsx(Divider, { variant: "middle", sx: { mb: 1 } }), menuItems.map((menuItem) => {
                        return (_jsxs(MenuItem, { onClick: () => {
                                var _a;
                                closeMenu();
                                (_a = menuItem.onClick) === null || _a === void 0 ? void 0 : _a.call(menuItem);
                            }, children: [menuItem.icon && _jsx(ListItemIcon, { children: menuItem.icon }), _jsx(ListItemText, { children: menuItem.name })] }, menuItem.name));
                    })] })] }));
};
