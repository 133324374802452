var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import { ContentCopy, Edit } from '@mui/icons-material';
import { Box, Button, Checkbox, Chip, CircularProgress, IconButton, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, useMediaQuery, } from '@mui/material';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { isValidElement, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getFilenameFromPath } from 'common/helpers';
import { HoverActionButtonContainer } from '@/components/HoverActionButtonContainer';
import { cellSx, rowSx } from '@/components/HoverActionButtonContainer/styles';
import EnhancedTableToolbar from '@/components/molecules/EnhancedTableToolbar';
import ExpandableData from '@/components/molecules/ExpandableData';
import MoreMenu from '@/components/molecules/MoreMenu';
import TableRowCard from '@/components/molecules/TableRowCard';
import { UIStateContext } from '@/contexts/UIStateProvider';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { hasAccess, requiresFintaryAdmin } from '@/services/helpers';
import { useAccountStore, useRoleStore } from '@/store';
import { useDragtoScroll } from '@/hooks/useDragtoScroll';
const APP_BAR_HEIGHT = 64;
function descendingComparator(a, b, orderBy) {
    var _c, _d, _e, _f, _g, _h, _j, _k;
    // TODO: Remove this hack
    if (window.location.pathname.startsWith('/reports/summary') &&
        ((_c = a === null || a === void 0 ? void 0 : a.totals) === null || _c === void 0 ? void 0 : _c.agent_commissions) &&
        ((_d = b === null || b === void 0 ? void 0 : b.totals) === null || _d === void 0 ? void 0 : _d.agent_commissions)) {
        const _a = (_g = (_f = Object.values((_e = a.totals.agent_commissions) !== null && _e !== void 0 ? _e : {})) === null || _f === void 0 ? void 0 : _f[0]) !== null && _g !== void 0 ? _g : 0;
        const _b = (_k = (_j = Object.values((_h = b.totals.agent_commissions) !== null && _h !== void 0 ? _h : {})) === null || _j === void 0 ? void 0 : _j[0]) !== null && _k !== void 0 ? _k : 0;
        if (_b < _a) {
            return -1;
        }
        if (_b > _a) {
            return 1;
        }
        return 0;
    }
    if (window.location.pathname.startsWith('/documents') &&
        orderBy === 'file_path') {
        return getFilenameFromPath(b[orderBy]).localeCompare(getFilenameFromPath(a[orderBy]));
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
const SelectDbValsShowMore = ({ data }) => {
    const [showMore, setShowMore] = useState(false);
    return (_jsxs(Box, { children: [data.slice(0, 6).map((item, i) => (_jsx(Box, { children: item }, i))), showMore && data.slice(6).map((item, i) => _jsx(Box, { children: item }, i)), data.length > 6 && (_jsx(Button, { onClick: () => setShowMore(!showMore), sx: { color: '#555' }, children: showMore ? 'Show less' : `Show ${data.length - 6} more` }))] }));
};
const EnhancedTableHead = ({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, onDelete, actionsCol, options = {}, }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (_jsx(TableHead, { children: _jsxs(TableRow, { children: [onDelete && (_jsx(TableCell, { padding: "checkbox", children: _jsx(Checkbox, { color: "primary", indeterminate: numSelected > 0 && numSelected < rowCount, checked: rowCount > 0 && numSelected === rowCount, onChange: onSelectAllClick, inputProps: {
                            'aria-label': 'Select all',
                        } }) })), options.radio && _jsx(TableCell, {}), headCells.map((headCell) => {
                    var _c, _d;
                    return (_jsx(TableCell, { align: headCell.numeric ? 'right' : 'left', padding: "none", sx: Object.assign(Object.assign({ p: 1, whiteSpace: 'nowrap', display: headCell.visible === false || headCell.hidden === true
                                ? 'none'
                                : 'table-cell' }, headCell.sx), headCell.headerSx), sortDirection: orderBy === headCell.id ? order : false, children: headCell.disableSort ? (_jsx(Tooltip, { title: headCell.description, placement: "top", children: _jsxs("span", { children: [headCell.label, headCell.infoIcon && ' ⓘ', requiresFintaryAdmin(headCell.access) && ' 🔒'] }) })) : (_jsx(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: (evt) => {
                                if (headCell.disableSort)
                                    return;
                                createSortHandler(headCell.id)(evt);
                            }, children: _jsx(Tooltip, { title: headCell.description, placement: "top", children: _jsxs("span", { children: [headCell.label, headCell.infoIcon && ' ⓘ', requiresFintaryAdmin(headCell.access) && ' 🔒'] }) }) }, headCell.id)) }, `${(_c = headCell.source) !== null && _c !== void 0 ? _c : ''}${headCell.id}${(_d = headCell.id2) !== null && _d !== void 0 ? _d : ''}`));
                }), actionsCol && _jsx(TableCell, {})] }) }));
};
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const EnhancedTable = ({ title, outstandingFieldsInMobileView, headers: _headers, rows, rowKey, dense, readOnly = false, onEdit, onBulkEdit = () => { }, onBulkSync = undefined, onDelete, // Original behavior is only onDelete
onClick, // Adding onClick while preserving default onDelete
actions = [], stickyHeader, actionsEnabled = () => false, paginated = false, controlledOrdering, controlledPagination, setSelectedData = () => { }, // TODO: Should be controlled selection...hack for now
options = {}, refetch = () => { }, showTotals = false, totals = {}, customHeaderActions = false, nonSelectableOnMobile = false, headingOffset = 109, }) => {
    var _c;
    const { refContainer } = useDragtoScroll();
    const navigate = useNavigate();
    const { selectedAccount } = useAccountStore();
    const [dynamicSelects, setDynamicSelects] = useState({});
    const [selected, setSelected] = useState([]);
    const [actionLoading, setActionLoading] = useState({});
    const getSetActionLoadingByRowId = (id) => (state) => {
        setActionLoading(Object.assign(Object.assign({}, actionLoading), { [id]: state }));
        if (!state)
            setTimeout(refetch, 250);
    };
    const [sums, setSums] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(paginated ? 50 : 999999);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [innerOrderControl, setInnerOrderControl] = useState({
        order,
        orderBy,
        setOrder,
        setOrderBy,
    });
    const { userRole } = useRoleStore();
    const { role: [role], } = useContext(UIStateContext);
    const headers = (_c = _headers === null || _headers === void 0 ? void 0 : _headers.flat()) !== null && _c !== void 0 ? _c : [];
    const dynamicSelectsPoster = API.getMutation('dynamic_selects', 'POST', {
        gcTime: 1,
    });
    useEffect(() => {
        return () => {
            dynamicSelectsPoster.abort();
        };
    }, []);
    useEffect(() => {
        const _order = order || (controlledOrdering && controlledOrdering.order);
        if (_order) {
            setInnerOrderControl((pre) => {
                return Object.assign(Object.assign({}, pre), { order: _order });
            });
        }
        const _orderBy = orderBy || (controlledOrdering && controlledOrdering.orderBy);
        if (_orderBy) {
            setInnerOrderControl((pre) => {
                return Object.assign(Object.assign({}, pre), { orderBy: _orderBy });
            });
        }
    }, [order, orderBy, controlledOrdering]);
    // Reset list of selected items if the underlying dataset changes. Using length as proxy for now.
    useEffect(() => {
        setSelected([]);
        setSelectedData([]);
    }, [rows === null || rows === void 0 ? void 0 : rows.length]); // If setSelectedData is added as a dependency, it will cause an infinite loop.
    useEffect(() => {
        const dynamicSelectVals = headers
            .filter((field) => field.table)
            .map((field) => ({
            table: field.table,
            queryParamTable: field.queryParamTable,
            queryParamNameTable: field.queryParamNameTable,
        }));
        const uniqueDynamicSelectVals = Array.from(new Set(dynamicSelectVals.map(JSON.stringify))).map(JSON.parse);
        getDynamicSelects(uniqueDynamicSelectVals);
    }, []);
    const getDynamicSelects = (dynamicSelectVals) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const data = yield dynamicSelectsPoster.mutateAsync(dynamicSelectVals);
            if (Array.isArray(data) && data.length > 0) {
                data.forEach((field) => {
                    setDynamicSelects((prev) => (Object.assign(Object.assign({}, prev), field)));
                });
            }
        }
        catch (e) {
            console.error(e);
        }
    });
    const headersFiltered = headers
        .filter((h) => hasAccess(h.access, userRole, role === 'admin'))
        .filter((h) => !Array.isArray(h.visible) || h.visible.includes('table'))
        .filter((h) => !['heading', 'divider'].includes(h.type));
    // Logic for calculating totals for certain columns based on the headers
    const columns = headersFiltered.map((header) => header.id);
    useEffect(() => {
        if (totals) {
            const newSums = columns.map((col) => {
                return totals[col] ? totals[col] : undefined;
            });
            if (JSON.stringify(newSums) !== JSON.stringify(sums)) {
                setSums(newSums);
            }
        }
    }, [columns, sums, totals]);
    if (!Array.isArray(rows)) {
        return null;
    }
    const handleRequestSort = (event, property) => {
        const isAsc = innerOrderControl.orderBy === property &&
            innerOrderControl.order === 'asc';
        innerOrderControl.setOrder(isAsc ? 'desc' : 'asc');
        if ((controlledOrdering === null || controlledOrdering === void 0 ? void 0 : controlledOrdering.setOrder) &&
            typeof (controlledOrdering === null || controlledOrdering === void 0 ? void 0 : controlledOrdering.setOrder) === 'function') {
            controlledOrdering.setOrder(isAsc ? 'desc' : 'asc');
        }
        innerOrderControl.setOrderBy(property);
        if ((controlledOrdering === null || controlledOrdering === void 0 ? void 0 : controlledOrdering.setOrderBy) &&
            typeof (controlledOrdering === null || controlledOrdering === void 0 ? void 0 : controlledOrdering.setOrderBy) === 'function') {
            controlledOrdering.setOrderBy(property);
        }
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            setSelectedData(rows);
            return;
        }
        setSelected([]);
        setSelectedData([]);
    };
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (options.radio) {
            newSelected = [name];
        }
        else if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        }
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        }
        else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
        setSelectedData(rows.filter((row) => newSelected.includes(row.id)));
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
    const rowsPerPageToUse = controlledPagination
        ? controlledPagination.rowsPerPage
        : rowsPerPage;
    const orderToUse = innerOrderControl.order; // ControlledOrdering ? controlledOrdering.order : order;
    // const orderByToUse = controlledOrdering
    //   ? controlledOrdering.orderBy
    //   : orderBy;
    const orderByToUse = innerOrderControl.orderBy;
    const rowsSorted = controlledPagination
        ? rows
        : rows.sort(getComparator(orderToUse, orderByToUse));
    const deleteResetSelected = (selected) => __awaiter(void 0, void 0, void 0, function* () {
        yield onDelete(selected);
        setSelected([]);
        setSelectedData([]);
    });
    const isMobile = useMediaQuery('(max-width:600px)');
    return (_jsxs(Box, { sx: { width: '100%' }, children: [!options.hideSelectedCount && (_jsx(EnhancedTableToolbar, { title: title, selected: selected, setSelected: setSelected, onDelete: !options.radio
                    ? (selected) => {
                        deleteResetSelected(selected);
                    }
                    : undefined, headers: headersFiltered, onEdit: !options.radio
                    ? (selected, updateData) => __awaiter(void 0, void 0, void 0, function* () {
                        yield onBulkEdit(selected, updateData);
                    })
                    : undefined, onSync: onBulkSync
                    ? (selected, updateData) => __awaiter(void 0, void 0, void 0, function* () { return yield onBulkSync(selected, updateData); })
                    : undefined, actionsCol: (actions === null || actions === void 0 ? void 0 : actions.length) > 0, customActions: customHeaderActions })), _jsx(TableContainer, { ref: refContainer, sx: {
                    height: `calc(100vh - ${headingOffset + APP_BAR_HEIGHT}px - ${!options.hideSelectedCount && selected.length > 0 ? 48 : 0}px)`,
                    overflowX: isMobile ? 'clip' : 'auto',
                    overflowY: 'auto',
                    pb: '42px',
                }, children: _jsxs(Table, { sx: {
                        tableLayout: isMobile ? 'fixed' : 'auto',
                    }, "aria-labelledby": "tableTitle", size: dense ? 'small' : 'medium', stickyHeader: stickyHeader, sx: isMobile
                        ? {
                            '.MuiTableCell-root': {
                                borderBottom: 'none',
                            },
                        }
                        : {}, children: [!isMobile && (_jsx(EnhancedTableHead, { numSelected: selected.length, order: orderToUse, orderBy: orderByToUse, onSelectAllClick: handleSelectAllClick, onRequestSort: handleRequestSort, rowCount: rows.length, headCells: [
                                ...headersFiltered,
                                typeof onEdit === 'function' || (actions === null || actions === void 0 ? void 0 : actions.length) > 0
                                    ? {
                                        id: 'actions',
                                        disablePadding: false,
                                        label: '',
                                        disableSort: true,
                                        visible: true,
                                        sx: { minWidth: 80 },
                                    }
                                    : undefined,
                            ].filter((header) => header !== undefined), onDelete: !options.radio ? onDelete : undefined, options: options })), _jsxs(TableBody, { children: [rowsSorted
                                    .slice(page * rowsPerPageToUse, page * rowsPerPageToUse + rowsPerPageToUse)
                                    .map((row, index) => {
                                    var _c;
                                    const isItemSelected = row.id
                                        ? isSelected(row.id)
                                        : isSelected(Object.values(row).join('-'));
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return isMobile ? (_jsx(TableRowCard, { row: row, headersFiltered: headersFiltered, outstandingFieldsInMobileView: outstandingFieldsInMobileView, isItemSelected: isItemSelected, handleClick: handleClick, onDelete: onDelete, onClick: onClick, onEdit: onEdit, readOnly: readOnly, options: options, dynamicSelects: dynamicSelects, navigate: navigate, labelId: labelId, nonSelectable: nonSelectableOnMobile }, index)) : (_jsxs(TableRow, { hover: true, onClick: (event) => {
                                            if (onDelete instanceof Function) {
                                                handleClick(event, row.id || Object.values(row).join('-'));
                                            }
                                            else if (onClick instanceof Function && !onDelete) {
                                                onClick(row);
                                            }
                                        }, role: "checkbox", "aria-checked": isItemSelected, tabIndex: -1, selected: isItemSelected, sx: Object.assign(Object.assign(Object.assign({}, rowSx), { '& .action-buttons-visible': {
                                                visibility: 'visible',
                                            }, position: 'relative' }), (row.state === 'grouped'
                                            ? {
                                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                                opacity: 0.65,
                                            }
                                            : {})), children: [onDelete && (_jsx(TableCell, { padding: "checkbox", children: options.radio ? (_jsx(Radio, { checked: isItemSelected })) : (_jsx(Checkbox, { color: "primary", checked: isItemSelected, inputProps: {
                                                        'aria-labelledby': labelId,
                                                    } })) })), headersFiltered.map((header) => {
                                                var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                                                const headerKey = (_c = header.keyAs) !== null && _c !== void 0 ? _c : header.id;
                                                const formatter = (x, dynamicSelects) => {
                                                    if (header.tableFormatter instanceof Function) {
                                                        return header.tableFormatter(x, row, dynamicSelects, header);
                                                    }
                                                    if (header.formatter instanceof Function) {
                                                        if (dynamicSelects) {
                                                            // TODO: Allow formatters to be applied when values are null
                                                            if (!x)
                                                                return null;
                                                            if (isValidElement(x))
                                                                return x;
                                                            if (isValidElement(header.formatter(x, dynamicSelects)))
                                                                return header.formatter(x, dynamicSelects);
                                                            return (_jsx(Chip, { label: header.formatter(x, dynamicSelects), sx: { m: 0.25 }, clickable: typeof header.linker === 'function', component: typeof header.linker === 'function'
                                                                    ? Link
                                                                    : 'div', to: typeof header.linker === 'function'
                                                                    ? header.linker(x)
                                                                    : undefined }));
                                                        }
                                                        if (x instanceof Object) {
                                                            // Return JSON.stringify(x);
                                                            const res = header.formatter(x, row, navigate);
                                                            if (res === null)
                                                                return null;
                                                            return typeof res === 'object' &&
                                                                !isValidElement(res)
                                                                ? '---'
                                                                : res;
                                                        }
                                                        return header.formatter(x, row, navigate);
                                                    }
                                                    if (x instanceof Object && !isValidElement(x)) {
                                                        return JSON.stringify(x);
                                                    }
                                                    if (header.type === 'boolean') {
                                                        return x ? 'Yes' : 'No';
                                                    }
                                                    return x;
                                                };
                                                let getter = header.getter;
                                                if (!getter) {
                                                    getter =
                                                        header.type === 'select' &&
                                                            typeof ((_d = header.options) === null || _d === void 0 ? void 0 : _d[0]) === 'object'
                                                            ? (x) => {
                                                                var _c;
                                                                return (_c = header.options.find((option) => option.id === x[headerKey])) === null || _c === void 0 ? void 0 : _c.label;
                                                            }
                                                            : (x) => x[headerKey];
                                                }
                                                return (_jsx(TableCell, { align: "left", sx: Object.assign({ p: 1, minWidth: (row === null || row === void 0 ? void 0 : row[header.id]) ? 100 : 0, display: header.visible === false || header.hidden === true
                                                            ? 'none'
                                                            : 'table-cell' }, header.sx), children: _jsxs(Box, { sx: {
                                                            whiteSpace: header.copyable ? 'nowrap' : 'normal',
                                                            cursor: header.onClick ? 'pointer' : 'default',
                                                        }, onClick: (e) => {
                                                            e.stopPropagation();
                                                            header.onClick instanceof Function &&
                                                                header.onClick(row);
                                                        }, children: [_jsx(Box, { sx: {
                                                                    display: header.copyable
                                                                        ? 'inline-flex'
                                                                        : 'inherit',
                                                                }, children: row[headerKey] !== undefined ? (Array.isArray(row[header.id]) &&
                                                                    !header.tableFormatter ? (header.id === 'notes' ? (_jsx(Tooltip, { title: _jsx(Box, { children: row[header.id].map((item) => (_jsx(Box, { sx: { my: 0.5 }, children: item }, item))) }), children: _jsx("span", { style: { whiteSpace: 'nowrap' }, children: row[header.id].length
                                                                            ? `⚠️ ${row[header.id].length} conflicts`
                                                                            : null }) })) : (_jsx(SelectDbValsShowMore, { data: row[header.id].map((item, i) => {
                                                                        var _c, _d, _e, _f;
                                                                        const formattedVal = formatter(item, (_d = (_c = dynamicSelects[header.table]) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : dynamicSelects[header.table], (_f = (_e = dynamicSelects[header.table]) === null || _e === void 0 ? void 0 : _e.count) !== null && _f !== void 0 ? _f : undefined);
                                                                        if (formattedVal === null)
                                                                            return null;
                                                                        return (_jsxs("span", { children: [header.type ===
                                                                                    'dynamic-select' ? (formattedVal) : isValidElement(formattedVal) ? (formattedVal) : (_jsx(Chip, { label: formattedVal, sx: {
                                                                                        m: 0.25,
                                                                                        cursor: header.itemOnClick ||
                                                                                            header.linker
                                                                                            ? 'pointer'
                                                                                            : 'default',
                                                                                        maxWidth: 500,
                                                                                    }, onClick: (e) => {
                                                                                        e.stopPropagation();
                                                                                        typeof header.itemOnClick ===
                                                                                            'function' &&
                                                                                            header.itemOnClick(item);
                                                                                    }, clickable: typeof header.linker ===
                                                                                        'function', component: typeof header.linker ===
                                                                                        'function'
                                                                                        ? Link
                                                                                        : 'div', to: typeof header.linker ===
                                                                                        'function'
                                                                                        ? header.linker(item)
                                                                                        : undefined }, item)), header.delimiter &&
                                                                                    i < row[header.id].length - 1 &&
                                                                                    header.delimiter] }, typeof item === 'object'
                                                                            ? JSON.stringify(item)
                                                                            : item));
                                                                    }) }))) : (formatter(header.id2 &&
                                                                    !(row[headerKey] instanceof Object)
                                                                    ? ((_g = (_f = (_e = row[headerKey]) === null || _e === void 0 ? void 0 : _e[header.id2]) === null || _f === void 0 ? void 0 : _f[headerKey]) !== null && _g !== void 0 ? _g : (_j = (_h = row[headerKey]) === null || _h === void 0 ? void 0 : _h[header.id2]) === null || _j === void 0 ? void 0 : _j[header.id2])
                                                                    : getter(row), header.table
                                                                    ? ((_l = (_k = dynamicSelects[header.table]) === null || _k === void 0 ? void 0 : _k.data) !== null && _l !== void 0 ? _l : dynamicSelects[header.table])
                                                                    : undefined, header.table
                                                                    ? ((_o = (_m = dynamicSelects[header.table]) === null || _m === void 0 ? void 0 : _m.count) !== null && _o !== void 0 ? _o : undefined)
                                                                    : undefined))) : ('') }), header.copyable && (_jsx(IconButton, { onClick: (e) => {
                                                                    var _c, _d, _e, _f, _g;
                                                                    e.stopPropagation();
                                                                    const valUnformatted = header.id2 &&
                                                                        !(row[headerKey] instanceof Object)
                                                                        ? ((_e = (_d = (_c = row[headerKey]) === null || _c === void 0 ? void 0 : _c[header.id2]) === null || _d === void 0 ? void 0 : _d[headerKey]) !== null && _e !== void 0 ? _e : (_g = (_f = row[headerKey]) === null || _f === void 0 ? void 0 : _f[header.id2]) === null || _g === void 0 ? void 0 : _g[header.id2])
                                                                        : getter(row);
                                                                    const valFormatted = formatter(valUnformatted);
                                                                    if (typeof valFormatted === 'string') {
                                                                        copy(valFormatted);
                                                                    }
                                                                    else {
                                                                        copy(valUnformatted);
                                                                    }
                                                                }, sx: {
                                                                    opacity: 0,
                                                                    '&:hover': { opacity: 1 },
                                                                }, children: _jsx(ContentCopy, {}) }))] }) }, `${(_p = header.source) !== null && _p !== void 0 ? _p : ''}${header.id}${(_q = header.id2) !== null && _q !== void 0 ? _q : ''}`));
                                            }), !readOnly && (_jsx(TableCell, { sx: cellSx, children: actionLoading[row.id] ? (_jsx(Box, { sx: { display: 'flex', justifyContent: 'center' }, children: _jsx(Box, { sx: {
                                                            borderRadius: 8,
                                                            backdropFilter: 'blur(8px)',
                                                            display: 'flex',
                                                        }, children: _jsx(CircularProgress, { disableShrink: true, size: 22, thickness: 4 }) }) })) : (_jsxs(HoverActionButtonContainer, { className: actionLoading[row.id]
                                                        ? 'action-buttons-visible'
                                                        : '', children: [typeof onEdit === 'function' && (_jsx(IconButton, { onClick: () => {
                                                                onEdit(row);
                                                            }, children: _jsx(Edit, {}) })), (actions === null || actions === void 0 ? void 0 : actions.length) > 0 && actionsEnabled(row) && (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [(_c = actions === null || actions === void 0 ? void 0 : actions.filter((action) => [
                                                                    'button',
                                                                    'iconButton',
                                                                    'icon',
                                                                    'custom',
                                                                ].includes(action.type) &&
                                                                    (!action.enabled ||
                                                                        (typeof action.enabled === 'function'
                                                                            ? action.enabled(row)
                                                                            : action.enabled)))) === null || _c === void 0 ? void 0 : _c.map((action) => {
                                                                    const setRowActionLoading = getSetActionLoadingByRowId(row.id);
                                                                    switch (action.type) {
                                                                        case 'button':
                                                                            return (_jsx(Button, { variant: "text", onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                                                                                    setRowActionLoading(true);
                                                                                    yield action.onClick(row, e);
                                                                                    setRowActionLoading(false);
                                                                                }), children: action.label }, action.label));
                                                                        case 'iconButton':
                                                                            return (_jsx(IconButton
                                                                            // Sx={{ p: 0 }}
                                                                            , { onClick: (e) => __awaiter(void 0, void 0, void 0, function* () {
                                                                                    setRowActionLoading(true);
                                                                                    yield action.onClick(row, e);
                                                                                    setRowActionLoading(false);
                                                                                }), children: action.icon }, action.label));
                                                                        case 'icon':
                                                                            return (_jsx(Box, { sx: {
                                                                                    height: 32,
                                                                                    display: 'flex',
                                                                                }, children: action.icon }, action.label));
                                                                        case 'custom':
                                                                            return (_jsx(Box, { sx: {
                                                                                    height: 32,
                                                                                    display: 'flex',
                                                                                }, children: action.getComponent(row) }, action.id));
                                                                        default:
                                                                            return null;
                                                                    }
                                                                }), (actions === null || actions === void 0 ? void 0 : actions.filter((action) => ![
                                                                    'button',
                                                                    'iconButton',
                                                                    'icon',
                                                                    'custom',
                                                                ].includes(action.type) &&
                                                                    (!action.enabled ||
                                                                        (typeof action.enabled === 'function'
                                                                            ? action.enabled(row)
                                                                            : action.enabled))).length) > 0 && (_jsx(MoreMenu, { actions: actions.filter((action) => ![
                                                                        'button',
                                                                        'iconButton',
                                                                        'icon',
                                                                        'custom',
                                                                    ].includes(action.type) &&
                                                                        (!action.enabled ||
                                                                            (typeof action.enabled === 'function'
                                                                                ? action.enabled(row)
                                                                                : action.enabled))), setActionLoading: getSetActionLoadingByRowId(row.id), data: row }))] }))] })) }))] }, row.id || row.uid || row.str_id || row[rowKey]));
                                }), showTotals && !isMobile && (_jsxs(TableRow, { children: [_jsx(TableCell, { sx: { pr: 0.5 }, children: _jsx("strong", { children: "Totals" }) }), columns.map((column, index) => (_jsx(TableCell, { sx: { px: 1 }, children: sums[index] ? (typeof sums[index] === 'object' ? (_jsx(Box, { sx: { minWidth: 250 }, children: _jsx(ExpandableData, { data: Object.entries(sums[index]).map(([k, v]) => {
                                                        var _c, _d;
                                                        return `${Formatter.contact((_d = (_c = dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.contacts) === null || _c === void 0 ? void 0 : _c.find((c) => c.str_id === k)) !== null && _d !== void 0 ? _d : {}, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId })}: ${Formatter.currency(v)}`;
                                                    }), header: headersFiltered[index] }) })) : (Formatter.currency(sums[index]))) : null }, `Sum-${column}-${index}`))), (typeof onEdit === 'function' || (actions === null || actions === void 0 ? void 0 : actions.length) > 0) &&
                                            !readOnly && _jsx(TableCell, { sx: { padding: 0, width: 0 } })] })), emptyRows > 0 && !isMobile && (_jsx(TableRow, { style: {
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }, children: _jsx(TableCell, { colSpan: 6 }) }))] })] }) }), paginated &&
                (controlledPagination ? (_jsx(TablePagination, { rowsPerPageOptions: [50, 250, 500, 1000], component: "div", count: controlledPagination.count, rowsPerPage: controlledPagination.rowsPerPage, page: controlledPagination.page, onPageChange: controlledPagination.onPageChange, onRowsPerPageChange: controlledPagination.onRowsPerPageChange, sx: {
                        '&.MuiTablePagination-root': {
                            position: 'fixed',
                            bottom: 0,
                            right: 0,
                            background: 'white',
                        },
                        '& .MuiTablePagination-toolbar': {
                            minHeight: 42,
                            height: 42,
                            pl: 2,
                        },
                    } })) : (_jsx(TablePagination, { rowsPerPageOptions: [50, 250, 500, 1000], component: "div", count: rows.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, sx: {
                        '&.MuiTablePagination-root': {
                            position: isMobile ? 'fixed' : 'static',
                            bottom: 0,
                            background: 'white',
                        },
                        '& .MuiTablePagination-toolbar': {
                            minHeight: 42,
                            height: 42,
                            pl: 2,
                        },
                    } })))] }));
};
export default EnhancedTable;
