var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import { useInterval } from 'react-use';
let intervalId = null;
const useAutoPolling = (apiFunction, checkCondition, delay, shouldPoll, limit = 5 * 1000 * 60) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    // Limit polling total times
    const [totalPollingTimes, setTotalPollingTimes] = useState(0);
    const [shouldStartPoll, setShouldStartPoll] = useState(false);
    useEffect(() => {
        const _totalTime = totalPollingTimes * delay;
        if (_totalTime >= limit) {
            setShouldStartPoll(true);
            console.log('polling limit reached, stop polling...');
        }
    }, [totalPollingTimes, limit]);
    useEffect(() => {
        // if (!shouldPoll) {
        //   clearInterval(intervalId);
        // }
        setShouldStartPoll(shouldPoll);
    }, [shouldPoll]);
    useInterval(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            try {
                const response = yield apiFunction();
                setTotalPollingTimes((prev) => prev + 1);
                if (checkCondition(response)) {
                    clearInterval(intervalId);
                    setData(response);
                }
            }
            catch (error) {
                console.error('polling error:', error);
                clearInterval(intervalId);
                setShouldStartPoll(false);
            }
            finally {
                setLoading(false);
            }
        });
        if (shouldStartPoll) {
            if (loading) {
                return;
            }
            if (intervalId) {
                clearInterval(intervalId);
            }
            fetchData();
            console.log(shouldStartPoll ? 'polling start...' : 'polling stop...');
            intervalId = setInterval(fetchData, delay);
            if (!shouldStartPoll) {
                clearInterval(intervalId);
            }
        }
    }, delay);
    return { data, loading };
};
export default useAutoPolling;
