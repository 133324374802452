var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import React from 'react';
function BasicDialog(props) {
    const { onClose, open, title, bodyComponent, negativeLabel = 'Cancel', positiveLabel = 'Ok', loading, sxContent } = props, other = __rest(props, ["onClose", "open", "title", "bodyComponent", "negativeLabel", "positiveLabel", "loading", "sxContent"]);
    const handleCancel = () => {
        onClose();
    };
    const handleOk = () => {
        onClose(true);
    };
    return (_jsxs(Dialog, Object.assign({ sx: { '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }, maxWidth: "xs", open: open }, other, { children: [_jsx(DialogTitle, { children: title }), _jsx(DialogContent, { dividers: true, sx: sxContent, children: bodyComponent }), _jsxs(DialogActions, { sx: { px: 3, py: 2 }, children: [_jsx(Button, { autoFocus: true, onClick: handleCancel, children: negativeLabel }), _jsx(Button, { onClick: handleOk, variant: "contained", children: loading ? 'Loading...' : positiveLabel })] })] })));
}
export default BasicDialog;
