export var DataProcessingTypes;
(function (DataProcessingTypes) {
    DataProcessingTypes["AGENT_COMMISSION_CALC"] = "agent_commission_calc";
    DataProcessingTypes["GROUPING"] = "grouping";
    DataProcessingTypes["RECONCILIATION"] = "reconciliation";
    DataProcessingTypes["DATA_SYNC"] = "data_sync";
    DataProcessingTypes["SYNC"] = "sync";
    DataProcessingTypes["DOCUMENT_PROCESSING"] = "document_processing";
    DataProcessingTypes["DOCUMENT_EXTRACTION"] = "document_extraction";
})(DataProcessingTypes || (DataProcessingTypes = {}));
