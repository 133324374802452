import { jsx as _jsx } from "react/jsx-runtime";
import { ContentCopy, Label, Login } from '@mui/icons-material';
import { Box, Chip, IconButton } from '@mui/material';
import copy from 'copy-to-clipboard';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
const dataSpec = {
    label: 'Users',
    table: 'admin/users',
    fields: {
        uid: {
            label: 'Uid',
            id: 'uid',
            copyable: true,
            enabled: true,
            visible: true,
        },
        email: {
            label: 'Email',
            enabled: true,
            visible: true,
        },
        first_name: {
            label: 'First name',
            enabled: true,
            visible: true,
        },
        last_name: {
            label: 'Last name',
            enabled: true,
            visible: true,
        },
        type: {
            label: 'Type',
            enabled: true,
            visible: true,
        },
        sub_type: {
            label: 'Sub type',
            enabled: true,
            visible: true,
        },
        account_user_roles: {
            label: 'Log in as user/account',
            enabled: true,
            visible: true,
            formatter: (fieldData, rowData) => {
                return (_jsx(Chip, { label: `${fieldData.account.name} (${fieldData.role.name})`, onClick: () => {
                        localStorage.clear();
                        localStorage.setItem('customLoginUser', JSON.stringify(rowData));
                        localStorage.setItem('selectedAccount', JSON.stringify({
                            accountId: fieldData.account.str_id,
                            accountName: fieldData.account.name,
                            accountMode: fieldData.account.mode,
                            state: fieldData.account.state,
                        }));
                        window.location.pathname = '/';
                    }, clickable: true, icon: _jsx(Login, {}), sx: { m: 0.25 } }, fieldData.id));
            },
        },
    },
};
const LoginAsView = () => (_jsx(EnhancedDataView, { dataSpec: dataSpec, hideAdd: true, hideSelectedCount: true, enableMultiSelect: false, enableEdit: false }));
export default LoginAsView;
