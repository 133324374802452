import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography, IconButton, Tooltip, } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/material/styles';
import authentication from '@/services/authentication';
import useSnackbar from '@/contexts/useSnackbar';
const EmailVerificationDialog = ({ dialogProps, theme, }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const { showSnackbar } = useSnackbar();
    const StyledIconButton = styled(IconButton)({
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    });
    useEffect(() => {
        let timer;
        if (isButtonDisabled) {
            setCountdown(60);
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        return () => {
            clearInterval(timer);
        };
    }, [isButtonDisabled]);
    useEffect(() => {
        if (countdown <= 0) {
            setIsButtonDisabled(false);
        }
    }, [countdown]);
    return (_jsxs(Dialog, { open: dialogProps.open, fullWidth: true, maxWidth: "sm", onClose: () => {
            dialogProps.onClose();
        }, children: [_jsxs(DialogTitle, { sx: { pb: 1 }, children: [_jsx(Typography, { variant: "h6", component: "div", children: "Verify your e-mail address" }), _jsx(Tooltip, { title: "Close", children: _jsx("span", { children: _jsx(StyledIconButton, { onClick: (event) => {
                                    event.preventDefault();
                                    dialogProps.onClose();
                                }, size: "large", children: _jsx(CloseIcon, {}) }) }) })] }), _jsxs(DialogContent, { children: [_jsx(Grid, { container: true, direction: "row", spacing: 2, children: _jsx(Grid, { item: true, xs: true, children: _jsxs(Typography, { variant: "body2", children: ["We need to send an e-mail to ", _jsx("strong", { children: dialogProps.email }), ' ', "to verify your e-mail address."] }) }) }), _jsx(Grid, { container: true, direction: "row", spacing: 2, sx: { mt: 1 }, children: _jsx(Grid, { item: true, xs: true, children: _jsx(Button, { color: "primary", type: "submit", variant: "contained", onClick: () => {
                                    setIsButtonDisabled(true);
                                    authentication
                                        .sendEmailVerification()
                                        .then(() => {
                                        showSnackbar('E-mail verification sent', 'success');
                                    })
                                        .catch((error) => {
                                        console.error('Error when sending e-mail verification', error);
                                        Sentry.captureException(error);
                                        showSnackbar('Error sending e-mail verification', 'error');
                                    });
                                }, children: isButtonDisabled
                                    ? `Resend in ${countdown}s`
                                    : 'Send e-mail verification' }) }) })] })] }));
};
export default EmailVerificationDialog;
