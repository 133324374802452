var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import CompGridImportTool from '@/components/DataBulkAdd/CompGridImportTool';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
export const CompGridImport = () => {
    const mutation = API.getMutation('comp-grids/import-rates', 'POST');
    const { showSnackbar } = useSnackbar();
    const onSave = (_a) => __awaiter(void 0, [_a], void 0, function* ({ data }) {
        mutation.reset();
        mutation.mutate(data, {
            onSuccess: () => {
                showSnackbar('Updated', 'success');
            },
            onError: (res) => {
                showSnackbar(res.message || 'Something went wrong, please try again!', 'error');
            },
        });
    });
    return (_jsx(CompGridImportTool, { isLoading: mutation.isPending, onCancel: () => { }, onSave: onSave, btnLabel: "Import" }));
};
