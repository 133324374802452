var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMediaQuery } from '@mui/material';
import { AccountIds, WorkerNames } from 'common/constants';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { SyncedEntity } from '@/common/SyncedEntity';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { ReconciliationConfirmProvider } from '@/contexts/ReconciliationConfirmProvider';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import API from '@/services/API';
import Reports from '@/services/Reports';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';
import useSnackbar from '@/contexts/useSnackbar';
const exportOptions = [
    {
        id: 'export',
        label: 'Export',
        options: {},
    },
    // If we want to enable the export producer view, we can add the following
    // {
    //   id: 'export-producer-view',
    //   label: 'Export producer view',
    //   options: { producer_view: true },
    // },
];
const PolicyDataView = ({ reportId = null }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const [searchParams, _] = useSearchParams();
    const location = useLocation();
    const prefillData = (_a = location.state) === null || _a === void 0 ? void 0 : _a.prefillData;
    const { data: settingsData, isFetched: isFetchedUserSettings } = API.getUser();
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const { selectedAccount } = useAccountStore();
    const { userRole } = useRoleStore();
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const syncPolicy = API.getMutation('data_processing/sync/nowcerts/policy', 'POST');
    const syncService = API.getMutation('data_processing/sync', 'POST');
    const [selectedData, setSelectedData] = useState([]);
    const [filterCacheKey, setFilterCacheKey] = useState([]);
    const queryClient = useQueryClient();
    const { showSnackbar } = useSnackbar();
    useEffect(() => {
        // Cancel previous query when searchParams or selectedAccount changes, avoid previous query from returning stale data and causing UI mismatch
        setFilterCacheKey((prev) => {
            const cacheKey = [
                selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                // Filter out pagination & order params from searchParams
                new URLSearchParams(Array.from(searchParams.entries()).filter(([key]) => !['limit', 'page', 'sort', 'orderBy'].includes(key))).toString(),
            ];
            if (prev.length > 0 && prev.join() !== cacheKey.join()) {
                queryClient.cancelQueries({ queryKey: prev });
            }
            return cacheKey;
        });
    }, [queryClient, searchParams, selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId]);
    const { data: filters } = useQuery({
        queryKey: filterCacheKey,
        queryFn: (_a) => __awaiter(void 0, [_a], void 0, function* ({ signal }) {
            const url = `${process.env.REACT_APP_API}/api/report_data/filters?${new URLSearchParams(searchParams).toString()}`;
            try {
                const res = yield fetch(url, {
                    method: 'GET',
                    headers: yield API.getHeaders(),
                    signal,
                });
                return res.json();
            }
            catch (err) {
                console.error(err);
                return {};
            }
        }),
    });
    const reports = new Reports(mode, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId });
    const isMobile = useMediaQuery('(max-width:600px)');
    const pageSettingFields = isMobile
        ? (_c = (_b = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _b === void 0 ? void 0 : _b.policies) === null || _c === void 0 ? void 0 : _c.outstandingMobileFields
        : (_e = (_d = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _d === void 0 ? void 0 : _d.policies) === null || _e === void 0 ? void 0 : _e.fields;
    const newFields = (pageSettingFields !== null && pageSettingFields !== void 0 ? pageSettingFields : []).reduce((acc, cur) => {
        acc[cur] = Object.assign({}, reports.fields[cur]);
        return acc;
    }, {});
    if (Object.keys(newFields).length > 0)
        reports.fields = newFields;
    if ((_g = (_f = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _f === void 0 ? void 0 : _f.policies) === null || _g === void 0 ? void 0 : _g.page_label) {
        reports.label = (_j = (_h = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _h === void 0 ? void 0 : _h.policies) === null || _j === void 0 ? void 0 : _j.page_label;
    }
    if (isFetchedAccountSettings &&
        ((_l = (_k = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _k === void 0 ? void 0 : _k.policies) === null || _l === void 0 ? void 0 : _l.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" })
        // <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
        //   <Typography variant="h5">No results</Typography>
        // </Box>
        );
    }
    const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();
    const isTrailStoneAccount = AccountIds.TRAILSTONE === (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId);
    const hasAgencyIntegratorWorker = !!(workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[WorkerNames.AgencyIntegratorWorker]);
    const showBulkSync = isTrailStoneAccount || hasAgencyIntegratorWorker;
    for (const key in reports.fields) {
        const field = reports.fields[key];
        field.readOnly =
            field.readOnly ||
                ((data) => {
                    var _a;
                    const syncedFields = (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.report_data;
                    if ((syncedFields || []).includes(field.id)) {
                        return isSyncedField(data, syncedFields, field.id, data.config);
                    }
                    return false;
                });
        field.endAdornment = (data, field, setNewData) => {
            var _a;
            return (_jsx(SyncEndAdornment, { syncedFields: (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.report_data, syncId: data === null || data === void 0 ? void 0 : data.sync_id, fieldId: field === null || field === void 0 ? void 0 : field.id, data: data, fieldType: field.type, onChange: (newOverrideFields) => {
                    setNewData(Object.assign(Object.assign({}, data), { config: Object.assign(Object.assign({}, (data.config || {})), { overrideFields: newOverrideFields }) }));
                } }));
        };
    }
    const onBulkSync = (params) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            let result = null;
            if (isTrailStoneAccount) {
                result = yield syncPolicy.mutateAsync(params);
            }
            else if (hasAgencyIntegratorWorker) {
                const lookupData = new Map(selectedData.map((item) => [item.id, item]));
                const policyNumbers = params.ids.map((id) => { var _a; return (_a = lookupData.get(id)) === null || _a === void 0 ? void 0 : _a.policy_id; });
                if (!policyNumbers.length) {
                    return;
                }
                result = yield syncService.mutateAsync({
                    entities: ['policies'],
                    policyNumbers: policyNumbers,
                    sync: true,
                    worker: WorkerNames.AgencyIntegratorWorker,
                });
            }
            if (!(result === null || result === void 0 ? void 0 : result.success)) {
                showSnackbar(`Sync failed: ${result === null || result === void 0 ? void 0 : result.message}`, 'error');
            }
            else {
                showSnackbar('Sync completed', 'success');
            }
        }
        catch (error) {
            console.error(error);
            showSnackbar('Sync failed', 'error');
        }
    });
    return settingsData && isFetchedUserSettings && isFetchedAccountSettings ? (_jsx(ReconciliationConfirmProvider, { mode: mode, children: _jsx(EnhancedDataView, { reportId: reportId, dataSpec: reports, exportOptions: exportOptions, outstandingMobileFields: (_o = (_m = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _m === void 0 ? void 0 : _m.policies) === null || _o === void 0 ? void 0 : _o.outstandingMobileFields, defaultData: prefillData, filters: filters, enableSaves: true, showTotals: true, onBulkSync: (showBulkSync ? (ids) => onBulkSync({ ids }) : undefined), setSelectedData: (data) => setSelectedData(data), actions: [
                ...((_p = reports.actions) !== null && _p !== void 0 ? _p : []),
                ...[
                    {
                        type: isTrailStoneAccount ? 'iconButton' : 'icon',
                        label: 'Sync',
                        icon: (_jsx(SyncedEntity, { isSynced: true, disabled: !isTrailStoneAccount })),
                        enabled: (row) => !!row.sync_id,
                        onClick: isTrailStoneAccount
                            ? (row, e) => __awaiter(void 0, void 0, void 0, function* () {
                                e === null || e === void 0 ? void 0 : e.stopPropagation();
                                e === null || e === void 0 ? void 0 : e.preventDefault();
                                if (isTrailStoneAccount) {
                                    yield onBulkSync({ syncId: row.sync_id });
                                }
                            })
                            : () => { },
                    },
                ],
            ], actionsEnabled: ((row) => {
                return !!row.sync_id || reports.actions.length > 0;
            }), 
            // TODO (frank.santillan): Move to settings after we migrate reconciliation / commissions / policies to pages_settings.
            readOnly: userRole === Roles.PRODUCER }) })) : null;
};
export default PolicyDataView;
