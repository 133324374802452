var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, FormControl, InputLabel, MenuItem, } from '@mui/material';
import Select from '@mui/material/Select';
import { SaveAs, Save } from '@mui/icons-material';
import { ReportReviewStatuses, ReportReviewStatusesLabels, } from 'common/globalTypes';
import PolicyDataView from '@/components/PolicyDataView';
import API from '@/services/API';
import { LoadingContext } from '@/contexts/LoadingContext';
import { useAccountStore } from '@/store';
import CommissionsDataView from '../CommissionsDataView';
import ReconciliationsView from '../ReconciliationsView';
import useSnackbar from '@/contexts/useSnackbar';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: 2,
};
const editDataStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: 2,
};
const EditViewReport = ({ open, setOpen, type, name, notes, access, usersWhiteList, approver = null, status = null, created_by, allData = null, updateName, updateNotes, updateAccess, updateUsersList, updateApprover = () => { }, updateStatus = () => { }, isOwner, id, }) => {
    const { selectedAccount } = useAccountStore();
    const { data: accountUsers } = API.getBasicQuery(
    // @ts-ignore
    `users/get_account_users?accId=${selectedAccount.accountId}&state=active`);
    const [newName, setNewName] = useState(name);
    const [newAccess, setNewAccess] = useState(access);
    const [newNotes, setNewNotes] = useState(notes);
    const [usersList, setUsersList] = useState(usersWhiteList);
    const [newApprover, setNewApprover] = useState(approver);
    const [newStatus, setNewStatus] = useState(status);
    const [openEditData, setOpenEditData] = useState(false);
    const patcher = API.getMutation('saved_reports', 'PATCH');
    const { showSnackbar } = useSnackbar();
    const { setLoadingConfig } = useContext(LoadingContext);
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick')
            return;
        setOpen(false);
    };
    const handleCloseEditData = () => {
        setOpenEditData(false);
    };
    const handleAccessChange = (event) => {
        if (event.target.value !== 'user_list')
            setUsersList([]);
        setNewAccess(event.target.value);
    };
    const handleUserListChange = (event) => {
        setUsersList(event.target.value);
    };
    const handleApproverChange = (event) => {
        setNewApprover(event.target.value);
    };
    const handleStatusChange = (event) => {
        setNewStatus(event.target.value);
    };
    const onEditReportData = () => {
        setOpenEditData(true);
    };
    const handleUpdate = (event, reason) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingConfig({
            loading: true,
            message: 'Loading...',
        });
        const newData = {
            id,
            str_id: allData === null || allData === void 0 ? void 0 : allData.str_id,
            page: allData === null || allData === void 0 ? void 0 : allData.page,
            type: type.toLowerCase(),
            name: newName,
            notes: newNotes,
            access: newAccess,
            users_white_list: usersList,
            reviewed_by: newApprover,
            status: newStatus,
            created_by,
        };
        // @ts-ignore
        const response = yield patcher.mutateAsync(newData);
        if (response.status === 200) {
            setLoadingConfig({
                loading: false,
                message: 'Updating...',
            });
            showSnackbar(`${type} updated!`, 'success');
            updateName(newName);
            updateNotes(newNotes);
            updateAccess(newAccess);
            updateUsersList(usersList);
            updateApprover(newApprover);
            updateStatus(newStatus);
        }
        else if (response.status === 400) {
            setLoadingConfig({
                loading: false,
                message: '',
            });
            setOpen(true);
            showSnackbar(response.error, 'error');
        }
        else {
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(`${type} not updated!`, 'error');
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(Modal, { open: open, onClose: handleClose, children: _jsxs(Box, { sx: style, children: [_jsx(Typography, { variant: "h6", component: "h2", children: "Saved report settings" }), _jsx(Box, { sx: { mt: 2 }, children: _jsx(TextField, { id: "name-textfield", label: "Name", value: newName, onChange: (e) => setNewName(e.target.value), fullWidth: true }) }), _jsx(Box, { sx: { mt: 2 }, children: _jsx(TextField, { id: "standard-multiline-static", label: "Notes", multiline: true, rows: 5, defaultValue: newNotes, onChange: (e) => setNewNotes(e.target.value), fullWidth: true }) }), isOwner ? (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { mt: 2 }, children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: "select-access", children: "Access" }), _jsxs(Select, { labelId: "select-access", value: newAccess, label: "Access", onChange: handleAccessChange, children: [_jsx(MenuItem, { value: 'user', children: "User" }), _jsx(MenuItem, { value: 'account', children: "Account" }), _jsx(MenuItem, { value: 'user_list', children: "User list" })] })] }) }), newAccess === 'user_list' && accountUsers ? (_jsx(Box, { sx: { mt: 2 }, children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: "select-user-list", children: "User list" }), _jsx(Select, { labelId: "select-user-list", multiple: true, 
                                                    // @ts-ignore
                                                    value: usersList, label: "Access", onChange: handleUserListChange, children: accountUsers.map((user) => (_jsx(MenuItem, { value: user.str_id, children: user.email }, user.str_id))) })] }) })) : null] })) : null, accountUsers && type === 'Report' ? (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { mt: 2 }, children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: "select-approver", children: "Approver" }), _jsxs(Select, { labelId: "select-approver", 
                                                    // @ts-ignore
                                                    value: newApprover, label: "Approver", onChange: handleApproverChange, children: [_jsx(MenuItem, { value: '', children: "None" }), accountUsers.map((user) => (_jsx(MenuItem, { value: user.uid, children: user.email }, `${user.str_id}-approver`)))] })] }) }), _jsx(Box, { sx: { mt: 2 }, children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: "select-status", children: "Status" }), _jsxs(Select, { labelId: "select-status", 
                                                    // @ts-ignore
                                                    value: newStatus, label: "Status", onChange: handleStatusChange, children: [_jsx(MenuItem, { value: ReportReviewStatuses.None, children: ReportReviewStatusesLabels.None }), _jsx(MenuItem, { value: ReportReviewStatuses.Draft, children: ReportReviewStatusesLabels.Draft }), _jsx(MenuItem, { value: ReportReviewStatuses.InReview, children: ReportReviewStatusesLabels.InReview }), _jsx(MenuItem, { value: ReportReviewStatuses.Approved, children: ReportReviewStatusesLabels.Approved }), _jsx(MenuItem, { value: ReportReviewStatuses.Rejected, children: ReportReviewStatusesLabels.Rejected }), _jsx(MenuItem, { value: ReportReviewStatuses.RequestUpdate, children: ReportReviewStatusesLabels.RequestUpdate }), _jsx(MenuItem, { value: ReportReviewStatuses.NonPayable, children: ReportReviewStatusesLabels.NonPayable })] })] }) })] })) : null, _jsx(Box, { sx: { mt: 2 }, children: _jsx(Button, { onClick: onEditReportData, startIcon: _jsx(SaveAs, {}), variant: "outlined", children: "Edit report data" }) }), _jsxs(Box, { sx: { mt: 3, display: 'flex', justifyContent: 'flex-end' }, children: [_jsx(Button, { onClick: (e) => handleClose(e, null), sx: { mr: 1 }, children: "Close" }), _jsx(Button, { variant: "contained", onClick: (e) => {
                                            handleUpdate(e, null);
                                            handleClose(e, null);
                                        }, children: "Save" })] })] }) }) }), _jsx("div", { children: _jsx(Modal, { open: openEditData, onClose: handleCloseEditData, children: _jsxs(Box, { sx: editDataStyle, children: [_jsx(Typography, { variant: "h6", component: "h2", children: "Edit report data" }), _jsx(Box, { sx: { mt: 1, display: 'flex', justifyContent: 'flex-start' }, children: _jsxs(Typography, { sx: { mr: 0.5 }, children: ["Select the new report data and use the save report icon", _jsx(Save, { color: "primary" }), "to update the report with the new data."] }) }), _jsxs(Box, { sx: {
                                    overflowX: 'auto',
                                    overflowY: 'auto',
                                    width: '100%',
                                    height: '70vh',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: 'white',
                                }, children: [(allData === null || allData === void 0 ? void 0 : allData.page) === 'reconciliation' && (_jsx(ReconciliationsView, { reportId: allData === null || allData === void 0 ? void 0 : allData.str_id })), (allData === null || allData === void 0 ? void 0 : allData.page) === 'commissions' && (_jsx(CommissionsDataView, { reportId: allData === null || allData === void 0 ? void 0 : allData.str_id })), (allData === null || allData === void 0 ? void 0 : allData.page) === 'policies' && (_jsx(PolicyDataView, { reportId: allData === null || allData === void 0 ? void 0 : allData.str_id }))] }), _jsx(Box, { sx: { mt: 3, display: 'flex', justifyContent: 'flex-end' }, children: _jsx(Button, { onClick: (e) => handleCloseEditData(), sx: { mr: 1 }, children: "Close" }) })] }) }) })] }));
};
export default EditViewReport;
