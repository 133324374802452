var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
function CustomTabPanel(props) {
    const { children, value, index } = props, other = __rest(props, ["children", "value", "index"]);
    return (_jsx("div", Object.assign({ style: { width: '100%' }, role: "tabpanel", hidden: value !== index, id: `vertical-tabpanel-${index}`, "aria-labelledby": `vertical-tab-${index}` }, other, { children: value === index && _jsx(Box, { children: children }) })));
}
function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const DataVisualizationTabs = ({ tabOneLabel, tabOneComponent, tabTwoLabel, tabTwoComponent, tabThreeLabel, tabThreeComponent, }) => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (_jsxs(Box, { sx: {
            display: 'flex',
        }, children: [_jsxs(Tabs, { orientation: "vertical", value: value, onChange: handleChange, children: [_jsx(Tab, Object.assign({ label: tabOneLabel }, a11yProps(0))), _jsx(Tab, Object.assign({ label: tabTwoLabel }, a11yProps(1))), _jsx(Tab, Object.assign({ label: tabThreeLabel }, a11yProps(2)))] }), _jsx(CustomTabPanel, { value: value, index: 0, children: tabOneComponent }), _jsx(CustomTabPanel, { value: value, index: 1, children: tabTwoComponent }), _jsx(CustomTabPanel, { value: value, index: 2, children: tabThreeComponent })] }));
};
export default DataVisualizationTabs;
