import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Select, MenuItem, FormControl, InputLabel, TextField, Button, } from '@mui/material';
const DataUpdateConfigGroups = ({ field, row, setter, dynamicSelects, }) => {
    const [newGroup, setNewGroup] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(row[field.id] || '');
    const [selectedGroupNewName, setSelectedGroupNewName] = useState(selectedGroup);
    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedGroup(value);
        setter(Object.assign(Object.assign({}, row), { [field.id]: value }));
    };
    const handleNewGroupChange = (event) => {
        setNewGroup(event.target.value);
    };
    const handleAddNewGroup = () => {
        if (newGroup && !dynamicSelects.includes(newGroup)) {
            dynamicSelects.push(newGroup);
            setSelectedGroup(newGroup);
            setter(Object.assign(Object.assign({}, row), { [field.id]: newGroup }));
            setNewGroup('');
        }
    };
    const handleRenameGroup = () => {
        if (selectedGroupNewName &&
            !dynamicSelects.includes(selectedGroupNewName)) {
            dynamicSelects.push(selectedGroupNewName);
            setSelectedGroup(selectedGroupNewName);
            setter(Object.assign(Object.assign({}, row), { [field.id]: selectedGroupNewName }));
        }
    };
    return (_jsxs(Box, { sx: { width: '50%', mb: 1 }, children: [_jsxs(FormControl, { fullWidth: true, sx: { mt: 0.5 }, children: [_jsx(InputLabel, { children: "Group" }), _jsx(Select, { value: selectedGroup, onChange: handleSelectChange, label: "Group", children: dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.map((group, index) => (_jsx(MenuItem, { value: group, children: group }, index))) })] }), !selectedGroup ? (_jsxs(Box, { sx: { mt: 1, ml: 1, display: 'flex', alignItems: 'center' }, children: [_jsx(TextField, { label: "New group", value: newGroup, onChange: handleNewGroupChange, fullWidth: true }), _jsx(Button, { variant: "contained", color: "primary", onClick: handleAddNewGroup, sx: { ml: 2 }, children: "Add" })] })) : (_jsxs(Box, { sx: { mt: 1, ml: 1, display: 'flex', alignItems: 'center' }, children: [_jsx(TextField, { label: "Rename group", value: selectedGroupNewName, onChange: (e) => setSelectedGroupNewName(e.target.value), fullWidth: true }), _jsx(Button, { variant: "contained", color: "primary", onClick: handleRenameGroup, sx: { ml: 2 }, children: "Rename" })] }))] }));
};
export default DataUpdateConfigGroups;
