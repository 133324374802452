import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import { isValidElement, useState } from 'react';
import { Link } from 'react-router-dom';
const ExpandableData = ({ data, header, formatter = (val) => val, link, }) => {
    const [expanded, setExpanded] = useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { endIcon: (data === null || data === void 0 ? void 0 : data.length) > 0 ? (expanded ? (_jsx(ExpandLessOutlined, {})) : (_jsx(ExpandMoreOutlined, {}))) : null, onClick: () => setExpanded(!expanded), sx: {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    color: 'text.primary',
                }, children: `${data.length} ${header.label.toLowerCase()}` }), expanded && (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.25, mt: 0.5 }, children: Array.isArray(data) &&
                    (data === null || data === void 0 ? void 0 : data.map((datum) => {
                        const formattedItem = formatter(datum);
                        return isValidElement(formattedItem) ? (formattedItem) : link ? (_jsx(Chip, { label: formatter(datum), component: Link, to: `${link}?id=${datum.str_id}`, clickable: true }, datum.id)) : (_jsx(Chip, { label: formatter(datum) }, datum.id));
                    })) }))] }));
};
export default ExpandableData;
