import { jsx as _jsx } from "react/jsx-runtime";
import DataView from '@/components/DataView';
import { FieldTypes } from '@/types';
export const CommissionCalculationView = () => {
    const dataDesc = {
        label: 'Calulation Methods',
        table: 'calculation',
        editable: true,
        fields: [
            {
                id: 'name',
                label: 'Name',
                formatter(val) {
                    return val;
                },
            },
            { id: 'description', label: 'Description' },
            {
                id: 'formula',
                label: 'Formula',
                type: FieldTypes.CODE,
            },
            {
                id: 'method',
                label: 'Method',
                type: FieldTypes.CODE,
                visible: false,
                render: (val, row) => {
                    return row.method;
                },
            },
        ],
    };
    return (_jsx("div", { children: _jsx(DataView, { dataDesc: dataDesc }) }));
};
export default CommissionCalculationView;
