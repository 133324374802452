import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ChevronRight, ExpandMore, Groups, HorizontalRule, } from '@mui/icons-material';
import { Box, Chip, Collapse, ToggleButton, ToggleButtonGroup, Typography, } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import HierarchyDiagram from '@/components/contacts/ContactGroupsView/HierarchyDiagram';
import DataView from '@/components/DataView';
import LazyLoadDynamicSelect from '@/components/molecules/LazyLoadDynamicSelect';
import SearchableList from '@/components/molecules/SearchableList';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
const formatter = (val, collectionVals = []) => {
    const hierarchy = _jsx(ContactHierarchy, { contact: val });
    return hierarchy;
};
const ContactHierarchy = ({ contact, depth = 0 }) => {
    var _a, _b, _c, _d;
    const { selectedAccount } = useAccountStore();
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };
    // Only show contacts with children (root contacts)
    if (((_a = contact === null || contact === void 0 ? void 0 : contact.parent_relationships) === null || _a === void 0 ? void 0 : _a.length) > 0)
        return null;
    const expandable = ((_b = contact === null || contact === void 0 ? void 0 : contact.child_relationships) === null || _b === void 0 ? void 0 : _b.length) > 0;
    const expandFunction = expandable ? toggleExpanded : undefined;
    return contact && (contact === null || contact === void 0 ? void 0 : contact.id) ? (_jsxs(Box, { children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [[...Array(depth + 1)].map((e, i) => i === depth ? (expanded ? (_jsx(ExpandMore, { sx: {
                            color: 'lightgray',
                            cursor: expandable ? 'pointer' : 'default',
                        }, onClick: expandFunction }, i)) : (_jsx(ChevronRight, { sx: {
                            color: 'lightgray',
                            cursor: expandable ? 'pointer' : 'default',
                        }, onClick: expandFunction }, i))) : (_jsx(HorizontalRule, { sx: { color: '#ddd' } }, i))), _jsx(Chip, { label: `${Formatter.contact(contact, {
                            account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                        })}${expandable ? ` (${(_c = contact === null || contact === void 0 ? void 0 : contact.child_relationships) === null || _c === void 0 ? void 0 : _c.length})` : ''}`, sx: {
                            m: 0.25,
                            cursor: expandable ? 'pointer' : 'default',
                        }, onClick: expandFunction }, `chip-${contact.id}-${depth}`)] }), _jsx(Collapse, { in: expanded, children: (_d = contact === null || contact === void 0 ? void 0 : contact.child_relationships) === null || _d === void 0 ? void 0 : _d.map((contact) => (_jsx(ContactHierarchy, { contact: contact.contact, depth: depth + 1 }, contact.id))) })] }, `box-${contact.id}-${depth}`)) : null;
};
const ContactGroupsView = () => {
    var _a, _b;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.agents_groups;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const { selectedAccount } = useAccountStore();
    const location = useLocation();
    const navigate = useNavigate();
    // Parse the URL's query string
    const params = new URLSearchParams(location.search);
    const urlSearch = params.get('search') || '';
    const urlSort = params.get('sort') || 'asc';
    const urlView = params.get('view') || 'tree';
    const urlSelectedAgentState = params.get('selected_agent_id') || '';
    const [fetchContacts, setFetchContacts] = useState(true);
    const [fetchClickedContact, setFetchClickedContact] = useState(false);
    const [clickedContactId, setClickedContactId] = useState(null);
    const [search, setSearch] = useState(urlSearch || '');
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(urlSort || 'asc');
    const [contactsItems, setContactsItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [view, setView] = useState(urlView);
    const limit = 100;
    const prevSort = useRef(sort);
    const prevSearch = useRef(search);
    const { data: contacts, isLoading } = API.getBasicQuery('contacts', `page=${page}&limit=${limit}&sort=${sort}&q=${search}&orderBy=first_name&hierarchy_depth=0&is_detailed_view=true`, fetchContacts);
    const { data: clickedContact, isLoading: isClickedContactLoading } = API.getBasicQuery('contacts', `id=${clickedContactId}&hierarchy_depth=9&is_detailed_view=true&get_parent_children=true`, fetchClickedContact);
    // Find the selected item when the component mounts or the URL's query string changes
    useEffect(() => {
        if (urlSelectedAgentState !== '') {
            setClickedContactId(urlSelectedAgentState);
            setFetchClickedContact(true);
        }
    }, []);
    // Update the URL's query string whenever the state changes
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        params.set('search', search);
        params.set('sort', sort);
        params.set('view', view);
        if (selectedItem)
            params.set('selected_agent_id', selectedItem.str_id);
        navigate({ search: params.toString() });
    }, [search, sort, view, selectedItem]);
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setFetchContacts(true);
        }
    };
    useEffect(() => {
        if (contacts) {
            if (sort !== prevSort.current) {
                setContactsItems(contacts.data);
            }
            else if (search !== prevSearch.current) {
                setContactsItems(contacts.data);
            }
            else {
                setContactsItems((prevContacts) => [...prevContacts, ...contacts.data]);
            }
            if (urlSelectedAgentState === '')
                handleItemClick(contacts.data[0]);
            setFetchContacts(false);
            prevSort.current = sort;
            prevSearch.current = search;
        }
    }, [contacts, sort]);
    const handleItemClick = (item) => {
        setClickedContactId(item === null || item === void 0 ? void 0 : item.str_id);
        setFetchClickedContact(true);
    };
    useEffect(() => {
        if (clickedContact === null || clickedContact === void 0 ? void 0 : clickedContact.data) {
            setSelectedItem(clickedContact.data[0]);
        }
    }, [clickedContact]);
    const listItemText = (item) => {
        var _a;
        if (((_a = item.child_relationships) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return (_jsxs(Box, { component: "span", display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", width: "100%", children: [_jsx(Box, { component: "span", flex: "1", pr: 2, children: Formatter.contact(item, {
                            account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                        }) }), _jsx(Groups, { fontSize: "small", sx: { color: 'lightgray' } })] }));
        }
        else {
            return Formatter.contact(item, {
                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
            });
        }
    };
    const dataDesc = {
        label: '',
        table: 'contacts/groups',
        editable: true,
        extrtaActions: [],
        fields: [
            {
                id: 'id',
                visible: false,
            },
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'notes',
                label: 'Notes',
            },
            {
                id: 'contacts',
                label: 'Agents',
                type: 'dynamic-select',
                table: 'contacts',
                multiple: true,
                queryParamTable: 'true',
                queryParamNameTable: 'add_contact_children',
                queryParamName: 'contact_group_id_add',
                queryParamValue: 'id',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val === '')
                        return '';
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const record = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return record
                            ? Formatter.contact(record, {
                                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                            })
                            : '';
                    }
                    return val;
                },
                optionFormatter: (option) => Formatter.contact(option, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId }),
                optionValuer: (option) => option.id,
                tableFormatter: (field, row, dynamicSelects, header) => (_jsx(LazyLoadDynamicSelect, { data: (field !== null && field !== void 0 ? field : []).map((o) => o === null || o === void 0 ? void 0 : o.id), header: header, formatter: formatter })),
            },
        ],
    };
    return (_jsx(Box, { sx: {
            width: '100%',
            overflowY: 'auto',
        }, children: _jsxs(Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
            }, children: [_jsxs(Box, { sx: {
                        pt: 2,
                        px: 2,
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                    }, children: [_jsx(Box, { sx: { display: 'flex', justifyContent: 'space-between' }, children: _jsx(Typography, { variant: "h5", children: (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) || 'Agent groups' }) }), _jsxs(ToggleButtonGroup, { sx: { height: 32 }, value: view, exclusive: true, color: "primary", onChange: (event, newView) => {
                                setView(newView);
                            }, children: [_jsx(ToggleButton, { sx: { borderRadius: 16, lineHeight: 'unset' }, value: "tree", children: "Tree view" }), _jsx(ToggleButton, { sx: { borderRadius: 16, lineHeight: 'unset' }, value: "orgchart", children: "Org chart" })] })] }), view === 'orgchart' ? (_jsx(_Fragment, { children: isLoading || isClickedContactLoading ? (_jsx(Box, { sx: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                            width: '100%',
                        }, children: _jsx(LoadingCircle, {}) })) : (_jsx(Box, { sx: {
                            width: '100%',
                        }, children: _jsx(Box, { sx: {
                                display: 'flex',
                                flexDirection: 'column',
                                pt: 2,
                                px: 2,
                                pb: 2,
                            }, children: _jsxs(Box, { display: "flex", flexDirection: "row", sx: {
                                    width: '100%',
                                }, children: [_jsx(Box, { maxHeight: "80vh", sx: {
                                            flex: 2,
                                        }, children: _jsx(SearchableList, { items: contactsItems, count: contacts === null || contacts === void 0 ? void 0 : contacts.count, page: page, setPage: setPage, search: search, setSearch: setSearch, onKeyPress: handleKeyPress, sort: sort, setSort: setSort, limit: limit, handleItemClick: handleItemClick, listItemText: listItemText, selectedItem: selectedItem, title: `My team (${(contacts === null || contacts === void 0 ? void 0 : contacts.count) || 0})` }) }), _jsx(Box, { sx: {
                                            flex: 10,
                                        }, children: selectedItem && (_jsx(HierarchyDiagram, { contact: selectedItem, handleItemClick: handleItemClick })) })] }) }) })) })) : (_jsx(DataView, { dataDesc: dataDesc, viewOnly: viewOnly, readOnly: viewOnly }))] }) }));
};
export default ContactGroupsView;
