import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, FormControl, InputLabel, TextField, } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DataProcessingAction } from 'common/globalTypes';
import { EnhancedSelect } from './EnhancedSelect';
export const fieldMatcherActions = [
    {
        id: DataProcessingAction.SET_FIELD_VALUE,
        label: 'Set field value',
    },
];
const ActionPicker = ({ value: actionObj = { field: '', action: '', value: '' }, row, fieldGetter, setValue, sx = {}, }) => {
    const [selectedActionField, setSelectedActionField] = useState(actionObj === null || actionObj === void 0 ? void 0 : actionObj.field);
    const selectedFields = fieldGetter(row);
    const setActionObject = (value, key) => {
        const updatedActionObj = Object.assign(Object.assign({}, actionObj), { [key]: value });
        setValue((prevData) => {
            return Object.assign(Object.assign({}, prevData), { data_update_actions: updatedActionObj });
        });
    };
    const valueOptions = useMemo(() => {
        var _a;
        return (_a = selectedFields === null || selectedFields === void 0 ? void 0 : selectedFields.find((field) => field.id === selectedActionField)) === null || _a === void 0 ? void 0 : _a.options;
    }, [selectedFields, selectedActionField]);
    return (_jsxs(Box, { sx: Object.assign({ mt: 1, display: 'flex', flexWrap: 'wrap', width: '100%' }, sx), children: [_jsx(EnhancedSelect, { label: "Action", value: fieldMatcherActions.find((item) => item.id === actionObj.action), options: fieldMatcherActions, onChange: (v) => {
                    setActionObject(v === null || v === void 0 ? void 0 : v.id, 'action');
                }, sx: { width: 200, mr: 1 } }), selectedFields && (_jsx(EnhancedSelect, { label: "Field", options: selectedFields, value: selectedFields.find((item) => item.id === (actionObj === null || actionObj === void 0 ? void 0 : actionObj.field)), onChange: (v) => {
                    setActionObject(v === null || v === void 0 ? void 0 : v.id, 'field');
                    setSelectedActionField(v === null || v === void 0 ? void 0 : v.id);
                }, sx: { mr: 1, minWidth: 200, width: 'fit-content' } })), selectedActionField && (_jsx(_Fragment, { children: (valueOptions === null || valueOptions === void 0 ? void 0 : valueOptions.length) ? (_jsx(EnhancedSelect, { label: "Value", onChange: (v) => {
                        setActionObject(v, 'value');
                    }, options: valueOptions, value: actionObj === null || actionObj === void 0 ? void 0 : actionObj.value })) : (_jsxs(FormControl, { sx: { width: 145 }, children: [_jsx(InputLabel, { children: "Value" }), _jsx(TextField, { value: (actionObj === null || actionObj === void 0 ? void 0 : actionObj.value) || '', onChange: (e) => setActionObject(e.target.value, 'value'), label: "Value", variant: "outlined" })] })) }))] }));
};
export default ActionPicker;
