import { jsx as _jsx } from "react/jsx-runtime";
import { Box, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Clear from '@mui/icons-material/Clear';
export const SearchBox = ({ onChange, search = '', }) => {
    return (_jsx(Box, { sx: {
            borderBottom: '1px solid',
            borderColor: 'grey.200',
            display: 'flex',
            alignItems: 'center',
        }, children: _jsx(TextField, { value: search, placeholder: "Search", onChange: (evt) => onChange(evt.target.value), InputProps: {
                startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(SearchIcon, { fontSize: "small", sx: { color: 'grey.400' } }) })),
                endAdornment: search ? (_jsx(InputAdornment, { onClick: () => onChange(''), sx: { cursor: 'pointer' }, position: "start", children: _jsx(Clear, { sx: { color: 'grey.400' }, fontSize: "small" }) })) : null,
            }, sx: {
                width: '100%',
                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
            }, size: "small" }) }));
};
