import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Card, CardContent, Typography, Chip, Tooltip, Box, Button, TableCell, TableRow, Grid, IconButton, } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
const CardView = ({ row, headersFiltered, isItemSelected, handleClick, onEdit, readOnly, onDelete, onClick, outstandingFieldsInMobileView, dynamicSelects, navigate, key, nonSelectable, }) => {
    const formatter = (x, dynamicSelects, header) => {
        if ((header === null || header === void 0 ? void 0 : header.tableFormatter) instanceof Function) {
            return header.tableFormatter(x, row, dynamicSelects, header);
        }
        if ((header === null || header === void 0 ? void 0 : header.formatter) instanceof Function) {
            if (dynamicSelects) {
                if (!x)
                    return null;
                if (React.isValidElement(x))
                    return x;
                if (React.isValidElement(header === null || header === void 0 ? void 0 : header.formatter(x, dynamicSelects)))
                    return header === null || header === void 0 ? void 0 : header.formatter(x, dynamicSelects);
                return (_jsx(Chip, { label: header === null || header === void 0 ? void 0 : header.formatter(x, dynamicSelects), sx: { m: 0.25 } }));
            }
            if (x instanceof Object) {
                const res = header === null || header === void 0 ? void 0 : header.formatter(x, row, navigate);
                return typeof res === 'object' && !React.isValidElement(res)
                    ? '---'
                    : res;
            }
            return header === null || header === void 0 ? void 0 : header.formatter(x, row, navigate);
        }
        if (x instanceof Object && !React.isValidElement(x)) {
            return JSON.stringify(x);
        }
        if ((header === null || header === void 0 ? void 0 : header.type) === 'boolean') {
            return x ? 'Yes' : 'No';
        }
        return x;
    };
    const handleCardClick = (event) => {
        if (nonSelectable)
            return;
        if (onDelete instanceof Function) {
            handleClick(event, row.id || Object.values(row).join('-'));
        }
        else if (onClick instanceof Function && !onDelete) {
            onClick(row);
        }
    };
    const SelectDbValsShowMore = ({ data }) => {
        const [showMore, setShowMore] = useState(false);
        return (_jsxs(Box, { children: [data.slice(0, 8).map((item, i) => (_jsx(Box, { children: item }, i))), showMore && data.slice(8).map((item, i) => _jsx(Box, { children: item }, i)), data.length > 8 && (_jsx(Button, { onClick: () => setShowMore(!showMore), sx: { color: '#555' }, children: showMore ? 'Show less' : `Show ${data.length - 8} more` }))] }));
    };
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollaps = (e) => {
        e.stopPropagation();
        setCollapsed(!collapsed);
    };
    const renderTableCell = (row, headerKey, header, getter) => {
        var _a, _b;
        row[headerKey] !== undefined;
        return Array.isArray(row[header.id]) && !header.tableFormatter ? (header.id === 'notes' ? (_jsx(Tooltip, { title: _jsx(Box, { children: row[header.id].map((item) => (_jsx(Box, { sx: { my: 0.5 }, children: item }, item))) }), children: _jsx("span", { style: { whiteSpace: 'nowrap' }, children: row[header.id].length
                    ? `⚠️ ${row[header.id].length} conflicts`
                    : null }) })) : (_jsx(SelectDbValsShowMore, { data: row[header.id].map((item, i) => {
                var _a, _b, _c, _d;
                return (_jsxs("span", { children: [header.type === 'dynamic-select' ? (formatter(item, (_b = (_a = dynamicSelects[header.table]) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : dynamicSelects[header.table], header)) : (_jsx(Chip, { label: formatter(item, (_d = (_c = dynamicSelects[header.table]) === null || _c === void 0 ? void 0 : _c.data) !== null && _d !== void 0 ? _d : dynamicSelects[header.table], header), sx: {
                                m: 0.25,
                                cursor: header.itemOnClick || header.linker
                                    ? 'pointer'
                                    : 'default',
                                maxWidth: 500,
                                '&.MuiChip-root': {
                                    height: 'auto',
                                    '& .MuiChip-root': {
                                        height: 'auto',
                                    },
                                },
                            }, onClick: (e) => {
                                e.stopPropagation();
                                typeof header.itemOnClick === 'function' &&
                                    header.itemOnClick(item);
                            }, clickable: typeof header.linker === 'function', component: typeof header.linker === 'function' ? 'a' : 'div', href: typeof header.linker === 'function'
                                ? header.linker(item)
                                : undefined }, item)), header.delimiter &&
                            i < row[header.id].length - 1 &&
                            header.delimiter] }, typeof item === 'object' ? JSON.stringify(item) : item));
            }) }))) : (formatter(getter(row), header.table
            ? ((_b = (_a = dynamicSelects[header.table]) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : dynamicSelects[header.table])
            : undefined, header));
        return null;
    };
    const displayItem = (headerKey) => {
        if (!outstandingFieldsInMobileView ||
            outstandingFieldsInMobileView.length === 0) {
            return true;
        }
        if (!collapsed) {
            return true;
        }
        return outstandingFieldsInMobileView.includes(headerKey);
    };
    return (_jsx(TableRow, { className: "TableRowCard", children: _jsx(TableCell, { sx: { px: 1 }, children: _jsx(Card, { onClick: handleCardClick, sx: isItemSelected
                    ? { border: '1px solid #2196f3', background: '#e5f1fd' }
                    : { border: 'inherit' }, children: _jsxs(CardContent, { sx: { p: 1, '&:last-child': { pb: 1 } }, children: [_jsx(Grid, { container: true, spacing: 1, children: headersFiltered === null || headersFiltered === void 0 ? void 0 : headersFiltered.map((header) => {
                                var _a, _b, _c;
                                const headerKey = (_a = header.keyAs) !== null && _a !== void 0 ? _a : header.id;
                                const getter = header.getter || ((x) => x[headerKey]);
                                return (_jsx(Grid, { item: true, xs: 6, sx: {
                                        display: displayItem(headerKey) &&
                                            renderTableCell(row, headerKey, header, getter)
                                            ? 'inherit'
                                            : 'none',
                                    }, children: _jsxs(Box, { sx: {
                                            lineBreak: 'anywhere',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                        }, children: [renderTableCell(row, headerKey, header, getter) && (_jsx(Typography, { variant: "subtitle1", sx: {
                                                    fontSize: '11px',
                                                    color: '#555',
                                                    mb: -0.5,
                                                }, children: header.label })), renderTableCell(row, headerKey, header, getter)] }) }, `${(_b = header.source) !== null && _b !== void 0 ? _b : ''}${header.id}${(_c = header.id2) !== null && _c !== void 0 ? _c : ''}`));
                            }) }), _jsxs(Box, { sx: { display: 'flex', justifyContent: 'flex-end' }, children: [onEdit instanceof Function && !readOnly && (_jsx(IconButton, { onClick: () => {
                                        onEdit(row);
                                    }, sx: { mr: 0.5 }, children: _jsx(EditIcon, {}) })), outstandingFieldsInMobileView &&
                                    outstandingFieldsInMobileView.length > 0 && (_jsx(IconButton, { onClick: toggleCollaps, children: collapsed ? _jsx(KeyboardArrowDown, {}) : _jsx(KeyboardArrowUp, {}) }))] })] }) }) }) }, key));
};
export default CardView;
