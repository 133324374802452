import { createElement as _createElement } from "react";
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo, useEffect, forwardRef, } from 'react';
import { Autocomplete, TextField, Checkbox, ListItemText, Box, Button, CircularProgress, Chip, Popper, } from '@mui/material';
import { isFunction } from 'lodash';
import { isNonEmptyArray } from 'common/helpers';
var OptionsType;
(function (OptionsType) {
    OptionsType["STRING"] = "string";
    OptionsType["OBJECT"] = "object";
})(OptionsType || (OptionsType = {}));
const CustomPopper = (props) => {
    return _jsx(Popper, Object.assign({}, props, { style: { width: 'auto' } }));
};
/**
 * MultiSelectV2 Component
 *
 * Props:
 *
 * @param {SelectItem[] | string[]} options - The options to display in the select dropdown. Can be an array of objects or strings.
 * @param {(option: SelectItem | string) => string} [formatter] - Function to get the label to show for an option. Required if options is an object.
 * @param {(option: SelectItem | string) => string} [valuer] - Function to get the value to use for an option. Required if options is an object.
 * @param {(option: SelectItem | string) => string} [selectedValueRenderer] - Function to define a custom renderer for the selected options. If not provided, the formatter prop will be used.
 * @param {(value: MultiSelectItem[] | MultiSelectItem | null, options: SelectItem[] | string[]) => void} onChange - Custom callback function to handle the change event when the selected value(s) change.
 * @param {string} [label] - The shown label for the select input.
 * @param {boolean} [multiple=false] - Whether multiple selections are allowed.
 * @param {boolean} [disabled=false] - Whether the select input is disabled.
 * @param {(selectedOption: MultiSelectItem | MultiSelectItem[] | null, availableOptions: MultiSelectItem[]) => ReactNode} [renderCustomOption] - Function to render custom options in the dropdown. The custom option is rendered before the list of options and it can be any React element.
 * @param {boolean} [enablePagination=false] - Whether to enable pagination for the options.
 * @param {number} [itemsPerPage=10] - The number of items to display per page when pagination is enabled.
 * @param {string} [placeholder=label] - The placeholder text for the select input. If not provided, defaults to the label.
 * @param {boolean} [loading=false] - Whether the options are currently loading.
 * @param {SxProps} [sx] - The sx prop for styling the component.
 * @param {MultiSelectItem[] | MultiSelectItem | null} [value] - The selected value(s) for the select input.
 */
const MultiSelectV2 = ({ options, valuer, formatter, onChange, label, multiple = false, disabled = false, renderCustomOption, selectedValueRenderer, enablePagination = false, itemsPerPage = 10, placeholder = label, loading = false, sx = {}, value = null, }) => {
    const [page, setPage] = useState(1);
    const [selectedValue, setSelectedValue] = useState(value || (multiple ? [] : null));
    const isValidFormatter = isFunction(formatter);
    const isValidValuer = isFunction(valuer);
    const isValidSelectedValueRenderer = isFunction(selectedValueRenderer);
    const optionsType = useMemo(() => {
        if (isNonEmptyArray(options)) {
            if (typeof options[0] === 'string') {
                return OptionsType.STRING;
            }
            else if (typeof options[0] === 'object') {
                return OptionsType.OBJECT;
            }
            else {
                console.warn('Invalid option type for MultiSelectV2');
            }
        }
        return OptionsType.STRING;
    }, [options]);
    const selectOptions = useMemo(() => {
        return options
            .map((option) => {
            if (optionsType === OptionsType.STRING) {
                return { value: option, label: option, data: option };
            }
            else if (optionsType === OptionsType.OBJECT && option !== null) {
                if (!isValidFormatter) {
                    console.error('Invalid formatter prop for object type options.');
                    return null;
                }
                if (!isValidValuer) {
                    console.error('Invalid valuer prop for object type options.');
                    return null;
                }
                return {
                    value: valuer(option),
                    label: formatter(option),
                    data: option,
                };
            }
            else {
                console.error('Invalid option type for MultiSelectV2');
                return null;
            }
        })
            .filter(Boolean);
    }, [
        options,
        optionsType,
        formatter,
        valuer,
        isValidFormatter,
        isValidValuer,
    ]);
    const displayedOptions = useMemo(() => {
        return enablePagination
            ? selectOptions.slice(0, page * itemsPerPage)
            : selectOptions;
    }, [selectOptions, enablePagination, page, itemsPerPage]);
    const handleShowMore = () => {
        setPage((prevPage) => prevPage + 1);
    };
    const filterOptions = (filteredOptions, state) => {
        const filtered = filteredOptions.filter((filteredOption) => {
            const label = optionsType === OptionsType.STRING
                ? isValidFormatter
                    ? formatter(filteredOption.data)
                    : filteredOption.label
                : isValidFormatter
                    ? formatter(filteredOption.data)
                    : filteredOption.label;
            return label
                ? label.toLowerCase().includes(state.inputValue.toLowerCase())
                : false;
        });
        return enablePagination ? filtered.slice(0, page * itemsPerPage) : filtered;
    };
    const renderOption = (props, selectedOption, state) => (_createElement("li", Object.assign({}, props, { key: selectedOption.value }),
        multiple && _jsx(Checkbox, { checked: state.selected }),
        _jsx(ListItemText, { primary: optionsType === OptionsType.STRING
                ? isValidFormatter
                    ? formatter(selectedOption.data)
                    : selectedOption.label
                : isValidFormatter
                    ? formatter(selectedOption.data)
                    : '' }, selectedOption.value)));
    const renderInput = (params) => (_jsx(TextField, Object.assign({}, params, { label: label, placeholder: placeholder, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(_Fragment, { children: [loading && _jsx(CircularProgress, { color: "inherit", size: 20 }), params.InputProps.endAdornment] })) }) })));
    const renderListboxComponent = forwardRef((listboxProps, ref) => (_jsx(Box, Object.assign({ component: "ul" }, listboxProps, { ref: ref, children: loading ? (_jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "100%", sx: { m: 1 }, children: _jsx(CircularProgress, { color: "inherit", size: 20 }) })) : (_jsxs(_Fragment, { children: [renderCustomOption &&
                    renderCustomOption(selectedValue, selectOptions), listboxProps.children, enablePagination &&
                    displayedOptions.length < selectOptions.length && (_jsx(Box, { display: "flex", justifyContent: "center", p: 2, children: _jsx(Button, { onClick: handleShowMore, children: "Show More" }) }))] })) }))));
    const renderTags = (value, getTagProps) => value.map((selectedOption, index) => (_jsx(Chip, Object.assign({ label: isValidSelectedValueRenderer
            ? selectedValueRenderer(selectedOption.data)
            : optionsType === OptionsType.STRING
                ? isValidFormatter
                    ? formatter(selectedOption.data)
                    : selectedOption.label
                : isValidFormatter
                    ? formatter(selectedOption.data)
                    : '' }, getTagProps({ index })))));
    useEffect(() => {
        if (renderCustomOption) {
            renderCustomOption(selectedValue, selectOptions);
        }
    }, [selectedValue, selectOptions, renderCustomOption]);
    return (_jsx(Autocomplete, { disabled: disabled, multiple: multiple, filterSelectedOptions: multiple, options: selectOptions, filterOptions: filterOptions, onChange: (event, value) => {
            setSelectedValue(value);
            onChange(value, options);
        }, getOptionLabel: (option) => optionsType === OptionsType.STRING
            ? isValidFormatter
                ? formatter(option.data)
                : option.label
            : isValidFormatter
                ? formatter(option.data)
                : '', loading: loading, renderOption: renderOption, renderInput: renderInput, ListboxComponent: renderListboxComponent, renderTags: renderTags, sx: sx, PopperComponent: CustomPopper, value: selectedValue }));
};
export default MultiSelectV2;
