import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Box, Button } from '@mui/material';
export const SelectSearchResultToggle = ({ onChange, value, multiple, filteredList, searchKeyword, enableSelectAllSearchResult = true, }) => {
    const selectedValues = useMemo(() => {
        return Array.isArray(value)
            ? value.reduce((prev, cur) => {
                prev[cur.value] = true;
                return prev;
            }, {})
            : { [(value === null || value === void 0 ? void 0 : value.value) || '']: true };
    }, [value]);
    const showSelectSearchResult = Boolean(filteredList.length) &&
        multiple &&
        Boolean(searchKeyword) &&
        enableSelectAllSearchResult;
    const isAllSearchResultSelected = useMemo(() => {
        if (!showSelectSearchResult)
            return '';
        const isAllSearchResultSelected = filteredList.every((item) => selectedValues[item.value]);
        return isAllSearchResultSelected;
    }, [filteredList, selectedValues, showSelectSearchResult]);
    const selectSearchResultText = isAllSearchResultSelected
        ? 'Deselect all search result'
        : 'Select all search result';
    const toggleSelectSearchResult = () => {
        const filterListValues = new Set(filteredList.map((item) => item.value));
        if (isAllSearchResultSelected) {
            const updatedValues = value === null || value === void 0 ? void 0 : value.filter((v) => !filterListValues.has(v.value));
            onChange === null || onChange === void 0 ? void 0 : onChange(updatedValues);
        }
        else {
            const newItems = filteredList.filter((item) => !selectedValues[item.value]);
            onChange === null || onChange === void 0 ? void 0 : onChange([...newItems, ...(value || [])]);
        }
    };
    return showSelectSearchResult ? (_jsx(Box, { sx: { display: 'flex', ml: 1 }, children: _jsx(Button, { onClick: toggleSelectSearchResult, children: selectSearchResultText }) })) : null;
};
