import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Chat, Login } from '@mui/icons-material';
import { Chip, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import Formatter from '@/services/Formatter';
import { CommentDrawer } from '@/common';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { NotesEntityTypes } from '@/types';
const dataSpec = {
    label: 'Accounts',
    table: 'admin/accounts',
    fields: {
        str_id: {
            label: 'ID',
            copyable: true,
            id: 'str_id',
            enabled: true,
        },
        name: {
            label: 'Name',
            enabled: true,
            visible: true,
        },
        account_user_roles: {
            label: 'Log in as user',
            enabled: true,
            visible: true,
            formatter: (fieldData, rowData) => {
                return (_jsx(Chip, { label: `${fieldData.user.email} (${fieldData.role.name})${fieldData.user.state !== 'active' ? ` (${fieldData.user.state})` : ''}`, onClick: () => {
                        localStorage.clear();
                        localStorage.setItem('customLoginUser', JSON.stringify(fieldData.user));
                        localStorage.setItem('selectedAccount', JSON.stringify({
                            accountId: rowData.str_id,
                            accountName: rowData.name,
                            accountMode: rowData.mode,
                            whiteLabelMode: rowData.white_label_mode,
                            logoUrl: rowData.logo_url,
                            state: rowData.state,
                        }));
                        localStorage.setItem('triggerReload', Date.now().toString());
                        window.location.pathname = '/';
                    }, clickable: true, icon: _jsx(Login, {}), sx: { m: 0.25 }, disabled: fieldData.user.state !== 'active' }, fieldData.id));
            },
            disableSort: true,
        },
        csms: {
            label: 'CSM(s)',
            enabled: true,
            visible: true,
            formatter: (o) => {
                return (o && Object.keys(o).length > 0 && Formatter.contact(JSON.parse(o)));
            },
        },
        type: {
            label: 'Type',
            enabled: true,
            visible: true,
            formatter: (o) => {
                var _a;
                const mapping = {
                    fintary: 'Test',
                    life: 'Life',
                    health: 'Health',
                    'p&c': 'P&C',
                };
                return (_a = mapping[o]) !== null && _a !== void 0 ? _a : o;
            },
        },
        status: {
            label: 'Status',
            enabled: true,
            visible: true,
            formatter: (o) => {
                var _a;
                const mapping = {
                    active: 'Active',
                    onboarding: 'Onboarding',
                    demo: ' Demo',
                    closed: 'Closed',
                };
                return (_a = mapping[o]) !== null && _a !== void 0 ? _a : o;
            },
        },
        created_at: {
            label: 'Last reconciliation',
            enabled: true,
            visible: true,
            formatter: (o, row) => {
                var _a, _b;
                return (row === null || row === void 0 ? void 0 : row.reconciliation_result) && (_jsx(Tooltip, { title: dayjs((_a = row === null || row === void 0 ? void 0 : row.reconciliation_result) === null || _a === void 0 ? void 0 : _a.created_at)
                        .toDate()
                        .toLocaleDateString(), placement: "right", children: _jsx("span", { children: `${Math.round(dayjs().diff(dayjs((_b = row === null || row === void 0 ? void 0 : row.recondciliation_result) === null || _b === void 0 ? void 0 : _b.created_at), 'days'))} day(s) ago` }) }));
            },
            disableSort: true,
        },
        reconciliation_result: {
            label: 'Matched',
            enabled: true,
            visible: true,
            formatter: (o) => `${Math.round(((o === null || o === void 0 ? void 0 : o.statementsReconciled) / (o === null || o === void 0 ? void 0 : o.statementsTotal)) * 100)}%`,
            description: '% of commission statement records matched to a policy record',
            disableSort: true,
        },
    },
    filters: {
        status: { label: 'Status' },
        type: { label: 'Type' },
    },
};
const LoginAsView = () => {
    const [showComments, setShowComments] = useState(false);
    const [current, setCurrent] = useState(null);
    const actions = [
        {
            label: 'Chat',
            type: 'iconButton',
            icon: _jsx(Chat, {}),
            onClick: (row) => {
                setShowComments(true);
                setCurrent(row);
            },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { dataSpec: dataSpec, hideAdd: true, hideSelectedCount: true, enableMultiSelect: false, enableEdit: false, actions: actions, actionsEnabled: () => true }), showComments && (_jsx(CommentDrawer, { open: showComments, setOpen: setShowComments, rowData: current, type: NotesEntityTypes.ACCOUNT }))] }));
};
export default LoginAsView;
