import { jsx as _jsx } from "react/jsx-runtime";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { isValidJsonString } from 'common/helpers';
import { RulesOperators } from 'common/globalTypes';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import { FieldTypes } from '@/types';
import { EnhancedSelect } from './EnhancedSelect';
import { ExpandableTextField } from './ExpandableTextField';
const fieldTypeComponents = {
    [FieldTypes.DATE]: ({ fieldMatcher, fieldsMatchers, i, setValue }) => (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(BasicDatePicker, { sx: { mr: 1 }, label: "Value", value: fieldMatcher.value, setValue: (value) => {
                const newFieldMatchers = [...fieldsMatchers];
                newFieldMatchers[i].value = dayjs(value).utc().format('YYYY-MM-DD');
                setValue(newFieldMatchers);
            } }) })),
    [FieldTypes.SELECT]: ({ field, fieldMatcher, fieldsMatchers, i, setValue, }) => (_jsx(EnhancedSelect, { label: "Value", options: field.options, value: field.options.find((item) => {
            if (typeof item === 'string')
                return item === fieldMatcher.value;
            return item.id === fieldMatcher.value;
        }), onChange: (v) => {
            const newFieldMatchers = [...fieldsMatchers];
            console.log(v);
            newFieldMatchers[i].value = typeof v === 'string' ? v : v.id;
            setValue(newFieldMatchers);
        }, sx: { mr: 1 } })),
    // Add more field types and their corresponding components here
};
const DefaultFieldComponent = ({ fieldMatcher, fieldsMatchers, i, setValue, }) => (_jsx(ExpandableTextField, { label: "Value", defaultValue: fieldMatcher.value, onChange: (value) => {
        const newFieldMatchers = [...fieldsMatchers];
        newFieldMatchers[i].value = value;
        setValue(newFieldMatchers);
    }, error: ['ncontainedin', 'containedin'].includes(fieldMatcher.op) &&
        !isValidJsonString(fieldMatcher.value) }));
const FieldMatcherComponent = ({ fields, fieldMatcher, fieldsMatchers, i, setValue, }) => {
    const field = fields.find((f) => f.id === (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.field));
    let FieldComponent = fieldTypeComponents[field === null || field === void 0 ? void 0 : field.type] || DefaultFieldComponent;
    if ((fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) === RulesOperators.WITHIN_ONE_YEAR ||
        (fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.op) === RulesOperators.AT_LEAST_ONE_YEAR) {
        FieldComponent = fieldTypeComponents[FieldTypes.SELECT];
    }
    return (_jsx(FieldComponent, { field: field, fieldMatcher: fieldMatcher, fieldsMatchers: fieldsMatchers, i: i, setValue: setValue }));
};
export default FieldMatcherComponent;
