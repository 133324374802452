import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close as CloseIcon } from '@mui/icons-material';
import { Autocomplete, Box, Button, Divider, FormControl, IconButton, TextField, Tooltip, Typography, } from '@mui/material';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { FilterSelect } from '@/common';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import Statements from '@/services/Statements';
import FieldMatcher from '@/components/molecules/FieldMatcher';
import { useAccountStore } from '@/store';
const defaultMemo = {
    approver_name: '',
    description: '',
    recorded_by_user_id: '',
    start_date: null,
    remarks: '',
};
const statements = new Statements('insurance');
const statementFields = Object.values(statements.fields);
const ContactsMemosAdd = ({ data, field, setter, dynamicSelects, }) => {
    var _a, _b, _c;
    const { selectedAccount } = useAccountStore();
    const [approvedInputs, setApprovedInputs] = useState({});
    const contactMemos = (_a = data === null || data === void 0 ? void 0 : data.contact_memos) !== null && _a !== void 0 ? _a : [];
    const { data: _contacts } = API.getBasicQuery('contacts', 'is_dynamic_select=true');
    const contacts = [
        ...new Set((_c = (_b = _contacts === null || _contacts === void 0 ? void 0 : _contacts.data) === null || _b === void 0 ? void 0 : _b.map((c) => Formatter.contact(c, {
            incl_email: true,
            account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
        }))) !== null && _c !== void 0 ? _c : []),
    ];
    const { data: users } = API.getBasicQuery('users/get_account_users', 'state=active');
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsx(Box, { sx: {
                        mt: 0.5,
                        px: 1,
                        pt: 0.5,
                        pb: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                    }, children: _jsxs(Box, { sx: { my: 0.5, display: 'flex', flexWrap: 'wrap' }, children: [contactMemos.map((memo, index) => {
                                var _a, _b, _c;
                                return (_jsxs(Box, { sx: {
                                        m: 0.5,
                                        p: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'silver',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        display: 'inline-block',
                                        width: '100%',
                                        backgroundColor: '#2196f30a',
                                    }, children: [_jsx(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: _jsx(Typography, { variant: "body2" }) }), _jsxs(Box, { children: [_jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'start',
                                                    }, children: [_jsx(BasicDatePicker, { label: "Date", value: memo.start_date, setValue: (e) => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_memos: data.contact_memos.map((item) => item.id === memo.id
                                                                        ? Object.assign(Object.assign({}, item), { start_date: e }) : item) }));
                                                            }, sx: { my: 0.5, width: 160 } }), _jsx(IconButton, { onClick: () => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_memos: contactMemos.filter((cm) => cm.id !== memo.id) }));
                                                            }, children: _jsx(CloseIcon, {}) })] }), _jsx(TextField, { label: "Description", variant: "outlined", value: memo.description, onChange: (e) => {
                                                        setter(Object.assign(Object.assign({}, data), { contact_memos: data.contact_memos.map((item) => item.id === memo.id
                                                                ? Object.assign(Object.assign({}, item), { description: e.target.value }) : item) }));
                                                    }, fullWidth: true, multiline: true, sx: { my: 0.5 } }), _jsx(TextField, { label: "Remarks", variant: "outlined", value: memo.remarks, onChange: (e) => {
                                                        setter(Object.assign(Object.assign({}, data), { contact_memos: data.contact_memos.map((item) => item.id === memo.id
                                                                ? Object.assign(Object.assign({}, item), { remarks: e.target.value }) : item) }));
                                                    }, fullWidth: true, multiline: true, sx: { my: 0.5 } }), _jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'space-between',
                                                    }, children: [_jsx(Autocomplete, { options: [
                                                                ...new Set([
                                                                    ...(contacts !== null && contacts !== void 0 ? contacts : []),
                                                                    memo.approver_name,
                                                                    (_a = approvedInputs[memo.id]) !== null && _a !== void 0 ? _a : '',
                                                                    '',
                                                                ]),
                                                            ], value: (_b = memo.approver_name) !== null && _b !== void 0 ? _b : '', onChange: (e, val) => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_memos: data.contact_memos.map((item) => item.id === memo.id
                                                                        ? Object.assign(Object.assign({}, item), { approver_name: val }) : item) }));
                                                            }, getOptionLabel: (o) => o, inputValue: approvedInputs[memo.id], onInputChange: (event, newInputValue) => {
                                                                setApprovedInputs(Object.assign(Object.assign({}, approvedInputs), { [memo.id]: newInputValue }));
                                                            }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Approved by", placeholder: "Select approver" }))), sx: { mt: 0.5, mr: 0.5, width: '100%' } }), _jsx(FilterSelect, { label: "Recorded by", placeholder: "Select user", options: [...(users !== null && users !== void 0 ? users : []), ''], getOptionLabel: (o) => Formatter.contact(typeof o === 'string' && users
                                                                ? users === null || users === void 0 ? void 0 : users.find((u) => u.uid === o)
                                                                : o, {
                                                                incl_email: true,
                                                                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                            }), value: memo.recorded_by_user_id, onChange: (e) => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_memos: data.contact_memos.map((item) => {
                                                                        var _a;
                                                                        return item.id === memo.id
                                                                            ? Object.assign(Object.assign({}, item), { recorded_by_user_id: (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.uid }) : item;
                                                                    }) }));
                                                            }, sx: { mt: 0.5, ml: 0.5, width: '100%' } })] }), _jsx(Divider, { sx: { mt: 1 } }), _jsx(Tooltip, { placement: "right", title: "Tag commission entries based on criteria", children: _jsx(Typography, { variant: "caption", sx: { cursor: 'default' }, children: "Memo tagging \u24D8" }) }), _jsx(FieldMatcher, { value: (_c = memo === null || memo === void 0 ? void 0 : memo.match_criteria) !== null && _c !== void 0 ? _c : [], setValue: (e) => {
                                                        setter(Object.assign(Object.assign({}, data), { contact_memos: data.contact_memos.map((item) => {
                                                                return item.id === memo.id
                                                                    ? Object.assign(Object.assign({}, item), { match_criteria: e }) : item;
                                                            }) }));
                                                    }, fields: statementFields, addLabel: "Add criteria" })] })] }, `${memo.id}-${index}`));
                            }), _jsx(FormControl, { sx: { m: 0.5, width: 30 }, children: _jsx(Button, { onClick: (e) => {
                                        var _a;
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setter(Object.assign(Object.assign({}, data), { contact_memos: [
                                                ...((_a = data.contact_memos) !== null && _a !== void 0 ? _a : []),
                                                Object.assign(Object.assign({}, defaultMemo), { id: nanoid() }),
                                            ] }));
                                    }, children: "Add" }) }, field.id)] }) }) }, `${field.id}-box`)] }));
};
export default ContactsMemosAdd;
