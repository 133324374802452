var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, TextField, } from '@mui/material';
import { AccountIds } from 'common/constants';
import dayjs from 'dayjs';
import { useState } from 'react';
import AdminCard from '@/common/AdminCard';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import MultiSelect from '@/components/molecules/MultiSelect';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useAccountStore } from '@/store';
export const DataSyncWorker = ({ workerInfo, onSyncComplete, onSyncStart }) => {
    const { showSnackbar } = useSnackbar();
    const isAgencyIntegrator = (workerInfo === null || workerInfo === void 0 ? void 0 : workerInfo.worker) === 'AgencyIntegratorWorker';
    const [showSyncDateRange, setShowSyncDateRange] = useState(false);
    const { selectedAccount } = useAccountStore();
    const [loading, setLoading] = useState(false);
    const policyStates = [
        'Active',
        'Cancelled',
        'Expired',
        'Renewed',
        'Fintary',
        'Non-Renewal',
        'Pending Cancel',
        'Renewing',
        'Replaced',
    ];
    const isTrailStoneAccount = (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) === AccountIds.TRAILSTONE;
    const SYNCERS = (workerInfo === null || workerInfo === void 0 ? void 0 : workerInfo.entities) || [];
    const [syncArgs, setSyncArgs] = useState({
        entities: SYNCERS,
        sync: false,
        startDate: null,
        endDate: null,
        worker: workerInfo === null || workerInfo === void 0 ? void 0 : workerInfo.worker,
        policyStates: isTrailStoneAccount ? policyStates : undefined,
        policyNumbers: [],
    });
    const [showPolicyNumbers, setShowPolicyNumbers] = useState(false);
    const syncData = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield fetch(`${process.env.REACT_APP_API}/api/data_processing/sync`, {
            method: 'POST',
            headers: yield API.getHeaders(),
            body: JSON.stringify(syncArgs),
        });
        return res;
    });
    const handleSync = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            const res = yield syncData();
            const data = yield res.json();
            if (res.status === 200) {
                if (!(data === null || data === void 0 ? void 0 : data.success)) {
                    showSnackbar(data.message, 'error');
                }
                else {
                    onSyncStart === null || onSyncStart === void 0 ? void 0 : onSyncStart(data.taskId);
                    showSnackbar('Data sync task has been created, please check data processing log to view task status', 'success');
                }
            }
            else if (res.status >= 400) {
                showSnackbar(data.message || data.statusText, 'error');
            }
            else {
                showSnackbar('Error syncing data', 'error');
            }
        }
        catch (error) {
            showSnackbar('Error syncing data', 'error');
        }
        finally {
            setLoading(false);
            onSyncComplete === null || onSyncComplete === void 0 ? void 0 : onSyncComplete();
        }
    });
    return (_jsx(Box, { sx: { mb: 2 }, children: _jsxs(FormControl, { children: [_jsxs(FormLabel, { children: ["Data sync (", workerInfo === null || workerInfo === void 0 ? void 0 : workerInfo.worker, ")"] }), _jsx(MultiSelect, { label: "Entities", values: SYNCERS === null || SYNCERS === void 0 ? void 0 : SYNCERS.sort(), selectedValues: syncArgs.entities || [], setSelectedValues: (entities) => setSyncArgs(Object.assign(Object.assign({}, syncArgs), { entities })), sx: { minWidth: 200, maxWidth: 266, mt: 1 } }), isAgencyIntegrator && (_jsxs(_Fragment, { children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { onChange: (e) => {
                                    setSyncArgs(Object.assign(Object.assign({}, syncArgs), { isFullSync: e.target.checked }));
                                } }), label: "Full run" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: showPolicyNumbers, onChange: (e) => {
                                    setShowPolicyNumbers(e.target.checked);
                                    if (!e.target.checked) {
                                        setSyncArgs(Object.assign(Object.assign({}, syncArgs), { policyNumbers: [] }));
                                    }
                                } }), label: "Sync with policy numbers" }), showPolicyNumbers && (_jsx(TextField, { label: "Policy numbers", placeholder: "Enter policy numbers separated by new lines or commas", multiline: true, sx: { my: 1 }, maxRows: 5, onChange: (e) => {
                                const policyNumbers = e.target.value
                                    .split(/[\n,]/g)
                                    .map((num) => num.trim())
                                    .map((num) => num.replace(/'|"/g, ''))
                                    .filter((r) => r);
                                setSyncArgs(Object.assign(Object.assign({}, syncArgs), { policyNumbers }));
                            } }))] })), isTrailStoneAccount && (_jsxs(_Fragment, { children: [_jsx(MultiSelect, { label: "Filter policy", values: policyStates === null || policyStates === void 0 ? void 0 : policyStates.sort(), sx: { minWidth: 200, maxWidth: 266, my: 1 }, selectedValues: syncArgs.policyStates || [], setSelectedValues: (states) => {
                                setSyncArgs(Object.assign(Object.assign({}, syncArgs), { policyStates: states.filter((s) => s) }));
                            } }), _jsx(FormControlLabel, { label: "Sync date range", control: _jsx(Checkbox, {}), checked: showSyncDateRange, onClick: (e) => setShowSyncDateRange(!showSyncDateRange) }), showSyncDateRange && (_jsx(Box, { children: _jsx(BasicDateRangePicker, { range: {
                                    startDate: (syncArgs === null || syncArgs === void 0 ? void 0 : syncArgs.startDate)
                                        ? dayjs.utc(syncArgs.startDate)
                                        : null,
                                    startDateLabel: 'Start date',
                                    endDate: (syncArgs === null || syncArgs === void 0 ? void 0 : syncArgs.endDate)
                                        ? dayjs.utc(syncArgs.endDate)
                                        : null,
                                    endDateLabel: 'End date',
                                }, onChange: ({ startDate, endDate }) => {
                                    setSyncArgs(Object.assign(Object.assign({}, syncArgs), { startDate: startDate
                                            ? dayjs.isDayjs(startDate)
                                                ? startDate.toDate()
                                                : new Date(startDate)
                                            : null, endDate: endDate
                                            ? dayjs.isDayjs(endDate)
                                                ? endDate.toDate()
                                                : new Date(endDate)
                                            : null }));
                                }, my: 0.5 }) }))] })), _jsx(AdminCard, { showIcon: true, iconPosition: "right", children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: !syncArgs.sync, onChange: (e) => setSyncArgs(Object.assign(Object.assign({}, syncArgs), { sync: !e.target.checked })) }), label: "Use workers" }) }), _jsx(LoadingButton, { loading: loading, onClick: handleSync, variant: "contained", children: "Sync" })] }) }));
};
