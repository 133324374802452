import { jsx as _jsx } from "react/jsx-runtime";
import { ContentCopy } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import copy from 'copy-to-clipboard';
const CopyButton = ({ value, sx = {} }) => (_jsx(IconButton, { onClick: (e) => {
        e.stopPropagation();
        copy(value);
    }, sx: Object.assign({
        opacity: 0,
        '&:hover': { opacity: 1 },
    }, sx), children: _jsx(ContentCopy, {}) }));
export default CopyButton;
