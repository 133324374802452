var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AutoFixHigh, AutoFixNormal } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Chip, ToggleButton, ToggleButtonGroup, Tooltip, Typography, } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { UIStateContext } from 'contexts/UIStateProvider';
import didYouMean, { ReturnTypeEnums } from 'didyoumean2';
import { useContext, useEffect, useMemo, useState } from 'react';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import RowCard from '@/components/molecules/RowCard';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import API from '@/services/API';
import Reports from '@/services/Reports';
import Statements from '@/services/Statements';
import UILabels from '@/services/UILabels';
import { useAccountStore, useRoleStore } from '@/store';
const filters = {
    startDate: {
        label: 'Start date',
        type: 'date',
        field: 'effectiveDate',
        initialize: () => new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0)),
        filterFunc: (val, filterVal) => val >= filterVal,
    },
    endDate: {
        label: 'End date',
        type: 'date',
        field: 'effectiveDate',
        initialize: () => new Date(new Date().setHours(0, 0, 0, 0)),
        filterFunc: (val, filterVal) => val <= filterVal,
    },
    carrier: {
        label: 'Carrier/MGA',
        type: 'multiSelect',
        field: 'writingCarrierName',
        initialize: (data) => Array.from(new Set(Object.values(data)
            .filter((datum) => datum.writingCarrierName)
            .map((datum) => datum.writingCarrierName))),
    },
    mga: {
        label: 'Paying entity',
        type: 'multiSelect',
        field: 'carrierName',
        initialize: (data) => Array.from(new Set(Object.values(data)
            .filter((datum) => datum.carrierName)
            .map((datum) => datum.carrierName))),
    },
};
// TODO: Temp mockup for demo, need to refactor/rewrite
const ReconcilerView = () => {
    var _a, _b, _c, _d;
    const { commissions: [commissionsUI, setCommissionsUI], role: [role], } = useContext(UIStateContext);
    const { data: generalSettings } = API.getUser();
    const mode = (_a = generalSettings === null || generalSettings === void 0 ? void 0 : generalSettings.mode) !== null && _a !== void 0 ? _a : 'default';
    const { selectedAccount } = useAccountStore();
    const { userRole } = useRoleStore();
    const statements = useMemo(() => new Statements(mode, role, userRole, {
        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
    }), [mode]);
    const reports = useMemo(() => new Reports(mode, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId }), [mode]);
    const labels = new UILabels(mode || 'default');
    const [filter, setFilter] = useState((_b = commissionsUI.filter) !== null && _b !== void 0 ? _b : 1);
    const [method, setMethod] = useState('string');
    const [query, setQuery] = useState('');
    const [filteredValues, setFilteredValues] = useState((_c = commissionsUI.filteredValues) !== null && _c !== void 0 ? _c : {});
    const [filteredFields, setFilteredFields] = useState([]);
    const [availableValues, setAvailableValues] = useState({});
    const [dataKeys, setDataKeys] = useState([]);
    // const [reconciliationData, setReconciliationData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedPolicy, setSelectedPolicy] = useState([]);
    // const [statementDataDupes, setStatementDataDupes] = useState([]);
    const [updating, setUpdating] = useState(false);
    const { data: reconciliationResults, isLoading } = API.getBasicQuery('reconciliation_data');
    const reconciliationData = reconciliationResults === null || reconciliationResults === void 0 ? void 0 : reconciliationResults.data;
    useEffect(() => {
        const convertedFilteredValues = Object.assign({}, filteredValues);
        // If multiSelect and all selected, save as 'all', since selected varies from filter to filter.
        Object.entries(filters).forEach(([k, v]) => {
            var _a, _b;
            if (v.type === 'multiSelect') {
                if (availableValues[k] &&
                    filteredValues[k] &&
                    ((_a = availableValues[k]) === null || _a === void 0 ? void 0 : _a.length) === ((_b = filteredValues[k]) === null || _b === void 0 ? void 0 : _b.length)) {
                    convertedFilteredValues[k] = 'all';
                }
            }
        });
        setCommissionsUI(Object.assign(Object.assign({}, commissionsUI), { filteredValues: convertedFilteredValues }));
    }, [filteredValues]);
    const statementIds = selectedData.map((row) => row.id);
    const { data: similarReportsData } = API.getBasicQuery('report_data/similar', `statement_ids=${statementIds.join(',')}`);
    const similarReports = (_d = similarReportsData === null || similarReportsData === void 0 ? void 0 : similarReportsData.similarReports) !== null && _d !== void 0 ? _d : [];
    if (!reconciliationData) {
        return null;
    }
    const orphanedCommissions = reconciliationData.filter((v) => v.reconciled === '⚠️ Missing policy data');
    const mapPolicyIdToNormalized = {};
    reconciliationData
        .filter((v) => v.reconciled !== '⚠️ Missing policy data')
        .forEach((row) => {
        mapPolicyIdToNormalized[row.policy_id] = row.normalized_id;
    });
    const policyIds = reconciliationData
        .filter((v) => v.reconciled !== '⚠️ Missing policy data')
        .map((row) => row.policy_id);
    const reconciliationDataMap = Object.fromEntries(reconciliationData.map((row) => [row.normalized_id, row]));
    orphanedCommissions.forEach((e) => {
        var _a, _b;
        const res = didYouMean((_b = (_a = e.policy_id) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '', policyIds, {
            returnType: ReturnTypeEnums.ALL_SORTED_MATCHES,
            threshold: 0.5,
        });
        if (res.length > 0) {
            res.forEach((e2) => {
                if (!reconciliationDataMap[mapPolicyIdToNormalized[e2]].possibleMatches) {
                    reconciliationDataMap[mapPolicyIdToNormalized[e2]].possibleMatches =
                        [];
                }
                console.log('found possible match', e, e2);
                reconciliationDataMap[mapPolicyIdToNormalized[e2]].possibleMatches.push(e);
            });
            e.possibleMatches = res;
        }
    });
    const dataProcessedFiltered = reconciliationData.filter((row) => {
        var _a, _b, _c;
        if (filter === 0) {
            return true;
        }
        if (filter === 1) {
            return (!((_a = row.reconciled) === null || _a === void 0 ? void 0 : _a.includes('✅')) &&
                (typeof row.policy_status !== 'string' ||
                    ![
                        'inactive',
                        'cancelled',
                        'canceled',
                        'non-renewed',
                        'lapsed',
                    ].includes(row.policy_status.toLowerCase())));
        }
        if (filter === 2) {
            return (_b = row.reconciled) === null || _b === void 0 ? void 0 : _b.includes('⛔️');
        }
        if (filter === 3) {
            return (_c = row.reconciled) === null || _c === void 0 ? void 0 : _c.includes('✅');
        }
        return true;
    });
    const commissionsMissingPolicies = reconciliationData.filter((row) => {
        return row.reconciled === '⚠️ Missing policy data';
    });
    const commissionIdsMissingPolicies = commissionsMissingPolicies.map((row) => row.statement_id);
    const rowCardFields = [
        statements.fields.writing_carrier_name,
        statements.fields.effective_date,
        statements.fields.premium_amount,
        statements.fields.commission_amount,
    ].filter((field) => field);
    console.log('selectedData', selectedData);
    const suggestedResultsStringMatch = [
        ...new Set(selectedData.reduce((acc, cur) => {
            var _a;
            const possibleMatches = (_a = dataProcessedFiltered.find((e) => e.statement_id === String(cur.id))
                .possibleMatches) !== null && _a !== void 0 ? _a : [];
            console.log('possibleMatches', possibleMatches);
            if (possibleMatches) {
                possibleMatches.forEach((match) => {
                    return acc.push(match);
                });
            }
            return acc;
        }, [])),
    ];
    const suggestedResults = method === 'string'
        ? suggestedResultsStringMatch
        : similarReports.map((e) => e.policy_id);
    console.log('suggestedResults', suggestedResults, similarReports);
    return (_jsx(Box, { mt: 2, mx: 2, sx: { width: 'calc(100vw - 232px)' }, children: _jsxs(Box, { children: [_jsx(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", children: _jsx(Typography, { variant: "h5", children: "Reconciliation" }) }), _jsx(Box, { sx: {
                        my: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }, children: _jsx(Chip, { sx: { mr: 0.5, cursor: 'pointer' }, color: filter === 1 ? 'primary' : 'default', label: "Missing policies", variant: filter === 1 ? 'filled' : 'outlined', onClick: () => { } }) }), isLoading && _jsx(LoadingCircle, {}), !isLoading && (_jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(EnhancedDataView, { dataSpec: statements, options: {
                                mode: 'reconciler',
                            }, prefilter: (row) => commissionIdsMissingPolicies.includes(String(row.id)), setSelectedData: setSelectedData, hideSelectedCount: true }), _jsxs(Box, { sx: {
                                width: 220,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                // JustifyContent: 'center',
                                mx: 1,
                                mt: 4,
                            }, children: [_jsxs(ToggleButtonGroup, { orientation: "vertical", value: method, exclusive: true, onChange: (e, newVal) => {
                                        console.log('method', newVal);
                                        if (newVal)
                                            setMethod(newVal);
                                    }, sx: { mb: 4 }, children: [_jsx(ToggleButton, { value: "string", children: _jsx(Tooltip, { title: "Basic magic", placement: "right", children: _jsx(AutoFixNormal, {}) }) }), _jsx(ToggleButton, { value: "embedding", children: _jsx(Tooltip, { title: "Super magic", placement: "right", children: _jsx(AutoFixHigh, {}) }) })] }), selectedData.map((row) => (_jsx(RowCard, { data: row, fields: rowCardFields, colSize: 12, style: {
                                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                                    } }, row.id))), _jsx(LoadingButton, { sx: { mt: 2 }, variant: "contained", disabled: !selectedData[0] || !selectedPolicy[0], onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        setUpdating(true);
                                        setTimeout(() => {
                                            setUpdating(false);
                                        }, 1000);
                                    }), loading: updating, sx: { width: '112px' }, children: "Reconcile" })] }), _jsx(EnhancedDataView, { dataSpec: reports, options: {
                                mode: 'reconciler',
                                radio: true,
                            }, 
                            // TODO: in this case these should be values (policy id strings) not objects (row)...need to reconcile statements vs policy so they're both either values or objects.
                            suggested: suggestedResults, 
                            // Prefilter={(row) => selectedPolicy[0]?.policyId}
                            setSelectedData: setSelectedPolicy })] }))] }) }));
};
export default ReconcilerView;
