import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback, useMemo } from 'react';
import { DataEntities } from 'common/globalTypes';
import { useQueryClient } from '@tanstack/react-query';
import CriteriaActionsViewer from '@/components/ToolsPage/DataUpdateTools/CriteriaActionsViewer';
import { DataUpdateCopyTypes, } from '@/components/ToolsPage/DataUpdateTools/types';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { FieldTypes } from '@/types';
import CriteriaActionsConfig, { CriteriaActionsConfigFieldTypes, } from '@/components/ToolsPage/DataUpdateTools/CriteriaActionsConfig';
import DataUpdateConfigGroups from '@/components/ToolsPage/DataUpdateTools/DataUpdateConfigGroups';
import API from '@/services/API';
import DataUpdateCopyModal from '@/components/ToolsPage/DataUpdateTools/DataUpdateCopyModal';
const DataUpdateConfig = ({ fields }) => {
    const [showGlobal, setShowGlobal] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [openCopy, setOpenCopy] = useState(false);
    const [queryKey, setQueryKey] = useState(null);
    const queryClient = useQueryClient();
    const poster = API.getMutation(`data-update/config?copy=true`, 'POST');
    const handleClose = useCallback(() => {
        setOpenCopy(false);
    }, []);
    const handleRefetch = useCallback(() => {
        if (queryKey) {
            queryClient.refetchQueries({
                queryKey: queryKey,
                type: 'active',
                exact: true,
            });
        }
    }, [queryKey, queryClient]);
    const dataSpec = useMemo(() => ({
        label: 'Data update config',
        table: `data-update/config?global=${showGlobal}&`,
        fields: {
            group: {
                id: 'group',
                label: 'Group',
                table: 'data-update/groups',
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, dynamicSelects) => (_jsx(DataUpdateConfigGroups, { field: field, row: row, setter: setter, dynamicSelects: dynamicSelects })),
                enabled: true,
            },
            data_entity: {
                label: 'Data entity',
                type: FieldTypes.SELECT,
                options: [DataEntities.COMMISSIONS, DataEntities.POLICIES],
                enabled: true,
            },
            name: {
                label: 'Name',
                enabled: true,
            },
            notes: {
                label: 'Notes',
                enabled: true,
            },
            data_update_criteria: {
                id: 'data_update_criteria',
                label: 'Criteria',
                table: 'data-update/criteria',
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, dynamicSelects) => (_jsx(CriteriaActionsConfig, { data: row, field: field, type: CriteriaActionsConfigFieldTypes.CRITERIA, setter: setter, dynamicSelects: dynamicSelects, fields: fields })),
                tableFormatter: (field, row) => {
                    return field.map((f) => (_jsx(CriteriaActionsViewer, { fieldData: f.data_update_criteria, rowData: row, fields: fields })));
                },
                enabled: true,
            },
            data_update_actions: {
                id: 'data_update_actions',
                label: 'Action',
                table: 'data-update/actions',
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, dynamicSelects) => (_jsx(CriteriaActionsConfig, { data: row, field: field, type: CriteriaActionsConfigFieldTypes.ACTION, setter: setter, dynamicSelects: dynamicSelects, fields: fields })),
                tableFormatter: (field, row) => {
                    return field.map((f) => (_jsx(CriteriaActionsViewer, { fieldData: f.data_update_actions, rowData: row, fields: fields })));
                },
                enabled: true,
            },
            access: {
                label: 'Access',
                readOnly: true,
                enabled: true,
            },
        },
    }), [showGlobal, fields]);
    const extraActions = useMemo(() => [
        {
            label: 'Toggle access',
            onClick: () => setShowGlobal(!showGlobal),
            type: 'button',
        },
        {
            label: 'Copy selected',
            onClick: () => setOpenCopy(true),
            disabled: selectedData.length === 0,
            type: 'button',
        },
    ], [showGlobal, selectedData.length]);
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { dataSpec: dataSpec, hideSelectedCount: true, hideExport: true, setSelectedData: setSelectedData, 
                // TODO: Fix this type in the EnhancedDataView component
                // @ts-ignore
                extraActions: extraActions, onQueryKeyChange: setQueryKey }), _jsx(DataUpdateCopyModal, { handleClose: handleClose, open: openCopy, type: DataUpdateCopyTypes.CONFIG, selectedData: selectedData, poster: poster, refetch: handleRefetch })] }));
};
export default DataUpdateConfig;
