var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useOriginalFile, useOverrideFile } from 'store/excelStore';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Spreadsheet from '@/services/Spreadsheet';
import FilePreview from '@/common/preview';
import { XLS_CSV_TYPES } from '@/common/preview/model';
import { DocumentPreviewKeys } from '@/types';
const ProcessPreview = ({ previewWidth, rowData, processForm }) => {
    var _a;
    const [currentPreviewFile, setCurrentPreviewFile] = useState();
    const [spreadsheet, setSpreadsheet] = useState();
    const originalFile = useOriginalFile();
    const overrideFile = useOverrideFile();
    useEffect(() => {
        const target = originalFile || overrideFile;
        const doChange = (file) => __awaiter(void 0, void 0, void 0, function* () {
            if (!file) {
                return;
            }
            setCurrentPreviewFile(file);
        });
        doChange(target);
    }, [originalFile, overrideFile]);
    useEffect(() => {
        // Excel, csv need to load the raw data
        const setExcelData = () => __awaiter(void 0, void 0, void 0, function* () {
            if (currentPreviewFile &&
                XLS_CSV_TYPES.includes(currentPreviewFile.type)) {
                const res = (yield Spreadsheet.loadSpreadsheet(currentPreviewFile));
                setSpreadsheet(res);
            }
        });
        setExcelData();
    }, [currentPreviewFile]);
    return (_jsxs(Box, { sx: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }, children: [_jsx(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    position: 'absolute',
                    zIndex: 100,
                }, children: rowData && (_jsx(Tooltip, { title: ((_a = rowData.override_file_path) === null || _a === void 0 ? void 0 : _a.split('/').pop()) || rowData.filename, children: _jsx(IconButton, { to: `/documents?id=${rowData.str_id}&t=${rowData.override_file_path ? DocumentPreviewKeys.OVERRIDE : DocumentPreviewKeys.ORIGINAL}&m=preview`, component: Link, sx: { opacity: 0.8, '&:hover': { opacity: 1 } }, target: "_blank", children: _jsx(Launch, {}) }) })) }), _jsx(Box, { sx: { flex: 1, overflow: 'auto' }, children: _jsx(FilePreview, { previewFile: currentPreviewFile, previewWith: previewWidth, sheet: processForm.selectedSheet, spreadsheet: spreadsheet, setSpreadsheet: setSpreadsheet }) })] }));
};
ProcessPreview.propTypes = {
    previewWidth: PropTypes.number,
};
ProcessPreview.displayName = 'ProcessPreview';
export default ProcessPreview;
