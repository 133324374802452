import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, CardHeader, Table, TableBody, TableCell, TableRow, Typography, } from '@mui/material';
import React from 'react';
const getStats = (reconciliationResult) => {
    var _a;
    const res = {};
    res['Policy data'] = {
        // Unique: reconciliationResult?.reportsUnique,
        Total: reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.reportsTotal,
        // Duplicates: reconciliationResult?.reportsDupes,
    };
    res['Commission data'] = {
        // Unique: reconciliationResult?.statementsUnique,
        Total: reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.statementsTotal,
        // Duplicates: reconciliationResult?.statementsDupes,
    };
    res['Reconciliation data'] = {
        Total: reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.reconciliationsTotal,
        'Missing Policy Data': reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.missingPolicyData,
        'Missing Commission Data': reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.missingCommissionData,
        'Commissions Shortfall': reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.commissionsShortfall,
        'Policies Matched': `${(reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.reportsReconciled) &&
            (reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.reportsTotal)
            ? ((reconciliationResult.reportsReconciled /
                reconciliationResult.reportsTotal) *
                100).toFixed(0)
            : '0'}% (${reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.reportsReconciled} / ${reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.reportsTotal})`,
        'Commissions Matched': `${(reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.statementsReconciled) &&
            (reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.statementsTotal)
            ? ((reconciliationResult.statementsReconciled /
                reconciliationResult.statementsTotal) *
                100).toFixed(0)
            : '0'}% (${reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.statementsReconciled} / ${reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.statementsTotal})`,
    };
    res['Reconciler stats'] = {};
    (_a = reconciliationResult === null || reconciliationResult === void 0 ? void 0 : reconciliationResult.reconcilerStats) === null || _a === void 0 ? void 0 : _a.forEach((stat) => {
        res['Reconciler stats'][`${stat.id}: ${stat.name}`] = (_jsx("div", { children: Object.entries(stat)
                .filter(([k, v]) => !['id', 'name'].includes(k))
                .map(([k, v]) => (_jsxs("div", { children: [k, ": ", v] }, k))) }));
    });
    return res;
};
const ResultsSection = ({ title, value }) => (_jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Typography, { variant: "subtitle1", children: title }), value &&
            Object.entries(value).map(([k2, v2]) => (_jsx(Table, { children: _jsx(TableBody, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: k2 }), _jsx(TableCell, { align: "right", children: v2 })] }) }) }, k2)))] }));
const ResultsCard = ({ title, data, variant }) => {
    const results = getStats(data);
    const date = new Date(data === null || data === void 0 ? void 0 : data.created_at);
    return (_jsxs(Box, { children: [title && _jsx(CardHeader, { title: title }), variant === 'wide' ? (_jsx(Box, { sx: { display: 'flex', justifyContent: 'space-between' }, children: Object.entries(results).map(([key, value]) => (_jsx(ResultsSection, { title: key, value: value }, key))) })) : (Object.entries(results).map(([key, value]) => (_jsx(Box, { sx: { mt: 1 }, children: _jsx(ResultsSection, { title: key, value: value }) }, key)))), ' ', _jsxs(Typography, { variant: "body2", sx: { mt: 2, textAlign: 'center ' }, children: ["Date: ", date === null || date === void 0 ? void 0 : date.toLocaleString()] })] }));
};
export default ResultsCard;
