import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import SignInDialog from '@/components/SignInDialog';
import SignUpDialog from '@/components/SignUpDialog';
import EmailVerificationDialog from '@/components/EmailVerificationDialog';
const DialogHost = ({ theme, user, dialogs }) => {
    const { signUpDialog } = dialogs;
    const { signInDialog } = dialogs;
    const { emailVerificationDialog } = dialogs;
    return (_jsxs(_Fragment, { children: [!user && (_jsxs(_Fragment, { children: [_jsx(SignUpDialog, { dialogProps: signUpDialog.dialogProps, theme: theme }), _jsx(SignInDialog, { dialogProps: signInDialog.dialogProps, theme: theme })] })), !(user === null || user === void 0 ? void 0 : user.emailVerified) && (_jsx(_Fragment, { children: _jsx(EmailVerificationDialog, { dialogProps: emailVerificationDialog.dialogProps, theme: theme }) }))] }));
};
export default DialogHost;
