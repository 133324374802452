import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import DataView from '@/components/DataView';
import API from '@/services/API';
const View = () => {
    var _a, _b, _c, _d, _e;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.options;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const queryChipData = (_e = (_d = (_c = API.getBasicQuery('companies/products')) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : [];
    const dataDesc = {
        label: 'Product options',
        table: 'companies/products/options',
        editable: true,
        copyable: true,
        bulkAdd: true,
        fields: [
            {
                id: 'product_id',
                label: 'Product',
                type: 'dynamic-select',
                table: 'companies/products',
                field: 'id',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    return (Array.isArray(collectionVals) &&
                        ((_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((item) => item.id === val)) === null || _a === void 0 ? void 0 : _a.product_name)) ||
                        '';
                },
                optionFormatter: (option) => option.product_name,
                optionValuer: (option) => option.id,
                required: true,
            },
            {
                id: 'name',
                label: 'Option',
                required: true,
            },
            {
                id: 'notes',
                label: 'Notes',
            },
        ],
        queryChips: {},
    };
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    if (Array.isArray(queryChipData) && queryChipData.length > 1) {
        const queryChips = [
            {
                id: '0',
                product_name: 'All',
                query: {},
            },
            ...(queryChipData !== null && queryChipData !== void 0 ? queryChipData : []),
        ];
        const FK = 'product_id';
        queryChips.forEach((item) => {
            dataDesc.queryChips[item.id] = {
                id: String(item.id),
                label: item.product_name,
                query: { [FK]: item.id },
            };
        });
    }
    return (_jsx(DataView, { dataDesc: dataDesc, viewOnly: viewOnly, readOnly: viewOnly, enablePagination: true }));
};
export default View;
