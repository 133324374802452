import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useUserInfo } from '@/hooks/useUserInfo';
const AdminCard = (params) => {
    const { children, isAdmin: externalIsAdmin, showIcon = false, iconPosition = 'left', } = params;
    const [isAdmin, setIsAdmin] = useState(false);
    const { data: { fintaryAdmin } = {} } = useUserInfo();
    useEffect(() => {
        if (externalIsAdmin !== undefined) {
            setIsAdmin(externalIsAdmin);
        }
        else {
            setIsAdmin(!!fintaryAdmin);
        }
    }, [externalIsAdmin, fintaryAdmin]);
    if (!isAdmin) {
        return null;
    }
    const icon = _jsx("span", { children: "\uD83D\uDD12" });
    return (_jsxs(Box, { children: [showIcon && iconPosition === 'left' ? icon : null, children, showIcon && iconPosition === 'right' ? icon : null] }));
};
export default AdminCard;
