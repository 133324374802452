import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close, DragIndicatorOutlined } from '@mui/icons-material';
import { Box, Chip, FormControl, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { EnhancedSelect } from '../molecules/EnhancedSelect';
const DraggableItem = ({ item, onDelete, onDrop, index, }) => {
    const [dragging, setDragging] = useState(false);
    const activeClass = 'drag-area-active';
    const dataKey = 'index';
    const ondragstart = (evt) => {
        evt.dataTransfer.setData(dataKey, String(index));
    };
    const findParent = (node) => node.draggable ? node : node.closest('div[draggable]');
    const ondrop = (evt) => {
        evt.preventDefault();
        const parent = findParent(evt.target);
        if (parent) {
            parent.className = parent.className.replace(` ${activeClass}`, '');
        }
        const dragIndex = parseInt(evt.dataTransfer.getData(dataKey));
        onDrop({
            dragIndex,
            dropIndex: index,
        });
    };
    const ondragenter = (evt) => {
        evt.preventDefault();
        const node = evt.target;
        const parent = findParent(node);
        if (parent) {
            parent.className += ` ${activeClass}`;
        }
    };
    const ondragleave = (evt) => {
        evt.preventDefault();
        const node = evt.target;
        const parent = findParent(node);
        if (parent) {
            parent.className = parent.className.replace(` ${activeClass}`, '');
        }
    };
    return (_jsx(Box, { onDragStart: ondragstart, onDrag: () => setDragging(true), onDragEnd: () => setDragging(false), onDrop: ondrop, onDragOver: (evt) => evt.preventDefault(), onDragEnter: ondragenter, onDragLeave: ondragleave, draggable: true, sx: {
            display: 'inline-flex',
            alignItems: 'center',
            position: 'relative',
            mr: 0.25,
            mb: 0.25,
            '&:hover': {
                cursor: 'grab',
            },
            [`&.${activeClass}`]: {
                '&::before': {
                    content: '""',
                    height: '80%',
                    borderLeft: '2px solid',
                    left: '-4px',
                    position: 'absolute',
                    cursor: 'grab',
                },
            },
        }, children: _jsx(Chip, { icon: _jsx(DragIndicatorOutlined, { sx: { height: 12, width: 12 } }), sx: {
                mr: 0.25,
                mb: 0.25,
                visibility: dragging ? 'hidden' : 'visible',
            }, label: item.label, deleteIcon: _jsx(Close, {}), onDelete: () => onDelete(item.id) }) }));
};
const DragableSelect = ({ data, field, setter, }) => {
    var _a;
    const [items, setItems] = useState(((_a = field[data.id]) === null || _a === void 0 ? void 0 : _a.map((key) => {
        var _a, _b, _c;
        return ({
            id: key,
            label: ((_c = (_b = (_a = data.customSelectOptions[field.custom_fields_id]) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b[key]) === null || _c === void 0 ? void 0 : _c.label) || key,
            type: 'item',
        });
    })) || []);
    const onDrop = ({ dragIndex, dropIndex }) => {
        const newList = [...items];
        const dragItem = newList.splice(dragIndex, 1);
        newList.splice(dropIndex, 0, ...dragItem);
        setItems(newList);
        setter(Object.assign(Object.assign({}, field), { [data.id]: newList.map((item) => item.id) }));
    };
    const handleSelectChange = (values) => {
        setItems(values);
        setter(Object.assign(Object.assign({}, field), { [data.id]: values.map((item) => item.id) }));
    };
    const handleDelete = (id) => {
        const newList = items.filter((item) => item.id !== id);
        setItems(newList);
        setter(Object.assign(Object.assign({}, field), { [data.id]: newList.map((item) => item.id) }));
    };
    const options = useMemo(() => {
        var _a;
        const fields = Object.entries(((_a = data.customSelectOptions[field.custom_fields_id]) === null || _a === void 0 ? void 0 : _a.fields) || {});
        const _options = fields === null || fields === void 0 ? void 0 : fields.map(([key, value]) => {
            const label = value.label || key;
            return { label, id: key, type: 'item' };
        });
        return _options;
    }, [data.customSelectOptions, field.custom_fields_id]);
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle2", children: data.label }), _jsx(FormControl, { children: _jsx(EnhancedSelect, { enableSearch: true, sx: { width: 'auto', minWidth: 150, maxWidth: 'fit-content' }, multiple: true, value: items, placeholder: "Select", onChange: (values) => {
                        handleSelectChange(values);
                    }, labelKey: "label", options: options }) }), Array.isArray(items) && items.length > 0 && (_jsx(Box, { sx: {
                    mt: 0.5,
                    mb: 1,
                    p: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    borderStyle: 'solid',
                    borderColor: 'silver',
                    borderWidth: 1,
                    borderRadius: 4,
                    width: '100%',
                    backgroundColor: '#2196f308',
                }, children: items === null || items === void 0 ? void 0 : items.map((item, index) => (_jsx(DraggableItem, { onDelete: (fieldId) => {
                        handleDelete(fieldId);
                    }, onDrop: onDrop, item: item, index: index }, item.id))) }))] }));
};
export default DragableSelect;
