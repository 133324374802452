var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Checkbox, FormControl, FormControlLabel, FormLabel, Stack, TextField, Tooltip, } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { BasicDialog } from '@/common';
import AdminCard from '@/common/AdminCard';
import AdminBox from '@/components/atoms/AdminBox';
import DataView from '@/components/DataView';
import MultiSelect from '@/components/molecules/MultiSelect';
import { DataProcessingTaskProgress } from '@/components/SettingsView/DataProcessingTaskProgress';
import { getProcessingLogDataDesc } from '@/dataDesc/processingLogDataDesc';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { DataSyncWorker } from '@/components/DataSyncWorker';
import useSnackbar from '@/contexts/useSnackbar';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
const deDupeData = (args) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield fetch(`${process.env.REACT_APP_API}/api/data_processing/grouping`, {
        method: 'POST',
        headers: yield API.getHeaders(),
        body: JSON.stringify(args),
    });
    const resJson = yield res.json();
    return resJson;
});
const calcReceivables = () => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield fetch(`${process.env.REACT_APP_API}/api/receivables-schedule/calculate`, {
        method: 'POST',
        headers: yield API.getHeaders(),
    });
    const resJson = yield res.json();
    return resJson;
});
const DataProcessing = () => {
    var _a, _b, _c, _d, _e, _f;
    const { selectedAccount } = useAccountStore();
    const [loading, setLoading] = useState({
        agentCommissionCalc: false,
        agentCommissionCalcClear: false,
        dataSync: false,
        group: false,
        match: false,
        receivablesCalc: false,
    });
    const [refresh, setRefresh] = useState(0);
    const [dedupeArgs, setDedupeArgs] = useState({
        policyDataPriorityField: 'created_at',
        isSync: false,
    });
    const [reconcileArgs, setReconcileArgs] = useState({
        calcReceivables: true,
        groupDedupe: true,
        flowId: null,
        forceFullRun: false,
        isSync: false,
        disableClearCommissionCalcs: false,
    });
    const [commissionCalcArgs, setCommissionCalcArgs] = useState({
        contactIds: [],
        dataSet: 'all',
        documentIds: [],
        endDate: null,
        id: '',
        payingEntity: '',
        policyId: '',
        startDate: null,
        useGroupedCommissions: true,
        isSync: false,
    });
    const [taskIds, setTaskIds] = useState([]);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const { data: accountSettings } = API.getBasicQuery(`accounts/settings`);
    const { data: _contacts } = API.getBasicQuery('contacts', 'is_dynamic_select=true');
    const contacts = (_a = _contacts === null || _contacts === void 0 ? void 0 : _contacts.data) !== null && _a !== void 0 ? _a : [];
    const { data: _payingEntities } = API.getBasicQuery('statement_data/carrier_name');
    const { data: _documents } = API.getBasicQuery('documents', 'is_dynamic_select=true');
    const eventListenerRef = useRef(null);
    useEffect(() => {
        return () => {
            // Cleanup function to remove the event listener when component unmounts
            if (eventListenerRef.current) {
                window.removeEventListener('onDone', eventListenerRef.current);
            }
        };
    }, []);
    const processReconciliation = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (options = {}) {
        const res = yield fetch(`${process.env.REACT_APP_API}/api/reconciliation/reconcile`, {
            method: 'POST',
            headers: yield API.getHeaders(),
            body: JSON.stringify(options),
        });
        const data = yield res.json();
        if (res.status === 200 && data.statusText) {
            throw new Error(data.message || data.statusText);
        }
        else if (res.status >= 400) {
            throw new Error(data.message || data.statusText);
        }
        if (data.taskId) {
            setTaskIds([...taskIds, data.taskId]);
        }
        console.log('processReconciliation: ', data);
        return data;
    });
    const payingEntities = (_b = _payingEntities === null || _payingEntities === void 0 ? void 0 : _payingEntities.values) !== null && _b !== void 0 ? _b : [];
    const documents = (_c = _documents === null || _documents === void 0 ? void 0 : _documents.data) !== null && _c !== void 0 ? _c : [];
    const viewSettings = (_d = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _d === void 0 ? void 0 : _d.settings_data_processing;
    const enableRunReconciliationButton = useMemo(() => {
        var _a;
        if (viewSettings) {
            let pageOptions;
            if (typeof viewSettings.page_options === 'string') {
                pageOptions = JSON.parse(viewSettings.page_options || '{}');
            }
            else {
                pageOptions = viewSettings.page_options;
            }
            return Array.isArray(pageOptions)
                ? (_a = pageOptions.find((option) => option.enable_run_reconciliation_button !== undefined)) === null || _a === void 0 ? void 0 : _a.enable_run_reconciliation_button
                : true;
        }
        else {
            return true;
        }
    }, [viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_options]);
    const { showSnackbar } = useSnackbar();
    const agentCommissionsPoster = API.getMutation('data_processing/commissions/agents', 'POST');
    const agentCommissionsDelete = API.getMutation('data_processing/commissions/agents', 'DELETE');
    const { data: accountData } = API.getBasicQuery('accounts');
    const { data: workerInfos } = API.getBasicQuery('data_processing/sync/workers', `accountId=${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId}`, ((_e = accountData === null || accountData === void 0 ? void 0 : accountData.data_processors) !== null && _e !== void 0 ? _e : []).includes('dataSync'));
    const processAgentCommissions = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield agentCommissionsPoster.mutateAsync(commissionCalcArgs);
        console.log('result: ', res);
        return res;
    });
    const clearAgentCommissions = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield agentCommissionsDelete.mutateAsync({});
        console.log('result: ', res);
        return res;
    });
    return (_jsxs(Box, { children: [_jsxs(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    mx: 2,
                }, children: [_jsxs(Box, { children: [_jsx(Box, { children: _jsx(FormControl, { children: _jsx(FormLabel, { id: "group-data", children: "Group / dedupe" }) }) }), _jsx(AdminCard, { showIcon: true, iconPosition: "right", children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: !dedupeArgs.isSync, onChange: (e) => setDedupeArgs(Object.assign(Object.assign({}, dedupeArgs), { isSync: !e.target.checked })) }), label: "Use workers" }) }), _jsx(LoadingButton, { loading: loading.group, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    setLoading(Object.assign(Object.assign({}, loading), { group: true }));
                                    const res = yield deDupeData(dedupeArgs);
                                    if (res.taskId) {
                                        setTaskIds([...taskIds, res.taskId]);
                                    }
                                    setLoading(Object.assign(Object.assign({}, loading), { group: false }));
                                    setRefresh(refresh + 1);
                                    if (res.success) {
                                        showSnackbar('Grouping and deduping task is created', 'success');
                                    }
                                    else if (res.statusText === 'ok') {
                                        showSnackbar('Grouping and deduping completed', 'success');
                                    }
                                    else {
                                        showSnackbar(`${res.message || 'Error grouping data'}`, 'error');
                                    }
                                }), variant: "contained", children: "Group data" })] }), _jsxs(Box, { children: [_jsxs(FormControl, { children: [_jsx(FormLabel, { children: "Reconciliation" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: reconcileArgs.groupDedupe, onChange: (e) => setReconcileArgs(Object.assign(Object.assign({}, reconcileArgs), { groupDedupe: e.target.checked })) }), label: "Group / dedupe" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: reconcileArgs.calcReceivables, onChange: (e) => {
                                                setReconcileArgs(Object.assign(Object.assign({}, reconcileArgs), { calcReceivables: e.target.checked }));
                                            } }), label: "Receivables calc" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: reconcileArgs.disableClearCommissionCalcs, onChange: (e) => setReconcileArgs(Object.assign(Object.assign({}, reconcileArgs), { disableClearCommissionCalcs: e.target.checked })) }), label: "Disable clear commission calcs" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: reconcileArgs.disableUpdatePaidApprovedContacts, onChange: (e) => setReconcileArgs(Object.assign(Object.assign({}, reconcileArgs), { disableUpdatePaidApprovedContacts: e.target.checked })) }), label: "Disable update paid/approved commissions' contacts" }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { onChange: (e) => {
                                                setReconcileArgs(Object.assign(Object.assign({}, reconcileArgs), { forceFullRun: e.target.checked }));
                                            } }), label: "Full run" }), _jsx(AdminCard, { showIcon: true, iconPosition: "right", children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: !reconcileArgs.isSync, onChange: (e) => setReconcileArgs(Object.assign(Object.assign({}, reconcileArgs), { isSync: !e.target.checked })) }), label: "Use workers" }) }), _jsx(Tooltip, { title: (accountData === null || accountData === void 0 ? void 0 : accountData.default_reconciler)
                                            ? ''
                                            : 'Reconciliation not configured', children: _jsx("span", { children: _jsx(LoadingButton, { loading: loading.match, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    setLoading(Object.assign(Object.assign({}, loading), { match: true }));
                                                    if (reconcileArgs.groupDedupe) {
                                                        const ret = yield deDupeData(Object.assign(Object.assign({}, dedupeArgs), { isSync: reconcileArgs.isSync }));
                                                        if (!reconcileArgs.isSync) {
                                                            setTaskIds([...taskIds, ret.taskId]);
                                                            yield new Promise((resolve) => {
                                                                const listener = (evt) => {
                                                                    var _a;
                                                                    if (((_a = evt === null || evt === void 0 ? void 0 : evt.detail) === null || _a === void 0 ? void 0 : _a.id) === ret.taskId) {
                                                                        resolve(true);
                                                                        window.removeEventListener('onDone', listener);
                                                                    }
                                                                };
                                                                window.addEventListener('onDone', listener, {
                                                                    once: true,
                                                                });
                                                            });
                                                        }
                                                    }
                                                    try {
                                                        yield processReconciliation(Object.assign(Object.assign({}, reconcileArgs), { deDupe: true, redo: true, testRun: false, flowId: accountData.default_reconciler }));
                                                    }
                                                    catch (error) {
                                                        showSnackbar(`${error.message}`, 'error');
                                                    }
                                                    if (reconcileArgs.calcReceivables) {
                                                        const res = yield calcReceivables();
                                                        if (res.success === true) {
                                                            showSnackbar('Receivables calculation completed', 'success');
                                                        }
                                                        else {
                                                            showSnackbar('Error calculating receivables', 'error', 12000);
                                                        }
                                                    }
                                                    setLoading(Object.assign(Object.assign({}, loading), { match: false }));
                                                    setRefresh(refresh + 1);
                                                }), variant: "contained", disabled: !(accountData === null || accountData === void 0 ? void 0 : accountData.default_reconciler) ||
                                                    !enableRunReconciliationButton, children: "Reconcile data" }) }) })] }), _jsx(Box, { sx: { mt: 2 }, children: _jsx(FormControl, { children: _jsx(FormLabel, { id: "group-data", children: "Receivables calc" }) }) }), _jsx(Box, { sx: { display: 'flex', flexDirection: 'column' }, children: _jsx(LoadingButton, { loading: loading.receivablesCalc, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                        try {
                                            setLoading(Object.assign(Object.assign({}, loading), { receivablesCalc: true }));
                                            const res = yield calcReceivables();
                                            if (res.success === true) {
                                                showSnackbar('Receivables calculation completed', 'success');
                                            }
                                            else {
                                                showSnackbar('Error calculating receivables', 'error');
                                            }
                                        }
                                        catch (e) {
                                            showSnackbar(e.message, 'error');
                                        }
                                        finally {
                                            setLoading(Object.assign(Object.assign({}, loading), { receivablesCalc: false }));
                                            setRefresh(refresh + 1);
                                        }
                                    }), variant: "contained", children: "Calculate" }) })] }), _jsxs(Box, { children: [_jsx(Box, { children: _jsxs(FormControl, { children: [_jsx(FormLabel, { id: "agent-commission-calc-settings", children: "Comp calc" }), _jsx(Box, { children: _jsx(BasicDateRangePicker, { range: {
                                                    startDate: commissionCalcArgs.startDate
                                                        ? dayjs.utc(commissionCalcArgs.startDate)
                                                        : null,
                                                    startDateLabel: 'Start date',
                                                    endDate: commissionCalcArgs.endDate
                                                        ? dayjs.utc(commissionCalcArgs.endDate)
                                                        : null,
                                                    endDateLabel: 'End date',
                                                }, onChange: ({ startDate, endDate }) => {
                                                    setCommissionCalcArgs(Object.assign(Object.assign({}, commissionCalcArgs), { startDate: startDate
                                                            ? dayjs.isDayjs(startDate)
                                                                ? startDate.toDate()
                                                                : new Date(startDate)
                                                            : null, endDate: endDate
                                                            ? dayjs.isDayjs(endDate)
                                                                ? endDate.toDate()
                                                                : new Date(endDate)
                                                            : null }));
                                                }, mt: 1 }) }), _jsxs(Box, { sx: { display: 'flex', gap: 1 }, children: [_jsx(MultiSelect, { label: "Paying entities", values: payingEntities, selectedValues: commissionCalcArgs.payingEntity.split(','), setSelectedValues: (values) => setCommissionCalcArgs(Object.assign(Object.assign({}, commissionCalcArgs), { payingEntity: values.join(',') })), sx: { width: 200, mt: 1.5 }, enableSearch: true, paginate: true }), _jsx(MultiSelect, { label: "Agents", values: contacts, valuer: (o) => o.str_id, formatter: (o) => Formatter.contact(o, {
                                                        incl_email: true,
                                                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                    }), selectedValues: commissionCalcArgs.contactIds, setSelectedValues: (values) => setCommissionCalcArgs(Object.assign(Object.assign({}, commissionCalcArgs), { 
                                                        // @ts-ignore
                                                        contactIds: values })), sx: { width: 200, mt: 1.5 }, enableSearch: true, paginate: true }), _jsx(MultiSelect, { label: "Documents", values: documents, valuer: (o) => o.str_id, formatter: (o) => {
                                                        return o
                                                            ? `${o === null || o === void 0 ? void 0 : o.filename} (${Formatter.date(o === null || o === void 0 ? void 0 : o.created_at, { format: 'YYYY/MM/DD hh:mmA' })})`
                                                            : '';
                                                    }, selectedValues: commissionCalcArgs.documentIds, setSelectedValues: (values) => setCommissionCalcArgs(Object.assign(Object.assign({}, commissionCalcArgs), { 
                                                        // @ts-ignore
                                                        documentIds: values })), sx: { width: 200, mt: 1.5 }, enableSearch: true, paginate: true })] }), _jsxs(Box, { sx: { display: 'flex', gap: 1 }, children: [_jsx(TextField, { label: "Policy ID", value: commissionCalcArgs.policyId, onChange: (e) => setCommissionCalcArgs(Object.assign(Object.assign({}, commissionCalcArgs), { policyId: e.target.value })), sx: {
                                                        mt: 1.5,
                                                        width: 200,
                                                        '.MuiInputBase-input': {
                                                            py: 0.75,
                                                            pl: 1.5,
                                                        },
                                                    }, InputLabelProps: {
                                                        sx: commissionCalcArgs.policyId ? {} : { top: -3 },
                                                    } }), _jsx(TextField, { label: "Individual record", value: commissionCalcArgs.id, onChange: (e) => setCommissionCalcArgs(Object.assign(Object.assign({}, commissionCalcArgs), { id: e.target.value })), sx: {
                                                        mt: 1.5,
                                                        width: 200,
                                                        '.MuiInputBase-input': {
                                                            py: 0.75,
                                                            pl: 1.5,
                                                        },
                                                    }, InputLabelProps: {
                                                        sx: commissionCalcArgs.id ? {} : { top: -3 },
                                                    } })] }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: commissionCalcArgs.useGroupedCommissions, onChange: (e) => setCommissionCalcArgs(Object.assign(Object.assign({}, commissionCalcArgs), { useGroupedCommissions: e.target.checked })) }), label: "Use grouped commissions" }), _jsx(AdminCard, { showIcon: true, iconPosition: "right", children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: !commissionCalcArgs.isSync, onChange: (e) => setCommissionCalcArgs(Object.assign(Object.assign({}, commissionCalcArgs), { isSync: !e.target.checked })) }), label: "Use workers" }) })] }) }), _jsxs(Box, { sx: { display: 'flex', flexDirection: 'column' }, children: [_jsx(LoadingButton, { loading: loading.agentCommissionCalc, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            try {
                                                setLoading(Object.assign(Object.assign({}, loading), { agentCommissionCalc: true }));
                                                const res = yield processAgentCommissions();
                                                if (res.statusText === 'ok' || res.success) {
                                                    showSnackbar('Agent commission calc completed', 'success');
                                                    if (res.taskId) {
                                                        setTaskIds([...taskIds, res.taskId]);
                                                    }
                                                }
                                                else {
                                                    showSnackbar(`${res.message || 'Error calculating agent commissions'} `, 'error');
                                                }
                                            }
                                            catch (e) {
                                                showSnackbar(e.message, 'error');
                                            }
                                            finally {
                                                setLoading(Object.assign(Object.assign({}, loading), { agentCommissionCalc: false }));
                                                setRefresh(refresh + 1);
                                            }
                                        }), variant: "contained", sx: { width: '220px' }, children: "Calculate" }), _jsx(LoadingButton, { loading: loading.agentCommissionCalcClear, onClick: () => setDeleteConfirm(true), variant: "outlined", sx: { width: '220px', mt: 1 }, children: "Clear ALL results" })] })] }), ((_f = accountData === null || accountData === void 0 ? void 0 : accountData.data_processors) !== null && _f !== void 0 ? _f : []).includes('dataSync') && (_jsx(Box, { children: workerInfos === null || workerInfos === void 0 ? void 0 : workerInfos.map((workerInfo, idx) => (_jsx(DataSyncWorker, { workerInfo: workerInfo, onSyncComplete: () => setRefresh(refresh + 1), onSyncStart: (taskId) => setTaskIds([...taskIds, taskId]) }, `${workerInfo.worker}-${idx}`))) })), _jsx(Stack, { spacing: 10, children: taskIds.map((id) => (_jsx(DataProcessingTaskProgress, { taskId: id, onFinish: () => {
                                dispatchEvent(new CustomEvent('onDone', { detail: { id } }));
                            }, onClose: () => {
                                setTaskIds(taskIds.filter((r) => r != id));
                            } }, id))) })] }), _jsx(BasicDialog, { title: "Delete comp calculation results?", open: deleteConfirm, onClose: (val) => __awaiter(void 0, void 0, void 0, function* () {
                    setDeleteConfirm(false);
                    if (val) {
                        setLoading(Object.assign(Object.assign({}, loading), { agentCommissionCalcClear: true }));
                        const res = yield clearAgentCommissions();
                        setLoading(Object.assign(Object.assign({}, loading), { agentCommissionCalcClear: false }));
                        setRefresh(refresh + 1);
                        if (res.statusText === 'ok') {
                            showSnackbar('Agent commission calc cleared', 'success');
                        }
                        else {
                            showSnackbar('Error clearing agent commissions', 'error');
                        }
                    }
                    else {
                        // Do nothing
                    }
                }), bodyComponent: _jsx(Alert, { severity: "warning", children: "Are you sure you want to delete all comp calculation results? Approved, Manual, and Paid entries will not be cleared." }) }), _jsx(AdminBox, { expandable: true, expandableDefault: false, title: "Activity logs", sx: { height: 800 }, children: _jsx(DataView, { dataDesc: getProcessingLogDataDesc(), viewOnly: true, hideExport: true, refresh: refresh, embed: true, sx: { width: '100%' }, enablePagination: true }) })] }));
};
export default DataProcessing;
