import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
const IMGPreview = ({ file }) => {
    const [fileContent, setFileContent] = useState('');
    useEffect(() => {
        if (file) {
            setFileContent(URL.createObjectURL(file));
        }
    }, [file]);
    return (_jsx("div", { className: "p-2 box-border flex items-center rounded-xl overflow-scroll", children: file && (_jsx("div", { style: { margin: 'auto' }, children: _jsx("img", { alt: "preview", src: fileContent, style: { maxWidth: '70vw', width: '100%' } }) })) }));
};
export default IMGPreview;
