import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, TextField, Typography, } from '@mui/material';
const BreakdownByMonth = ({ schedule, setSchedule }) => {
    var _a;
    const perMonth = ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'];
    return (_jsx(Box, { sx: { display: 'flex', mr: 1 }, children: ((_a = schedule.breakdown) !== null && _a !== void 0 ? _a : perMonth).map((month, i) => (_jsx(TextField, { label: `Mo ${i + 1}`, value: month, onChange: (e) => {
                const newSchedule = Object.assign({}, schedule);
                if (!newSchedule.breakdown) {
                    newSchedule.breakdown = perMonth;
                }
                newSchedule.breakdown[i] = e.target.value;
                setSchedule(newSchedule);
            }, sx: { minWidth: 70, mr: '-1px' }, InputProps: {
                sx: i === 0
                    ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 }
                    : i === 11
                        ? {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }
                        : { borderRadius: 0 },
            }, inputProps: {
                style: { paddingLeft: 8, paddingRight: 8, textAlign: 'center' },
            } }, `${schedule.year}-${i}`))) }));
};
const RateScheduleAnnual = ({ value: schedule, setValue }) => {
    return (_jsxs(Box, { sx: { mb: 2 }, children: [schedule === null || schedule === void 0 ? void 0 : schedule.map((scheduleYear, i) => (_jsxs(Box, { sx: { mb: 1, display: 'flex' }, children: [_jsx(TextField, { label: "Year", value: scheduleYear.year, onChange: (e) => {
                            const newSchedule = [...schedule];
                            newSchedule[i].year = e.target.value;
                            setValue(newSchedule);
                        }, sx: { mr: 1, minWidth: 56, width: 56 } }), _jsx(TextField, { label: "Rate", value: scheduleYear.rate, onChange: (e) => {
                            const newSchedule = [...schedule];
                            newSchedule[i].rate = e.target.value;
                            setValue(newSchedule);
                        }, sx: { mr: 1, minWidth: 72, width: 72 }, InputProps: {
                            endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                        } }), scheduleYear.rate > 0 && (_jsx(BreakdownByMonth, { schedule: scheduleYear, setSchedule: (newScheduleYear) => setValue(schedule.map((s, j) => (j === i ? newScheduleYear : s))) })), _jsx(TextField, { label: "Max comm", value: scheduleYear.max_commission, onChange: (e) => {
                            const newSchedule = [...schedule];
                            newSchedule[i].max_commission = e.target.value;
                            setValue(newSchedule);
                        }, sx: { mr: 1, minWidth: 88, width: 88 }, InputProps: {
                            startAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0, mr: 0.5 }, children: "$" })),
                        } }), _jsx(IconButton, { onClick: () => {
                            const newSchedule = [...schedule];
                            newSchedule.splice(i, 1);
                            setValue(newSchedule);
                        }, children: _jsx(RemoveCircleOutline, {}) })] }, i))), schedule.length > 0 && schedule.some((s) => (s === null || s === void 0 ? void 0 : s.rate) > 0) > 0 && (_jsx(Box, { sx: { ml: 24 }, children: _jsx(Typography, { variant: "caption", children: "Monthly values for each year must add up to 100. Fraction may be used (e.g. 100 / 12 for 8.33...%)." }) })), _jsx(Button, { onClick: () => setValue([
                    ...schedule,
                    {
                        year: '',
                        rate: '',
                        breakdown: [
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                            '0',
                        ],
                    },
                ]), children: "Add year" })] }));
};
export default RateScheduleAnnual;
