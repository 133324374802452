export var CustomerType;
(function (CustomerType) {
    CustomerType["individual"] = "individual";
    CustomerType["group"] = "group";
})(CustomerType || (CustomerType = {}));
export var CustomerGender;
(function (CustomerGender) {
    CustomerGender["male"] = "male";
    CustomerGender["female"] = "female";
})(CustomerGender || (CustomerGender = {}));
export const TRANSGLOBAL_ACCOUNTID = 'XN9U5UtGrD5aovyEwNlHj';
export const TRANSGLOBAL_SIGNIN_URL = 'https://hubs.transglobalus.com/_hcms/mem/login?redirect_url=https://hubs.transglobalus.com/fintary-sso-login';
