import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import API from '@/services/API';
import EditViewReport from '../molecules/EditViewReport';
import { useAccountStore } from '@/store';
import ReportHistory from './ReportHistory';
import ReportData from '../molecules/ReportData';
import SearchBox from '../molecules/SearchBox';
const SnapshotReport = () => {
    var _a, _b, _c;
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams({});
    const { selectedAccount } = useAccountStore();
    const [newName, setNewName] = useState('');
    const [newNotes, setNewNotes] = useState('');
    const [newAccess, setNewAccess] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [approver, setApprover] = useState('');
    const [status, setStatus] = useState('');
    const [open, setOpen] = useState(false);
    const [fetchAccountUsers, setFetchAccountUsers] = useState(false);
    const [openHistory, setOpenHistory] = useState(false);
    const initialGrouping = searchParams.get('grouping') || 'none';
    const [grouping, setGrouping] = useState(initialGrouping);
    const initialView = searchParams.get('view') || 'prod-view';
    const [view, setView] = useState(initialView);
    const [filteredData, setFilteredData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [showInfo, setShowInfo] = useState(false);
    const { data: accountUsers, isLoading: accountUsersLoading } = API.getBasicQuery(
    // @ts-ignore
    `users/get_account_users?accId=${selectedAccount.accountId}`, '', fetchAccountUsers);
    const { data: selectedSnapshotReport, isLoading, refetch, } = API.getBasicQuery(
    // @ts-ignore
    `saved_reports/reports?report_id=${id}&grouping=${grouping}&view=${view}`);
    const applySearch = (query) => {
        const lowerCaseQuery = query.toLowerCase();
        const filtered = originalData.filter((row) => {
            const rowString = Object.values(row)
                .map((value) => {
                if (typeof value === 'object' && value !== null) {
                    return JSON.stringify(value);
                }
                return value;
            })
                .join(' ')
                .toLowerCase();
            return rowString.includes(lowerCaseQuery);
        });
        setFilteredData(filtered);
    };
    useEffect(() => {
        var _a, _b;
        if ((selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data) && !isLoading) {
            setFilteredData((_a = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.data) === null || _a === void 0 ? void 0 : _a.data);
            setOriginalData((_b = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.data) === null || _b === void 0 ? void 0 : _b.data);
            setNewName(selectedSnapshotReport.name);
            setNewNotes(selectedSnapshotReport.notes);
            setNewAccess(selectedSnapshotReport.access);
            setUsersList(selectedSnapshotReport.users_white_list);
            setApprover(selectedSnapshotReport.reviewed_by);
            setStatus(selectedSnapshotReport.status);
        }
        setFetchAccountUsers(true);
    }, [selectedSnapshotReport, isLoading]);
    // On initial load, check if there is a search query in the URL after data is loaded
    useEffect(() => {
        var _a;
        const query = (_a = searchParams.get('q')) !== null && _a !== void 0 ? _a : '';
        if (query) {
            applySearch(query);
        }
    }, [searchParams, originalData]);
    const handleGroupingChange = (event, newGrouping) => {
        if (newGrouping !== null) {
            setGrouping(newGrouping);
            const params = new URLSearchParams(searchParams);
            params.set('grouping', newGrouping);
            setSearchParams(params);
            refetch();
        }
    };
    const handleViewChange = (event, newView) => {
        const params = new URLSearchParams(searchParams);
        params.set('view', newView);
        setSearchParams(params);
        setView(newView);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                    overflowY: 'scroll',
                    width: '100%',
                }, children: [_jsxs(Box, { sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            mx: 2,
                            mt: 2,
                            mb: 2,
                        }, children: [_jsx(Box, { sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }, children: _jsxs(Box, { sx: {
                                        display: 'flex',
                                        alignItems: 'center',
                                    }, children: [_jsx(Typography, { variant: "h5", children: newName }), _jsx(IconButton, { onClick: (e) => {
                                                e.stopPropagation();
                                                setOpen(true);
                                            }, color: "primary", children: _jsx(Edit, {}) })] }) }), _jsxs(Box, { sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    mt: 2,
                                }, children: [_jsxs(Box, { sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                        }, children: [_jsxs(Typography, { variant: "h6", sx: { fontWeight: 400, textTransform: 'capitalize' }, children: [selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.reportPage, " data"] }), _jsx(Tooltip, { title: showInfo ? 'Hide Details' : 'View Details', children: _jsx(IconButton, { onClick: () => setShowInfo(!showInfo), color: "primary", children: showInfo ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {}) }) })] }), _jsx(SearchBox, { id: "snapshot_reports" })] }), showInfo && (_jsx(Box, { sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mt: 2,
                                }, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 6, children: [_jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        mr: 1,
                                                    }, children: [_jsx(Typography, { sx: { fontWeight: 500, mr: 0.5 }, children: "Approver:" }), _jsx(Typography, { children: (_a = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.users_reports_reviewed_byTousers) === null || _a === void 0 ? void 0 : _a.email })] }), _jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        mr: 1,
                                                    }, children: [_jsx(Typography, { sx: { fontWeight: 500, mr: 0.5 }, children: "Status:" }), _jsx(Typography, { children: status })] }), _jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        mr: 1,
                                                    }, children: [_jsx(Typography, { sx: { fontWeight: 500, mr: 0.5 }, children: "Notes:" }), _jsx(Typography, { children: newNotes })] }), _jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        mr: 1,
                                                    }, children: [_jsx(Typography, { sx: { fontWeight: 500, mr: 0.5 }, children: "Agent payout status:" }), _jsx(Typography, { children: (_b = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.data) === null || _b === void 0 ? void 0 : _b.contactPayableStatus })] }), _jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        mr: 1,
                                                    }, children: [_jsx(Typography, { sx: { fontWeight: 500, mr: 0.5 }, children: "Agent bank info:" }), _jsx(Typography, { children: (_c = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.data) === null || _c === void 0 ? void 0 : _c.contactBankInfo })] })] }), _jsxs(Grid, { item: true, xs: 6, children: [_jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        mb: 1,
                                                    }, children: [_jsx(Typography, { sx: { fontWeight: 500, mr: 0.5 }, children: "Created at:" }), _jsx(Typography, { children: new Date(selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.created_at).toLocaleString() })] }), _jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        mb: 1,
                                                    }, children: [_jsx(Typography, { sx: { fontWeight: 500, mr: 0.5 }, children: "Access:" }), _jsx(Typography, { children: newAccess })] }), accountUsers && !accountUsersLoading && (_jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }, children: [_jsx(Typography, { sx: { fontWeight: 500, mr: 0.5 }, children: "Users:" }), _jsx(Box, { children: usersList.length > 0
                                                                ? usersList.map((user) => {
                                                                    const userObj = accountUsers === null || accountUsers === void 0 ? void 0 : accountUsers.find((u) => u.str_id === user);
                                                                    return (_jsx(Typography, { children: userObj === null || userObj === void 0 ? void 0 : userObj.email }, userObj === null || userObj === void 0 ? void 0 : userObj.str_id));
                                                                })
                                                                : null })] }))] })] }) }))] }), _jsx(ReportData, { isLoading: isLoading, selectedSnapshotReport: selectedSnapshotReport, filteredData: filteredData, setOpenHistory: setOpenHistory, handleGroupingChange: handleGroupingChange, grouping: grouping, view: view, handleViewChange: handleViewChange, refetch: refetch })] }), selectedSnapshotReport ? (_jsxs(_Fragment, { children: [_jsx(EditViewReport, { open: open, setOpen: setOpen, type: "Report", name: selectedSnapshotReport.name, notes: selectedSnapshotReport.notes, access: selectedSnapshotReport.access, usersWhiteList: selectedSnapshotReport.users_white_list, approver: selectedSnapshotReport.reviewed_by, status: selectedSnapshotReport.status, created_by: selectedSnapshotReport.created_by, allData: selectedSnapshotReport, updateName: setNewName, updateNotes: setNewNotes, updateAccess: setNewAccess, updateUsersList: setUsersList, updateApprover: setApprover, updateStatus: setStatus, isOwner: selectedSnapshotReport.isOwner ? true : false, id: selectedSnapshotReport.id }), _jsx(ReportHistory, { open: openHistory, setOpen: setOpenHistory, reportId: selectedSnapshotReport.str_id })] })) : null] }));
};
export default SnapshotReport;
