var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _Statements_mode, _Statements_strings;
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Launch, VerticalSplit } from '@mui/icons-material';
import { Box, Chip, IconButton, Skeleton, Tooltip, Typography, } from '@mui/material';
import copy from 'copy-to-clipboard';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { getFilenameFromPath } from 'common/helpers';
import { AgentCommissionsStatusesLabels } from 'common/globalTypes';
import AgentCommissionsEdit from '@/components/CommissionsDataView/AgentCommissionsEdit';
import CommissionCalcLog from '@/components/molecules/CommissionCalcLog';
import { ComparisonContext } from '@/contexts/ReconciliationConfirmProvider';
import states from '@/data/states.json';
import API from '@/services/API';
import DataTransformation from '@/services/DataTransformation';
import Formatter from '@/services/Formatter';
import UILabels from '@/services/UILabels';
import { FieldTypes, Roles } from '@/types';
import PayoutStatusEdit from '@/components/CommissionsDataView/PayoutStatusEdit';
const Normalizer = DataTransformation;
const policyDataIfEmpty = (field, formatter = (s) => s) => (val, row) => {
    var _a;
    const policyVal = (_a = row === null || row === void 0 ? void 0 : row.report) === null || _a === void 0 ? void 0 : _a[field];
    return val
        ? formatter(val)
        : policyVal && (_jsx(Tooltip, { title: "Sourced from policy data", children: _jsxs("span", { children: [formatter(policyVal), "*"] }) }));
};
class Statements {
    constructor(mode, role, userRole, options) {
        _Statements_mode.set(this, 'default');
        _Statements_strings.set(this, new UILabels(__classPrivateFieldGet(this, _Statements_mode, "f")));
        this.label = 'Statements data';
        this.labelSimple = 'Statements data';
        this.table = 'statement_data';
        this.copyable = true;
        this.filters = {};
        this.fields = {};
        this.actions = [];
        this.outstandingFieldsInMobileView = [];
        this.dateFilters = [];
        this.fieldsCollapsed = [];
        this.options = {};
        this.getDataInfo = (tableData, topN = 8) => {
            const rowData = [];
            const ranges = [];
            let curRange = {
                start: 0,
                end: 999,
            };
            const filteredTableData = tableData.filter((row) => Array.isArray(row));
            filteredTableData.forEach((row, i) => {
                let matches = 0;
                let filled = 0;
                const dateStyleRegex = /(?:\d{1,2})(?:\/|-)(?:\d{1,2})(?:\/|-)(?:\d{1,4})/;
                const hasDate = dateStyleRegex.test(row.join(' '));
                const hasCurrencyValue = row.filter((cell) => cell === null || cell === void 0 ? void 0 : cell.toString().match(/\$\d{1,}/)).length;
                const hasTotalTitle = row.toString().toLowerCase().includes('total:');
                row.forEach((cell) => {
                    var _a;
                    if (cell || cell === 0) {
                        filled += 1;
                    }
                    if (this.fieldsCollapsed.includes((_a = cell === null || cell === void 0 ? void 0 : cell.toString()) === null || _a === void 0 ? void 0 : _a.toLowerCase())) {
                        matches += 1;
                    }
                });
                if (filled === 0 && curRange !== null) {
                    curRange.end = i - 1;
                    ranges.push(curRange);
                    curRange = null;
                }
                else if (filled > 0 && curRange === null) {
                    curRange = { start: i, end: 999 };
                }
                if (!hasDate && !hasCurrencyValue && !hasTotalTitle) {
                    rowData.push({ index: i, matches, filled, length: row.length });
                }
            });
            if (curRange !== null) {
                ranges.push(Object.assign(Object.assign({}, curRange), { end: filteredTableData.length - 1 }));
            }
            let rowDataMatches;
            // No matches - use top n rows
            if (rowData.filter((row) => row.matches > 0).length === 0) {
                rowDataMatches = rowData.splice(0, Math.min(topN, rowData.length));
            }
            else {
                rowDataMatches = rowData
                    .sort((a, b) => b.matches - a.matches)
                    .filter((row) => row.matches > 0)
                    .splice(0, Math.min(topN, rowData.filter((row) => row.matches > 0).length, rowData.length));
            }
            const rangeData = rowDataMatches.map((row) => ({
                index: row.index,
                count: row.matches,
                fields: filteredTableData[row.index],
                data: filteredTableData.filter((dataRow, i) => {
                    var _a, _b;
                    return i >
                        ((_a = ranges.filter((a) => a.start <= row.index)[ranges.filter((a) => a.start <= row.index).length - 1]) === null || _a === void 0 ? void 0 : _a.start) &&
                        i <=
                            ((_b = ranges.filter((a) => a.start <= row.index)[ranges.filter((a) => a.start <= row.index).length - 1]) === null || _b === void 0 ? void 0 : _b.end) &&
                        i > row.index;
                }),
            }));
            return { rowData, ranges, rangeData };
        };
        __classPrivateFieldSet(this, _Statements_mode, mode !== null && mode !== void 0 ? mode : __classPrivateFieldGet(this, _Statements_mode, "f"), "f");
        __classPrivateFieldSet(this, _Statements_strings, new UILabels(mode), "f");
        this.options = options;
        this.label = __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'title');
        this.labelSimple = __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'titleSimple');
        this.copyable = true;
        this.filters = {
            agent_name: {
                label: 'Agent name',
                useEnhancedSelect: true,
            },
            carrier_name: {
                label: 'Paying entity',
                useEnhancedSelect: true,
            },
            compensation_type: {
                label: 'Compensation type',
                useEnhancedSelect: true,
            },
            contacts: {
                label: 'Agents',
                useEnhancedSelect: true,
            },
            tags: {
                label: 'Tags',
                useEnhancedSelect: true,
            },
            document_id: {
                label: 'Document',
                useEnhancedSelect: true,
                listContainerSx: {
                    width: 400,
                },
            },
            payment_status: {
                label: 'Payment status',
                useEnhancedSelect: true,
            },
            product_name: {
                label: 'Product name',
                useEnhancedSelect: true,
            },
            product_type: {
                label: 'Product type',
                useEnhancedSelect: true,
            },
            status: {
                label: 'Status',
                useEnhancedSelect: true,
            },
            writing_carrier_name: {
                label: 'Carrier/MGA',
                useEnhancedSelect: true,
            },
            premium_type: {
                label: 'Premium type',
                useEnhancedSelect: true,
            },
            account_type: {
                label: 'Account type',
                useEnhancedSelect: true,
            },
            agent_commissions_status: {
                label: 'Payout status',
                useEnhancedSelect: true,
            },
            agent_commissions_status2: {
                label: 'Payout status*',
                useEnhancedSelect: true,
            },
            report_data_id: {
                label: 'Reconciliation',
                useEnhancedSelect: true,
            },
            // Reconciliation_status: {
            //   label: 'Reconciliation status',
            // },
        };
        this.fields = {
            policy_id: {
                // String
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'transactionId'),
                matches: [
                    'policy id',
                    'policy no',
                    'policy no.',
                    'policy number',
                    'policy #',
                    'policy',
                    'contract #',
                    'policy_number',
                    'policyid',
                ],
                reconciler: true,
                enabled: true,
                normalizer: (s) => s === null || s === void 0 ? void 0 : s.toString(),
                subPolicyDataIfEmpty: true,
                tableFormatter: policyDataIfEmpty('policy_id', (val) => { var _a; return Formatter.policyNumber(val, { account_id: (_a = this.options) === null || _a === void 0 ? void 0 : _a.account_id }); }),
                copyable: true,
            },
            // TotalAmount: {
            //   label: 'Total amount',
            //   matches: ['total', 'total amount', 'total amt', 'amount'],
            //   enabled: true,
            //   global: true,
            //   formatter: (s) => (s?.toString()?.startsWith('$') ? s : `$${s}`),
            // },
            commission_amount: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'amount'),
                matches: [
                    'commission',
                    'commission amount',
                    'gross commission',
                    'gross comm earned',
                    'commission earned',
                    'commission due',
                    'amount',
                    'comm amount',
                    'comm amt',
                    'commissionamount',
                ],
                enabled: true,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: FieldTypes.CURRENCY,
            },
            commission_paid_amount: {
                label: 'Commissions paid',
                matches: ['commissions paid out', 'agent commission'],
                enabled: true,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: FieldTypes.CURRENCY,
            },
            customer_name: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'customerName'),
                matches: [
                    'customer name',
                    'customer',
                    'insuree',
                    'insured',
                    'client',
                    'client name',
                    'insured name',
                    'policy holder',
                    'policy holder name',
                    'subscriber',
                    'name of insured',
                    'consumer name contract #',
                    'named insured',
                    'customername',
                    'policyholder name',
                    'individual name',
                ],
                reconciler: true,
                enabled: true,
            },
            invoice_date: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'invoiceDate'),
                matches: ['invoice date', 'invoice received', 'invoiceDate'],
                enabled: true,
                global: true,
                type: FieldTypes.DATE,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
            },
            payment_date: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'paymentDate'),
                matches: [
                    'payment date',
                    'payment received',
                    'statement date',
                    'month billed',
                    'paymentdate',
                ],
                enabled: true,
                global: true,
                type: FieldTypes.DATE,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
            },
            processing_date: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'processingDate'),
                matches: ['processing date', 'processingDate'],
                enabled: true,
                global: true,
                type: FieldTypes.DATE,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
            },
            agent_name: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'repName'),
                matches: [
                    'assigned agent',
                    'agt',
                    'writing agent',
                    'writing agt',
                    'agent name',
                    'agt name',
                    'assigned_agent',
                    'agent',
                    'producer name',
                ],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                global: true,
            },
            statement_number: {
                // New, renewal, cancellation, rewrite, fees
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'invoiceNumber'),
                matches: [
                    'statement number',
                    'commission statement number',
                    'statementnumber',
                ],
                enabled: true,
            },
            transaction_type: {
                // New, renewal, cancellation, rewrite, fees
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'transactionType'),
                matches: [
                    'transaction type',
                    'commission type',
                    'policy type',
                    'transactiontype',
                ],
                subPolicyDataIfEmpty: true,
                formatter: (val, row) => {
                    var _a, _b;
                    return val
                        ? val
                        : ((_a = row === null || row === void 0 ? void 0 : row.report) === null || _a === void 0 ? void 0 : _a.transaction_type) && (_jsx(Tooltip, { title: "Sourced from policy data", children: _jsxs("span", { children: [(_b = row === null || row === void 0 ? void 0 : row.report) === null || _b === void 0 ? void 0 : _b.transaction_type, "*"] }) }));
                },
                enabled: true,
                tableFormatter: policyDataIfEmpty('transaction_type'),
            },
            writing_carrier_name: {
                // String
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'entity'),
                matches: ['carrier name', 'carrier', 'writingcarriername'],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                reconciler: true,
                global: true,
                type: FieldTypes.DYNAMIC_SELECT,
                table: 'companies',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val &&
                        Array.isArray(collectionVals) &&
                        collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((company) => company.company_name === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? (_jsx(Chip, { label: `${datum.company_name}`, clickable: true, component: "a", href: `/companies?q=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val;
                },
                optionFormatter: (option) => option.company_name,
                optionValuer: (option) => option.company_name,
            },
            premium_type: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'premiumType'),
                type: FieldTypes.SELECT,
                matches: [],
                options: ['policy', 'split'],
                reconciler: true,
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                global: true,
            },
            carrier_name: {
                // String
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'payingEntity'),
                matches: ['carrier name', 'carrier', 'carriername'],
                enabled: true,
                global: true,
                type: FieldTypes.DYNAMIC_SELECT,
                table: 'companies',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val &&
                        Array.isArray(collectionVals) &&
                        collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((company) => company.company_name === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? (_jsx(Chip, { label: `${datum.company_name}`, clickable: true, component: "a", href: `/companies?q=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val;
                },
                optionFormatter: (option) => option.company_name,
                optionValuer: (option) => option.company_name,
            },
            agent_id: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'repId'),
                matches: [
                    'agent id',
                    'agt id',
                    'agent no',
                    'agt no',
                    'writing agent #',
                    'writing agent no',
                    'writing agent id',
                    'writing agt #',
                    'writing agt no',
                    'writing agt id',
                    'agentid',
                ],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
            },
            premium_amount: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'annualizedPremium'),
                description: 'Annualized premium amount as recorded in commission statements',
                matches: [
                    'premium amount',
                    'premium amt',
                    'premium paid',
                    'premium',
                    'premium - annualized',
                    'annualized_premium',
                    'annualized_premium2',
                    'premiumamount',
                    'policy premium',
                    'Paid Prem',
                ],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: FieldTypes.CURRENCY,
            },
            expected_result: {
                label: 'Expected result',
                enabled: true,
                readOnly: true,
            },
            commission_rate: {
                // Number
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'rate'),
                matches: [
                    'commission rate',
                    'commission pct',
                    'commission %',
                    'commission perentage',
                    'comm %',
                    'commissionrate',
                ],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                formatter: Normalizer.formatPercentage,
                normalizer: Normalizer.normalizePercentage,
                type: FieldTypes.PERCENTAGE,
            },
            carrier_rate: {
                label: 'Carrier rate',
                matches: [],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                formatter: Normalizer.formatPercentage,
                normalizer: Normalizer.normalizePercentage,
                type: FieldTypes.PERCENTAGE,
            },
            // Rows - Optional
            effective_date: {
                // Date
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'startDate'),
                matches: [
                    'policy effective date',
                    'effective date',
                    'effective',
                    'policy effective',
                    'in force date',
                    'eff date',
                    'effective_date',
                    'effective_date2',
                    'effectivedate',
                ],
                enabled: true,
                type: FieldTypes.DATE,
                tableFormatter: policyDataIfEmpty('effective_date', Normalizer.formatDate),
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
            },
            product_type: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'productType'),
                matches: ['product type', 'product line', 'producttype'],
                reconciler: true,
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                global: true,
                tableFormatter: policyDataIfEmpty('product_type'),
            },
            product_sub_type: {
                label: 'Product sub type',
                matches: [],
                reconciler: true,
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                tableFormatter: policyDataIfEmpty('product_sub_type'),
                global: true,
            },
            product_name: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'productName'),
                matches: ['product name', 'product', 'policy name'],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                subPolicyDataIfEmpty: true,
                tableFormatter: policyDataIfEmpty('product_name'),
            },
            product_option_name: {
                label: 'Product option name',
                matches: ['option', 'product option'],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                subPolicyDataIfEmpty: true,
                tableFormatter: (val, row) => {
                    var _a, _b;
                    return val
                        ? val
                        : ((_a = row === null || row === void 0 ? void 0 : row.report) === null || _a === void 0 ? void 0 : _a.product_option_name) && (_jsx(Tooltip, { title: "Sourced from policy data", children: _jsxs("span", { children: [(_b = row.report) === null || _b === void 0 ? void 0 : _b.product_option_name, "*"] }) }));
                },
            },
            fees: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'fees'),
                matches: ['fees'],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: FieldTypes.CURRENCY,
            },
            group_id: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'groupId'),
                matches: [
                    'group id',
                    'group number',
                    'group no',
                    'grp #',
                    'grp number',
                    'groupid',
                ],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
            },
            internal_id: {
                label: 'Internal Id',
                enabled: true,
                subPolicyDataIfEmpty: true,
                tableFormatter: policyDataIfEmpty('internal_id'),
            },
            period_date: {
                label: __classPrivateFieldGet(this, _Statements_strings, "f").getLabel('cash', 'periodDate'),
                matches: ['period', 'coverage period', 'perioddate', 'due date'],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
                type: FieldTypes.DATE,
                global: true,
                formatter: Normalizer.formatDate,
                normalizer: Normalizer.normalizeDate,
            },
            status: {
                label: 'Status',
                enabled: true,
            },
            performance_bonus: {
                label: 'Performance bonus',
                matches: ['performance bonus', 'performancebonus'],
                enabled: false,
            },
            override: { label: 'Override', matches: ['override'], enabled: false },
            payee: {
                label: 'Payee',
                matches: ['payee', 'payee name'],
                enabled: false,
            },
            payee_id: {
                label: 'Payee Id',
                matches: ['payee id', 'payee no', 'payeeid'],
                enabled: false,
            },
            agent_level: {
                label: 'Agent level',
                matches: ['agent level', 'writing agent level', 'agentlevel'],
                enabled: false,
            },
            agency: { label: 'Agency', matches: ['agency'], enabled: false }, // String
            policy_issue_date: {
                label: 'Policy issue date',
                matches: ['policy issue date', 'issue date', 'policyissuedate'],
                type: FieldTypes.DATE,
                enabled: false,
            },
            policy_amount: {
                label: 'Policy amount',
                matches: [
                    'policy amount',
                    'original face amount',
                    'face amount',
                    'policyamount',
                ],
                enabled: false,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: FieldTypes.CURRENCY,
            },
            policy_term: {
                label: 'Policy term',
                matches: ['policy term', 'policyterm'],
                enabled: false,
            },
            premium_received: {
                // Number
                label: 'Premium received',
                matches: ['premium received', 'premiumreceived'],
                enabled: false,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: FieldTypes.CURRENCY,
            },
            compensation_type: {
                label: 'Compensation type',
                matches: ['compensation type', 'compensationtype'],
                enabled: __classPrivateFieldGet(this, _Statements_mode, "f") === 'insurance',
            },
            income_class: {
                label: 'Income class',
                matches: ['income class', 'tax form', 'incomeclass'],
                enabled: false,
            },
            billing_frequency: {
                label: 'Billing frequency',
                matches: ['billing frequency', 'billingfrequency'],
                enabled: false,
            },
            premium_transaction: {
                label: 'Premium transaction',
                matches: ['premium transaction', 'premiumtransaction'],
                enabled: false,
            },
            commissionable_premium_amount: {
                label: 'Target premium',
                matches: ['target premium', 'commissionable premium'],
                enabled: true,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: FieldTypes.CURRENCY,
            },
            account_type: {
                label: 'Account type',
                enabled: true,
                matches: ['account type', 'accounttype'],
            },
            issue_age: {
                label: 'Issue age',
                enabled: true,
                normalizer: Normalizer.normalizeInt,
                matches: ['issue age'],
                type: FieldTypes.INTEGER,
                subPolicyDataIfEmpty: true,
                tableFormatter: policyDataIfEmpty('issue_age'),
            },
            customer_paid_premium_amount: {
                label: 'Customer Paid Premium Amount',
                enabled: true,
                matches: ['Basis', 'customer paid premium amount'],
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
            },
            bill_mode: {
                label: 'Bill mode',
                enabled: true,
                matches: ['bill mode'],
            },
            geo_state: {
                label: 'State',
                type: FieldTypes.SELECT,
                options: states,
                matches: [],
                enabled: true,
            },
            split_percentage: {
                label: 'Split percentage',
                enabled: true,
                matches: ['split percentage'],
                tableFormatter: (val, row) => {
                    var _a, _b, _c, _d, _e, _f;
                    return val ? (Normalizer.formatPercentage(val)) : ((_a = row.contacts) === null || _a === void 0 ? void 0 : _a.length) === 1 &&
                        ((_c = (_b = row.report) === null || _b === void 0 ? void 0 : _b.contacts_split) === null || _c === void 0 ? void 0 : _c[row.contacts[0]]) ? (_jsx(Tooltip, { title: "Sourced from policy data", children: _jsxs("span", { children: [Normalizer.formatPercentage(((_d = row.contacts) === null || _d === void 0 ? void 0 : _d.length) === 1 &&
                                    ((_f = (_e = row.report) === null || _e === void 0 ? void 0 : _e.contacts_split) === null || _f === void 0 ? void 0 : _f[row.contacts[0]])), "*"] }) })) : ('');
                },
                formatter: Normalizer.formatPercentage,
                normalizer: Normalizer.normalizePercentage,
                type: FieldTypes.PERCENTAGE,
            },
            group_name: {
                label: 'Group Name',
                matches: ['group name'],
                required: false,
                enabled: true,
                subPolicyDataIfEmpty: true,
                tableFormatter: policyDataIfEmpty('group_name'),
            },
            payment_mode: {
                label: 'Payment Mode',
                matches: ['payment mode'],
                required: false,
                enabled: true,
            },
            aggregation_id: {
                label: 'Aggregation Id',
                matches: [],
                enabled: true,
                subPolicyDataIfEmpty: true,
                tableFormatter: (val, row) => {
                    var _a, _b, _c;
                    return val
                        ? val
                        : ((_a = row === null || row === void 0 ? void 0 : row.report) === null || _a === void 0 ? void 0 : _a.policy_id) && ((_b = row.report) === null || _b === void 0 ? void 0 : _b.aggregation_id) && (_jsx(Tooltip, { title: "Sourced from policy data", children: _jsxs("span", { children: [(_c = row.report) === null || _c === void 0 ? void 0 : _c.aggregation_id, "*"] }) }));
                },
                copyable: true,
            },
            member_count: {
                label: 'Member count',
                matches: ['member count'],
                enabled: true,
                type: FieldTypes.INTEGER,
                normalizer: Normalizer.normalizeInt,
            },
            commission_basis: {
                label: 'Commission basis',
                matches: ['commission basis'],
                enabled: true,
            },
            standardized_customer_name: {
                label: 'Standardized customer name',
                matches: ['standardized customer name'],
                enabled: true,
            },
            contacts: {
                label: 'Agents',
                enabled: true,
                type: FieldTypes.DYNAMIC_SELECT,
                multiple: true,
                table: 'contacts',
                formatter: (val, collectionVals = []) => {
                    var _a, _b;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.str_id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? (_jsx(Chip, { label: Formatter.contact(datum, {
                                account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                            }), clickable: true, component: "a", href: `/agents/list?id=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val; // Not formatting when collectionVals is not available
                },
                optionFormatter: (o) => { var _a; return Formatter.contact(o, { account_id: (_a = this.options) === null || _a === void 0 ? void 0 : _a.account_id }); },
                optionValuer: (option) => option.str_id,
            },
            agent_commissions: {
                label: 'Agent commissions',
                enabled: true,
                table: 'contacts',
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, collectionVals = []) => {
                    return (_jsx(AgentCommissionsEdit, { data: row, setter: setter, field: field, dynamicSelects: collectionVals }, "agent_commissions"));
                },
                tableFormatter: (val, row, collectionVals = []) => {
                    if (!val || !Array.isArray(collectionVals))
                        return '';
                    return (_jsx(Box, { children: Object.entries(val)
                            .filter(([k, v]) => k !== 'total')
                            .map(([k, v]) => {
                            var _a, _b;
                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => (e === null || e === void 0 ? void 0 : e.str_id) === k)) !== null && _a !== void 0 ? _a : { id: undefined };
                            return contact.id ? (_jsx(Chip, { label: `${Formatter.contact(contact, {
                                    account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                                })}: ${Formatter.currency(v)}`, sx: { m: 0.1 } }, contact === null || contact === void 0 ? void 0 : contact.id)) : (_jsx(Skeleton, {}, k));
                        }) }));
                },
                readOnly: true,
            },
            agent_commissions_v2: {
                label: 'Agent commissions V2',
                enabled: true,
                table: 'contacts',
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, collectionVals = []) => {
                    return (_jsx(AgentCommissionsEdit, { data: row, setter: setter, field: field, dynamicSelects: collectionVals }, "agent_commissions_v2"));
                },
                tableFormatter: (val, row, collectionVals = []) => {
                    if (!val || !Array.isArray(collectionVals))
                        return '';
                    return (_jsx(Box, { children: Object.entries(val)
                            .filter(([k, v]) => k !== 'total')
                            .map(([k, v]) => {
                            var _a, _b;
                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => (e === null || e === void 0 ? void 0 : e.str_id) === k)) !== null && _a !== void 0 ? _a : { id: undefined };
                            return contact.id ? (_jsx(Chip, { label: `${Formatter.contact(contact, {
                                    account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                                })}: ${Formatter.currency(v)}`, sx: { m: 0.1 } }, contact === null || contact === void 0 ? void 0 : contact.id)) : (_jsx(Skeleton, {}, k));
                        }) }));
                },
                readOnly: true,
            },
            agent_commissions_log: {
                label: 'Agent commissions log',
                enabled: true,
                table: 'contacts',
                formatter: (val, collectionVals = []) => {
                    if (!val || !Array.isArray(collectionVals))
                        return '';
                    return (_jsx(Box, { children: Object.entries(val)
                            .filter(([k, v]) => k !== 'total')
                            .map(([k, v]) => {
                            var _a, _b;
                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {};
                            // After grouping if is not an array means that grouped data is not the same so we render ***
                            if (!Array.isArray(v)) {
                                return _jsx(Box, { children: "***" }, k);
                            }
                            return (_jsxs(Box, { children: [Formatter.contact(contact, {
                                        account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                                    }), _jsx("br", {}), v.map((e, i) => (_jsx(CommissionCalcLog, { commissionProfile: e }, i)))] }, k));
                        }) }));
                },
                readOnly: true,
                // Formatter: (val) => (val ? JSON.stringify(val) : ''),
            },
            agent_commissions_status: {
                label: 'Payout status',
                enabled: true,
                type: FieldTypes.SELECT,
                options: Object.values(AgentCommissionsStatusesLabels),
            },
            agent_commissions_status2: {
                label: 'Payout status*',
                enabled: true,
                type: FieldTypes.CUSTOM,
                table: 'contacts',
                render: (field, row, setter, collectionVals = []) => {
                    return (_jsx(PayoutStatusEdit, { data: row, setter: setter, field: field, dynamicSelects: collectionVals }, "agent_commissions_status2"));
                },
                tableFormatter: (val, row, collectionVals = []) => {
                    if (!val || !Array.isArray(collectionVals))
                        return '';
                    return (_jsx(Box, { children: Object.entries(val).map(([k, v]) => {
                            var _a, _b;
                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => (e === null || e === void 0 ? void 0 : e.str_id) === k)) !== null && _a !== void 0 ? _a : { id: undefined };
                            return contact.id ? (_jsx(Chip, { label: `${Formatter.contact(contact, {
                                    account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                                })}: ${v}`, sx: { m: 0.1 } }, contact === null || contact === void 0 ? void 0 : contact.id)) : (_jsx(Skeleton, {}, k));
                        }) }));
                },
            },
            notes: {
                label: 'Notes',
                enabled: true,
                matches: ['RevCd', 'notes'],
                subPolicyDataIfEmpty: true,
                tableFormatter: policyDataIfEmpty('notes'),
            },
            tags: {
                label: 'Tags',
                enabled: true,
                normalizer: (val) => {
                    var _a;
                    if (Array.isArray(val) && val.length === 0)
                        return [];
                    if (typeof val === 'string')
                        return (_a = val === null || val === void 0 ? void 0 : val.split(',').map((s) => s.trim())) !== null && _a !== void 0 ? _a : [];
                    return val;
                },
                formatter: (val) => (Array.isArray(val) ? val.join(', ') : val),
            },
            document_id: {
                label: 'Document',
                matches: ['document id', 'document'],
                enabled: true,
                required: false,
                type: FieldTypes.DYNAMIC_SELECT,
                table: `documents`,
                queryParamValue: 'statement',
                queryParamName: 'type',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const datum = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((datum) => datum.str_id === val);
                        return datum ? (_jsx(Chip, { clickable: true, component: "a", href: `/documents?id=${datum === null || datum === void 0 ? void 0 : datum.str_id}`, target: "_blank", label: `${((_a = datum === null || datum === void 0 ? void 0 : datum.file_path) === null || _a === void 0 ? void 0 : _a.endsWith(datum.filename)) ? datum.filename : `${getFilenameFromPath(datum.file_path)}`}` })) : (`${val} (not found in Fintary)`);
                    }
                    return val;
                },
                optionFormatter: (option) => { var _a; return `${((_a = option.file_path) === null || _a === void 0 ? void 0 : _a.endsWith(option.filename)) ? option.filename : `${getFilenameFromPath(option.file_path)}`}`; },
                optionValuer: (option) => option.str_id,
            },
            processing_status: {
                label: 'Processing status',
                options: ['new', 'processed', 'frozen'],
                matches: [],
                type: FieldTypes.SELECT,
                enabled: true,
            },
            children_data: {
                label: 'Linked records',
                enabled: true,
                type: FieldTypes.CUSTOM,
                tableFormatter: (vals) => (_jsx(Box, { children: vals === null || vals === void 0 ? void 0 : vals.map((val) => (_jsx(Chip, { label: val.str_id, clickable: true, component: "a", href: `/commissions?id=${val.str_id}`, target: "_blank", sx: { m: 0.1 } }, val.str_id))) }, "children_data")),
                render: (field, row) => {
                    return row && Array.isArray(row.children_report_data) ? (_jsxs(Box, { children: [_jsx(Typography, { children: "Linked records" }), row.children_report_data.map((val) => (_jsx(Chip, { label: val.str_id, clickable: true, component: "a", href: `/commissions?id=${val.str_id}`, target: "_blank", sx: { m: 0.1 } }, val.str_id)))] }, "children_report_data")) : null;
                },
                readOnly: true,
            },
            payment_status: {
                label: 'Payment status',
                enabled: true,
            },
            agent_payout_rate: {
                label: 'Agent payout rate',
                table: 'contacts',
                enabled: true,
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, collectionVals = []) => {
                    return (_jsx(AgentCommissionsEdit, { data: row, setter: setter, field: field, dynamicSelects: collectionVals, readOnly: true }, "agent_payout_rates"));
                },
                formatter: (val, collectionVals = []) => {
                    if (!val || !Array.isArray(collectionVals))
                        return '';
                    return (_jsx(Box, { children: Object.entries(val)
                            .filter(([k, v]) => k !== 'total')
                            .map(([k, v]) => {
                            var _a, _b;
                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {};
                            return (_jsx(Chip, { label: `${Formatter.contact(contact, {
                                    account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                                })}: ${Formatter.percentage(v)}`, sx: { m: 0.1 } }, contact.id));
                        }) }));
                },
                readOnly: true,
            },
            agent_payout_rate_override: {
                label: 'Agent payout rate override',
                table: 'contacts',
                enabled: true,
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, collectionVals = []) => {
                    return (_jsx(AgentCommissionsEdit, { data: row, setter: setter, field: field, dynamicSelects: collectionVals, disableConditions: false, percentageFormat: "percentage" }, "agent_payout_rate_override"));
                },
                formatter: (val, collectionVals = []) => {
                    if (!val || !Array.isArray(collectionVals))
                        return '';
                    return (_jsx(Box, { children: Object.entries(val)
                            .filter(([k, v]) => k !== 'total' && k !== 'config')
                            .map(([k, v]) => {
                            var _a, _b;
                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {};
                            return (_jsx(Chip, { label: `${Formatter.contact(contact, {
                                    account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                                })}: ${Formatter.percentage(Number(v) / 100)}`, sx: { m: 0.1 } }, contact.id));
                        }) }));
                },
            },
            agent_commission_payout_rate: {
                label: 'Agent commission payout rate',
                table: 'contacts',
                enabled: true,
                type: FieldTypes.CUSTOM,
                render: (field, row, setter, collectionVals = []) => {
                    return (_jsx(AgentCommissionsEdit, { data: row, setter: setter, field: field, dynamicSelects: collectionVals }, "agent_payout_rates"));
                },
                formatter: (val, collectionVals = []) => {
                    if (!val || !Array.isArray(collectionVals))
                        return '';
                    return (_jsx(Box, { children: Object.entries(val)
                            .filter(([k, v]) => k !== 'total')
                            .map(([k, v]) => {
                            var _a, _b, _c;
                            const contact = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {};
                            return (_jsx(Chip, { label: `${Formatter.contact(contact, {
                                    account_id: (_b = this.options) === null || _b === void 0 ? void 0 : _b.account_id,
                                })}: ${(_c = Formatter.percentage(v)) !== null && _c !== void 0 ? _c : 'n/a'}`, sx: { m: 0.1 } }, contact.id));
                        }) }));
                },
                readOnly: true,
            },
            report_data_id: {
                label: 'Linked policy',
                enabled: true,
                readOnly: true,
                type: FieldTypes.CUSTOM,
                render(field, row) {
                    var _a;
                    return row[field.id] ? (_jsx(Box, { sx: { m: 0.1 }, children: _jsx(Chip, { label: "Linked policy", component: Link, to: `/policies?id=${(_a = row === null || row === void 0 ? void 0 : row.report) === null || _a === void 0 ? void 0 : _a.str_id}`, target: "_blank", clickable: true }) })) : (_jsx(Box, { sx: { m: 0.1 }, children: _jsx(Chip, { label: "No linked policy", sx: { cursor: 'default' } }) }));
                },
                tableFormatter(val, row) {
                    var _a;
                    const ctx = useContext(ComparisonContext);
                    return val ? (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx("span", { style: { whiteSpace: 'nowrap' }, children: row.str_id }), _jsx(IconButton, { onClick: () => {
                                    ctx.setData({
                                        statements: [row],
                                        policy: row.report,
                                        defaultOpenStatement: row.str_id,
                                    });
                                    ctx.setShow(true);
                                }, children: _jsx(VerticalSplit, {}) }), _jsx(IconButton, { component: Link, to: `/policies?id=${(_a = row === null || row === void 0 ? void 0 : row.report) === null || _a === void 0 ? void 0 : _a.str_id}`, target: "_blank", children: _jsx(Launch, {}) })] })) : null;
                },
            },
            reconciliation_method: {
                label: 'Reconciler 🔒',
                enabled: role === 'admin',
                readOnly: true,
                type: FieldTypes.DYNAMIC_SELECT,
                table: 'reconcilers',
                formatter: (val, collectionVals = []) => {
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const datum = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((datum) => datum.id === +val);
                        return datum ? (_jsx(Chip, { label: datum.name, clickable: true, component: Link, to: `/reconciliation/reconcilers?id=${datum.str_id}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val;
                },
            },
        };
        this.actions =
            userRole === Roles.ACCOUNT_ADMIN
                ? [
                    {
                        id: 'comp_calc',
                        label: 'Calculate comp',
                        onClick: (row) => __awaiter(this, void 0, void 0, function* () {
                            // TODO: Temp implementation for TG demo. Figure out how to use API for the call. If not, replace with Axios.
                            const headers = yield API.getHeaders();
                            yield fetch(`${process.env.REACT_APP_API}/api/data_processing/commissions/agents`, {
                                method: 'post',
                                headers,
                                body: JSON.stringify({
                                    isSync: true,
                                    useGroupedCommissions: true,
                                    id: row.id,
                                }),
                            });
                        }),
                    },
                    {
                        id: 'copy_link',
                        label: 'Copy link',
                        onClick: (row) => {
                            copy(`${window.location.origin}/commissions?id=${row.str_id}`);
                        },
                    },
                ]
                : [
                    {
                        id: 'copy_link',
                        label: 'Copy link',
                        onClick: (row) => {
                            copy(`${window.location.origin}/commissions?id=${row.str_id}`);
                        },
                    },
                ];
        this.dateFilters = [
            {
                filterFieldName: 'Payment date',
                filterFieldId: 'payment_date',
                filters: [
                    {
                        label: 'Payment date start',
                        filterKey: 'payment_date_start',
                    },
                    {
                        label: 'Payment date end',
                        filterKey: 'payment_date_end',
                    },
                    { filterKey: 'payment_date_empty', type: FieldTypes.BOOLEAN },
                ],
            },
            {
                filterFieldName: 'Processing date',
                filterFieldId: 'processing_date',
                filters: [
                    {
                        label: 'Processing date start',
                        filterKey: 'processing_date_start',
                    },
                    {
                        label: 'Processing date end',
                        filterKey: 'processing_date_end',
                    },
                    { filterKey: 'processing_date_empty', type: FieldTypes.BOOLEAN },
                ],
            },
            {
                filterFieldName: 'Statement date',
                filterFieldId: 'invoice_date',
                filters: [
                    {
                        label: 'Statement date start',
                        filterKey: 'invoice_date_start',
                    },
                    {
                        label: 'Statement date end',
                        filterKey: 'invoice_date_end',
                    },
                    { filterKey: 'invoice_date_empty', type: FieldTypes.BOOLEAN },
                ],
            },
            {
                filterFieldName: 'Effective date',
                filterFieldId: 'effective_date',
                filters: [
                    {
                        label: 'Effective date start',
                        filterKey: 'effective_date_start',
                    },
                    {
                        label: 'Effective date end',
                        filterKey: 'effective_date_end',
                    },
                    { filterKey: 'effective_date_empty', type: FieldTypes.BOOLEAN },
                ],
            },
        ];
        this.outstandingFieldsInMobileView = [
            'policy_id',
            'commission_amount',
            'customer_name',
            'payment_date',
            'carrier_name',
            'premium_amount',
            'agent_payout_rate',
        ];
        // Add field key as field property
        // Delete fields that are not enabled
        Object.entries(this.fields).forEach(([k, v]) => {
            this.fields[k].id = k;
            if (!v.enabled) {
                delete this.fields[k];
            }
        });
        const allFields = Object.values(this.fields)
            .map((field) => {
            var _a, _b;
            const rowMatches = field.id
                ? [field.id, ...((_a = field.matches) !== null && _a !== void 0 ? _a : [])]
                : ((_b = field.matches) !== null && _b !== void 0 ? _b : []);
            return rowMatches.filter(Boolean);
        })
            .flat();
        this.fieldsCollapsed = [
            ...new Set(allFields.map((field) => field === null || field === void 0 ? void 0 : field.toString().toLowerCase())),
        ];
    }
}
_Statements_mode = new WeakMap(), _Statements_strings = new WeakMap();
export default Statements;
