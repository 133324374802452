import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Chip, Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import API from '@/services/API';
import ReconciliationsView from '@/components/ReconciliationsView';
import CommissionsDataView from '@/components/CommissionsDataView';
import PolicyDataView from '@/components/PolicyDataView';
import EditViewReport from '../molecules/EditViewReport';
import { useAccountStore } from '@/store';
const CheckView = () => {
    const { id } = useParams();
    const { selectedAccount } = useAccountStore();
    const [open, setOpen] = useState(false);
    const [newName, setNewName] = useState('');
    const [newNotes, setNewNotes] = useState('');
    const [newAccess, setNewAccess] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [fetchAccountUsers, setFetchAccountUsers] = useState(false);
    const { data: selectedView } = API.getBasicQuery(
    // @ts-ignore
    `saved_reports/views?view_id=${id}`);
    const { data: accountUsers, isLoading: accountUsersLoading } = API.getBasicQuery(
    // @ts-ignore
    `users/get_account_users?accId=${selectedAccount.accountId}`, '', fetchAccountUsers);
    useEffect(() => {
        if (selectedView) {
            setNewName(selectedView.name);
            setNewNotes(selectedView.notes);
            setNewAccess(selectedView.access);
            setUsersList(selectedView.users_white_list);
        }
        if ((selectedView === null || selectedView === void 0 ? void 0 : selectedView.access) === 'user_list' &&
            (selectedView === null || selectedView === void 0 ? void 0 : selectedView.users_white_list.length) > 0)
            setFetchAccountUsers(true);
    }, [selectedView]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    pt: 2,
                    pb: 1,
                }, children: [_jsxs(Box, { sx: { px: 2 }, children: [_jsx(Box, { sx: { display: 'flex', flexDirection: 'row' }, children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 8, children: _jsx(Typography, { sx: { flex: '1 1 100%' }, color: "inherit", variant: "h5", component: "div", children: newName }) }), _jsxs(Grid, { item: true, xs: 4, sx: { textAlign: 'right', pr: 2 }, children: [_jsxs(Typography, { sx: {
                                                        flex: '1 1 100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }, component: "div", children: [_jsx("strong", { children: "Created at:" }), _jsxs("div", { style: { textAlign: 'right' }, children: [' ', new Date(selectedView === null || selectedView === void 0 ? void 0 : selectedView.created_at).toLocaleString()] })] }), _jsxs(Typography, { sx: {
                                                        flex: '1 1 100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }, component: "div", children: [_jsx("strong", { children: "Access:" }), ' ', _jsx("div", { style: { textAlign: 'right' }, children: newAccess })] }), accountUsers && !accountUsersLoading && (_jsxs(Typography, { sx: {
                                                        flex: '1 1 100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }, component: "div", children: [_jsx("strong", { children: "Users:" }), _jsx("div", { style: { textAlign: 'right' }, children: usersList.map((user) => {
                                                                const userObj = accountUsers === null || accountUsers === void 0 ? void 0 : accountUsers.find((u) => u.str_id === user);
                                                                return (_jsx("div", { children: userObj === null || userObj === void 0 ? void 0 : userObj.email }, userObj === null || userObj === void 0 ? void 0 : userObj.str_id));
                                                            }) })] }))] })] }) }), _jsx(Box, { sx: { display: 'flex', flexDirection: 'row' }, children: _jsx(Box, { sx: { display: 'flex', alignItems: 'center', mr: 1, mt: 2 }, children: _jsxs(Typography, { sx: { flex: '1 1 100%' }, color: "inherit", variant: "subtitle1", component: "div", children: [_jsx("strong", { children: "Notes:" }), " ", newNotes] }) }) }), _jsx(Box, { sx: { display: 'flex', alignItems: 'center' }, children: _jsx(Chip, { label: "Edit", sx: { mr: 1, mt: 1 }, icon: _jsx(EditIcon, {}), onClick: (e) => {
                                        e.stopPropagation();
                                        setOpen(true);
                                    }, variant: "outlined", color: "primary" }) })] }), _jsxs(Box, { sx: {
                            overflowY: 'scroll',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }, children: [(selectedView === null || selectedView === void 0 ? void 0 : selectedView.page) === 'reconciliation' && _jsx(ReconciliationsView, {}), (selectedView === null || selectedView === void 0 ? void 0 : selectedView.page) === 'commissions' && _jsx(CommissionsDataView, {}), (selectedView === null || selectedView === void 0 ? void 0 : selectedView.page) === 'policies' && _jsx(PolicyDataView, {})] })] }), selectedView ? (_jsx(EditViewReport, { open: open, setOpen: setOpen, type: "View", name: selectedView.name, notes: selectedView.notes, access: selectedView.access, usersWhiteList: selectedView.users_white_list, created_by: selectedView.created_by, updateName: setNewName, updateNotes: setNewNotes, updateAccess: setNewAccess, updateUsersList: setUsersList, isOwner: selectedView.isOwner ? true : false, allData: selectedView, id: selectedView === null || selectedView === void 0 ? void 0 : selectedView.id })) : null] }));
};
export default CheckView;
