import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React, { useState } from 'react';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
const BasicDateRangePicker = ({ range, onChange, justify = 'center', width = 152, ml = 0, my = 0, mr = 0, mt = 0, display = 'flex', }) => {
    const [openStartDate, setOpenStartDate] = useState(false);
    const [openEndDate, setOpenEndDate] = useState(false);
    const handleStartDateChange = (date) => {
        onChange(Object.assign(Object.assign({}, range), { startDate: date }));
    };
    const handleEndDateChange = (date) => {
        onChange(Object.assign(Object.assign({}, range), { endDate: date }));
    };
    const wrapWithElement = (wrapper, content) => {
        if (!wrapper) {
            return content;
        }
        return React.cloneElement(wrapper, {}, content);
    };
    return (_jsxs(Box, { sx: {
            display: display,
            alignItems: 'center',
            justifyContent: justify,
            ml: ml,
            my: my,
            mr: mr,
            mt: mt,
        }, children: [wrapWithElement(range.toolTipStartDate, _jsx("div", { children: _jsx(BasicDatePicker, { label: range.startDateLabel || 'Start date', value: range.startDate, setValue: handleStartDateChange, sx: { width: width }, InputProps: {
                        sx: { borderRadius: '32px 0 0 32px', pr: 1 },
                    }, endAdornment: range.endAdornmentStartDate, disabled: range.disableStartDatePicker, open: openStartDate, onOpen: () => setOpenStartDate(true), onClose: () => setOpenStartDate(false) }, "startDate") })), wrapWithElement(range.toolTipEndDate, _jsx("div", { children: _jsx(BasicDatePicker, { label: range.endDateLabel || 'End date', value: range.endDate, setValue: handleEndDateChange, sx: { width: width }, InputProps: {
                        sx: { borderRadius: '0 32px 32px 0', ml: -0.15, pr: 1 },
                    }, endAdornment: range.endAdornmentEndDate, disabled: range.disableEndDatePicker, open: openEndDate, onOpen: () => setOpenEndDate(true), onClose: () => setOpenEndDate(false) }, "endDate") }))] }));
};
export default BasicDateRangePicker;
