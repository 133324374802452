import { jsx as _jsx } from "react/jsx-runtime";
import CommonFormatter from 'common/Formatter';
import { TransactionStatuses } from 'common/constants';
import { enumToSelectOptions } from 'common/utils';
import DataView from '@/components/DataView';
import Formatter from '@/services/Formatter';
import { useRoleStore } from '@/store';
import { FieldTypes, Roles } from '@/types';
const View = () => {
    const { userRole } = useRoleStore();
    const dataDesc = {
        label: 'Transaction details',
        table: 'accounting/transaction-details',
        editable: true,
        copyable: true,
        bulkAdd: true,
        fields: [
            {
                id: 'str_id',
                label: 'Transaction detail ID',
                condition: (val) => val === null || val === void 0 ? void 0 : val.str_id,
                visible: false,
                readOnly: true,
            },
            {
                id: 'transaction_id',
                label: 'Transaction ID',
                type: FieldTypes.DYNAMIC_SELECT,
                table: 'accounting/transactions',
                optionFormatter: (option) => `${option.str_id} - ${CommonFormatter.date(option.date)} - ${CommonFormatter.contact(option.contact)} - $${option.amount}`,
                optionValuer: (option) => option.id,
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val === '')
                        return '';
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const record = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return record
                            ? `${record.str_id} - ${CommonFormatter.date(record.date)} - ${CommonFormatter.contact(record.contact)} - $${record.amount}`
                            : '';
                    }
                    return val;
                },
            },
            {
                id: 'date',
                label: 'Transaction date',
                type: 'date',
                formatter: Formatter.date,
            },
            { id: 'amount', label: 'Amount' },
            {
                id: 'type',
                label: 'Type',
                type: 'select',
                options: [
                    { label: 'Cash receipt', id: 'cash_received' },
                    { label: 'Receivable', id: 'receivable' },
                    { label: 'Payable', id: 'payable' },
                ],
            },
            {
                id: 'notes',
                label: 'Notes',
            },
            {
                id: 'status',
                label: 'Status',
                type: FieldTypes.SELECT,
                options: enumToSelectOptions(TransactionStatuses),
            },
            {
                id: 'created_at',
                label: 'Created',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
                readOnly: true,
            },
            {
                id: 'updated_at',
                label: 'Last updated',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
                readOnly: true,
            },
        ],
    };
    const rolesWithEditAccess = [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST];
    return (_jsx(DataView, { dataDesc: dataDesc, readOnly: !userRole || !rolesWithEditAccess.includes(userRole), viewOnly: !userRole || !rolesWithEditAccess.includes(userRole) }));
};
export default View;
