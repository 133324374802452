import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Button, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from '@mui/material';
import { Close } from '@mui/icons-material';
import CriteriaActionsViewer from './CriteriaActionsViewer';
export var CriteriaActionsConfigFieldTypes;
(function (CriteriaActionsConfigFieldTypes) {
    CriteriaActionsConfigFieldTypes["CRITERIA"] = "Criteria";
    CriteriaActionsConfigFieldTypes["ACTION"] = "Action";
})(CriteriaActionsConfigFieldTypes || (CriteriaActionsConfigFieldTypes = {}));
const CriteriaActionsConfig = ({ data, field, type, setter, dynamicSelects, fields, }) => {
    var _a, _b;
    const [selectedActionCriteria, setSelectedActionCriteria] = useState([]);
    const [query, setQuery] = useState('');
    let dataUpdateData;
    if (type === CriteriaActionsConfigFieldTypes.CRITERIA) {
        dataUpdateData = (_a = data === null || data === void 0 ? void 0 : data.data_update_criteria) !== null && _a !== void 0 ? _a : [];
    }
    else if (type === CriteriaActionsConfigFieldTypes.ACTION) {
        dataUpdateData = (_b = data === null || data === void 0 ? void 0 : data.data_update_actions) !== null && _b !== void 0 ? _b : [];
    }
    const handleSelectAll = (event) => {
        event.stopPropagation();
        const filteredOptions = filterOptions(dynamicSelects, query);
        setSelectedActionCriteria(filteredOptions.map((option) => option.id));
    };
    const handleAddSelected = () => {
        var _a;
        const newData = selectedActionCriteria.map((dataId) => dynamicSelects.find((item) => item.id === dataId));
        setter(Object.assign(Object.assign({}, data), { [field.id]: [...((_a = data[field.id]) !== null && _a !== void 0 ? _a : []), ...newData] }));
        setSelectedActionCriteria([]);
    };
    const filterOptions = (options = [], query) => {
        const trimmedQuery = query.trim().toLowerCase();
        return options.filter((item) => {
            var _a, _b;
            const itemName = (_b = (_a = item === null || item === void 0 ? void 0 : item.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '';
            if (trimmedQuery.startsWith('"') && trimmedQuery.endsWith('"')) {
                const exactMatchRegex = new RegExp(`\\b${trimmedQuery.slice(1, -1)}\\b`);
                return exactMatchRegex.test(itemName);
            }
            return itemName.includes(trimmedQuery);
        });
    };
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(TableContainer, { children: _jsxs(Table, { children: [dataUpdateData.length > 0 && (_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Name" }), _jsx(TableCell, { children: type }), _jsx(TableCell, { children: "Notes" }), _jsx(TableCell, { sx: { width: 0 } })] }) })), _jsxs(TableBody, { children: [dataUpdateData.map((datum) => (_jsx(React.Fragment, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(TextField, { label: "Name", variant: "outlined", value: datum.name, disabled: true }) }), _jsx(TableCell, { children: _jsx(CriteriaActionsViewer, { fieldData: datum[field.id], rowData: data, fields: fields }) }), _jsxs(TableCell, { children: [_jsx(TextField, { label: "Notes", variant: "outlined", value: datum.notes, disabled: true }), "`"] }), _jsx(TableCell, { children: _jsx(IconButton, { onClick: () => {
                                                        setter(Object.assign(Object.assign({}, data), { [field.id]: dataUpdateData.filter((d) => d.id !== datum.id) }));
                                                    }, children: _jsx(Close, {}) }) })] }) }, datum.id))), _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 6, children: _jsxs(FormControl, { sx: { m: 0.5, width: 145 }, children: [_jsx(InputLabel, { children: "Add" }), _jsxs(Select, { label: "Add", multiple: true, value: selectedActionCriteria, onClick: (e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }, onClose: handleAddSelected, onChange: (e) => {
                                                        setSelectedActionCriteria(Array.isArray(e.target.value)
                                                            ? e.target.value.filter(Boolean)
                                                            : e.target.value);
                                                    }, sx: {
                                                        '& .MuiInputBase-input.Mui-disabled': {
                                                            WebkitTextFillColor: '#333',
                                                        },
                                                    }, renderValue: (selected) => (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: Array.isArray(selected) &&
                                                            selected.map((value) => {
                                                                const item = dynamicSelects.find((option) => option.id === value);
                                                                return (_jsx(Chip, { label: item ? item.name : value }, item ? item.str_id : value));
                                                            }) })), children: [_jsxs(Box, { sx: {
                                                                mb: 0.5,
                                                                mx: 1,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                            }, onKeyDown: (e) => {
                                                                e.stopPropagation();
                                                            }, children: [_jsx(TextField, { sx: { flex: 1, mr: 1 }, label: "Search", value: query, onChange: (e) => {
                                                                        setQuery(e.target.value);
                                                                    } }), _jsx(Button, { variant: "outlined", onClick: (e) => handleSelectAll(e), sx: { mr: 1 }, children: "Select all" }), _jsx(Button, { variant: "outlined", onClick: handleAddSelected, children: "Add selected" })] }), field.nullable && (_jsx(MenuItem, { value: '', children: "\u00A0" }, "null")), filterOptions(dynamicSelects, query).map((option) => (_jsx(MenuItem, { value: option.id, children: option.name }, `${option.id}-option-item`)))] })] }) }) })] })] }) })] }));
};
export default CriteriaActionsConfig;
