import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Chip, Drawer, Stack, Typography, } from '@mui/material';
import { createContext, useState } from 'react';
import { CheckCircleOutline, ExpandMoreOutlined } from '@mui/icons-material';
import DataForm from '@/components/DataForm';
import Statements from '@/services/Statements';
import Reports from '@/services/Reports';
export const ComparisonContext = createContext({});
const ReconciliationStatusTip = ({ status }) => {
    // Use chips, different colors, and icons to display the status confirmed, rejected, and pending
    if (status === 'confirmed') {
        return (_jsx(Chip, { label: 'Confirmed', icon: _jsx(CheckCircleOutline, {}), color: "success" }));
    }
    if (status === 'pending' || status === null) {
        return _jsx(Chip, { label: 'Pending', color: "warning" });
    }
};
export const ReconciliationConfirmProvider = ({ mode, children }) => {
    const [show, setShow] = useState(false);
    const statementList = new Statements(mode);
    const [data, setData] = useState({
        policy: {},
        statements: [],
        defaultOpenStatement: '',
    });
    const report = new Reports(mode);
    const context = {
        setShow,
        setData,
    };
    const dataDesc = {
        label: statementList.label,
        table: statementList.table,
        editable: false,
        fields: Object.entries(statementList.fields)
            .filter(([k, v]) => v.enabled)
            .reduce((acc, [k, v]) => [...acc, Object.assign(Object.assign({}, v), { id: k })], []),
    };
    const policyDesc = {
        label: report.label,
        table: report.table,
        editable: false,
        fields: Object.entries(report.fields)
            .filter(([k, v]) => v.enabled)
            .reduce((acc, [k, v]) => [...acc, Object.assign(Object.assign({}, v), { id: k })], []),
    };
    return (_jsx(ComparisonContext.Provider, { value: context, children: _jsxs(_Fragment, { children: [children, _jsx(Drawer, { anchor: "right", open: show, onClose: () => setShow(false), children: _jsxs(Stack, { spacing: 2, direction: "row", sx: { width: '80vw', padding: 1, paddingTop: '72px' }, children: [_jsx(Box, { sx: {
                                    width: '50%',
                                    height: 'calc(100vh - 80px)',
                                    overflow: 'scroll',
                                }, children: _jsxs(Accordion, { expanded: true, children: [_jsx(AccordionSummary, { sx: { '.MuiAccordionSummary-content': { my: 0 } }, children: _jsx(Typography, { variant: "h5", children: "Policy" }) }), _jsx(AccordionDetails, { sx: { py: 0 }, children: _jsx(DataForm, { dataDesc: policyDesc, fields: Object.values(policyDesc.fields), newData: data.policy, readOnly: true, formModeOnly: true, oldData: {}, setNewData: null, onCancel: null, onDelete: null, onSave: null }) })] }) }), _jsx(Box, { sx: {
                                    width: '50%',
                                    height: 'calc(100vh - 80px)',
                                    overflow: 'scroll',
                                }, children: data.statements.map((statement, index) => (_jsxs(Accordion, { defaultExpanded: data.defaultOpenStatement === statement.str_id, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreOutlined, {}), sx: { '.MuiAccordionSummary-content': { my: 0 } }, children: _jsxs(Box, { sx: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    mr: 1,
                                                }, children: [_jsx(Typography, { variant: "h5", children: "Commission" }), _jsx(Typography, { variant: "body2", children: statement.str_id }), _jsx(ReconciliationStatusTip, { status: statement.reconciliation_status })] }) }), _jsx(AccordionDetails, { sx: { py: 0 }, children: _jsx(DataForm, { dataDesc: dataDesc, fields: Object.values(dataDesc.fields), newData: statement, readOnly: true, formModeOnly: true, oldData: {}, setNewData: null, onCancel: null, onDelete: null, onSave: null }) }), _jsxs(AccordionActions, { children: [_jsx(Button, { children: "Confirm" }), _jsx(Button, { children: "Reject" })] })] }))) })] }) })] }) }));
};
