import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { RotateRight } from '@mui/icons-material';
import ExcelPreview from '@/common/preview/ExcelPreview';
import PDFPreview from '@/common/preview/PdfPreview';
import IMGPreview from '@/common/preview/imgPreview';
import { IMG_TYPES, PDF_TYPES, XLS_CSV_TYPES, Plain_TEXT_TYPES, } from '@/common/preview/model';
import useSnackbar from '@/contexts/useSnackbar';
import { DocumentPreviewTypes } from '@/types';
import TextPreview from '@/common/preview/TextPreview';
const FilePreview = ({ spreadsheet, setSpreadsheet, previewFile, previewWith, sheet, }) => {
    const [preview, setPreview] = useState();
    const [rotate, setRotate] = useState(0);
    const { showSnackbar } = useSnackbar();
    const handleRotate = () => {
        setRotate((prev) => (prev + 90) % 360);
    };
    useEffect(() => {
        if (previewFile) {
            let preview = '';
            if (XLS_CSV_TYPES.includes(previewFile.type)) {
                preview = DocumentPreviewTypes.EXCEL;
            }
            else if (PDF_TYPES.includes(previewFile.type)) {
                preview = DocumentPreviewTypes.PDF;
            }
            else if (IMG_TYPES.includes(previewFile.type)) {
                preview = DocumentPreviewTypes.IMG;
            }
            else if (Plain_TEXT_TYPES.includes(previewFile.type)) {
                preview = DocumentPreviewTypes.TEXT;
            }
            else {
                showSnackbar('Unsupported file type', 'error');
            }
            setPreview(preview);
        }
    }, [previewFile, showSnackbar]);
    return (_jsxs(Box, { sx: {
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            position: 'relative',
        }, children: [preview === DocumentPreviewTypes.EXCEL && (_jsx(ExcelPreview, { spreadsheet: spreadsheet, setSpreadsheet: setSpreadsheet, sheet: sheet })), preview === DocumentPreviewTypes.PDF && (_jsxs(_Fragment, { children: [_jsx(RotateRight, { onClick: handleRotate, color: "action", sx: {
                            position: 'absolute',
                            right: 40,
                            top: 5,
                            zIndex: 100,
                            cursor: 'pointer',
                            '&:hover': {
                                transform: 'rotate(90deg)',
                                color: '#2196f3',
                                transition: 'all 0.1s ease-in-out',
                            },
                        } }), _jsx(PDFPreview, { file: previewFile, previewWidth: previewWith, rotate: rotate })] })), preview === DocumentPreviewTypes.IMG && (_jsx(IMGPreview, { file: previewFile })), preview === DocumentPreviewTypes.TEXT && (_jsx(TextPreview, { file: previewFile }))] }));
};
export default FilePreview;
