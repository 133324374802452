import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, } from '@mui/material';
import { RATES_LABELS } from 'common/constants';
import DateRangeManager from '@/components/molecules/DateRangeManager';
import { DateRangesTypes } from '@/types';
const ConfirmNewRatesDialog = ({ open, onClose, params, compGrids, selectedDateRanges, handleNewRatesConfirm, handleDateRangeChange, }) => {
    var _a;
    return (_jsxs(Dialog, { open: open, onClose: onClose, maxWidth: "md", children: [_jsx(DialogTitle, { children: "Confirm new rates" }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { children: "Are you sure you want to create new rates?" }), _jsx(Box, { sx: { mt: 1 }, children: ((_a = params === null || params === void 0 ? void 0 : params.new_rates) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Level name" }), _jsx(TableCell, { children: "Comp grid" }), _jsx(TableCell, { children: "Rates" }), _jsx(TableCell, { children: "Date range" })] }) }), _jsx(TableBody, { children: params.new_rates.map((rate, index) => {
                                            var _a;
                                            if (typeof rate.rates !== 'object' || rate.rates === null) {
                                                return null;
                                            }
                                            return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: rate.level_name }), _jsx(TableCell, { children: (_a = compGrids.filter((compGrid) => compGrid.id === rate.comp_grid_id)[0]) === null || _a === void 0 ? void 0 : _a.name }), _jsx(TableCell, { children: Object.keys(rate.rates).map((rateItem, rateIndex) => {
                                                            if (!Object.prototype.hasOwnProperty.call(RATES_LABELS, rateItem)) {
                                                                return null;
                                                            }
                                                            return (_jsxs(Box, { children: [RATES_LABELS[rateItem], ":", ' ', rate.rates[rateItem]] }, rateIndex));
                                                        }) }), _jsx(TableCell, { children: _jsx(DateRangeManager, { row: selectedDateRanges, setter: (newDateRange) => handleDateRangeChange(index, newDateRange), field: {
                                                                id: 'date_ranges',
                                                                label: '',
                                                            }, type: DateRangesTypes.ANY }, `comp_grid_criteria_date_ranges_${index}`) })] }, index));
                                        }) })] }) })) })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, size: "small", color: "primary", children: "Cancel" }), _jsx(Button, { onClick: handleNewRatesConfirm, size: "small", variant: "contained", children: "Confirm" })] })] }));
};
export default ConfirmNewRatesDialog;
