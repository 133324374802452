var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
import { getMimeType } from '@/services/helpers';
const useUploadStorageFile = () => {
    const [fileParams, setUploadFileParams] = useState([]);
    const fileSignedUrlLoader = API.getMutation('storage/getSignedUrl', 'POST');
    const [isUploaded, setIsUploaded] = useState(false);
    const { showSnackbar } = useSnackbar();
    const getSignedUrl = (params) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const res = yield fileSignedUrlLoader.mutateAsync(Object.assign(Object.assign({}, params), { action: 'write' }));
            return res.data;
        }
        catch (error) {
            console.error(error.message || error);
            showSnackbar(error.message || error, 'error');
            return null;
        }
    });
    const uploadFilesToStorage = (signedUrl, file) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const mimeType = getMimeType(file.name);
            const headers = new Headers({
                'content-type': mimeType,
                'content-length': file.size.toString(),
            });
            const res = yield fetch(signedUrl, {
                method: 'PUT',
                headers: headers,
                body: file,
            });
            if (!res.ok) {
                showSnackbar(`Failed to upload file, error: ${res.status} ${res.statusText} `, 'error');
                setIsUploaded(false);
                return;
            }
            setIsUploaded(true);
            return res;
        }
        catch (error) {
            const err = error.message || error;
            console.error(err);
            captureException(error);
            showSnackbar(`Failed to upload file: ${err}`, 'error');
            setIsUploaded(false);
            return;
        }
    });
    const uploadFilesByGenerateSignedUrl = (fileParams) => __awaiter(void 0, void 0, void 0, function* () {
        const signedUrlsPromise = fileParams.map((_a) => __awaiter(void 0, [_a], void 0, function* ({ file_preview_type, endpoint, endpoint_str_id, file }) {
            const params = {
                file_preview_type,
                endpoint,
                endpoint_str_id,
            };
            const signedUrl = (yield getSignedUrl(params));
            return { signedUrl, file };
        }));
        try {
            const signedUrls = yield Promise.all(signedUrlsPromise);
            const uploadPromises = signedUrls.map((_a) => __awaiter(void 0, [_a], void 0, function* ({ signedUrl, file }) {
                return uploadFilesToStorage(signedUrl, file);
            }));
            yield Promise.all(uploadPromises);
            setIsUploaded(true);
        }
        catch (error) {
            console.error(error.message || error);
            captureException(error);
            showSnackbar(error.message || error, 'error');
            setIsUploaded(false);
            return;
        }
    });
    useEffect(() => {
        if (fileParams && fileParams.length) {
            uploadFilesByGenerateSignedUrl(fileParams);
        }
    }, [fileParams]);
    return {
        setUploadFileParams,
        isUploaded,
        uploadFilesByGenerateSignedUrl,
        uploadFilesToStorage,
    };
};
export default useUploadStorageFile;
