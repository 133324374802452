import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GpsFixedOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, useMediaQuery, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
const ITEM_HEIGHT = 24;
const ITEM_PADDING_TOP = 4;
const defaultFormatter = (val, isOpen) => String(val);
const defaultValuer = (val) => val;
const MultiSelect = ({ label, values: _values = [], selectedValues = [], setSelectedValues, formatter = defaultFormatter, valuer = defaultValuer, sx = {}, noall = false, enableSearch = false, paginate = false, paginateStep = 100, sorted = false, }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 18.5 + ITEM_PADDING_TOP,
                minWidth: 240,
                maxWidth: isMobile ? undefined : 640,
            },
        },
        MenuListProps: {
            style: {
                paddingBottom: 4,
            },
        },
        autoFocus: false,
    };
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [visibleCount, setVisibleCount] = useState(paginate ? paginateStep : 10000);
    const values = useMemo(() => {
        if (sorted) {
            return _values.sort((a, b) => {
                const aVal = formatter(a, isOpen);
                const bVal = formatter(b, isOpen);
                return aVal.localeCompare(bVal);
            });
        }
        return _values;
    }, [_values, formatter, isOpen, sorted]);
    const valuesMap = useMemo(() => typeof values[0] === 'object'
        ? values.reduce((acc, cur) => {
            acc.set(valuer(cur), cur);
            return acc;
        }, new Map())
        : null, [values, valuer]);
    const valuesFiltered = useMemo(() => values.filter((value) => {
        if (!query)
            return true;
        const valueFormatted = formatter(value, isOpen).toLowerCase();
        return query
            .toLowerCase()
            .split(' ')
            .map((q) => q.trim())
            .every((q) => valueFormatted.includes(q));
    }), [values, query, formatter, isOpen]);
    const valuesFilteredVisible = valuesFiltered === null || valuesFiltered === void 0 ? void 0 : valuesFiltered.slice(0, visibleCount);
    const handleChange = useCallback((event) => {
        const { target: { value }, } = event;
        if (value[value.length - 1] === 'all') {
            setSelectedValues(selectedValues.filter((val) => val !== '').length === values.length
                ? ['']
                : valuesMap
                    ? Array.from(valuesMap.keys())
                    : values);
        }
        else {
            setSelectedValues(typeof value === 'string' ? value.split(',') : value);
        }
    }, [selectedValues, setSelectedValues, values, valuesMap]);
    const pickMePickMe = useCallback((e, value) => {
        e.stopPropagation();
        setSelectedValues([value]);
    }, [setSelectedValues]);
    return (_jsx("div", { style: { width: '100%' }, children: _jsxs(FormControl, { sx: Object.assign(Object.assign({}, sx), { '.MuiInputLabel-shrink': { top: 0 } }), children: [_jsx(InputLabel, { id: "multiple-checkbox-label", sx: Object.assign({ top: -3 }, (selectedValues.length === values.length
                        ? {}
                        : { color: '#2196f3' })), children: label }), _jsxs(Select, { labelId: "multiple-checkbox-label", id: "multiple-checkbox", multiple: true, value: Array.isArray(selectedValues)
                        ? selectedValues.filter((val) => val !== '')
                        : [selectedValues], onOpen: () => setIsOpen(true), onClose: () => setIsOpen(false), onChange: handleChange, input: _jsx(OutlinedInput, { label: label }), renderValue: (selected) => {
                        if (Array.isArray(selected)) {
                            const selectedWithoutEmpty = selected.filter((val) => val !== '');
                            return selectedWithoutEmpty.length === values.length
                                ? `All (${selectedWithoutEmpty.length})`
                                : selectedWithoutEmpty.length === 0
                                    ? 'None'
                                    : `(${selectedWithoutEmpty.length}) ${valuesMap
                                        ? selectedWithoutEmpty
                                            .map((item) => formatter(valuesMap.get(item), isOpen))
                                            .join(', ')
                                        : selectedWithoutEmpty.join(', ')}`;
                        }
                        return selected;
                    }, MenuProps: MenuProps, sx: Object.assign({ '.MuiSelect-select': {
                            py: 0.75,
                            px: 1.5,
                        } }, (selectedValues.length === values.length
                        ? {}
                        : {
                            color: '#2196f3',
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#2196f3',
                            },
                            '.MuiSvgIcon-root ': { fill: '#2196f3' },
                        })), children: [enableSearch && (_jsx(Box, { sx: {
                                mb: 0.5,
                                mx: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }, onKeyDown: (e) => {
                                e.stopPropagation();
                            }, children: _jsx(TextField, { sx: { flex: 1 }, value: query, onChange: (e) => {
                                    setQuery(e.target.value);
                                }, onClickCapture: (e) => {
                                    e.stopPropagation();
                                }, placeholder: "Search" }) })), !noall && (_jsxs(MenuItem, { value: "all", dense: true, children: [_jsx(Checkbox, { checked: selectedValues.length === values.length, sx: { height: 24 } }), _jsx(ListItemText, { primary: "All" })] })), valuesFilteredVisible.map((value) => {
                            return (_jsxs(MenuItem, { value: valuer(value), dense: true, children: [_jsx(Checkbox, { checked: selectedValues.indexOf(valuer(value)) > -1, sx: { height: 24 } }), _jsx(ListItemText, { primary: formatter(value, isOpen) }), _jsx(IconButton, { sx: {
                                            ml: 1,
                                            opacity: 0,
                                            '&:hover': { opacity: 1, color: '#2196f3' },
                                        }, onClick: (e) => pickMePickMe(e, valuer(value)), children: _jsx(GpsFixedOutlined, { sx: { width: 16, height: 16 } }) })] }, valuer(value)));
                        }), (valuesFiltered === null || valuesFiltered === void 0 ? void 0 : valuesFiltered.length) > visibleCount && (_jsx(Box, { sx: {
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                mt: 0.5,
                            }, children: _jsxs(Button, { onClick: () => {
                                    setVisibleCount(visibleCount + paginateStep);
                                }, children: ["Show more (", (valuesFiltered === null || valuesFiltered === void 0 ? void 0 : valuesFiltered.length) - visibleCount, ")"] }) }))] })] }) }));
};
export default MultiSelect;
