import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState } from 'react';
export const DashboardContext = createContext({
    predefinedDashboardName: null,
    predefinedWidgetSettings: null, // Fixed typo
    setPredefinedDashboardName: () => { },
    setPredefinedWidgetSettings: () => { }, // Fixed typo
});
export const DashboardProvider = ({ children, }) => {
    const [predefinedDashboardName, setPredefinedDashboardName] = useState(null);
    const [predefinedWidgetSettings, setPredefinedWidgetSettings] = useState(null); // Fixed typo
    return (_jsx(DashboardContext.Provider, { value: {
            predefinedDashboardName,
            predefinedWidgetSettings, // Fixed typo
            setPredefinedDashboardName,
            setPredefinedWidgetSettings,
        }, children: children }));
};
