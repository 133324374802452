import { convertArrayOfMapsToArrayOfArrays } from 'common/helpers';

import removeColsWithName, {
  desc_removeColsWithName,
} from '@/common/tools/removeColsWithName';
import mapToArray, { desc_mapToArray } from '@/common/tools/mapToArray';
import spliceExcelArray, {
  desc_spliceExcelArray,
} from '@/common/tools/spliceExcelArray';
import pickData, { desc_pickData } from '@/common/tools/pickData';
import findTextLine, { desc_findTextLine } from '@/common/tools/findTextLine';
import findTextLineWithContext, {
  desc_findTextLineWithContext,
} from '@/common/tools/findTextLineWithContext';
import findAllTextLine, {
  desc_findAllTextLine,
} from '@/common/tools/findAllTextLine';
import findAllTextLineWithContext, {
  desc_findAllTextLineWithContext,
} from '@/common/tools/findAllTextLineWithContext';
import convertToNumber, {
  desc_convertToNumber,
} from '@/common/tools/convertToNumber';
import getPercent, { desc_getPercent } from '@/common/tools/getPercent';
import splitFormatDate, {
  desc_splitFormatDate,
} from '@/common/tools/splitFormatDate';
import autoMapping, { desc_autoMapping } from '@/common/tools/autoMapping';
import convertArrayToMap, {
  desc_convertArrayToMap,
} from '@/common/tools/convertArrayToMap';
import findTextLineIndex, {
  desc_findTextLineIndex,
} from '@/common/tools/findTextLineIndex';
import findTextLineByIndex, {
  desc_findTextLineByIndex,
} from '@/common/tools/findTextLineByIndex';
import findTextLineAllIndex, {
  desc_findTextLineAllIndex,
} from '@/common/tools/findTextLineAllIndex';
import findAllTextLineByIndex, {
  desc_findAllTextLineByIndex,
} from '@/common/tools/findAllTextLineByIndex';
import findAllDate, { desc_findAllDate } from '@/common/tools/findAllDate';
import dateProcessor, {
  desc_dateProcessor,
} from '@/common/tools/dateProcessor';
import convertObjectListToArrayList, {
  desc_convertObjectListToArrayList,
} from '@/common/tools/convertObjectListToArrayList';
import parallelArray, {
  desc_parallelArray,
} from '@/common/tools/parallelArray';
import fillDown, { desc_fillDown } from '@/common/tools/fillDown';
import parseDate, { desc_parseDate } from '@/common/tools/parseDate';
import convertMapToArray, {
  desc_convertMapToArray,
} from '@/common/tools/convertMapToArray';
import findTargetIn2DArray, {
  desc_findTargetIn2DArray,
} from '@/common/tools/findTargetIn2DArray';
import isValidDateFormat, { desc_isValidDateFormat } from './isValidDateFormat';

/**
 * Tool funtion list
 */
export const tool = {
  removeColsWithName,
  mapToArray,
  spliceExcelArray,
  parseDate,
  pickData,
  findTextLine,
  findTextLineWithContext,
  findAllTextLine,
  findAllTextLineWithContext,
  findTextLineIndex,
  findTextLineAllIndex,
  findTextLineByIndex,
  findAllTextLineByIndex,
  findAllDate,
  dateProcessor,
  convertToNumber,
  getPercent,
  convertMapToArray,
  splitFormatDate,
  autoMapping,
  convertArrayToMap,
  convertArrayOfMapsToArrayOfArrays,
  convertObjectListToArrayList,
  parallelArray,
  fillDown,
  findTargetIn2DArray,
  isValidDateFormat,
};

export const toolDesc = {
  Array: {
    mapToArray: desc_mapToArray,
    convertArrayToMap: desc_convertArrayToMap,
    convertMapToArray: desc_convertMapToArray,
    convertObjectListToArrayList: desc_convertObjectListToArrayList,
    spliceExcelArray: desc_spliceExcelArray,
    findTargetIn2DArray: desc_findTargetIn2DArray,
    parallelArray: desc_parallelArray,
    fillDown: desc_fillDown,
  },
  Date: {
    parseDate: desc_parseDate,
    isValidDateFormat: desc_isValidDateFormat,
    splitFormatDate: desc_splitFormatDate,
    dateProcessor: desc_dateProcessor,
  },

  Number: {
    convertToNumber: desc_convertToNumber,
    getPercent: desc_getPercent,
  },
  Lines: {
    findTextLine: desc_findTextLine,
    findTextLineWithContext: desc_findTextLineWithContext,
    findAllTextLine: desc_findAllTextLine,
    findAllTextLineWithContext: desc_findAllTextLineWithContext,
    findTextLineIndex: desc_findTextLineIndex,
    findTextLineAllIndex: desc_findTextLineAllIndex,
    findTextLineByIndex: desc_findTextLineByIndex,
    findAllTextLineByIndex: desc_findAllTextLineByIndex,
    findAllDate: desc_findAllDate,
  },
  Utility: {
    pickData: desc_pickData,
    autoMapping: desc_autoMapping,
    removeColsWithName: desc_removeColsWithName,
  },
};

export type ToolType = typeof tool;
