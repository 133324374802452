var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _a, _Spreadsheet_data;
import * as XLSX from 'xlsx';
class Spreadsheet {
    // Private
    constructor(data) {
        _Spreadsheet_data.set(this, void 0);
        this.getSheets = () => {
            return __classPrivateFieldGet(this, _Spreadsheet_data, "f").SheetNames;
        };
        this.getJson = (sheetName, isRawData = false) => {
            if (sheetName && __classPrivateFieldGet(this, _Spreadsheet_data, "f").SheetNames.includes(sheetName)) {
                const json = XLSX.utils.sheet_to_json(__classPrivateFieldGet(this, _Spreadsheet_data, "f").Sheets[sheetName], {
                    header: 1,
                    raw: false,
                    blankrows: false,
                });
                if (!isRawData) {
                    let lastRow = json.length - 1;
                    for (; lastRow > 0; lastRow -= 1) {
                        if (json[lastRow].length > 0) {
                            break;
                        }
                    }
                    return json.slice(0, lastRow + 1);
                }
                return XLSX.utils.sheet_to_json(__classPrivateFieldGet(this, _Spreadsheet_data, "f").Sheets[sheetName]);
            }
            return {};
        };
        __classPrivateFieldSet(this, _Spreadsheet_data, data, "f");
    }
}
_a = Spreadsheet, _Spreadsheet_data = new WeakMap();
Spreadsheet.loadSpreadsheet = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const data = XLSX.read(yield file.arrayBuffer(), {
        raw: file.type === 'text/csv',
        cellStyles: true,
    });
    return new _a(data);
});
Spreadsheet.indexToCol = (index) => {
    return index < 0
        ? ''
        : _a.indexToCol(index / 26 - 1) +
            String.fromCharCode((index % 26) + 65);
};
export default Spreadsheet;
