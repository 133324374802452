var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { auth, firestore } from '@/firebase';
const Datastore = {};
const checkUser = () => {
    if (!(auth === null || auth === void 0 ? void 0 : auth.currentUser)) {
        throw new Error('No current user');
    }
};
Datastore.addData = (collection, data, validation) => __awaiter(void 0, void 0, void 0, function* () {
    checkUser();
    if (validation instanceof Function && !validation(data)) {
        throw new Error('Invalid data');
    }
    // if (!validator.isEmail(contact.email)) {
    //   throw new Error(`Invalid email: ${contact.email}`);
    // }
    const impersonatedUser = JSON.parse(localStorage.getItem('customLoginUser'));
    const user = impersonatedUser !== null && impersonatedUser !== void 0 ? impersonatedUser : auth.currentUser;
    if (data.id === auth.currentUser.uid) {
        data.id = user.uid;
    }
    const res = yield firestore.collection(collection).add(Object.assign(Object.assign({}, data), { uid: user.uid, state: 'active', created: new Date().getTime(), updated: new Date().getTime() }));
    return res.id;
});
Datastore.addOrUpdateData = (collection, data, validation) => __awaiter(void 0, void 0, void 0, function* () {
    checkUser();
    if (validation instanceof Function && !validation(data)) {
        throw new Error('Invalid data');
    }
    // If doc key is uid, swap with impersonatedUser.uid
    const impersonatedUser = JSON.parse(localStorage.getItem('customLoginUser'));
    const user = impersonatedUser !== null && impersonatedUser !== void 0 ? impersonatedUser : auth.currentUser;
    if (data.id === auth.currentUser.uid) {
        data.id = user.uid;
    }
    const res = yield firestore
        .collection(collection)
        .doc(data.id)
        .set(Object.assign(Object.assign({}, data), { uid: user.uid, state: 'active', created: new Date().getTime(), updated: new Date().getTime() }), { merge: true });
    return res;
});
Datastore.updateData = (collection, _a) => __awaiter(void 0, void 0, void 0, function* () {
    var { id } = _a, data = __rest(_a, ["id"]);
    checkUser();
    if (!id) {
        throw new Error('id required');
    }
    // If doc key is uid, swap with impersonatedUser.uid
    const impersonatedUser = JSON.parse(localStorage.getItem('customLoginUser'));
    const user = impersonatedUser !== null && impersonatedUser !== void 0 ? impersonatedUser : auth.currentUser;
    const docRef = firestore
        .collection(collection)
        .doc(id === auth.currentUser.uid ? user.uid : id);
    const doc = yield docRef.get();
    if (!doc.exists) {
        throw new Error(`${id} not found.`);
    }
    yield docRef.update(Object.assign(Object.assign({}, data), { updated: new Date().getTime() }));
});
export default Datastore;
