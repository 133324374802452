import { jsx as _jsx } from "react/jsx-runtime";
import { LaunchOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DataView from '@/components/DataView';
import ResultsCard from '@/components/molecules/ResultsCard';
const dataDesc = {
    label: 'Reconciliations history',
    table: 'reconciliations',
    editable: true,
    fields: [
        // {
        //   id: 'created_at',
        //   label: 'Time',
        //   formatter: (date) => date.toLocaleString(),
        // },
        {
            id: 'result',
            label: 'Stats',
            formatter: (val) => (_jsx(ResultsCard, { data: val, variant: "wide" }, val === null || val === void 0 ? void 0 : val.id)),
            readOnly: true,
        },
        {
            id: 'settings',
            label: 'Settings',
            formatter: (val) => _jsx("pre", { children: JSON.stringify(val, null, 2) }),
            readOnly: true,
        },
        {
            id: 'notes',
            type: 'text-multiline',
            label: 'Notes',
        },
        {
            id: 'str_id',
            type: 'computed',
            label: 'View',
            // Formatter: added in react below
        },
        {
            id: 'reconciler_flow_str_id',
            label: 'Reconciler flow',
            readOnly: true,
            // Formatter: added in react below
        },
    ],
};
const ReconciliationsHistoryView = () => {
    const getLinkFormatter = (route, queryParam = 'id') => (id) => (_jsx(IconButton, { component: Link, to: `/${route}?${queryParam}=${id}`, children: _jsx(LaunchOutlined, {}) }));
    const strIdTarget = dataDesc.fields.find((f) => f.id === 'str_id');
    strIdTarget &&
        (strIdTarget.formatter = getLinkFormatter('reconciliation', 'reconciliation_id'));
    const flowIdTarget = dataDesc.fields.find((f) => f.id === 'reconciler_flow_str_id');
    flowIdTarget &&
        (flowIdTarget.formatter = getLinkFormatter('reconciliation/flows'));
    return _jsx(DataView, { dataDesc: dataDesc });
};
ReconciliationsHistoryView.propTypes = {
    user: PropTypes.object,
};
export default ReconciliationsHistoryView;
