var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip, } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSetOriginFile, useSetOverrideFile } from 'store/excelStore';
import ProcessUploads from '@/components/UploadModal/processFlow/ProcessUploads';
import API from '@/services/API';
import TimerCount from '@/components/UploadModal/processFlow/TimerCount';
import FeedbackDialog from '@/components/documents/DocumentsView/FeedbackDialog';
import useSnackbar from '@/contexts/useSnackbar';
const UpdateProcessData = ({ open, handleClose, rowData, setRowData }) => {
    var _a, _b;
    const [uploading, setUploading] = useState(false);
    const [baseQueryLoading, setBaseQueryLoading] = useState(false); // [mappings, processors
    const processFileRef = useRef();
    const [mappingsAndProcessors, setMappingsAndProcessors] = useState({
        mappings: [],
        processors: [],
    });
    const [actionCount, setActionCount] = useState(0);
    const timerCountRef = useRef(null);
    const [feedback, setFeedback] = useState('');
    const [showFeedback, setShowFeedback] = useState(false);
    const queryClient = useQueryClient();
    const setOriginFile = useSetOriginFile();
    const setOverrideFile = useSetOverrideFile();
    const { showSnackbar } = useSnackbar();
    const handleCancel = () => {
        handleClose(false);
        setOriginFile(null);
        setOverrideFile(null);
    };
    const handleFeedbackOpen = () => setShowFeedback(true);
    const handleFeedbackClose = () => setShowFeedback(false);
    const handleFeedbackSubmit = (feedback) => {
        setFeedback(feedback);
        handleFeedbackClose();
    };
    const { data: mappingsList, isLoading: mappingLoading } = API.getBasicQuery('mappings', `company_id=${rowData.company_str_id}&type=${rowData.type}`, !!rowData.company_str_id);
    const { data: processorsList, isLoading: processorsLoading } = API.getBasicQuery('processors');
    useEffect(() => {
        setBaseQueryLoading(processorsLoading || mappingLoading);
        if (mappingsList && processorsList) {
            setMappingsAndProcessors({
                mappings: mappingsList,
                processors: processorsList,
            });
        }
    }, [processorsLoading, mappingLoading]);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setUploading(true);
        const res = yield ((_a = processFileRef.current) === null || _a === void 0 ? void 0 : _a.submit());
        setUploading(false);
        if (res && !res.error && !res.upload) {
            showSnackbar('Import successful', 'success');
            queryClient.invalidateQueries();
        }
        else {
            showSnackbar((res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.upload) || 'Import failed', 'error');
            setUploading(false);
            return;
        }
        handleCancel();
    });
    let touchLevel = 'low';
    // @ts-ignore
    if (actionCount > 20 || ((_a = timerCountRef === null || timerCountRef === void 0 ? void 0 : timerCountRef.current) === null || _a === void 0 ? void 0 : _a.totalSeconds) > 300)
        touchLevel = 'medium';
    // @ts-ignore
    if (actionCount > 50 || ((_b = timerCountRef === null || timerCountRef === void 0 ? void 0 : timerCountRef.current) === null || _b === void 0 ? void 0 : _b.totalSeconds) > 600)
        touchLevel = 'high';
    return (_jsx("div", { children: _jsxs(Dialog, { open: open, fullScreen: true, maxWidth: "100%", sx: { background: 'transparent', p: 2 }, PaperProps: { sx: { borderRadius: 2 } }, children: [_jsx(DialogTitle, { sx: { p: 2 }, children: _jsxs("header", { className: "flex justify-between items-center", children: [_jsxs(Box, { className: "flex justify-between items-center", children: [_jsx(CloudSyncIcon, {}), _jsxs(Box, { className: "pl-2 flex items-center", children: [_jsx("span", { children: "Process file" }), baseQueryLoading ? (_jsx("span", { className: "ml-2 text-sm text-gray-700", children: "Loading companies and processors..." })) : (_jsx(Typography, { variant: "body2", sx: {
                                                    ml: 2,
                                                    mt: 0.1,
                                                    color: '#777',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '0.97rem',
                                                }, children: rowData.filename }))] })] }), _jsx(Box, { className: "flex group cursor-pointer hover:text-blue-600", onClick: handleCancel, children: _jsx(CloseIcon, { className: "group-hover:rotate-180 transition-all origin-center" }) })] }) }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                        padding: 1,
                        backgroundColor: '#fff',
                    }, children: _jsx(ProcessUploads, { ref: processFileRef, rowData: rowData, setRowData: setRowData, mpData: mappingsAndProcessors, actionCount: actionCount, setActionCount: setActionCount, timerCountRef: timerCountRef, feedback: feedback }) }), _jsxs(DialogActions, { sx: { display: 'flex', width: '100%' }, children: [_jsx(Box, { children: _jsx(Tooltip, { title: "Having trouble with document processing? Let the Fintary eng team know.", children: _jsx(Button, { onClick: handleFeedbackOpen, variant: ['medium', 'high'].includes(touchLevel)
                                        ? 'contained'
                                        : 'outlined', color: touchLevel === 'high'
                                        ? 'error'
                                        : touchLevel === 'medium'
                                            ? 'primary'
                                            : 'inherit', sx: ['medium', 'high'].includes(touchLevel)
                                        ? {}
                                        : { color: '#555', borderColor: '#555' }, children: "Add feedback" }) }) }), _jsx(Box, { sx: { flex: 1, mt: -1 }, children: _jsx(TimerCount, { count: actionCount, ref: timerCountRef }) }), _jsxs(Box, { sx: { display: 'flex', gap: 1 }, children: [_jsx(Button, { onClick: handleCancel, children: "Cancel" }), _jsx(LoadingButton, { loading: uploading, sx: { width: '100px' }, onClick: submit, variant: "contained", children: "Import" })] })] }), _jsx(FeedbackDialog, { open: showFeedback, onClose: handleFeedbackClose, onSubmit: handleFeedbackSubmit })] }) }));
};
UpdateProcessData.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    rowData: PropTypes.object,
    mpData: PropTypes.object,
};
export default UpdateProcessData;
