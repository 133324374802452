import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Tabs, Tab } from '@mui/material';
import { useState } from 'react';
import API from '@/services/API';
const ReleaseView = () => {
    const { data } = API.getBasicQuery('release/changelogs');
    const [activeTab, setActiveTab] = useState('release');
    const handleChange = (_event, newValue) => {
        setActiveTab(newValue);
    };
    const tabs = ['release', 'api', 'web', 'common'];
    return (_jsxs("div", { style: { display: 'flex', flexDirection: 'column', width: '100%' }, children: [_jsx(Tabs, { value: activeTab, onChange: handleChange, defaultValue: "release", sx: { ml: 4 }, children: tabs.map((tab) => (_jsx(Tab, { label: tab.toUpperCase(), value: tab }, tab))) }), _jsx("div", { style: {
                    padding: 16,
                    marginLeft: 32,
                    overflowY: 'auto',
                    width: '100%',
                }, children: _jsx(ReactMarkdown, { remarkPlugins: [remarkGfm], children: data === null || data === void 0 ? void 0 : data[activeTab] }) })] }));
};
export default ReleaseView;
