import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Close as CloseIcon } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Tooltip, Typography, } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import validate from 'validate.js';
import AuthProviderList from '@/components/AuthProviderList';
import constraints from '@/data/constraints';
import authentication from '@/services/authentication';
import { removeURLQueryParameters } from '@/services/helpers';
import useSnackbar from '@/contexts/useSnackbar';
const SignUpDialog = ({ dialogProps, theme }) => {
    const initialState = {
        performingAction: false,
        emailAddress: dialogProps.email || '',
        emailAddressConfirmation: dialogProps.email || '',
        password: '',
        passwordConfirmation: '',
        errors: null,
    };
    const [state, setState] = useState(initialState);
    const [inviteCodeValidated, setInviteCodeValidated] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);
    const urlInviteCode = urlParams.get('invite-code');
    const [inviteCode, setInviteCode] = useState(urlInviteCode ? urlInviteCode : '');
    const userLoginEmail = dialogProps.email || '';
    const { showSnackbar } = useSnackbar();
    const StyledIconButton = styled(IconButton)({
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    });
    const StyledDivider = styled(Divider)({
        margin: 'auto',
    });
    const validateInviteCode = () => {
        const PUBLIC_INVITE_CODE = 'TRYFINTARY2025';
        const INTERNAL_INVITE_CODE = 'ADMININVITE123!';
        if (inviteCode.toUpperCase() === PUBLIC_INVITE_CODE ||
            inviteCode.toUpperCase() === INTERNAL_INVITE_CODE) {
            setState(Object.assign(Object.assign({}, state), { errors: null }));
            setInviteCodeValidated(true);
        }
        else {
            const errors = {
                inviteCodeValidation: 'Wrong invite code, please try again.',
            };
            setState(Object.assign(Object.assign({}, state), { errors: errors }));
            setInviteCodeValidated(false);
        }
    };
    const validateEmailAndPassword = (emailAddress, emailAddressConfirmation, password, passwordConfirmation) => {
        return validate({
            emailAddress,
            emailAddressConfirmation,
            password,
            passwordConfirmation,
        }, {
            emailAddress: constraints.emailAddress,
            emailAddressConfirmation: constraints.emailAddressConfirmation,
            password: constraints.password,
            passwordConfirmation: constraints.passwordConfirmation,
        });
    };
    const signUp = () => {
        const { emailAddress, emailAddressConfirmation, password, passwordConfirmation, } = state;
        const errors = validateEmailAndPassword(emailAddress, emailAddressConfirmation, password, passwordConfirmation);
        if (errors) {
            setState(Object.assign(Object.assign({}, state), { errors }));
        }
        else {
            setState(Object.assign(Object.assign({}, state), { performingAction: true, errors: null }));
            authentication
                .signUpWithEmailAddressAndPassword(emailAddress, password)
                .then((user) => {
                removeURLQueryParameters(['login-type', 'email']);
                dialogProps.onClose(() => {
                    const emailAddress = user.email;
                    showSnackbar(`Signed in as ${emailAddress}`, 'success');
                });
            })
                .catch((reason) => {
                const { message } = reason;
                showSnackbar(message, 'error');
            })
                .finally(() => {
                setState(Object.assign(Object.assign({}, state), { performingAction: false }));
            });
        }
    };
    const signInWithAuthProvider = (provider) => {
        setState(Object.assign(Object.assign({}, state), { performingAction: true }));
        authentication
            .signInWithAuthProvider(provider)
            .then((user) => {
            dialogProps.onClose(() => {
                const emailAddress = user.email;
                showSnackbar(`Signed in as ${emailAddress}`, 'success');
            });
        })
            .catch((reason) => {
            const { message } = reason;
            showSnackbar(message, 'error');
        })
            .finally(() => {
            setState(Object.assign(Object.assign({}, state), { performingAction: false }));
        });
    };
    const handleKeyPress = (event) => {
        const { emailAddress, emailAddressConfirmation, password, passwordConfirmation, } = state;
        if (!emailAddress ||
            !emailAddressConfirmation ||
            !password ||
            !passwordConfirmation) {
            return;
        }
        const { key } = event;
        if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
            return;
        }
        if (key === 'Enter') {
            signUp();
        }
    };
    const handleExited = () => {
        setState(initialState);
    };
    const handleEmailAddressChange = (event) => {
        const emailAddress = event.target.value;
        setState(Object.assign(Object.assign({}, state), { emailAddress }));
    };
    const handleEmailAddressConfirmationChange = (event) => {
        const emailAddressConfirmation = event.target.value;
        setState(Object.assign(Object.assign({}, state), { emailAddressConfirmation }));
    };
    const handlePasswordChange = (event) => {
        const password = event.target.value;
        setState(Object.assign(Object.assign({}, state), { password }));
    };
    const handlePasswordConfirmationChange = (event) => {
        const passwordConfirmation = event.target.value;
        setState(Object.assign(Object.assign({}, state), { passwordConfirmation }));
    };
    // Dialog Properties
    const { performingAction, emailAddress, emailAddressConfirmation, password, passwordConfirmation, errors, } = state;
    return (_jsxs(Dialog, { open: dialogProps.open, fullWidth: true, maxWidth: "sm", disableEscapeKeyDown: performingAction, onKeyPress: handleKeyPress, TransitionProps: {
            onExited: handleExited,
        }, onClose: () => {
            dialogProps.onClose();
        }, sx: {
            [theme.breakpoints.down('sm')]: {
                '& .MuiDialog-container .MuiDialog-paper': {
                    margin: 2,
                    width: '100%',
                },
            },
        }, children: [_jsxs(DialogTitle, { sx: { pb: 1 }, children: [_jsx(Typography, { variant: "h6", component: "div", children: "Sign up for an account" }), _jsx(Tooltip, { title: "Close", children: _jsx(StyledIconButton, { disabled: performingAction, onClick: (event) => {
                                event.preventDefault();
                                dialogProps.onClose();
                            }, size: "large", children: _jsx(CloseIcon, {}) }) })] }), inviteCodeValidated ? (_jsxs(_Fragment, { children: [_jsx(DialogContent, { children: _jsxs(Grid, { container: true, direction: "row", sx: { mt: 1 }, children: [_jsx(Grid, { item: true, xs: 4, children: _jsx(AuthProviderList, { performingAction: performingAction, onAuthProviderClick: signInWithAuthProvider }) }), _jsx(Grid, { item: true, xs: 1, sx: { display: 'flex' }, children: _jsx(StyledDivider, { orientation: "vertical" }) }), _jsx(Grid, { item: true, xs: 7, children: _jsxs(Grid, { container: true, direction: "column", spacing: 2, children: [_jsx(Grid, { item: true, xs: true, children: _jsx(TextField, { autoComplete: "email", disabled: !!(performingAction || userLoginEmail), error: !!(errors && errors.emailAddress), fullWidth: true, helperText: errors && errors.emailAddress
                                                        ? errors.emailAddress[0]
                                                        : '', label: "E-mail address", placeholder: "john@doe.com", required: true, type: "email", value: emailAddress, variant: "outlined", InputLabelProps: { required: false }, onChange: handleEmailAddressChange }) }), _jsx(Grid, { item: true, xs: true, children: _jsx(TextField, { autoComplete: "email", disabled: !!(performingAction || userLoginEmail), error: !!(errors && errors.emailAddressConfirmation), fullWidth: true, helperText: errors && errors.emailAddressConfirmation
                                                        ? errors.emailAddressConfirmation[0]
                                                        : '', label: "E-mail address confirmation", placeholder: "john@doe.com", required: true, type: "email", value: emailAddressConfirmation, variant: "outlined", InputLabelProps: { required: false }, onChange: handleEmailAddressConfirmationChange }) }), _jsx(Grid, { item: true, xs: true, children: _jsx(TextField, { autoComplete: "new-password", disabled: performingAction, error: !!(errors && errors.password), fullWidth: true, helperText: errors && errors.password ? errors.password[0] : '', label: "Password", placeholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022", required: true, type: "password", value: password, variant: "outlined", InputLabelProps: { required: false }, onChange: handlePasswordChange }) }), _jsx(Grid, { item: true, xs: true, children: _jsx(TextField, { autoComplete: "password", disabled: performingAction, error: !!(errors && errors.passwordConfirmation), fullWidth: true, helperText: errors && errors.passwordConfirmation
                                                        ? errors.passwordConfirmation[0]
                                                        : '', label: "Password confirmation", placeholder: "\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022", required: true, type: "password", value: passwordConfirmation, variant: "outlined", InputLabelProps: { required: false }, onChange: handlePasswordConfirmationChange }) })] }) })] }) }), _jsx(DialogActions, { children: _jsx(Button, { color: "primary", disabled: !emailAddress ||
                                !emailAddressConfirmation ||
                                !password ||
                                !passwordConfirmation ||
                                performingAction, variant: "contained", onClick: signUp, children: "Sign up" }) })] })) : (_jsxs(_Fragment, { children: [_jsx(DialogContent, { children: _jsx(Grid, { container: true, direction: "row", sx: { mt: 1 }, children: _jsx(Grid, { item: true, xs: 12, children: _jsx(Grid, { container: true, direction: "column", spacing: 2, children: _jsx(Grid, { item: true, xs: true, children: _jsx(TextField, { autoComplete: "Invite code", 
                                            // Disabled={!!performingAction}
                                            error: !!(errors && errors.inviteCodeValidation), fullWidth: true, helperText: errors && errors.inviteCodeValidation
                                                ? errors.inviteCodeValidation
                                                : '', label: "Invite code", placeholder: "Invite code", required: true, type: "text", value: inviteCode, variant: "outlined", InputLabelProps: { required: false }, onChange: (e) => {
                                                setInviteCode(e.target.value);
                                            } }) }) }) }) }) }), _jsx(DialogActions, { children: _jsx(Button, { color: "primary", disabled: !inviteCode || performingAction, variant: "contained", onClick: validateInviteCode, children: "Next" }) })] }))] }));
};
SignUpDialog.propTypes = {
    dialogProps: PropTypes.shape({
        onClose: PropTypes.func.isRequired,
    }).isRequired,
    theme: PropTypes.object.isRequired,
};
export default SignUpDialog;
