var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Add, Delete, Edit } from '@mui/icons-material';
import { Alert, Button, IconButton } from '@mui/material';
import { useContext, useState } from 'react';
import DataView from '@/components/DataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import ActionModal from '@/components/admin/AdminDocumentsView/PromptsView/ActionModal';
import { BasicDialog } from '@/common';
const PromptsView = ({ variant = '' }) => {
    const [showModal, setShowModal] = useState(false);
    /**
     * @type {Object}
     */
    const [currentRow, setCurrentRow] = useState();
    const [refresh, setRefresh] = useState(0);
    const { setLoadingConfig } = useContext(LoadingContext);
    // Show comfirm dialog when deleting
    const [showConfirm, setShowConfirm] = useState(false);
    const deleter = API.getMutation('prompts', 'DELETE');
    const { data: owners = [] } = API.getBasicQuery('users/get_fintary_admins');
    const extraActions = (_jsx(Button, { variant: "contained", startIcon: _jsx(Add, {}), onClick: () => {
            setCurrentRow(null);
            setShowModal(true);
        }, children: "Add" }));
    const dataDesc = {
        table: 'prompts',
        editable: false,
        fields: [
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'access',
                label: 'Access',
                access: 'admin',
            },
            {
                id: 'owner',
                label: 'Owner',
                formatter: (s) => {
                    const target = owners === null || owners === void 0 ? void 0 : owners.find((c) => c.uid === s);
                    return Formatter.contact(target);
                },
            },
            {
                id: 'status',
                label: 'Status',
                access: 'admin',
            },
            {
                id: 'prompt',
                label: 'Prompt',
            },
            {
                id: 'updated_at',
                label: 'Last updated',
                formatter: (s) => new Date(s).toLocaleString(),
                readOnly: true,
            },
            {
                id: 'id',
                label: 'Actions',
                access: 'admin',
                /**
                 *
                 * @param {number} id
                 * @param {*} row
                 * @returns
                 */
                formatter: (id, row) => (_jsxs(_Fragment, { children: [_jsx(IconButton, { "aria-label": "edit", onClick: () => {
                                setCurrentRow(row);
                                setShowModal(true);
                            }, children: _jsx(Edit, {}) }), _jsx(IconButton, { "aria-label": "delete", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setCurrentRow(row);
                                setShowConfirm(true);
                            }), children: _jsx(Delete, {}) })] })),
            },
        ],
    };
    const closeModal = (e, reason) => {
        if (reason === 'backdropClick')
            return;
        setShowModal(false);
        setRefresh(refresh + 1);
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataView, { dataDesc: dataDesc, viewOnly: true, extraActions: extraActions, refresh: refresh, hideExport: true, enableAutoRefetch: true, variant: variant }), showModal && (_jsx(ActionModal, { rowData: currentRow, handleCancel: closeModal, open: showModal, owners: owners })), showConfirm && (_jsx(BasicDialog, { open: showConfirm, bodyComponent: _jsxs(Alert, { severity: "warning", children: ["Are you sure you want to delete the following prompt?", _jsx("div", { className: "text-blue-500 mt-4", children: (currentRow === null || currentRow === void 0 ? void 0 : currentRow.name) || '' })] }), positiveLabel: "Delete", title: "Delete prompt?", onClose: (val) => __awaiter(void 0, void 0, void 0, function* () {
                    setShowConfirm(false);
                    if (val) {
                        setLoadingConfig({
                            loading: true,
                            message: 'Deleting...',
                        });
                        yield deleter.mutateAsync({ ids: [currentRow === null || currentRow === void 0 ? void 0 : currentRow.id] });
                        setRefresh(refresh + 1);
                        setLoadingConfig({
                            loading: false,
                            delayToClose: 1000,
                        });
                    }
                }) }))] }));
};
export default PromptsView;
