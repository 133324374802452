var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { exportToCsv } from '@/services/helpers';
import { auth } from '@/firebase';
const DownloadsPage = () => {
    const [downloadsMessage, setDownloadsMessage] = useState('Your donwload will begin shortly.');
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const downloadType = params.get('download_type');
        const fileName = params.get('file_name');
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!downloadType || !fileName) {
                setDownloadsMessage('Download not available');
            }
            else {
                const idToken = yield ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken(true));
                if (idToken) {
                    const extraParams = new Map(Object.entries({
                        download_type: downloadType,
                        file_name: fileName,
                    }));
                    yield exportToCsv({ extraParams }, {
                        idToken,
                        endpoint: 'custom_download',
                        exportOptions: { fileName: fileName },
                    });
                }
            }
        });
        fetchData();
    }, [location]);
    return (_jsx(Box, { sx: {
            width: '100%',
        }, children: _jsx(Box, { display: "flex", justifyContent: "center", alignItems: "center", sx: { mt: 2 }, children: _jsx(Typography, { variant: "h5", children: downloadsMessage }) }) }));
};
export default DownloadsPage;
