class Validator {
}
Validator.json = (s) => {
    try {
        JSON.parse(s);
    }
    catch (e) {
        return false;
    }
    return true;
};
export default Validator;
