var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { javascript } from '@codemirror/lang-javascript';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, } from '@mui/material';
import ReactCodeMirror from '@uiw/react-codemirror';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import Formatter from 'common/Formatter';
import useWindowSize from 'contexts/useWindowSize';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import DocPreview from './DocPreview';
import useStorageSignedUrl from '@/contexts/useStorageSignedUrl';
import { DocumentPreviewKeys } from '@/types';
const defaultPrompt = `
Extract the following information from the given pdf:
  1-Find the statement date mentioned in the text and convert it to this format MM/DD/YYYY.
  2-Locate the data entries containing policy number which is Unique identifier of insurance policy (it might not exist and it might be called customer no.), commission which is the Commission received according to the carrier commission statement amount and customer name by identifying the lines that have the required information.
  3-Extract the following with the data entries if exists: Agent name, Carrier/MGA, Paying entity, Premium amount, Commission rate, Effective date, Period date, Transaction type, Product type, Product name, Compensation type, Statement number, Agent id and Group number.
  4-For any field that doesn't exist and is not 0, use null.

Format the output as follows in correct JSON format without any extra chars:
  1-For each valid data entry extract the following statement_date , policy_number, commission_amount, customer_name, agent_name, carrier_name, paying_entity, premium_amount, commission_rate, effective_date, period_date, transaction_type, product_type, product_name, compensation_type, statement_number, agent_id and group_number.
  2-Convert all dates to this format MM/DD/YYYY.
  3-If the period has 2 dates choose the end date.

  ***Do not add '''json ''' to the output***
  ***If any field is written once in the document but it goes with multiple rows, you should add to all rows in the json output.***
  
`;
const PromptsAction = ({ rowData, handleCancel, open, owners }) => {
    const [formData, setFormData] = useState({
        name: '',
        method: '',
        fileName: '',
        fileType: '',
        selectedSheet: '',
        document: '',
        prompt: '',
        owner: '',
        company_str_id: '',
    });
    const [curDocument, setCurDocument] = useState();
    const [uploading, setUploading] = useState(false);
    const [previewFile, setPreviewFile] = useState(null);
    const [aiLoading, setAiLoading] = useState(false);
    const [testResult, setTestResult] = useState([]);
    const { width: winWidth } = useWindowSize();
    const [previewWidth, setPreviewWidth] = useState(winWidth * 0.35);
    const [documentList, setDocumentList] = useState([]);
    const { setLoadingConfig } = useContext(LoadingContext);
    const { getSignedUrl } = useStorageSignedUrl();
    const { data: documents, isLoading } = API.getBasicQuery('documents');
    const { data: companies } = API.getBasicQuery('companies');
    const vertexPoster = API.getMutation('gpt/doc', 'POST');
    const promptPoster = API.getMutation('prompts', 'POST');
    const promptPatcher = API.getMutation('prompts', 'PATCH');
    useEffect(() => {
        setLoadingConfig({
            loading: isLoading,
            message: 'Page init...',
        });
    }, [isLoading]);
    useEffect(() => {
        if (!isLoading && (documents === null || documents === void 0 ? void 0 : documents.data.length)) {
            const list = documents.data.map((item) => {
                return {
                    label: item.filename,
                    value: item.file_path,
                    data: item,
                };
            });
            setDocumentList(list);
        }
    }, [documents, isLoading]);
    useEffect(() => {
        if (rowData && documents) {
            const { document_str_id } = rowData;
            const target = documents.data.find((item) => (item === null || item === void 0 ? void 0 : item.str_id) === document_str_id);
            if (target) {
                setFormData((prev) => (Object.assign(Object.assign({}, prev), { document: target.file_path, name: rowData.name, prompt: rowData.prompt, owner: rowData.owner, company_str_id: rowData.company_str_id || target.company_str_id })));
            }
        }
    }, [rowData, documents]);
    useEffect(() => {
        if (formData.document && documentList.length) {
            const target = documentList.find((item) => item.value === formData.document);
            if (target) {
                setCurDocument(target.data);
            }
        }
    }, [formData.document, documentList]);
    useEffect(() => {
        var _a;
        if (curDocument) {
            onFormChange('prompt', defaultPrompt);
            onFormChange('company_str_id', curDocument.company_str_id);
            if (!formData.name) {
                const _name = `${(_a = curDocument === null || curDocument === void 0 ? void 0 : curDocument.companies) === null || _a === void 0 ? void 0 : _a.company_name} ${curDocument.file_type} prompt`;
                onFormChange('name', _name);
            }
        }
    }, [curDocument]);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        setUploading(true);
        const params = {
            name: formData.name,
            prompt: formData.prompt,
            document_str_id: curDocument.str_id,
            profile_str_id: curDocument.profile_str_id,
            status: 'draft',
            owner: formData.owner,
            access: 'global',
            company_str_id: formData.company_str_id,
        };
        try {
            const api = rowData ? promptPatcher : promptPoster;
            if (rowData) {
                params.id = rowData.id;
            }
            const res = yield api.mutateAsync(params);
            setUploading(false);
            if (res.error) {
                console.log(res.error);
                return;
            }
            handleCancel(true);
        }
        catch (error) {
            setUploading(false);
            console.log(error);
        }
    });
    const onDragFinished = (size) => {
        setPreviewWidth(size[0]);
    };
    const onFormChange = (key, value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
    };
    const fixIncompleteJSONString = (jsonString) => {
        // Check if the string ends with a closing square bracket ']'
        if (jsonString.endsWith('""')) {
            jsonString += '}]';
        }
        else if (jsonString.endsWith('"')) {
            // Add the missing closing bracket
            jsonString += '"}]';
        }
        else if (jsonString.endsWith('"}')) {
            jsonString += ']';
        }
        return jsonString;
    };
    const runVertex = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!formData.document) {
            return;
        }
        setAiLoading(true);
        const _url = yield getSignedUrl({
            endpoint_str_id: curDocument.str_id,
            file_preview_type: curDocument.override_file_path
                ? DocumentPreviewKeys.OVERRIDE
                : DocumentPreviewKeys.ORIGINAL,
            endpoint: 'documents',
            action: 'read',
        });
        const params = {
            url: _url,
            type: previewFile === null || previewFile === void 0 ? void 0 : previewFile.type,
            document_id: curDocument.id,
            force_run: false,
            prompt: formData.prompt,
            result_id: rowData === null || rowData === void 0 ? void 0 : rowData.result_id,
            prompt_id: rowData === null || rowData === void 0 ? void 0 : rowData.id,
        };
        try {
            const resp = yield vertexPoster.mutateAsync(params);
            setAiLoading(false);
            if (resp.error) {
                console.log(resp.error);
                return;
            }
            let res = [];
            resp.data.forEach((objStr) => {
                const target = JSON.parse(objStr);
                const list = target.parts.map((item) => {
                    const fixStr = fixIncompleteJSONString(item.text);
                    return JSON.parse(fixStr);
                });
                res = res.concat(...list);
            });
            setTestResult(res);
        }
        catch (error) {
            setAiLoading(false);
            console.log(error);
        }
    });
    return (_jsxs(Dialog, { open: open, fullScreen: true, sx: { background: 'transparent', p: 1 }, onClose: handleCancel, disableEscapeKeyDown: true, children: [_jsx(DialogTitle, { children: _jsx(Box, { className: "flex items-center", children: _jsx(Box, { className: "pl-2", children: "Prompt Editor" }) }) }), _jsx(IconButton, { className: "group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600", onClick: handleCancel, children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                    p: 0,
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                }, children: _jsx("section", { className: "flex justify-between px-2 relative h-full", children: _jsxs(Allotment, { defaultSizes: [35, 65], onDragEnd: onDragFinished, children: [_jsx(Allotment.Pane, { children: _jsx(Box, { className: "h-full pr-3", children: previewWidth && formData.document ? (_jsx(DocPreview, { path: formData.document, previewWidth: previewWidth, setPreviewFile: setPreviewFile, previewFile: previewFile, rowData: curDocument })) : null }) }), _jsx(Allotment.Pane, { children: _jsx(Box, { className: "flex-1 flex flex-col h-full overflow-auto pl-3", children: _jsxs(Box, { sx: {
                                            flex: 1,
                                            overflow: 'auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }, children: [_jsxs(Box, { sx: { p: 1, pt: 2, display: 'flex', gap: 2 }, children: [_jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Documents" }), _jsx(Select, { id: "select-document", value: formData === null || formData === void 0 ? void 0 : formData.document, label: "Documents", onChange: (e) => {
                                                                    onFormChange('document', e.target.value);
                                                                }, children: documentList.map((item) => (_jsx(MenuItem, { value: item.value, children: item.label }, item.value))) })] }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: _jsx(TextField, { label: "Name", variant: "outlined", value: formData === null || formData === void 0 ? void 0 : formData.name, onChange: (e) => {
                                                                onFormChange('name', e.target.value);
                                                            } }) }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Owner" }), _jsx(Select, { id: "select-owner", value: formData === null || formData === void 0 ? void 0 : formData.owner, label: "Owner", onChange: (e) => onFormChange('owner', e.target.value), children: (owners || []).map((c) => (_jsx(MenuItem, { value: c.uid, children: _jsxs(Box, { sx: {
                                                                            display: 'flex',
                                                                            width: '100%',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                            gap: 1,
                                                                        }, children: [_jsx(Box, { sx: { flex: 1 }, children: Formatter.contact(c) }), _jsx(Box, { sx: { color: '#666' }, children: c.email })] }) }, c.uid))) })] }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Carrier" }), _jsx(Select, { id: "select-carrier", value: formData === null || formData === void 0 ? void 0 : formData.company_str_id, label: "Carrier", onChange: (e) => {
                                                                    onFormChange('company_str_id', e.target.value);
                                                                }, children: ((companies === null || companies === void 0 ? void 0 : companies.data) || []).map((c) => (_jsx(MenuItem, { value: c.str_id, children: _jsx(Box, { sx: {
                                                                            display: 'flex',
                                                                            width: '100%',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                            gap: 1,
                                                                        }, children: _jsx(Box, { sx: { flex: 1 }, children: c.company_name }) }) }, c.str_id))) })] })] }), _jsx(Box, { sx: { flex: 1, p: 1 }, children: _jsx(FormControl, { sx: { mb: 2, width: '100%' }, children: _jsx(TextField, { label: "Prompt", variant: "outlined", multiline: true, minRows: 16, value: formData === null || formData === void 0 ? void 0 : formData.prompt, onChange: (e) => {
                                                            onFormChange('prompt', e.target.value);
                                                        } }) }) }), _jsx(Box, { sx: { display: 'flex', justifyContent: 'flex-end' }, children: _jsx(LoadingButton, { onClick: runVertex, loading: aiLoading, variant: "contained", children: "Test Gemini" }) }), _jsx(Box, { sx: {
                                                    flex: 1,
                                                    overflow: 'auto',
                                                    border: '1px solid #fefefe',
                                                }, children: _jsx(ReactCodeMirror, { value: typeof testResult !== 'string'
                                                        ? JSON.stringify(testResult, null, 2)
                                                        : testResult, width: "100%", height: "100%", readOnly: true, editable: false, basicSetup: {
                                                        lineNumbers: true,
                                                        foldGutter: true,
                                                    }, extensions: [javascript({ jsx: true })] }) })] }) }) })] }) }) }), _jsxs(DialogActions, { sx: { pb: 2, px: 2 }, children: [_jsx(Button, { onClick: handleCancel, children: "Cancel" }), _jsx(LoadingButton, { onClick: submit, loading: uploading, variant: "contained", sx: { width: '100px' }, children: "Save" })] })] }));
};
PromptsAction.displayName = 'PromptsAction';
export default PromptsAction;
