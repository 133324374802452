import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Tooltip, Badge } from '@mui/material';
import { useEffect } from 'react';
import API from '@/services/API';
const DuplicateFilesDetector = ({ hash, hasDuplicateFile, setHasDuplicateFile, }) => {
    var _a;
    const data = (_a = API.getBasicQuery('documents/duplicateFilesDetector', `hash=${hash}`).data) === null || _a === void 0 ? void 0 : _a.data;
    useEffect(() => {
        if (!hasDuplicateFile && data && data.length > 0) {
            setHasDuplicateFile(true);
        }
    }, [data, hasDuplicateFile, setHasDuplicateFile]);
    const openDuplicateDocuments = () => {
        const queryString = `id=${data.map((item) => item.str_id).join(',')}`;
        const url = `/documents?${queryString}`;
        window.open(url, '_blank');
    };
    return data ? (_jsx(Box, { onClick: openDuplicateDocuments, sx: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            cursor: 'pointer',
        }, children: _jsx(Tooltip, { title: `Detected ${data.length} uploads of this file. Click to view details.`, arrow: true, children: _jsx(Badge, { badgeContent: data.length, color: "error", sx: {
                    '& .MuiBadge-dot': {
                        borderRadius: '50%',
                        height: '20px',
                        width: '20px',
                    },
                    marginRight: '10px',
                } }) }) })) : null;
};
export default DuplicateFilesDetector;
