import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { useState } from 'react';
import Validator from 'common/Validator';
import { clone } from 'lodash-es';
import MultiSelect from '@/components/molecules/MultiSelect';
const transformers = [
    {
        id: 'standardizeProduct',
        label: 'Standardize product',
        params: {
            mapping: {
                default: '{}',
                multiline: true,
                validator: Validator.json,
            },
        },
    },
    {
        id: 'standardizeProductType',
        label: 'Standardize product type',
        params: {
            mapping: {
                default: '{}',
                multiline: true,
                validator: Validator.json,
            },
        },
    },
    { id: 'toLowerCase', label: 'Convert to lower case' },
    { id: 'toUpperCase', label: 'Convert to upper case' },
    { id: 'removeDashes', label: 'Remove dashes' },
    { id: 'removeSpaces', label: 'Remove spaces' },
    { id: 'removeCommas', label: 'Remove commas' },
    { id: 'removePeriods', label: 'Remove periods' },
    { id: 'trimZeros', label: 'Trim zeros (leading and trailing)' },
    { id: 'removeLetters', label: 'Remove letters (6+ numbers required)' },
    { id: 'removedashnn', label: 'Remove "-##" and "_##"' },
    { id: 'removespacenn', label: 'Remove " ##"' },
    {
        id: 'removeFromEnd',
        label: 'Remove last n characters',
        params: {
            n: { default: 1, formatter: (val) => (val ? parseFloat(val) : 1) },
        },
    },
    {
        id: 'slice',
        label: 'Extract a section of the string',
        params: {
            indexStart: {
                default: 0,
                formatter: (val) => (val ? parseFloat(val) : 0),
            },
            indexEnd: {
                default: null,
                formatter: (val) => (val ? parseFloat(val) : null),
            },
        },
    },
    { id: 'normalizeCompany', label: 'Normalize company names' },
    { id: 'normalizeSymbols', label: 'Normalize symbols (&)' },
];
const emptyRow = { field: '', transformers: [], params: '' };
const KeyConfig = ({ fieldId, data: rowData, setter, fields }) => {
    var _a;
    const [editingParam, setEditingParam] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const data = typeof rowData[fieldId] === 'string'
        ? [clone(emptyRow)]
        : ((_a = rowData[fieldId]) !== null && _a !== void 0 ? _a : [clone(emptyRow)]);
    const handleSave = () => {
        var _a;
        const transformer = transformers.find((t) => t.id === editingParam.transformerId);
        const param = (_a = transformer === null || transformer === void 0 ? void 0 : transformer.params) === null || _a === void 0 ? void 0 : _a[editingParam.key];
        if (param === null || param === void 0 ? void 0 : param.validator) {
            const isValid = param.validator(editingParam.value);
            if (!isValid) {
                setValidationError('Invalid input ');
                return;
            }
        }
        const newData = [...data];
        const datum = newData[editingParam.datumIndex];
        datum.params = datum.params || {};
        datum.params[editingParam.transformerId] = Object.assign(Object.assign({}, datum.params[editingParam.transformerId]), { [editingParam.key]: editingParam.value });
        setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newData }));
        setEditingParam(null);
        setValidationError(null);
    };
    return (_jsxs(Box, { children: [data === null || data === void 0 ? void 0 : data.map((datum, i) => (_jsxs(Box, { sx: { mb: 1, display: 'flex' }, children: [_jsxs(FormControl, { sx: { mr: 2 }, children: [_jsx(InputLabel, { id: datum.id, children: "Field" }), _jsx(Select, { label: "Field", labelId: datum.id, value: datum.field, onChange: (e) => {
                                    const newdatums = [...data];
                                    newdatums[i].field = e.target.value;
                                    setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                                }, sx: { minWidth: 180 }, children: fields === null || fields === void 0 ? void 0 : fields.map((field) => (_jsx(MenuItem, { value: field.id, children: field.label }, field.id))) })] }), _jsx(Box, { sx: { display: 'flex' }, children: _jsx(MultiSelect, { label: "Transformers", values: transformers, formatter: (transformer, isOpen) => {
                                var _a;
                                return isOpen ? (_jsxs("div", { children: [transformer.label, _jsx(Box, { children: (_a = Object.entries(transformer.params || {})) === null || _a === void 0 ? void 0 : _a.map(([key, param]) => {
                                                var _a, _b;
                                                return (_jsx(TextField, { label: key, defaultValue: param.default, value: (_a = datum === null || datum === void 0 ? void 0 : datum.params[transformer.id]) === null || _a === void 0 ? void 0 : _a[key], onClick: (e) => {
                                                        var _a;
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        if (param.multiline) {
                                                            setEditingParam({
                                                                transformerId: transformer.id,
                                                                key,
                                                                value: ((_a = datum === null || datum === void 0 ? void 0 : datum.params[transformer.id]) === null || _a === void 0 ? void 0 : _a[key]) ||
                                                                    param.default,
                                                                datumIndex: i,
                                                            });
                                                        }
                                                    }, InputProps: {
                                                        readOnly: param.multiline,
                                                    }, onChange: (e) => {
                                                        const transformers = [...rowData[fieldId]];
                                                        transformers.map((r) => {
                                                            if (r.field === datum.field) {
                                                                r.params = r.params || {};
                                                                r.params[transformer.id] = Object.assign(Object.assign({}, r.params[transformer.id]), { [key]: param.formatter
                                                                        ? param.formatter(e.target.value)
                                                                        : e.target.value });
                                                            }
                                                        });
                                                        setter(Object.assign(Object.assign({}, rowData), { [fieldId]: transformers }));
                                                    }, onKeyDown: (e) => {
                                                        e.stopPropagation();
                                                    }, sx: {
                                                        mr: 2,
                                                        width: param.multiline ? 360 : 120,
                                                    }, error: param.validator
                                                        ? !param.validator((_b = datum === null || datum === void 0 ? void 0 : datum.params[transformer.id]) === null || _b === void 0 ? void 0 : _b[key])
                                                        : false }, key));
                                            }) })] })) : (transformer.label);
                            }, valuer: (transformer) => transformer.id, selectedValues: data[i].transformers, setSelectedValues: (newValues) => {
                                const newdatums = [...data];
                                newdatums[i].transformers =
                                    typeof newValues === 'string'
                                        ? newValues.split(',')
                                        : newValues;
                                setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                            }, sx: { width: 160, mr: 2 } }) }), _jsx(IconButton, { onClick: () => {
                            const newdatums = [...data];
                            newdatums.splice(i, 1);
                            setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                        }, children: _jsx(RemoveCircleOutline, {}) })] }, i))), _jsx(Button, { onClick: () => setter(Object.assign(Object.assign({}, rowData), { [fieldId]: [...data, clone(emptyRow)] })), children: "Add" }), _jsxs(Dialog, { open: !!editingParam, fullWidth: true, maxWidth: "lg", onClose: () => {
                    setEditingParam(null);
                    setValidationError(null);
                }, children: [_jsxs(DialogTitle, { children: ["Edit ", editingParam === null || editingParam === void 0 ? void 0 : editingParam.key] }), _jsx(DialogContent, { sx: { pb: 1 }, children: _jsx(TextField, { fullWidth: true, multiline: true, minRows: 16, maxRows: 24, value: editingParam === null || editingParam === void 0 ? void 0 : editingParam.value, onChange: (e) => {
                                setEditingParam(Object.assign(Object.assign({}, editingParam), { value: e.target.value }));
                                setValidationError(null);
                            }, error: !!validationError, helperText: validationError }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: () => {
                                    setEditingParam(null);
                                    setValidationError(null);
                                }, children: "Cancel" }), _jsx(Button, { onClick: handleSave, variant: "contained", children: "Save" })] })] })] }, fieldId));
};
export default KeyConfig;
