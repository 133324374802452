import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
const EmptyValuesSelect = ({ data, field, setter, }) => {
    var _a, _b;
    const fieldKeys = (_a = data[`${field.id.replace('_unique_empty', '')}`]) !== null && _a !== void 0 ? _a : [];
    const fieldUniqueEmptyKeys = (_b = data[field.id]) !== null && _b !== void 0 ? _b : [];
    return (_jsxs(Box, { sx: { mt: -1, mb: 1 }, children: [_jsx(Typography, { variant: "caption", sx: { ml: 1.5 }, children: field.label }), _jsx(Box, { sx: {
                    p: 0.25,
                    borderStyle: 'solid',
                    borderColor: 'silver',
                    borderWidth: 1,
                    borderRadius: 8,
                    width: '100%',
                    backgroundColor: '#2196f308',
                }, children: fieldKeys.map((fieldKey) => {
                    var _a;
                    return (_jsx(Box, { sx: {
                            m: 0.5,
                            pl: 1,
                            borderStyle: 'solid',
                            borderColor: 'silver',
                            borderWidth: 1,
                            borderRadius: 4,
                            display: 'inline-block',
                            backgroundColor: '#2196f30a',
                        }, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: (_a = fieldUniqueEmptyKeys.includes(fieldKey)) !== null && _a !== void 0 ? _a : false, onChange: (e) => {
                                    console.log('e', e);
                                    const newUniqueEmpties = data[field.id].filter((item) => item !== fieldKey);
                                    if (e.target.checked)
                                        newUniqueEmpties.push(fieldKey);
                                    setter(Object.assign(Object.assign({}, data), { [field.id]: newUniqueEmpties }));
                                }, sx: { py: 0.5 } }), label: fieldKey }) }, `${field.id}-${fieldKey}`));
                }) })] }));
};
export default EmptyValuesSelect;
