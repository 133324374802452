var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Download, History } from '@mui/icons-material';
import { Box, Button, Chip, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, } from '@mui/material';
import { CompReportViewTypes } from 'common/globalTypes';
import { cloneDeep } from 'lodash-es';
import { useContext, useState } from 'react';
import EnhancedTable from '@/components/molecules/EnhancedTable';
import { UIStateContext } from '@/contexts/UIStateProvider';
import { auth } from '@/firebase';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import Reconciliations from '@/services/Reconciliations';
import Reports from '@/services/Reports';
import Statements from '@/services/Statements';
import { exportToCsv } from '@/services/helpers';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles, savedReportsGroupsTemplates } from '@/types';
import CommissionCalcLog from './CommissionCalcLog';
import useSnackbar from '@/contexts/useSnackbar';
const ReportData = ({ selectedSnapshotReport, filteredData = [], isLoading, setOpenHistory, handleGroupingChange = null, grouping = '', handleViewChange = null, view = '', refetch = () => { }, showActions = true, isHistoryView = false, }) => {
    var _a, _b, _c, _d;
    const isMobile = useMediaQuery('(max-width:600px)');
    const [isDownloading, setIsDownloading] = useState(false);
    const { role: [role], } = useContext(UIStateContext);
    const { selectedAccount } = useAccountStore();
    const { userRole } = useRoleStore();
    const { showSnackbar } = useSnackbar();
    const SavedReportsPatcher = API.getMutation('saved_reports/reports', 'PATCH');
    if ((selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.reportPage) === 'commissions') {
        const statements = new Statements('insurance', role, userRole, {
            account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
        });
        selectedSnapshotReport.snapshot_data.headers.forEach((header) => {
            var _a;
            // Match statements.fields with headers based on label
            const field = Object.values(statements.fields).find((field) => field.label === header.label);
            // Add a new field header.formatter if field is found using field.formatter
            if (field && field.formatter) {
                header.formatter = field.formatter;
            }
            if (field && field.tableFormatter) {
                header.tableFormatter = field.tableFormatter;
            }
            if (field && field.optionFormatter) {
                header.optionFormatter = field.optionFormatter;
            }
            if (field && field.optionValuer) {
                header.optionValuer = field.optionValuer;
            }
            if (field && field.render) {
                header.render = field.render;
            }
            // Custom formatters for commission payout reports
            if (((_a = selectedSnapshotReport.saved_report_group) === null || _a === void 0 ? void 0 : _a.template) ===
                savedReportsGroupsTemplates.COMMISSION_PAYOUT) {
                if (header.id === 'agent_commissions') {
                    header.tableFormatter = (val) => {
                        if (!val)
                            return '';
                        return (_jsx(Box, { children: Object.entries(val)
                                .filter(([k, v]) => k !== 'total')
                                .map(([k, v]) => {
                                return (_jsx(Chip, { label: `${Formatter.currency(v)}`, sx: { m: 0.1 } }, k));
                            }) }));
                    };
                }
                else if (header.id === 'agent_commissions_log') {
                    header.tableFormatter = (val) => {
                        if (!val)
                            return '';
                        return (_jsx(Box, { children: Object.entries(val)
                                .filter(([k, v]) => k !== 'total')
                                .map(([k, v]) => {
                                return (_jsx(Box, { children: Array.isArray(v) ? (v.map((e, i) => (_jsx(CommissionCalcLog, { commissionProfile: e }, i)))) : (_jsx(Chip, { label: '***' })) }, k));
                            }) }));
                    };
                }
                else if (header.id === 'agent_payout_rate') {
                    header.tableFormatter = (val) => {
                        if (!val)
                            return '';
                        return (_jsx(Box, { children: Object.entries(val)
                                .filter(([k, v]) => k !== 'total')
                                .map(([k, v]) => {
                                return (_jsx(Chip, { label: `${Formatter.percentage(v)}`, sx: { m: 0.1 } }, k));
                            }) }));
                    };
                }
            }
        });
        // Custom totals for commission payout reports
        if (((_a = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.data) === null || _a === void 0 ? void 0 : _a.totals) &&
            ((_b = selectedSnapshotReport.saved_report_group) === null || _b === void 0 ? void 0 : _b.template) ===
                savedReportsGroupsTemplates.COMMISSION_PAYOUT) {
            const totals = selectedSnapshotReport.snapshot_data.data.totals;
            Object.entries(totals).forEach(([k, v]) => {
                // Remove the contact id for agent commissions totals
                if (k === 'agent_commissions' && typeof v === 'object' && v !== null) {
                    totals[k] = Object.values(v)[0];
                }
            });
        }
    }
    if ((selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.reportPage) === 'reconciliation') {
        const reconciliations = new Reconciliations('insurance', '');
        selectedSnapshotReport.snapshot_data.headers.forEach((header) => {
            // Match reconciliations.fields with headers based on label
            const field = Object.values(reconciliations.fields).find((field) => field.label === header.label);
            // Add a new field header.formatter if field is found using field.formatter
            if (field && field.formatter) {
                header.formatter = field.formatter;
            }
            if (field && field.optionFormatter) {
                header.optionFormatter = field.optionFormatter;
            }
            if (field && field.optionValuer) {
                header.optionValuer = field.optionValuer;
            }
            if (field && field.getter) {
                header.getter = field.getter;
            }
            if (field && field.render) {
                header.render = field.render;
            }
        });
    }
    if ((selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.reportPage) === 'policies') {
        const reports = new Reports('insurance', {});
        selectedSnapshotReport.snapshot_data.headers.forEach((header) => {
            // Match reports.fields with headers based on label
            const field = Object.values(reports.fields).find((field) => field.label === header.label);
            // Add a new field header.formatter if field is found using field.formatter
            if (field && field.formatter) {
                header.formatter = field.formatter;
            }
            if (field && field.optionFormatter) {
                header.optionFormatter = field.optionFormatter;
            }
            if (field && field.optionValuer) {
                header.optionValuer = field.optionValuer;
            }
            if (field && field.render) {
                header.render = field.render;
            }
        });
    }
    const onExport = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsDownloading(true);
        const idToken = yield ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken(true));
        yield exportToCsv({
            q: selectedSnapshotReport.str_id,
        }, {
            // @ts-ignore
            idToken,
            endpoint: 'saved_reports',
            exportOptions: { fileName: selectedSnapshotReport.name, view: view },
        });
        setIsDownloading(false);
    });
    const handleBulkEdit = (selected, updateData) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        let data = cloneDeep((_a = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.data) === null || _a === void 0 ? void 0 : _a.data);
        data = data
            .map((item) => {
            if (selected.includes(item.id)) {
                Object.keys(item).forEach((key) => {
                    if (!Object.prototype.hasOwnProperty.call(updateData, key) &&
                        key !== 'id') {
                        delete item[key];
                    }
                });
                Object.assign(item, updateData);
                return item;
            }
            return null;
        })
            .filter((item) => item !== null);
        const params = {
            id: selectedSnapshotReport.id,
            data,
        };
        const response = yield SavedReportsPatcher.mutateAsync(params);
        if (response.status === 200) {
            showSnackbar('Report updated!', 'success');
            refetch();
        }
        else {
            showSnackbar('Error updating report!', 'error');
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mx: 2,
                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                }, children: [_jsx(Box, { sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            mb: 0.5,
                        }, children: typeof handleGroupingChange === 'function' &&
                            typeof handleViewChange === 'function' ? (_jsxs(_Fragment, { children: [_jsxs(ToggleButtonGroup, { sx: { height: '34px', mr: 1 }, exclusive: true, orientation: isMobile ? 'vertical' : 'horizontal', color: "primary", onChange: handleGroupingChange, size: "small", value: grouping, children: [_jsx(ToggleButton, { value: "policyNumber", children: "Group by policy" }), _jsx(ToggleButton, { value: "none", children: "Commission data" })] }), userRole === Roles.ACCOUNT_ADMIN && (_jsxs(ToggleButtonGroup, { sx: { height: '34px' }, exclusive: true, orientation: isMobile ? 'vertical' : 'horizontal', color: "primary", onChange: handleViewChange, value: view, children: [_jsx(ToggleButton, { value: CompReportViewTypes.ADMIN_VIEW, children: "Admin view" }), _jsx(ToggleButton, { value: CompReportViewTypes.PRODUCER_VIEW, children: "Producer view" })] }))] })) : null }), showActions && (_jsxs(Box, { sx: {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            height: '20px',
                        }, children: [_jsx(Button, { onClick: () => setOpenHistory(true), startIcon: _jsx(History, {}), variant: "outlined", size: "small", sx: { mr: 1 }, children: "Report history" }), _jsx(Button, { disabled: isDownloading, onClick: onExport, startIcon: _jsx(Download, {}), variant: "outlined", size: "small", children: "Export" })] }))] }), isLoading ? (_jsx(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mr: 2,
                    mt: 2,
                }, children: _jsx(Typography, { variant: "h5", sx: { fontWeight: 500, mr: 0.5 }, children: "Loading..." }) })) : (_jsx(EnhancedTable, { dense: true, 
                // @ts-ignore
                headers: selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.headers, rows: filteredData
                    ? filteredData
                    : (_c = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.data) === null || _c === void 0 ? void 0 : _c.data, 
                // @ts-ignore
                onBulkEdit: (selected, updateData) => __awaiter(void 0, void 0, void 0, function* () {
                    yield handleBulkEdit(selected, updateData);
                }), 
                // @ts-ignore
                actionsEnabled: (v) => false, onDelete: !isHistoryView ? (v) => false : undefined, stickyHeader: true, paginated: true, showTotals: true, totals: (_d = selectedSnapshotReport === null || selectedSnapshotReport === void 0 ? void 0 : selectedSnapshotReport.snapshot_data.data) === null || _d === void 0 ? void 0 : _d.totals }))] }));
};
export default ReportData;
