import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, TextField, Typography, } from '@mui/material';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import validator from 'validator';
import DynamicSelect from '@/components/DataForm/DynamicSelect';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
const applyFunc = (func, arg) => (typeof func === 'function' ? func(arg) : arg);
const CompProfilesAdd = ({ data, field, setter, dynamicSelects, readOnly = false, table, }) => {
    var _a;
    const { selectedAccount } = useAccountStore();
    const [query, setQuery] = useState('');
    const [visibleProfilesCount, setVisibleProfilesCount] = useState(5);
    const profilesWithDates = (_a = data === null || data === void 0 ? void 0 : data[table]) !== null && _a !== void 0 ? _a : [];
    profilesWithDates.forEach((profileWithDates) => {
        if (!profileWithDates.contact_id.toString().includes('::'))
            profileWithDates.contact_id =
                profileWithDates.contact_id + '::' + profileWithDates.str_id;
    });
    const showMoreProfiles = () => {
        setVisibleProfilesCount((prevCount) => prevCount + 100);
    };
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsx(Box, { sx: {
                        pl: 0.5,
                        py: 0.5,
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                    }, children: _jsxs(Box, { sx: { display: 'flex', flexWrap: 'wrap' }, children: [profilesWithDates
                                .slice(0, visibleProfilesCount)
                                .map((profileWithDates) => {
                                var _a;
                                return (_jsxs(Box, { sx: {
                                        m: 0.5,
                                        p: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'silver',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        display: 'inline-block',
                                        width: 330,
                                        backgroundColor: '#2196f30a',
                                    }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: Formatter.contact(profileWithDates.contact, {
                                                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                    }) }), _jsx(IconButton, { onClick: () => {
                                                        setter(Object.assign(Object.assign({}, data), { [table]: data[table].filter((item) => item.contact_id !== profileWithDates.contact_id) }));
                                                    }, disabled: readOnly, sx: { p: 0 }, children: _jsx(CloseIcon, {}) })] }), _jsx(Box, { children: _jsx(BasicDateRangePicker, { range: {
                                                    startDate: profileWithDates.start_date,
                                                    startDateLabel: 'Start date',
                                                    disableStartDatePicker: readOnly,
                                                    endDate: profileWithDates.end_date,
                                                    endDateLabel: 'End date',
                                                    disableEndDatePicker: readOnly,
                                                }, onChange: ({ startDate, endDate }) => {
                                                    setter(Object.assign(Object.assign({}, data), { [table]: data[table].map((item) => item.contact_id === profileWithDates.contact_id
                                                            ? Object.assign(Object.assign({}, item), { start_date: startDate, end_date: endDate }) : item) }));
                                                }, mt: 0.5, my: 0.5 }) }), _jsx(Box, { children: _jsx(TextField, { label: "Multiplier", value: (_a = profileWithDates.multiplier) !== null && _a !== void 0 ? _a : '100', sx: { mt: 0.5, width: '50%' }, InputProps: {
                                                    endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                                                }, onChange: (e) => {
                                                    setter(Object.assign(Object.assign({}, data), { [table]: data[table].map((item) => item.contact_id === profileWithDates.contact_id
                                                            ? Object.assign(Object.assign({}, item), { multiplier: e.target.value }) : item) }));
                                                }, error: profileWithDates.multiplier &&
                                                    !validator.isFloat(profileWithDates.multiplier.trim(), {
                                                        min: 0,
                                                        max: 1000,
                                                    }), helperText: profileWithDates.multiplier &&
                                                    !validator.isFloat(profileWithDates.multiplier.trim(), {
                                                        min: 0,
                                                        max: 1000,
                                                    })
                                                    ? 'Must be a number between 0 and 1000'
                                                    : '', disabled: readOnly }) })] }, profileWithDates.contact_id));
                            }), profilesWithDates.length > visibleProfilesCount && (_jsx(Box, { sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mt: 0.75,
                                    mr: 1,
                                }, children: _jsx(Box, { children: _jsxs(Button, { onClick: showMoreProfiles, variant: "outlined", children: ["Show more (", `${profilesWithDates.length - visibleProfilesCount}`, ")"] }) }) })), _jsx(DynamicSelect, { field: {
                                    id: 'contact',
                                    label: 'Agents',
                                    table: 'contacts',
                                    multiple: true,
                                    formatter: Formatter.getDynamicSelectFormatter((option) => {
                                        return `${Formatter.contact(option, { incl_email: true, account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId })}${Array.isArray(option === null || option === void 0 ? void 0 : option.contact_level) && (option === null || option === void 0 ? void 0 : option.contact_level.length) > 0 ? ` • ${option === null || option === void 0 ? void 0 : option.contact_level.map((l) => l.level_label).join(', ')}` : ''}`;
                                    }),
                                    optionFormatter: (option) => {
                                        return `${Formatter.contact(option, { incl_email: true, account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId })}${Array.isArray(option === null || option === void 0 ? void 0 : option.contact_level) && (option === null || option === void 0 ? void 0 : option.contact_level.length) > 0 ? ` • ${option === null || option === void 0 ? void 0 : option.contact_level.map((l) => l.level_label).join(', ')}` : ''}`;
                                    },
                                    optionValuer: (o) => o.id,
                                    type: 'dynamic-select',
                                }, setNewData: (e) => {
                                    var _a, _b;
                                    let newIds = [];
                                    if (e instanceof Event) {
                                        // @ts-ignore
                                        newIds = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value;
                                    }
                                    else if (Array.isArray(e)) {
                                        // @ts-ignore
                                        newIds = e;
                                    }
                                    if (Array.isArray(newIds)) {
                                        setter(Object.assign(Object.assign({}, data), { [table]: [
                                                ...((_b = data[table]) !== null && _b !== void 0 ? _b : []),
                                                ...newIds.map((newId) => ({
                                                    contact: dynamicSelects.find((item) => item.id === newId),
                                                    contact_id: newId + '::' + nanoid(),
                                                    start_date: null,
                                                    end_date: null,
                                                    multiplier: '100',
                                                })),
                                            ] }));
                                    }
                                }, dynamicSelects: { contacts: dynamicSelects }, dataFormMode: false, addMode: true, sx: { m: 0.5 } }), _jsx(Box, { sx: { mt: 0.5 }, children: _jsx(Button, { onClick: () => {
                                        setter(Object.assign(Object.assign({}, data), { [table]: [] }));
                                    }, children: "Clear" }) })] }) }) }, `${field.id}-box`)] }));
};
export default CompProfilesAdd;
