import { jsx as _jsx } from "react/jsx-runtime";
import Chip from '@mui/material/Chip';
import CommonFormatter from 'common/Formatter';
import DataView from '@/components/DataView';
import DateRangeManager from '@/components/molecules/DateRangeManager';
import API from '@/services/API';
import DataTransformation from '@/services/DataTransformation';
import Formatter from '@/services/Formatter';
import { useRoleStore } from '@/store';
import { DateRangesTypes, Roles } from '@/types';
const criteriaFormatter = (val) => {
    var _a;
    return val
        ? `Company: ${(_a = val === null || val === void 0 ? void 0 : val.company) === null || _a === void 0 ? void 0 : _a.company_name} • ${Formatter.compGridCriterion(val)}`
        : '';
};
const View = () => {
    const { userRole } = useRoleStore();
    const { data: compGrids, isLoading: isLoadingCompGrids } = API.getBasicQuery('comp-grids', 'is_dynamic_select=true');
    const dataDesc = {
        label: 'Comp grid rates',
        table: 'comp-grids/rates',
        editable: true,
        copyable: true,
        bulkAdd: true,
        fields: [
            {
                id: 'comp_grid_id',
                label: 'Comp grid',
                type: 'dynamic-select',
                table: 'comp-grids',
                field: 'id',
                formatter: Formatter.getDynamicSelectFormatter(),
                optionFormatter: (o) => o === null || o === void 0 ? void 0 : o.name,
                optionValuer: (o) => o.id,
                nullable: true,
                bulkAddSelect: true,
                formModeOnly: true,
                onChange: (e, row) => {
                    if (row.comp_grid_criterion_id)
                        row.comp_grid_criterion_id = null;
                    if (row.comp_grid_level_id)
                        row.comp_grid_level_id = null;
                },
            },
            {
                id: 'date_ranges',
                label: 'Date ranges',
                readOnly: true,
                formatter: (val) => {
                    if (!val)
                        return '';
                    if (Array.isArray(val)) {
                        return val
                            .map((item) => {
                            return item.name
                                ? `${item.name} : ${CommonFormatter.dateRange(item.start_date, item.end_date)}`
                                : `${CommonFormatter.dateRange(item.start_date, item.end_date)}`;
                        })
                            .join(', ');
                    }
                },
                tableFormatter: (val) => {
                    if (!val)
                        return '';
                    if (Array.isArray(val)) {
                        return val.map((item) => {
                            return (_jsx(Chip, { label: item.name
                                    ? `${item.name} : ${CommonFormatter.dateRange(item.start_date, item.end_date)}`
                                    : `${CommonFormatter.dateRange(item.start_date, item.end_date)}`, sx: {
                                    m: 0.25,
                                } }, `chip-${item.id}-date-range`));
                        });
                    }
                },
                optionValuer: (option) => {
                    return option === null || option === void 0 ? void 0 : option.id;
                },
            },
            {
                id: 'comp_grid_criterion_id',
                label: 'Comp grid criteria',
                type: 'dynamic-select',
                table: 'comp-grids/criteria',
                queryParamName: 'comp_grid_id',
                queryParamValue: 'comp_grid_id',
                field: 'id',
                formatter: Formatter.getDynamicSelectFormatter(criteriaFormatter),
                optionFormatter: criteriaFormatter,
                optionValuer: (o) => o.id,
                required: true,
                bulkAddSelect: true,
            },
            {
                id: 'comp_grid_level_id',
                label: 'Comp grid level',
                type: 'dynamic-select',
                table: 'comp-grids/levels',
                queryParamName: 'comp_grid_id',
                queryParamValue: 'comp_grid_id',
                field: 'id',
                formatter: Formatter.getDynamicSelectFormatter((o) => { var _a; return `Comp grid: ${(_a = o === null || o === void 0 ? void 0 : o.comp_grid) === null || _a === void 0 ? void 0 : _a.name} • Level: ${o === null || o === void 0 ? void 0 : o.name}`; }),
                optionFormatter: (o) => { var _a; return `Comp grid: ${(_a = o.comp_grid) === null || _a === void 0 ? void 0 : _a.name} • Level: ${o.name}`; },
                optionValuer: (o) => o.id,
                required: true,
                bulkAddSelect: true,
            },
            {
                id: 'carrier_rate',
                label: 'Carrier rate',
                formatter: DataTransformation.formatPercentage,
                normalizer: DataTransformation.normalizePercentage,
            },
            {
                id: 'house_rate',
                label: 'House rate',
                formatter: DataTransformation.formatPercentage,
                normalizer: DataTransformation.normalizePercentage,
            },
            {
                id: 'rate',
                label: 'Rate',
                formatter: DataTransformation.formatPercentage,
                normalizer: DataTransformation.normalizePercentage,
            },
            {
                id: 'created_at',
                label: 'Created',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
            },
            {
                id: 'updated_at',
                label: 'Last updated',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
            },
        ],
        filterConfigs: {
            comp_grid_id: { type: 'select', label: 'Comp grids', options: {} },
        },
    };
    if (Array.isArray(compGrids) && compGrids.length > 0) {
        compGrids.forEach((compGrid, i) => {
            dataDesc.filterConfigs.comp_grid_id.options[compGrid.id] = {
                id: compGrid.id,
                str_id: compGrid.str_id,
                label: compGrid.name,
                query: { comp_grid_id: compGrid.id },
            };
        });
    }
    const rolesWithEditAccess = [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST];
    return (_jsx(DataView, { dataDesc: dataDesc, readOnly: !userRole || !rolesWithEditAccess.includes(userRole), viewOnly: !userRole || !rolesWithEditAccess.includes(userRole), headingOffset: 122, enablePagination: true }));
};
export default View;
