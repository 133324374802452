var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteOutline } from '@mui/icons-material';
import { Avatar, Box, Drawer, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, TextField, Typography, } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { formatDatetime } from '@/services/DataTransformation/formatter';
import stringAvatar from '@/common/CommentDrawer/stringAvatar';
import useSnackbar from '@/contexts/useSnackbar';
import { AccountAccessLevels, NotesEntityTypes } from '@/types';
const CommentDrawer = ({ open, setOpen, rowData, type, access = AccountAccessLevels.ACCOUNT, }) => {
    const [notes, setNotes] = useState();
    const [userMap, setUserMap] = useState({});
    const [anchorEl, setAnchorEl] = useState();
    const [loading, setLoading] = useState(false);
    const [currentComment, setCurrentComment] = useState();
    const [showList, setShowList] = useState([]);
    const { data: comments = [], refetch } = API.getBasicQuery('comments', `id=${rowData.str_id}&entity=${type}`, !!rowData.str_id && !!type);
    const { data: owners, isLoading } = API.getBasicQuery('users/get_fintary_admins');
    const commentsPoster = API.getMutation('comments', 'POST');
    const commentsDel = API.getMutation('comments', 'DELETE');
    const { showSnackbar } = useSnackbar();
    useEffect(() => {
        if (comments.length) {
            setShowList([...comments]);
        }
    }, [comments]);
    useEffect(() => {
        if (owners && !isLoading) {
            const users = {};
            owners.forEach((o) => {
                users[o.uid] = Formatter.contact(o);
            });
            setUserMap(users);
        }
        else {
            setUserMap({});
        }
    }, [owners, isLoading]);
    const submit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (notes && !loading) {
            setLoading(true);
            const item = {
                message: notes,
                type: type,
                account_str_id: type === NotesEntityTypes.ACCOUNT ? rowData.str_id : null,
                processor_str_id: type === NotesEntityTypes.PROCESSOR ? rowData.str_id : null,
                access: access || AccountAccessLevels.ACCOUNT,
            };
            const res = yield commentsPoster.mutateAsync(item);
            setLoading(false);
            if (res.error) {
                showSnackbar(res.error, 'error');
                return;
            }
            setShowList([...showList, res]);
            setNotes('');
        }
    });
    const delComment = () => __awaiter(void 0, void 0, void 0, function* () {
        if (currentComment) {
            setAnchorEl(undefined);
            const filtered = showList.filter((it) => it.id !== currentComment.id);
            setShowList(filtered);
            try {
                const res = yield commentsDel.mutateAsync({
                    ids: [currentComment.id],
                });
                if (res.error) {
                    showSnackbar(res.error, 'error');
                    return;
                }
                refetch();
                setCurrentComment(undefined);
            }
            catch (error) {
                showSnackbar(error, 'error');
            }
        }
    });
    return (_jsx(Drawer, { anchor: "right", sx: {
            zIndex: 9999,
        }, open: open, onClose: () => setOpen(false), children: _jsxs(Box, { sx: {
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                padding: '8px',
                height: '100vh',
                width: '460px',
                overflow: 'hidden',
            }, children: [_jsx(Box, { className: "flex items-center justify-between p-4", children: _jsx(Typography, { variant: "h6", children: "Notes" }) }), _jsxs(Box, { sx: { flex: 1, overflow: 'auto' }, children: [_jsx(List, { children: showList.map((item) => (_jsx(ListItem, { sx: {
                                    background: 'rgba(100, 100, 100, .04)',
                                    marginBottom: '4px',
                                }, onClick: (e) => {
                                    setCurrentComment(item);
                                    setAnchorEl(e.currentTarget);
                                }, children: item.user_str_id && userMap[item.user_str_id] && (_jsxs(_Fragment, { children: [_jsx(ListItemAvatar, { children: _jsx(Avatar, Object.assign({}, stringAvatar(userMap[item.user_str_id]))) }), _jsx(ListItemText, { primary: userMap[item.user_str_id], secondary: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body2", sx: { color: '#333', whiteSpace: 'pre-wrap' }, children: item.message }), _jsx(Typography, { variant: "caption", color: "text.secondary", children: formatDatetime(item.created_at) })] }) })] })) }, item.str_id))) }), _jsx(Popover, { id: "p", open: !!anchorEl, anchorEl: anchorEl, onClose: () => setAnchorEl(null), anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            }, transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            }, sx: { zIndex: 9999, padding: '2px' }, children: _jsx(IconButton, { className: "ml-1", onClick: delComment, children: _jsx(DeleteOutline, {}) }) })] }), _jsxs(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        alignItems: 'flex-end',
                    }, children: [_jsx(TextField, { id: "standard-multiline-static", label: "Notes", multiline: true, rows: 3, value: notes, onChange: (e) => setNotes(e.target.value), fullWidth: true }), _jsx(LoadingButton, { onClick: submit, loading: loading, disabled: !(notes === null || notes === void 0 ? void 0 : notes.trim()), variant: "contained", sx: { width: '100px' }, children: "Submit" })] })] }) }));
};
export default CommentDrawer;
