import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
const Field = ({ name, val }) => (_jsxs(Box, { children: [_jsx(Typography, { variant: "caption", color: "text.secondary", children: name }), _jsx(Typography, { sx: { mt: '-4px' }, children: val || 'ㅤ' })] }));
const RowCard = ({ data, fields = [], colSize = 4, style = {} }) => {
    return data ? (_jsx(Card, { variant: "outlined", sx: { mb: 1, bgcolor: style.bgcolor ? style.bgcolor : 'inherit' }, children: _jsx(CardContent, { sx: { pt: 1, pb: '8px !important' }, children: _jsx(Grid, { container: true, children: fields.map((f) => {
                    var _a, _b;
                    return (_jsx(Grid, { item: true, xs: colSize, children: _jsx(Field, { name: f.label, val: f.field3 ? (_b = (_a = data === null || data === void 0 ? void 0 : data[f.id]) === null || _a === void 0 ? void 0 : _a[f.field2]) === null || _b === void 0 ? void 0 : _b[f.field3] : data === null || data === void 0 ? void 0 : data[f.id] }) }, f.label));
                }) }) }) }, data.id)) : (_jsx(Box, { sx: { color: 'darkgray' }, children: "No data selected" }));
};
export default RowCard;
