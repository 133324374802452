import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import DataView from '@/components/DataView';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';
const View = () => {
    const { userRole } = useRoleStore();
    const { data: compGrids, isLoading: isLoadingCompGrids } = API.getBasicQuery('comp-grids', 'is_dynamic_select=true');
    const [dataDesc, setDataDesc] = useState({
        label: 'Comp grid criteria',
        table: 'comp-grids/criteria',
        editable: true,
        copyable: true,
        bulkAdd: true,
        fields: [
            {
                id: 'company_id',
                label: 'Carrier',
                type: 'dynamic-select',
                table: 'companies',
                field: 'id',
                formatter: Formatter.getDynamicSelectFormatter((o) => o === null || o === void 0 ? void 0 : o.company_name),
                optionFormatter: (o) => o.company_name,
                optionValuer: (o) => o.id,
                required: true,
            },
            {
                id: 'comp_grid_id',
                label: 'Comp grid',
                type: 'dynamic-select',
                table: 'comp-grids',
                queryParamName: 'company_id',
                queryParamValue: 'company_id',
                field: 'id',
                formatter: Formatter.getDynamicSelectFormatter(),
                optionFormatter: (o) => o.name,
                optionValuer: (o) => o.id,
                required: true,
            },
            // Whenever we want to add date ranges to the criteria, we can use the following code:
            // {
            //   id: 'date_ranges',
            //   label: 'Date ranges',
            //   type: 'custom',
            //   tableFormatter: (val) => {
            //     if (!val) return '';
            //     if (Array.isArray(val)) {
            //       return val.map((item) => {
            //         return (
            //           <Chip
            //             key={`chip-${item.id}-date-range`}
            //             label={
            //               item.name
            //                 ? `${item.name} : ${CommonFormatter.dateRange(item.start_date, item.end_date)}`
            //                 : `${CommonFormatter.dateRange(item.start_date, item.end_date)}`
            //             }
            //             sx={{
            //               m: 0.25,
            //             }}
            //           />
            //         );
            //       });
            //     }
            //   },
            //   optionValuer: (option) => {
            //     return option?.id;
            //   },
            //   render: (field, row, setter) => (
            //     <DateRangeManager
            //       key="comp_grid_criteria_date_ranges"
            //       row={row}
            //       setter={setter}
            //       field={field}
            //       type={DateRangesTypes.ANY}
            //     />
            //   ),
            // },
            {
                id: 'grid_product_id',
                label: 'Grid product',
                type: 'dynamic-select',
                table: 'comp-grids/products',
                queryParamName: 'comp_grid_id',
                queryParamValue: 'comp_grid_id',
                field: 'id',
                formatter: Formatter.getDynamicSelectFormatter(),
                optionFormatter: (o) => o.name,
                optionValuer: (o) => o.id,
                required: true,
            },
            { id: 'compensation_type', label: 'Compensation type' },
            {
                id: 'compensation_type_alternative',
                label: 'Compensation type alternatives',
            },
            { id: 'transaction_type', label: 'Transaction type' },
            { id: 'payment_mode', label: 'Payment mode' },
            {
                id: 'issue_age_start',
                label: 'Issue age start',
                normalizer: (s) => ([undefined, null, ''].includes(s) ? null : +s),
            },
            {
                id: 'issue_age_end',
                label: 'Issue age end',
                normalizer: (s) => ([undefined, null, ''].includes(s) ? null : +s),
            },
            {
                id: 'premium_min',
                label: 'Premium min',
                normalizer: (s) => ([undefined, null, ''].includes(s) ? null : +s),
            },
            {
                id: 'premium_max',
                label: 'Premium max',
                normalizer: (s) => ([undefined, null, ''].includes(s) ? null : +s),
            },
            {
                id: 'policy_year_start',
                label: 'Policy year start',
                normalizer: (s) => ([undefined, null, ''].includes(s) ? null : +s),
            },
            {
                id: 'policy_year_end',
                label: 'Policy year end',
                normalizer: (s) => ([undefined, null, ''].includes(s) ? null : +s),
            },
            {
                id: 'filter_date_field',
                label: 'Filter date field',
                type: 'select',
                nullable: true,
                options: [
                    { id: null, label: '' },
                    { id: 'effective_date', label: 'Effective date' },
                    { id: 'signed_date', label: 'Signed date' },
                ],
            },
            {
                id: 'created_at',
                label: 'Created',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
            },
            {
                id: 'updated_at',
                label: 'Updated',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
            },
        ],
        filterConfigs: {
            comp_grid_id: { type: 'select', label: 'Comp grids', options: {} },
        },
    });
    useEffect(() => {
        if (Array.isArray(compGrids) && compGrids.length > 0) {
            compGrids.forEach((compGrid, i) => {
                dataDesc.filterConfigs.comp_grid_id.options[compGrid.id] = {
                    id: compGrid.id,
                    str_id: compGrid.str_id,
                    label: compGrid.name,
                    query: { comp_grid_id: compGrid.id },
                };
            });
            setDataDesc(dataDesc);
        }
    }, [compGrids, dataDesc]);
    const rolesWithEditAccess = [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST];
    const isReadOnly = !userRole || !rolesWithEditAccess.includes(userRole);
    return (_jsx(DataView, { headingOffset: 112, dataDesc: dataDesc, readOnly: isReadOnly, viewOnly: isReadOnly, enablePagination: true }));
};
export default View;
