var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DeleteOutline, PersonAdd } from '@mui/icons-material';
import { Alert, Button, IconButton } from '@mui/material';
import Formatter from 'common/Formatter';
import { useContext, useState } from 'react';
import BasicDialog from '@/common/BasicDialog';
import DataView from '@/components/DataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import { auth } from '@/firebase';
import API from '@/services/API';
import { useAccountStore } from '@/store';
import useSnackbar from '@/contexts/useSnackbar';
import InviteUserForm from './InviteUserForm';
const UserManager = () => {
    var _a;
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const handleClose = () => {
        setOpen(false);
    };
    const { showSnackbar } = useSnackbar();
    const { setLoadingConfig } = useContext(LoadingContext);
    const { selectedAccount } = useAccountStore();
    const usersDeleter = API.getMutation('users', 'DELETE');
    const userId = (_a = auth === null || auth === void 0 ? void 0 : auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid;
    const dataDesc = {
        label: 'Users',
        // @ts-ignore
        table: `users/get_account_users?accId=${selectedAccount.accountId}`,
        editable: true,
        fields: [
            {
                id: 'first_name',
                label: 'First name',
            },
            {
                id: 'last_name',
                label: 'Last name',
            },
            {
                id: 'email',
                label: 'Email',
                readOnly: true,
            },
            {
                id: 'role_id',
                label: 'Role',
                type: 'dynamic-select',
                table: 'roles',
                field: 'name',
                formatter: (val, collectionVals = []) => {
                    var _a, _b;
                    if (val === '')
                        return '';
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const record = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return (_b = record === null || record === void 0 ? void 0 : record.name) !== null && _b !== void 0 ? _b : '';
                    }
                    return val;
                },
                optionFormatter: (option) => option === null || option === void 0 ? void 0 : option.name,
                optionValuer: (option) => option === null || option === void 0 ? void 0 : option.id,
            },
            {
                id: 'user_contact_id',
                label: 'Agent name',
                type: 'dynamic-select',
                table: 'contacts',
                queryParamName: 'user_contact',
                queryParamValue: 'str_id',
                field: 'name',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val === '')
                        return '';
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const record = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.str_id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return record ? `${record.first_name} ${record.last_name}` : '';
                    }
                    return val;
                },
                optionFormatter: (option) => `${option.first_name} ${option.last_name}`,
                optionValuer: (option) => option === null || option === void 0 ? void 0 : option.str_id,
                access: 'admin',
            },
            {
                id: 'invite_status',
                label: 'Status',
                readOnly: true,
            },
            {
                id: 'invite_date',
                label: 'Invited on',
                readOnly: true,
                formatter: (date) => new Date(date).toLocaleString(),
            },
            {
                id: 'id',
                label: 'Actions',
                condition: () => false,
                formatter: (i, row) => {
                    return (_jsx(IconButton, { className: "ml-1", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setCurrentRow(row);
                            setShowDelete(true);
                        }), disabled: userId === row.uid ? true : false, children: _jsx(DeleteOutline, {}) }));
                },
            },
        ],
    };
    const DelConfirmComp = ({ row }) => {
        return (_jsx(BasicDialog, { title: "Delete user", open: showDelete, onClose: (val) => {
                if (val) {
                    setLoadingConfig({
                        loading: true,
                        message: 'Deleting...',
                    });
                    const params = { id: row.id, strId: row.str_id };
                    usersDeleter
                        .mutateAsync(params)
                        .then(() => {
                        setRefresh(refresh + 1);
                        setLoadingConfig({
                            loading: false,
                            message: '',
                            delayToClose: 1000,
                        });
                        setCurrentRow(null);
                    })
                        .catch((err) => {
                        showSnackbar(err, 'error');
                        setLoadingConfig({
                            loading: false,
                            message: '',
                        });
                    });
                }
                else {
                    setShowDelete(false);
                    setCurrentRow(null);
                }
            }, bodyComponent: _jsxs(Alert, { severity: "warning", children: ["Are you sure you want to delete this user?", _jsx("br", {}), _jsx("br", {}), Formatter.contact(row, {
                        incl_email: true,
                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                    })] }), positiveLabel: "Delete" }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataView, { dataDesc: dataDesc, viewOnly: true, sx: { width: '100%' }, refresh: refresh, extraActions: _jsx(Button, { variant: "contained", onClick: () => setOpen(true), startIcon: _jsx(PersonAdd, {}), children: "Invite user" }), hideExport: true, embed: true }), showDelete && currentRow && _jsx(DelConfirmComp, { row: currentRow }), _jsx(InviteUserForm, { open: open, handleClose: handleClose })] }));
};
export default UserManager;
