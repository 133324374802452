var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Step, StepLabel, Stepper, } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import FileUploads from '@/components/UploadModal/FileUploads';
import useSnackbar from '@/contexts/useSnackbar';
import useStorageSignedUrl from '@/contexts/useStorageSignedUrl';
import API from '@/services/API';
import { useOriginalFile, useSetOriginFile } from '@/store/excelStore';
import useUploadStore from '@/store/uploadStore';
import PreviewUploads from './PreviewUploads';
const BaseModal = ({ open, handleClose }) => {
    const [current, setCurrent] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [formModel, setFormModel] = useState({});
    const queryClient = useQueryClient();
    const [btnText, setBtnText] = useState('');
    const [titleText, setTitleText] = useState('');
    const uploadFileRef = useRef();
    const previewFileRef = useRef();
    const setOriginFile = useSetOriginFile();
    const originalFile = useOriginalFile();
    const { getSignedUrl } = useStorageSignedUrl();
    const uploadProgresses = useUploadStore((s) => s.uploadProgresses);
    const { showSnackbar } = useSnackbar();
    const tryAgain = Object.values(uploadProgresses).some((item) => item === 'failed');
    const documentsExtractor = API.getMutation('documents/extractData', 'POST');
    const googleDocumentsAIExtractor = API.getMutation('documents/googleDocumentAI', 'POST');
    const { data: processors } = API.getBasicQuery('processors', '', open);
    const handleCancel = useCallback(() => {
        var _a;
        setCurrent(0);
        (_a = uploadFileRef.current) === null || _a === void 0 ? void 0 : _a.clearFile();
        setOriginFile(null);
        setFormModel({});
        handleClose(false);
    }, [setOriginFile]);
    const onCancel = () => {
        if (current === 0) {
            handleCancel();
            return;
        }
        setCurrent((prev) => prev - 1);
    };
    const goNext = useCallback(() => {
        if (current === 1) {
            handleCancel();
            return;
        }
        setCurrent((prev) => prev + 1);
    }, [current, handleCancel]);
    const autoExtractData = (_a) => __awaiter(void 0, [_a], void 0, function* ({ document, profile, }) {
        let targetProcessors = [];
        const isPdf = document.file_type === 'pdf';
        if (!isPdf)
            return;
        if (!targetProcessors.length) {
            targetProcessors = processors
                .filter((p) => { var _a; return (_a = profile.processor_str_ids) === null || _a === void 0 ? void 0 : _a.includes(p.str_id); })
                .map(({ method, inner_name, str_id }) => {
                return {
                    method,
                    inner_name,
                    str_id,
                };
            });
        }
        const hasExtractTable = targetProcessors.some((p) => p.method === 'extractTable');
        const hasGoogleDocumentAI = targetProcessors.some((p) => p.method === 'documentAI');
        const signedUrl = yield getSignedUrl({
            endpoint_str_id: document.str_id,
            endpoint: 'documents',
            file_preview_type: document.override_file_path ? 'override' : 'original',
            action: 'read',
        });
        if (hasExtractTable) {
            const extractDataParams = {
                url: signedUrl,
                filename: document.filename,
                document_id: document.id,
            };
            documentsExtractor.mutateAsync(extractDataParams);
        }
        else if (hasGoogleDocumentAI) {
            const _file = originalFile.find((item) => item.name === document.filename);
            const params = {
                document_id: document.id,
                document_type: 'original',
            };
            googleDocumentsAIExtractor.mutateAsync(params);
        }
        showSnackbar(`${document.filename} was uploaded. We'll let you know when the file is processed.`, 'success');
    });
    const doStepOne = () => {
        var _a;
        setUploading(true);
        (_a = uploadFileRef.current) === null || _a === void 0 ? void 0 : _a.submit();
        setUploading(false);
    };
    const doStepTwo = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setUploading(true);
        (_a = previewFileRef.current) === null || _a === void 0 ? void 0 : _a.submit({ tryAgain });
    });
    const onFinishUpload = useCallback((err) => {
        setUploading(false);
        if (err) {
            showSnackbar('Some files has failed to upload, please try again.', 'error');
            return;
        }
        goNext();
        // Auto refresh page
        if (window.location.pathname === '/documents') {
            queryClient.invalidateQueries();
        }
    }, [goNext, queryClient]);
    const submit = () => {
        switch (current) {
            case 0:
                doStepOne();
                break;
            case 1:
                doStepTwo();
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        if (current === 0) {
            setBtnText('Next');
            setTitleText('Upload file');
        }
        else if (current === 1) {
            setBtnText(tryAgain ? 'Try again' : 'Upload');
            setTitleText('Preview');
        }
    }, [current, tryAgain]);
    const steps = [
        {
            title: 'Select files',
            content: (_jsx(FileUploads, { ref: uploadFileRef, setCurrent: setCurrent, setFormModel: setFormModel, formModel: formModel })),
        },
        {
            title: 'Preview & Upload',
            content: (_jsx(PreviewUploads, { ref: previewFileRef, formModel: formModel, onFileUploadSuccess: autoExtractData, onFinishUpload: onFinishUpload })),
        },
    ];
    return (_jsxs(Dialog, { open: open, fullScreen: current === 1, maxWidth: current === 1 ? undefined : 'lg', sx: {
            background: 'transparent',
            padding: 2,
            minWidth: 800,
        }, onClose: handleCancel, PaperProps: { sx: { borderRadius: 2 } }, children: [_jsx(DialogTitle, { children: _jsxs(Box, { className: "flex items-center", children: [_jsx(CloudSyncIcon, {}), _jsx(Box, { className: "pl-2", children: titleText })] }) }), _jsx(IconButton, { className: "group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600", onClick: handleCancel, children: _jsx(CloseIcon, { className: "group-hover:rotate-180 transition-all origin-center" }) }), _jsx(Divider, {}), _jsxs(DialogContent, { sx: {
                    padding: 2,
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                }, children: [_jsx(Stepper, { activeStep: current, alternativeLabel: true, children: steps.map((step) => (_jsx(Step, { children: _jsx(StepLabel, { sx: { '.MuiStepLabel-label': { mt: '6px !important' } }, children: step.title }) }, step.title))) }), _jsx("div", { className: "p-2 pb-1 rounded flex-1", children: steps[current].content })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onCancel, children: current === 0 ? 'Cancel' : 'Back' }), _jsx(LoadingButton, { onClick: submit, loading: uploading, variant: "contained", children: btnText })] })] }));
};
BaseModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};
export default BaseModal;
