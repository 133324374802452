import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { UIStateContext } from '@/contexts/UIStateProvider';
import API from '@/services/API';
import Reconciliations from '@/services/Reconciliations';
import { useAccountStore } from '@/store';
const exportOptions = [
    {
        id: 'export',
        label: 'Export',
        options: {},
    },
    {
        id: 'export-received',
        label: 'Commission received',
        options: {},
    },
    {
        id: 'export-received-due',
        label: 'Commission received + due',
        options: { include_commissions_due: true },
    },
    // If we want to enable the export producer view, we can add the following
    // {
    //   id: 'export-producer-view',
    //   label: 'Export producer view',
    //   options: { producer_view: true },
    // },
];
const ReconciliationsView = ({ reportId = null }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { role: [role], } = useContext(UIStateContext);
    const { selectedAccount } = useAccountStore();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const reconciliations = new Reconciliations(mode, role, {
        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
    });
    const pageSettingFields = isMobile
        ? (_b = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.reconciliation) === null || _b === void 0 ? void 0 : _b.outstandingMobileFields
        : (_d = (_c = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _c === void 0 ? void 0 : _c.reconciliation) === null || _d === void 0 ? void 0 : _d.fields;
    const newFields = (pageSettingFields !== null && pageSettingFields !== void 0 ? pageSettingFields : []).reduce((acc, cur) => {
        acc[cur] = Object.assign({}, reconciliations.fields[cur]);
        return acc;
    }, {});
    if (Object.keys(newFields).length > 0)
        reconciliations.fields = newFields;
    if ((_f = (_e = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _e === void 0 ? void 0 : _e.reconciliation) === null || _f === void 0 ? void 0 : _f.page_label) {
        reconciliations.label =
            (_h = (_g = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _g === void 0 ? void 0 : _g.reconciliation) === null || _h === void 0 ? void 0 : _h.page_label;
    }
    if (isFetchedAccountSettings &&
        ((_k = (_j = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _j === void 0 ? void 0 : _j.reconciliation) === null || _k === void 0 ? void 0 : _k.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/commissions" })
        // <Box sx={{ textAlign: 'center', mt: 6, width: '100%' }}>
        //   <Typography variant="h5">No results</Typography>
        // </Box>
        );
    }
    return selectedAccount && isFetchedAccountSettings ? (_jsx(EnhancedDataView, { reportId: reportId, dataSpec: reconciliations, outstandingMobileFields: (_m = (_l = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _l === void 0 ? void 0 : _l.reconciliation) === null || _m === void 0 ? void 0 : _m.outstandingMobileFields, hideAdd: true, enableSaves: true, exportOptions: exportOptions, readOnly: true, nonSelectableOnMobile: true })) : null;
};
export default ReconciliationsView;
