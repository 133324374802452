import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, ButtonGroup } from '@mui/material';
import styled from '@mui/system/styled';
import authProviders from 'data/auth-providers';
import PropTypes from 'prop-types';
const AuthProviderList = ({ gutterBottom = false, onAuthProviderClick, performingAction = false, }) => {
    return (_jsx(Box, { mb: gutterBottom ? 3 : 0, children: _jsx(ButtonGroup, { disabled: performingAction, fullWidth: true, orientation: "vertical", variant: "outlined", children: authProviders.map((authProvider) => {
                const AuthProviderButton = styled(Button)({
                    color: authProvider.color,
                });
                return (_jsx(AuthProviderButton, { startIcon: authProvider.icon, onClick: () => onAuthProviderClick(authProvider), children: authProvider.name }, authProvider.id));
            }) }) }));
};
AuthProviderList.propTypes = {
    gutterBottom: PropTypes.bool,
    onAuthProviderClick: PropTypes.func.isRequired,
    performingAction: PropTypes.bool,
};
export default AuthProviderList;
