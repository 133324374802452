import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Chip } from '@mui/material';
import React from 'react';
import { fieldMatcherActions } from '@/components/molecules/ActionPicker';
import { fieldMatcherOptions } from '@/components/molecules/FieldMatcher';
const CriteriaActionsViewer = ({ fieldData, rowData, fields, }) => {
    var _a;
    if (!fieldData) {
        return _jsx(Chip, { label: "Not available" });
    }
    const dataEntityFields = fields[rowData.data_entity] || {};
    const getFieldLabel = (fieldId) => {
        const field = Object.values(dataEntityFields).find((field) => field.id === fieldId);
        return field ? field.label : fieldId;
    };
    const getOperatorLabel = (operatorString) => {
        var _a, _b;
        const operator = Object.values(fieldMatcherOptions).find((action) => action.id === operatorString);
        return operator
            ? ((_b = (_a = operator.shortLabel) !== null && _a !== void 0 ? _a : operator.label) !== null && _b !== void 0 ? _b : operator)
            : operator;
    };
    return (_jsx(Box, { sx: { whiteSpace: 'nowrap' }, children: !Array.isArray(fieldData) && fieldData.action ? (_jsxs(Box, { children: [fieldData.field && (_jsx(Box, { sx: { mb: 1 }, children: _jsx(Chip, { label: `${getFieldLabel(fieldData.field)}` }) })), _jsx(Chip, { label: `${(_a = fieldMatcherActions.find((action) => action.id === fieldData.action)) === null || _a === void 0 ? void 0 : _a.label} ` }), _jsx("span", { style: { marginLeft: 4, marginRight: 4 }, children: "to" }), _jsx(Chip, { label: `${fieldData.value}` })] })) : Array.isArray(fieldData) && fieldData.length > 0 ? (fieldData.map((rule, index) => (_jsxs(Box, { sx: { mb: 1 }, children: [rule.field && (_jsx(Chip, { label: `${getFieldLabel(rule.field)}`, sx: { mr: 0.5 } })), _jsx("span", { style: { marginRight: 4 }, children: rule.op && getOperatorLabel(rule.op) }), _jsx(Chip, { label: `${rule.value}`, sx: { mr: 0.5 } })] }, index)))) : (_jsx(Chip, { label: "Not available" })) }));
};
export default CriteriaActionsViewer;
