import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, } from '@mui/material';
import { clone } from 'lodash-es';
const emptyRow = { field: '', order: '' };
const OrderConfig = ({ fieldId, data: rowData, setter, fields }) => {
    var _a;
    const data = typeof rowData[fieldId] === 'string'
        ? [clone(emptyRow)]
        : ((_a = rowData[fieldId]) !== null && _a !== void 0 ? _a : [clone(emptyRow)]);
    return (_jsxs(Box, { children: [data === null || data === void 0 ? void 0 : data.map((datum, i) => (_jsxs(Box, { sx: { mb: 1, display: 'flex' }, children: [_jsxs(FormControl, { sx: { mr: 2 }, children: [_jsx(InputLabel, { id: datum.id, children: "Field" }), _jsx(Select, { label: "Field", labelId: datum.id, value: datum.field, onChange: (e) => {
                                    const newdatums = [...data];
                                    newdatums[i].field = e.target.value;
                                    setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                                }, sx: { minWidth: 180 }, children: fields === null || fields === void 0 ? void 0 : fields.map((field) => (_jsx(MenuItem, { value: field.id, children: field.label }, field.id))) })] }), _jsx(Box, { sx: { display: 'flex' }, children: _jsxs(FormControl, { sx: { mr: 2 }, children: [_jsx(InputLabel, { id: `${datum.id}-order`, children: "Order" }), _jsxs(Select, { label: "Order", labelId: `${datum.id}-order`, value: datum.order, onChange: (e) => {
                                        const newdatums = [...data];
                                        newdatums[i].order = e.target.value;
                                        setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                                    }, sx: { minWidth: 180 }, children: [_jsx(MenuItem, { value: "asc", children: "Ascending" }), _jsx(MenuItem, { value: "desc", children: "Descending" })] })] }) }), _jsx(IconButton, { onClick: () => {
                            const newdatums = [...data];
                            newdatums.splice(i, 1);
                            setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                        }, children: _jsx(RemoveCircleOutline, {}) })] }, i))), _jsx(Button, { onClick: () => setter(Object.assign(Object.assign({}, rowData), { [fieldId]: [...data, clone(emptyRow)] })), children: "Add" })] }, fieldId));
};
export default OrderConfig;
