import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { UIStateContext } from '@/contexts/UIStateProvider';
const ExpandButton = ({ expanded, setExpanded }) => (_jsx(IconButton, { sx: { height: 24, width: 24 }, onClick: () => {
        setExpanded((prev) => !prev);
    }, children: expanded ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {}) }));
const AdminBox = ({ title = '', bypass = false, expandable = false, expandableDefault = true, sx = {}, children, }) => {
    const [expanded, setExpanded] = useState(expandableDefault);
    const { role: [role], } = useContext(UIStateContext);
    if (role !== 'admin' && !bypass)
        return null;
    return (_jsxs(Box, { sx: sx, children: [_jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mt: 2,
                }, children: [!title && _jsx("span", { children: "\uD83D\uDD12" }), _jsx(Box, { sx: { width: '100%', ml: 1 }, children: _jsx(Box, { children: _jsx(Divider, {}) }) }), expandable && (_jsxs(_Fragment, { children: [title ? (_jsxs(_Fragment, { children: [_jsxs(Typography, { variant: "body2", sx: { whiteSpace: 'nowrap', cursor: 'pointer' }, onClick: () => {
                                            setExpanded((prev) => !prev);
                                        }, children: ["\uD83D\uDD12 ", title] }), ' ', _jsx(ExpandButton, { expanded: expanded, setExpanded: setExpanded })] })) : (_jsx(ExpandButton, { expanded: expanded, setExpanded: setExpanded })), _jsx(Box, { sx: { width: '100%' }, children: _jsx(Box, { children: _jsx(Divider, {}) }) })] }))] }), _jsx(Collapse, { in: expanded, children: children })] }));
};
export default AdminBox;
