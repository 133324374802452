import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { TextField, IconButton, Menu, MenuItem, Autocomplete, Box, Tooltip, } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
const FieldAggregationSelector = ({ fields, selectedField, onRemove, onUpdate, }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElFormatter, setAnchorElFormatter] = useState(null);
    const [selectedAggregation, setSelectedAggregation] = useState(selectedField.aggregation_method || '');
    const [selectedFieldValue, setSelectedFieldValue] = useState(selectedField.field || '');
    const [selectedFieldFormatter, setSelectedFieldFormatter] = useState(selectedField.formatter || '');
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleFormatterMenuOpen = (event) => {
        setAnchorElFormatter(event.currentTarget);
    };
    const handleFormatterMenuClose = () => {
        setAnchorElFormatter(null);
    };
    const handleAggregationChange = (method) => {
        setSelectedAggregation(method);
        onUpdate(Object.assign(Object.assign({}, selectedField), { aggregation_method: method }));
        handleMenuClose();
    };
    const handleFormatterChange = (method) => {
        setSelectedFieldFormatter(method);
        onUpdate(Object.assign(Object.assign({}, selectedField), { formatter: method }));
    };
    const handleFieldChange = (event, value) => {
        setSelectedFieldValue(value);
        onUpdate(Object.assign(Object.assign({}, selectedField), { field: value }));
    };
    return (_jsxs("div", { style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '5px',
        }, children: [_jsx(Autocomplete, { options: fields.map((field) => field.name), value: selectedFieldValue, style: { width: '60%' }, onChange: handleFieldChange, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { variant: "outlined", size: "small" }))) }), _jsxs(Box, { ml: 1, display: 'flex', alignItems: 'center', children: [selectedAggregation, _jsx(Tooltip, { title: "Aggregation method", children: _jsx(IconButton, { onClick: handleMenuOpen, children: _jsx(MoreVertIcon, {}) }) }), selectedFieldFormatter, _jsx(Tooltip, { title: "Result formatter", children: _jsx(IconButton, { onClick: handleFormatterMenuOpen, children: _jsx(MoreHorizIcon, {}) }) }), _jsx(IconButton, { onClick: onRemove, children: _jsx(ClearIcon, {}) })] }), _jsxs(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleMenuClose, children: [_jsx(MenuItem, { onClick: () => handleAggregationChange('Sum'), children: "Sum" }), _jsx(MenuItem, { onClick: () => handleAggregationChange('Average'), children: "Average" }), _jsx(MenuItem, { onClick: () => handleAggregationChange('Count'), children: "Count" })] }), _jsxs(Menu, { anchorEl: anchorElFormatter, open: Boolean(anchorElFormatter), onClose: handleFormatterMenuClose, children: [_jsx(MenuItem, { onClick: () => handleFormatterChange('currency'), children: "Currency" }), _jsx(MenuItem, { onClick: () => handleFormatterChange('percentage'), children: "Percentage" }), _jsx(MenuItem, { onClick: () => handleFormatterChange('number'), children: "Number" })] })] }));
};
export default FieldAggregationSelector;
