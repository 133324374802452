var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Close } from '@mui/icons-material';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
const ActionModal = ({ open, setOpen, rowData, companies }) => {
    const [uploading, setUploading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        mapping: '',
        carrier_id: '',
        type: '',
        access: '',
    });
    const { showSnackbar } = useSnackbar();
    const accessList = useMemo(() => [
        {
            label: 'Account',
            value: 'account',
        },
        {
            label: 'Global',
            value: 'global',
        },
    ], []);
    const typeList = useMemo(() => [
        {
            label: 'Statement',
            value: 'statement',
        },
        {
            label: 'Report',
            value: 'report',
        },
    ], []);
    const mappingPoster = API.getMutation('mappings', 'POST');
    const mappingPutter = API.getMutation('mappings', 'PATCH');
    useEffect(() => {
        if (rowData) {
            const { type, name, carrier_id, mapping, access, carrier } = rowData;
            setFormData({
                type,
                name,
                carrier_id: carrier_id || carrier.str_id,
                mapping: JSON.stringify(mapping, null, 4),
                access,
            });
        }
    }, [rowData]);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setUploading(true);
        const { id, str_id } = rowData, poster = __rest(rowData, ["id", "str_id"]);
        const body = Object.assign(Object.assign({}, formData), { str_id: rowData.str_id, id: id, modify_status: 'modified', carrier_id: formData.carrier_id, mapping: JSON.parse(formData.mapping || '{}') });
        try {
            // Save origin mappings
            const postRes = yield mappingPoster.mutateAsync(Object.assign(Object.assign({}, poster), { modify_status: `${str_id}::${new Date().toISOString()}` }));
            if (postRes.error) {
                showSnackbar(postRes.error, 'error');
                return;
            }
            // Modify
            const result = yield mappingPutter.mutateAsync(body);
            setUploading(false);
            if (result.error) {
                showSnackbar(result.error, 'error');
                return;
            }
            if (result) {
                setOpen(false);
            }
        }
        catch (error) {
            showSnackbar(error, 'error');
            setUploading(false);
        }
    });
    const onFormChange = (key, value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
    };
    return (_jsxs(Dialog, { open: open, maxWidth: "lg", fullWidth: true, sx: { background: 'transparent', p: 1 }, onClose: () => setOpen(false), disableEscapeKeyDown: true, children: [_jsx(DialogTitle, { children: _jsx(Box, { className: "flex items-center", children: "Mappings edit" }) }), _jsx(IconButton, { className: "group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600", onClick: () => setOpen(false), children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                    p: 0,
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                }, children: _jsxs(Box, { sx: { width: '100%', maxHeight: '700px', p: 4 }, children: [_jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: _jsx(TextField, { label: "Name", variant: "outlined", value: formData.name, onChange: (e) => {
                                    onFormChange('name', e.target.value);
                                } }) }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: _jsx(TextField, { label: "Mappings", variant: "outlined", multiline: true, minRows: 4, maxRows: 18, value: formData.mapping, onChange: (e) => {
                                    onFormChange('mapping', e.target.value);
                                } }) }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Access" }), _jsx(Select, { id: "select-access", value: formData.access, label: "Priority", onChange: (e) => {
                                        onFormChange('priority', e.target.value);
                                    }, children: accessList.map((item) => (_jsx(MenuItem, { value: item.value, children: item.label }, item.value))) })] }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Type" }), _jsx(Select, { id: "select-type", value: formData.type, label: "Type", onChange: (e) => {
                                        onFormChange('type', e.target.value);
                                    }, children: typeList.map((item) => (_jsx(MenuItem, { value: item.value, children: item.label }, item.value))) })] }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: [_jsx(InputLabel, { children: "Carrier" }), _jsx(Select, { id: "select-carrier", value: formData.carrier_id, label: "Carrier", onChange: (e) => {
                                        onFormChange('carrier_id', e.target.value);
                                    }, children: (companies || []).map((c) => (_jsx(MenuItem, { value: c.str_id, children: _jsx(Box, { sx: {
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: 1,
                                            }, children: _jsx(Box, { sx: { flex: 1 }, children: c.company_name }) }) }, c.str_id))) })] })] }) }), _jsxs(DialogActions, { sx: { pt: 0, pb: 2, px: 2 }, children: [_jsx(Button, { onClick: () => setOpen(false), children: "Cancel" }), _jsx(LoadingButton, { onClick: onSubmit, loading: uploading, variant: "contained", sx: { width: '100px' }, children: "Save" })] })] }));
};
export default ActionModal;
