import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LoadingButton } from '@mui/lab';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';
export default function SplitButton({ startIcon = null, options = [], disabled = false, variant = 'outlined', loading = false, useLoadingBtn = false, }) {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const anchorRef = useRef(null);
    const handleClick = (option) => {
        try {
            setIsLoading(true);
            option.onClick();
        }
        catch (e) {
            console.error('Error: ', e);
        }
        finally {
            setIsLoading(false);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = (event) => {
        var _a;
        if ((_a = anchorRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ButtonGroup, { ref: anchorRef, disabled: disabled || isLoading, variant: variant, disableElevation: true, sx: { height: 'fit-content' }, children: [useLoadingBtn ? (_jsx(LoadingButton, { onClick: () => handleClick(options[0]), startIcon: startIcon, loading: loading, loadingPosition: "start", children: options[0].label })) : (_jsx(Button, { onClick: () => handleClick(options[0]), startIcon: startIcon, children: options[0].label })), options.length > 1 && (_jsx(Button, { onClick: handleToggle, sx: { pl: 0, pr: 0.2, minWidth: 24, width: 24 }, children: _jsx(ArrowDropDownIcon, {}) }))] }), _jsx(Popper, { sx: {
                    zIndex: 10000,
                }, open: open, anchorEl: anchorRef.current, role: undefined, transition: true, disablePortal: true, children: ({ TransitionProps, placement }) => (_jsx(Grow, Object.assign({}, TransitionProps, { style: {
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }, children: _jsx(Paper, { elevation: 5, children: _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(MenuList, { id: "split-button-menu", autoFocusItem: true, sx: { py: 0 }, children: options
                                    .filter((option, i) => i !== 0)
                                    .map((option) => {
                                    var _a, _b, _c;
                                    return (_jsx(Tooltip, { title: (_b = (_a = option === null || option === void 0 ? void 0 : option.options) === null || _a === void 0 ? void 0 : _a.tooltip) !== null && _b !== void 0 ? _b : '', children: _jsx("span", { children: _jsx(MenuItem, { onClick: () => {
                                                    handleClick(option);
                                                    setOpen(false);
                                                }, disabled: (_c = option === null || option === void 0 ? void 0 : option.options) === null || _c === void 0 ? void 0 : _c.disabled, children: option.label }, option.id) }) }, option.id));
                                }) }) }) }) }))) })] }));
}
