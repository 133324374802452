import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { InfoOutlined, SyncDisabled, SyncLock } from '@mui/icons-material';
import { Tooltip, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, } from '@mui/material';
import { FieldTypes } from '@/types';
export const SyncEndAdornment = ({ syncedFields, syncId, fieldId, data, fieldType: type, onChange, }) => {
    var _a, _b;
    const [openDialog, setOpenDialog] = useState(false);
    if (!syncId || !(syncedFields === null || syncedFields === void 0 ? void 0 : syncedFields.includes(fieldId))) {
        return null;
    }
    const isOverridden = (_b = (_a = data === null || data === void 0 ? void 0 : data.config) === null || _a === void 0 ? void 0 : _a.overrideFields) === null || _b === void 0 ? void 0 : _b.includes(fieldId);
    const icon = isOverridden ? _jsx(SyncDisabled, {}) : _jsx(SyncLock, {});
    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpenDialog(true);
    };
    const handleConfirm = () => {
        var _a, _b;
        const overrideFields = (_b = (_a = data.config) === null || _a === void 0 ? void 0 : _a.overrideFields) !== null && _b !== void 0 ? _b : [];
        let newOverrideFields;
        if (overrideFields.includes(fieldId)) {
            newOverrideFields = overrideFields.filter((id) => id !== fieldId);
        }
        else {
            newOverrideFields = [...overrideFields, fieldId];
        }
        onChange(newOverrideFields);
        setOpenDialog(false);
    };
    const handleCancel = () => {
        setOpenDialog(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: isOverridden
                    ? 'Sync is disabled for this field, click to enable. Current value will be replaced in the next sync.'
                    : 'Sync is enabled for this field, click to disable. You will be able to manually set value.', sx: {
                    display: 'flex',
                    justifyContent: 'center',
                }, children: _jsx(Box, { onClick: handleClick, style: {
                        position: 'relative',
                        left: type === FieldTypes.DATE ? 0 : -16,
                        cursor: 'pointer',
                    }, children: icon }) }), _jsxs(Dialog, { open: openDialog, onClose: handleCancel, children: [_jsxs(DialogTitle, { sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }, children: [isOverridden ? 'Enable sync?' : 'Disable sync?', _jsx(Tooltip, { title: isOverridden
                                    ? 'Existing will be replaced in the next sync'
                                    : 'You can resume syncing by toggling the sync button on this field', children: _jsx(InfoOutlined, { sx: { color: '#aaa' } }) })] }), _jsxs(DialogContent, { children: ["Are you sure you want to ", isOverridden ? 'enable' : 'disable', " sync for", ' ', _jsx(Typography, { component: "span", sx: { fontWeight: 500 }, children: fieldId }), "?"] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleCancel, children: "Cancel" }), _jsxs(Button, { onClick: handleConfirm, autoFocus: true, variant: "contained", children: [isOverridden ? 'Enable' : 'Disable', " sync"] })] })] })] }));
};
