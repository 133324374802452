import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useMemo } from 'react';
export const LoadingContext = createContext({
    loadingConfig: {
        loading: false,
        message: '',
        delayToClose: 0,
        lock: false,
        unlock: false,
        allowClose: false,
    },
    setLoadingConfig: (e) => { },
});
const LoadingProvider = ({ children }) => {
    const [loadingConfig, setLoadingConfig] = useState({
        loading: false,
        message: '',
        delayToClose: 0,
    });
    const store = useMemo(() => {
        return {
            setLoadingConfig,
            loadingConfig,
        };
    }, [loadingConfig]);
    return (_jsx(LoadingContext.Provider, { value: store, children: children }));
};
export default LoadingProvider;
