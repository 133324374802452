import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, IconButton, } from '@mui/material';
import { RulesOperators } from 'common/globalTypes';
import { FieldTypes } from '@/types';
import FieldMatcherComponent from '@/components/molecules/FieldMatcherComponent';
import { EnhancedSelect } from './EnhancedSelect';
const isNumeric = (type) => [FieldTypes.CURRENCY, FieldTypes.PERCENTAGE, FieldTypes.INTEGER].includes(type);
const isDate = (type) => {
    return [FieldTypes.DATE].includes(type);
};
// TODO: Add validations, enums and conditional rendering for options based on field types
export const fieldMatcherOptions = [
    { id: RulesOperators.EQ, label: 'Equals', shortLabel: '=' },
    { id: RulesOperators.NEQ, label: 'Not equals', shortLabel: '!=' },
    {
        id: RulesOperators.GT,
        label: 'Greater than',
        shortLabel: '>',
        validate: isNumeric,
    },
    {
        id: RulesOperators.LT,
        label: 'Less than',
        shortLabel: '<',
        validate: isNumeric,
    },
    {
        id: RulesOperators.GTE,
        label: 'Greater than or equals',
        shortLabel: '>=',
        validate: isNumeric,
    },
    {
        id: RulesOperators.LTE,
        label: 'Less than or equals',
        shortLabel: '>=',
        validate: isNumeric,
    },
    { id: RulesOperators.CONTAINS, label: 'Contains' },
    { id: RulesOperators.NCONTAINS, label: 'Not contains' },
    { id: RulesOperators.CONTAINEDIN, label: 'Contained in' },
    { id: RulesOperators.NCONTAINEDIN, label: 'Not contained in' },
    { id: RulesOperators.STARTSWITH, label: 'Starts with' },
    { id: RulesOperators.ENDSWITH, label: 'Ends with' },
    { id: RulesOperators.BEFORE, label: 'Before', validate: isDate },
    {
        id: RulesOperators.BEFORE_EQUALS,
        label: 'Before or equals',
        validate: isDate,
    },
    { id: RulesOperators.AFTER, label: 'After', validate: isDate },
    {
        id: RulesOperators.AFTER_EQUALS,
        label: 'After or equals',
        validate: isDate,
    },
    { id: RulesOperators.IS_EMPTY, label: 'Is empty' },
    { id: RulesOperators.WITHIN_ONE_YEAR, label: 'Within one year' },
    { id: RulesOperators.AT_LEAST_ONE_YEAR, label: 'At least one year' },
    { id: RulesOperators.UNKNOWN, label: 'Date range unknown' },
];
const FieldMatcher = ({ fields, value: fieldsMatchers = [], setValue, addLabel = 'Add', hideUsePolicyData = false, sx = {}, }) => {
    const getSelectedField = (fieldId) => {
        return fields.find((f) => f.id === fieldId);
    };
    const booleanOptions = [
        { label: '', value: '' },
        { label: 'True', value: true },
        { label: 'False', value: false },
    ];
    return (_jsxs(Box, { sx: Object.assign({}, sx), children: [fieldsMatchers === null || fieldsMatchers === void 0 ? void 0 : fieldsMatchers.map((fieldMatcher, i) => fieldMatcher.type !== 'Action' && (_jsxs(Box, { sx: {
                    mt: 1,
                    display: 'flex',
                    flexWrap: 'no-wrap',
                    width: '100%',
                }, children: [_jsx(EnhancedSelect, { enableSearch: true, label: "Field", options: fields === null || fields === void 0 ? void 0 : fields.sort((a, b) => (b.label > a.label ? -1 : 1)), labelKey: "label", value: fields.find((item) => item.id === fieldMatcher.field), onChange: (item) => {
                            const newFieldMatchers = [...fieldsMatchers];
                            newFieldMatchers[i].field = item.id;
                            setValue(newFieldMatchers);
                        }, sx: { marginRight: 1 } }), _jsx(EnhancedSelect, { label: "Operation", options: fieldMatcherOptions.filter((op) => {
                            var _a;
                            const selectedField = getSelectedField((_a = fieldMatcher === null || fieldMatcher === void 0 ? void 0 : fieldMatcher.field) === null || _a === void 0 ? void 0 : _a.id);
                            if (op.validate &&
                                selectedField &&
                                !op.validate(selectedField === null || selectedField === void 0 ? void 0 : selectedField.type)) {
                                return false;
                            }
                            return true;
                        }), labelKey: "label", value: fieldMatcherOptions.find((item) => item.id === fieldMatcher.op), onChange: (item) => {
                            const newFieldMatchers = [...fieldsMatchers];
                            newFieldMatchers[i].op = item.id;
                            setValue(newFieldMatchers);
                        }, sx: { marginRight: 1 } }), _jsx(FieldMatcherComponent, { fields: fields, fieldMatcher: fieldMatcher, fieldsMatchers: fieldsMatchers, i: i, setValue: setValue }, i), _jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(EnhancedSelect, { label: "Case sensitivity", valueKey: "value", value: booleanOptions.find((item) => item.value === fieldMatcher.caseSensitive), options: booleanOptions, onChange: (v) => {
                                    const newFieldMatchers = [...fieldsMatchers];
                                    newFieldMatchers[i].caseSensitive = v.value;
                                    setValue(newFieldMatchers);
                                } }), !hideUsePolicyData && (_jsx(EnhancedSelect, { label: "Use policy data", valueKey: "value", value: booleanOptions.find((item) => item.value === fieldMatcher.usePolicyData), options: booleanOptions, onChange: (v) => {
                                    const newFieldMatchers = [...fieldsMatchers];
                                    newFieldMatchers[i].usePolicyData = v.value;
                                    setValue(newFieldMatchers);
                                } })), _jsx(EnhancedSelect, { label: "Skip empty", valueKey: "value", value: booleanOptions.find((item) => item.value === fieldMatcher.skipEmpty), options: booleanOptions, onChange: (v) => {
                                    const newFieldMatchers = [...fieldsMatchers];
                                    newFieldMatchers[i].skipEmpty = v.value;
                                    setValue(newFieldMatchers);
                                } }), _jsx(Box, { children: _jsx(IconButton, { onClick: () => {
                                        const newFieldMatchers = [...fieldsMatchers];
                                        newFieldMatchers.splice(i, 1);
                                        setValue(newFieldMatchers);
                                    }, children: _jsx(RemoveCircleOutline, {}) }) })] })] }, i))), _jsx(Button, { onClick: () => setValue([...fieldsMatchers, {}]), sx: { mt: 0.5 }, children: addLabel })] }));
};
export default FieldMatcher;
