var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Add, Download, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Chip, IconButton, Typography, useMediaQuery, } from '@mui/material';
import { numberOrDefault } from 'common/helpers';
import { LoadingContext } from 'contexts/LoadingContext';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DataBulkAdd from '@/components/DataBulkAdd';
import DataBulkAddCompGrids from '@/components/DataBulkAdd/DataBulkAddCompGrids';
import DataForm from '@/components/DataForm';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import EnhancedTable from '@/components/molecules/EnhancedTable';
import MultiSelect from '@/components/molecules/MultiSelect';
import SearchBox from '@/components/molecules/SearchBox';
import SplitButton from '@/components/molecules/SplitButton';
import useSnackbar from '@/contexts/useSnackbar';
import { auth } from '@/firebase';
import API from '@/services/API';
import { exportToCsv } from '@/services/helpers';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';
import { useFilterParams } from './hooks/useFilterParams';
const DataView = ({ dataDesc, formModeOnly = false, viewOnly = false, hideExport, refresh, extraActions, dataCallback, sx = {}, enableAutoRefetch = true, embed = false, readOnly = false, exportOptions = [], 
// Use them to trigger a callback in the partent component with any custom export logic
customExport = false, customExportCallback = () => { }, handleCustomExportOptions, rowKey = '', setSelectedData = (a) => { }, onDelete = null, onBulkEdit = () => { }, customHeaderActions = false, enablePagination = false, customQueryParams = [], headingOffset = 98, variant = '', }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const { showSnackbar } = useSnackbar();
    const { filterValues, setFilters, filterParams } = useFilterParams({
        filters: dataDesc.filters,
    });
    const isMobile = useMediaQuery('(max-width:600px)');
    const [searchParams, setSearchParams] = useSearchParams({});
    const mode = (_a = searchParams.get('m')) !== null && _a !== void 0 ? _a : 'list';
    const embedMode = (_b = searchParams.get('em')) !== null && _b !== void 0 ? _b : 'list';
    const [newData, setNewData] = useState({});
    const [oldData, setOldData] = useState({});
    // Can put editingData and originData in the same state
    const [editingData, setEditingData] = useState({});
    const [originData, setOriginData] = useState({});
    const [isDownloading, setIsDownloading] = useState(false);
    const [qcExpanded, setQcExpanded] = useState(false);
    const [selectedExportOptions, setSelectedExportOptions] = useState({});
    const { userRole } = useRoleStore();
    const orderBy = (_c = searchParams.get('order_by')) !== null && _c !== void 0 ? _c : 'created_at';
    const order = (_d = searchParams.get('order')) !== null && _d !== void 0 ? _d : 'desc';
    const page = numberOrDefault(searchParams.get('page'), 0);
    const rowsPerPage = numberOrDefault(searchParams.get('limit'), 50);
    // const [additionalQueryParams, setParams] = useState({})
    let additionalQueryParams = filterParams;
    const idParam = searchParams.get('id');
    const [embededId, setEmbededId] = useState(null);
    Array.from(searchParams.entries()).forEach(([key, value]) => {
        additionalQueryParams += `&${key}=${value}`;
    });
    if (enablePagination) {
        if (!additionalQueryParams.includes('page'))
            additionalQueryParams += `&page=${page}`;
        if (!additionalQueryParams.includes('limit'))
            additionalQueryParams += `&limit=${rowsPerPage}`;
        additionalQueryParams += `&orderBy=${orderBy}&sort=${order}`;
    }
    if (dataDesc.queryChips && searchParams.get('qc')) {
        const chipQuery = (_f = (_e = dataDesc.queryChips[searchParams.get('qc')]) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : {};
        Object.entries(chipQuery).forEach(([k, v]) => {
            if (v instanceof Array) {
                v.forEach((e) => {
                    additionalQueryParams += `&${k}=${encodeURIComponent(e)}`;
                });
            }
            else {
                additionalQueryParams += `&${k}=${encodeURIComponent(v)}`;
            }
        });
    }
    customQueryParams.forEach((param) => {
        if (searchParams.get(param))
            additionalQueryParams += `&${param}=${searchParams.get(param)}`;
    });
    const searchQuery = searchParams.get('q');
    useEffect(() => {
        setSearchParams((prev) => {
            prev.delete('page');
            return prev;
        });
    }, [searchQuery]);
    useEffect(() => {
        const params = new URLSearchParams(additionalQueryParams);
        params.set('page', page.toString());
        params.set('limit', rowsPerPage.toString());
        additionalQueryParams = `?${params.toString()}`;
    }, [page, rowsPerPage]);
    let { isLoading, data, refetch } = API.getBasicQuery(dataDesc.table, additionalQueryParams, enableAutoRefetch);
    let count = 0;
    if (enablePagination) {
        count = data === null || data === void 0 ? void 0 : data.count;
        data = data === null || data === void 0 ? void 0 : data.data;
    }
    useEffect(() => {
        if (!isEqual(newData, editingData) && isEqual(newData, originData)) {
            setNewData(Object.assign(Object.assign({}, newData), editingData));
        }
    }, [originData]);
    const handleChangePage = (event, newPage) => __awaiter(void 0, void 0, void 0, function* () {
        setSearchParams((prev) => {
            if (newPage && +newPage > 0) {
                prev.set('page', newPage);
            }
            else {
                prev.delete('page');
            }
            return prev;
        });
    });
    const handleChangeRowsPerPage = (event) => {
        setSearchParams((prev) => {
            prev.delete('page');
            prev.set('limit', event.target.value);
            return prev;
        });
    };
    const { setLoadingConfig } = useContext(LoadingContext);
    const updateSearchParams = (kvMap) => setSearchParams((prev) => {
        Object.entries(kvMap).forEach(([k, v]) => {
            if ([undefined, null, ''].includes(v)) {
                prev.delete(k);
            }
            else {
                prev.set(k, v);
            }
        });
        return prev;
    });
    useEffect(() => {
        let _data;
        let _count;
        if (enablePagination) {
            _data = data === null || data === void 0 ? void 0 : data.data;
            _count = data === null || data === void 0 ? void 0 : data.count;
        }
        else {
            _data = data;
        }
        if (formModeOnly) {
            const defaultData = _data ||
                dataDesc.fields
                    .filter((field) => field.default !== undefined)
                    .reduce((acc, cur) => (Object.assign(Object.assign({}, acc), { [cur.id]: cur.default })), {});
            setNewData(defaultData);
            setOldData(JSON.parse(JSON.stringify(defaultData)));
        }
        if (_count !== undefined) {
            count = _count;
        }
    }, [data, dataDesc.fields, formModeOnly, enablePagination]);
    useEffect(() => {
        if (mode === 'edit' &&
            idParam &&
            Array.isArray(data) &&
            data.length === 1) {
            setNewData(data[0]);
            setOriginData(data[0]);
            setOldData(JSON.parse(JSON.stringify(data[0])));
        }
    }, [data, mode, idParam]);
    useEffect(() => {
        if (refresh) {
            setTimeout(refetch, 100);
        }
    }, [refresh, refetch]);
    const poster = API.getMutation(dataDesc.table, 'POST');
    const apiPath = useMemo(() => {
        let url = dataDesc.table;
        if (url.includes('?')) {
            url = url.split('?')[0];
        }
        return url;
    }, [dataDesc.table]);
    // @ts-ignore
    const posterBulk = API.getMutation(`${apiPath}/bulk_add`, 'POST');
    const patcher = API.getMutation(dataDesc.table, 'PATCH');
    const deleter = API.getMutation(dataDesc.table, 'DELETE');
    /**
     * Download CSV
     */
    const downloadCsvFn = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const idToken = (yield ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken(true))) || '';
        yield exportToCsv({}, {
            idToken,
            endpoint: dataDesc.table,
            exportOptions: selectedExportOptions,
        });
    }), [dataDesc.table, selectedExportOptions]);
    useEffect(() => {
        const doDownload = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield downloadCsvFn();
            }
            catch (e) {
                console.error(e);
                showSnackbar('Error exporting data', 'error');
            }
            finally {
                setIsDownloading(false);
                setLoadingConfig({
                    loading: false,
                });
            }
        });
        if (isDownloading) {
            doDownload();
        }
    }, [downloadCsvFn, isDownloading, setLoadingConfig]);
    let queryFields = dataDesc.fields
        .flat()
        .filter((field) => field.queryable && field.type !== 'boolean')
        .map((field) => field.id);
    queryFields =
        queryFields.length > 0
            ? queryFields
            : dataDesc.fields
                .filter((field) => field.type !== 'boolean')
                .map((field) => field.id);
    const dataFiltered = Array.isArray(data)
        ? data === null || data === void 0 ? void 0 : data.filter((datum) => {
            let query = searchParams.get('q');
            if (enablePagination)
                query = null;
            if (!query)
                return true;
            return Object.entries(datum)
                .filter(([k, v]) => queryFields.includes(k))
                .some(([key, value]) => (typeof value === 'object'
                ? JSON.stringify(value !== null && value !== void 0 ? value : {})
                : (value !== null && value !== void 0 ? value : '').toString())
                .toLowerCase()
                .includes(query.trim().toLowerCase()));
        })
        : data;
    const DataFormer = (_jsx(DataForm, { dataDesc: dataDesc, fields: dataDesc.fields.filter((field) => field.condition ? field.condition(newData) : true), newData: newData, oldData: oldData, setNewData: (data) => {
            setNewData(data);
            setEditingData(data);
        }, onCancel: () => {
            setNewData({});
            setEditingData({});
            updateSearchParams(embed ? { em: null } : { m: null, id: null });
            embed && setEmbededId(null);
        }, onSave: () => __awaiter(void 0, void 0, void 0, function* () {
            let dbData = null;
            const dataDescFields = dataDesc.fields.flat();
            const normalizedNewData = Object.fromEntries(Object.entries(newData).map(([key, value]) => {
                var _a;
                return [
                    key,
                    ((_a = dataDescFields.find((field) => field.id === key)) === null || _a === void 0 ? void 0 : _a.normalizer)
                        ? dataDescFields
                            .find((field) => field.id === key)
                            .normalizer(value)
                        : value,
                ];
            }));
            if (normalizedNewData.id) {
                dbData = yield patcher.mutateAsync(normalizedNewData);
            }
            else {
                dbData = yield poster.mutateAsync(normalizedNewData);
            }
            if (dbData.error) {
                showSnackbar(dbData.error, 'error');
                return;
            }
            if (dataCallback)
                yield dataCallback(dbData);
            setNewData({});
            setEditingData({});
            updateSearchParams(embed ? { em: null } : { m: null, id: null });
            embed && setEmbededId(null);
            setTimeout(refetch, 200);
            return dbData;
        }), onDelete: () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                yield deleter.mutateAsync({
                    ids: [newData.id],
                    strId: (_a = newData.str_id) !== null && _a !== void 0 ? _a : '',
                });
                setNewData({});
                setEditingData({});
                updateSearchParams(embed ? { em: null } : { m: null, id: null });
                embed && setEmbededId(null);
            }
            catch (e) {
                console.error(e);
                // @ts-ignore
                showSnackbar(`Error deleting data: ${e.message}`, 'error');
            }
            finally {
                setTimeout(refetch, 200);
            }
        }), validateData: dataDesc.validateData, formModeOnly: formModeOnly, embed: embed, readOnly: readOnly }));
    const DataBulkAdder = (_jsx(DataBulkAdd, { fields: dataDesc.fields
            .flat()
            .filter((field) => [undefined, 'select', 'dynamic-select', 'boolean', 'date'].includes(field.type) &&
            field.access !== 'admin' &&
            !field.readOnly &&
            !field.bulkAddUnsupported), onCancel: () => {
            updateSearchParams(embed ? { em: null } : { m: null });
        }, onSave: (jsonEntities) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const res = yield posterBulk.mutateAsync(jsonEntities);
            if (res.statusText === 'ok') {
                showSnackbar(`Added ${(_a = res === null || res === void 0 ? void 0 : res.stats) === null || _a === void 0 ? void 0 : _a.current_length} records`, 'success');
                updateSearchParams(embed ? { em: null } : { m: null });
                setTimeout(refetch, 200);
            }
            else {
                showSnackbar('Error bulk adding data', 'error');
            }
        }) }));
    const DataBulkAdderCompGrids = (_jsx(DataBulkAddCompGrids, { fields: dataDesc.fields
            .flat()
            .filter((field) => [undefined, 'select', 'dynamic-select', 'boolean', 'date'].includes(field.type) &&
            field.access !== 'admin' &&
            !field.readOnly &&
            !field.bulkAddUnsupported), onCancel: () => {
            updateSearchParams(embed ? { em: null } : { m: null });
        }, onSave: (jsonEntities) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const res = yield posterBulk.mutateAsync(jsonEntities);
            if (res.statusText === 'ok') {
                showSnackbar(`Added ${(_a = res === null || res === void 0 ? void 0 : res.stats) === null || _a === void 0 ? void 0 : _a.current_length} records`, 'success');
                updateSearchParams(embed ? { em: null } : { m: null });
                setTimeout(refetch, 200);
            }
            else {
                showSnackbar(`Error bulk adding records ${res.error ? ` (${res.error}})` : ''}`, 'error');
            }
        }) }));
    const onExport = useCallback((options) => {
        setSelectedExportOptions(options);
        setIsDownloading(true);
        setLoadingConfig({
            loading: true,
            message: 'Exporting...',
        });
    }, [setLoadingConfig, setSelectedExportOptions]);
    useEffect(() => {
        if (customExport) {
            handleCustomExportOptions(onExport);
        }
    }, [handleCustomExportOptions, customExport, onExport]);
    return (_jsxs(Box, { sx: Object.assign({
            width: formModeOnly
                ? 'inherit'
                : `calc(100vw - ${isMobile ? '0px' : '200px'})`,
            overflowY: embed ? 'auto' : 'scroll',
        }, sx), children: [_jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    pt: embed ? 0 : 2,
                    px: 2,
                    pb: embed ? 0 : 1,
                }, children: [_jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(Typography, { variant: "h5", children: !['tabbed'].includes(variant) && dataDesc.label }), mode !== 'add' && !formModeOnly && (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [(idParam || embededId) && (_jsx(Chip, { label: !embed ? idParam : embededId, sx: { mr: 1 }, onDelete: () => {
                                            setNewData({});
                                            updateSearchParams(embed ? { em: null } : { m: null, id: null });
                                            embed && setEmbededId(null);
                                        }, 
                                        // variant="outlined"
                                        color: "primary" })), _jsx(SearchBox, { id: dataDesc.table })] }))] }), !['add', 'bulkAdd'].includes(mode) && !formModeOnly && (_jsxs(Box, { className: "mt-2 flex justify-between items-start", children: [_jsxs(Box, { id: "filter-chips", children: [((_h = Object.keys((_g = dataDesc === null || dataDesc === void 0 ? void 0 : dataDesc.filterConfigs) !== null && _g !== void 0 ? _g : {})) === null || _h === void 0 ? void 0 : _h.length) > 0
                                        ? Object.entries((_j = dataDesc === null || dataDesc === void 0 ? void 0 : dataDesc.filterConfigs) !== null && _j !== void 0 ? _j : {}).map(([filterId, filterConfig]) => {
                                            var _a, _b;
                                            return (_jsx(MultiSelect, { label: filterConfig.label, values: Object.values((_a = filterConfig.options) !== null && _a !== void 0 ? _a : {}), selectedValues: searchParams.getAll(filterId).length
                                                    ? searchParams.getAll(filterId)
                                                    : Object.values((_b = filterConfig.options) !== null && _b !== void 0 ? _b : {}).map((o) => o.str_id), enableSearch: true, setSelectedValues: (values) => {
                                                    setSearchParams((prev) => {
                                                        var _a;
                                                        prev.delete(filterId);
                                                        if (Object.values((_a = filterConfig.options) !== null && _a !== void 0 ? _a : {})
                                                            .length !== values.length) {
                                                            values.forEach((v) => {
                                                                if (v !== '') {
                                                                    prev.append(filterId, v);
                                                                }
                                                                else {
                                                                    values.forEach((v) => {
                                                                        prev.append(filterId, v);
                                                                    });
                                                                }
                                                            });
                                                        }
                                                        return prev;
                                                    });
                                                }, valuer: (o) => o.str_id, formatter: (o) => o.label, sorted: true, sx: { minWidth: 120, maxWidth: 240 } }, `filter-${filterId}`));
                                        })
                                        : null, (_l = Object.values((_k = dataDesc.queryChips) !== null && _k !== void 0 ? _k : {})) === null || _l === void 0 ? void 0 : _l.filter((val, i) => qcExpanded || i < 10).sort((a, b) => {
                                        var _a, _b;
                                        return ['all', 0, '0'].includes(a.id) || a.label === 'All'
                                            ? -1
                                            : ['all', 0, '0'].includes(b.id) || b.label === 'All'
                                                ? 1
                                                : ((_a = a.label) !== null && _a !== void 0 ? _a : '').localeCompare((_b = b.label) !== null && _b !== void 0 ? _b : '');
                                    }).map((chip) => {
                                        var _a, _b;
                                        return (_jsx(Chip, { label: chip.label, onClick: () => {
                                                // Restart pagination when changing query chips
                                                setSearchParams((prev) => {
                                                    if (['all', 0, '0'].includes(chip.id)) {
                                                        prev.delete('qc');
                                                    }
                                                    else {
                                                        prev.set('qc', chip.id);
                                                    }
                                                    return prev;
                                                });
                                            }, sx: {
                                                mr: 0.5,
                                                my: ((_b = Object.keys((_a = dataDesc === null || dataDesc === void 0 ? void 0 : dataDesc.filterConfigs) !== null && _a !== void 0 ? _a : {})) === null || _b === void 0 ? void 0 : _b.length) > 0
                                                    ? 1.5
                                                    : 0.25,
                                                cursor: 'pointer',
                                            }, color: searchParams.get('qc') === chip.id ||
                                                (!searchParams.get('qc') &&
                                                    ['all', 0, '0'].includes(chip.id))
                                                ? 'primary'
                                                : 'default', variant: searchParams.get('qc') === chip.id ||
                                                (!searchParams.get('qc') &&
                                                    ['all', 0, '0'].includes(chip.id))
                                                ? 'filled'
                                                : 'outlined' }, chip.id));
                                    }), dataDesc.queryChipsType !== 'select' &&
                                        ((_o = Object.values((_m = dataDesc.queryChips) !== null && _m !== void 0 ? _m : {})) === null || _o === void 0 ? void 0 : _o.length) > 10 && (_jsx(IconButton, { onClick: () => setQcExpanded(!qcExpanded), children: qcExpanded ? (_jsx(ExpandLess, { sx: { width: 16, height: 16 } })) : (_jsx(ExpandMore, { sx: { width: 16, height: 16 } })) }))] }), _jsxs(Box, { sx: {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }, children: [_jsxs(Box, { sx: {
                                            display: 'flex',
                                            alignItems: 'center',
                                        }, children: [extraActions ? _jsx(Box, { sx: { ml: 1 }, children: extraActions }) : null, !(viewOnly || formModeOnly) && (_jsx(SplitButton, { startIcon: _jsx(Add, {}), options: dataDesc.bulkAdd &&
                                                    [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST].includes(userRole)
                                                    ? [
                                                        {
                                                            id: 'add',
                                                            label: 'Add',
                                                            onClick: () => {
                                                                setNewData({});
                                                                updateSearchParams({ m: 'add' });
                                                            },
                                                        },
                                                        {
                                                            id: 'bulkAdd',
                                                            label: 'Bulk add',
                                                            onClick: () => updateSearchParams({ m: 'bulkAdd' }),
                                                        },
                                                    ]
                                                    : [
                                                        {
                                                            id: 'add',
                                                            label: 'Add',
                                                            onClick: () => {
                                                                setNewData({});
                                                                updateSearchParams({ m: 'add' });
                                                            },
                                                        },
                                                    ], disabled: ['add', 'edit'].includes(mode), variant: "contained" }))] }), !hideExport && (_jsx(Box, { sx: { ml: 1 }, children: customExport ? (_jsx(_Fragment, { children: _jsx(Button, { onClick: () => customExportCallback(), variant: "outlined", children: "Export" }) })) : (_jsx(SplitButton, { startIcon: _jsx(Download, {}), options: exportOptions.length > 0
                                                ? exportOptions.map((e) => (Object.assign(Object.assign({}, e), { onClick: () => onExport(e.options), key: e.id })))
                                                : [
                                                    {
                                                        id: 'export',
                                                        label: 'Export',
                                                        onClick: onExport,
                                                        key: 'export',
                                                    },
                                                ], disabled: isDownloading })) }))] })] }))] }), _jsx(Box, { children: (embed
                    ? ['add', 'embed-edit'].includes(embedMode)
                    : ['add', 'edit'].includes(mode)) || formModeOnly ? (_jsx(Box, { sx: {
                        overflowY: embed ? 'auto' : 'scroll',
                        height: embed
                            ? 'inherit'
                            : `calc(100vh - ${(headingOffset !== null && headingOffset !== void 0 ? headingOffset : 0) + 74}px)`,
                        display: 'flex',
                        justifyContent: 'center',
                        py: embed ? 0 : 2,
                        px: 2,
                    }, children: DataFormer })) : mode === 'bulkAdd' ? (_jsx(Box, { sx: {
                        overflowY: 'scroll',
                        height: embed ? 'inherit' : 'calc(100vh - 168px)',
                        display: 'flex',
                        justifyContent: 'center',
                        px: 2,
                        pb: 2,
                    }, children: dataDesc.label !== 'Agent commission schedule profiles'
                        ? DataBulkAdder
                        : DataBulkAdderCompGrids })) : isLoading ? (_jsx(LoadingCircle, {})) : (_jsx(_Fragment, { children: _jsx(EnhancedTable, Object.assign({ dense: true, headers: dataDesc.fields, rows: dataFiltered, rowKey: rowKey, onEdit: dataDesc.editable
                            ? (row) => {
                                updateSearchParams(embed
                                    ? { em: 'embed-edit' }
                                    : { m: 'edit', id: row.str_id });
                                embed && setEmbededId(row.str_id);
                                setNewData(row);
                                setOldData(JSON.parse(JSON.stringify(row)));
                            }
                            : null, actionsEnabled: () => Array.isArray(dataDesc.actions) && dataDesc.actions.length > 0, actions: dataDesc.actions, refetch: refetch, setSelectedData: setSelectedData, onDelete: onDelete, onBulkEdit: onBulkEdit, customHeaderActions: customHeaderActions, paginated: enablePagination, headingOffset: headingOffset, controlledOrdering: {
                            order,
                            orderBy,
                            setOrder: (e) => {
                                setSearchParams((prev) => {
                                    prev.delete('page');
                                    prev.set('order', e);
                                    return prev;
                                });
                            },
                            setOrderBy: (e) => {
                                setSearchParams((prev) => {
                                    prev.delete('page');
                                    prev.set('order_by', e);
                                    return prev;
                                });
                            },
                        } }, (enablePagination
                        ? {
                            controlledPagination: {
                                count,
                                page,
                                onPageChange: handleChangePage,
                                rowsPerPage,
                                onRowsPerPageChange: handleChangeRowsPerPage,
                            },
                        }
                        : {}))) })) })] }));
};
DataView.propTypes = {
    dataDesc: PropTypes.object,
    formModeOnly: PropTypes.bool,
    viewOnly: PropTypes.bool,
    hideExport: PropTypes.bool,
    refresh: PropTypes.number,
    extraActions: PropTypes.node,
    dataCallback: PropTypes.func,
    onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
    handleCustomExportOptions: PropTypes.func,
};
export default DataView;
