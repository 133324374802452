import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, styled } from '@mui/material';
import ContactCard from '@/components/contacts/ContactGroupsView/ContactCard';
const LineBox = styled(Box)(({ theme, 'data-hasparents': hasParents, 'data-haschildren': hasChildren, }) => ({
    position: 'relative',
    border: hasParents || hasChildren ? `1px solid ${theme.palette.divider}` : 'none',
    padding: '15px 15px 15px 15px',
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    justifyContent: 'center',
    // TODO: Tweak the line styles to show up as expected
    // '&::before': hasParents
    //   ? {
    //       content: '""',
    //       position: 'absolute',
    //       top: '0',
    //       left: '0',
    //       right: '0',
    //       height: '1px',
    //       borderTop: `1px solid ${theme.palette.divider}`,
    //       transform: 'translateY(-50%)',
    //     }
    //   : {},
    // '&::after': hasChildren
    //   ? {
    //       content: '""',
    //       position: 'absolute',
    //       bottom: '0',
    //       left: '0',
    //       right: '0',
    //       height: '1px',
    //       borderBottom: `1px solid ${theme.palette.divider}`,
    //       transform: 'translateY(50%)',
    //     }
    //   : {},
}));
const HierarchyDiagram = ({ contact, handleItemClick }) => {
    const { id, child_relationships } = contact;
    let { parent_relationships } = contact;
    if (parent_relationships &&
        !parent_relationships.some((rel) => rel.parent !== null)) {
        parent_relationships = undefined;
    }
    return (_jsxs(Box, { overflow: "auto", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "85vh", width: "100%", gap: 1, mb: 2, children: [_jsx(Box, { display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", flexWrap: "wrap", overflow: "auto", height: "auto", gap: 1, mb: 2, children: _jsx(LineBox, { "data-hasparents": (parent_relationships === null || parent_relationships === void 0 ? void 0 : parent_relationships.length) > 0, children: parent_relationships === null || parent_relationships === void 0 ? void 0 : parent_relationships.map((parentRel) => parentRel.parent && (_jsx(Box, { children: _jsx(ContactCard, { contact: parentRel.parent, onClick: handleItemClick }) }, parentRel.id))) }) }), _jsx(Box, { display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", flexWrap: "wrap", height: "auto", gap: 1, mb: 2, children: _jsx(ContactCard, { contact: contact, main: true, onClick: handleItemClick }, id) }), _jsx(Box, { display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", flexWrap: "wrap", overflow: "auto", height: "auto", gap: 1, mb: 2, children: _jsx(LineBox, { "data-haschildren": (child_relationships === null || child_relationships === void 0 ? void 0 : child_relationships.length) > 0, children: child_relationships === null || child_relationships === void 0 ? void 0 : child_relationships.map((childRel) => {
                        return (childRel.contact.status !== 'Inactive' && (_jsx(Box, { children: _jsx(ContactCard, { contact: childRel.contact, onClick: handleItemClick }) }, childRel.id)));
                    }) }) })] }));
};
export default HierarchyDiagram;
