import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close, GroupAdd } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';
import { useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';
import { splitCustomerName } from 'common/utils';
import { CustomerType } from 'common/customer/customer.constants';
import useSnackbar from '@/contexts/useSnackbar';
import { useCreateCustomersFromPolicies, useCustomerNames, } from '@/api/customerNames';
import { EnhancedSelect } from '../molecules/EnhancedSelect';
export const CreateCustomersFromPolicies = ({ open, onClose, }) => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const [rowSelection, setRowSelection] = useState({});
    const { data: { total, data } = {}, isLoading, isFetching, refetch, } = useCustomerNames({
        page: pagination.pageIndex,
        limit: pagination.pageSize,
    });
    const mutation = useCreateCustomersFromPolicies();
    const [type, setType] = useState({});
    console.log(type);
    const tableData = useMemo(() => {
        return ((data === null || data === void 0 ? void 0 : data.map((item) => {
            const isGroup = type[item.str_id]
                ? type[item.str_id] === CustomerType.group
                : Boolean(item.group_id);
            const names = isGroup
                ? { company_name: item.customer_name }
                : splitCustomerName(item.customer_name);
            const customer = Object.assign({ policy_str_id: item.str_id, group_id: item.group_id, type: isGroup ? CustomerType.group : CustomerType.individual }, names);
            return customer;
        })) || []);
    }, [data, type]);
    const { showSnackbar } = useSnackbar();
    const columns = useMemo(() => {
        return [
            {
                accessorKey: 'first_name',
                header: 'First name',
                enableHiding: false,
            },
            {
                accessorKey: 'last_name',
                header: 'Last name',
                enableHiding: false,
            },
            {
                accessorKey: 'company_name',
                header: 'Company name',
                enableHiding: false,
            },
            {
                accessorKey: 'type',
                header: 'Type',
                Cell: ({ renderedCellValue, row }) => {
                    return (_jsx(EnhancedSelect, { options: [CustomerType.individual, CustomerType.group], value: renderedCellValue, onChange: (v) => {
                            setType((prev) => (Object.assign(Object.assign({}, prev), { [tableData[row.index].policy_str_id]: v })));
                        } }));
                },
                enableHiding: false,
            },
            {
                accessorKey: 'group_id',
                header: 'Group ID',
                enableHiding: false,
            },
        ];
    }, [tableData]);
    const onSubmit = () => {
        const submitData = Object.keys(rowSelection)
            .map((str_id) => {
            return tableData.find((item) => item.policy_str_id === str_id);
        })
            .filter((item) => !!item);
        mutation.mutate(submitData, {
            onSuccess: () => {
                showSnackbar('Added customers', 'success');
                setRowSelection({});
                refetch();
            },
            onError: () => {
                showSnackbar('Something went wrong, please try again!', 'error');
            },
        });
    };
    const table = useMaterialReactTable({
        columns,
        data: tableData, // Must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        enableRowSelection: true, // Enable some features
        enableGlobalFilter: false, // Turn off a feature
        enableFilters: false,
        enableSorting: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableRowVirtualization: true,
        autoResetPageIndex: false,
        muiTablePaperProps: {
            sx: {
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '100%',
            },
        },
        onRowSelectionChange: setRowSelection,
        getRowId: (row) => row.policy_str_id,
        // Control server side pagination
        onPaginationChange: setPagination,
        state: {
            pagination,
            isLoading: isLoading || isFetching,
            rowSelection,
            density: 'compact',
        },
        manualPagination: true,
        muiPaginationProps: {
            rowsPerPageOptions: [50, 250, 500, 100],
        },
        rowCount: total,
    });
    return (_jsxs(Dialog, { open: open, fullScreen: true, sx: { padding: '20px' }, children: [_jsx(DialogTitle, { children: _jsxs("header", { className: "flex justify-between items-center", children: [_jsxs(Box, { className: "flex justify-between items-center", children: [_jsx(GroupAdd, {}), _jsx(Box, { className: "pl-2", children: "Create customers from policies" })] }), _jsx(Box, { className: "group p-2 cursor-pointer hover:text-blue-600", onClick: onClose, children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) })] }) }), _jsx(DialogContent, { dividers: true, sx: {}, children: _jsx(MaterialReactTable, { table: table }) }), _jsxs(DialogActions, { children: [_jsx(Button, { autoFocus: true, onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: onSubmit, variant: "contained", disabled: !Object.values(rowSelection).length || mutation.isPending, children: "Submit" })] })] }));
};
