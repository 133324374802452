import { jsx as _jsx } from "react/jsx-runtime";
import { useContext } from 'react';
import { Snackbar, Box, Alert } from '@mui/material';
import { GlobalSnackbarContext } from '@/contexts/SnackbarContext';
const GlobalSnackbar = () => {
    const { globalSnackbarConfig, setGlobalSnackbarConfig } = useContext(GlobalSnackbarContext);
    return (_jsx(Snackbar, { open: globalSnackbarConfig.open, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, autoHideDuration: globalSnackbarConfig.autoHideDuration || 8000, onClose: () => {
            setGlobalSnackbarConfig(Object.assign(Object.assign({}, globalSnackbarConfig), { open: false }));
        }, children: typeof globalSnackbarConfig.message === 'object' ? (globalSnackbarConfig.message) : (_jsx(Alert, { severity: globalSnackbarConfig.severity, children: _jsx(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                }, children: globalSnackbarConfig.message }) })) }));
};
export default GlobalSnackbar;
