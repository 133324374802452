var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useState } from 'react';
import API from '@/services/API';
const PlanSettings = () => {
    const [loadingPortal, setLoadingPortal] = useState(false);
    const { data } = API.getBasicQuery('plans');
    const stripeSession = API.getMutation('stripe/create_session', 'POST');
    const getStripePortal = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const returnUrl = 'https://app.fintary.com/settings/plan';
        setLoadingPortal(true);
        if (data && data.stripe_id) {
            const { stripe_id: customerId } = data;
            const response = yield stripeSession.mutateAsync({
                customerId,
                returnUrl,
            });
            if (response.message === 'OK' && ((_a = response.payload) === null || _a === void 0 ? void 0 : _a.url)) {
                window.location.href = response.payload.url;
            }
            else {
                console.error('Could not retrieve payment link.');
            }
        }
        else {
            const paymentLink = 'https://buy.stripe.com/dR63dk2hR5AMc8M9AA';
            window.open(paymentLink, '_blank');
        }
        setLoadingPortal(false);
    });
    return (_jsx(Box, { sx: { mx: 2 }, children: (data === null || data === void 0 ? void 0 : data.plan_name) ? (_jsxs(Box, { children: [_jsxs(Typography, { variant: "h5", children: ["Plan: ", data.plan_name] }), _jsx(Box, { sx: { mt: 2 }, children: _jsxs(Button, { variant: "contained", onClick: getStripePortal, disabled: loadingPortal, children: ["Manage Subscription", loadingPortal && _jsx(CircularProgress, { size: 20, sx: { ml: 1 } })] }) })] })) : (_jsx(Button, { onClick: getStripePortal, disabled: loadingPortal, variant: "contained", children: "Get Fintary Premium" })) }));
};
export default PlanSettings;
