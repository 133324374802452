var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert, Button, Snackbar } from '@mui/material';
const VersionChecker = () => {
    const [showUpdateAlert, setShowUpdateAlert] = useState(false);
    const INTERVAL = 5 * 60 * 1000; // 5 minutes
    const [notify, setNotify] = useState();
    const forceAlertDelay = 12 * 1000 * 60 * 60; // 12 hours
    useEffect(() => {
        const checkVersion = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            try {
                const response = yield axios.get('/manifest.json');
                const manifestBuildTime = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.versionInfo) === null || _b === void 0 ? void 0 : _b.buildTime;
                if (manifestBuildTime &&
                    __BUILD_TIME__ &&
                    manifestBuildTime !== __BUILD_TIME__) {
                    if (!notify) {
                        setNotify({ notifyTime: Date.now() });
                    }
                    if (notify && Date.now() - notify.notifyTime >= forceAlertDelay) {
                        setShowUpdateAlert(true);
                    }
                    else {
                        dispatchNotification();
                    }
                }
            }
            catch (error) {
                console.error('Failed to check version:', error);
            }
        });
        checkVersion();
        const interval = setInterval(checkVersion, INTERVAL);
        return () => clearInterval(interval);
    }, [INTERVAL, forceAlertDelay, notify]);
    const dispatchNotification = () => {
        window.dispatchEvent(new CustomEvent('version-update', {
            detail: {
                buildTime: __BUILD_TIME__,
                notifyTime: Date.now(),
            },
        }));
    };
    const handleClose = (_event, reason) => {
        if (reason === 'clickaway')
            return;
        setShowUpdateAlert(false);
    };
    return (_jsx(Snackbar, { open: showUpdateAlert, anchorOrigin: { vertical: 'top', horizontal: 'center' }, onClose: handleClose, sx: { mt: -2 }, children: _jsx(Alert, { onClose: handleClose, severity: "info", sx: { width: '100%' }, action: _jsx(Button, { variant: "outlined", color: "inherit", size: "small", onClick: () => {
                    localStorage.setItem('triggerReload', Date.now().toString());
                    window.location.reload();
                }, sx: { textTransform: 'none' }, children: "Refresh" }), children: "A new version is available. Please refresh to update." }) }));
};
export default VersionChecker;
