var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Add, DeleteOutline, DownloadOutlined, Edit, LaunchOutlined, PlayArrow, SyncOutlined, } from '@mui/icons-material';
import { Alert, Box, Button, Chip, IconButton, Tooltip, FormControlLabel, Checkbox, } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import CommonFormatter from 'common/Formatter';
import { numberOrDefault, removeLeadingTrailingChar, tryDecodeURIComponent, } from 'common/helpers';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState, useRef, } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { DocumentTypeLabels } from 'common/constants/documents';
import { BasicDialog, FileDialogPreview } from '@/common';
import { PDF_IMG_TYPES } from '@/common/preview/model';
import UploadOverrideFile from '@/components/documents/DocumentsView/DocumentOverrideFile';
import ExtractMethod from '@/components/documents/DocumentsView/ExtractMethod';
import UpdateProcessData from '@/components/documents/DocumentsView/UpdateProcessData';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import { UIStateContext } from '@/contexts/UIStateProvider';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import usePreviewParams from '@/contexts/usePreviewParams';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import UILabels from '@/services/UILabels';
import useAccountStore from '@/store/accountStore';
import { useSetOriginFile } from '@/store/excelStore';
import { DocumentPreviewKeys } from '@/types';
import ActionDialog from './ActionDialog';
import useSnackbar from '@/contexts/useSnackbar';
const DocumentsView = () => {
    var _a, _b, _c;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const exportPoster = API.getMutation('statement_data/export', 'POST', {
        rawData: true,
    });
    const { showPreview, setShowPreview, previewId, setPreviewPath } = usePreviewParams();
    const { role: [role], } = useContext(UIStateContext);
    const syncStatement = API.getMutation('data_processing/sync/benefit-point/statements', 'POST');
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.documents;
    const { data: companies } = API.getBasicQuery('companies');
    const { data: documentProfiles } = API.getBasicQuery('document_profiles');
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showExtract, setShowExtract] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [processLoading, setProcessLoading] = useState('');
    const [activeRow, setActiveRow] = useState();
    const [showEdit, setShowEdit] = useState(null);
    const [sync, setSync] = useState({ documentId: '', show: false, count: 0 });
    const [rowData, setRowData] = useState({});
    const { setLoadingConfig } = useContext(LoadingContext);
    const [deleteRecords, setDeleteRecords] = useState(false);
    const setUploadedFile = useSetOriginFile();
    const { downloadFile } = useDownloadStorageFile();
    const documentsDelete = API.getMutation('documents', 'DELETE');
    const documentPutter = API.getMutation('documents', 'PUT');
    const { data: activeRowData, isLoading: isActiveRowLoading } = API.getBasicQuery('extractions', `document_id=${activeRow === null || activeRow === void 0 ? void 0 : activeRow.id}`, !!(activeRow === null || activeRow === void 0 ? void 0 : activeRow.id));
    const queryClient = useQueryClient();
    const { selectedAccount } = useAccountStore();
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const labels = new UILabels(mode);
    const activeRowRef = useRef({});
    // Hard coded for now, will be dynamic later
    const enableAccountId = ['W4kSrayZvmh26pGfYVrGE', 'fFF86XAy2Cu97xxra8lgA'];
    const isRiskTag = enableAccountId.includes((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) || '') ||
        ((_b = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes('risktag'));
    const filePathFormatter = useCallback((filename, row) => filename ? (_jsx(Tooltip, { title: filename, enterNextDelay: 1000, children: _jsx(Button, { style: {
                width: 'max-content',
                maxWidth: 240,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'left',
            }, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setPreviewPath(row.str_id, DocumentPreviewKeys.ORIGINAL);
                setShowPreview(true);
            }), children: _jsx("span", { style: {
                    maxWidth: 240,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                }, children: filename }) }) })) : ('--'), [setPreviewPath, setShowPreview]);
    const syncToBenefit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingConfig({
                loading: true,
                message: 'Syncing...',
            });
            const ret = yield syncStatement.mutateAsync(data);
            if (ret.success === false) {
                showSnackbar(`Sync failed: ${ret.message || ret.statusText}`, 'error');
            }
            else {
                const { actualCreated, create, invalid, grouped } = ret;
                let tips = [];
                if (grouped === actualCreated && create.length != grouped) {
                    tips = [
                        `${actualCreated} commission entries posted.`,
                        '',
                        grouped !== create.length
                            ? `${create.length} commissions entries merged into ${actualCreated}.`
                            : '',
                        `Only one commission entry allowed per policy.`,
                    ];
                }
                else {
                    tips.push(`${actualCreated} statement ${actualCreated > 1 ? 'entries' : 'entry'} created`);
                }
                // if not equal, show the tips in a yellow background
                showSnackbar(_jsxs(Alert, { severity: grouped !== actualCreated || (invalid === null || invalid === void 0 ? void 0 : invalid.length) > 0
                        ? 'warning'
                        : 'success', children: ["Synced statmentID: ", ret.statementId, _jsx("br", {}), tips.map((tip, index) => (_jsx("div", { children: tip ? tip : _jsx("br", {}) }, index))), (invalid === null || invalid === void 0 ? void 0 : invalid.length) > 0 && _jsx("br", {}), (invalid === null || invalid === void 0 ? void 0 : invalid.length) > 0
                            ? `${invalid.length} statement ${invalid.length > 1 ? 'reports' : 'report'} invalid`
                            : null] }));
            }
        }
        catch (error) {
            showSnackbar(error.message || error, 'error');
        }
        finally {
            setLoadingConfig({
                loading: false,
                message: '',
            });
        }
    });
    const exportData = useCallback((data) => __awaiter(void 0, void 0, void 0, function* () {
        const params = {
            document_id: data.str_id,
            company_str_id: data.company_str_id,
        };
        try {
            setLoadingConfig({
                loading: true,
                message: 'Exporting...',
            });
            const res = yield exportPoster.mutateAsync(params);
            setLoadingConfig({
                loading: false,
                message: '',
            });
            const blob = yield res.blob();
            saveAs(blob, `${removeLeadingTrailingChar(tryDecodeURIComponent(data.filename), '"')}`);
        }
        catch (error) {
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(error.message || error, 'error');
        }
    }), [exportPoster, setLoadingConfig]);
    const deleteData = (row) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingConfig({
                loading: true,
                message: 'Deleting...',
            });
            yield documentsDelete.mutateAsync({
                id: row.id,
                str_id: row.str_id,
                type: row.type,
                deleteRecords,
            });
            queryClient.invalidateQueries();
            setDeleteRecords(false);
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(`Deleted ${row.filename}`, 'success');
        }
        catch (error) {
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(error.message || error, 'error');
        }
    });
    const handleRunButtonClick = (row) => __awaiter(void 0, void 0, void 0, function* () {
        setActiveRow(row);
        activeRowRef.current = true;
        let interval;
        let timeout;
        try {
            yield new Promise((resolve, reject) => {
                timeout = setTimeout(() => {
                    reject(new Error('Operation timed out after 60 seconds'));
                }, 60000);
                interval = setInterval(() => {
                    if (!activeRowRef.current) {
                        clearInterval(interval);
                        clearTimeout(timeout);
                        resolve();
                    }
                }, 100);
            });
        }
        catch (error) {
            console.error('Error during row processing:', error);
            clearInterval(interval);
            clearTimeout(timeout);
            activeRowRef.current = false;
        }
    });
    /**
     * Extraxt data from file, if file is image or pdf, show an select option to choose which method to extract data (1. Google Document AI, 2. ExtractTable)
     * @param {object} row documents table row
     */
    useEffect(() => {
        if (isActiveRowLoading || !activeRow) {
            return;
        }
        if (activeRow) {
            setProcessLoading(activeRow.id);
        }
        const extractData = (_row) => __awaiter(void 0, void 0, void 0, function* () {
            const filePreviewType = (activeRow === null || activeRow === void 0 ? void 0 : activeRow.override_file_path)
                ? 'override'
                : 'original';
            try {
                const file = yield downloadFile({
                    endpoint_str_id: activeRow === null || activeRow === void 0 ? void 0 : activeRow.str_id,
                    file_preview_type: filePreviewType,
                    endpoint: 'documents',
                });
                if (!file) {
                    throw new Error('Failed to download file');
                }
                setRowData(() => {
                    return Object.assign(Object.assign({}, activeRow), { extractions: activeRowData });
                });
                setUploadedFile(file);
                setProcessLoading('');
                if (PDF_IMG_TYPES.includes(file.type)) {
                    setShowExtract(true);
                }
                else {
                    setOpen(true);
                }
                activeRowRef.current = false;
                setActiveRow(undefined);
            }
            catch (error) {
                activeRowRef.current = false;
                setActiveRow(undefined);
                setProcessLoading('');
                showSnackbar((error === null || error === void 0 ? void 0 : error.message) || error, 'error');
            }
        });
        extractData(activeRowData);
    }, [
        activeRowData,
        isActiveRowLoading,
        activeRow,
        processLoading,
        downloadFile,
        setUploadedFile,
        showSnackbar,
    ]);
    const markProcessed = useCallback((row) => __awaiter(void 0, void 0, void 0, function* () {
        const { company_str_id, file_type, id } = row;
        setProcessLoading(row.id);
        const res = yield documentPutter.mutateAsync({
            company_str_id,
            file_type,
            id,
            status: 'processed',
        });
        setProcessLoading('');
        if (res.error) {
            showSnackbar(res.error, 'error');
        }
        else {
            queryClient.invalidateQueries();
        }
    }), [documentPutter, queryClient]);
    const overrideFilePathFormatter = useCallback((s, row) => s ? (_jsx(Tooltip, { title: s, enterNextDelay: 1000, children: _jsx(Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                setPreviewPath(row.str_id, DocumentPreviewKeys.OVERRIDE);
                setShowPreview(true);
            }), children: _jsx("span", { style: {
                    maxWidth: 240,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                }, children: s }) }) })) : (_jsx(Box, { sx: { ml: 4 }, children: _jsx(Tooltip, { title: "Add override file", placement: "right", children: _jsx(IconButton, { onClick: () => {
                    setRowData(row);
                    setShowUploadModal(true);
                }, children: _jsx(Add, { sx: { height: 18 } }) }) }) })), [setPreviewPath, setShowPreview]);
    const filtersData = {
        type: Object.entries(DocumentTypeLabels).map(([id, name]) => ({
            id,
            name,
        })),
    };
    const dataDesc = useMemo(() => ({
        label: labels.getLabel('documents', 'title'),
        table: 'documents',
        filters: {
            type: {
                label: 'Type',
                useEnhancedSelect: true,
            },
        },
        fields: {
            filename: {
                label: 'File',
                enabled: true,
                copyable: true,
                formatter: filePathFormatter,
            },
            override_filename: {
                label: 'Override file',
                enabled: true,
                copyable: true,
                formatter: overrideFilePathFormatter,
                access: 'admin',
            },
            type: {
                label: 'Type',
                enabled: true,
                formatter: (v) => DocumentTypeLabels[v],
            },
            companies: {
                label: 'Company',
                enabled: true,
                formatter: Formatter.getLinkChipFormatter('company_name', 'str_id', '/companies?id='),
            },
            statement_data: {
                label: 'Records',
                disableSort: true,
                enabled: true,
                formatter: (val, row) => {
                    var _a, _b;
                    const groupedCountInfoStrList = [];
                    const groupedCommissionInfoStrList = [];
                    if (val.groupedCountInfo) {
                        Object.entries(val.groupedCountInfo).forEach(([key, value]) => {
                            if (key !== 'NO_STATUS') {
                                groupedCountInfoStrList.push(`${key}: ${value}`);
                            }
                        });
                    }
                    if (val.groupedCommissionInfo) {
                        Object.entries(val.groupedCommissionInfo).forEach(([key, value]) => {
                            if (key !== 'NO_STATUS') {
                                groupedCommissionInfoStrList.push(`${key}: ${Formatter.currency(value)}`);
                            }
                        });
                    }
                    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '4px',
                                height: isRiskTag ? 90 : 'auto',
                                justifyContent: 'space-between',
                            }, children: [_jsxs(Box, { sx: { flex: 1 }, children: [!!val.total_count && (_jsxs(Box, { sx: {
                                                whiteSpace: 'nowrap',
                                            }, children: [_jsx("span", { children: +val.total_count }), groupedCountInfoStrList.length > 0 && (_jsxs("span", { style: {
                                                        color: '#555',
                                                        fontSize: 13,
                                                        marginLeft: 4,
                                                    }, children: ["(", groupedCountInfoStrList.toString(), ")"] }))] })), !!val.total_commission && (_jsxs(Box, { sx: {
                                                whiteSpace: 'nowrap',
                                            }, children: [_jsx("span", { children: Formatter.currency(val.total_commission) }), groupedCommissionInfoStrList.length > 0 && (_jsxs("span", { style: {
                                                        color: '#555',
                                                        fontSize: 13,
                                                        marginLeft: 4,
                                                    }, children: ["(", groupedCommissionInfoStrList.join(', '), ")"] }))] })), !val.total_commission && !val.total_count && '0'] }), _jsx(Box, { style: {
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }, children: (val.total_commission > 0 || val.total_count > 0) && (_jsxs(_Fragment, { children: [_jsx(IconButton, { component: Link, to: `/${row.type === 'statement' ? 'commissions' : 'policies'}?q=${row.str_id}`, target: "_blank", sx: {
                                                    opacity: 0.5,
                                                    '&:hover': { opacity: 1 },
                                                    color: '#2196f3',
                                                }, children: _jsx(LaunchOutlined, {}) }), isRiskTag && (_jsxs(_Fragment, { children: [((_a = row === null || row === void 0 ? void 0 : row.statement_data) === null || _a === void 0 ? void 0 : _a.total_count) > 0 && (_jsx(Tooltip, { title: `${(row === null || row === void 0 ? void 0 : row.sync_id) ? `The document has been synced with statementId: ${row === null || row === void 0 ? void 0 : row.sync_id}, you can sync again to update` : `${(_b = row === null || row === void 0 ? void 0 : row.statement_data) === null || _b === void 0 ? void 0 : _b.total_count} statement entries will be synced to BenefitPoint`}`, placement: "top", children: _jsx(IconButton, { onClick: () => {
                                                                var _a;
                                                                return setSync({
                                                                    documentId: row.str_id,
                                                                    show: true,
                                                                    count: ((_a = row === null || row === void 0 ? void 0 : row.statement_data) === null || _a === void 0 ? void 0 : _a.total_count) || 0,
                                                                });
                                                            }, size: "small", sx: {
                                                                marginLeft: '4px',
                                                                opacity: 0.5,
                                                                '&:hover': { opacity: 1 },
                                                                color: '#2196f3',
                                                            }, children: _jsx(SyncOutlined, { color: row.sync_id ? 'disabled' : 'primary' }) }) })), _jsx(IconButton, { onClick: () => exportData(row), size: "small", sx: {
                                                            opacity: 0.5,
                                                            '&:hover': { opacity: 1 },
                                                            color: '#2196f3',
                                                        }, children: _jsx(DownloadOutlined, {}) })] }))] })) })] }) }));
                },
            },
            bank_total_amount: {
                label: 'Commission totals',
                enabled: true,
                disableSort: true,
                formatter: (v, row) => {
                    var _a, _b;
                    const statementData = row.statement_data;
                    const bankTotalAmount = numberOrDefault(row.bank_total_amount, null, { toFixed: 2 });
                    const totalCommissionAmount = numberOrDefault(statementData.total_commission, null, { toFixed: 2 });
                    const statementTotalAmount = numberOrDefault((_b = (_a = row.imports) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.statement_total_amount, null, { toFixed: 2 });
                    if (row.status === 'new') {
                        return `Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}`;
                    }
                    const bankAndTotalCmsIsEqual = isEqual(bankTotalAmount, totalCommissionAmount);
                    const statementAndCmsTotalIsEqual = isEqual(totalCommissionAmount, statementTotalAmount);
                    let matchesNode = _jsx(_Fragment, {});
                    if (bankAndTotalCmsIsEqual &&
                        statementAndCmsTotalIsEqual &&
                        bankTotalAmount !== null) {
                        const tip = 'Bank total, statement total, and commission records all match';
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", children: _jsx("span", { children: `✅ ${Formatter.currency(bankTotalAmount)}` }) }));
                    }
                    else if (bankAndTotalCmsIsEqual && totalCommissionAmount) {
                        const tip = (_jsxs("span", { children: ["Bank total and commissions match", _jsx("br", {}), "(Statement total not available)"] }));
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", children: _jsx("span", { children: `✅ ${Formatter.currency(bankTotalAmount)}` }) }));
                    }
                    else if (statementAndCmsTotalIsEqual && totalCommissionAmount) {
                        const tip = 'Statement total and commission records match (Bank total not available)';
                        matchesNode = (_jsx(Tooltip, { title: tip, placement: "right", children: _jsx("span", { children: `✅ ${Formatter.currency(statementTotalAmount)}` }) }));
                    }
                    else if (!bankTotalAmount &&
                        !statementTotalAmount &&
                        !totalCommissionAmount) {
                        matchesNode = _jsx("span", { children: "0" });
                    }
                    else if (totalCommissionAmount &&
                        !statementTotalAmount &&
                        !bankTotalAmount) {
                        matchesNode = (_jsx(Tooltip, { title: _jsxs("span", { children: ["Validation not available.", _jsx("br", {}), "Bank total and/or statement total required."] }), placement: "right", children: _jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Box, { sx: { fontSize: 12 }, children: "\u2139\uFE0F" }), _jsx(Box, { sx: {
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }, children: _jsx("span", { children: `Commissions: ${Formatter.currency(totalCommissionAmount)}` }) })] }) }));
                    }
                    else {
                        matchesNode = (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', gap: 1 }, children: [_jsx(Box, { sx: { fontSize: 12 }, children: "\u274C" }), _jsxs(Box, { sx: {
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }, children: [_jsx("span", { children: `Commissions: ${Formatter.currency(totalCommissionAmount)}` }), _jsx("span", { children: `Bank total: ${Formatter.currency(bankTotalAmount) || 'n/a'}` }), _jsx("span", { children: `Statement total: ${Formatter.currency(statementTotalAmount) || 'n/a'}` })] })] }));
                    }
                    return _jsx(Box, { sx: { whiteSpace: 'nowrap' }, children: matchesNode });
                },
            },
            created_by: {
                label: 'Imported total',
                enabled: true,
                disableSort: true,
                formatter: (v, row) => {
                    const list = row.imports;
                    if (list && list.length) {
                        const target = list[0];
                        return Formatter.currency(target === null || target === void 0 ? void 0 : target.summed_total_amount);
                    }
                },
                access: 'admin',
            },
            status: {
                label: 'Status',
                enabled: true,
                formatter: (val) => Formatter.statusChip(val, {
                    mapping: { new: 'yellow', processed: 'green' },
                }),
            },
            method: {
                label: 'Method',
                enabled: true,
                access: 'admin',
                formatter: (val, row) => val ? (_jsx(Chip, { label: val, component: Link, to: `/documents/profiles?id=${row.profile_str_id}` })) : null,
            },
            notes: {
                label: 'Notes',
                enabled: true,
            },
            imported_at: {
                label: 'Imported at',
                enabled: true,
                access: 'admin',
                formatter: (s, row) => {
                    if (!s) {
                        return '';
                    }
                    const uploadToImportTime = dayjs(s).diff(dayjs(row.created_at), 'milliseconds');
                    const uploadedInRes = `${CommonFormatter.duration(uploadToImportTime, { truncate: 'seconds' })}`;
                    return `${Formatter.date(s, {
                        format: 'MM/DD/YYYY hh:mmA',
                    })} (${uploadedInRes})`;
                },
            },
            created_at: {
                label: 'Uploaded at',
                enabled: true,
                formatter: (s) => {
                    return Formatter.date(s, {
                        format: 'MM/DD/YYYY hh:mmA',
                    });
                },
                readOnly: true,
            },
        },
        actions: role === 'admin'
            ? [
                {
                    id: 'process',
                    label: 'Process',
                    type: 'iconButton',
                    icon: _jsx(PlayArrow, {}),
                    onClick: (row) => handleRunButtonClick(row),
                },
                {
                    id: 'edit',
                    label: 'Edit',
                    type: 'iconButton',
                    icon: _jsx(Edit, {}),
                    onClick: (row) => {
                        setRowData(row);
                        setShowEdit(true);
                    },
                },
                {
                    id: 'delete',
                    label: 'Delete',
                    type: 'iconButton',
                    icon: _jsx(DeleteOutline, {}),
                    onClick: (row) => {
                        setRowData(row);
                        setShowConfirm(true);
                    },
                },
                {
                    id: 'mark_processed',
                    label: 'Mark as processed',
                    enabled: (row) => row.status === 'new',
                    onClick: (row) => __awaiter(void 0, void 0, void 0, function* () { return yield markProcessed(row); }),
                },
            ]
            : [
                {
                    id: 'edit',
                    label: 'Edit',
                    type: 'iconButton',
                    icon: _jsx(Edit, {}),
                    onClick: (row) => {
                        setRowData(row);
                        setShowEdit(true);
                    },
                },
                {
                    id: 'delete',
                    label: 'Delete',
                    type: 'iconButton',
                    icon: _jsx(DeleteOutline, {}),
                    enabled: (row) => row.status === 'new',
                    onClick: (row) => {
                        setRowData(row);
                        setShowConfirm(true);
                    },
                },
            ],
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            new: {
                id: 'new',
                label: 'New',
                query: {
                    status: 'new',
                },
            },
            processed: {
                id: 'processed',
                label: 'Processed',
                query: {
                    status: 'processed',
                },
            },
        },
    }), [
        exportData,
        filePathFormatter,
        isRiskTag,
        labels,
        markProcessed,
        overrideFilePathFormatter,
        role,
    ]);
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    const onConfirmMethod = (method) => {
        setRowData((prev) => (Object.assign(Object.assign({}, prev), { method })));
        setShowExtract(false);
        setOpen(true);
    };
    const onSetShowEdit = (isShow, action = 'cancel') => {
        setShowEdit(isShow);
        if (action === 'save') {
            queryClient.invalidateQueries();
        }
    };
    const extraActions = [
        {
            type: 'dateRange',
            label: 'Uploaded at',
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { dataSpec: dataDesc, hideAdd: true, hideSelectedCount: true, enableMultiSelect: false, enableEdit: false, actionsEnabled: () => true, 
                // @ts-ignore
                actions: dataDesc.actions, 
                // @ts-ignore
                extraActions: extraActions, filters: filtersData }), open && (_jsx(UpdateProcessData, { open: open, rowData: rowData, setRowData: setRowData, handleClose: (arg) => {
                    queryClient.invalidateQueries();
                    setOpen(arg);
                } })), showUploadModal && (_jsx(UploadOverrideFile, { open: showUploadModal, setOpen: setShowUploadModal, uploadedRow: rowData })), showExtract && (_jsx(ExtractMethod, { showExtract: showExtract, onClose: () => setShowExtract(false), onConfirm: onConfirmMethod, uploadedRow: rowData })), showPreview && (_jsx(FileDialogPreview, { showPreview: showPreview, setShowPreview: setShowPreview, fileId: previewId })), showConfirm && (_jsx(BasicDialog, { open: showConfirm, title: "Delete document", bodyComponent: _jsxs(_Fragment, { children: [_jsxs(Alert, { severity: "warning", children: ["Are you sure you want to delete ", rowData.filename, "?"] }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: deleteRecords, onChange: (e) => setDeleteRecords(e.target.checked) }), label: `Also delete imported records (${(_c = rowData.statement_data) === null || _c === void 0 ? void 0 : _c.total_count})`, sx: { ml: 1, mt: 1 } })] }), onClose: (isOk) => {
                    if (isOk) {
                        deleteData(rowData);
                    }
                    setShowConfirm(false);
                }, sxContent: { pb: 1 } })), showEdit && (_jsx(ActionDialog, { open: showEdit, setOpen: onSetShowEdit, rowData: rowData, companies: companies === null || companies === void 0 ? void 0 : companies.data, documentProfiles: documentProfiles === null || documentProfiles === void 0 ? void 0 : documentProfiles.data })), _jsx(BasicDialog, { open: sync.show, title: "Sync data", bodyComponent: _jsxs(Alert, { severity: "warning", children: ["Are you sure you want to sync ", sync.count, " statement entries to BenefitPoint?"] }), onClose: (isOk) => {
                    if (isOk) {
                        syncToBenefit({ documentId: sync.documentId });
                    }
                    setSync(Object.assign(Object.assign({}, sync), { show: false }));
                }, positiveLabel: "Sync" })] }));
};
export default DocumentsView;
