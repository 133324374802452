import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { EnhancedSelect } from '../molecules/EnhancedSelect';
export const AgentSelect = ({ updateAgentData, dynamicSelects, agentDataToUse, removeAgentData, }) => {
    const { selectedAccount } = useAccountStore();
    const [search, setSearch] = useState('');
    const options = useMemo(() => {
        return dynamicSelects.filter((item) => {
            var _a, _b;
            return (((_a = item.first_name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(search.toLowerCase())) ||
                ((_b = item.last_name) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(search.toLowerCase())));
        });
    }, [dynamicSelects, search]);
    const value = useMemo(() => {
        const _set = new Set(Object.keys(agentDataToUse));
        return options.filter((item) => _set.has(item.str_id));
    }, [agentDataToUse, options]);
    const onChange = (v) => {
        const createMap = (list) => {
            return list.reduce((prev, cur) => {
                prev[cur.str_id] = 1;
                return prev;
            }, {});
        };
        const valueMap = createMap(value);
        const newValueMap = createMap(v);
        // Adding new keys
        v.forEach((item) => {
            if (!valueMap[item.str_id])
                updateAgentData(item.str_id, '0');
        });
        // Remove keys
        value.map((item) => {
            if (!newValueMap[item.str_id])
                removeAgentData(item.str_id);
        });
    };
    return (_jsx(EnhancedSelect, { label: "Add", enableSelectAllSearchResult: false, disableAllOption: true, enableSearch: true, multiple: true, options: options, valueKey: "str_id", value: value, searchKeyword: search, onSearch: setSearch, renderLabel: ({ sx, key }) => {
            const option = options.find((item) => item.str_id === key);
            return (_jsx(Box, { sx: sx, children: Formatter.contact(option, {
                    account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                }) }));
        }, onChange: onChange }));
};
