var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { BaseSelect } from './BaseSelect';
import { convertObjectToOption, convertOptionToValue } from '../utils';
export const ObjectSelect = (_a) => {
    var { options, valueKey = 'id', labelKey = 'name', onChange, multiple, value } = _a, rest = __rest(_a, ["options", "valueKey", "labelKey", "onChange", "multiple", "value"]);
    const _options = useMemo(() => {
        return options.map((item) => convertObjectToOption(item, valueKey, labelKey));
    }, [labelKey, options, valueKey]);
    const _onChange = (item) => {
        let newValue;
        if (Array.isArray(item)) {
            newValue = item.map((item) => {
                return convertOptionToValue(item, valueKey, labelKey);
            });
        }
        else {
            newValue = convertOptionToValue(item, valueKey, labelKey);
        }
        onChange(newValue);
    };
    const _value = useMemo(() => {
        if (Array.isArray(value))
            return value.map((item) => convertObjectToOption(item, valueKey, labelKey));
        return convertObjectToOption(value, valueKey, labelKey);
    }, [labelKey, value, valueKey]);
    return (_jsx(BaseSelect, Object.assign({ multiple: multiple, value: _value, options: _options, onChange: _onChange }, rest)));
};
