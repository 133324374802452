var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import DataView from '@/components/DataView';
import DocumentReconciler from '@/components/molecules/DocumentReconciler';
import API from '@/services/API';
const deDupeData = (p) => __awaiter(void 0, void 0, void 0, function* () {
    const res = yield fetch(`${process.env.REACT_APP_API}/api/data_processing/grouping`, {
        method: 'POST',
        headers: yield API.getHeaders(),
    });
    console.log('deDupe: ', yield res.json());
    return res;
});
const processReconciliation = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const { redo, testRun, flowId } = options || {};
    const res = yield fetch(`${process.env.REACT_APP_API}/api/reconciliation/reconcile`, {
        method: 'POST',
        headers: yield API.getHeaders(),
        body: JSON.stringify({
            redo,
            testRun,
            flowId,
        }),
    });
    console.log('processReconciliation: ', yield res.json());
    return res;
});
const dataDesc = {
    label: 'Reconciler Flows',
    table: 'reconciler_flows',
    editable: true,
    fields: [
        {
            id: 'name',
            label: 'Name',
        },
        {
            id: 'flow',
            label: 'Flow',
            type: 'dynamic-select',
            multiple: true,
            table: 'reconcilers',
            field: 'id',
            formatter: (val, collectionVals = []) => {
                var _a, _b;
                if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                    return (((_b = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((company) => company.str_id === val)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.name) || 'Not found');
                }
                return val; // Not formatting when collectionVals is not available
            },
            optionFormatter: (option) => option.name,
            optionValuer: (option) => option.str_id,
            itemOnClick: (item) => {
                window.open(`/reconciliation/reconcilers?q=${item}`, '_blank');
            },
            delimiter: ' → ',
        },
        { id: 'notes', label: 'Notes', type: 'text-multiline' },
        {
            id: 'access',
            label: 'Access',
            type: 'select',
            default: 'account',
            options: ['account', 'global'],
        },
        {
            id: 'state',
            label: 'State',
            type: 'select',
            default: 'active',
            options: ['active', 'disabled'],
        },
        {
            id: 'account_default',
            label: 'Default reconciler',
            type: 'boolean',
        },
        { id: 'divider', type: 'divider', label: 'Metadata' },
        {
            id: 'updated_at',
            label: 'Last updated',
            formatter: (s) => new Date(s).toLocaleString(),
            readOnly: true,
        },
        // TODO: Move to actions
        // Hack since actions don't support formatters rn
        {
            id: 'id',
            label: 'Test doc',
            tableFormatter: (id) => _jsx(DocumentReconciler, { flowId: id }),
            formatter: (a) => null,
        },
    ],
    actions: [
        {
            label: 'Test run',
            onClick: (data) => __awaiter(void 0, void 0, void 0, function* () {
                yield deDupeData();
                yield processReconciliation({
                    deDupe: true,
                    redo: true,
                    testRun: true,
                    flowId: data.id,
                });
            }),
            type: 'button',
        },
        {
            label: 'Run',
            onClick: (data) => __awaiter(void 0, void 0, void 0, function* () {
                yield deDupeData();
                yield processReconciliation({
                    deDupe: true,
                    redo: true,
                    testRun: false,
                    flowId: data.id,
                });
            }),
            type: 'button',
        },
    ],
};
const ReconcilersFlowsView = () => _jsx(DataView, { dataDesc: dataDesc });
export default ReconcilersFlowsView;
