import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Launch, RequestQuoteOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Typography } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ReportReviewStatuses, ReportReviewStatusesLabels, } from 'common/globalTypes';
import DataView from '@/components/DataView';
import CommissionPayoutReportConfig from '@/components/ReportsGroupView/CommissionPayoutReportConfig';
import { useAccountStore } from '@/store';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
const ReportsSummaryView = () => {
    var _a, _b;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.summaries;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const { selectedAccount } = useAccountStore();
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const extraActions = (_jsx(Button, { variant: "contained", disableElevation: true, startIcon: _jsx(RequestQuoteOutlined, {}), onClick: () => {
            setShowModal(true);
        }, sx: { mr: 0.5 }, children: "Commissions payout" }));
    const dataDesc = {
        label: 'Saved report groups',
        table: 'saved_reports/groups',
        onDeleteText: 'All reports in this group will be deleted.',
        editable: true,
        fields: [
            {
                id: 'name',
                label: 'Name',
                tableFormatter: (row, collectionVals) => {
                    return (_jsx(Button, { onClick: () => {
                            window.open(`/reports/summary/${collectionVals.str_id}`, '_blank');
                        }, endIcon: _jsx(Launch, {}), children: row }));
                },
            },
            {
                id: 'template',
                label: 'Type',
                type: 'select',
                options: [
                    { id: '', label: '' },
                    { id: 'commission_payout', label: 'Commission payout' },
                ],
                readOnly: true,
            },
            {
                id: 'saved_reports',
                label: 'Saved reports',
                type: 'dynamic-select',
                table: 'saved_reports?include_grouped=true',
                field: 'id',
                multiple: true,
                tableFormatter: (field, row) => {
                    return (_jsxs(Box, { children: [(field !== null && field !== void 0 ? field : []).slice(0, 6).map((item) => (_jsx(Chip, { label: item.name, sx: { m: 0.25 } }, item.id))), _jsx(Typography, { variant: "body2", sx: { ml: 0.5, mt: 0.5 }, children: field.length > 6 && `+${field.length - 6} more` })] }));
                },
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const _val = typeof val === 'object' ? val.id : val;
                        return (((_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((item) => item.id === _val)) === null || _a === void 0 ? void 0 : _a.name) ||
                            'Not found');
                    }
                    return typeof val === 'string' ? val : ''; // Not formatting when collectionVals is not available
                },
                optionFormatter: (option) => option.name,
                optionValuer: (option) => option.id,
            },
            {
                id: 'notes',
                label: 'Notes',
            },
            {
                id: 'status',
                label: 'Status',
                type: 'select',
                default: 'draft',
                options: [
                    {
                        id: ReportReviewStatuses.None,
                        label: ReportReviewStatusesLabels.None,
                    },
                    {
                        id: ReportReviewStatuses.Draft,
                        label: ReportReviewStatusesLabels.Draft,
                    },
                    {
                        id: ReportReviewStatuses.InReview,
                        label: ReportReviewStatusesLabels.InReview,
                    },
                    {
                        id: ReportReviewStatuses.Approved,
                        label: ReportReviewStatusesLabels.Approved,
                    },
                    {
                        id: ReportReviewStatuses.RequestUpdate,
                        label: ReportReviewStatusesLabels.RequestUpdate,
                    },
                    {
                        id: ReportReviewStatuses.Rejected,
                        label: ReportReviewStatusesLabels.Rejected,
                    },
                    {
                        id: ReportReviewStatuses.NonPayable,
                        label: ReportReviewStatusesLabels.NonPayable,
                    },
                ],
                formatter: Formatter.reportStatusFormatter,
            },
            {
                id: 'reviewed_by',
                label: 'Approver',
                type: 'dynamic-select',
                table: `users/get_account_users?state=active`,
                queryParamTable: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                queryParamNameTable: 'accId',
                queryParamValue: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                queryParamName: 'accId',
                field: 'id',
                multiple: false,
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const _val = typeof val === 'object' ? val.uid : val;
                        return (((_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((item) => item.uid === _val)) === null || _a === void 0 ? void 0 : _a.email) ||
                            'Not found');
                    }
                    return val; // Not formatting when collectionVals is not available
                },
                optionFormatter: (option) => option.email,
                optionValuer: (option) => option.uid,
            },
            {
                id: 'access',
                label: 'Access',
                type: 'select',
                default: 'user',
                options: [
                    { id: 'user', label: 'User' },
                    { id: 'account', label: 'Account' },
                    { id: 'user_list', label: 'User list' },
                ],
            },
            {
                id: 'users_white_list',
                label: 'Users with access',
                type: 'dynamic-select',
                table: `users/get_account_users?state=active`,
                queryParamValue: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                queryParamName: 'accId',
                field: 'id',
                multiple: true,
                readOnly: (val) => {
                    return val.access !== 'user_list';
                },
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const _val = typeof val === 'object' ? val.str_id : val;
                        return (((_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((item) => item.str_id === _val)) === null || _a === void 0 ? void 0 : _a.email) ||
                            'Not found');
                    }
                    return val; // Not formatting when collectionVals is not available
                },
                optionFormatter: (option) => option.email,
                optionValuer: (option) => option.str_id,
            },
            {
                id: 'created_at',
                label: 'Created at',
                formatter: (row) => new Date(row).toLocaleString(),
                condition: (row) => row.created_at,
                readOnly: true,
            },
        ],
        actions: [],
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            draft: {
                id: 'draft',
                label: 'Draft',
                query: {
                    status: 'draft',
                },
            },
            in_review: {
                id: 'in_review',
                label: 'In review',
                query: {
                    status: 'in_review',
                },
            },
            approved: {
                id: 'approved',
                label: 'Approved',
                query: {
                    status: 'approved',
                },
            },
        },
    };
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    return (_jsxs(_Fragment, { children: [_jsx(DataView, { headingOffset: 105, dataDesc: dataDesc, extraActions: extraActions, hideExport: true, refresh: refresh, viewOnly: viewOnly, readOnly: viewOnly, enablePagination: true }), showModal && (_jsx(CommissionPayoutReportConfig, { open: showModal, setOpen: setShowModal, refreshData: () => setRefresh(refresh + 1) }))] }));
};
export default ReportsSummaryView;
