import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SyncOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
export const SyncedEntity = (props) => {
    const { children, isSynced: _isSynced, disabled } = props;
    const isSynced = typeof _isSynced === 'function' ? _isSynced() : _isSynced;
    return (_jsx(Tooltip, { title: "This record is synced from your AMS/CRM", children: _jsxs(Box, { style: {
                cursor: disabled ? 'default' : 'pointer',
                display: 'flex',
                alignItems: 'center',
            }, children: [isSynced && _jsx(SyncOutlined, { color: disabled ? 'disabled' : 'primary' }), children] }) }));
};
