var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DataEntities } from 'common/globalTypes';
import API from '@/services/API';
import { FieldTypes } from '@/types';
import Statements from '@/services/Statements';
import Reports from '@/services/Reports';
import RunDataUpdate from '@/components/ToolsPage/DataUpdateTools/RunDataUpdate';
import DataUpdateConfig from '@/components/ToolsPage/DataUpdateTools/DataUpdateConfig';
import DataUpdateCriteria from '@/components/ToolsPage/DataUpdateTools/DataUpdateCriteria';
import DataUpdateActions from '@/components/ToolsPage/DataUpdateTools/DataUpdateActions';
const TabPanel = (_a) => {
    var { children, value, index } = _a, other = __rest(_a, ["children", "value", "index"]);
    return (_jsx("div", Object.assign({ role: "tabpanel", hidden: value !== index, id: `tabpanel-${index}` }, other, { children: value === index && (_jsx(Box, { sx: { pt: 2 }, children: _jsx(Typography, { component: "div", children: children }) })) })));
};
const DataUpdateTools = () => {
    const statements = useMemo(() => new Statements('insurance'), []);
    const reports = useMemo(() => new Reports('insurance', {}), []);
    const [fields, setFields] = useState({
        [DataEntities.COMMISSIONS]: Object.values(statements.fields),
        [DataEntities.POLICIES]: Object.values(reports.fields),
    });
    const { data: _statementDataOptions } = API.getBasicQuery(`statement_data/options`);
    const statementProducTypesList = useMemo(() => {
        var _a;
        return [
            ...(((_a = _statementDataOptions === null || _statementDataOptions === void 0 ? void 0 : _statementDataOptions.product_type) === null || _a === void 0 ? void 0 : _a.sort((a, b) => a.localeCompare(b))) || []),
        ];
    }, [_statementDataOptions === null || _statementDataOptions === void 0 ? void 0 : _statementDataOptions.product_type]);
    const statementCompTypeList = useMemo(() => {
        var _a;
        return [
            ...(((_a = _statementDataOptions === null || _statementDataOptions === void 0 ? void 0 : _statementDataOptions.compensation_type) === null || _a === void 0 ? void 0 : _a.sort((a, b) => a.localeCompare(b))) || []),
        ];
    }, [_statementDataOptions === null || _statementDataOptions === void 0 ? void 0 : _statementDataOptions.compensation_type]);
    useEffect(() => {
        // Set custom fields for the statements entity
        if (statementProducTypesList && statementCompTypeList) {
            statements.fields.product_type.options = statementProducTypesList;
            statements.fields.product_type.type = FieldTypes.SELECT;
            // Define statement_data fields as options for the effective_date field so we can use the 'within one year' or 'at least one year' from the payment date operators.
            statements.fields.effective_date.options = Object.keys(statements.fields);
            statements.fields.compensation_type.options = statementCompTypeList;
            statements.fields.compensation_type.type = FieldTypes.SELECT;
            // Remove the date type from the payment_date field because it is not necessary.
            // We plan to use operators like "within one year" or "at least one year from the payment date".
            statements.fields.payment_date.type = undefined;
        }
        setFields({
            [DataEntities.COMMISSIONS]: Object.values(statements.fields),
            [DataEntities.POLICIES]: Object.values(reports.fields),
        });
    }, [statementProducTypesList, statementCompTypeList, reports, statements]);
    const tabs = [
        {
            label: 'Run data update',
            path: 'run-data-update',
            component: _jsx(RunDataUpdate, { fields: fields }),
        },
        {
            label: 'Data update config',
            path: 'data-update-config',
            component: _jsx(DataUpdateConfig, { fields: fields }),
        },
        {
            label: 'Data update criteria',
            path: 'data-update-criteria',
            component: _jsx(DataUpdateCriteria, { fields: fields }),
        },
        {
            label: 'Data update actions',
            path: 'data-update-actions',
            component: _jsx(DataUpdateActions, { fields: fields }),
        },
    ];
    const { tab = 'list' } = useParams();
    const tabPaths = tabs.map((tab) => tab.path);
    const routedTab = tabPaths.indexOf(tab) >= 0 ? tabPaths.indexOf(tab) : 0;
    const [value, setValue] = useState(routedTab);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        navigate(`/settings/data-update-tools/${tabs[newValue].path}`);
        setValue(newValue);
    };
    return (_jsxs(Box, { children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: 'divider' }, children: _jsx(Tabs, { value: value, onChange: handleChange, children: tabs.map((tab) => (_jsx(Tab, { label: tab.label }, tab.label))) }) }), _jsx(Box, { sx: { overflowX: 'scroll', height: '700px' }, children: tabs.map((tab) => (_jsx(TabPanel, { value: value, index: tabs.indexOf(tab), children: tab.component }, tab.label))) })] }));
};
export default DataUpdateTools;
