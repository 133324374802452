var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEqual } from 'lodash-es';
import { SortOrder } from './globalTypes';
dayjs.extend(utc);
export const getUpdatedFields = (oldObj, newObj) => {
    // TODO: Would be nice if FE only sent those fields that were updated
    // Use centralized types for this handling instead of keying off field keys
    const updatedFields = [];
    Object.keys(newObj).forEach((key) => {
        if (key.endsWith('_date')) {
            if (oldObj[key] &&
                !Number.isNaN(new Date(oldObj[key]).getTime()) &&
                newObj[key] &&
                !Number.isNaN(new Date(newObj[key]).getTime()) &&
                new Date(oldObj[key]).getTime() !== new Date(newObj[key]).getTime()) {
                updatedFields.push(key);
            }
        }
        else if (key.endsWith('_amount')) {
            if (+oldObj[key] !== +newObj[key]) {
                updatedFields.push(key);
            }
        }
        else if (['split_percentage'].includes(key)) {
            +oldObj[key] === +newObj[key];
        }
        else if (!isEqual(oldObj[key], newObj[key])) {
            updatedFields.push(key);
        }
        return oldObj[key] !== newObj[key];
    });
    return updatedFields;
};
export const numberOrDefault = (input, defaultValue = 0, opts = {}) => {
    if (defaultValue === 'undefined') {
        defaultValue = undefined;
    }
    let res = Number.isNaN(parseFloat(input))
        ? defaultValue
        : parseFloat(input);
    if (typeof res === 'number' && typeof (opts === null || opts === void 0 ? void 0 : opts.toFixed) === 'number') {
        res = +res.toFixed(opts.toFixed);
    }
    return res;
};
export const dateOrDefault = (input, defaultValue = undefined) => {
    if (input === null || input === undefined)
        return defaultValue;
    const _input = typeof input === 'string' ? decodeURIComponent(input) : input.toString();
    const date = new Date(_input);
    if (Number.isNaN(date.getTime())) {
        return defaultValue;
    }
    return date;
};
export const calcExecuteTime = (name, context, fn) => __awaiter(void 0, void 0, void 0, function* () {
    const start = process.hrtime();
    const result = yield fn.call(context);
    const stop = process.hrtime(start);
    const executionTime = (stop[0] * 1e9 + stop[1]) / 1e9;
    console.log(`${name} execution time: ${executionTime}s`);
    return result;
});
export const setNextDay = (date) => {
    if (!(date instanceof Date))
        return date;
    date.setDate(date.getDate() + 1);
    return date;
};
export const isNill = (value) => {
    return (value === null ||
        value === undefined ||
        value === 'null' ||
        value === 'undefined' ||
        value === '' ||
        value === '""' ||
        value === "''" ||
        value === '“”' ||
        value === '‘’' ||
        value === 'NaN'
    // Number.isNaN(value)
    );
};
export const isValidJsonString = (str) => {
    try {
        JSON.parse(str);
    }
    catch (e) {
        return false;
    }
    return true;
};
const getValidDate = (date, defaultDate) => {
    if (typeof date === 'string' || typeof date === 'number') {
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate.getTime())) {
            return parsedDate;
        }
    }
    if (date instanceof Date && !isNaN(date.getTime())) {
        return date;
    }
    return defaultDate;
};
export const doDateRangesOverlap = (ranges) => {
    const earliestDate = new Date(-8640000000000000);
    const latestDate = new Date(8640000000000000);
    for (let i = 0; i < ranges.length; i++) {
        for (let j = i + 1; j < ranges.length; j++) {
            const range1 = ranges[i];
            const range2 = ranges[j];
            const range1Start = getValidDate(range1.start_date, earliestDate);
            const range1End = getValidDate(range1.end_date, latestDate);
            const range2Start = getValidDate(range2.start_date, earliestDate);
            const range2End = getValidDate(range2.end_date, latestDate);
            if ((range1Start <= range2End && range1End >= range2Start) ||
                (range2Start <= range1End && range2End >= range1Start) ||
                (range1Start === range2Start && range1End === range2End)) {
                return true;
            }
        }
    }
    return false;
};
/**
 * Merges and processes date ranges by converting date strings to Date objects
 * and filtering out existing date ranges that are not present in the new date ranges.
 *
 * @param {DateRange[]} date_ranges - The new date ranges to be processed.
 * @param {DateRange[]} existingDateRanges - The existing date ranges to be filtered.
 * @returns {DateRange[]} - The merged and processed date ranges.
 */
export const mergeAndProcessDateRanges = (date_ranges, existingDateRanges) => {
    const newDateRanges = (date_ranges || []).map((range) => (Object.assign(Object.assign({}, range), { start_date: range.start_date
            ? new Date(range.start_date)
            : range.start_date, end_date: range.end_date ? new Date(range.end_date) : range.end_date })));
    const filteredExistingDateRanges = existingDateRanges.filter((existingRange) => newDateRanges.some((newRange) => newRange.id === existingRange.id));
    const uniqueDateRanges = [
        ...filteredExistingDateRanges,
        ...newDateRanges.filter((newRange) => !filteredExistingDateRanges.some((existingRange) => existingRange.id === newRange.id)),
    ];
    return uniqueDateRanges;
};
/**
 * Converts fields in an object that end with '_date' to dayjs objects.
 *
 * @param {Record<string, any>} obj - The object containing fields to convert.
 * @returns {Record<string, any>} A new object with '_date' fields converted to dayjs objects.
 */
export const convertDateFields = (obj) => Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    if (key.endsWith('_date') || key.endsWith('date')) {
        if (value !== null && value !== undefined && value !== '') {
            acc[key] = dayjs.utc(value);
        }
        else {
            acc[key] = null;
        }
    }
    else {
        acc[key] = value;
    }
    return acc;
}, {});
export const getOrderBy = (property = 'created_at', sort = 'desc', fieldMappings = []) => {
    const fieldMapTarget = fieldMappings.find((item) => item.query_field === property);
    if (fieldMapTarget) {
        const { query_field, db_field } = fieldMapTarget;
        return {
            [query_field]: {
                [db_field]: sort,
            },
        };
    }
    return {
        [property]: sort,
    };
};
export const mapToOrderBy = (property = 'created_at', sort = 'desc', fieldMappings = []) => {
    const fieldMapTarget = fieldMappings.find((item) => item.query_field === property);
    if (fieldMapTarget) {
        if (fieldMapTarget.nested_db_field) {
            const { db_field, nested_db_field } = fieldMapTarget;
            return {
                [db_field]: {
                    [nested_db_field]: sort,
                },
            };
        }
        else {
            const { db_field } = fieldMapTarget;
            return {
                [db_field]: sort,
            };
        }
    }
    return {
        ['created_at']: sort,
    };
};
export const getFilenameFromPath = (path, removeAllNanoId = false) => {
    var _a;
    if (!path)
        return '';
    const filename = (_a = path.split('/').pop()) === null || _a === void 0 ? void 0 : _a.trim();
    if (!filename)
        return '';
    if (removeAllNanoId) {
        const nanoIdAllRegex = /[-_a-zA-Z0-9]{21}-/g;
        return filename.replace(nanoIdAllRegex, '');
    }
    return filename.replace(/^[-_a-zA-Z0-9]{21}-/, '');
};
export const convertArrayOfMapsToArrayOfArrays = (fields = [], arrayOfMaps = [{}]) => {
    const arr = [];
    arrayOfMaps === null || arrayOfMaps === void 0 ? void 0 : arrayOfMaps.forEach((map) => {
        const row = [];
        fields === null || fields === void 0 ? void 0 : fields.forEach((k) => {
            row.push(map[k]);
        });
        arr.push(row);
    });
    return arr;
};
export const arrayToJson = (arr, fields) => {
    return arr.map((row) => {
        const obj = {};
        fields.forEach((field, i) => {
            obj[field] = row[i];
        });
        return obj;
    });
};
export const removeLeadingTrailingChar = (str, charToRemove) => {
    return str.replace(new RegExp(`^${charToRemove}+|${charToRemove}+$`, 'g'), '');
};
/**
 * Checks if the parameter is an array and has a length greater than 0.
 *
 * @param {unknown} param - The parameter to check.
 * @returns {boolean} - Returns true if the parameter is an array with length > 0, otherwise false.
 */
export const isNonEmptyArray = (param) => {
    return Array.isArray(param) && param.length > 0;
};
export const getBasicRelevancySort = (fields = [], query = '') => (a, b) => {
    const getFieldVals = (fields, data) => fields.map((field) => { var _a; return (_a = data[field]) === null || _a === void 0 ? void 0 : _a.toLowerCase(); });
    const queryLowerCase = query.trim().toLowerCase();
    const aVals = getFieldVals(fields, a);
    const bVals = getFieldVals(fields, b);
    if (aVals.includes(queryLowerCase))
        return -1;
    if (bVals.includes(queryLowerCase))
        return 1;
    if (aVals.some((e) => e === null || e === void 0 ? void 0 : e.startsWith(queryLowerCase)))
        return -1;
    if (bVals.some((e) => e === null || e === void 0 ? void 0 : e.startsWith(queryLowerCase)))
        return 1;
    return;
};
export const tryDecodeURIComponent = (str) => {
    try {
        const decoded = decodeURIComponent(str);
        return decoded !== str ? decoded : str;
    }
    catch (e) {
        return str;
    }
};
/**
 * Custom sorting function for any type or object.
 * @param CustomSortParams<T> customSortParams - The custom sort parameters.
 * @returns The sorted array.
 */
export const customSort = (customSortParams) => {
    const { array, orderBy, sort } = customSortParams;
    if (!orderBy || !sort)
        return array;
    return array.sort((a, b) => {
        const aValue = a[orderBy];
        const bValue = b[orderBy];
        if (aValue === undefined && bValue === undefined)
            return 0;
        if (aValue === undefined)
            return sort === SortOrder.ASC ? -1 : 1;
        if (bValue === undefined)
            return sort === SortOrder.ASC ? 1 : -1;
        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return sort === SortOrder.ASC
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
        }
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return sort === SortOrder.ASC ? aValue - bValue : bValue - aValue;
        }
        return 0;
    });
};
