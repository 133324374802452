import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import prettyMilliseconds from 'pretty-ms';
import { AccountIds, CALC_METHODS } from './constants';
import { CompGridRateFields } from './globalTypes';
import { isNill } from './helpers';
dayjs.extend(utc);
class Formatter {
}
Formatter.calcMethod = (s) => { var _a; return (_a = CALC_METHODS[s]) !== null && _a !== void 0 ? _a : s; };
Formatter.percentage = (val) => {
    if (typeof val === 'string' || isNill(val)) {
        return val;
    }
    return val * 100 < 100
        ? `${(val * 100).toPrecision(3)}%`
        : `${Math.round(val * 100)}%`;
};
Formatter.numberRange = (start, end) => {
    if (![null, undefined, ''].includes(start) && start === end) {
        return start;
    }
    else if (start && end) {
        return `${start}-${end}`;
    }
    else if (start) {
        return `${start}+`;
    }
    else if (end) {
        return `< ${end}`;
    }
    else {
        return 'any';
    }
};
Formatter.duration = (val, opts = { truncate: undefined }) => {
    let res = prettyMilliseconds(+val, { secondsDecimalDigits: 0 });
    if ((opts === null || opts === void 0 ? void 0 : opts.truncate) === 'seconds') {
        res = res.replace(/ \d+s/, '');
    }
    return res;
};
Formatter.dateRange = (start, end, useUTC = true) => {
    const _dayjs = useUTC ? dayjs.utc : dayjs;
    const _startStr = _dayjs(start).format('MM/DD/YYYY');
    const _endStr = _dayjs(end).format('MM/DD/YYYY');
    const startStr = _startStr === 'Invalid Date' ? null : _startStr;
    const endStr = _endStr === 'Invalid Date' ? null : _endStr;
    if (!startStr && !endStr) {
        return 'Full date range';
    }
    else if (startStr && startStr === endStr) {
        return startStr;
    }
    else if (startStr && endStr) {
        return `${startStr}-${endStr}`;
    }
    else if (startStr) {
        return `${startStr} or later`;
    }
    else if (endStr) {
        return `${endStr} or earlier`;
    }
    else {
        return '';
    }
};
Formatter.compGridCriterion = (val) => {
    var _a, _b;
    return val
        ? `${(_a = val.comp_grid_product) === null || _a === void 0 ? void 0 : _a.type} • ${(_b = val.comp_grid_product) === null || _b === void 0 ? void 0 : _b.name} • Years (${Formatter.numberRange(val.policy_year_start, val.policy_year_end)}) • Ages (${Formatter.numberRange(val.issue_age_start, val.issue_age_end)})${val.compensation_type ? ` • ${val.compensation_type}` : ''}${val.transaction_type ? ` • ${val.transaction_type}` : ''}`
        : '';
};
Formatter.date = (val, useUTC = true, format = 'MM/DD/YYYY') => {
    const _dayjs = useUTC ? dayjs.utc : dayjs;
    return _dayjs(val).format(format);
};
Formatter.contact = (contact, opts = {
    incl_email: false,
    last_first: false,
}) => {
    var _a;
    const isTransGlobal = (opts === null || opts === void 0 ? void 0 : opts.account_id) === AccountIds.TRANSGLOBAL;
    const _lastFirst = (_a = (isTransGlobal || (opts === null || opts === void 0 ? void 0 : opts.last_first))) !== null && _a !== void 0 ? _a : false;
    if (!contact) {
        return '';
    }
    const arr = [];
    const nameArr = [];
    if (contact.first_name)
        nameArr.push(contact.first_name.trim());
    if (contact.last_name)
        nameArr.push(contact.last_name.trim());
    if (_lastFirst) {
        nameArr.reverse();
        arr.push(nameArr.join(', '));
    }
    else {
        arr.push(nameArr.join(' '));
    }
    if ((opts === null || opts === void 0 ? void 0 : opts.incl_email) && contact.email)
        arr.push(`(${contact.email.trim()})`);
    const str = arr.join(' ');
    return str;
};
Formatter.compGridRatesFields = (val) => {
    if (val === CompGridRateFields.CARRIER_RATE) {
        return 'Carrier Rate';
    }
    else if (val === CompGridRateFields.HOUSE_RATE) {
        return 'House Rate';
    }
    else if (val === CompGridRateFields.TOTAL_RATE) {
        return 'Total Rate';
    }
    else {
        return 'All';
    }
};
export default Formatter;
