import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, List, ListItem, ListItemText, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, } from '@mui/material';
const SearchableList = ({ items, count = 0, page, setPage, search, setSearch, onKeyPress, sort, setSort, limit, handleItemClick, listItemText, selectedItem, title, }) => {
    const handleShowMore = () => {
        setPage(page + 1);
    };
    const handleSortChange = (event, newSort) => {
        if (newSort !== null) {
            setPage(0);
            setSort(newSort);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", sx: { mt: 4, mb: 2, flexDirection: ['column', 'row'] }, children: [_jsx(Typography, { style: {
                            fontWeight: 'medium',
                            marginBottom: '10px',
                            whiteSpace: 'normal',
                            flex: 1,
                            marginRight: '10px',
                        }, children: title }), _jsxs(ToggleButtonGroup, { value: sort, exclusive: true, onChange: handleSortChange, sx: { height: '20px' }, color: "primary", children: [_jsx(ToggleButton, { value: "asc", sx: {
                                    borderRadius: '12px',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }, children: "A-Z" }), _jsx(ToggleButton, { value: "desc", sx: {
                                    borderRadius: '12px',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }, children: "Z-A" })] })] }), _jsx(Tooltip, { title: "Press Enter to search", children: _jsx(TextField, { label: "Search", value: search, onChange: (e) => setSearch(e.target.value), onKeyDown: onKeyPress, fullWidth: true }) }), _jsx(Box, { overflow: "auto", maxHeight: "60vh", sx: {
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                }, children: _jsx(List, { children: items === null || items === void 0 ? void 0 : items.map((item, index) => (_jsx(ListItem, { onClick: () => handleItemClick(item), sx: {
                            cursor: 'pointer',
                            borderRadius: '0 24px 24px 0',
                            px: 0.5,
                            py: 0.5,
                            backgroundColor: item === selectedItem ? '#2096f322' : 'inherit',
                        }, children: _jsx(ListItemText, { primary: listItemText(item), sx: { '& .MuiTypography-body1': { fontSize: '14px' } } }) }, index))) }) }), _jsx(Box, { display: "flex", justifyContent: "center", width: "100%", children: (page + 1) * limit <= count && (_jsx(Button, { onClick: handleShowMore, children: "Show more" })) })] }));
};
export default SearchableList;
