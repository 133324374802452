import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { RemoveCircleOutline } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, } from '@mui/material';
import { clone } from 'lodash-es';
import MultiSelect from '@/components/molecules/MultiSelect';
const transformers = [
    { id: 'toLowerCase', label: 'Convert to lower case' },
    { id: 'toUpperCase', label: 'Convert to upper case' },
    { id: 'removeDashes', label: 'Remove dashes' },
    { id: 'removeSpaces', label: 'Remove spaces' },
    { id: 'removeCommas', label: 'Remove commas' },
    { id: 'removePeriods', label: 'Remove periods' },
    { id: 'removeApostrophes', label: 'Remove apostrophes' },
    { id: 'normalizeSpaces', label: 'Normalize spaces' },
    { id: 'normalizeCompany', label: 'Normalize company names' },
    { id: 'normalizeSymbols', label: 'Normalize symbols (&)' },
    { id: 'trimZeros', label: 'Trim zeros (leading and trailing)' },
    { id: 'removeLetters', label: 'Remove letters (6+ numbers required)' },
    { id: 'removedashnn', label: 'Remove "-##" and "_##"' },
    { id: 'removespacenn', label: 'Remove " ##"' },
    { id: 'removeFromEnd', label: 'Remove last n characters (n=1 atm)' },
];
const additionalSettings = [
    { id: 'ignoreEmpty', label: 'Ignore empty values' },
    {
        id: 'startsWithMatch',
        label: 'Consider startsWith a match',
        tip: 'If the longer string starts with the shorter string, consider this a match (requires exact match)',
    },
    {
        id: 'simiarlityOnShorterLength',
        label: 'Perform similarity on shorter length',
        tip: 'Perform similarty scoring on the length of the shorter string (truncates longer string to same length as shorter string for comparison)',
    },
];
const emptyRow = { field: '', transformers: [], params: '' };
const SimilarityConfig = ({ fieldId, data: rowData, setter, reportFields, statementFields, }) => {
    var _a;
    const data = typeof rowData[fieldId] === 'string'
        ? [clone(emptyRow)]
        : ((_a = rowData[fieldId]) !== null && _a !== void 0 ? _a : [clone(emptyRow)]);
    return (_jsxs(Box, { children: [data === null || data === void 0 ? void 0 : data.map((datum, i) => (_jsxs(Box, { sx: { mb: 1, display: 'flex' }, children: [_jsxs(FormControl, { sx: { mr: 2 }, children: [_jsx(InputLabel, { id: datum.id, children: "Report field" }), _jsx(Select, { label: "Report field", labelId: datum.id, value: datum.reportField, onChange: (e) => {
                                    const newdatums = [...data];
                                    newdatums[i].reportField = e.target.value;
                                    setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                                }, sx: { minWidth: 180 }, children: reportFields === null || reportFields === void 0 ? void 0 : reportFields.map((field) => (_jsx(MenuItem, { value: field.id, children: field.label }, field.id))) })] }), _jsx(Box, { sx: { display: 'flex' }, children: _jsx(MultiSelect, { label: "Transformers", values: transformers, formatter: (transformer) => transformer.label, valuer: (transformer) => transformer.id, selectedValues: data[i].reportTransformers, setSelectedValues: (newValues) => {
                                const newdatums = [...data];
                                newdatums[i].reportTransformers =
                                    typeof newValues === 'string'
                                        ? newValues.split(',')
                                        : newValues;
                                setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                            }, sx: { width: 120, mr: 2 } }) }), _jsxs(FormControl, { sx: { mr: 2 }, children: [_jsx(InputLabel, { id: datum.id, children: "Statement field" }), _jsx(Select, { label: "Statement field", labelId: datum.id, value: datum.statementField, onChange: (e) => {
                                    const newdatums = [...data];
                                    newdatums[i].statementField = e.target.value;
                                    setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                                }, sx: { minWidth: 180 }, children: statementFields === null || statementFields === void 0 ? void 0 : statementFields.map((field) => (_jsx(MenuItem, { value: field.id, children: field.label }, field.id))) })] }), _jsx(Box, { sx: { display: 'flex' }, children: _jsx(MultiSelect, { label: "Transformers", values: transformers, formatter: (transformer) => transformer.label, valuer: (transformer) => transformer.id, selectedValues: data[i].statementTransformers, setSelectedValues: (newValues) => {
                                const newdatums = [...data];
                                newdatums[i].statementTransformers =
                                    typeof newValues === 'string'
                                        ? newValues.split(',')
                                        : newValues;
                                setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                            }, sx: { width: 120, mr: 2 } }) }), _jsx(Box, { sx: { display: 'flex' }, children: _jsx(MultiSelect, { label: "Settings", values: additionalSettings, formatter: (transformer) => transformer.label, valuer: (transformer) => transformer.id, selectedValues: data[i].additionalSettings, setSelectedValues: (newValues) => {
                                const newdatums = [...data];
                                newdatums[i].additionalSettings =
                                    typeof newValues === 'string'
                                        ? newValues.split(',')
                                        : newValues;
                                setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                            }, noall: true, sx: { width: 120, mr: 2 } }) }), _jsx(TextField, { label: "Threshold", value: datum.threshold, onChange: (e) => {
                            const newdatums = [...data];
                            newdatums[i].threshold = e.target.value;
                            setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                        }, sx: { mr: 2, maxWidth: 82 } }), _jsx(IconButton, { onClick: () => {
                            const newdatums = [...data];
                            newdatums.splice(i, 1);
                            setter(Object.assign(Object.assign({}, rowData), { [fieldId]: newdatums }));
                        }, children: _jsx(RemoveCircleOutline, {}) })] }, i))), _jsx(Button, { onClick: () => setter(Object.assign(Object.assign({}, rowData), { [fieldId]: [...data, clone(emptyRow)] })), children: "Add" })] }, fieldId));
};
export default SimilarityConfig;
