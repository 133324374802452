import { parseDate } from 'chrono-node';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import currency from 'currency.js';

import {
  isScientificNotation,
  getPrecisionLength,
  isNill,
  isDateStyle,
  isCurrencyStyle,
  isPercentageStyle,
  isValideDate,
  convertExcelDateToJSDate,
} from '../tools';
import { _isErrorValue } from './normalizer';

dayjs.extend(utc);

/**
 * Check if the date is in the valid range and format it
 * @param date date value
 */
const _validAndFormatDate = (date: string | number | Date, isUtc = true) => {
  const warnTip = `The date ${date} seems to be out of range (01/01/1980 - 01/01/2200)`;
  const dateTimestamp = dayjs(date).valueOf();
  if (isValideDate(dateTimestamp)) {
    if (isUtc) {
      return dayjs.utc(dateTimestamp).format('MM/DD/YYYY');
    }
    return dayjs(dateTimestamp).format('MM/DD/YYYY');
  } else {
    console.warn(warnTip);
    return `❌${date}`;
  }
};

const _keepAtLeastTwoDecimal = (value: number | string) => {
  const res = new BigNumber(value);
  const db = res.dp();
  if (db !== null && db < 2) {
    return res.toFixed(2);
  }
  return res;
};

/**
 * Format date type value to date string
 * @param date processed date value
 * @returns date string
 */
const formatDate = (date: string | number, isUtc = true) => {
  if (_isErrorValue(date)) {
    return date;
  }
  if (isNill(date)) {
    return null;
  }
  const trimStr = date.toString().trim();
  const {
    isTimestamp,
    isISODate,
    isUsDate: isUs,
    isCnDate: isCn,
  } = isDateStyle(trimStr);

  if (isTimestamp && trimStr.length === 5) {
    const timeVal = convertExcelDateToJSDate(+trimStr);
    return _validAndFormatDate(timeVal, isUtc);
  }

  if (isUs || isCn || isTimestamp || isISODate) {
    return _validAndFormatDate(trimStr, isUtc);
  }

  // Parse date in a string
  const natureStr = trimStr.replace(/[_]/g, '/');
  const parsedDate = parseDate(natureStr);
  if (parsedDate) {
    return _validAndFormatDate(parsedDate, isUtc);
  }

  // When grouping reports by policy_id the response can be '***' when a grouped field has different values
  if (trimStr === '***') return trimStr;

  return `❌${date}`;
};

/**
 * Format percentage to readable format
 * @param {string | number} percentage raw percentage: 0.1, "-10%", 10, "4.8500000000000005%" "(10%)" "(10,00%)"
 * @returns {string|null} formated percentage
 * */
const formatPercentage = (percentage: string | number) => {
  if (_isErrorValue(percentage)) {
    return percentage;
  }
  if (isNill(percentage)) {
    return null;
  }
  // When grouping reports by policy_id the response can be '***' when a grouped field has different values
  if (percentage === '***') return percentage;

  if (percentage === '%') {
    return '0%';
  }
  const trimStr = percentage.toString().trim().replaceAll(',', '.');
  if (isPercentageStyle(trimStr)) {
    const num = trimStr.replace(/[^0-9.-]+/g, '');
    return `${_keepAtLeastTwoDecimal(num)}%`;
  }
  // If percentage is contains special characters: - , (, ), %
  // eg: (-10%) => -10%, 10%- => -10%, (10%) => -10%,
  const regex = /[()-]/;
  const hasDash = regex.test(trimStr);
  // Got the number from string which still contains -, ),(
  let percentNum = trimStr.replace(/[^0-9.]+/g, '');
  if (percentNum === '') {
    return `❌'${percentage}'`;
  }
  if (hasDash) {
    percentNum = `-${percentNum}`;
  }
  return `${_keepAtLeastTwoDecimal(percentNum)}%`;
};

/**
 * Convert currency to readable format
 * @param currencyVal peocessed currency value
 * @returns string | null: eg: $100.00
 */
const formatCurrency = (val: string | number) => {
  if (_isErrorValue(val)) {
    return val;
  }
  const currencyVal = val;
  if (isNill(currencyVal)) {
    return null;
  }

  // When grouping reports by policy_id the response can be '***' when a grouped field has different values
  if (val === '***') return val;

  if (typeof +currencyVal === 'number' && !Number.isNaN(+currencyVal)) {
    return currency(currencyVal, { precision: 2 }).format();
  }

  // If currency is standard currency style
  const trimStr = currencyVal.toString().replaceAll(' ', '').trim();
  if (isCurrencyStyle(trimStr)) {
    const precisionLength = getPrecisionLength(currencyVal);
    return currency(currencyVal, { precision: precisionLength }).format();
  }

  // If currency is scientific notation style
  if (isScientificNotation(trimStr)) {
    const res = BigNumber(trimStr).toNumber();
    const precisionLength = getPrecisionLength(res);
    return currency(res, {
      precision: precisionLength,
    }).format();
  }
  // Got the number from string which still contains -, ),(
  const regex = /[()-]/;
  const hasDash = regex.test(trimStr);

  let result = trimStr.replace(/[^0-9.]+/g, '');
  if (hasDash) {
    result = `-${result}`;
  }
  const precisionLength = getPrecisionLength(result);
  const res = currency(result, {
    precision: precisionLength,
  }).format();
  const logText = ` ❌'${currencyVal}'`;
  return res !== '$0.00' ? res : logText;
};

const formatDatetime = (dateInput) => {
  if (!dateInput) {
    return '';
  }
  const date = dateInput instanceof Date ? dateInput : new Date(dateInput);

  // const options = {
  //   timeZone: 'America/New_York',
  //   year: 'numeric',
  //   month: '2-digit',
  //   day: '2-digit',
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   second: '2-digit',
  //   hour12: true,
  // };

  return date.toLocaleString('en');
};

export { formatDate, formatPercentage, formatCurrency, formatDatetime };
