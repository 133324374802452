import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import { areEqual, FixedSizeList } from 'react-window';
import { Box, Checkbox, colors, IconButton, Typography } from '@mui/material';
import GpsFixedOutlined from '@mui/icons-material/GpsFixedOutlined';
import { AllValue } from '../constants';
const ROW_HEIGHT = 40;
const Row = memo(({ index, style, data, }) => {
    const item = data.options[index];
    const isAll = item.value === AllValue;
    const isSelected = !!(item.value === AllValue
        ? data.isAllSelected
        : data.selectedValues[item.value]);
    const renderLabel = data.renderLabel && !isAll ? data.renderLabel : null;
    return (_jsxs(Box, { onClick: () => data.onChange(item), style: style, sx: {
            background: isSelected ? 'rgba(33, 150, 243, 0.12)' : 'white',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                background: 'rgba(33, 150, 243, 0.12)',
                '& .target-select-btn': {
                    visibility: 'visible',
                },
            },
        }, children: [data.multiple && _jsx(Checkbox, { checked: isSelected }), renderLabel ? (renderLabel({
                isSelected,
                key: item.value,
                sx: { flexGrow: 1 },
            })) : (_jsx(Typography, { sx: { flexGrow: 1, paddingLeft: !data.multiple ? 2 : 0 }, children: item.label })), data.multiple && item.value !== AllValue && (_jsx(IconButton, { className: "target-select-btn", sx: {
                    marginRight: 1,
                    opacity: 0.3,
                    visibility: 'hidden',
                    color: colors.blue['600'],
                    '&:hover': { opacity: 1 },
                }, onClick: (evt) => {
                    evt.stopPropagation();
                    data.onTargetSelect(item);
                }, children: _jsx(GpsFixedOutlined, { sx: { width: 16, height: 16 } }) }))] }));
}, areEqual);
export default function List({ itemData }) {
    return (_jsx(FixedSizeList, { itemData: itemData, height: ROW_HEIGHT * Math.min(itemData.options.length, 10), itemCount: itemData.options.length, itemSize: 40, width: '100%', itemKey: (index, _data) => _data.options[index].value, children: Row }));
}
