import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { dashboardWidgetOptions } from 'common/constants';
import DataView from '@/components/DataView';
import DataVisualizationTabs from '@/components/SettingsView/DataVisualizationTabs';
// TODO: Get this data from the fields table in db
const dashboardWidgetsAdmin = {
    label: '',
    table: 'accounts/settings/dashboard?roleId=1',
    editable: true,
    fields: [
        {
            id: 'insights_widgets',
            label: 'Widgets',
            type: 'select',
            options: Object.values(dashboardWidgetOptions),
            multiple: true,
            access: 'admin',
        },
    ],
};
const dashboardWidgetsProd = {
    label: '',
    table: 'accounts/settings/dashboard?roleId=2',
    editable: true,
    fields: [
        {
            id: 'insights_widgets',
            label: 'Widgets',
            type: 'select',
            options: Object.values(dashboardWidgetOptions),
            multiple: true,
            access: 'admin',
        },
    ],
};
const dataDesc = {
    label: 'Dashboard settings',
    table: 'accounts',
    editable: true,
    fields: [
        {
            id: 'effective_commissions_data_source',
            label: 'Effective commissions data source',
            type: 'select',
            options: ['Processed data', 'Commissions data'],
            access: 'admin',
        },
        {
            id: 'effective_commissions_exclude_zeros',
            label: 'Effective commissions exclude zeros',
            type: 'select',
            options: ['True', 'False'],
            access: 'admin',
        },
        {
            id: 'dashboard_filter_by_agent',
            label: 'Enable filter by agent',
            type: 'select',
            options: ['True', 'False'],
            access: 'admin',
        },
        {
            id: 'premium_source',
            label: 'Premium amount data source',
            type: 'select',
            options: ['Premium amount', 'Commissionable premium amount'],
            access: 'admin',
        },
    ],
};
const DashboardSettings = () => {
    return (_jsx(Box, { sx: { ml: 2 }, children: _jsx(Box, { children: _jsx(DataView, { dataDesc: dataDesc, formModeOnly: true }) }) }));
};
export default DashboardSettings;
