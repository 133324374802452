type DateOperationType = 'prevDay' | 'nextDay' | 'firstDay' | 'lastDay';
type DateInputFormat = 'auto' | 'dd/mm/yyyy';

const monthMap: { [key: string]: string } = {
  jan: '01',
  feb: '02',
  mar: '03',
  apr: '04',
  may: '05',
  jun: '06',
  jul: '07',
  aug: '08',
  sep: '09',
  oct: '10',
  nov: '11',
  dec: '12',
};

const dateProcessor = (
  input: string | string[],
  type?: DateOperationType,
  inputFormat: DateInputFormat = 'auto'
): string | string[] => {
  if (Array.isArray(input)) {
    return input.map((dateStr) =>
      processSingleDate(dateStr, type, inputFormat)
    );
  }
  return processSingleDate(input, type, inputFormat);
};

const processSingleDate = (
  dateStr: string,
  type?: DateOperationType,
  inputFormat: DateInputFormat = 'auto'
): string => {
  dateStr = dateStr.trim();

  const fullMonthDateRegex =
    /^(Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s+(\d{1,2}),\s+(\d{4})$/i;
  const fullMonthDateMatch = dateStr.match(fullMonthDateRegex);
  const dayMonthYearRegex =
    // eslint-disable-next-line no-useless-escape
    /^(\d{1,2})\s(Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sep(?:tember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\,\s(\d{4})$/i;
  const dayMonthYearMatch = dateStr.match(dayMonthYearRegex);

  let date: Date;

  if (fullMonthDateMatch) {
    // Handle "January 01, 2025" format
    const month = monthMap[fullMonthDateMatch[1].toLowerCase().substring(0, 3)];
    const day = fullMonthDateMatch[2].padStart(2, '0');
    const year = fullMonthDateMatch[3];
    date = new Date(`${year}-${month}-${day}`);
  } else if (dayMonthYearMatch) {
    // Handle "01 January, 2025" format
    const day = dayMonthYearMatch[1].padStart(2, '0');
    const month = monthMap[dayMonthYearMatch[2].toLowerCase().substring(0, 3)];
    const year = dayMonthYearMatch[3];
    date = new Date(`${year}-${month}-${day}`);
  } else {
    // Handle other formats (YYYY-MM-DD, DD/MM/YYYY, MM/DD/YYYY)
    let year, month, day;
    // eslint-disable-next-line no-useless-escape
    const parts = dateStr.split(/[-\/]/);

    // If the parts do not match the expected length, return "Invalid date"
    if (parts.length !== 3) return 'Invalid date';

    if (inputFormat === 'dd/mm/yyyy') {
      // DD/MM/YYYY format
      [day, month, year] = parts;
    } else if (parts[0].length === 4) {
      // YYYY-MM-DD or YYYY/MM/DD format
      [year, month, day] = parts;
    } else if (parts[2].length === 4) {
      // MM-DD-YYYY or MM/DD/YYYY format
      [month, day, year] = parts;
    } else {
      // Handle MM-DD-YY or MM/DD/YY format
      [month, day, year] = parts;
      year = '20' + year.padStart(2, '0');
    }

    month = month.padStart(2, '0');
    day = day.padStart(2, '0');
    date = new Date(`${year}-${month}-${day}`);
  }

  if (isNaN(date.getTime())) return 'Invalid date';

  // Handle date operations: prevDay, nextDay, firstDay, lastDay
  switch (type) {
    case 'prevDay':
      date.setDate(date.getDate() - 1);
      break;
    case 'nextDay':
      date.setDate(date.getDate() + 1);
      break;
    case 'firstDay':
      date.setDate(1);
      break;
    case 'lastDay':
      date.setMonth(date.getMonth() + 1, 0);
      break;
  }

  return formatDate(date);
};

// Helper function to format Date object to MM/DD/YYYY format
const formatDate = (date: Date): string => {
  const resultMonth = String(date.getMonth() + 1).padStart(2, '0');
  const resultDay = String(date.getDate()).padStart(2, '0');
  const resultYear = date.getFullYear();
  return `${resultMonth}/${resultDay}/${resultYear}`;
};

export const desc_dateProcessor = {
  name: 'libs.tools.dateProcessor(dateStr, type, inputFormat)',
  description:
    'Converts various date formats to MM/DD/YYYY format with optional date manipulations.',
  arguments: {
    dateStr: '[String] Date string to convert',
    type: '[String] Optional date operation: "prevDay", "nextDay", "firstDay", "lastDay"',
    inputFormat:
      '[String] Optional input format: "auto" (default) or "dd/mm/yyyy"',
  },
  returns:
    '[String] Date in MM/DD/YYYY format or "Invalid date" if conversion fails',
  examples: `dateProcessor("2024-01-15")
// → "01/15/2024"

dateProcessor("2024-01-15", "prevDay")
// → "01/14/2024"

dateProcessor("15/01/2024", undefined, "dd/mm/yyyy")
// → "01/15/2024"

dateProcessor("Jan 2024")
// → "01/01/2024"

dateProcessor("2024-01-15", "lastDay")
// → "01/31/2024"`,
};

export default dateProcessor;
