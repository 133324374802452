export var CustomMethodConditionOperator;
(function (CustomMethodConditionOperator) {
    CustomMethodConditionOperator["EQUAL"] = "eq";
    CustomMethodConditionOperator["NOT_EQUAL"] = "neq";
    CustomMethodConditionOperator["GREATER_THAN"] = "gt";
    CustomMethodConditionOperator["LESS_THAN"] = "lt";
    CustomMethodConditionOperator["GREATER_THAN_OR_EQUAL"] = "gte";
    CustomMethodConditionOperator["LESS_THAN_OR_EQUAL"] = "lte";
    CustomMethodConditionOperator["CONTAINED_IN"] = "containedin";
})(CustomMethodConditionOperator || (CustomMethodConditionOperator = {}));
export var CustomMethodConditionField;
(function (CustomMethodConditionField) {
    CustomMethodConditionField["ACCOUNT_TYPE"] = "account_type";
})(CustomMethodConditionField || (CustomMethodConditionField = {}));
export var CustomMethodConditionType;
(function (CustomMethodConditionType) {
    CustomMethodConditionType["PAYEE_LEVEL_RATE_DIFFERENCE_THRESHOLD"] = "payee_level_rate_difference_threshold";
    CustomMethodConditionType["COMPENSATION_TYPE"] = "compensation_type";
})(CustomMethodConditionType || (CustomMethodConditionType = {}));
export const CustomMethodConditionTypeLabel = {
    [CustomMethodConditionType.PAYEE_LEVEL_RATE_DIFFERENCE_THRESHOLD]: 'Payee level rate difference threshold',
    [CustomMethodConditionType.COMPENSATION_TYPE]: 'Compensation type',
};
export const CustomMethodConditionOperatorLabel = {
    [CustomMethodConditionOperator.EQUAL]: 'Equal',
    [CustomMethodConditionOperator.NOT_EQUAL]: 'Not equal',
    [CustomMethodConditionOperator.GREATER_THAN]: 'Greater than',
    [CustomMethodConditionOperator.LESS_THAN]: 'Less than',
    [CustomMethodConditionOperator.GREATER_THAN_OR_EQUAL]: 'Greater than or equal',
    [CustomMethodConditionOperator.LESS_THAN_OR_EQUAL]: 'Less than or equal',
    [CustomMethodConditionOperator.CONTAINED_IN]: 'Contained in',
};
export const CustomMethodOperatorOptions = Object.values(CustomMethodConditionOperator).map((operator) => ({
    value: operator,
    label: CustomMethodConditionOperatorLabel[operator],
}));
