import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, Chip } from '@mui/material';
import { isValidElement, useState } from 'react';
import { Link } from 'react-router-dom';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import API from '@/services/API';
const LazyLoadDynamicSelect = ({ data, header, formatter = (val) => val, link, }) => {
    const [expanded, setExpanded] = useState(false);
    let queryParams = `is_dynamic_select=true&ids=${encodeURIComponent(data.join(','))}`;
    if (header.queryParamNameTable && header.queryParamTable) {
        queryParams += `&${encodeURIComponent(header.queryParamNameTable)}=${encodeURIComponent(header.queryParamTable)}`;
    }
    const { data: _items, isLoading } = API.getBasicQuery(header.table, queryParams, expanded && data.length > 0);
    // Some endpoints return data in data property, while others don't >_<
    const items = Array.isArray(_items === null || _items === void 0 ? void 0 : _items.data) ? _items.data : _items;
    return (_jsx(Box, { children: !isLoading ? (_jsxs(_Fragment, { children: [_jsx(Button, { endIcon: data.length > 0 ? (expanded ? (_jsx(ExpandLessOutlined, {})) : (_jsx(ExpandMoreOutlined, {}))) : null, onClick: () => data.length > 0 && setExpanded(!expanded), sx: {
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        color: 'text.primary',
                    }, children: `${data.length} ${header.label.toLowerCase()}` }), expanded && (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.25, mt: 0.5 }, children: Array.isArray(items) &&
                        (items === null || items === void 0 ? void 0 : items.map((item) => {
                            const formattedItem = formatter(item);
                            return isValidElement(formattedItem) ? (formattedItem) : link ? (_jsx(Chip, { label: formatter(item), component: Link, to: `${link}?id=${item.str_id}`, clickable: true }, item.id)) : (_jsx(Chip, { label: formatter(item) }, item.id));
                        })) }))] })) : (_jsx(LoadingCircle, {})) }));
};
export default LazyLoadDynamicSelect;
