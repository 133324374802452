import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Home as HomeIcon } from '@mui/icons-material';
import { Box, Fab } from '@mui/material';
import { Link } from 'react-router-dom';
import EmptyState from '@/components/EmptyState';
import { ReactComponent as NotFoundIllustration } from '@/illustrations/not-found.svg';
const NotFoundPage = () => {
    return (_jsx(EmptyState, { image: _jsx(NotFoundIllustration, {}), title: "Page doesn\u2019t exist.", description: "The page you\u2019re trying to access doesn\u2019t exist.", button: _jsxs(Fab, { variant: "extended", color: "primary", component: Link, to: "/", children: [_jsx(Box, { mr: 1, children: _jsx(HomeIcon, {}) }), "Home"] }) }));
};
export default NotFoundPage;
