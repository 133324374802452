import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
const DataUpdatePreviewModal = ({ open, handleClose, handleDataUpdatePost, loading, previewData = [], fieldsToPreview, }) => {
    const fieldsIds = fieldsToPreview.map((field) => field.id);
    return (_jsxs(Dialog, { open: open, onClose: handleClose, maxWidth: "md", fullWidth: true, children: [_jsx(DialogTitle, { children: "Data Update Preview" }), _jsx(DialogContent, { children: _jsx(TableContainer, { component: Paper, sx: {
                        margin: 2,
                        maxHeight: 500,
                        width: 800,
                        overflow: 'auto',
                        border: '1px solid #ccc',
                    }, children: _jsxs(Table, { stickyHeader: true, sx: { borderCollapse: 'collapse' }, size: "small", children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { border: '1px solid #ccc' }, children: "Preview fields" }), _jsx(TableCell, { sx: { border: '1px solid #ccc' }, children: "Fields to change" })] }) }), _jsx(TableBody, { children: previewData.map((data, index) => {
                                    const unchangedFields = {};
                                    const changedFields = {};
                                    Object.keys(data).forEach((key) => {
                                        if (key !== 'data_entity_id') {
                                            if (fieldsIds.includes(key)) {
                                                unchangedFields[key] = data[key];
                                            }
                                            else {
                                                changedFields[key] = data[key];
                                            }
                                        }
                                    });
                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { sx: { border: '1px solid #ccc' }, children: Object.entries(unchangedFields).map(([key, value]) => (_jsxs("div", { children: [_jsxs("strong", { children: [key, ":"] }), ' ', typeof value === 'object'
                                                            ? JSON.stringify(value)
                                                            : value] }, key))) }), _jsx(TableCell, { sx: { border: '1px solid #ccc' }, children: Object.entries(changedFields).map(([key, value]) => (_jsxs("div", { children: [_jsxs("strong", { children: [key, ":"] }), ' ', typeof value === 'object'
                                                            ? JSON.stringify(value)
                                                            : value] }, key))) })] }, index));
                                }) })] }) }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, color: "primary", children: "Cancel" }), _jsx(LoadingButton, { loading: loading, variant: "contained", onClick: () => handleDataUpdatePost(false), sx: { ml: 1 }, children: "Run data update" })] })] }));
};
export default DataUpdatePreviewModal;
