import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Formatter from 'common/Formatter';
import { useEffect, useState } from 'react';
import API from '@/services/API';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 2,
};
const ReviewerSelector = ({ open, setOpen, setReviewer }) => {
    var _a;
    const [dataReady, setDataReady] = useState(false);
    const [reviewerSelected, setReviewerSelected] = useState({
        uid: null,
        first_name: null,
        last_name: null,
        email: null,
    });
    const data = (_a = API.getBasicQuery('users/get_fintary_admins')) === null || _a === void 0 ? void 0 : _a.data;
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick')
            return;
        setOpen(false);
    };
    const handleChange = (event) => {
        setReviewerSelected(JSON.parse(event.target.value));
    };
    const handleSelect = () => {
        setReviewer(reviewerSelected);
        setOpen(false);
    };
    useEffect(() => {
        if (data && Array.isArray(data)) {
            data.forEach((elem) => {
                if (elem.email === 'yu@fintary.com') {
                    setReviewerSelected(elem);
                }
            });
            setDataReady(true);
        }
    }, [data]);
    return (_jsx("div", { children: _jsx(Modal, { open: open, onClose: handleClose, children: _jsxs(Box, { sx: style, children: [_jsx(Typography, { variant: "h6", component: "h2", children: "Select reviewer" }), _jsx(Box, { sx: { mt: 2 }, children: dataReady ? (_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: "select-reviewer", children: "Reviewer" }), _jsx(Select, { labelId: "select-reviewer", value: JSON.stringify(reviewerSelected), label: "Reviewer", onChange: handleChange, children: data.map((elem) => (_jsx(MenuItem, { value: JSON.stringify(elem), children: Formatter.contact(elem, { incl_email: true }) }, elem.str_id || elem.uid))) })] })) : ('Loading...') }), _jsxs(Box, { sx: { mt: 3, display: 'flex', justifyContent: 'flex-end' }, children: [_jsx(Button, { variant: "outlined", onClick: (e) => handleClose(e, null), sx: { mr: 1 }, children: "Cancel" }), _jsx(Button, { variant: "contained", onClick: handleSelect, children: "Select" })] })] }) }) }));
};
export default ReviewerSelector;
