var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { LoadingContext } from 'contexts/LoadingContext';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { Roles } from '@/types';
const WidgetSelector = ({ sharedWidgets, dashboardSettings, closeAddWidgetDialog, dashboardName, }) => {
    var _a, _b, _c;
    const [dashboardWidgetsAdmin, setDashboardWidgetsAdmin] = useState((_a = (dashboardSettings && dashboardSettings[Roles.ACCOUNT_ADMIN])) !== null && _a !== void 0 ? _a : []);
    const [dashboardWidgetsProducer, setDashboardWidgetsProducer] = useState((_b = (dashboardSettings && dashboardSettings[Roles.PRODUCER])) !== null && _b !== void 0 ? _b : []);
    const [dashboardWidgetsDataSpecialist, setDashboardWidgetsDataSpecialist] = useState((_c = (dashboardSettings && dashboardSettings[Roles.DATA_SPECIALIST])) !== null && _c !== void 0 ? _c : []);
    const { setLoadingConfig } = useContext(LoadingContext);
    const { showSnackbar } = useSnackbar();
    const saveWidgetSettingPoster = API.getMutation('insights/accountWidgetsSettings', 'PUT');
    const saveWidgetSetting = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingConfig({
            loading: true,
            message: 'Saving widget setting...',
        });
        const data = {
            adminWidgetsSettings: dashboardWidgetsAdmin,
            producerWidgetsSettings: dashboardWidgetsProducer,
            dataSpecialistWidgetsSettings: dashboardWidgetsDataSpecialist,
            dashboardName,
        };
        try {
            yield saveWidgetSettingPoster.mutateAsync(data);
        }
        catch (error) {
            showSnackbar(`Error save widget setting :${error}`, 'error');
        }
        setLoadingConfig({
            loading: false,
        });
    });
    const handleFieldChange = (value, name) => {
        if (name === 'admin') {
            setDashboardWidgetsAdmin(value);
        }
        else if (name === 'producer') {
            setDashboardWidgetsProducer(value);
        }
        else {
            setDashboardWidgetsDataSpecialist(value);
        }
    };
    return (_jsxs(Box, { sx: { padding: 2, minWidth: '400px' }, children: [_jsx(Typography, { variant: "h6", children: "Choose widgets" }), _jsxs(Box, { padding: 2, children: [_jsx(Typography, { variant: "caption", children: "Admin" }), _jsx(Select, { value: dashboardWidgetsAdmin, style: { width: '100%' }, label: "Widgets", multiple: true, onChange: (e) => handleFieldChange(e.target.value, 'admin'), children: sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.map((field) => (_jsx(MenuItem, { value: field.id, children: field.name }, field.id))) }), _jsx(Typography, { variant: "caption", children: "Producer" }), _jsx(Select, { value: dashboardWidgetsProducer, label: "Widgets", style: { width: '100%' }, multiple: true, onChange: (e) => handleFieldChange(e.target.value, 'producer'), children: sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.map((field) => (_jsx(MenuItem, { value: field.id, children: field.name }, field.id))) }), _jsx(Typography, { variant: "caption", children: "Data specialist" }), _jsx(Select, { value: dashboardWidgetsDataSpecialist, label: "Widgets", style: { width: '100%' }, multiple: true, onChange: (e) => handleFieldChange(e.target.value, 'dataSpecialist'), children: sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.map((field) => (_jsx(MenuItem, { value: field.id, children: field.name }, field.id))) }), _jsx(Box, { sx: {
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '10px',
                            borderBottom: '1px solid #e0e0e0',
                            position: 'fixed',
                            bottom: '0',
                            width: '100%',
                            background: 'inherit',
                        }, children: _jsxs(Box, { children: [_jsx(Button, { variant: "contained", onClick: saveWidgetSetting, children: "Save" }), _jsx(Button, { variant: "outlined", style: { marginLeft: '10px' }, onClick: closeAddWidgetDialog, children: "Close" })] }) })] })] }));
};
export default WidgetSelector;
