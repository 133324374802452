import { jsx as _jsx } from "react/jsx-runtime";
import { Clear, Search } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { useAccountStore } from '@/store';
const SearchBox = ({ id = '' }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState(searchParams.get('q'));
    const [queryDebounced] = useDebounce(query !== null && query !== void 0 ? query : '', 500);
    const queryRef = useRef(null);
    const { selectedAccount } = useAccountStore();
    useEffect(() => {
        setQuery(searchParams.get('q'));
    }, [searchParams]);
    const updateSearchParams = useCallback((kvMap) => {
        setSearchParams((prev) => {
            Object.entries(kvMap).forEach(([k, v]) => {
                if ([undefined, null, ''].includes(v)) {
                    prev.delete(k);
                }
                else {
                    prev.set(k, v);
                }
            });
            return prev;
        });
    }, [setSearchParams]);
    useEffect(() => {
        updateSearchParams({ q: queryDebounced });
    }, [queryDebounced, updateSearchParams]);
    return (_jsx(TextField, { name: `search-${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId}-${id}`, variant: "outlined", value: query !== null && query !== void 0 ? query : '', inputRef: queryRef, onChange: (e) => setQuery(e.target.value), onKeyDown: (e) => {
            var _a;
            if (e.key === 'Escape') {
                (_a = queryRef === null || queryRef === void 0 ? void 0 : queryRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            }
        }, autoFocus: true, id: `search-${id}`, autoComplete: `search-${id}`, InputProps: {
            startAdornment: _jsx(Search, { sx: { opacity: 0.5 } }),
            endAdornment: (_jsx(IconButton, { onClick: () => {
                    setQuery('');
                }, children: _jsx(Clear, { sx: { opacity: 0.5 } }) })),
            style: { height: '34px' },
        } }));
};
export default SearchBox;
