var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { validateData, } from 'common/validators/contacts-bulk-add-upline';
import DataBulkAdd from '@/components/DataBulkAdd';
import useSnackbar from '@/contexts/useSnackbar';
import { useBulkUpdateUpline } from '@/api/contacts';
const fields = [
    { id: 'key_column', required: true },
    { id: 'key_data', required: true },
    { id: 'agent_upline', required: true },
];
export const BulkUpdateAgentUpline = () => {
    const mutation = useBulkUpdateUpline();
    const { showSnackbar } = useSnackbar();
    const onSave = (_a) => __awaiter(void 0, [_a], void 0, function* ({ data }) {
        mutation.reset();
        const { errRows, validData } = validateData(data);
        console.log(errRows);
        if (errRows.length) {
            showSnackbar(`Invalid data at rows: ${errRows.join(', ')}`, 'error');
            return;
        }
        mutation.mutate(validData, {
            onSuccess: () => {
                showSnackbar('Updated', 'success');
            },
            onError: (res) => {
                showSnackbar(res.message || 'Something went wrong, please try again!', 'error');
            },
        });
    });
    return (_jsx(DataBulkAdd, { isLoading: mutation.isPending, fields: fields, onCancel: () => { }, onSave: onSave, btnLabel: "Update" }));
};
