import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { NotificationsNone, DeveloperBoard } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Alert, Badge, Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tooltip, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API from '@/services/API';
import { States } from '@/types';
import { useAccountStore } from '@/store';
export const Notification = () => {
    var _a;
    const navigate = useNavigate();
    const { userState } = useAccountStore();
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
    const [versionNotification, setVersionNotification] = useState();
    const { data: notifications } = API.getBasicQuery('notifications', '', (userState === null || userState === void 0 ? void 0 : userState.userOverallState) === States.ACTIVE);
    useEffect(() => {
        const versionListener = (evt) => {
            setVersionNotification({
                id: evt.detail.buildTime,
                type: 'version',
                text: (_jsx(Alert, { severity: "info", onClick: (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        localStorage.setItem('triggerReload', Date.now().toString());
                        window.location.reload();
                    }, children: "A new version is available. Click to reload and update." })),
            });
        };
        window.addEventListener('version-update', versionListener);
        return () => window.removeEventListener('version-update', versionListener);
    }, []);
    const openNotifications = (event) => {
        const notificationsAnchorEl = event.currentTarget;
        setNotificationsAnchorEl(notificationsAnchorEl);
    };
    const closeNotifications = () => {
        setNotificationsAnchorEl(null);
    };
    const getNotificationIcon = (notification) => {
        switch (notification.type) {
            case 'processor':
                return _jsx(DeveloperBoard, {});
            default:
                return null;
        }
    };
    const total = ((_a = notifications === null || notifications === void 0 ? void 0 : notifications.length) !== null && _a !== void 0 ? _a : 0) + (versionNotification ? 1 : 0);
    const hasNotification = !!total;
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: !hasNotification ? 'No new notifications' : `${total} notifications`, children: _jsx(IconButton, { onClick: openNotifications, children: _jsx(Badge, { badgeContent: total, color: "warning", children: _jsx(NotificationsNone, { sx: {
                                color: hasNotification ? '#fff' : '#eee',
                            } }) }) }) }), _jsx(Menu, { anchorEl: notificationsAnchorEl, open: Boolean(notificationsAnchorEl), onClose: closeNotifications, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, children: _jsx(Box, { children: _jsx(MenuList, { sx: { py: 0 }, children: hasNotification ? ([versionNotification, ...(notifications || [])].map((notification) => (_jsxs(MenuItem, { onClick: () => {
                                navigate(notification.link);
                            }, children: [notification.type !== 'version' && (_jsx(ListItemIcon, { children: getNotificationIcon(notification) })), _jsx(ListItemText, { children: notification.text })] }, notification.id)))) : (_jsx(MenuItem, { disabled: true, children: "No notifications" })) }) }) })] }));
};
