import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Navigate } from 'react-router-dom';
import validator from 'validator';
import { SyncedEntity } from '@/common/SyncedEntity';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import DataView from '@/components/DataView';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import API from '@/services/API';
import { useIsBulkAddMode } from '@/hooks/useIsBulkAddMode';
import { FieldTypes } from '@/types';
const dataDesc = {
    label: 'Companies',
    table: 'companies',
    editable: true,
    copyable: true,
    bulkAdd: true,
    validateData: (data) => !!(data === null || data === void 0 ? void 0 : data.company_name),
    fields: [
        {
            id: 'company_name',
            label: 'Company Name',
            required: true,
        },
        {
            id: 'email',
            label: 'Email',
            validator: (val) => validator.isEmail(val || '') || val === '',
        },
        {
            id: 'website',
            label: 'Website',
            validator: (val) => validator.isURL(val || '') || val === '',
        },
        {
            id: 'phone',
            label: 'Phone',
            validator: (val) => validator.isMobilePhone(val || '', 'en-US') || val === '',
        },
        {
            id: 'address',
            label: 'Address',
        },
        {
            id: 'notes',
            label: 'Notes',
        },
        {
            id: 'type',
            label: 'Type',
            type: FieldTypes.SELECT,
            multiple: true,
            options: [
                'Carrier',
                'MGA/MGU',
                'General Agency',
                'Sub Agency',
                'Customer',
                'Partner',
            ],
            strToValue: (vals) => (vals === null || vals === void 0 ? void 0 : vals.split(',').map((v) => v.trim())) || undefined,
        },
        {
            id: 'divider',
            type: FieldTypes.DIVIDER,
            access: 'admin',
            label: 'Admin',
        },
        {
            id: 'access',
            label: 'Access',
            type: FieldTypes.SELECT,
            options: ['account', 'global'],
            access: 'admin',
        },
    ],
    actions: [
        {
            id: 'sync',
            label: 'Sync',
            type: 'custom',
            getComponent: (row) => (_jsx(SyncedEntity, { isSynced: !!row.sync_id, disabled: true })),
        },
    ],
};
const CompaniesView = () => {
    var _a, _b;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.companies;
    const { isBulkAddMode } = useIsBulkAddMode();
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();
    dataDesc.fields.forEach((field) => {
        field.readOnly =
            dataDesc.bulkAdd && isBulkAddMode
                ? false
                : (data) => {
                    var _a;
                    const syncedFields = (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.companies;
                    if (syncedFields === null || syncedFields === void 0 ? void 0 : syncedFields.includes(field.id)) {
                        return isSyncedField(data, syncedFields, field.id, data.config);
                    }
                    return false;
                };
        field.endAdornment = (data, field, setNewData) => {
            var _a;
            return (_jsx(SyncEndAdornment, { syncedFields: (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.companies, syncId: data === null || data === void 0 ? void 0 : data.sync_id, fieldId: field === null || field === void 0 ? void 0 : field.id, data: data, fieldType: field.type, onChange: (newOverrideFields) => {
                    setNewData(Object.assign(Object.assign({}, data), { config: Object.assign(Object.assign({}, (data.config || {})), { overrideFields: newOverrideFields }) }));
                } }));
        };
    });
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return _jsx(Navigate, { to: "/settings" });
    }
    return (_jsx(DataView, { dataDesc: dataDesc, readOnly: viewOnly, viewOnly: viewOnly, enablePagination: true }));
};
export default CompaniesView;
