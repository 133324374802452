import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, TextField } from '@mui/material';
import { useState } from 'react';
const GenericTool = ({ inputLabel = 'Input', outputLabel = 'Output', outFn, }) => {
    const [input, setInput] = useState('');
    return (_jsx(Box, { display: "flex", children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(TextField, { label: inputLabel, multiline: true, minRows: 16, value: input, onChange: (event) => {
                            setInput(event.target.value);
                        }, sx: { width: '100%' }, maxRows: 32 }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(TextField, { label: outputLabel, multiline: true, minRows: 16, value: outFn(input), sx: { width: '100%' }, maxRows: 32 }) })] }) }));
};
export default GenericTool;
