import { jsx as _jsx } from "react/jsx-runtime";
import { ButtonGroup } from '@mui/material';
import { actionButtonsStyle } from './styles';
export const HoverActionButtonContainer = ({ children, className, alwaysShow, }) => {
    let defaultClassName = 'action-buttons';
    let sx = {};
    if (alwaysShow) {
        defaultClassName = '';
        sx = {
            width: 'auto',
            overflow: 'visible',
        };
    }
    return (_jsx(ButtonGroup, { variant: "contained", className: `${className || ''} flex ${defaultClassName}`, sx: Object.assign(Object.assign({}, actionButtonsStyle), sx), children: children }));
};
