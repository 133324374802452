var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Autorenew, Delete, Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Divider, Toolbar, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import DataForm from '@/components/DataForm';
import MultiSelect from '@/components/molecules/MultiSelect';
// TODO: Since bulk edit is being used in more places, we should find a way to separate fields based on the page where it is being used
const fieldIds = [
    'policy_id',
    'agent_name',
    'writing_carrier_name',
    'carrier_name',
    'customer_name',
    'product_type',
    'product_name',
    'effective_date',
    'contacts',
    'notes',
    'agent_commissions_status',
    'agent_commissions_status2',
    'compensation_type',
    'transaction_type',
    'policy_status',
    'product_option_name',
    'processing_date',
    'payment_date',
    'period_date',
    'internal_id',
    'split_percentage',
    'premium_type',
    'agent_commissions',
    'report_notes',
    'status', // Mainly for saved_report status bulk edit
    'document_id',
    'agent_id',
    'tags',
];
const FieldSelector = ({ fields, fieldIds, onFieldsChange }) => {
    const [selectedFieldsIds, setSelectedFieldsIds] = useState([]);
    const foundFields = fieldIds
        .map((id) => fields.find((field) => field.id === id))
        .filter(Boolean);
    useEffect(() => {
        const selectedFields = selectedFieldsIds
            .map((id) => fields.find((field) => field.id === id))
            .filter(Boolean);
        onFieldsChange(selectedFields);
    }, [selectedFieldsIds]);
    return (_jsx(Box, { sx: { mt: 2 }, children: _jsx(MultiSelect, { label: "Fields to edit", values: foundFields, selectedValues: selectedFieldsIds, setSelectedValues: setSelectedFieldsIds, formatter: (field) => field.label, valuer: (field) => field.id, sx: { width: '100%' } }) }));
};
const EnhancedTableToolbar = ({ title, selected, setSelected, onDelete, onSync, headers: fields, onEdit, customActions = false, }) => {
    const [isWorkingToolbar, setIsWorkingToolbar] = useState(false);
    const [open, setOpen] = useState(false);
    const [newDatum, setNewDatum] = useState({});
    const [dataDesc, setDataDesc] = useState({ fields: [] });
    const numSelected = selected.length;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        borderRadius: 2,
        pt: 1,
        px: 2,
        maxHeight: '90vh',
        overflowY: 'auto',
    };
    const handleClose = (event, reason) => {
        if (reason && reason == 'backdropClick')
            return;
        setOpen(false);
        setIsWorkingToolbar(false);
        setNewDatum({});
    };
    const handleFieldsChange = (selectedFields) => {
        setDataDesc({
            fields: selectedFields.map((header) => (Object.assign(Object.assign({}, header), { enableNullCheckbox: true }))),
        });
    };
    return numSelected > 0 ? (_jsxs(_Fragment, { children: [_jsxs(Toolbar, { sx: Object.assign({ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }, (numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                })), variant: "dense", children: [numSelected > 0 ? (_jsxs(Typography, { sx: { flex: '1 1 100%' }, color: "inherit", variant: "subtitle1", component: "div", children: [numSelected, " selected"] })) : (_jsx(Typography, { sx: { flex: '1 1 100%' }, variant: "h6", id: "tableTitle", component: "div", children: title })), numSelected > 0 && !customActions && onEdit instanceof Function && (_jsx(Tooltip, { title: "Bulk edit", children: _jsx(LoadingButton, { loading: isWorkingToolbar, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setIsWorkingToolbar(true);
                                setOpen(true);
                            }), sx: { minWidth: 48 }, children: _jsx(Edit, {}) }) })), numSelected > 0 && !customActions && onDelete instanceof Function && (_jsx(Tooltip, { title: "Delete", children: _jsx(LoadingButton, { loading: isWorkingToolbar, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                if (confirm('Are you sure you want to delete the selected items?')) {
                                    setIsWorkingToolbar(true);
                                    yield onDelete(selected);
                                    setIsWorkingToolbar(false);
                                }
                            }), sx: { minWidth: 48 }, children: _jsx(Delete, {}) }) })), numSelected > 0 && !customActions && onSync instanceof Function && (_jsx(Tooltip, { title: "Sync", children: _jsx(LoadingButton, { loading: isWorkingToolbar, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                if (confirm('Are you sure you want to sync the selected items?')) {
                                    setIsWorkingToolbar(true);
                                    yield onSync(selected);
                                    setIsWorkingToolbar(false);
                                }
                            }), sx: { minWidth: 48 }, children: _jsx(Autorenew, {}) }) }))] }), _jsx(Modal, { open: open, onClose: handleClose, sx: { overflowY: 'scroll' }, children: _jsxs(Box, { sx: style, children: [_jsx(Typography, { variant: "h6", component: "h2", children: "Bulk editor" }), _jsx(FieldSelector, { fields: fields, fieldIds: fieldIds, onFieldsChange: handleFieldsChange }), _jsx(Divider, { sx: { my: 1 } }), _jsx(DataForm, { dataDesc: { saveOnly: true }, fields: dataDesc.fields, newData: newDatum, setNewData: setNewDatum, onCancel: () => {
                                setIsWorkingToolbar(false);
                                setOpen(false);
                            }, onSave: () => __awaiter(void 0, void 0, void 0, function* () {
                                yield onEdit(selected, newDatum);
                                setIsWorkingToolbar(false);
                                setOpen(false);
                                setSelected([]);
                                setNewDatum({});
                            }), formModeOnly: false, embed: true, fetchOnFieldChange: true })] }) })] })) : (_jsx(_Fragment, {}));
};
export default EnhancedTableToolbar;
