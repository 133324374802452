var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import validator from 'validator';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useUserInfo } from '@/hooks/useUserInfo';
const ActionDialog = ({ open, setOpen, rowData, companies, documentProfiles, }) => {
    const { showSnackbar } = useSnackbar();
    const [uploading, setUploading] = useState(false);
    const [formData, setFormData] = useState({
        bank_total_amount: '',
        check_date: null,
        company_str_id: '',
        profile_str_id: '',
        deposit_date: null,
        notes: '',
        status: '',
        type: '',
    });
    const [error, setError] = useState(false);
    const { data: { fintaryAdmin } = {} } = useUserInfo();
    const statusList = useMemo(() => [
        {
            label: 'New',
            value: 'new',
        },
        {
            label: 'Processed',
            value: 'processed',
        },
    ], []);
    const typeList = useMemo(() => [
        {
            label: 'Statement',
            value: 'statement',
        },
        {
            label: 'Report',
            value: 'report',
        },
    ], []);
    const apiPutter = API.getMutation('documents', 'PUT');
    useEffect(() => {
        if (rowData) {
            const { bank_total_amount, check_date, company_str_id, profile_str_id, deposit_date, notes, status, type, } = rowData;
            setFormData({
                bank_total_amount,
                check_date,
                company_str_id,
                profile_str_id,
                deposit_date,
                notes,
                status,
                type,
            });
        }
    }, [rowData]);
    const onSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setUploading(true);
        const { id, str_id } = rowData;
        const body = Object.assign(Object.assign({}, formData), { str_id,
            id });
        try {
            const result = yield apiPutter.mutateAsync(body);
            setUploading(false);
            if (result.error) {
                showSnackbar(result.error, 'error');
                return;
            }
            if (result) {
                setOpen(false, 'save');
            }
        }
        catch (error) {
            showSnackbar((error === null || error === void 0 ? void 0 : error.message) || error, 'error');
            setUploading(false);
        }
    });
    const onFormChange = (key, value) => {
        setFormData((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
    };
    return (_jsxs(Dialog, { open: open, maxWidth: "md", fullWidth: true, sx: { background: 'transparent', p: 1 }, onClose: () => setOpen(false), disableEscapeKeyDown: true, children: [_jsx(DialogTitle, { children: _jsx(Box, { className: "flex items-center", children: "Edit document" }) }), _jsx(IconButton, { className: "group absolute p-4 right-0 top-0 cursor-pointer hover:text-blue-600", onClick: () => setOpen(false), children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                    p: 0,
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                }, children: _jsxs(Box, { sx: { width: '100%', maxHeight: '700px', p: 2 }, children: [_jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1 }, children: [_jsx(InputLabel, { children: "Type" }), _jsx(Select, { id: "select-type", value: formData.type, label: "Type", onChange: (e) => {
                                        onFormChange('type', e.target.value);
                                    }, children: typeList.map((item) => (_jsx(MenuItem, { value: item.value, children: item.label }, item.value))) })] }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1 }, children: [_jsx(InputLabel, { children: "Company" }), _jsx(Select, { id: "select-carrier", value: formData.company_str_id, label: "Company", onChange: (e) => {
                                        onFormChange('company_str_id', e.target.value);
                                    }, children: (companies || []).map((c) => (_jsx(MenuItem, { value: c.str_id, children: _jsx(Box, { sx: {
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: 1,
                                            }, children: _jsx(Box, { sx: { flex: 1 }, children: c.company_name }) }) }, c.str_id))) })] }), fintaryAdmin && (_jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1 }, children: [_jsx(InputLabel, { children: "Document profiles \uD83D\uDD12" }), _jsx(Select, { id: "select-document-profiles", value: formData.profile_str_id, label: "Document profiles \uD83D\uDD12", onChange: (e) => {
                                        onFormChange('profile_str_id', e.target.value);
                                    }, children: (documentProfiles || []).map((c) => (
                                    // TODO: change c.str_id to c.name (Since most of docuemnt_profiles.name is null currently)
                                    _jsx(MenuItem, { value: c.str_id, children: _jsx(Box, { sx: {
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: 1,
                                            }, children: _jsx(Box, { sx: { flex: 1 }, children: [c.name, c.str_id].join(' - ') }) }) }, c.str_id))) })] })), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1 }, children: [_jsx(InputLabel, { children: "Status" }), _jsx(Select, { id: "select-type", value: formData.status, label: "Status", onChange: (e) => {
                                        onFormChange('status', e.target.value);
                                    }, children: statusList.map((item) => (_jsx(MenuItem, { value: item.value, children: item.label }, item.value))) })] }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1 }, children: _jsx(TextField, { label: "Bank total", variant: "outlined", value: formData.bank_total_amount, error: error, helperText: error ? 'Invalid currency format' : '', onChange: (e) => {
                                    let val = e.target.value;
                                    const scientificNotationRegex = /^[+-]?\d+(\.\d+)?([eE][+-]?\d+)?$/;
                                    if (scientificNotationRegex.test(val)) {
                                        const numericValue = parseFloat(val);
                                        if (!isNaN(numericValue)) {
                                            val = numericValue.toLocaleString('fullwide', {
                                                useGrouping: false,
                                            });
                                        }
                                    }
                                    const isValidCurrency = validator.isCurrency(val, {
                                        allow_negatives: true,
                                        thousands_separator: ',',
                                        decimal_separator: '.',
                                        allow_decimal: true,
                                        digits_after_decimal: [1, 2],
                                    });
                                    if (!isValidCurrency ||
                                        parseFloat(val) > 1e12 ||
                                        parseFloat(val) < -1e12) {
                                        setError(true);
                                    }
                                    else {
                                        setError(false);
                                    }
                                    onFormChange('bank_total_amount', e.target.value);
                                } }) }), _jsx(Box, { children: _jsx(BasicDatePicker, { label: "Check date", value: formData.check_date, setValue: (e) => onFormChange('check_date', e), sx: { width: 250 } }) }), _jsx(Box, { children: _jsx(BasicDatePicker, { label: "Deposit date", value: formData.deposit_date, setValue: (e) => onFormChange('deposit_date', e), sx: { my: 1, width: 250 } }) }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1 }, children: _jsx(TextField, { label: "Notes", variant: "outlined", multiline: true, rows: 4, value: formData.notes, onChange: (e) => {
                                    onFormChange('notes', e.target.value);
                                } }) })] }) }), _jsxs(DialogActions, { sx: { pt: 0, pb: 2, px: 2 }, children: [_jsx(Button, { onClick: () => setOpen(false), children: "Cancel" }), _jsx(LoadingButton, { onClick: onSubmit, loading: uploading, variant: "contained", sx: { width: '100px' }, disabled: error, children: "Save" })] })] }));
};
export default ActionDialog;
