import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { Roles } from '@/types';

const inviteFormSchema = z
  .object({
    email: z.string().email({ message: 'Invalid email address' }),
    roleId: z.number({ message: 'Role is required' }),
    agentId: z.number({ message: 'Agent is required' }).optional(),
  })
  .refine(
    (data) => {
      if (data.roleId === Roles.PRODUCER) {
        return !!data.agentId;
      }
      return true;
    },
    { message: 'Agent is required', path: ['agentId'] }
  );

export type InviteFormValues = z.infer<typeof inviteFormSchema>;

export const useInviteForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm<InviteFormValues>({
    resolver: zodResolver(inviteFormSchema),
  });

  return {
    register,
    handleSubmit,
    errors,
    watch,
    getValues,
    setValue,
  };
};
