var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PlayArrow } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, TextField } from '@mui/material';
import { useState } from 'react';
import API from '@/services/API';
const processReconciliation = (options, documentId) => __awaiter(void 0, void 0, void 0, function* () {
    const { deDupe, redo, testRun, flowId } = options || {};
    const res = yield fetch(`${process.env.REACT_APP_API}/api/reconciliation/reconcile`, {
        method: 'POST',
        headers: yield API.getHeaders(),
        body: JSON.stringify({
            deDupe,
            redo,
            testRun,
            flowId,
            documentId,
        }),
    });
    console.log('processReconciliation: ', yield res.json());
    return res;
});
const DocumentReconciler = ({ flowId }) => {
    var _a;
    const { data } = API.getBasicQuery('documents');
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const statementData = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.filter((datum) => datum.type === 'statement');
    return (statementData && (_jsxs(Box, { sx: { display: 'flex', flexDirection: 'row' }, children: [_jsx(Autocomplete, { disablePortal: true, id: "document", options: statementData, renderInput: (params) => _jsx(TextField, Object.assign({}, params, { label: "Document" })), getOptionLabel: (option) => `${option.filename} (${option === null || option === void 0 ? void 0 : option.str_id} - ${new Date(option === null || option === void 0 ? void 0 : option.created_at).toLocaleString()})`, value: value, onChange: (event, newValue) => {
                    setValue(newValue);
                }, inputValue: inputValue, onInputChange: (event, newInputValue) => {
                    setInputValue(newInputValue);
                }, sx: { width: 300 } }), _jsx(LoadingButton, { disabled: !(value === null || value === void 0 ? void 0 : value.str_id), loading: isLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setIsLoading(true);
                    yield processReconciliation({
                        deDupe: false,
                        redo: true,
                        testRun: true,
                        flowId,
                    }, value === null || value === void 0 ? void 0 : value.str_id);
                    setIsLoading(false);
                }), children: _jsx(PlayArrow, {}) })] })));
};
export default DocumentReconciler;
