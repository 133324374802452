import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
const FieldRow = ({ children }) => (_jsx(Box, { sx: {
        display: 'flex',
        flexDirection: 'row',
        mb: 1,
        width: '100%',
        justifyContent: 'space-between',
    }, children: children }));
export default FieldRow;
