import { useSearchParams } from 'react-router-dom';
import { DocumentPreviewKeys } from '@/types';
const usePreviewParams = () => {
    var _a;
    const [searchParams, setSearchParams] = useSearchParams({});
    const setShowPreview = (val) => setSearchParams((prev) => {
        if (val) {
            prev.set('m', DocumentPreviewKeys.PREVIEW);
        }
        else {
            prev.delete('m');
            prev.delete('id');
            prev.delete('t');
        }
        return prev;
    });
    const setPreviewPath = (val, previewType, isId = true) => setSearchParams((prev) => {
        if (val) {
            if (isId)
                prev.set('id', val);
            prev.set('id', val);
            if (previewType === DocumentPreviewKeys.ORIGINAL)
                prev.set('t', DocumentPreviewKeys.ORIGINAL);
            else if (previewType === DocumentPreviewKeys.OVERRIDE)
                prev.set('t', DocumentPreviewKeys.OVERRIDE);
        }
        else {
            prev.delete('id');
            prev.delete('t');
        }
        return prev;
    });
    const showPreview = searchParams.get('m') === DocumentPreviewKeys.PREVIEW;
    const previewId = (_a = searchParams.get('id')) !== null && _a !== void 0 ? _a : '';
    const previewType = searchParams.get('t');
    return {
        showPreview,
        setShowPreview,
        previewId,
        setPreviewPath,
        previewType,
    };
};
export default usePreviewParams;
