var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Tabs, Tab, Typography, Chip, DialogActions, Button, Alert, } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Formatter from 'common/Formatter';
import DateRangeManager from '@/components/molecules/DateRangeManager';
import { BasicDialog } from '@/common';
import { DateRangesTypes } from '@/types';
const SelectedCriteria = ({ selectedCriteria, }) => {
    return (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body2", sx: { fontWeight: 'bold' }, children: "Selected criteria" }), selectedCriteria.map((criteria, index) => (_jsx(Chip, { label: Formatter.compGridCriterion(criteria), sx: { mr: 0.5, mb: 0.5 } }, `${index}-criterion`)))] }));
};
const CompGridBulkActions = ({ openBulkActionsDialog, setOpenBulkActionsDialog, selectedCriteria, selectedLevels, selectedDateRanges, setSelectedDateRanges, loading, bulkEditRates, bulkCopyRates, bulkDeleteRates, showConfirmDeleteDialog, setShowConfirmDeleteDialog, }) => {
    var _a, _b;
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [activeDialogTab, setActiveDialogTab] = useState(0);
    return (_jsxs(Dialog, { open: openBulkActionsDialog, onClose: () => {
            setOpenBulkActionsDialog(false);
            setSelectedDateRanges([]);
        }, fullWidth: true, children: [_jsx(DialogTitle, { children: "Bulk actions" }), _jsxs(DialogContent, { children: [_jsxs(Tabs, { value: activeDialogTab, onChange: (e, v) => setActiveDialogTab(v), children: [_jsx(Tab, { label: "Edit" }), _jsx(Tab, { label: "Copy" }), _jsx(Tab, { label: "Delete" })] }), activeDialogTab === 0 && (_jsxs(Box, { sx: { mt: 1 }, children: [_jsxs(Box, { sx: { mt: 1, mb: 1 }, children: [Array.isArray(selectedCriteria) &&
                                        selectedCriteria.length > 0 && (_jsx(SelectedCriteria, { selectedCriteria: selectedCriteria })), selectedLevels.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body2", sx: { fontWeight: 'bold' }, children: "Editing rates for levels" }), selectedLevels.map((level) => (_jsx(Chip, { sx: { mr: 0.5 }, label: level }, level)))] })) : (_jsx(Typography, { variant: "body2", children: "No rates selected" }))] }), _jsx(DateRangeManager, { row: selectedDateRanges, setter: setSelectedDateRanges, field: { id: 'date_ranges', label: 'Rates date ranges' }, type: DateRangesTypes.ANY }, "comp_grid_criteria_date_ranges"), _jsxs(DialogActions, { sx: { p: 0 }, children: [_jsx(Button, { sx: { ml: 1 }, onClick: () => {
                                            setOpenBulkActionsDialog(false);
                                            setSelectedDateRanges([]);
                                        }, children: "Cancel" }), _jsx(LoadingButton, { sx: { ml: 1 }, variant: "contained", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                            yield bulkEditRates();
                                        }), loading: loading, disabled: (selectedLevels === null || selectedLevels === void 0 ? void 0 : selectedLevels.length) === 0 ||
                                            ((_a = selectedDateRanges === null || selectedDateRanges === void 0 ? void 0 : selectedDateRanges.date_ranges) === null || _a === void 0 ? void 0 : _a.length) === 0, children: "Bulk edit" })] })] })), activeDialogTab === 1 && (_jsxs(Box, { sx: { mt: 1 }, children: [_jsxs(Box, { sx: { mt: 1, mb: 1 }, children: [Array.isArray(selectedCriteria) &&
                                        selectedCriteria.length > 0 && (_jsx(SelectedCriteria, { selectedCriteria: selectedCriteria })), selectedLevels.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body2", sx: { fontWeight: 'bold' }, children: "Copying rates for levels" }), selectedLevels.map((level) => (_jsx(Chip, { sx: { mr: 0.5 }, label: level }, level)))] })) : (_jsx(Typography, { variant: "body2", children: "No rates selected" }))] }), _jsx(DateRangeManager, { row: selectedDateRanges, setter: setSelectedDateRanges, field: { id: 'date_ranges', label: 'New rates date ranges' }, type: DateRangesTypes.ANY }, "comp_grid_criteria_date_ranges"), _jsxs(DialogActions, { sx: { p: 0 }, children: [_jsx(Button, { sx: { ml: 1 }, onClick: () => {
                                            setOpenBulkActionsDialog(false);
                                            setSelectedDateRanges([]);
                                        }, children: "Cancel" }), _jsx(LoadingButton, { sx: { ml: 1 }, variant: "contained", onClick: () => {
                                            setShowConfirmDialog(true);
                                        }, loading: loading, disabled: (selectedLevels === null || selectedLevels === void 0 ? void 0 : selectedLevels.length) === 0 ||
                                            ((_b = selectedDateRanges === null || selectedDateRanges === void 0 ? void 0 : selectedDateRanges.date_ranges) === null || _b === void 0 ? void 0 : _b.length) === 0, children: "Bulk copy" }), showConfirmDialog && (_jsx(BasicDialog, { open: showConfirmDialog, title: "Bulk copy rates", bodyComponent: _jsx(Alert, { severity: "warning", children: "Are you sure you want to bulk copy these rates?" }), onClose: (isOk) => __awaiter(void 0, void 0, void 0, function* () {
                                            setShowConfirmDialog(false);
                                            if (isOk) {
                                                yield bulkCopyRates();
                                            }
                                        }) }))] })] })), activeDialogTab === 2 && (_jsxs(Box, { sx: { mt: 1 }, children: [_jsxs(Box, { sx: { mt: 1, mb: 1 }, children: [Array.isArray(selectedCriteria) &&
                                        selectedCriteria.length > 0 && (_jsxs(_Fragment, { children: [_jsx(SelectedCriteria, { selectedCriteria: selectedCriteria }), _jsx(Typography, { variant: "body2", sx: { fontWeight: 'bold' }, children: "Selected date range" }), selectedCriteria.map((criteria, index) => (_jsx(Chip, { label: Formatter.compGridCriterion(criteria), sx: { mr: 0.5, mb: 0.5 } }, `${index}-criterion`)))] })), selectedLevels.length > 0 ? (_jsxs(_Fragment, { children: [_jsx(Typography, { variant: "body2", sx: { fontWeight: 'bold' }, children: "Deleting rates for levels" }), selectedLevels.map((level) => (_jsx(Chip, { sx: { mr: 0.5 }, label: level }, level)))] })) : (_jsx(Typography, { variant: "body2", children: "No rates selected" }))] }), _jsxs(DialogActions, { sx: { p: 0 }, children: [_jsx(Button, { sx: { ml: 1 }, onClick: () => {
                                            setOpenBulkActionsDialog(false);
                                        }, children: "Cancel" }), _jsx(LoadingButton, { sx: { ml: 1 }, variant: "contained", onClick: () => {
                                            setShowConfirmDeleteDialog(true);
                                        }, loading: loading, disabled: (selectedLevels === null || selectedLevels === void 0 ? void 0 : selectedLevels.length) === 0, children: "Bulk delete" }), showConfirmDeleteDialog && (_jsx(BasicDialog, { open: showConfirmDeleteDialog, title: "Bulk delete rates", bodyComponent: _jsx(Alert, { severity: "warning", children: "Are you sure you want to delete these rates?" }), onClose: (isOk) => __awaiter(void 0, void 0, void 0, function* () {
                                            setShowConfirmDeleteDialog(false);
                                            if (isOk) {
                                                yield bulkDeleteRates();
                                            }
                                        }) }))] })] }))] })] }));
};
export default CompGridBulkActions;
