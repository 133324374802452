var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, FormGroup, InputLabel, Link, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from '@mui/material';
import { useEffect, useState } from 'react';
import API from '@/services/API';
import CopyButton from '@/components/atoms/CopyButton';
import useSnackbar from '@/contexts/useSnackbar';
const GenerateLink = () => {
    const [linkParams, setLinkParams] = useState({
        amount: 0,
        paymentTypes: [],
    });
    return (_jsxs(FormGroup, { sx: {
            display: 'flex',
            flexDirection: 'row',
            mb: 1,
            justifyContent: 'center',
        }, children: [_jsx(TextField, { label: "Amount", value: linkParams.amount, sx: { mr: 1, width: 160 } }), _jsxs(FormControl, { sx: { mr: 1, minWidth: 180 }, children: [_jsx(InputLabel, { children: "Payment methods" }), _jsxs(Select, { label: "Payment methods", multiple: true, value: linkParams.paymentTypes, onChange: ({ target: { value } }) => {
                            setLinkParams(Object.assign(Object.assign({}, linkParams), { paymentTypes: typeof value === 'string' ? value.split(',') : value }));
                        }, children: [_jsx(MenuItem, { value: 'ACH', children: "ACH" }), _jsx(MenuItem, { value: 'CREDIT_CARD', children: "Credit Card" })] })] }), _jsx(Button, { variant: "contained", disabled: true, children: "Generate link" })] }));
};
// Form that takes in a url and has a button to call backend api to disable other stripe payment methods
const UpdatePamentLink = () => {
    const [paymentLinkId, setPaymentLinkId] = useState('');
    const [loading, setLoading] = useState(false);
    // const { data: paymentLinks } = API.getBasicQuery('stripe/payment_links');
    const poster = API.getMutation('stripe/payment_links', 'POST');
    return (_jsxs(Box, { children: [_jsx(TextField, { label: "Payment Id", value: paymentLinkId, onChange: ({ target: { value } }) => setPaymentLinkId(value), sx: { width: 400, mr: 1 } }), _jsx(LoadingButton, { loading: loading, variant: "outlined", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setLoading(true);
                    yield poster.mutateAsync({
                        paymentLinkId,
                        paymentMethods: ['us_bank_account'],
                    });
                    setLoading(false);
                }), sx: { mr: 1 }, children: "ACH only" }), _jsx(LoadingButton, { loading: loading, variant: "outlined", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    setLoading(true);
                    yield poster.mutateAsync({
                        paymentLinkId,
                        paymentMethods: ['us_bank_account', 'card', 'link'],
                    });
                    setLoading(false);
                }), children: "Enable credit card" })] }));
};
const PaymentLinks = () => {
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState({});
    const { data: paymentLinks } = API.getBasicQuery('stripe/payment_links');
    const poster = API.getMutation('stripe/payment_links', 'POST');
    const { showSnackbar } = useSnackbar();
    useEffect(() => {
        setLinks(paymentLinks || []);
    }, [paymentLinks]);
    return (_jsxs(Box, { children: [_jsx(Box, { sx: { mb: 1, textAlign: 'center' }, children: _jsx(UpdatePamentLink, {}) }), _jsx(Typography, { variant: "h6", children: "Payment links" }), _jsx(Box, { children: _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Id" }), _jsx(TableCell, { children: "Link" }), _jsx(TableCell, { children: "Actions" })] }) }), _jsx(TableBody, { children: links.map((link) => (_jsxs(TableRow, { children: [_jsxs(TableCell, { sx: {
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }, children: [link.id, _jsx(CopyButton, { value: link.id })] }), _jsxs(TableCell, { children: [_jsx(Link, { href: link.url, target: "_blank", children: link.url }), _jsx(CopyButton, { value: link.url })] }), _jsxs(TableCell, { children: [_jsx(LoadingButton, { loading: loading[link.id], variant: "outlined", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                        try {
                                                            setLoading(Object.assign(Object.assign({}, loading), { [link.id]: true }));
                                                            yield poster.mutateAsync({
                                                                paymentLinkId: link.id,
                                                                paymentMethods: ['us_bank_account'],
                                                            });
                                                        }
                                                        catch (e) {
                                                            showSnackbar(e.message, 'error');
                                                        }
                                                        finally {
                                                            setLoading(Object.assign(Object.assign({}, loading), { [link.id]: false }));
                                                        }
                                                    }), sx: { mr: 1 }, children: "ACH only" }), _jsx(LoadingButton, { loading: loading[link.id], variant: "outlined", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                        try {
                                                            setLoading(Object.assign(Object.assign({}, loading), { [link.id]: true }));
                                                            yield poster.mutateAsync({
                                                                paymentLinkId: link.id,
                                                                paymentMethods: ['us_bank_account', 'card', 'link'],
                                                            });
                                                        }
                                                        catch (e) {
                                                            showSnackbar(e.message, 'error');
                                                        }
                                                        finally {
                                                            setLoading(Object.assign(Object.assign({}, loading), { [link.id]: false }));
                                                        }
                                                    }), children: "Enable credit card" })] })] }, link.id))) })] }) }) })] }));
};
export default PaymentLinks;
