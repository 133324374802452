var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, } from '@mui/material';
import { isValidJsonString } from 'common/helpers';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import BasicTable from '@/components/molecules/BasicTable';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import useSnackbar from '@/contexts/useSnackbar';
import fillDown from '@/common/tools/fillDown';
const parseRange = (range) => {
    const result = {
        min: null,
        max: null,
    };
    if (!range)
        return result;
    const cleanedRange = range.replace(/\s+/g, '');
    const rangePattern = /^(\d+)-(\d+)$/;
    const minPattern = /^>(\d+)$/;
    const maxPattern = /^<(\d+)$/;
    const minPlusPattern = /^(\d+)\+$/;
    if (rangePattern.test(cleanedRange)) {
        const [, min, max] = cleanedRange.match(rangePattern);
        result.min = Number(min);
        result.max = Number(max);
    }
    else if (minPattern.test(cleanedRange)) {
        const [, min] = cleanedRange.match(minPattern);
        result.min = Number(min) + 1;
    }
    else if (maxPattern.test(cleanedRange)) {
        const [, max] = cleanedRange.match(maxPattern);
        result.max = Number(max) - 1;
    }
    else if (minPlusPattern.test(cleanedRange)) {
        const [, min] = cleanedRange.match(minPlusPattern);
        result.min = Number(min);
    }
    else {
        throw new Error(`Invalid range format: "${range}"`);
    }
    return result;
};
const dropColumnsByStrings = (data, colsToDrop, opts = { substringMatch: true }) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const columnIndexes = headerRow
        .map((header, index) => colsToDrop.some((substring) => opts.substringMatch
        ? header.toLowerCase().includes(substring.toLowerCase())
        : header.toLowerCase() === substring.toLowerCase())
        ? index
        : -1)
        .filter((index) => index !== -1);
    if (columnIndexes.length === 0) {
        throw new Error(`No headers include any of the substrings "${colsToDrop.join(', ')}".`);
    }
    return data.map((row) => row.filter((_, index) => !columnIndexes.includes(index)));
};
const combineHeaders = (data) => {
    if (data.length < 2) {
        throw new Error('The input array must have at least two rows.');
    }
    const firstRow = data[0];
    const secondRow = data[1];
    const combinedHeader = [];
    let currentPrefix = '';
    const levels = [];
    for (let i = 0; i < secondRow.length; i++) {
        if (firstRow[i]) {
            currentPrefix = firstRow[i];
            levels.push(currentPrefix);
        }
        combinedHeader.push(currentPrefix && secondRow[i] !== 'TG rate effective date'
            ? `${currentPrefix}::${secondRow[i]}`
            : secondRow[i]);
    }
    return {
        levels,
        data: [combinedHeader, ...data.slice(2)],
    };
};
const duplicateColumn = (data, header, newHeader, transformer = (data) => data) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const columnIndex = headerRow.indexOf(header);
    if (columnIndex === -1) {
        throw new Error(`Header "${header}" not found.`);
    }
    const newHeaderRow = [...headerRow, newHeader];
    const newData = data.map((row, rowIndex) => {
        if (rowIndex === 0) {
            return newHeaderRow;
        }
        const newCol = transformer(row[columnIndex]);
        const newRow = [...row, newCol];
        return newRow;
    });
    return newData;
};
const padToHeaderLength = (data) => {
    if (data.length === 0)
        throw new Error('The input array must not be empty.');
    const headerRow = data[0];
    const headerLength = headerRow.length;
    const paddedData = data.map((row) => {
        const paddedRow = [...row];
        while (paddedRow.length < headerLength) {
            paddedRow.push(null);
        }
        return paddedRow;
    });
    return paddedData;
};
const splitByPrefix = (data, sharedFields, levels, fields) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const result = {};
    levels.forEach((level) => {
        const newHeaderRow = [...sharedFields, ...fields];
        const newData = data.map((row, i) => {
            if (i === 0)
                return newHeaderRow;
            const newRow = [];
            newHeaderRow.forEach((header) => {
                const index = sharedFields.includes(header)
                    ? headerRow.indexOf(header)
                    : headerRow.indexOf(`${level}::${header}`);
                newRow.push(index !== -1 ? row[index] : null);
            });
            return newRow;
        });
        result[level] = newData;
    });
    return result;
};
const splitByLevelDupeShared = (data, sharedFields, levelField) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const result = {};
    const levels = headerRow.filter((header) => !sharedFields.includes(header));
    levels.forEach((level) => {
        const newHeaderRow = [...sharedFields, levelField];
        const newData = data.map((row, i) => {
            if (i === 0)
                return newHeaderRow;
            const newRow = [];
            newHeaderRow.forEach((header) => {
                const index = sharedFields.includes(header)
                    ? headerRow.indexOf(header)
                    : headerRow.indexOf(level);
                newRow.push(index !== -1 ? row[index] : null);
            });
            return newRow;
        });
        result[level] = newData;
    });
    return result;
};
const renameHeaders = (data, generateNewHeader) => {
    if (data.length === 0) {
        throw new Error('The input array must not be empty.');
    }
    const headerRow = data[0];
    const newHeaderRow = headerRow.map((header) => generateNewHeader(header));
    return [newHeaderRow, ...data.slice(1)];
};
const addColWithFill = (data, newHeader, fillValue) => {
    const headerRow = data[0];
    const newHeaderRow = [...headerRow, newHeader];
    const newData = data.map((row, rowIndex) => {
        if (rowIndex === 0) {
            return newHeaderRow;
        }
        const newRow = [...row, fillValue];
        return newRow;
    });
    return newData;
};
const parseTGGrid = (jsonRes, metadata) => {
    let { levels, data: gridData } = combineHeaders(jsonRes);
    levels = levels.filter((level) => ['TG', 'AT', 'A0', 'A1', 'A2', 'A3', 'S', 'A', 'B'].includes(level));
    gridData = gridData.filter((row) => row.some((cell) => cell !== null));
    gridData = padToHeaderLength(gridData);
    gridData = dropColumnsByStrings(gridData, [
        'Bonus',
        'Broker',
        'Contract code',
        'LIBRA',
        'TG::Pass Thru',
        'TG::Gross',
    ]);
    gridData = renameHeaders(gridData, (header) => {
        return header
            .replace('Gross Comm', 'total_rate')
            .replace('Comm from TG', 'house_rate')
            .replace('Comm from Carrier', 'carrier_rate')
            .replace('TG rate effective date', 'effective_date')
            .replace('Carrier Grid Effective Date', 'effective_date')
            .replace('TG Stmt\n (3)', 'total_rate')
            .replace('Carrier', 'grid_name')
            .replace('Product Type', 'product_type')
            .replace('Product', 'product_name')
            .replace('Commission Type', 'compensation_type')
            .replace('Age Group', 'age_range');
    });
    levels.forEach((level) => {
        gridData = duplicateColumn(gridData, 'effective_date', `${level}::effective_date`);
    });
    gridData = duplicateColumn(gridData, 'age_range', 'issue_age_start', (range) => parseRange(range).min);
    gridData = duplicateColumn(gridData, 'age_range', 'issue_age_end', (range) => parseRange(range).max);
    const gridsByLevels = splitByPrefix(gridData, [
        'grid_name',
        'product_type',
        'product_name',
        'compensation_type',
        'issue_age_start',
        'issue_age_end',
    ], levels, ['effective_date', 'carrier_rate', 'house_rate', 'total_rate']);
    console.log('gridsByLevels', gridsByLevels);
    return gridsByLevels;
};
const hasRequiredRates = (data) => {
    const rateIndexMap = data[0].reduce((acc, header, index) => {
        if (['carrier_rate', 'house_rate', 'total_rate'].includes(header)) {
            acc[header] = index;
        }
        return acc;
    }, {});
    const rateIndicies = Object.values(rateIndexMap);
    return data.filter((row, index) => index === 0 || rateIndicies.some((i) => row[i] !== null));
};
const parseBAGrid = (jsonRes, metadata) => {
    let gridData = jsonRes;
    gridData = fillDown(gridData, [
        'AI Product Name',
        'AI Carrier ID',
        'AI Product ID',
        'Types',
        'State',
    ]);
    gridData = dropColumnsByStrings(gridData, ['AI Carrier ID', 'AI Product ID', 'State', 'Product'], { substringMatch: false });
    gridData = renameHeaders(gridData, (header) => {
        return header
            .replace('Types', 'product_type')
            .replace('AI Product Name', 'product_name')
            .replace('Compensation Type', 'compensation_type')
            .replace('Sub Name', 'age_range');
    });
    gridData = duplicateColumn(gridData, 'age_range', 'issue_age_start', (range) => (range ? parseRange(range.replace(/Ages?/g, '')).min : null));
    gridData = duplicateColumn(gridData, 'age_range', 'issue_age_end', (range) => range ? parseRange(range.replace(/Ages?/g, '')).max : null);
    gridData = dropColumnsByStrings(gridData, ['age_range']);
    gridData = addColWithFill(gridData, 'grid_name', metadata.gridName);
    gridData = addColWithFill(gridData, 'compensation_type', 'Override');
    gridData = splitByLevelDupeShared(gridData, [
        'grid_name',
        'product_type',
        'product_name',
        'issue_age_start',
        'issue_age_end',
        'compensation_type',
    ], 'carrier_rate');
    gridData = Object.fromEntries(Object.entries(gridData).map(([k, v]) => [k, hasRequiredRates(v)]));
    return gridData;
};
const parsers = {
    TransGlobal: parseTGGrid,
    BrokersAlliance: parseBAGrid,
};
const DataBulkAdd = ({ params, handleUpdateParams, onCancel, onSave, btnLabel = 'Bulk add', isLoading = false, }) => {
    const [csv, setCsv] = useState('');
    const [grids, setGrids] = useState({});
    const { showSnackbar } = useSnackbar();
    const [parser, setParser] = useState('');
    const [selectedGrid, setSelectedGrid] = useState('');
    const [gridName, setGridName] = useState('');
    const [createGridStructure, setCreateGridStructure] = useState(true);
    useEffect(() => {
        var _a;
        try {
            setGrids({});
            const csvSheet = XLSX.read(csv, {
                type: 'string',
                raw: true,
            });
            const jsonRes = XLSX.utils.sheet_to_json(csvSheet.Sheets[csvSheet.SheetNames[0]], { raw: true, header: 1 });
            const gridsByLevels = parser
                ? (_a = parsers === null || parsers === void 0 ? void 0 : parsers[parser]) === null || _a === void 0 ? void 0 : _a.call(parsers, jsonRes, { gridName })
                : {};
            setGrids(gridsByLevels);
        }
        catch (e) {
            console.error(e);
        }
    }, [csv, gridName, parser]);
    return (_jsxs(Box, { sx: { width: '100%', px: 1 }, children: [_jsx(Typography, { children: "Import grid in csv/tsv format" }), _jsx(EnhancedSelect, { options: ['', ...Object.keys(parsers)], value: parser, onChange: setParser, sx: { mt: 1 } }), parser === 'BrokersAlliance' && (_jsx(TextField, { label: "Comp grid name", value: gridName, onChange: (e) => setGridName(e.target.value), fullWidth: true, sx: { mt: 1 } })), _jsx(TextField, { label: "CSV/TSV", multiline: true, rows: 8, value: csv, onChange: (event) => {
                    setCsv(event.target.value);
                }, sx: { width: '100%', mt: 1 }, fullWidth: true }), params && (_jsx(TextField, { label: "Params", value: params, onChange: handleUpdateParams, sx: { width: '100%', mt: 1 }, fullWidth: true, error: !isValidJsonString(params), helperText: !isValidJsonString(params) && 'Invalid JSON string' })), _jsxs(Box, { sx: { mt: 1, display: 'flex', justifyContent: 'flex-end' }, children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: createGridStructure, onChange: (e) => setCreateGridStructure(e.target.checked) }), disabled: true, label: "Create grid structure" }), _jsx(Button, { sx: { mr: 1 }, onClick: onCancel, children: "Cancel" }), _jsx(Button, { variant: "contained", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            try {
                                yield onSave({ data: grids });
                            }
                            catch (e) {
                                console.error(e);
                                showSnackbar(e.message, 'error');
                            }
                        }), disabled: (params && !isValidJsonString(params)) || isLoading, children: btnLabel })] }), Object.keys(grids).length > 0 && (_jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Typography, { children: "Summary" }), _jsxs(Typography, { variant: "body2", children: ["Levels (", Object.keys(grids).length, "):", ' ', Object.entries(grids)
                                .map(([level, grid]) => `${level} (${Array.isArray(grid) ? grid.length : 0})`)
                                .join(', ')] }), _jsx(EnhancedSelect, { options: ['', ...Object.keys(grids)], value: selectedGrid, onChange: setSelectedGrid, sx: { mt: 1 }, enableSearch: true }), selectedGrid && (_jsx(Box, { sx: { mt: 2 }, children: _jsx(BasicTable, { headers: grids[selectedGrid][0], rows: grids[selectedGrid].slice(1) }) }))] }))] }));
};
export default DataBulkAdd;
