var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ObjectSelect } from './components/ObjectSelect';
import { StringSelect } from './components/StringSelect';
import { AsyncSelect } from './components/AsyncSelect';
export const EnhancedSelect = (_a) => {
    var { options } = _a, rest = __rest(_a, ["options"]);
    if (rest.url)
        return _jsx(AsyncSelect, Object.assign({ options: options }, rest));
    const isStringSelect = typeof options[0] === 'string' || typeof options[0] === 'number';
    if (isStringSelect)
        return _jsx(StringSelect, Object.assign({ options: options }, rest));
    return _jsx(ObjectSelect, Object.assign({ options: options }, rest));
};
