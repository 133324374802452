import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import FieldRow from './FieldRow';
import NullCheckbox from './NullCheckbox'; // Make sure to import NullCheckbox from the correct path
const FieldComponent = ({ field, newData, formattedData, setNewData, readOnly, navigate, oldData, }) => {
    const [localValue, setLocalValue] = useState(typeof field.formatter === 'function'
        ? field.formatter((newData === null || newData === void 0 ? void 0 : newData[field.id]) || field.default || '', newData, navigate, 'form')
        : ![null, undefined].includes(newData === null || newData === void 0 ? void 0 : newData[field.id])
            ? newData === null || newData === void 0 ? void 0 : newData[field.id]
            : field.default || '');
    useEffect(() => {
        setLocalValue(typeof field.formatter === 'function'
            ? field.formatter((newData === null || newData === void 0 ? void 0 : newData[field.id]) || field.default || '', newData, navigate, 'form')
            : ![null, undefined].includes(newData === null || newData === void 0 ? void 0 : newData[field.id])
                ? newData === null || newData === void 0 ? void 0 : newData[field.id]
                : field.default || '');
    }, [newData === null || newData === void 0 ? void 0 : newData[field.id]]);
    const handleInputChange = (e) => {
        const { value } = e.target;
        setLocalValue(value);
    };
    return (_jsxs(FieldRow, { children: [_jsx(Tooltip, { title: field.tip, placement: "right", children: _jsx(TextField, { fullWidth: true, id: field.id, label: field.label, sx: {
                        display: field.hidden ? 'none' : 'inherit',
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#555',
                        },
                    }, value: localValue, required: field.required, disabled: typeof field.readOnly === 'function'
                        ? field.readOnly(newData)
                        : readOnly ||
                            field.readOnly ||
                            (formattedData === null || formattedData === void 0 ? void 0 : formattedData[`${field.id}-null`]), onChange: handleInputChange, onBlur: (e) => {
                        setNewData(Object.assign(Object.assign({}, formattedData), { [e.target.id]: typeof field.onBlurFormatter === 'function'
                                ? field.onBlurFormatter(e.target.value)
                                : typeof field.formatter === 'function'
                                    ? field.formatter(e.target.value)
                                    : e.target.value }));
                    }, error: (field.validator instanceof Function &&
                        !field.validator((formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) || '')) ||
                        (field.isUnique &&
                            field.existingVals.includes(formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) &&
                            (formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) !== (oldData === null || oldData === void 0 ? void 0 : oldData[field.id])), helperText: field.validator instanceof Function &&
                        !field.validator((formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) || '')
                        ? `Invalid ${field.label}`
                        : field.isUnique &&
                            field.existingVals.includes(formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) &&
                            (formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) !== (oldData === null || oldData === void 0 ? void 0 : oldData[field.id])
                            ? 'Already exists'
                            : null, multiline: field.type === 'text-multiline', InputProps: Object.assign(Object.assign(Object.assign({}, (field.endAdornment && {
                        endAdornment: typeof field.endAdornment === 'function'
                            ? field.endAdornment(formattedData, field, setNewData)
                            : field.endAdornment,
                    })), (field.access === 'admin' && {
                        endAdornment: (_jsxs(InputAdornment, { position: "end", children: [typeof field.endAdornment === 'function'
                                    ? field.endAdornment(formattedData, field, setNewData)
                                    : field.endAdornment, _jsx(IconButton, { disabled: true, children: _jsx(AdminPanelSettings, {}) })] })),
                    })), ((formattedData === null || formattedData === void 0 ? void 0 : formattedData[`${field.id}-null`]) && {
                        sx: {
                            textDecoration: 'line-through',
                        },
                    })) }, field.id) }), field.enableNullCheckbox && (_jsx(NullCheckbox, { readOnly: readOnly, field: field, formattedData: formattedData, setNewData: setNewData }))] }, field.id));
};
export default FieldComponent;
