var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BulkEditPolicyAgentSplit from '@/components/ToolsPage/BulkEditPolicyAgentSplit';
import CsvToJson from '@/components/ToolsPage/CsvToJson';
import GenericTool from '@/components/ToolsPage/GenericTool';
import JsonToCsv from '@/components/ToolsPage/JsonToCsv';
import PaymentLinks from '@/components/ToolsPage/PaymentLinks';
import UserTools from '@/components/ToolsPage/UserTools';
import { BulkUpdateAgentUpline } from './BulkUpdateAgentUpline';
import { CompGridImport } from '@/components/ToolsPage/CompGridImport';
const uniques = (strs, delimiter = '\n') => Array.from(new Set(strs.split(delimiter))).join('\n');
const TabPanel = (_a) => {
    var { children, value, index } = _a, other = __rest(_a, ["children", "value", "index"]);
    return (_jsx("div", Object.assign({ role: "tabpanel", hidden: value !== index, id: `tabpanel-${index}` }, other, { children: value === index && (_jsx(Box, { sx: { pt: 2 }, children: _jsx(Typography, { component: "div", children: children }) })) })));
};
const tabs = [
    {
        label: 'Policy split import',
        path: 'agent-split-import',
        component: _jsx(BulkEditPolicyAgentSplit, {}),
    },
    {
        label: 'Agent upline import',
        path: 'agent-upline-import',
        component: _jsx(BulkUpdateAgentUpline, {}),
    },
    {
        label: 'Comp grid import',
        path: 'comp-grid-import',
        component: _jsx(CompGridImport, {}),
    },
    {
        label: 'User tools',
        path: 'user-tools',
        component: _jsx(UserTools, {}),
    },
    {
        label: 'Payment links',
        path: 'payment-links',
        component: _jsx(PaymentLinks, {}),
    },
    {
        label: 'Unique lines',
        path: 'unique-lines',
        component: _jsx(GenericTool, { outFn: uniques }),
    },
    {
        label: 'Nano Ids',
        path: 'nano-ids',
        component: (_jsx(GenericTool, { outFn: (num) => Array.from(new Array(Number.isInteger(Number.parseInt(num)) ? Number.parseInt(num) : 1))
                .map(() => nanoid())
                .join('\n') })),
    },
    {
        label: 'CSV to JSON',
        path: 'csv-to-json',
        component: _jsx(CsvToJson, {}),
    },
    {
        label: 'JSON to CSV',
        path: 'json-to-csv',
        component: _jsx(JsonToCsv, {}),
    },
];
const ToolsPage = ({}) => {
    const { tab = 'list' } = useParams();
    const tabPaths = tabs.map((tab) => tab.path);
    const routedTab = tabPaths.indexOf(tab) >= 0 ? tabPaths.indexOf(tab) : 0;
    const [value, setValue] = useState(routedTab);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        navigate(`/admin/tools/${tabs[newValue].path}`);
        setValue(newValue);
    };
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Box, { sx: { borderBottom: 1, borderColor: 'divider' }, children: _jsx(Tabs, { value: value, onChange: handleChange, children: tabs.map((tab) => (_jsx(Tab, { label: tab.label }, tab.label))) }) }), tabs.map((tab) => (_jsx(TabPanel, { value: value, index: tabs.indexOf(tab), children: tab.component }, tab.label)))] }));
};
ToolsPage.propTypes = {
    user: PropTypes.object,
};
export default ToolsPage;
