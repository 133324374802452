import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { nanoid } from 'nanoid';
import { Launch } from '@mui/icons-material';
import { ReportReviewStatuses } from 'common/globalTypes';
import API from '@/services/API';
import ReportData from '../molecules/ReportData';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: 2,
};
const viewDataStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: 2,
};
const ReportHistory = ({ open, setOpen, reportId }) => {
    var _a;
    const { data: reportHistoryData } = API.getBasicQuery(
    // @ts-ignore
    `saved_reports/history?report_id=${reportId}`, '', open);
    const [showData, setShowData] = useState([]);
    const [openData, setOpenData] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const openDataModal = (snapshotData) => {
        setSelectedData(snapshotData);
        setOpenData(true);
    };
    useEffect(() => {
        if (reportHistoryData && reportHistoryData.statusText === 'ok') {
            const _showData = reportHistoryData.data.map((row) => {
                let color;
                if (row.status === ReportReviewStatuses.Approved) {
                    color = '#a5d6a7';
                }
                else if (row.status === ReportReviewStatuses.InReview) {
                    color = '#fff59d';
                }
                else if (row.status === ReportReviewStatuses.RequestUpdate) {
                    color = '#ffcc80';
                }
                else if (row.status === ReportReviewStatuses.Rejected) {
                    color = '#f52525';
                }
                else if (row.status === ReportReviewStatuses.NonPayable) {
                    color = '#f52525';
                }
                else {
                    color = '#ddd';
                }
                if (row.users_white_list.length > 0) {
                    row.users_white_list = row.users_white_list.map((user) => (_jsx("p", { children: user === null || user === void 0 ? void 0 : user.email }, `${user === null || user === void 0 ? void 0 : user.email}-${nanoid()}`)));
                }
                else {
                    row.users_white_list = [_jsx("p", { children: "No users" }, "no-users")];
                }
                if (row.snapshot_data) {
                    const data = row.snapshot_data;
                    row.snapshot_data = (_jsx(Button, { onClick: () => openDataModal(data), endIcon: _jsx(Launch, {}), children: "Check Data" }));
                }
                return Object.assign(Object.assign({}, row), { status: (_jsx(Chip, { sx: { backgroundColor: color, my: 0.25, mr: 0.5 }, label: row.status })), users_white_list: _jsx("div", { children: row.users_white_list }) });
            });
            setShowData(_showData);
        }
    }, [reportHistoryData]);
    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseData = () => {
        setOpenData(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Modal, { open: open, onClose: handleClose, children: _jsxs(Box, { sx: style, children: [_jsx(Typography, { variant: "h6", component: "h2", children: "Report history" }), _jsxs(Box, { sx: {
                                overflowX: 'auto',
                                overflowY: 'auto',
                                width: '100%',
                                height: '70vh',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: 'white',
                            }, children: [!showData && _jsx("p", { children: "loading..." }), showData.length > 0 ? (_jsx(TableContainer, { component: Paper, children: _jsxs(Table, { sx: { minWidth: '100%' }, "aria-label": "simple table", children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { align: "center", children: "Date" }), _jsx(TableCell, { align: "center", children: "Performed by" }), _jsx(TableCell, { align: "center", children: "Report name" }), _jsx(TableCell, { align: "center", children: "Report notes" }), _jsx(TableCell, { align: "center", children: "Access" }), _jsx(TableCell, { align: "center", children: "Users with access" }), _jsx(TableCell, { align: "center", children: "Status" }), _jsx(TableCell, { align: "center", children: "Approver" }), _jsx(TableCell, { align: "center", children: "Check report data" })] }) }), _jsx(TableBody, { children: showData.map((row) => {
                                                    var _a, _b;
                                                    return (_jsxs(TableRow, { sx: {
                                                            '&:last-child td, &:last-child th': { border: 0 },
                                                        }, children: [_jsx(TableCell, { component: "th", scope: "row", children: new Date(row.created_at).toLocaleString() }), _jsx(TableCell, { align: "center", children: (_a = row.rep_created_byTousers) === null || _a === void 0 ? void 0 : _a.email }), _jsx(TableCell, { align: "center", children: row.name }), _jsx(TableCell, { align: "center", children: row.notes }), _jsx(TableCell, { align: "center", children: row.access }), _jsx(TableCell, { align: "center", children: row.users_white_list }), _jsx(TableCell, { align: "center", children: row.status }), _jsx(TableCell, { align: "center", children: (_b = row.rep_reviewed_byTousers) === null || _b === void 0 ? void 0 : _b.email }), _jsx(TableCell, { align: "center", children: row.snapshot_data })] }, row.str_id));
                                                }) })] }) })) : (_jsx("p", { children: "No historical data found" }))] }), _jsx(Box, { sx: { mt: 3, display: 'flex', justifyContent: 'flex-end' }, children: _jsx(Button, { onClick: (e) => handleClose(), sx: { mr: 1, mt: 2 }, children: "Close" }) })] }) }), _jsx(Modal, { open: openData, onClose: handleCloseData, children: _jsxs(Box, { sx: viewDataStyle, children: [_jsx(ReportData, { isLoading: false, selectedSnapshotReport: { snapshot_data: selectedData }, filteredData: (_a = selectedData === null || selectedData === void 0 ? void 0 : selectedData.data) === null || _a === void 0 ? void 0 : _a.data, setOpenHistory: setOpen, showActions: false, isHistoryView: true }), _jsx(Box, { sx: { mt: 3, display: 'flex', justifyContent: 'flex-end' }, children: _jsx(Button, { onClick: (e) => handleCloseData(), sx: { mr: 1, mt: 2 }, children: "Close" }) })] }) })] }));
};
export default ReportHistory;
