import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Table, TableBody, TableCell, TableHead, TableRow, Typography, } from '@mui/material';
import { arrayToMap } from 'common/utils';
import { useState } from 'react';
import DataView from '@/components/DataView';
import LazyLoadDynamicSelect from '@/components/molecules/LazyLoadDynamicSelect';
import CompProfilesAdd from '@/components/schedules/CompProfilesView/CompProfilesAdd';
import CompRuleConfig from '@/components/schedules/CompProfilesView/CompRuleConfig';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore, useRoleStore } from '@/store';
import { FieldTypes, Roles } from '@/types';
const CommissionProfileRules = ({ rules, dynamicSelects }) => {
    var _a;
    const [expanded, setExpanded] = useState(false);
    const ruleKeys = [
        { id: 'product_id', label: 'Product(s)' },
        { id: 'product_option_id', label: 'Option(s)' },
        { id: 'issue_age_min', label: 'Issue age min' },
        { id: 'issue_age_max', label: 'Issue age max' },
        { id: 'transaction_type', label: 'Transaction type' },
        { id: 'compensation_type', label: 'Compensation type' },
        { id: 'match_criteria', label: 'Other criteria' },
        { id: 'commission_rate', label: 'Comm rate' },
        { id: 'receivable_rate', label: 'Recv rate' },
        { id: 'payout_rate', label: 'Payout rate' },
        { id: 'keep_rate', label: 'Flat rate' },
        { id: 'plus_rate', label: 'Percentage of received' },
        { id: 'carrier_grid_level', label: 'Carrier grid level' },
        { id: 'carrier_grid_override_rate', label: 'Carrier grid level rate' },
        { id: 'split', label: 'Split pct' },
    ];
    const { data: optionsLookup } = API.getBasicQuery('companies/products/options');
    const optionsMap = (_a = optionsLookup === null || optionsLookup === void 0 ? void 0 : optionsLookup.data) === null || _a === void 0 ? void 0 : _a.reduce((acc, option) => {
        acc[option.id] = option;
        return acc;
    }, {});
    return (_jsxs(Box, { children: [_jsx(Button, { endIcon: expanded ? _jsx(ExpandLessOutlined, {}) : _jsx(ExpandMoreOutlined, {}), onClick: () => setExpanded(!expanded), sx: {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    color: 'text.primary',
                    fontWeight: 500,
                }, children: `${rules.length} rules` }), expanded && (_jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: ruleKeys.map((ruleKey) => (_jsx(TableCell, { children: ruleKey.label }, ruleKey.id))) }) }), _jsx(TableBody, { children: rules.map((rule, i) => (_jsx(TableRow, { children: ruleKeys.map((ruleKey) => {
                                var _a, _b;
                                return (_jsx(TableCell, { children: ['product_id', 'product_option_id'].includes(ruleKey.id) &&
                                        Array.isArray(rule === null || rule === void 0 ? void 0 : rule[ruleKey.id])
                                        ? (_a = rule === null || rule === void 0 ? void 0 : rule[ruleKey.id]) === null || _a === void 0 ? void 0 : _a.map((id) => {
                                            var _a, _b;
                                            return (_jsx(Chip, { label: ruleKey.id === 'product_id'
                                                    ? (_a = dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.find((option) => option.id === id)) === null || _a === void 0 ? void 0 : _a.product_name
                                                    : (_b = optionsMap[id]) === null || _b === void 0 ? void 0 : _b.name, sx: { height: 24, m: 0.1 } }, `rule-${i}::${ruleKey.id}-${id}`));
                                        })
                                        : ruleKey.id === 'match_criteria'
                                            ? (_b = rule === null || rule === void 0 ? void 0 : rule[ruleKey.id]) === null || _b === void 0 ? void 0 : _b.map((matcher, i) => (_jsx(Chip, { label: Formatter.fieldMatcher(matcher), sx: { height: 24, m: 0.1 } }, `rule-${i}::${ruleKey.id}-${i}`)))
                                            : rule[ruleKey.id] }, ruleKey.id));
                            }) }, `rule-${i}`))) })] }))] }));
};
const CommissionProfileCriteriaRules = ({ payeeLevelId, payerLevelId, rules, dynamicSelects, }) => {
    const [expanded, setExpanded] = useState(false);
    const [fetchData, setFetchData] = useState(false);
    const { data: payeeRates } = API.getBasicQuery('comp-grids/rates', `comp_grid_level_id=${payeeLevelId !== null && payeeLevelId !== void 0 ? payeeLevelId : ''}&is_dynamic_select=true`, fetchData);
    const { data: payerRates } = API.getBasicQuery('comp-grids/rates', `comp_grid_level_id=${payerLevelId !== null && payerLevelId !== void 0 ? payerLevelId : ''}&is_dynamic_select=true`, fetchData);
    const payeeRatesMap = arrayToMap(payeeRates === null || payeeRates === void 0 ? void 0 : payeeRates.data, (e) => `${e.comp_grid_level_id}::${e.comp_grid_criterion_id}`);
    const payerRatesMap = arrayToMap(payerRates === null || payerRates === void 0 ? void 0 : payerRates.data, (e) => `${e.comp_grid_level_id}::${e.comp_grid_criterion_id}`);
    const ruleKeys = [
        { id: 'comp_grid_criteria_id', label: 'Criteria' },
        { id: 'match_criteria', label: 'Other criteria' },
        { id: 'calculation_method', label: 'Calculation method' },
    ];
    return (_jsxs(Box, { children: [_jsx(Button, { onClick: () => {
                    setExpanded(!expanded);
                    if (!fetchData)
                        setFetchData(true);
                }, endIcon: expanded ? _jsx(ExpandLessOutlined, {}) : _jsx(ExpandMoreOutlined, {}), children: `${rules.length} rules` }), expanded && (_jsxs(Table, { sx: { minWidth: 1000 }, children: [_jsx(TableHead, { children: _jsx(TableRow, { children: ruleKeys.map((ruleKey) => (_jsx(TableCell, { children: ruleKey.label }, ruleKey.id))) }) }), _jsx(TableBody, { children: rules.map((rule, i) => (_jsx(TableRow, { children: ruleKeys.map((ruleKey) => {
                                var _a, _b;
                                return (_jsx(TableCell, { children: ruleKey.id === 'match_criteria'
                                        ? (_a = rule === null || rule === void 0 ? void 0 : rule[ruleKey.id]) === null || _a === void 0 ? void 0 : _a.map((matcher, i) => (_jsx(Chip, { label: Formatter.fieldMatcher(matcher), sx: { height: 24, m: 0.1 } }, `rule-${i}::${ruleKey.id}-${i}`)))
                                        : ruleKey.id === 'comp_grid_criteria_id'
                                            ? (_b = rule === null || rule === void 0 ? void 0 : rule[ruleKey.id]) === null || _b === void 0 ? void 0 : _b.map((criterion_id) => {
                                                var _a, _b, _c, _d, _e, _f, _g, _h;
                                                return (_jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }, children: [_jsx(Typography, { variant: "body2", children: Formatter.getDynamicSelectFormatter(Formatter.compGridCriterion)(criterion_id, dynamicSelects) }), _jsx(Typography, { variant: "body2", sx: { whiteSpace: 'nowrap' }, children: (rule === null || rule === void 0 ? void 0 : rule.calculation_method) === 'compGridLevel'
                                                                ? `(@ ${(_a = payerRatesMap[`${payerLevelId}::${criterion_id}`]) === null || _a === void 0 ? void 0 : _a.rate}% - ${(_b = payeeRatesMap[`${payeeLevelId}::${criterion_id}`]) === null || _b === void 0 ? void 0 : _b.rate}% = ${+((_d = (_c = payerRatesMap[`${payerLevelId}::${criterion_id}`]) === null || _c === void 0 ? void 0 : _c.rate) !== null && _d !== void 0 ? _d : 0) - +((_f = (_e = payeeRatesMap[`${payeeLevelId}::${criterion_id}`]) === null || _e === void 0 ? void 0 : _e.rate) !== null && _f !== void 0 ? _f : 0)}%)`
                                                                : (rule === null || rule === void 0 ? void 0 : rule.calculation_method) === 'payHouseRate'
                                                                    ? `(@ ${(_g = payeeRatesMap[`${payeeLevelId}::${criterion_id}`]) === null || _g === void 0 ? void 0 : _g.house_rate}%)`
                                                                    : (rule === null || rule === void 0 ? void 0 : rule.calculation_method) ===
                                                                        'payOverrideUpToTotalRate'
                                                                        ? `(@ ${(_h = payeeRatesMap[`${payeeLevelId}::${criterion_id}`]) === null || _h === void 0 ? void 0 : _h.rate}%)`
                                                                        : '' })] }));
                                            })
                                            : rule[ruleKey.id] }, ruleKey.id));
                            }) }, `rule-${i}`))) })] }))] }));
};
const CompProfilesView = () => {
    var _a, _b;
    const { userRole } = useRoleStore();
    const { selectedAccount } = useAccountStore();
    const { data: accountSettings } = API.getBasicQuery('accounts/settings');
    const { data: companies } = API.getBasicQuery('companies', 'type=Carrier&is_dynamic_select=true');
    const isNewCompGrids = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.comp_grids_enabled;
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.compensation_profiles;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    const dataDesc = {
        label: 'Compensation profiles',
        table: 'schedules/comp-profiles',
        editable: userRole === Roles.ACCOUNT_ADMIN,
        copyable: true,
        validateData: (data) => data.name,
        bulkAdd: true,
        fields: [
            { id: 'name', label: 'Name' },
            {
                id: 'company_id',
                label: 'Carrier',
                type: 'dynamic-select',
                table: 'companies',
                field: 'id',
                nullable: true,
                formatter: Formatter.getDynamicSelectFormatter((o) => o === null || o === void 0 ? void 0 : o.company_name),
                optionFormatter: (option) => option.company_name,
                optionValuer: (option) => option.id,
            },
            {
                id: 'comp_grid_id',
                label: 'Comp grid',
                type: 'dynamic-select',
                table: 'comp-grids',
                nullable: true,
                queryParamName: 'company_id',
                queryParamValue: 'company_id',
                formatter: Formatter.getDynamicSelectFormatter(),
                optionFormatter: (option) => option.name,
                optionValuer: (option) => option.id,
            },
            {
                id: 'product_type',
                label: 'Product type',
                type: 'dynamic-select',
                nullable: true,
                multiple: true,
                table: 'comp-grids/products?only_types=true',
                queryParamName: 'comp_grid_id',
                queryParamValue: 'comp_grid_id',
                formatter: (val) => {
                    if (val === '')
                        return '';
                    return _jsx(Chip, { label: val, sx: { mb: 0.5 } });
                },
            },
            {
                id: 'payee_types',
                label: 'Payout recipients',
                type: 'select',
                options: [
                    { id: 'agent', label: 'Agent' },
                    { id: 'upline', label: "Agents' hierarchy upline" },
                ],
            },
            {
                id: 'hierarchy_processing',
                label: 'Hierarchy processing',
                type: 'select',
                options: [
                    { id: 'none', label: 'None (assigned agent only)' },
                    { id: 'upline', label: 'Agent and upline' },
                    { id: 'all', label: 'Entire hierarchy' },
                ],
            },
            {
                id: 'calculation_basis',
                label: 'Calculation basis',
                type: 'select',
                options: [
                    { id: 'annual_premium', label: 'Policy data: Annual premium' },
                    { id: 'target_premium', label: 'Policy data: Target premium' },
                    {
                        id: 'normalized_premium',
                        label: 'Commission data: Normalize premium based on split',
                    },
                    { id: 'commissions', label: 'Commissions' },
                    { id: 'commissions_remaining', label: 'Commissions remaining' },
                ],
            },
            {
                id: 'calculation_scope',
                label: 'Calculation scope',
                type: 'select',
                options: [
                    { id: 'commission', label: 'Per commission' },
                    { id: 'policy', label: 'Per policy' },
                ],
            },
            {
                id: 'payout_timing',
                label: 'Payout timing',
                type: 'select',
                options: [
                    { id: 'first_payment_date', label: 'First payment date' },
                    { id: 'first_processed_date', label: 'First processing date' },
                ],
            },
            {
                id: 'payer_comp_grid_level_id',
                label: 'Carrier grid level',
                type: 'dynamic-select',
                table: 'comp-grids/levels',
                queryParamName: 'comp_grid_id',
                queryParamValue: 'comp_grid_id',
                formatter: Formatter.getDynamicSelectFormatter(),
                optionFormatter: (option) => option.name,
                optionValuer: (option) => option.id,
            },
            {
                id: 'payee_comp_grid_level_id',
                label: 'House payout grid level',
                type: 'dynamic-select',
                table: 'comp-grids/levels',
                queryParamName: 'comp_grid_id',
                queryParamValue: 'comp_grid_id',
                formatter: Formatter.getDynamicSelectFormatter(),
                optionFormatter: (option) => option.name,
                optionValuer: (option) => option.id,
            },
            {
                id: 'schedules',
                label: 'Comp calculation rules',
                disableSort: true,
                type: FieldTypes.CUSTOM,
                table: isNewCompGrids ? 'comp-grids/criteria' : 'companies/products',
                tableFormatter: (rules, row, dynamicSelects) => isNewCompGrids ? (_jsx(CommissionProfileCriteriaRules, { payeeLevelId: row.payee_comp_grid_level_id, payerLevelId: row.payer_comp_grid_level_id, rules: rules, dynamicSelects: dynamicSelects })) : (_jsx(CommissionProfileRules, { rules: rules, dynamicSelects: dynamicSelects })),
                render: (field, row, setter) => (_jsx(CompRuleConfig, { fieldId: "schedules", companyId: row.company_id, compGridId: row.comp_grid_id, payerLevelId: row.payer_comp_grid_level_id, payeeLevelId: row.payee_comp_grid_level_id, data: row, setter: setter, readOnly: userRole === Roles.DATA_SPECIALIST }, 'schedules')),
            },
            { id: 'notes', label: 'Notes' },
            {
                id: 'contacts_agent_commission_profiles_names',
                label: 'Agents',
                disableSort: true,
                type: 'custom',
                table: 'contacts',
                tableFormatter: (field, row, dynamicSelects, header) => (_jsx(LazyLoadDynamicSelect, { data: field, header: header, formatter: (o) => Formatter.contact(o, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId }) })),
                optionFormatter: (option) => `${option.email}`,
                optionValuer: (option) => option === null || option === void 0 ? void 0 : option.id,
                render: (field, row, setter, dynamicSelects) => (_jsx(CompProfilesAdd, { table: "contacts_agent_commission_schedule_profiles", data: row, setter: setter, field: field, dynamicSelects: dynamicSelects, readOnly: userRole !== Roles.ACCOUNT_ADMIN }, "contacts_agent_commission_schedule_profiles")),
            },
            { id: 'divider', type: 'divider' },
            {
                id: 'created_at',
                label: 'Created at',
                condition: (data) => !!data.created_at,
                formatter: Formatter.dateTime,
                readOnly: true,
                visible: ['form'],
            },
            {
                id: 'updated_at',
                label: 'Updated at',
                condition: (data) => !!data.updated_at,
                formatter: Formatter.dateTime,
                readOnly: true,
                visible: ['form'],
            },
        ],
        // QueryChips: {},
        filterConfigs: {
            company_id: { type: 'select', label: 'Carrier', options: {} },
        },
    };
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    if (Array.isArray(companies) && companies.length > 0) {
        companies.forEach((company, i) => {
            dataDesc.filterConfigs.company_id.options[company.id] = {
                id: company.id,
                str_id: company.str_id,
                label: company.company_name,
                query: { comp_grid_id: company.id },
            };
        });
    }
    return (_jsx(DataView, { dataDesc: dataDesc, hideExport: true, readOnly: userRole === Roles.DATA_SPECIALIST, viewOnly: viewOnly, enablePagination: true, headingOffset: 122 }));
};
export default CompProfilesView;
