var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams, useSearchParams } from 'react-router-dom';
import { DateRangeOutlined, Launch, Share } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import { capitalize } from 'lodash-es';
import { useState } from 'react';
import CommonFormatter from 'common/Formatter';
import { ContactPayableStatuses, ReportReviewStatuses, ReportReviewStatusesLabels, } from 'common/globalTypes';
import DataView from '@/components/DataView';
import API from '@/services/API';
import DataTransformation from '@/services/DataTransformation';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { savedReportsGroupsTemplates } from '@/types';
import CommissionPayoutExportConfig from './CommissionPayoutExportConfig';
import useSnackbar from '@/contexts/useSnackbar';
const Normalizer = DataTransformation;
const ReportsGroupDetailsView = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedData, setSelectedData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [exportOptions, setExportOptions] = useState(null);
    const bulkSharePoster = API.getMutation('saved_reports/groups/bulk_share', 'POST');
    const { data: contactOptions, isFetched: isFetchedContactOptions } = API.getBasicQuery(`contacts/options?saved_report_group=${id}`);
    const { data: savedReportResponse } = API.getBasicQuery(
    // @ts-ignore
    `saved_reports/groups?id=${id}`);
    const reportGroupPatcher = API.getMutation('saved_reports/groups/details', 'PATCH');
    const savedReport = savedReportResponse === null || savedReportResponse === void 0 ? void 0 : savedReportResponse.data;
    const { selectedAccount } = useAccountStore();
    const { showSnackbar } = useSnackbar();
    const isAccountTransactionsEnabled = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountingTransactionsEnabled;
    // TODO: Currently getting dates from report name. These should be saved as params in the saved report for this type.
    let processing_date_start = '';
    let processing_date_end = '';
    if (Array.isArray(savedReport) && savedReport.length === 1) {
        const report = savedReport[0];
        const processingDates = (_a = report === null || report === void 0 ? void 0 : report.name) === null || _a === void 0 ? void 0 : _a.match(/\d\d?\/\d\d?\/\d{4}/g);
        if (processingDates) {
            [processing_date_start, processing_date_end] = processingDates;
        }
    }
    const extraActions = [
        ...(processing_date_start && processing_date_end
            ? [
                _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: searchParams.get('hide_empty') === 'true' }), label: "Hide empty", sx: { mr: 1 }, onChange: (e) => {
                        setSearchParams((prev) => {
                            if (e.target.checked) {
                                prev.set('hide_empty', 'true');
                            }
                            else {
                                prev.delete('hide_empty');
                            }
                            return prev;
                        });
                    } }, "emptyFilter"),
                _jsx(Button, { href: `/commissions/?processing_date_start=${processing_date_start}&processing_date_end=${processing_date_end}`, variant: "outlined", startIcon: _jsx(DateRangeOutlined, {}), children: "View current data" }, `${processing_date_end}-${processing_date_start}-key`),
            ]
            : []),
        _jsx(Tooltip, { title: "Share report with producer", children: _jsx("span", { children: _jsx(Button, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                        const response = yield bulkSharePoster.mutateAsync(selectedData);
                        if (response && response.statusText === 'ok') {
                            showSnackbar('Reports shared successfully', 'success');
                        }
                        else if (response && response.error) {
                            showSnackbar(response.error, 'error');
                        }
                        else {
                            showSnackbar('Error sharing reports', 'error');
                        }
                    }), variant: "outlined", startIcon: _jsx(Share, {}), disabled: selectedData.length === 0, sx: { ml: 1 }, children: "Share reports" }) }) }, `shareReportsButtonKey`),
    ];
    const dataDesc = {
        label: 'Report group summary',
        table: `saved_reports/groups/details?id=${id}`,
        fields: [
            {
                id: 'name',
                label: 'Report name',
                tableFormatter: (field, row) => {
                    return (_jsx(Button, { onClick: () => {
                            window.open(`/reports/${row.str_id}`, '_blank');
                        }, endIcon: _jsx(Launch, {}), children: field }));
                },
            },
            {
                id: 'processing_date_start',
                label: 'Start date',
                formatter: (val) => {
                    if (val) {
                        return CommonFormatter.date(val);
                    }
                    return '';
                },
            },
            {
                id: 'processing_date_end',
                label: 'End date',
                formatter: (val) => {
                    if (val) {
                        return CommonFormatter.date(val);
                    }
                    return '';
                },
            },
            {
                id: 'totals',
                label: 'Report amount',
                formatter: (val, row) => {
                    var _a, _b;
                    if (val.agent_commissions)
                        return Formatter.currency(Normalizer.normalizeCurrency(JSON.stringify((_b = (_a = Object.values(val.agent_commissions)) === null || _a === void 0 ? void 0 : _a[0]) !== null && _b !== void 0 ? _b : 0)));
                },
            },
            {
                id: 'totals',
                label: 'Gross commission',
                formatter: (val, row) => {
                    var _a;
                    if (val.commission_amount)
                        return Formatter.currency((_a = val.commission_amount) !== null && _a !== void 0 ? _a : 0);
                },
            },
            ...(isAccountTransactionsEnabled
                ? [
                    {
                        id: 'agent_balance',
                        label: 'Agent balance',
                        formatter: (val) => {
                            if (val)
                                return Formatter.currency(Normalizer.normalizeCurrency(val));
                        },
                    },
                    {
                        id: 'current_balance',
                        label: 'Amount due',
                        formatter: (val) => {
                            if (val)
                                return Formatter.currency(Normalizer.normalizeCurrency(val));
                        },
                    },
                ]
                : []),
            {
                id: 'report_notes',
                label: 'Notes',
            },
            {
                id: 'status',
                label: 'Report status',
                type: 'select',
                options: [
                    {
                        id: ReportReviewStatuses.None,
                        label: ReportReviewStatusesLabels.None,
                    },
                    {
                        id: ReportReviewStatuses.Draft,
                        label: ReportReviewStatusesLabels.Draft,
                    },
                    {
                        id: ReportReviewStatuses.InReview,
                        label: ReportReviewStatusesLabels.InReview,
                    },
                    {
                        id: ReportReviewStatuses.Approved,
                        label: ReportReviewStatusesLabels.Approved,
                    },
                    {
                        id: ReportReviewStatuses.RequestUpdate,
                        label: ReportReviewStatusesLabels.RequestUpdate,
                    },
                    {
                        id: ReportReviewStatuses.Rejected,
                        label: ReportReviewStatusesLabels.Rejected,
                    },
                    {
                        id: ReportReviewStatuses.NonPayable,
                        label: ReportReviewStatusesLabels.NonPayable,
                    },
                ],
                formatter: Formatter.reportStatusFormatter,
            },
            {
                id: 'agent_name',
                label: 'Agent',
            },
            {
                id: 'agent_email',
                label: 'Agent email',
            },
            {
                id: 'agent_status',
                label: 'Agent status',
                queryable: true,
                condition: () => isFetchedContactOptions,
                formatter: (val) => capitalize(val),
            },
            {
                id: 'agent_payable_status',
                label: 'Payable status',
                queryable: true,
                type: 'select',
                options: [
                    ...new Set([
                        '',
                        ContactPayableStatuses.PAYABLE,
                        ContactPayableStatuses.NON_PAYABLE,
                        ...((_b = contactOptions === null || contactOptions === void 0 ? void 0 : contactOptions.agent_payable_status) !== null && _b !== void 0 ? _b : []),
                    ]),
                ],
                condition: () => isFetchedContactOptions,
                formatter: (val) => capitalize(val),
            },
            {
                id: 'agent_bank_info',
                label: 'Agent bank info',
            },
            {
                id: 'agent',
                readOnly: true,
                visible: false,
            },
        ],
        filterConfigs: {
            agent_status: { type: 'select', label: 'Agent status', options: {} },
            agent_payable_status: {
                type: 'select',
                label: 'Agent payable status',
                options: {},
            },
        },
    };
    if (contactOptions) {
        contactOptions.status.forEach((option) => {
            dataDesc.filterConfigs.agent_status.options[option] = {
                id: option,
                label: capitalize(option),
                query: { agent_status: option },
            };
        });
        contactOptions.payable_status.forEach((option) => {
            dataDesc.filterConfigs.agent_payable_status.options[option] = {
                id: option,
                label: capitalize(option),
                query: { agent_payable_status: option },
            };
        });
    }
    const defaultExportOptions = [
        {
            id: 'export',
            label: 'Export',
            options: {
                export_type: 'zip',
                fileName: savedReport && Array.isArray(savedReport) && savedReport.length > 0
                    ? ((_c = savedReport[0]) === null || _c === void 0 ? void 0 : _c.template) ===
                        savedReportsGroupsTemplates.COMMISSION_PAYOUT
                        ? `${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName} - ${Formatter.date((_d = savedReport[0]) === null || _d === void 0 ? void 0 : _d.created_at)}`
                        : (_e = savedReport[0]) === null || _e === void 0 ? void 0 : _e.name
                    : '',
            },
        },
        {
            id: 'export-zip',
            label: 'Export reports as zip',
            options: {
                export_type: 'zip',
                fileName: savedReport && Array.isArray(savedReport) && savedReport.length > 0
                    ? ((_f = savedReport[0]) === null || _f === void 0 ? void 0 : _f.template) ===
                        savedReportsGroupsTemplates.COMMISSION_PAYOUT
                        ? `${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName} - ${Formatter.date((_g = savedReport[0]) === null || _g === void 0 ? void 0 : _g.created_at)}`
                        : (_h = savedReport[0]) === null || _h === void 0 ? void 0 : _h.name
                    : '',
            },
        },
        {
            id: 'export-xlsx',
            label: 'Export reports as xlsx',
            options: {
                export_type: 'xlsx',
                fileName: savedReport && Array.isArray(savedReport) && savedReport.length > 0
                    ? ((_j = savedReport[0]) === null || _j === void 0 ? void 0 : _j.template) ===
                        savedReportsGroupsTemplates.COMMISSION_PAYOUT
                        ? `${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName} - ${Formatter.date((_k = savedReport[0]) === null || _k === void 0 ? void 0 : _k.created_at)}`
                        : (_l = savedReport[0]) === null || _l === void 0 ? void 0 : _l.name
                    : '',
            },
        },
    ];
    const handleCustomExportOptions = (onExport) => {
        if (exportOptions && Object.keys(exportOptions).length > 0) {
            onExport(Object.assign({}, exportOptions));
            setExportOptions(null);
        }
    };
    const handleBulkEdit = (selected, updateData) => __awaiter(void 0, void 0, void 0, function* () {
        const body = Object.assign({ saved_reports_ids: selected }, updateData);
        const response = yield reportGroupPatcher.mutateAsync(body);
        if (response && response.statusText === 'ok') {
            showSnackbar('Reports updated successfully', 'success');
            setRefresh(refresh + 1);
        }
        else if (response && response.error) {
            showSnackbar(response.error, 'error');
        }
        else {
            showSnackbar('Error updating reports', 'error');
        }
    });
    return (_jsxs(_Fragment, { children: [_jsx(DataView, Object.assign({ refresh: refresh, enableAutoRefetch: true, dataDesc: dataDesc, setSelectedData: setSelectedData, 
                // @ts-ignore
                onDelete: (v) => false, 
                // @ts-ignore
                onBulkEdit: (selected, updateData) => __awaiter(void 0, void 0, void 0, function* () {
                    yield handleBulkEdit(selected, updateData);
                }), 
                // @ts-ignore
                exportOptions: defaultExportOptions, viewOnly: true, extraActions: extraActions, handleCustomExportOptions: handleCustomExportOptions }, (savedReport &&
                Array.isArray(savedReport) &&
                savedReport.length > 0 &&
                ((_m = savedReport[0]) === null || _m === void 0 ? void 0 : _m.template) ===
                    savedReportsGroupsTemplates.COMMISSION_PAYOUT
                ? {
                    customExport: true,
                    customExportCallback: () => setShowModal(true),
                }
                : {}), { headingOffset: 122, enablePagination: true })), _jsx(CommissionPayoutExportConfig, { open: showModal, setOpen: setShowModal, setExportOptions: setExportOptions, savedReport: savedReport, selectedAccount: selectedAccount, selectedData: selectedData })] }));
};
export default ReportsGroupDetailsView;
