import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import currency from 'currency.js';
import { parseDate } from 'chrono-node';

import { isNill } from '../tools';

dayjs.extend(utc);

function extractNumbers(str) {
  const regex = /[-+]?\d*\.?\d+/g;
  const numbers = str.match(regex);
  return numbers ? numbers.map(Number) : [];
}

export const _isErrorValue = (value: string | undefined | number) => {
  return value && value.toString().includes('❌');
};

/**
 * Convert the date to a normalized
 * @param date formated date
 * @returns number
 */
const normalizeDate = (date: string | number, isUtc = true) => {
  if (isNill(date) || _isErrorValue(date.toString())) {
    return '';
  }
  // ParsedDate returns noon if no time specified, startOf('date') removes it
  const parsedDate = parseDate(date.toString()) ?? date.toString().trim();
  return isUtc
    ? dayjs.utc(parsedDate).startOf('date').valueOf()
    : dayjs(parsedDate).startOf('date').valueOf();
};

const normalizeCurrency = (value: string | number) => {
  if (isNill(value) || _isErrorValue(value.toString())) {
    return null;
  }
  return currency(value).value;
};

/**
 * Convert the percentage to a normalized value 10% => 0.1
 * @param value formated percentage
 * @returns number
 */
const normalizePercentage = (value: string | number) => {
  if (isNill(value) || _isErrorValue(value.toString())) {
    return null;
  }
  // const _value = (value || '').toString().replace('%', '');
  const numList = extractNumbers(value);
  if (numList.length === 0) {
    return '';
  }
  const _value = numList[0];
  return _value.toString();
  // Return new BigNumber(_value).toNumber();
  // return new BigNumber(value).div(100).toNumber();
};

const normalizeBoolean = (value: string | number) => {
  if (isNill(value)) {
    return false;
  }
  const trueList = ['true', 'yes'];
  return trueList.includes(value.toString().toLowerCase());
};

const normalizeInt = (value: string | number) => {
  if (isNill(value) || _isErrorValue(value.toString())) {
    return null;
  }
  return parseInt(value.toString(), 10);
};

export {
  normalizeDate,
  normalizeCurrency,
  normalizePercentage,
  normalizeBoolean,
  normalizeInt,
};
