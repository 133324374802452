var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
const useStorageSignedUrl = () => {
    const [signedUrlParams, setSignedUrlParams] = useState();
    const [signedUrl, setSignedUrl] = useState(null);
    const getSignedUrlPoster = API.getMutation('storage/getSignedUrl', 'POST');
    const { showSnackbar } = useSnackbar();
    const getSignedUrl = useCallback((option) => __awaiter(void 0, void 0, void 0, function* () {
        const { endpoint_str_id, action } = option;
        try {
            if (action === 'read' && !endpoint_str_id) {
                setSignedUrl(null);
                return '';
            }
            const res = yield getSignedUrlPoster.mutateAsync(Object.assign({}, option));
            setSignedUrl(res.data);
            return res.data;
        }
        catch (error) {
            console.error(error.message || error);
            captureException(error);
            showSnackbar(error.message || error, 'error');
            setSignedUrl(null);
            return '';
        }
    }), [showSnackbar]);
    useEffect(() => {
        if (!signedUrlParams)
            return;
        getSignedUrl(signedUrlParams);
    }, [getSignedUrl, signedUrlParams]);
    return { signedUrl, setSignedUrlParams, getSignedUrl };
};
export default useStorageSignedUrl;
