var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography, } from '@mui/material';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { startCase } from 'lodash-es';
import API from '@/services/API';
const DataBulkAddCompGrids = ({ fields, onCancel, onSave }) => {
    const [selectOptions, setSelectOptions] = useState({});
    const [selectedVals, setSelectedVals] = useState({});
    const [autoCreate, setAutoCreate] = useState(false);
    const [csv, setCsv] = useState('');
    const [json, setJSON] = useState('[]');
    const requiredFields = fields.filter((field) => field.required);
    const selectFields = fields.filter((field) => field.bulkAddSelect);
    selectFields.forEach((field) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = API.getBasicQuery(field.table);
        if (JSON.stringify(selectOptions[field.table]) !== JSON.stringify(data)) {
            setSelectOptions((prev) => (Object.assign(Object.assign({}, prev), { [field.table]: data })));
        }
    }));
    useEffect(() => {
        setCsv(``);
    }, [fields]);
    useEffect(() => {
        const csvSheet = XLSX.read(csv, {
            type: 'string',
            raw: true,
        });
        const jsonRes = XLSX.utils.sheet_to_json(csvSheet.Sheets[csvSheet.SheetNames[0]], {
            header: 1,
            raw: false,
            blankrows: true,
        });
        let lastRow = jsonRes.length - 1;
        for (; lastRow > 0; lastRow -= 1) {
            if (jsonRes[lastRow].length > 0) {
                break;
            }
        }
        const compTable = jsonRes.slice(0, lastRow + 1);
        // console.log(compTable);
        //* ************************************************//
        // Begins the processor
        // Get headers, empty rows and carrier indexes
        const title = [];
        let indHeadersTable = 0;
        let indManProcHeaders = 0;
        const indSeparators = [];
        let maxLenghtPast = 0;
        let firstValueTitle = false;
        let doneWithTitle = false;
        let firstValueMain = false;
        for (let indRow = 0; indRow < compTable.length; indRow++) {
            const rowTable = compTable[indRow];
            // Gets title info
            // Ignores any empty row at beginning of the complete table
            if (rowTable[0] != null)
                firstValueTitle = true;
            if (firstValueTitle && rowTable[0] === null)
                doneWithTitle = true;
            if (firstValueTitle && !doneWithTitle)
                title.push(rowTable[0]);
            // Gets main table info
            // Ignores any empty row at beginning of the main table
            if (rowTable[1] != null)
                firstValueMain = true;
            if (firstValueMain && indManProcHeaders === 0)
                indManProcHeaders = indRow;
            // Takes the indexes of the empty rows
            if (firstValueMain && rowTable[1] === null) {
                indSeparators.push(indRow);
            }
            // Get the headers index based on the number of columns
            const maxLenght = rowTable.length;
            if (maxLenght > maxLenghtPast && rowTable[maxLenght - 2] !== null) {
                maxLenghtPast = maxLenght;
                indHeadersTable = indRow;
            }
        }
        indSeparators.push(compTable.length); // Adds an extra index to locate the final row
        const possHeaders = [
            'product type',
            'product',
            'option',
            'issue age start',
            'issue age end',
            'premium min',
            'premium max',
            'transaction type',
            'compensation type',
            'match criteria',
            // 'house',
            // 'owner',
            // 'partner',
            // 'recruiter',
            // 'producer',
            // 'notes',
        ];
        const levelHeaders = [];
        // Removes any header that is not supposed to be there
        const headers = [];
        const mHeaders = compTable[indManProcHeaders];
        const pHeaders = compTable[indHeadersTable];
        if (!mHeaders || !pHeaders)
            return;
        let payerHeader;
        console.log('pHeaders', pHeaders);
        for (let i = 0; i < pHeaders.length; i++) {
            let header = null;
            const mheader = mHeaders[i];
            const pheader = pHeaders[i];
            if (pheader)
                header = pheader;
            if (mheader)
                header = mheader;
            if (header) {
                header = header.toLowerCase().trim();
                if (!possHeaders.includes(header) && i <= 5) {
                    headers.push(null);
                }
                else if (!possHeaders.includes(header) && i > 5) {
                    if (!payerHeader) {
                        payerHeader = header;
                    }
                    headers.push(header);
                    levelHeaders.push(header);
                }
                else {
                    headers.push(header);
                }
            }
        }
        // List of the headers of the final table
        const finalTable = [];
        // Build the final table
        for (let index = indManProcHeaders + 1; index < compTable.length; index++) {
            if (!indSeparators.includes(index)) {
                // Aux variables
                const company = title[0];
                let product_type = '';
                let product_name = '';
                let issue_age_min = '';
                let issue_age_max = '';
                let premium_min = '';
                let premium_max = '';
                let option = '';
                let transaction_type = '';
                let compensation_type = '';
                let match_criteria = '';
                let payee_level_name = '';
                let payee_level_rate = 0;
                let payer_level_name = '';
                let payer_level_rate = 0;
                let override_rate = 0;
                let relevantCIRG = false;
                let notes = '';
                const notProcCols = [];
                const compTableRow = compTable[index];
                for (let indexItem = 0; indexItem < headers.length; indexItem++) {
                    const header = headers[indexItem];
                    const item = compTableRow[indexItem];
                    switch (header) {
                        case 'product type':
                            product_type = item;
                            break;
                        case 'product':
                            product_name = item;
                            break;
                        case 'option':
                            option = item;
                            break;
                        case 'issue age start':
                            issue_age_min = item;
                            break;
                        case 'issue age end':
                            issue_age_max = item;
                            break;
                        case 'premium min':
                            premium_min = item;
                            break;
                        case 'premium max':
                            premium_max = item;
                            break;
                        case 'transaction type':
                            transaction_type = item;
                            break;
                        case 'compensation type':
                            compensation_type = item;
                            break;
                        case 'match criteria':
                            match_criteria = item;
                            break;
                        case 'notes':
                            notes = item;
                            break;
                        case payerHeader:
                            payer_level_rate = parseFloat(item);
                            break;
                        default:
                            notProcCols.push(indexItem);
                    }
                }
                console.log('notProcCols', notProcCols);
                console.log('headers', headers);
                for (const notProcCol of notProcCols) {
                    const header = headers[notProcCol];
                    const item = compTableRow[notProcCol];
                    if (header && header !== payerHeader && header !== 'notes') {
                        // Level = startCase(header[0]) + header.slice(1);
                        payer_level_name = startCase(payerHeader);
                        payee_level_rate = parseFloat(item);
                        payee_level_name = startCase(header);
                        // TODO: Find better way to handle floating point issues
                        override_rate = +(payer_level_rate - payee_level_rate).toFixed(12);
                        if (compensation_type != null) {
                            const lowCompType = compensation_type.toLowerCase();
                            relevantCIRG = ['target', 'excess'].includes(lowCompType);
                        }
                        const finalRow = {
                            company,
                            product_type,
                            product_name,
                            issue_age_min,
                            issue_age_max,
                            premium_min,
                            premium_max,
                            option,
                            transaction_type,
                            compensation_type,
                            match_criteria,
                            calculation_method: 'compGridLevel',
                            payee_level_rate,
                            payee_level_name,
                            payer_level_rate,
                            payer_level_name,
                            carrier_grid_override_rate: override_rate,
                            relevantCIRG,
                            notes,
                        };
                        finalTable.push(finalRow);
                    }
                }
            }
        }
        console.log('finalTable', finalTable);
        // Ends the processor
        //* ************************************************//
        //* ************************************************//
        // Begins the Upload Format Table
        // Build the upload table
        const uploadTable = [];
        for (let index = 0; index < finalTable.length; index++) {
            const curRow = finalTable[index];
            curRow.profile_name = [
                curRow.company,
                curRow.product_type,
                curRow.compensation_type,
                curRow.payee_level_name,
            ].join(' - ');
            uploadTable.push(curRow);
        }
        console.log('uploadTable', uploadTable);
        // Collapse uploadTable into profiles by profile_name, with each row as a array in a schedules key
        const profiles = {};
        for (let index = 0; index < uploadTable.length; index++) {
            const curRow = uploadTable[index];
            const profile_name = curRow.profile_name;
            if (profiles[profile_name] == null) {
                profiles[profile_name] = {
                    name: profile_name,
                    company: curRow.company,
                    product_type: curRow.product_type,
                    schedules: [],
                };
            }
            const rule = {
                product_name: curRow.product_name,
                option: curRow.option,
                issue_age_min: curRow.issue_age_min,
                issue_age_max: curRow.issue_age_max,
                premium_min: curRow.premium_min,
                premium_max: curRow.premium_max,
                transaction_type: curRow.transaction_type,
                compensation_type: curRow.compensation_type,
                match_criteria: curRow.match_criteria,
                calculation_method: curRow.calculation_method,
                payee_level_rate: curRow.payee_level_rate,
                payee_level_name: curRow.payee_level_name,
                payer_level_rate: curRow.payer_level_rate,
                payer_level_name: curRow.payer_level_name,
                carrier_grid_override_rate: curRow.carrier_grid_override_rate,
            };
            profiles[profile_name].schedules.push(rule);
        }
        console.log('profiles', profiles);
        setJSON(JSON.stringify(Object.values(profiles), null, 2));
        // Ends the Upload Format Table
        //* ************************************************//
        // const jsonSet = new Set(jsonRes.map((item) => JSON.stringify(item)));
        // setJSON(JSON.stringify(transformObjectVal(jsonRes, fieldsMap), null, 2));
        // const jsonSet = new Set(uploadTable.map((item) => JSON.stringify(item)));
        // setJSON(
        //   JSON.stringify(transformObjectVal(uploadTable, fieldsMap), null, 2)
        // );
    }, [csv]);
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { children: "Bulk add entities in csv/tsv format" }), _jsxs(Typography, { variant: "caption", children: ["Standard CSV/TSV format, you may remove columns that you don't need, but these are required: (", `${requiredFields.map((field) => field.id).join(', ')}`, ")"] }), _jsx(Box, { sx: { mt: 1 }, children: selectFields.map((field) => {
                    var _a;
                    return (_jsxs(FormControl, { sx: { mb: 1 }, fullWidth: true, children: [_jsx(InputLabel, { id: `${field.id}-label`, children: field.label }), _jsx(Select, { label: field.label, value: selectedVals === null || selectedVals === void 0 ? void 0 : selectedVals[field.id], onChange: (event) => setSelectedVals(Object.assign(Object.assign({}, selectedVals), { [field.id]: event.target.value })), children: (_a = selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions[field.table]) === null || _a === void 0 ? void 0 : _a.map((option) => (_jsxs(MenuItem, { value: option.id, children: [option.id, " ", (option === null || option === void 0 ? void 0 : option.access) ? `(${option.access})` : '', ":", ' ', field.optionFormatter(option)] }, option.id))) })] }, field.id));
                }) }), _jsx(TextField, { label: "CSV/TSV", multiline: true, rows: 8, value: csv, onChange: (event) => {
                    setCsv(event.target.value);
                }, sx: { width: '100%' }, fullWidth: true }), _jsx(TextField, { label: "Entities", multiline: true, maxRows: 20, value: JSON.parse(json).map(JSON.stringify).join('\n'), sx: { width: '100%', mt: 1 }, fullWidth: true }), _jsxs(Box, { sx: { mt: 1, display: 'flex', justifyContent: 'space-between' }, children: [_jsx(Box, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: autoCreate, onChange: (event) => setAutoCreate(event.target.checked) }), label: "Auto-create companies/products/options" }) }), _jsxs(Box, { sx: { display: 'flex', justifyContent: 'flex-end' }, children: [_jsx(Button, { sx: { mr: 1 }, onClick: onCancel, children: "Cancel" }), _jsx(Button, { variant: "contained", onClick: () => onSave({ autoCreate, data: json }), children: "Bulk add" })] })] })] }));
};
export default DataBulkAddCompGrids;
