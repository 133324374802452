import { jsx as _jsx } from "react/jsx-runtime";
import DataView from '@/components/DataView';
import LazyLoadDynamicSelect from '@/components/molecules/LazyLoadDynamicSelect';
import CompProfilesAdd from '@/components/schedules/CompProfilesView/CompProfilesAdd';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';
const CompProfileSetsView = () => {
    var _a, _b;
    const { userRole } = useRoleStore();
    const { selectedAccount } = useAccountStore();
    const { data: accountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.carriers_schedules;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    const dataDesc = {
        label: 'Compensation profile sets',
        table: 'schedules/comp-profile-sets',
        editable: userRole === Roles.ACCOUNT_ADMIN,
        fields: [
            { id: 'name', label: 'Name' },
            { id: 'notes', label: 'Notes' },
            {
                id: 'commission_profiles',
                label: 'Comp profiles',
                disableSort: true,
                table: 'schedules/comp-profiles',
                field: 'id',
                multiple: true,
                formatter: (val, collectionVals = []) => {
                    var _a, _b;
                    if (val === '')
                        return '';
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const record = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return (_b = record === null || record === void 0 ? void 0 : record.name) !== null && _b !== void 0 ? _b : '';
                    }
                    return val;
                },
                optionValuer: (option) => option === null || option === void 0 ? void 0 : option.id,
                optionFormatter: (option) => option === null || option === void 0 ? void 0 : option.name,
                type: 'dynamic-select',
                tableFormatter: (field, row, dynamicSelects, header) => (_jsx(LazyLoadDynamicSelect, { data: (field !== null && field !== void 0 ? field : []).map((o) => o === null || o === void 0 ? void 0 : o.id), header: header, formatter: (val) => val === null || val === void 0 ? void 0 : val.name, link: "/schedules/comp-profiles" })),
            },
            {
                id: 'contacts_agent_commission_schedule_profiles_sets',
                label: 'Agents',
                disableSort: true,
                type: 'custom',
                table: 'contacts',
                tableFormatter: (field, row, dynamicSelects, header) => (_jsx(LazyLoadDynamicSelect, { data: (field !== null && field !== void 0 ? field : []).map((o) => { var _a; return (_a = o === null || o === void 0 ? void 0 : o.contact) === null || _a === void 0 ? void 0 : _a.id; }), header: header, formatter: (o) => Formatter.contact(o, { account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId }), link: "/contacts" })),
                optionFormatter: (option) => `${option.email}`,
                optionValuer: (option) => option === null || option === void 0 ? void 0 : option.id,
                render: (field, row, setter, dynamicSelects) => (_jsx(CompProfilesAdd, { table: "contacts_agent_commission_schedule_profiles_sets", data: row, setter: setter, field: field, dynamicSelects: dynamicSelects, readOnly: userRole !== Roles.ACCOUNT_ADMIN }, "contacts_agent_commission_schedule_profiles_sets")),
            },
            { id: 'divider', type: 'divider' },
            {
                id: 'created_at',
                label: 'Created at',
                condition: (data) => !!data.created_at,
                formatter: Formatter.dateTime,
                readOnly: true,
                visible: ['form'],
            },
            {
                id: 'updated_at',
                label: 'Updated at',
                condition: (data) => !!data.updated_at,
                formatter: Formatter.dateTime,
                readOnly: true,
                visible: ['form'],
            },
        ],
    };
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    return (_jsx(DataView, { dataDesc: dataDesc, hideExport: true, viewOnly: viewOnly, readOnly: userRole !== Roles.ACCOUNT_ADMIN }));
};
export default CompProfileSetsView;
