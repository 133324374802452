import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AttachFileOutlined, Close, ExpandLess, ExpandMore, ReceiptOutlined, RequestQuoteOutlined, TableChartOutlined, } from '@mui/icons-material';
import { Autocomplete, Box, Button, Collapse, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, Tooltip, } from '@mui/material';
import { UIStateContext } from 'contexts/UIStateProvider';
import useBeforeUnloadPage from 'contexts/useBeforeunloadPage';
import { cloneDeep, isEqual } from 'lodash-es';
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState, } from 'react';
import { useBeforeUnload } from 'react-use';
import { useOriginalFile, useSetOriginFile } from 'store/excelStore';
import validator from 'validator';
import { DocumentTypes } from 'common/constants/documents';
import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import useUploadStore from '@/store/uploadStore';
const initialFormState = {
    companies: { value: '', error: false },
    documentType: { value: '', error: false },
    originalFile: { value: [], error: false, required: true },
};
const FileUploads = ({ setCurrent, setFormModel, formModel }, ref) => {
    const [loading, setLoading] = useState(false);
    const { role: [role], } = useContext(UIStateContext);
    const { showSnackbar } = useSnackbar();
    const resetProgresses = useUploadStore((s) => s.resetProgresses);
    const [formState, setFormState] = useState(initialFormState);
    const [error, setError] = useState(false);
    const [newCompany, setNewCompany] = useState({
        value: '',
        error: false,
    });
    const [eachCompany, setEachCompany] = useState({});
    const [companyError, setCompanyError] = useState([]);
    const [eachDocType, setEachDocType] = useState({});
    const [docTypeError, setDocTypeError] = useState([]);
    const [bankTotalAmounts, setBankTotalAmounts] = useState({});
    const [checkDates, setCheckDates] = useState({});
    const [depositDates, setDepositDates] = useState({});
    const [multiCompanyMode, setMultiCompanyMode] = useState(false);
    const [multiDocTypeMode, setMultiDocTypeMode] = useState(false);
    const [fileCollapse, setFileCollapse] = useState([]);
    const documentTypesList = [
        {
            label: 'Commissions',
            value: DocumentTypes.STATEMENT,
            icon: _jsx(RequestQuoteOutlined, { sx: { color: '#666', mr: 1 } }),
        },
        {
            label: 'Policies',
            value: DocumentTypes.REPORT,
            icon: _jsx(ReceiptOutlined, { sx: { color: '#666', mr: 1 } }),
        },
        {
            label: 'Comp grid',
            value: DocumentTypes.COMPGRID,
            icon: _jsx(TableChartOutlined, { sx: { color: '#666', mr: 1 } }),
        },
    ];
    const [isAddingCompany, setIsAddingCompany] = useState(false);
    const { data: companies, refetch } = API.getBasicQuery('companies');
    const companiesPoster = API.getMutation('companies', 'POST');
    const companiesOptions = useMemo(() => {
        var _a, _b;
        if (!companies) {
            return [];
        }
        return (_b = (_a = companies === null || companies === void 0 ? void 0 : companies.data) === null || _a === void 0 ? void 0 : _a.map((company) => ({
            label: company.company_name,
            value: company.str_id,
        }))) === null || _b === void 0 ? void 0 : _b.sort((a, b) => (b.label > a.label ? -1 : 1));
    }, [companies]);
    const [formDirty, setFormDirty] = useState(false);
    const dirtyFn = useCallback(() => {
        const isChange = !isEqual(formState, initialFormState);
        setFormDirty(isChange);
        return isChange;
    }, [formState]);
    useBeforeUnload(dirtyFn, 'You have unsaved changes, are you sure?');
    useBeforeUnloadPage(formDirty, 'You have unsaved changes, are you sure?');
    const originFile = useOriginalFile();
    const setOriginFile = useSetOriginFile();
    /**
     * Handle form item change
     */
    const handleChange = (field) => (event) => {
        const val = event.target.value;
        setFormState((pre) => {
            const preState = cloneDeep(pre);
            preState[field].value = val;
            preState[field].error = false;
            return preState;
        });
    };
    useEffect(() => {
        if (formModel && formModel.form) {
            setFormState(formModel.form);
        }
        if (formModel && formModel.bank_total_amounts) {
            setBankTotalAmounts(formModel.bank_total_amounts);
        }
        if (formModel && formModel.check_dates) {
            setCheckDates(formModel.check_dates);
        }
        if (formModel && formModel.deposit_dates) {
            setDepositDates(formModel.deposit_dates);
        }
        if (formModel && formModel.companies) {
            setEachCompany(formModel.companies);
        }
        if (formModel && formModel.document_type) {
            setEachDocType(formModel.document_type);
        }
    }, [formModel, companiesOptions]);
    /**
     * Handle file delete
     */
    const onFileDelete = (f, index) => {
        const deletePathKey = (state, pathKey) => {
            const newState = Object.assign({}, state);
            delete newState[pathKey];
            return newState;
        };
        setFormState((pre) => {
            const preState = Object.assign({}, pre);
            preState['originalFile'].value = preState['originalFile'].value.filter((each) => each !== f);
            return preState;
        });
        setEachCompany((pre) => deletePathKey(pre, f.path));
        setEachDocType((pre) => deletePathKey(pre, f.path));
        setBankTotalAmounts((pre) => deletePathKey(pre, f.path));
        setCheckDates((pre) => deletePathKey(pre, f.path));
        setDepositDates((pre) => deletePathKey(pre, f.path));
        setCompanyError((pre) => pre.filter((_, i) => i !== index));
        setDocTypeError((pre) => pre.filter((_, i) => i !== index));
        setFileCollapse((pre) => pre.filter((_, i) => i !== index));
    };
    /**
     * Handle file add
     */
    const addMoreFile = (field) => (files) => {
        if (field === 'originalFile') {
            setFormState((pre) => {
                const preState = cloneDeep(pre);
                // Remove the duplicate files
                files = files.filter((file) => {
                    return !preState[field].value.some((each) => each.path === file.path);
                });
                preState[field].value.push(...files);
                return preState;
            });
            const newAmountsMap = {};
            const newCompanyMap = {};
            const newDocTypeMap = {};
            files.forEach((f) => {
                newAmountsMap[f.path] = '';
                newCompanyMap[f.path] = {
                    label: '',
                    value: '',
                };
                newDocTypeMap[f.path] = '';
            });
            setBankTotalAmounts((prev) => (Object.assign(Object.assign({}, prev), newAmountsMap)));
            setEachCompany((prev) => (Object.assign(Object.assign({}, prev), newCompanyMap)));
            setEachDocType((prev) => (Object.assign(Object.assign({}, prev), newDocTypeMap)));
            setCompanyError((prev) => [...prev, ...Array(files.length).fill(false)]);
            setDocTypeError((prev) => [...prev, ...Array(files.length).fill(false)]);
            setFileCollapse((prev) => [...prev, ...Array(files.length).fill(false)]);
        }
    };
    /**
     * Handle form submit
     */
    const handleSubmit = () => {
        var _a, _b;
        let isValid = true;
        const entries = Object.entries(formState);
        entries.forEach(([field, value]) => {
            if (!value.value && value.required) {
                isValid = false;
                setFormState((pre) => {
                    const preState = cloneDeep(pre);
                    preState[field].error = true;
                    return preState;
                });
            }
        });
        if (Object.entries(eachCompany).length === 0 ||
            Object.entries(eachDocType).length === 0) {
            isValid = false;
        }
        const updatedCompanyError = companyError.slice();
        Object.values(eachCompany).some((innerObj, index) => {
            if ((innerObj === null || innerObj === void 0 ? void 0 : innerObj.value) === '') {
                isValid = false;
                updatedCompanyError[index] = true;
            }
        });
        const updatedDocTypeError = docTypeError.slice();
        Object.values(eachDocType).some((innerObj, index) => {
            if (!innerObj) {
                isValid = false;
                updatedDocTypeError[index] = true;
            }
        });
        // if (!isValid) {
        //   setCompanyError(updatedCompanyError);
        //   setDocTypeError(updatedDocTypeError);
        //   console.error('Form validation error');
        // } else {
        // store the file to global store
        const _originalFiles = formState.originalFile.value;
        if (_originalFiles.length) {
            setOriginFile(_originalFiles);
        }
        // Store current data to formModel
        setFormModel({
            companies: multiCompanyMode ? eachCompany : (_a = formState.companies) === null || _a === void 0 ? void 0 : _a.value,
            document_type: multiDocTypeMode
                ? eachDocType
                : (_b = formState.documentType) === null || _b === void 0 ? void 0 : _b.value,
            form: cloneDeep(formState),
            bank_total_amounts: bankTotalAmounts,
            check_dates: checkDates,
            deposit_dates: depositDates,
        });
        // Go to next step
        setCurrent((pre) => pre + 1);
        // }
    };
    const onAddCompany = (evt) => {
        evt.preventDefault();
        const companyName = newCompany.value;
        if (!companyName) {
            setNewCompany((pre) => (Object.assign(Object.assign({}, pre), { error: true })));
            return;
        }
        const companyItem = {
            company_name: companyName,
        };
        setLoading(true);
        companiesPoster.mutate(companyItem, {
            onSuccess: (res) => {
                refetch(); // Refresh companies list
                setLoading(false);
                setNewCompany({ value: '', error: false });
                showSnackbar(`Added new company ${companyName}`, 'success');
                // Auto select the new company
                setFormState((pre) => {
                    const preState = cloneDeep(pre);
                    preState.companies.value = { label: companyName, value: res.str_id };
                    preState.companies.error = false;
                    return preState;
                });
                if (!multiCompanyMode) {
                    const tempEachCompany = eachCompany;
                    Object.keys(tempEachCompany).forEach((key) => {
                        tempEachCompany[key] = { label: companyName, value: res.str_id };
                    });
                    setEachCompany(tempEachCompany);
                }
                setIsAddingCompany(false);
            },
            onError: (err) => {
                showSnackbar(err.message, 'error');
                setLoading(false);
            },
        });
    };
    const clearFile = () => {
        setOriginFile(null);
        setFormState(initialFormState);
    };
    useImperativeHandle(ref, () => ({
        submit: handleSubmit,
        clearFile,
    }));
    return (_jsx("div", { style: { minWidth: 640, maxWidth: 1200 }, children: _jsxs("form", { onSubmit: handleSubmit, style: { width: '100%' }, children: [_jsxs(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                    }, children: [!isAddingCompany && (_jsx(FormControl, { required: true, fullWidth: true, sx: {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }, error: formState.companies.error, children: _jsxs(Box, { sx: {
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                }, children: [_jsx(Autocomplete, { id: "company-search", options: companiesOptions, getOptionLabel: (option) => option && option.label, value: formState.companies.value, isOptionEqualToValue: (option, value) => value && option.value === value.value, onChange: (event, newValue) => {
                                            if (!newValue)
                                                return;
                                            handleChange('companies')({ target: { value: newValue } });
                                            if (!multiCompanyMode) {
                                                const updatedEachCompany = Object.assign({}, eachCompany);
                                                Object.keys(updatedEachCompany).forEach((key) => {
                                                    updatedEachCompany[key] = newValue;
                                                });
                                                setEachCompany(updatedEachCompany);
                                            }
                                        }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Company", variant: "outlined", error: formState.companies.error, helperText: formState.companies.error && 'Required' }))), renderOption: (props, option) => (_createElement("li", Object.assign({}, props, { key: option.value }), option.label)), fullWidth: true }), _jsx(Button, { variant: "outlined", sx: { ml: 1, minWidth: 155 }, onClick: () => setIsAddingCompany(true), children: "Add new company" })] }) })), isAddingCompany && (_jsxs(Box, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flex: 1,
                            }, onKeyDown: (e) => e.stopPropagation(), children: [_jsx(TextField, { sx: { flex: 1 }, label: "New company", error: newCompany.error, value: newCompany.value, onChange: (evt) => setNewCompany({ value: evt.target.value, error: false }) }), _jsx(Button, { sx: { ml: 1 }, onClick: onAddCompany, variant: newCompany.value ? 'contained' : 'outlined', disabled: !newCompany.value, children: loading ? 'Adding...' : 'Add' }), _jsx(Button, { variant: "outlined", sx: { ml: 1 }, onClick: () => setIsAddingCompany(false), children: "Cancel" })] })), _jsx(Tooltip, { title: "Select if documents are from multiple carriers", children: _jsx(FormControlLabel, { control: _jsx(Switch, { onChange: () => setMultiCompanyMode((prev) => !prev), checked: multiCompanyMode }), label: "Various", sx: { ml: 0.5, mr: 0 } }) })] }), _jsxs(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                    }, children: [_jsxs(FormControl, { sx: { flex: 1, my: 1, width: '100%' }, error: formState.documentType.error, children: [_jsx(InputLabel, { children: "Document type" }), _jsx(Select, { value: formState.documentType.value, onChange: (event, newValue) => {
                                        if (!newValue)
                                            return;
                                        handleChange('documentType')({
                                            target: { value: event.target.value },
                                        });
                                        if (!multiDocTypeMode) {
                                            const updatedEachDocType = Object.assign({}, eachDocType);
                                            Object.keys(updatedEachDocType).forEach((key) => {
                                                updatedEachDocType[key] = event.target.value;
                                            });
                                            setEachDocType(updatedEachDocType);
                                        }
                                    }, label: "Document type", children: documentTypesList.map((item) => (_jsx(MenuItem, { value: item.value, children: _jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [item.icon, item.label] }) }, item.value))) })] }), _jsx(Tooltip, { title: "Select if documents are of differnt types", children: _jsx(FormControlLabel, { control: _jsx(Switch, { onChange: () => setMultiDocTypeMode((prev) => !prev), checked: multiDocTypeMode }), label: "Various", sx: { ml: 0.5, mr: 0 } }) })] }), _jsx(FactoryFileUpload, { required: true, error: formState.originalFile.error, curFile: originFile, onChange: addMoreFile('originalFile'), multiple: true, showFileInfo: false }), _jsx(Box, { sx: { mt: 1 }, children: !!formState.originalFile.value.length &&
                        formState.originalFile.value.map((f, index) => (_jsxs(Box, { sx: {
                                position: 'relative',
                                display: 'flex',
                                fontSize: '0.875rem',
                                '& .MuiInputLabel-root': {
                                    fontSize: '0.875rem',
                                },
                                '& .MuiInputBase-root': {
                                    fontSize: '0.875rem',
                                },
                            }, children: [_jsx(IconButton, { className: "group absolute p-0 -top-1 -right-2 cursor-pointer hover:text-blue-600", onClick: () => onFileDelete(f, index), children: _jsx(Close, { className: "text-sm group-hover:rotate-180 transition-all origin-center rounded-full border border-gray-400 p-1 bg-white hover:bg-gray-100", style: { fontSize: '1.35rem' } }) }), _jsxs(Box, { sx: {
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        margin: '4px 0',
                                        p: 1,
                                        pt: 1.5,
                                        border: '1px dashed #ccc',
                                        gap: 0.5,
                                    }, children: [_jsxs(Box, { sx: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                color: 'text.primary',
                                                justifyContent: 'space-between',
                                            }, children: [_jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        overflow: 'hidden',
                                                        whiteSpace: 'nowrap',
                                                        overflowX: 'auto',
                                                        mr: 2,
                                                        '&::-webkit-scrollbar': {
                                                            display: 'none',
                                                        },
                                                    }, children: [_jsx(AttachFileOutlined, { sx: { pr: 1 } }), f.path, " - ", (f.size / 1024).toFixed(2), " KB"] }), _jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [multiCompanyMode && (_jsx(Autocomplete, { id: "company-search", options: companiesOptions, getOptionLabel: (option) => option && option.label, value: eachCompany[f.name], isOptionEqualToValue: (option, value) => value && option.value === value.value, sx: {
                                                                mr: 1,
                                                                minWidth: 160,
                                                            }, onChange: (event, newValue) => {
                                                                if (!newValue)
                                                                    return;
                                                                if (!multiCompanyMode &&
                                                                    newValue !== eachCompany[f.name]) {
                                                                    setMultiCompanyMode(true);
                                                                }
                                                                setEachCompany((pre) => {
                                                                    const preState = cloneDeep(pre);
                                                                    preState[f.name] = newValue;
                                                                    return preState;
                                                                });
                                                                setCompanyError((prev) => {
                                                                    const updated = [...prev];
                                                                    updated[index] = false;
                                                                    return updated;
                                                                });
                                                            }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Company", variant: "outlined", error: companyError[index], sx: {
                                                                    fontSize: '0.875rem',
                                                                } }))), renderOption: (props, option) => (_createElement("li", Object.assign({}, props, { key: option.value }), option.label)) })), multiDocTypeMode && (_jsxs(FormControl, { error: docTypeError[index], sx: { mr: 1 }, children: [_jsx(InputLabel, { children: "Type" }), _jsx(Select, { value: eachDocType[f.path], onChange: (event, newValue) => {
                                                                        if (!newValue)
                                                                            return;
                                                                        if (!multiDocTypeMode &&
                                                                            event.target.value !== eachDocType[f.path]) {
                                                                            setMultiDocTypeMode(true);
                                                                        }
                                                                        setEachDocType((pre) => {
                                                                            const preState = cloneDeep(pre);
                                                                            preState[f.path] = event.target.value;
                                                                            return preState;
                                                                        });
                                                                        setDocTypeError((prev) => {
                                                                            const updated = [...prev];
                                                                            updated[index] = false;
                                                                            return updated;
                                                                        });
                                                                    }, label: "Type", sx: { minWidth: 134 }, children: documentTypesList.map((item) => (_jsx(MenuItem, { value: item.value, children: _jsx(Box, { sx: { display: 'flex', alignItems: 'center' }, children: item.label }) }, item.value))) })] })), _jsx(Tooltip, { title: "Amount received in your bank for this statement. We'll cross reference with records from this document.", children: _jsx(TextField, { label: "Bank total", sx: {
                                                                    mr: 1,
                                                                    width: 130,
                                                                }, InputProps: {
                                                                    startAdornment: (_jsx(InputAdornment, { position: "start", children: "$" })),
                                                                }, value: bankTotalAmounts[f.path] || '', error: error, helperText: error ? 'Invalid amount' : '', onChange: (evt) => {
                                                                    const val = evt.target.value;
                                                                    const isValidCurrency = validator.isCurrency(val, {
                                                                        allow_negatives: true,
                                                                        thousands_separator: ',',
                                                                        decimal_separator: '.',
                                                                        allow_decimal: true,
                                                                        digits_after_decimal: [1, 2],
                                                                    });
                                                                    if (val !== '' &&
                                                                        (!isValidCurrency ||
                                                                            parseFloat(val) >= 1e12 ||
                                                                            parseFloat(val) <= -1e12)) {
                                                                        setError(true);
                                                                    }
                                                                    else {
                                                                        setError(false);
                                                                    }
                                                                    setBankTotalAmounts((pre) => {
                                                                        const preState = cloneDeep(pre);
                                                                        preState[f.path] = val;
                                                                        return preState;
                                                                    });
                                                                } }) }), fileCollapse[index] ? (_jsx(ExpandLess, { sx: {
                                                                cursor: 'pointer',
                                                                transition: 'all 0.3s ease',
                                                                color: '#888',
                                                                '&:hover': {
                                                                    color: '#555',
                                                                    transform: 'scale(1.1)',
                                                                },
                                                                '&:active': {
                                                                    transform: 'scale(0.95)',
                                                                },
                                                            }, onClick: () => setFileCollapse((pre) => {
                                                                const newState = [...pre];
                                                                newState[index] = false;
                                                                return newState;
                                                            }) })) : (_jsx(ExpandMore, { sx: {
                                                                cursor: 'pointer',
                                                                transition: 'all 0.3s ease',
                                                                color: '#888',
                                                                '&:hover': {
                                                                    color: '#555',
                                                                    transform: 'scale(1.1)',
                                                                },
                                                                '&:active': {
                                                                    transform: 'scale(0.95)',
                                                                },
                                                            }, onClick: () => setFileCollapse((pre) => {
                                                                const newState = [...pre];
                                                                newState[index] = true;
                                                                return newState;
                                                            }) }))] })] }), _jsx(Collapse, { in: fileCollapse[index], children: _jsxs(Box, { sx: {
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                }, children: [_jsx(BasicDatePicker, { label: "Check date", value: checkDates[f.path], setValue: (e) => setCheckDates((pre) => {
                                                            const preState = cloneDeep(pre);
                                                            preState[f.path] = e;
                                                            return preState;
                                                        }), sx: { mr: 1 } }), _jsx(BasicDatePicker, { label: "Deposit date", value: depositDates[f.path], setValue: (e) => setDepositDates((pre) => {
                                                            const preState = cloneDeep(pre);
                                                            preState[f.path] = e;
                                                            return preState;
                                                        }) })] }) })] })] }, index))) })] }) }));
};
export default forwardRef(FileUploads);
