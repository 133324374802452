import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, Tooltip, Typography, } from '@mui/material';
import { isValidElement, useMemo, useState } from 'react';
const applyFunc = (func, arg) => (typeof func === 'function' ? func(arg) : arg);
const DynamicSelect = ({ field, readOnly = false, formattedData = {}, oldData = {}, endAdornment = null, setNewData, formState = undefined, setFormState = undefined, dynamicSelects, disabled = false, fullWidth = false, dataFormMode = true, sx = {}, addMode = false, onChange = (e, row) => { }, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const [query, setQuery] = useState('');
    const [opened, setOpened] = useState(false);
    const [visibleCount, setVisibleCount] = useState(20);
    const isControlled = formState && typeof setFormState === 'function';
    const handleSelectAll = (e, options) => {
        e.stopPropagation();
        const selected = options
            .map((option) => applyFunc(field.optionValuer, option))
            .filter((option) => option);
        if (dataFormMode) {
            setNewData(Object.assign(Object.assign({}, formattedData), { [field.id]: selected }));
        }
        else {
            setNewData(selected);
        }
    };
    const filteredOptions = useMemo(() => {
        var _a, _b, _c, _d, _e;
        return (_e = (_d = (_c = applyFunc(field.sorter, (_b = (_a = dynamicSelects[field.table]) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : dynamicSelects[field.table])) === null || _c === void 0 ? void 0 : _c.sort((a, b) => applyFunc(field.optionFormatter, a) <
            applyFunc(field.optionFormatter, b)
            ? -1
            : 1)) === null || _d === void 0 ? void 0 : _d.reduce((unique, option) => {
            const optionValue = applyFunc(field.optionValuer, option);
            return unique.some((item) => applyFunc(field.optionValuer, item) === optionValue)
                ? unique
                : [...unique, option];
        }, [])) === null || _e === void 0 ? void 0 : _e.filter((option) => {
            var _a, _b;
            const trimmedQuery = query.trim().toLowerCase();
            const itemName = (_b = (_a = option === null || option === void 0 ? void 0 : option.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '';
            const formattedOptionResult = applyFunc(field.optionFormatter, option);
            const formattedOption = typeof formattedOptionResult === 'string'
                ? formattedOptionResult.toLowerCase()
                : '';
            if (trimmedQuery.startsWith('"') && trimmedQuery.endsWith('"')) {
                const exactMatchRegex = new RegExp(`\\b${trimmedQuery.slice(1, -1)}\\b`);
                return (exactMatchRegex.test(itemName) ||
                    exactMatchRegex.test(formattedOption));
            }
            const queryFieldsValues = (Array.isArray(field.queryFields) ? field.queryFields : [])
                .map((field) => { var _a, _b; return (_b = (_a = option[field]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : ''; })
                .join(' ');
            return (trimmedQuery === '' ||
                typeof formattedOption !== 'string' ||
                trimmedQuery.split(' ').every((word) => {
                    return (formattedOption.includes(word) ||
                        itemName.includes(word) ||
                        queryFieldsValues.includes(word));
                }));
        });
    }, [dynamicSelects, field.optionFormatter, field.sorter, field.table, query]);
    const fieldValue = Array.isArray(formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id])
        ? typeof field.optionValuer === 'function'
            ? ((_a = formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) !== null && _a !== void 0 ? _a : []).map((item) => typeof item === 'object' ? field.optionValuer(item) : item)
            : formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]
        : field.multiple
            ? []
            : ((_b = formattedData === null || formattedData === void 0 ? void 0 : formattedData[field.id]) !== null && _b !== void 0 ? _b : '');
    // Ensure that the selected values show up in list of options
    const filteredOptionsSelected = (_c = filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.filter((o) => {
        const optionValue = applyFunc(field.optionValuer, o);
        return Array.isArray(fieldValue)
            ? fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.includes(optionValue)
            : fieldValue === optionValue;
    })) !== null && _c !== void 0 ? _c : [];
    const filteredOptionsOther = (_d = filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.filter((o) => {
        const optionValue = applyFunc(field.optionValuer, o);
        return !(Array.isArray(fieldValue)
            ? fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.includes(optionValue)
            : fieldValue === optionValue);
    })) !== null && _d !== void 0 ? _d : [];
    const filteredOptionsVisible = (_e = [
        ...filteredOptionsSelected,
        ...filteredOptionsOther,
    ]) === null || _e === void 0 ? void 0 : _e.slice(0, visibleCount);
    // const filteredOptionsVisible = filteredOptions?.slice(0, visibleCount);
    const isLoading = !((_g = (_f = dynamicSelects[field.table]) === null || _f === void 0 ? void 0 : _f.data) !== null && _g !== void 0 ? _g : dynamicSelects[field.table]);
    return (_jsx(Tooltip, { title: field.tip, placement: "right", children: _jsxs(FormControl, { fullWidth: fullWidth, disabled: readOnly ||
                disabled ||
                (typeof field.readOnly === 'function'
                    ? field.readOnly(formattedData, oldData)
                    : field.readOnly), sx: Object.assign({ minWidth: 100 }, sx), children: [_jsx(InputLabel, { id: `${field.id}-label`, children: field.label }), _jsxs(Select, { labelId: `${field.id}-label`, id: field.id, open: isControlled ? (formState === null || formState === void 0 ? void 0 : formState[field.id]) || false : opened, endAdornment: typeof field.endAdornment === 'function'
                        ? field.endAdornment(formattedData, field, setNewData)
                        : endAdornment, onOpen: () => {
                        isControlled
                            ? // @ts-ignore
                                setFormState(Object.assign(Object.assign({}, formState), { [field.id]: true }))
                            : setOpened(true);
                    }, onClose: () => {
                        isControlled
                            ? // @ts-ignore
                                setFormState(Object.assign(Object.assign({}, formState), { [field.id]: false }))
                            : setOpened(false);
                        setVisibleCount(20);
                    }, multiple: (_h = field.multiple) !== null && _h !== void 0 ? _h : false, disabled: typeof field.readOnly === 'function'
                        ? field.readOnly(formattedData, oldData)
                        : field.readOnly, value: isLoading ? (field.multiple ? [] : '') : fieldValue, label: field.label, onChange: dataFormMode
                        ? (e) => {
                            var _a;
                            if (field.onChange)
                                field.onChange(e, formattedData);
                            const setter = (val) => {
                                if (typeof field.preSetter === 'function') {
                                    val = field.preSetter(val);
                                }
                                setNewData(val);
                            };
                            if (['contacts'].includes(field.id)) {
                                let updatedData = Object.assign({}, formattedData);
                                if (Array.isArray(e.target.value)) {
                                    // Create a map to count the occurrences of each id in e.target.value
                                    const idCounts = e.target.value.reduce((counts, item) => {
                                        if (item)
                                            counts[item.id] = (counts[item.id] || 0) + 1;
                                        return counts;
                                    }, {});
                                    // Find the ids that occur more than once
                                    const duplicateIds = new Set(Object.keys(idCounts).filter((id) => idCounts[id] > 1));
                                    const duplicateIdsAsInts = new Set(Array.from(duplicateIds, (id) => Number(id)));
                                    // Filter out the objects in e.target.value whose ids are in duplicateIds
                                    const uniqueValues = e.target.value.filter((item) => item && !duplicateIdsAsInts.has(item.id));
                                    // Update updatedData[field.id] with the unique values
                                    updatedData[field.id] = uniqueValues;
                                }
                                else {
                                    // If it's not an array, just set the value
                                    updatedData[field.id] =
                                        e.target.value === '' ? null : e.target.value;
                                }
                                setter(updatedData);
                            }
                            else {
                                setter(Object.assign(Object.assign({}, formattedData), { [field.id]: e.target.value === ''
                                        ? null
                                        : Array.isArray(e.target.value)
                                            ? (_a = e.target.value) === null || _a === void 0 ? void 0 : _a.filter((item) => item)
                                            : e.target.value }));
                            }
                        }
                        : (e) => {
                            if (field.onChange)
                                field.onChange(e, formattedData);
                            setNewData(e);
                        }, renderValue: (selected) => {
                        var _a, _b;
                        return (_jsx(Box, { sx: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                            }, children: field.multiple
                                ? selected === null || selected === void 0 ? void 0 : selected.map((selectedItem, i) => {
                                    var _a, _b, _c, _d;
                                    const value = applyFunc(field.optionValuer, selectedItem);
                                    const formattedVal = typeof field.formatter === 'function'
                                        ? field.formatter(selectedItem, (_b = (_a = dynamicSelects[field.table]) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : dynamicSelects[field.table])
                                        : selectedItem;
                                    return (_jsxs(Box, { children: [isValidElement(formattedVal) ? (formattedVal) : (_jsx(Chip, { label: formattedVal }, value)), field.delimiter && i < selected.length - 1 && (_jsx(Typography, { sx: { mx: 0.5 }, children: field.delimiter }))] }, (_d = (_c = value === null || value === void 0 ? void 0 : value.id) !== null && _c !== void 0 ? _c : value) !== null && _d !== void 0 ? _d : selectedItem));
                                })
                                : typeof field.formatter === 'function'
                                    ? field.formatter(selected, dynamicSelects[field.table]
                                        ? ((_b = (_a = dynamicSelects[field.table]) === null || _a === void 0 ? void 0 : _a.data) !== null && _b !== void 0 ? _b : dynamicSelects[field.table])
                                        : [])
                                    : selected }));
                    }, sx: {
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#333',
                        },
                    }, children: [_jsxs(Box, { sx: {
                                mb: 0.5,
                                mx: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }, onKeyDown: (e) => {
                                e.stopPropagation();
                            }, children: [_jsx(TextField, { sx: { flex: 1 }, value: query, onChange: (e) => {
                                        setQuery(e.target.value);
                                    }, onClickCapture: (e) => {
                                        console.log(e);
                                        e.stopPropagation();
                                    }, placeholder: "Search" }), addMode ? (_jsx(Box, { children: _jsxs(Button, { variant: "outlined", onClick: (e) => handleSelectAll(e, filteredOptions), sx: { ml: 1 }, children: ["Add all (", filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.length, ")"] }) })) : (field.multiple && (_jsxs(Box, { children: [_jsx(Button, { variant: "outlined", onClick: (e) => handleSelectAll(e, filteredOptions), sx: { ml: 1 }, children: "Select all" }), _jsx(Button, { variant: "outlined", onClick: (e) => handleSelectAll(e, []), sx: { ml: 1 }, children: "Clear" })] })))] }), field.nullable && (_jsx(MenuItem, { value: '', children: _jsx(Box, { children: "\u00A0" }) }, "null")), filteredOptionsVisible === null || filteredOptionsVisible === void 0 ? void 0 : filteredOptionsVisible.map((option) => (_jsx(MenuItem, { value: applyFunc(field.optionValuer, option), children: typeof field.optionFormatter === 'function'
                                ? field.optionFormatter(option)
                                : typeof field.formatter === 'function'
                                    ? field.formatter(option)
                                    : typeof option === 'object'
                                        ? JSON.stringify(option)
                                        : option }, applyFunc(field.optionValuer, option)))), (filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.length) > visibleCount && (_jsx(Box, { sx: { display: 'flex', justifyContent: 'center', width: '100%' }, children: _jsxs(Button, { onClick: () => {
                                    setVisibleCount(visibleCount + 100);
                                }, children: ["Show more (", (filteredOptions === null || filteredOptions === void 0 ? void 0 : filteredOptions.length) - visibleCount, ")"] }) }))] })] }, field.id) }, field.id));
};
export default DynamicSelect;
