import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, } from '@mui/material';
import Formatter from 'common/Formatter';
import { nanoid } from 'nanoid';
import React from 'react';
import dayjs from 'dayjs';
import { useAccountStore } from '@/store';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import { FieldTypes } from '@/types';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
const applyFunc = (func, arg) => (typeof func === 'function' ? func(arg) : arg);
const ParentsHierarchyAdd = ({ data, field, setter, syncedFields, isSyncedField, dynamicSelects, }) => {
    var _a, _b, _c;
    const [query, setQuery] = React.useState('');
    const { selectedAccount } = useAccountStore();
    const isReadonly = (data, fieldId) => {
        if (syncedFields === null || syncedFields === void 0 ? void 0 : syncedFields.includes(fieldId)) {
            return isSyncedField(data, syncedFields, fieldId, data.config);
        }
        return false;
    };
    const parentsHierarchy = (_a = data === null || data === void 0 ? void 0 : data.parent_relationships) !== null && _a !== void 0 ? _a : [];
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsx(Box, { sx: {
                        mt: 0.5,
                        pl: 1,
                        pt: 0.5,
                        pb: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                    }, children: _jsxs(Box, { sx: { my: 0.5, display: 'flex', flexWrap: 'wrap' }, children: [parentsHierarchy.map((parent, index) => {
                                var _a;
                                return (_jsxs(Box, { sx: {
                                        m: 0.5,
                                        p: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'silver',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        display: 'inline-block',
                                        width: 300,
                                        backgroundColor: '#2196f30a',
                                    }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: Formatter.contact(parent.parent, {
                                                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                    }) }), _jsx(IconButton, { onClick: () => {
                                                        setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.filter((item) => item.id !== parent.id) }));
                                                    }, children: _jsx(CloseIcon, {}) })] }), _jsx(Box, { children: _jsx(BasicDateRangePicker, { range: {
                                                    startDate: parent.start_date
                                                        ? dayjs.utc(parent.start_date)
                                                        : null,
                                                    startDateLabel: 'Start date',
                                                    endDate: parent.end_date
                                                        ? dayjs.utc(parent.end_date)
                                                        : null,
                                                    endDateLabel: 'End date',
                                                    endAdornmentStartDate: (_jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: parent.sync_id, fieldId: "start_date", fieldType: FieldTypes.DATE, data: parent, onChange: (newOverrideFields) => {
                                                            setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.map((item) => item.id === parent.id
                                                                    ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                        } })),
                                                    endAdornmentEndDate: (_jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: parent.sync_id, fieldId: "end_date", fieldType: FieldTypes.DATE, data: parent, onChange: (newOverrideFields) => {
                                                            setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.map((item) => item.id === parent.id
                                                                    ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                        } })),
                                                }, onChange: ({ startDate, endDate }) => {
                                                    setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.map((item) => item.id === parent.id
                                                            ? Object.assign(Object.assign({}, item), { start_date: startDate, end_date: endDate }) : item) }));
                                                }, width: 140, my: 0.5 }) }), _jsx(Box, { children: _jsx(TextField, { label: "Split percentage", value: (_a = parent.split_percentage) !== null && _a !== void 0 ? _a : '', onChange: (e) => {
                                                    setter(Object.assign(Object.assign({}, data), { parent_relationships: data.parent_relationships.map((item) => item.id === parent.id
                                                            ? Object.assign(Object.assign({}, item), { split_percentage: e.target.value }) : item) }));
                                                }, InputProps: {
                                                    endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                                                }, sx: { my: 0.5, width: '100%' } }) })] }, `${parent.id}-${index}`));
                            }), _jsxs(FormControl, { sx: { m: 0.5, width: 145 }, children: [_jsx(InputLabel, { id: `${field.id}-labelAdd`, children: "Add" }), _jsxs(Select, { labelId: `${field.id}-label`, id: field.id, label: "Add", value: '', onClick: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }, onChange: (e) => {
                                            var _a;
                                            e.preventDefault();
                                            e.stopPropagation();
                                            const newContact = e.target
                                                .value;
                                            const newId = newContact === null || newContact === void 0 ? void 0 : newContact.id;
                                            if (newId) {
                                                setter(Object.assign(Object.assign({}, data), { parent_relationships: [
                                                        ...((_a = data.parent_relationships) !== null && _a !== void 0 ? _a : []),
                                                        {
                                                            parent: dynamicSelects.find((item) => item.id === newId),
                                                            id: nanoid(),
                                                            start_date: null,
                                                            end_date: null,
                                                        },
                                                    ] }));
                                            }
                                        }, sx: {
                                            '& .MuiInputBase-input.Mui-disabled': {
                                                WebkitTextFillColor: '#333',
                                            },
                                            width: '50%',
                                        }, children: [_jsx(Box, { sx: {
                                                    mb: 0.5,
                                                    mx: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }, onClickCapture: (e) => {
                                                    e.stopPropagation();
                                                }, onKeyDown: (e) => {
                                                    e.stopPropagation();
                                                }, children: _jsx(TextField, { sx: { flex: 1 }, label: "Search", value: query, onChange: (e) => {
                                                        setQuery(e.target.value);
                                                    } }) }), field.nullable && (_jsx(MenuItem, { value: '', children: "\u00A0" }, "null")), (_c = (_b = dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.filter((item) => {
                                                var _a, _b, _c, _d;
                                                return (((_b = (_a = item === null || item === void 0 ? void 0 : item.first_name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '') +
                                                    ((_d = (_c = item === null || item === void 0 ? void 0 : item.last_name) === null || _c === void 0 ? void 0 : _c.toLowerCase()) !== null && _d !== void 0 ? _d : '')).includes(query.toLowerCase());
                                            })) === null || _b === void 0 ? void 0 : _b.map((option, index) => (_jsx(MenuItem, { value: option, children: Formatter.contact(option, {
                                                    account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                }) }, applyFunc(field.optionValuer, option) + index)))) !== null && _c !== void 0 ? _c : null] })] }, field.id)] }) }) }, `${field.id}-box`)] }));
};
export default ParentsHierarchyAdd;
