import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, Paper, Typography, Box, Grow, } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ContentCopy as ContentCopyIcon, } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
const ToolDesc = ({ toolDesc = {} }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const [hoveredItem, setHoveredItem] = useState({ category: '', key: '' });
    const [anchorEl, setAnchorEl] = useState(null);
    const timeoutRef = useRef();
    const isHoveringDescription = useRef(false);
    const CodeBlock = styled('pre')(({ theme }) => ({
        margin: 0,
        padding: theme.spacing(1.5),
        backgroundColor: 'black',
        borderRadius: theme.shape.borderRadius,
        color: 'white',
        fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
        fontSize: '13px',
        overflowX: 'auto',
        '& code': {
            padding: 0,
            background: 'none',
        },
    }));
    const handleMouseEnter = (event, category, key) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        setAnchorEl(event.currentTarget);
        setHoveredItem({ category, key });
    };
    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            if (!isHoveringDescription.current) {
                setAnchorEl(null);
                setHoveredItem({ category: '', key: '' });
            }
        }, 100);
    };
    const handleDescriptionMouseEnter = () => {
        isHoveringDescription.current = true;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };
    const handleDescriptionMouseLeave = () => {
        isHoveringDescription.current = false;
        handleMouseLeave();
    };
    const handleToolsCopy = (name) => {
        navigator.clipboard.writeText(name).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };
    const getPopperPosition = () => {
        if (!anchorEl)
            return {};
        const rect = anchorEl.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const descriptionHeight = 600;
        let top = rect.top;
        if (rect.top + descriptionHeight > windowHeight) {
            top = windowHeight - descriptionHeight - 20;
        }
        return {
            position: 'fixed',
            top: Math.max(20, top),
            right: window.innerWidth - rect.left,
            transform: 'translateX(10px)',
            zIndex: 1300,
        };
    };
    return (_jsxs(Box, { sx: { display: 'flex', justifyContent: 'flex-end', position: 'relative' }, children: [_jsx(List, { sx: { width: 270 }, children: Object.keys(toolDesc).map((category) => (_jsxs(Accordion, { sx: {
                        boxShadow: 'none',
                        '&::before': {
                            display: 'none',
                        },
                        '& .MuiAccordionSummary-root': {
                            minHeight: 'auto',
                            padding: '4px 12px',
                            backgroundColor: 'rgba(0, 0, 0, 0.03)',
                            fontFamily: '"Source Sans Pro", sans-serif',
                            fontWeight: 600,
                            fontSize: '0.9rem',
                            '&.Mui-expanded': {
                                minHeight: 'auto',
                            },
                        },
                        '& .MuiAccordionDetails-root': {
                            padding: '4px 12px',
                        },
                    }, defaultExpanded: true, children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), sx: {
                                '& .MuiAccordionSummary-content': {
                                    margin: 0,
                                },
                            }, children: _jsx(Typography, { sx: {
                                    fontSize: '0.9rem',
                                    fontWeight: 600,
                                    fontFamily: '"Source Sans Pro", sans-serif',
                                }, children: category }) }), _jsx(AccordionDetails, { children: Object.keys(toolDesc[category]).map((key) => (_jsx(ListItem, { onMouseEnter: (e) => handleMouseEnter(e, category, key), onMouseLeave: handleMouseLeave, sx: {
                                    cursor: 'pointer',
                                    padding: '4px 8px',
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                    '& .MuiListItemText-primary': {
                                        fontSize: '0.875rem',
                                        fontFamily: '"Source Code Pro", monospace',
                                    },
                                }, children: _jsx(ListItemText, { primary: key }) }, key))) })] }, category))) }), _jsx(Grow, { in: Boolean(hoveredItem.key), children: _jsxs(Paper, { sx: Object.assign({ position: 'absolute', width: 350, p: 2, right: '100%', mr: 2, bgcolor: 'background.paper', boxShadow: 4, maxHeight: '600px', overflowY: 'auto' }, (anchorEl ? getPopperPosition() : {})), onMouseEnter: handleDescriptionMouseEnter, onMouseLeave: handleDescriptionMouseLeave, children: [_jsx(Box, { sx: { mb: 0.5 }, children: _jsxs(Box, { sx: { mb: 1, display: 'flex', alignItems: 'center' }, children: [_jsx(Typography, { color: "primary", sx: {
                                            fontWeight: 600,
                                            mb: 1,
                                            fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
                                            fontSize: '0.9rem',
                                            flexGrow: 1,
                                            mr: 2,
                                            wordBreak: 'break-all',
                                        }, children: (_b = (_a = toolDesc[hoveredItem.category]) === null || _a === void 0 ? void 0 : _a[hoveredItem.key]) === null || _b === void 0 ? void 0 : _b.name }), _jsx(Box, { sx: {
                                            cursor: 'pointer',
                                            color: 'text.secondary',
                                            '&:hover': {
                                                color: 'text.primary',
                                            },
                                        }, onClick: () => {
                                            var _a, _b;
                                            return handleToolsCopy((_b = (_a = toolDesc[hoveredItem.category]) === null || _a === void 0 ? void 0 : _a[hoveredItem.key]) === null || _b === void 0 ? void 0 : _b.name);
                                        }, children: _jsx(ContentCopyIcon, { fontSize: "small" }) })] }) }), _jsx(Box, { sx: { mt: 1 }, children: _jsx(Typography, { variant: "body2", sx: { color: 'text.secondary' }, children: (_d = (_c = toolDesc[hoveredItem.category]) === null || _c === void 0 ? void 0 : _c[hoveredItem.key]) === null || _d === void 0 ? void 0 : _d.description }) }), _jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Typography, { variant: "subtitle2", color: "primary", sx: {
                                        fontWeight: 600,
                                        mb: 1,
                                    }, children: "Arguments:" }), _jsx(Box, { children: Object.entries(((_f = (_e = toolDesc[hoveredItem.category]) === null || _e === void 0 ? void 0 : _e[hoveredItem.key]) === null || _f === void 0 ? void 0 : _f.arguments) ||
                                        {}).map(([argName, argDesc]) => (_jsxs(Typography, { variant: "body2", sx: {
                                            color: 'text.secondary',
                                            fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
                                            '& .type': {
                                                color: '#8250df',
                                            },
                                        }, children: [_jsxs(Box, { component: "span", fontWeight: 600, children: [argName.split(/(\[.*?\])/).map((part, index) => (_jsx(Box, { component: "span", className: part.startsWith('[') ? 'type' : '', children: part }, index))), ":"] }), ' ', String(argDesc)
                                                .split(/(\[.*?\])/)
                                                .map((part, index) => (_jsx(Box, { component: "span", className: part.startsWith('[') ? 'type' : '', children: part }, index)))] }, argName))) })] }), _jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Typography, { variant: "subtitle2", color: "primary", sx: {
                                        fontWeight: 600,
                                        mb: 1,
                                    }, children: "Returns:" }), _jsx(Typography, { variant: "body2", sx: {
                                        color: 'text.secondary',
                                        fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
                                        '& .type': {
                                            color: '#8250df',
                                        },
                                    }, children: (_h = (_g = toolDesc[hoveredItem.category]) === null || _g === void 0 ? void 0 : _g[hoveredItem.key]) === null || _h === void 0 ? void 0 : _h.returns.split(/(\[.*?\])/).map((part, index) => (_jsx(Box, { component: "span", className: part.startsWith('[') ? 'type' : '', children: part }, index))) })] }), _jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Typography, { variant: "subtitle2", color: "primary", sx: {
                                        fontWeight: 600,
                                        mb: 0.5,
                                    }, children: "Example:" }), _jsx(CodeBlock, { children: _jsx("code", { children: ((_k = (_j = toolDesc[hoveredItem.category]) === null || _j === void 0 ? void 0 : _j[hoveredItem.key]) === null || _k === void 0 ? void 0 : _k.examples) ||
                                            '' }) })] })] }) })] }));
};
export default ToolDesc;
