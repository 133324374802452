import { z } from 'zod';
const key_column = z.enum(['id', 'str_id', 'agent_code', 'email']);
const key_data = z.string();
const agent_upline = z.string();
export const bulkAddUplineSchema = z.object({
    key_column,
    key_data,
    agent_upline,
});
export const validateData = (data) => {
    const errRows = [];
    const validData = [];
    for (let i = 0; i < data.length; i++) {
        try {
            validData.push(bulkAddUplineSchema.parse(data[i]));
        }
        catch (_a) {
            errRows.push(i + 1);
        }
    }
    return { errRows, validData };
};
