import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
const TextPreview = ({ file }) => {
    const [fileContent, setFileContent] = useState('');
    useEffect(() => {
        if (!file) {
            setFileContent('');
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            var _a;
            const text = ((_a = e.target) === null || _a === void 0 ? void 0 : _a.result) || '';
            setFileContent(text);
        };
        reader.onerror = () => {
            setFileContent('');
        };
        reader.readAsText(file);
    }, [file]);
    return (_jsx(Box, { children: _jsx("pre", { style: { whiteSpace: 'pre-wrap', wordWrap: 'break-word' }, children: fileContent }) }));
};
export default TextPreview;
