import { jsx as _jsx } from "react/jsx-runtime";
import { Chip } from '@mui/material';
import { AccountIds } from 'common/constants';
import { Link, Navigate } from 'react-router-dom';
import { SyncedEntity } from '@/common/SyncedEntity';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import DataView from '@/components/DataView';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import API from '@/services/API';
import { useAccountStore, useRoleStore } from '@/store';
import { Roles } from '@/types';
import { useUserInfo } from '@/hooks/useUserInfo';
import Formatter from '@/services/Formatter';
import { useIsBulkAddMode } from '@/hooks/useIsBulkAddMode';
const View = () => {
    var _a, _b, _c, _d, _e;
    const { data: { fintaryAdmin } = {} } = useUserInfo();
    const { selectedAccount } = useAccountStore();
    const { userRole } = useRoleStore();
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const { isBulkAddMode } = useIsBulkAddMode();
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.products;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const companies = (_e = (_d = (_c = API.getBasicQuery('companies')) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data) !== null && _e !== void 0 ? _e : [];
    const dataDesc = {
        label: 'Products',
        table: 'companies/products',
        editable: true,
        copyable: true,
        bulkAdd: true,
        filterConfigs: {
            cids: {
                type: 'select',
                label: 'Companies',
                options: companies.map((item) => ({
                    id: item.id,
                    str_id: item.str_id,
                    label: item.company_name,
                })),
            },
        },
        fields: [
            {
                id: 'company_id',
                label: 'Company',
                type: 'dynamic-select',
                table: 'companies',
                field: 'id',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    return (Array.isArray(collectionVals) &&
                        ((_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((company) => company.id === val)) === null || _a === void 0 ? void 0 : _a.company_name)) ||
                        '';
                },
                optionFormatter: (option) => option.company_name,
                optionValuer: (option) => option.id,
                required: true,
                bulkAddSelect: true,
            },
            {
                id: 'product_type',
                label: 'Product type',
                type: 'select',
                options: [
                    'Life',
                    'IUL',
                    'UL',
                    'Term',
                    'Annuity',
                    'Indexed Annuity',
                    'Fixed Annuity',
                    'Health',
                    'Dental',
                    'Vision',
                    'Disability',
                    'Long Term Care',
                    'Other',
                ],
            },
            {
                id: 'product_name',
                label: 'Product name',
                required: true,
            },
            {
                id: 'comp_grid_products',
                label: 'Comp grid products 🔒',
                formatter: (val) => val ? (_jsx(Chip, { label: val === null || val === void 0 ? void 0 : val.name, component: Link, to: `/schedules/comp-grids/products?id=${val === null || val === void 0 ? void 0 : val.str_id}`, clickable: true })) : (''),
                tableFormatter: (val) => (val === null || val === void 0 ? void 0 : val.length) > 0
                    ? val.map((compGridProduct) => (_jsx(Chip, { label: compGridProduct === null || compGridProduct === void 0 ? void 0 : compGridProduct.name, component: Link, to: `/schedules/comp-grids/products?id=${compGridProduct === null || compGridProduct === void 0 ? void 0 : compGridProduct.str_id}`, clickable: true, sx: { m: 0.25 } })))
                    : Formatter.statusChip('Missing products', {
                        mapping: {
                            ['Missing products']: 'yellow',
                        },
                    }),
                readOnly: true,
                enabled: !!fintaryAdmin ||
                    (userRole === Roles.ACCOUNT_ADMIN &&
                        (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) === AccountIds.TRANSGLOBAL),
            },
            {
                id: 'notes',
                label: 'Notes',
            },
        ],
        actions: [
            {
                id: 'sync',
                label: 'Sync',
                type: 'custom',
                getComponent: (row) => (_jsx(SyncedEntity, { isSynced: !!row.sync_id, disabled: true })),
            },
        ],
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            contains_products: {
                id: 'contains_products',
                label: 'Contains products',
                query: {
                    comp_grid_products: 'contains_products',
                },
            },
            missing_products: {
                id: 'missing_products',
                label: 'Missing products',
                query: {
                    comp_grid_products: 'missing_products',
                },
            },
        },
    };
    dataDesc.fields = dataDesc.fields.filter((field) => field.enabled !== false);
    const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();
    dataDesc.fields.forEach((field) => {
        // Store static readOnly if specified, since new one overwritten below
        if (field.readOnly === true)
            field._readOnly = true;
        field.readOnly =
            dataDesc.bulkAdd && isBulkAddMode
                ? field.readOnly
                : (data) => {
                    var _a;
                    const syncedFields = (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.company_products;
                    if (syncedFields === null || syncedFields === void 0 ? void 0 : syncedFields.includes(field.id)) {
                        return isSyncedField(data, syncedFields, field.id, data.config);
                    }
                    return !!field._readOnly;
                };
        field.endAdornment = (data, field, setNewData) => {
            var _a;
            return (_jsx(SyncEndAdornment, { syncedFields: (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.company_products, syncId: data === null || data === void 0 ? void 0 : data.sync_id, fieldId: field === null || field === void 0 ? void 0 : field.id, fieldType: field.type, data: data, onChange: (newOverrideFields) => {
                    setNewData(Object.assign(Object.assign({}, data), { config: Object.assign(Object.assign({}, (data.config || {})), { overrideFields: newOverrideFields }) }));
                } }));
        };
    });
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    return (_jsx(DataView, { dataDesc: dataDesc, viewOnly: viewOnly, readOnly: viewOnly, enablePagination: true }));
};
export default View;
