import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, FormControl, InputLabel, MenuItem, TextField, Typography, } from '@mui/material';
import { useEffect, useState } from 'react';
import Formatter from 'common/Formatter';
import StatusIcon from '@/components/atoms/StatusIcon';
import AutoCompleteCompanies from '@/components/molecules/AutoCompleteCompanies';
import { DocumentTypeE, ProcessMethodE } from './process';
import API from '@/services/API';
import { ProcessMethodList } from './config';
import { DocumentProcessActionTypes } from '@/types';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
const ProcessForm = ({ mpData, shareData, processForm, rowData, handleProcessFormChange, addActionCount, }) => {
    var _a, _b, _c, _d, _e;
    const [processorList, setProcessorList] = useState([]);
    const [processorItemValue, setProcessorItemValue] = useState({
        value: '',
        label: '',
        company_name: '',
        updated_at: '',
    });
    const [search, setSearch] = useState('');
    const { data: prompts } = API.getBasicQuery('prompts');
    useEffect(() => {
        if (mpData.processors) {
            const list = mpData.processors.map((processor) => {
                var _a;
                return ({
                    value: processor.str_id,
                    label: processor.name,
                    company_name: (_a = processor.companies) === null || _a === void 0 ? void 0 : _a.company_name,
                    updated_at: processor.updated_at,
                });
            });
            setProcessorList(list);
        }
    }, [mpData.processors]);
    useEffect(() => {
        if (rowData) {
            if (prompts) {
                const rowDataCompany = rowData.company_str_id;
                const promptCompany = prompts.find((item) => item.company_str_id === rowDataCompany);
                if (promptCompany) {
                    handleProcessFormChange('prompt', promptCompany.company_str_id);
                    handleProcessFormChange('promptText', promptCompany.prompt);
                }
            }
        }
    }, [rowData, prompts]);
    useEffect(() => {
        if (processForm.processor) {
            const processor = processorList === null || processorList === void 0 ? void 0 : processorList.find((processor) => processor.value === processForm.processor);
            if (processor) {
                setProcessorItemValue(processor);
            }
        }
    }, [processForm.processor, processorList]);
    const processorOptions = processorList
        .map((option) => ({
        id: option.value,
        label: option.label,
        companyName: option.company_name,
        updatedAt: option.updated_at,
    }))
        .filter((item) => {
        var _a;
        if (search) {
            const searchLower = search.toLowerCase();
            return (_a = item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchLower);
        }
        return true;
    });
    const enhancedSelectorStyle = {
        minWidth: 125,
        width: 'auto',
        '& .MuiBox-root': {
            maxWidth: 'calc(100% - 24px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    };
    return (_jsxs(Box, { sx: {
            gap: 2,
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingTop: 1,
        }, children: [shareData.sheets.length > 1 && (_jsxs(FormControl, { fullWidth: true, children: [_jsxs(InputLabel, { children: [" ", shareData.isExcel ? 'Sheet' : 'Table'] }), _jsx(EnhancedSelect, { enableSearch: true, label: shareData.isExcel ? 'Sheet' : 'Table', sx: enhancedSelectorStyle, options: shareData.sheets.map((sheet) => ({
                            id: sheet,
                            label: sheet,
                        })), value: {
                            id: processForm.selectedSheet || '',
                            label: processForm.selectedSheet || '',
                        }, onChange: (value) => {
                            addActionCount(DocumentProcessActionTypes.SELECT_SPREADSHEET);
                            handleProcessFormChange('selectedSheet', value.id);
                        } })] })), _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { label: "Method", sx: enhancedSelectorStyle, options: ProcessMethodList.map((method) => ({
                        id: method.value,
                        label: method.label,
                    })), value: {
                        id: processForm.method || '',
                        label: ((_a = ProcessMethodList.find((m) => m.value === processForm.method)) === null || _a === void 0 ? void 0 : _a.label) || '',
                    }, onChange: (value) => {
                        addActionCount(DocumentProcessActionTypes.SELECT_METHOD);
                        handleProcessFormChange('method', value.id);
                    } }) }), processForm.method === ProcessMethodE.Processor ? (_jsx(_Fragment, { children: _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { enableSearch: true, label: "Processor", sx: enhancedSelectorStyle, listContainerSx: {
                            minWidth: 850,
                            p: 1,
                        }, value: {
                            id: (processorItemValue === null || processorItemValue === void 0 ? void 0 : processorItemValue.value) || '',
                            label: (processorItemValue === null || processorItemValue === void 0 ? void 0 : processorItemValue.label) || '',
                            companyName: processorItemValue === null || processorItemValue === void 0 ? void 0 : processorItemValue.company_name,
                            updatedAt: processorItemValue === null || processorItemValue === void 0 ? void 0 : processorItemValue.updated_at,
                        }, onChange: (value) => {
                            addActionCount(DocumentProcessActionTypes.SELECT_PROCESSOR);
                            handleProcessFormChange('processor', value.id);
                        }, onSearch: setSearch, searchKeyword: search, options: processorOptions, renderLabel: ({ key }) => {
                            const option = processorOptions.find((o) => o.id === key);
                            if (!option)
                                return null;
                            return (_jsx(MenuItem, { value: option.id, children: _jsxs(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", sx: {
                                        width: '100%',
                                        minWidth: 780,
                                        p: 1,
                                    }, children: [_jsx(Typography, { children: option.label }), _jsx(Typography, { variant: "caption", sx: { color: '#555' }, children: `(${option.companyName || 'Unknown'} - ${option.updatedAt
                                                ? new Date(option.updatedAt).toLocaleString()
                                                : 'No date'})` })] }) }, option.id));
                        } }) }) })) : processForm.method === ProcessMethodE.Gemini ? (_jsx(_Fragment, { children: _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { label: "Prompt", sx: enhancedSelectorStyle, options: (prompts || []).map((c) => ({
                            id: c.str_id,
                            label: c.name,
                        })), value: {
                            id: processForm.prompt || '',
                            label: ((_b = (prompts || []).find((c) => c.str_id === processForm.prompt)) === null || _b === void 0 ? void 0 : _b.name) || '',
                        }, onChange: (value) => {
                            addActionCount(DocumentProcessActionTypes.SELECT_GEMINI_PROMPT);
                            handleProcessFormChange('prompt', value.id);
                        } }) }) })) : null, _jsx(FormControl, { fullWidth: true, children: _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { label: "Mapping", sx: enhancedSelectorStyle, listContainerSx: {
                            minWidth: 600,
                            p: 1,
                        }, value: {
                            id: processForm.mapping || 0,
                            label: ((_d = (_c = mpData.mappings) === null || _c === void 0 ? void 0 : _c.find((m) => m.str_id === processForm.mapping)) === null || _d === void 0 ? void 0 : _d.name) || 'New',
                        }, options: [
                            { id: 0, label: 'New' },
                            ...(((_e = mpData.mappings) === null || _e === void 0 ? void 0 : _e.map((mapping) => ({
                                id: mapping.str_id,
                                label: mapping,
                            }))) || []),
                        ], onChange: (value) => {
                            if (value.id === 0) {
                                handleProcessFormChange('mapping', '');
                            }
                            else {
                                handleProcessFormChange('mapping', value.id);
                            }
                            addActionCount(DocumentProcessActionTypes.SELECT_MAPPING);
                        }, renderLabel: ({ key }) => {
                            var _a, _b;
                            const item = mpData.mappings
                                ? mpData.mappings.find((item) => item.str_id === key)
                                : null;
                            if (!item)
                                return (_jsx(MenuItem, { children: _jsx(Box, { sx: { display: 'flex', width: '100%' }, children: _jsx(Box, { sx: { flex: 1 }, children: "New" }) }) }));
                            return (_jsx(MenuItem, { value: item.str_id, children: _jsxs(Box, { sx: { display: 'flex', width: '100%' }, children: [_jsx(Box, { sx: { flex: 1 }, children: item.name }), _jsx(Typography, { variant: "caption", component: "span", sx: { color: '#555', ml: 1 }, children: `(${((_a = rowData.companies) === null || _a === void 0 ? void 0 : _a.company_name) &&
                                                `${(_b = rowData.companies) === null || _b === void 0 ? void 0 : _b.company_name} - `}${Formatter.date(item.created_at, true, 'MM/DD/YYYY hh:mmA')})` })] }) }, item.str_id));
                        } }) }) }), !processForm.mapping &&
                shareData.fileType === DocumentTypeE.Statement && (_jsx(FormControl, { fullWidth: true, children: _jsx(AutoCompleteCompanies, { sx: {
                        '& .MuiInputBase-root': {
                            height: 35,
                        },
                    }, value: processForm.newMappingCarrier, onChange: (event, newEvent) => {
                        addActionCount(DocumentProcessActionTypes.SELECT_COMPANY);
                        handleProcessFormChange('newMappingCarrier', newEvent);
                    } }) })), !processForm.mapping && (_jsx(FormControl, { fullWidth: true, children: _jsx(TextField, { label: "Mapping name", variant: "outlined", value: processForm.newMappingName, onBlur: () => {
                        addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                    }, onChange: (e) => {
                        handleProcessFormChange('newMappingName', e.target.value);
                    }, sx: {
                        '& .MuiInputBase-root': {
                            height: 35,
                        },
                    } }) })), shareData.errors.mapping ? (_jsx(StatusIcon, { icon: "info", color: "#F4B400", message: shareData.errors.mapping })) : (_jsx(StatusIcon, { icon: "complete", color: "#0F9D58", message: "" }))] }));
};
export default ProcessForm;
