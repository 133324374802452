import { jsx as _jsx } from "react/jsx-runtime";
import { CheckCircle, Info, Error, RadioButtonUnchecked, TaskAlt, } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';
const icons = {
    info: _jsx(Info, {}),
    error: _jsx(Error, {}),
    complete: _jsx(CheckCircle, {}),
    complete2: _jsx(TaskAlt, {}),
    incomplete: _jsx(RadioButtonUnchecked, {}),
};
const Icon = ({ color, icon }) => React.cloneElement(icons[icon], { style: { color } });
const StatusIcon = ({ icon, color, message }) => (_jsx(Tooltip, { title: message, placement: "left", children: _jsx("div", { style: { height: '24px' }, children: _jsx(Icon, { icon: icon, color: color }) }) }));
export default StatusIcon;
