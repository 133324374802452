import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Box, Checkbox, FormControlLabel, IconButton, Popover, } from '@mui/material';
import { Tune } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
const SearchSettings = ({ settings }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (_jsxs("div", { children: [_jsx(IconButton, { color: "primary", onClick: handleClick, children: _jsx(Tune, {}) }), _jsx(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                }, children: settings.map((setting) => {
                    if (typeof setting === 'object') {
                        return (_jsx(Box, { children: setting.type === 'toggle' && (_jsx(FormControlLabel, { label: setting.label, control: _jsx(Checkbox, { checked: searchParams.get(setting.id) === 'true', onChange: () => {
                                        setSearchParams((prev) => {
                                            if (searchParams.get(setting.id)) {
                                                prev.delete(setting.id);
                                            }
                                            else {
                                                prev.set(setting.id, 'true');
                                            }
                                            return prev;
                                        });
                                    } }), sx: { ml: 0 } })) }, setting.id));
                    }
                    return null;
                }) })] }));
};
export default SearchSettings;
