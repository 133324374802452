var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Add, Visibility } from '@mui/icons-material';
import { Box, Button, IconButton, Modal, TextField, Typography, } from '@mui/material';
import { useState } from 'react';
import DataView from '@/components/DataView';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 2,
    borderRadius: 4,
};
const ApiKeyManager = () => {
    const [open, setOpen] = useState(false);
    const [newKeyName, setNewKeyName] = useState('');
    const [visibleKeyMap, setVisibleKeyMap] = useState({});
    const [refresh, setRefresh] = useState(0);
    const apiKeyPoster = API.getMutation('api_key', 'POST');
    const { showSnackbar } = useSnackbar();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setNewKeyName('');
    };
    const handleCreateKey = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield apiKeyPoster.mutateAsync({ name: newKeyName });
            if (!response.error) {
                handleClose();
                showSnackbar('API key created');
            }
            else {
                console.error(response.error);
                showSnackbar(`An error occurred: ${response.error}`, 'error');
            }
        }
        catch (error) {
            console.error('An unexpected error occurred:', error);
            showSnackbar('An unexpected error occurred. Please try again later.', 'error');
        }
        finally {
            setRefresh(refresh + 1);
        }
    });
    const apiKeyFormatter = (val) => {
        return (_jsx(Box, { sx: {
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }, children: _jsxs(Box, { sx: {
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    fontFamily: 'monospace',
                    fontSize: '14px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    pl: 1,
                }, children: [_jsx("span", { style: {
                            filter: visibleKeyMap[val] ? 'none' : 'blur(5px)',
                            transition: 'filter 0.2s ease',
                        }, children: val }), _jsx(IconButton, { onClick: () => setVisibleKeyMap((prev) => (Object.assign(Object.assign({}, prev), { [val]: !prev[val] }))), sx: { ml: 1 }, size: "small", children: _jsx(Visibility, {}) })] }) }));
    };
    const dataDesc = {
        label: 'API keys',
        table: 'api_key',
        editable: true,
        fields: [
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'api_key',
                label: 'Key',
                readOnly: true,
                formatter: apiKeyFormatter,
                copyable: true,
                type: 'custom',
                render: (field, row, setter, dynamicSelects) => {
                    var _a;
                    return (_jsx(TextField, { value: (_a = row[field.id]) !== null && _a !== void 0 ? _a : '', fullWidth: true, size: "small", inputProps: {
                            readOnly: true,
                        } }));
                },
            },
            {
                id: 'status',
                label: 'Status',
                formatter: (val) => Formatter.statusChip(val, {
                    mapping: { Active: 'green' },
                }),
                type: 'select',
                options: [
                    { label: 'Active', id: 'active' },
                    { label: 'Disabled', id: 'disabled' },
                ],
            },
            // Disable permissions until we get better spec set up
            // Should probably be JSON, so we can configure
            // different permissions for different endpoints
            // {
            //   id: 'permissions',
            //   label: 'Permission',
            //   formatter: (key) => {
            //     if (key === 'all') {
            //       return (
            //         <>
            //           <Chip label="Read" />
            //           <Chip label="Write" />
            //         </>
            //       );
            //     } else if (key === 'read') {
            //       return <Chip label="Read" />;
            //     } else if (key === 'write') {
            //       return <Chip label="Write" />;
            //     }
            //   },
            //   type: 'select',
            //   options: [
            //     { label: 'All', id: 'all' },
            //     { label: 'Read', id: 'read' },
            //     { label: 'Write', id: 'write' },
            //   ],
            // },
            // Disable IP allow list until we implement it
            // {
            //   id: 'ip_allow_list',
            //   label: 'IP allow list',
            //   type: 'custom',
            //   render: (field, row, setter, dynamicSelects) => {
            //     const isValidIP = isIP;
            //     return (
            //       <Box sx={{ width: '100%' }}>
            //         <Typography
            //           variant="subtitle2"
            //           sx={{
            //             color: '#666',
            //             fontSize: '14px',
            //             fontWeight: 400,
            //           }}
            //         >
            //           {field.label}
            //         </Typography>
            //         <Box
            //           sx={{
            //             width: '100%',
            //             display: 'flex',
            //             alignItems: 'center',
            //           }}
            //         >
            //           <Box
            //             sx={{
            //               display: 'flex',
            //               flexDirection: 'column',
            //               borderStyle: 'solid',
            //               borderColor: 'silver',
            //               borderWidth: 1,
            //               borderRadius: 4,
            //               width: '100%',
            //               backgroundColor: '#2196f308',
            //               p: 2,
            //             }}
            //           >
            //             <Box
            //               sx={{
            //                 display: 'flex',
            //                 flexWrap: 'wrap',
            //                 gap: 2,
            //                 alignItems: 'center',
            //               }}
            //             >
            //               {(row?.ip_allow_list ?? []).map((ip, index) => (
            //                 <Box
            //                   key={index}
            //                   sx={{
            //                     display: 'flex',
            //                     alignItems: 'center',
            //                     width: 300,
            //                     position: 'relative',
            //                   }}
            //                 >
            //                   <TextField
            //                     label="IP address"
            //                     variant="outlined"
            //                     value={ip}
            //                     size="small"
            //                     error={ip !== '' && !isValidIP(ip)}
            //                     onChange={(e) => {
            //                       const newIPList = [...(row?.ip_allow_list ?? [])];
            //                       newIPList[index] = e.target.value;
            //                       setter({
            //                         ...row,
            //                         ip_allow_list: newIPList,
            //                       });
            //                     }}
            //                     sx={{
            //                       flex: 1,
            //                       '& .MuiFormHelperText-root': {
            //                         display: 'none',
            //                       },
            //                     }}
            //                   />
            //                   {ip !== '' && !isValidIP(ip) && (
            //                     <Typography
            //                       sx={{
            //                         position: 'absolute',
            //                         bottom: -15,
            //                         left: 14,
            //                         fontSize: '11px',
            //                         color: '#d32f2f',
            //                       }}
            //                     >
            //                       Invalid IP address
            //                     </Typography>
            //                   )}
            //                   <IconButton
            //                     size="small"
            //                     onClick={() => {
            //                       const newIPList = [...(row?.ip_allow_list ?? [])];
            //                       newIPList.splice(index, 1);
            //                       setter({
            //                         ...row,
            //                         ip_allow_list: newIPList,
            //                       });
            //                     }}
            //                     sx={{ ml: 1 }}
            //                   >
            //                     <CloseIcon fontSize="small" />
            //                   </IconButton>
            //                 </Box>
            //               ))}
            //               <Button
            //                 variant="text"
            //                 size="small"
            //                 onClick={() => {
            //                   setter({
            //                     ...row,
            //                     ip_allow_list: [...(row?.ip_allow_list ?? []), ''],
            //                   });
            //                 }}
            //                 sx={{
            //                   height: 30,
            //                   minWidth: 'auto',
            //                   alignSelf: 'center',
            //                   color: '#2196f3',
            //                   padding: '0 8px',
            //                   '&:hover': {
            //                     backgroundColor: 'transparent',
            //                   },
            //                 }}
            //               >
            //                 Add
            //               </Button>
            //             </Box>
            //           </Box>
            //         </Box>
            //       </Box>
            //     );
            //   },
            // },
            {
                id: 'created_at',
                label: 'Created at',
                formatter: (row) => new Date(row).toLocaleString(),
                readOnly: true,
            },
            {
                id: 'notes',
                label: 'Notes',
            },
        ],
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataView, { dataDesc: dataDesc, viewOnly: true, sx: { width: '100%' }, extraActions: _jsx(Button, { variant: "contained", startIcon: _jsx(Add, {}), onClick: handleOpen, children: "Create API key" }), refresh: refresh, hideExport: true }), _jsx(Modal, { open: open, onClose: handleClose, children: _jsxs(Box, { sx: style, children: [_jsx(Typography, { variant: "h6", component: "h2", children: "Create API key" }), _jsx(TextField, { autoFocus: true, margin: "dense", label: "Name", fullWidth: true, value: newKeyName, onChange: (e) => {
                                setNewKeyName(e.target.value);
                            } }), _jsxs(Box, { sx: { display: 'flex', justifyContent: 'flex-end', mt: 2 }, children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { onClick: handleCreateKey, variant: "contained", sx: { ml: 1 }, disabled: !newKeyName, children: "Create" })] })] }) })] }));
};
export default ApiKeyManager;
