var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from '@mui/material';
import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import DataBulkAdd from '@/components/DataBulkAdd';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
const policyAgentSplitFields = [
    { id: 'key_column', label: 'Key column', required: true },
    { id: 'key_data', label: 'Key data', required: true },
    { id: 'agent_id', label: 'Agent ID', required: true },
    { id: 'split', label: 'Split', required: true },
];
const defaultParams = '{ "updatePolicyContacts": true }';
const BulkEditPolicyAgentSplit = () => {
    const [params, setParams] = useState(defaultParams);
    const posterBulk = API.getMutation(`report_data/bulk_update`, 'PATCH');
    const { showSnackbar } = useSnackbar();
    return (_jsx(DataBulkAdd, { fields: policyAgentSplitFields, params: params, handleUpdateParams: (e) => {
            setParams(e.target.value);
        }, onCancel: () => { }, onSave: (payload) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const res = yield posterBulk.mutateAsync(payload);
            if (res.statusText === 'ok') {
                showSnackbar(`Updated ${(_a = res === null || res === void 0 ? void 0 : res.stats) === null || _a === void 0 ? void 0 : _a.current_length} records`, 'success');
            }
            else {
                showSnackbar(_jsxs(Alert, { severity: "error", children: ["Error updating records", _jsx("br", {}), res.error.split('\n').map((line, i) => (_jsxs("span", { children: [line, _jsx("br", {})] }, i)))] }));
            }
        }), btnLabel: "Update" }));
};
export default BulkEditPolicyAgentSplit;
