import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import { AgGridReact } from 'ag-grid-react';
const ResultTable = ({ headers, rows, resultTableSearch }) => {
    const columnDefs = useMemo(() => (headers || []).map((header) => ({ field: header })), [headers]);
    const rowDataDef = useMemo(() => (rows || []).map((row) => {
        const obj = {};
        headers.forEach((header, i) => {
            obj[header] = row[i];
        });
        return obj;
    }), [headers, rows]);
    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 100,
    }), []);
    const onGridReady = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);
    return (_jsx(Box, { sx: { width: '100%', height: '100%' }, className: "ag-theme-material", children: _jsx(AgGridReact, { headerHeight: 40, rowHeight: 35, rowData: rowDataDef, columnDefs: columnDefs, defaultColDef: defaultColDef, onGridReady: onGridReady, quickFilterText: resultTableSearch, enableCellTextSelection: true }) }));
};
export default ResultTable;
