var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Reconciliations_mode, _Reconciliations_strings;
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Chip, Skeleton, Tooltip, Typography } from '@mui/material';
import UILabels from '@/services/UILabels';
import Formatter from '@/services/Formatter';
import DataTransformation from '@/services/DataTransformation';
const Normalizer = DataTransformation;
class Reconciliations {
    constructor(mode, role, options = {}) {
        _Reconciliations_mode.set(this, 'default');
        _Reconciliations_strings.set(this, new UILabels(__classPrivateFieldGet(this, _Reconciliations_mode, "f")));
        this.label = 'Reconciliation';
        this.table = 'reconciliation_data';
        this.defaultOrderBy = 'reconciled';
        this.defaultSort = 'asc';
        this.outstandingFieldsInMobileView = [];
        this.options = {};
        __classPrivateFieldSet(this, _Reconciliations_mode, mode, "f");
        __classPrivateFieldSet(this, _Reconciliations_strings, new UILabels(mode), "f");
        this.options = options;
        this.filters = {
            agent_name: {
                label: 'Agent name',
                useEnhancedSelect: true,
            },
            carrier_name: {
                label: 'Paying entity',
                useEnhancedSelect: true,
            },
            policy_status: {
                label: 'Status',
                useEnhancedSelect: true,
            },
            product_name: {
                label: 'Product name',
                useEnhancedSelect: true,
            },
            product_type: {
                label: 'Product type',
                useEnhancedSelect: true,
            },
            report_data_id: {
                label: 'Policy document',
                useEnhancedSelect: true,
            },
            writing_carrier_name: {
                label: 'Carrier/MGA',
                useEnhancedSelect: true,
                listContainerSx: {
                    width: 500,
                },
            },
            statements: {
                label: 'Statement documents',
                useEnhancedSelect: true,
            },
            compensation_type: {
                label: 'Compensation type',
                useEnhancedSelect: true,
            },
        };
        this.outstandingFieldsInMobileView = [
            'policy_id',
            'customer_name',
            'amount_paid',
            'effective_date',
        ];
        this.fields = {
            policy_id: {
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'salesId'),
                enabled: true,
                copyable: true,
                formatter: (val, rowData = {}) => {
                    // @ts-ignore
                    if (rowData.report_data_parent) {
                        return (_jsx(Tooltip
                        // @ts-ignore
                        , { title: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle2", children: "Master policy" }), _jsx(Chip, { variant: "outlined", label: rowData.report_data_parent.policy_id, clickable: true, component: "a", 
                                        // @ts-ignore
                                        href: `/policies?id=${rowData.report_data_parent.str_id}`, target: "_blank", sx: { mb: 0.5 }, style: { backgroundColor: 'white' } }, val.str_id)] }), children: _jsx("span", { children: `${val}*` }) }, `${rowData.str_id}-MasterPolicyTooltip`));
                        // @ts-ignore
                    }
                    else if (rowData.children_report_data_ids) {
                        return (_jsx(Tooltip
                        // @ts-ignore
                        , { title: _jsxs(_Fragment, { children: [_jsx(Typography, { variant: "subtitle2", children: "Linked policies" }), rowData.children_report_data_ids.map((childId) => (_jsx(Chip, { variant: "outlined", style: { backgroundColor: 'white' }, label: childId.policy_id, clickable: true, component: "a", 
                                        // @ts-ignore
                                        href: `/policies?id=${childId.str_id}`, target: "_blank", sx: { m: 0.2 } }, `${childId.str_id}-childPolicy`)))] }), children: _jsx("span", { children: `${val} 🗂️` }) }, `${rowData.str_id}-linkedPoliciesTooltip`));
                    }
                    else
                        return val;
                },
                readOnly: true,
            },
            compensation_type: {
                label: 'Compensation type',
                enabled: true,
                readOnly: true,
            },
            report_str_id: {
                label: 'Linked policy',
                enabled: true,
                // Type: FieldTypes.DYNAMIC_SELECT,
                formatter: (val, row) => {
                    return val ? (_jsx(Chip, { label: val, clickable: true, component: "a", href: `/policies?id=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : null;
                },
            },
            writing_carrier_name: {
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'entity'),
                enabled: __classPrivateFieldGet(this, _Reconciliations_mode, "f") === 'insurance',
                global: true,
                readOnly: true,
            },
            carrier_name: {
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'payingEntity'),
                enabled: true,
                global: true,
                readOnly: true,
            },
            customer_name: {
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'customerName'),
                enabled: true,
                readOnly: true,
            },
            product_type: {
                label: 'Product type',
                enabled: true,
                readOnly: true,
            },
            product_sub_type: {
                label: 'Product sub type',
                enabled: true,
                readOnly: true,
            },
            product_name: {
                label: 'Product name',
                enabled: true,
                readOnly: true,
            },
            effective_date: {
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'startDate'),
                enabled: true,
                formatter: Normalizer.formatDate,
                nomalizer: Normalizer.normalizeDate,
                type: 'date',
                readOnly: true,
            },
            premium_amount: {
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'annualizedRevenue'),
                enabled: __classPrivateFieldGet(this, _Reconciliations_mode, "f") === 'insurance',
                formatter: Normalizer.formatCurrency,
                nomalizer: Normalizer.normalizeCurrency,
                type: 'currency',
                readOnly: true,
            },
            commissionable_premium_amount: {
                label: 'Target premium',
                matches: ['target premium', 'commissionable premium'],
                enabled: true,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: 'currency',
                readOnly: true,
            },
            commissions_expected: {
                label: 'Commissions due',
                description: 'Commission amount due. Caclulated from commission schedules when available.',
                matches: [
                    'agency commission total',
                    'commissions expected',
                    'commission expected',
                ],
                enabled: __classPrivateFieldGet(this, _Reconciliations_mode, "f") === 'insurance',
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
                type: 'currency',
                infoIcon: true,
                readOnly: true,
            },
            cancellation_date: {
                label: 'Cancellation date',
                enabled: __classPrivateFieldGet(this, _Reconciliations_mode, "f") === 'insurance',
                formatter: Normalizer.formatDate,
                nomalizer: Normalizer.normalizeDate,
                type: 'date',
                defaultTableHidden: true,
                readOnly: true,
            },
            reinstatement_date: {
                label: 'Reinstatement date',
                enabled: __classPrivateFieldGet(this, _Reconciliations_mode, "f") === 'insurance',
                formatter: Normalizer.formatDate,
                nomalizer: Normalizer.normalizeDate,
                type: 'date',
                defaultTableHidden: true,
                readOnly: true,
            },
            agent_name: {
                label: 'Agent name',
                enabled: __classPrivateFieldGet(this, _Reconciliations_mode, "f") === 'insurance',
                defaultTableHidden: true,
                readOnly: true,
            },
            contacts: {
                label: 'Agents',
                enabled: __classPrivateFieldGet(this, _Reconciliations_mode, "f") === 'insurance',
                type: 'dynamic-select',
                multiple: true,
                table: 'contacts',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.str_id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? (_jsx(Chip, { label: Formatter.contact(datum, {
                                account_id: this.options.account_id,
                            }), clickable: true, component: "a", href: `/agents/list?id=${val}`, target: "_blank", sx: { m: 0.1 } }, val)) : (`${val} (not found in Fintary)`);
                    }
                    return val; // Not formatting when collectionVals is not available
                },
                optionFormatter: (option) => Formatter.contact(option, { account_id: this.options.account_id }),
                optionValuer: (option) => option.str_id,
                readOnly: true,
            },
            amount_paid: {
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'cashPaid'),
                id2: 'amountPaid',
                description: 'Commission amount paid as recorded from commission statements',
                infoIcon: true,
                enabled: true,
                formatter: Normalizer.formatCurrency,
                type: 'currency',
                getter: (row) => {
                    var _a, _b, _c, _d, _e;
                    let childAmounts = 0;
                    if (((_a = row === null || row === void 0 ? void 0 : row.children_reconciliation_data) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        childAmounts = row.children_reconciliation_data.reduce((acc, cur) => {
                            var _a, _b, _c, _d;
                            const curAmount = Number.isNaN(+((_b = (_a = cur.amount_paid) === null || _a === void 0 ? void 0 : _a.amount_paid) === null || _b === void 0 ? void 0 : _b.amount_paid))
                                ? 0
                                : +((_d = (_c = cur.amount_paid) === null || _c === void 0 ? void 0 : _c.amount_paid) === null || _d === void 0 ? void 0 : _d.amount_paid);
                            return acc + curAmount;
                        }, 0);
                    }
                    return Number.isNaN(+((_c = (_b = row.amount_paid) === null || _b === void 0 ? void 0 : _b.amount_paid) === null || _c === void 0 ? void 0 : _c.amount_paid))
                        ? 0
                        : ((_e = (_d = row.amount_paid) === null || _d === void 0 ? void 0 : _d.amount_paid) === null || _e === void 0 ? void 0 : _e.amount_paid) + childAmounts;
                },
                // Getter: (row) => {
                //   return (
                //     row.amount_paid?.amount_paid?.amount_paid ??
                //     row.amount_paid?.amountPaid?.amountPaid
                //   );
                // },
                readOnly: true,
            },
            amount_paid_commissionable_premium_amount_pct: {
                label: '% Target premium',
                enabled: true,
                type: 'percentage',
                formatter: Formatter.percentage,
                readOnly: true,
            },
            amount_paid_premium_amount_pct: {
                label: '% Premium',
                enabled: true,
                type: 'percentage',
                formatter: Formatter.percentage,
                readOnly: true,
            },
            balance: {
                // Type: 'computed',
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'balance'),
                enabled: true,
                formatter: Normalizer.formatCurrency,
                type: 'currency',
                getter: (row) => {
                    var _a;
                    let bal;
                    if (((_a = row === null || row === void 0 ? void 0 : row.children_reconciliation_data) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        row.children_reconciliation_data.forEach((child) => {
                            if (child.balance)
                                bal = +child.balance + (bal !== null && bal !== void 0 ? bal : 0);
                        });
                        if (bal)
                            bal = bal + +row.balance;
                    }
                    return bal !== null && bal !== void 0 ? bal : row.balance;
                },
                compute: (v) => {
                    var _a, _b;
                    return ((_a = v.commissions_expected) !== null && _a !== void 0 ? _a : 0) - ((_b = v.amount_paid) !== null && _b !== void 0 ? _b : 0);
                },
                readOnly: true,
            },
            policy_status: {
                type: 'field',
                fieldId: 'policy_status',
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'status'),
                infoIcon: false,
                enabled: true,
                // Visible: false,
                readOnly: true,
            },
            issue_age: {
                label: 'Issue age',
                enabled: true,
                normalizer: Normalizer.normalizeInt,
                matches: ['issue age'],
                type: 'integer',
                readOnly: true,
            },
            group_id: {
                label: 'Group id',
                enabled: true,
                readOnly: true,
            },
            internal_id: {
                label: 'Internal id',
                enabled: true,
                readOnly: true,
            },
            notes: {
                type: 'field',
                fieldId: 'notes',
                label: 'Notes',
                visible: true,
                enabled: true,
            },
            reconciled: {
                label: __classPrivateFieldGet(this, _Reconciliations_strings, "f").getLabel('reconciliation', 'reconciliationStatus'),
                readOnly: true,
                enabled: true,
            },
            commission_amount_monthly: {
                // Type: 'aggregate',
                visible: false,
                // FieldId: 'commission_amount',
                // id2: 'amount_paid_monthly',
                enabled: true,
                type: 'currency',
                readOnly: true,
            },
            customer_paid_premium_amount: {
                label: 'Customer paid premium',
                enabled: true,
                matches: ['Basis', 'customer paid premium'],
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
            },
            agent_commission_amount: {
                label: 'Agent commission',
                enabled: true,
                formatter: Normalizer.formatCurrency,
                normalizer: Normalizer.normalizeCurrency,
            },
            agent_commission_amount_pct: {
                label: '% Agent commission',
                enabled: true,
                formatter: Formatter.percentage,
            },
            report_data: {
                label: 'Agent split',
                table: 'contacts',
                tableFormatter: (val, row = {}, collectionVals) => {
                    var _a, _b, _c;
                    if (((_a = row.report_data) === null || _a === void 0 ? void 0 : _a.contacts_split) && !collectionVals)
                        return _jsx(Skeleton, { variant: "rectangular" });
                    return ((_b = row.report_data) === null || _b === void 0 ? void 0 : _b.contacts_split)
                        ? Object.entries((_c = row.report_data) === null || _c === void 0 ? void 0 : _c.contacts_split).map(([k, v]) => {
                            var _a, _b;
                            return (_jsx(Chip, { label: `${(_b = Formatter.contact((_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((e) => e.str_id === k)) !== null && _a !== void 0 ? _a : {}, { account_id: this.options.account_id })) !== null && _b !== void 0 ? _b : `${k} (Not found)`}: ${v}%` }, k));
                        })
                        : '';
                },
                enabled: true,
            },
            log: {
                type: 'field',
                fieldId: 'log',
                label: 'Log 🔒',
                visible: true,
                enabled: role === 'admin',
                readOnly: true,
            },
            statements: {
                type: 'field',
                label: 'Statement documents',
                enabled: true,
                visible: true,
                readOnly: true,
                formatter: (val) => {
                    if (val && val.document) {
                        return (_jsx(Chip, { label: `${val.document.filename}`, clickable: true, component: "a", href: `/documents?id=${val.document.str_id}`, target: "_blank", sx: { m: 0.1 } }, val.document.str_id));
                    }
                    else {
                        return 'Document not available';
                    }
                },
            },
            report_data_id: {
                visible: true,
                label: 'Policy document',
                type: 'field',
                enabled: true,
                readOnly: true,
                formatter: (val, collectionVals = []) => {
                    var _a, _b, _c, _d;
                    if (val === '')
                        return '';
                    if ((_a = collectionVals.report_data) === null || _a === void 0 ? void 0 : _a.document) {
                        return (_jsx(Chip, { label: `${(_b = collectionVals.report_data) === null || _b === void 0 ? void 0 : _b.document.filename}`, clickable: true, component: "a", href: `/documents?id=${(_c = collectionVals.report_data) === null || _c === void 0 ? void 0 : _c.document.str_id}`, target: "_blank", sx: { m: 0.1 } }, (_d = collectionVals.report_data) === null || _d === void 0 ? void 0 : _d.document.str_id));
                    }
                    else {
                        return '';
                    }
                },
            },
            statement_str_ids: {
                type: 'field',
                fieldId: 'statement_str_ids',
                label: 'Statements 🔒',
                enabled: role === 'admin',
                itemOnClick: (item) => {
                    window.open(`/commissions?id=${item}`, '_blank');
                },
                formatter: (val) => val && typeof val === 'string' ? `${val === null || val === void 0 ? void 0 : val.substring(0, 5)}...` : null,
                readOnly: true,
            },
        };
        this.queryChips = {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            unreconciled: {
                id: 'unreconciled',
                label: 'Unreconciled',
                query: {
                    reconciled: [
                        '⛔️ Commissions shortfall',
                        '⚠️ Missing policy data',
                        '⚠️ Missing commissions data',
                    ],
                },
            },
            reconciled: {
                id: 'reconciled',
                label: '✅ Reconciled',
                query: {
                    reconciled: [
                        '✅ Excess commissions',
                        '✅ Reconciled',
                        '✅ Reconciled (negligible balance)',
                        '✅ Commission received',
                    ],
                },
                more: true,
            },
            missing_policy_data: {
                id: 'missing_policy_data',
                label: '⚠️ Missing policy data',
                query: {
                    reconciled: '⚠️ Missing policy data',
                },
                more: true,
            },
            missing_commissions_data: {
                id: 'missing_commissions_data',
                label: '⚠️ Missing commissions data',
                query: {
                    reconciled: '⚠️ Missing commissions data',
                },
                more: true,
            },
            commissions_shortfall: {
                id: 'commissions_shortfall',
                label: '⛔️ Commissions shortfall',
                query: {
                    reconciled: '⛔️ Commissions shortfall',
                },
                more: true,
            },
        };
        this.fieldsCollapsed = Object.values(this.fields)
            .filter((field) => field.enabled)
            .map((field) => field.matches)
            .flat();
    }
}
_Reconciliations_mode = new WeakMap(), _Reconciliations_strings = new WeakMap();
export default Reconciliations;
