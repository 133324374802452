var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
const UserTools = () => {
    const [userUid, setUserUid] = useState('');
    const [passUserUid, setPassUserUid] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [verifyLoading, setVerifyLoading] = useState(false);
    const [passLoading, setPassLoading] = useState(false);
    const verifyUserPoster = API.getMutation('admin/users/verify-user-account', 'POST');
    const resetPasswordPoster = API.getMutation('admin/users/reset-user-password', 'POST');
    const { showSnackbar } = useSnackbar();
    return (_jsxs(Box, { children: [_jsxs(Box, { children: [_jsx(Typography, { sx: { m: 0.5 }, children: "Verify user email" }), _jsx(TextField, { label: "User uid", value: userUid, onChange: ({ target: { value } }) => setUserUid(value), sx: { width: 400, mr: 1 } }), _jsx(LoadingButton, { loading: verifyLoading, variant: "outlined", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setVerifyLoading(true);
                            const response = yield verifyUserPoster.mutateAsync({
                                userUid: userUid,
                            });
                            if (response.error) {
                                showSnackbar(response.error, 'error');
                            }
                            else {
                                showSnackbar('User email verified', 'info');
                            }
                            setVerifyLoading(false);
                        }), sx: { mr: 1 }, children: "Verify email" })] }), _jsxs(Box, { sx: { mt: 1 }, children: [_jsx(Typography, { sx: { m: 0.5 }, children: "Reset user password" }), _jsx(TextField, { label: "User uid", value: passUserUid, onChange: ({ target: { value } }) => setPassUserUid(value), sx: { width: 400, mr: 1 } }), _jsx(TextField, { label: "New password", value: newUserPassword, onChange: ({ target: { value } }) => setNewUserPassword(value), sx: { width: 400, mr: 1 }, type: "password" }), _jsx(LoadingButton, { loading: passLoading, variant: "outlined", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setPassLoading(true);
                            const response = yield resetPasswordPoster.mutateAsync({
                                userUid: passUserUid,
                                newPassword: newUserPassword,
                            });
                            if (response.data) {
                                showSnackbar('User password reset', 'success');
                            }
                            else {
                                showSnackbar(response.error, 'error');
                            }
                            setPassLoading(false);
                        }), sx: { mr: 1 }, children: "Reset password" })] })] }));
};
export default UserTools;
