import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState, createContext } from 'react';
export const UIStateContext = createContext({});
const UIStateProvider = ({ children }) => {
    const [appDrawerExpanded, setAppDrawerExpanded] = useState({});
    const [commissions, setCommissions] = useState({});
    const [commissionDetails, setCommissionDetails] = useState({});
    const [role, setRole] = useState('default');
    const store = useMemo(() => ({
        commissions: [commissions, setCommissions],
        commissionDetails: [commissionDetails, setCommissionDetails],
        role: [role, setRole],
        appDrawerExpanded: [appDrawerExpanded, setAppDrawerExpanded],
    }), [commissions, commissionDetails, role, appDrawerExpanded]);
    return (_jsx(UIStateContext.Provider, { value: store, children: children }));
};
export default UIStateProvider;
