var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CssBaseline, Snackbar, ThemeProvider } from '@mui/material';
import { StyledEngineProvider, createTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { RouterProvider } from 'react-router-dom';
import { StatsigProvider } from 'statsig-react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import DialogHost from '@/components/DialogHost';
import ErrorBoundary from '@/components/ErrorBoundary';
import LoadingMask from '@/components/atoms/LoadingMask';
import LoadingProvider from '@/contexts/LoadingContext';
import UiStateProvider from '@/contexts/UIStateProvider';
import API from '@/services/API';
import appearance from '@/services/appearance';
import { useAuth } from '@/services/useAuth';
import { useAccountStore, useFeVersionStore } from '@/store';
import { GlobalStateCodes, Roles } from '@/types';
import Bar from '@/views/Bar';
import Router from '@/views/Router';
import GlobalSnackbar from '@/components/atoms/Snackbar';
import GlobalSnackbarProvider from '@/contexts/SnackbarContext';
import { useUpdateSelectedAccount } from './hooks/useUpdateSelectedAccount';
import { useUpdateUserRole } from './hooks/useUpdateUserRole';
import useSnackbar from '@/contexts/useSnackbar';
import useLoginStore from '@/store/loginStore';
import { useUserInfo } from '@/hooks/useUserInfo';
import { LOCAL_STORAGE_KEYS } from '@/constants/account';
const baseTheme = appearance.defaultTheme;
const theme = createTheme(Object.assign({}, baseTheme));
const initialState = {
    ready: false,
    performingAction: false,
    userData: null,
    roles: [],
    defaultLandingPage: '/',
    signUpDialog: {
        open: false,
    },
    signInDialog: {
        open: false,
    },
    emailVerificationDialog: {
        open: false,
    },
    snackbar: {
        autoHideDuration: 0,
        message: '',
        open: false,
    },
};
const App = () => {
    var _a, _b;
    const [user, setUser] = useState(null);
    const [authError, setAuthError] = useState(null);
    const [loginEmail, setLoginEmail] = useState('');
    const setLoginParam = useLoginStore((s) => s.setLoginParam);
    // Custom hook for handle authentication
    const { signOut } = useAuth(setUser, setLoginEmail, setAuthError);
    const statsigKey = process.env.REACT_APP_STATSIG_API_KEY || '';
    const environment = process.env.REACT_APP_ENVIRONMENT || '';
    const [state, setState] = useState(initialState);
    const [showUpdateMessage, setShowUpdateMessage] = useState(false);
    const [hasSignedOut, setHasSignedOut] = useState(false);
    const tawkMessengerRef = useRef(null);
    const handleTawkHide = () => {
        var _a;
        if ((_a = tawkMessengerRef === null || tawkMessengerRef === void 0 ? void 0 : tawkMessengerRef.current) === null || _a === void 0 ? void 0 : _a.hideWidget)
            tawkMessengerRef.current.hideWidget();
    };
    const handleTawkMaximize = () => {
        var _a;
        if ((_a = tawkMessengerRef === null || tawkMessengerRef === void 0 ? void 0 : tawkMessengerRef.current) === null || _a === void 0 ? void 0 : _a.maximize)
            tawkMessengerRef.current.maximize();
    };
    const { data: loginResponse } = useUserInfo();
    const { feVersion, setFeVersion } = useFeVersionStore();
    const { showSnackbar } = useSnackbar();
    const { selectedAccount, setSelectedAccount, setUserState, resetAccountStore, } = useAccountStore();
    const authHandlerPoster = API.getMutation('auth_handler', 'POST');
    useEffect(() => {
        setTimeout(() => {
            setShowUpdateMessage(false);
        }, 5000);
    }, [showUpdateMessage]);
    useEffect(() => {
        if (feVersion &&
            feVersion.stateCode === GlobalStateCodes.FE_OUT_OF_DATE &&
            !showUpdateMessage) {
            if (feVersion.message !== null) {
                window.confirm(feVersion.message);
            }
            setFeVersion({
                stateCode: null,
                message: null,
            });
            setShowUpdateMessage(true);
        }
        if (feVersion &&
            feVersion.stateCode === GlobalStateCodes.FE_INCOMPATIBLE &&
            !showUpdateMessage) {
            if (feVersion.message !== null) {
                window.confirm(feVersion.message);
                window.location.reload();
            }
            setFeVersion({
                stateCode: null,
                message: null,
            });
            setShowUpdateMessage(true);
        }
    }, [feVersion]);
    // Reload all appication tabs after a logout
    useEffect(() => {
        window.addEventListener('storage', (event) => {
            if (['loggedOut', 'triggerReload'].includes(event.key || '')) {
                window.location.reload();
            }
        });
    }, []);
    useUpdateSelectedAccount();
    useUpdateUserRole();
    useEffect(() => {
        const isLoggedIn = user !== null;
        if (authError) {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { ready: true, user: null, roles: [] })));
            showSnackbar(authError, 'error');
            return;
        }
        const userCheck = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const impUser = JSON.parse((_a = localStorage.getItem('customLoginUser')) !== null && _a !== void 0 ? _a : '{}');
            try {
                if (isLoggedIn && Object.keys(impUser).length > 0) {
                    setLoginParam({
                        email: impUser.email,
                        isImpUser: true,
                        role_name: impUser.role_name,
                        sso: impUser.sso,
                    });
                }
                else if (isLoggedIn && user) {
                    setLoginParam({
                        email: user.email,
                        role_name: user.role_name,
                        sso: user.sso,
                    });
                }
            }
            catch (error) {
                console.error('An error occurred:', error);
                Sentry.captureException(error);
            }
        });
        if (isLoggedIn && user) {
            if (process.env.NODE_ENV === 'production') {
                hotjar.initialize({ id: 3472012, sv: 6 });
                setTimeout(() => {
                    if (hotjar.initialized()) {
                        hotjar.identify(user.uid, {
                            userUid: user.uid,
                        });
                    }
                }, 2000);
            }
        }
        else {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { ready: true, performingAction: false })));
        }
        userCheck();
    }, [user, authError, showSnackbar, setLoginParam]);
    const openDialog = (dialogId) => {
        const dialog = state[dialogId];
        if (!dialog || dialog.open === undefined || null) {
            return;
        }
        dialog.open = true;
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { dialog })));
    };
    const closeDialog = (dialogId) => {
        const dialog = state[dialogId];
        if (!dialog || dialog.open === undefined || null) {
            return;
        }
        dialog.open = false;
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { dialog })));
    };
    const closeDialogCallback = (dialogId, callback) => {
        closeDialog(dialogId);
        if (callback && typeof callback === 'function') {
            callback();
        }
    };
    // Sign In or Sign Up dialog based on query params
    const queryParams = new URLSearchParams(window.location.search);
    const loginType = queryParams.get('login-type');
    const userLoginEmail = queryParams.get('email');
    const issuer = queryParams.get('issuer');
    const token = queryParams.get('token');
    const role_name = queryParams.get('role_name');
    const ssoToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ssoToken);
    useEffect(() => {
        const verifyToken = (issuer, token) => __awaiter(void 0, void 0, void 0, function* () {
            const response = yield authHandlerPoster.mutateAsync({
                issuer,
                token,
            });
            if (response.valid) {
                setUser(Object.assign(Object.assign({}, response.user), { sso: true, role_assigned: role_name }));
                localStorage.setItem('sso-token', token ? `sso:${token}` : '');
            }
            else {
                showSnackbar('Unable to log in, redirecting to login page', 'error');
                setTimeout(() => {
                    if (issuer === 'transglobal')
                        window.location.href = `https://hubs.transglobalus.com/_hcms/mem/login?redirect_url=${window.location.href}`;
                }, 2000);
            }
        });
        if (issuer && token) {
            verifyToken(issuer, token);
        }
        else if (ssoToken) {
            const token = ssoToken.split(':')[1];
            verifyToken('transglobal', token);
        }
    }, [issuer, role_name, token, ssoToken]);
    useEffect(() => {
        setState((prev) => (Object.assign(Object.assign({}, prev), { user })));
    }, [user]);
    useEffect(() => {
        const setUserAndAccount = (userLogin) => __awaiter(void 0, void 0, void 0, function* () {
            setUserState({
                userOverallState: userLogin.userOverallState,
                userEmail: userLogin.userEmail,
                userOnboardingNeeded: userLogin.userOnboardingNeeded,
                accountOnboardingNeeded: userLogin.accountOnboardingNeeded,
                userAccounts: userLogin.userAccounts,
            });
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { defaultLandingPage: getDefaulLandingPage(userLogin.userLandingPage), ready: true })));
        });
        const getDefaulLandingPage = (userLandingPage) => {
            if (!userLandingPage) {
                return '/reconciliation';
            }
            if (Array.isArray(userLandingPage)) {
                return userLandingPage[0].default_page;
            }
            if (typeof userLandingPage === 'string') {
                return userLandingPage;
            }
            return '/reconciliation';
        };
        const checkResult = () => {
            var _a;
            if (!loginResponse || !user)
                return;
            const isUserEmailVerified = 'emailVerified' in user ? user.emailVerified : true;
            if (((_a = loginResponse.userAccounts[0]) === null || _a === void 0 ? void 0 : _a.role_id) !== Roles.ACCOUNT_ADMIN &&
                !isUserEmailVerified) {
                openDialog('emailVerificationDialog');
                return;
            }
            setUserAndAccount(loginResponse);
        };
        checkResult();
    }, [loginResponse, setUserState, showSnackbar, user]);
    useEffect(() => {
        const signOutWhenInvitingUser = () => {
            if (!user)
                setHasSignedOut(true);
            if (user && !hasSignedOut) {
                signOut(() => {
                    localStorage.clear();
                    setSelectedAccount(null);
                    setState((prevState) => (Object.assign(Object.assign({}, prevState), { ready: true, performingAction: false, userData: null, roles: [] })));
                    showSnackbar('You have been signed out', 'info');
                    setHasSignedOut(true);
                });
            }
        };
        const handleDialogOpen = (dialogType) => {
            setLoginEmail(userLoginEmail || '');
            setTimeout(() => {
                signOutWhenInvitingUser();
                openDialog(dialogType);
            }, 1000);
        };
        if (loginType === 'signUp') {
            handleDialogOpen('signUpDialog');
        }
        if (loginType === 'signIn') {
            handleDialogOpen('signInDialog');
        }
    }, [user, hasSignedOut, loginType, userLoginEmail]);
    const closeSnackbar = (clearMessage = false) => {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { snackbar: {
                autoHideDuration: 10,
                message: clearMessage ? '' : prevState.snackbar.message,
                open: false,
            } })));
    };
    useEffect(() => {
        if (user && user.uid) {
            Sentry.setUser({ id: user.uid, email: user.email });
        }
    });
    const { ready, performingAction, roles, signUpDialog, signInDialog, emailVerificationDialog, snackbar, defaultLandingPage, } = state;
    const statsigUser = {
        userID: (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) || '',
        email: (user === null || user === void 0 ? void 0 : user.email) || '',
    };
    const userInfo = {
        name: (_a = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName) !== null && _a !== void 0 ? _a : '',
        email: (_b = user === null || user === void 0 ? void 0 : user.email) !== null && _b !== void 0 ? _b : '',
    };
    return (_jsx(ErrorBoundary, { user: userInfo, children: _jsx(LoadingProvider, { children: _jsx(GlobalSnackbarProvider, { children: _jsx(StatsigProvider, { sdkKey: statsigKey, options: {
                        environment: { tier: environment },
                    }, waitForInitialization: true, user: statsigUser, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsxs(ThemeProvider, { theme: theme, children: [_jsxs(UiStateProvider, { children: [_jsx(CssBaseline, {}), ready && defaultLandingPage && (_jsxs(_Fragment, { children: [_jsx(RouterProvider, { router: Router({
                                                        user,
                                                        roles,
                                                        bar: (_jsx(Bar, { performingAction: performingAction, user: user, onSignUpClick: () => openDialog('signUpDialog'), onSignInClick: () => openDialog('signInDialog'), onSignOutClick: () => {
                                                                setState((prevState) => (Object.assign(Object.assign({}, prevState), { performingAction: true })));
                                                                signOut(() => {
                                                                    localStorage.clear();
                                                                    resetAccountStore();
                                                                    setState((prevState) => (Object.assign(Object.assign({}, prevState), { ready: true, performingAction: false, userData: null, roles: [] })));
                                                                    showSnackbar('Signed out', 'info');
                                                                    localStorage.setItem('triggerReload', Date.now().toString());
                                                                });
                                                            }, onHelpClick: handleTawkMaximize })),
                                                        defaultLandingPage: defaultLandingPage,
                                                    }) }), _jsx(DialogHost, { theme: theme, user: user, dialogs: {
                                                        signUpDialog: {
                                                            dialogProps: {
                                                                open: signUpDialog.open,
                                                                email: loginEmail,
                                                                onClose: (callback) => {
                                                                    closeDialogCallback('signUpDialog', callback);
                                                                },
                                                            },
                                                        },
                                                        signInDialog: {
                                                            dialogProps: {
                                                                open: signInDialog.open,
                                                                email: loginEmail,
                                                                onClose: (callback) => {
                                                                    closeDialogCallback('signInDialog', callback);
                                                                },
                                                            },
                                                        },
                                                        emailVerificationDialog: {
                                                            dialogProps: {
                                                                open: emailVerificationDialog.open,
                                                                email: loginEmail,
                                                                onClose: (callback) => {
                                                                    closeDialogCallback('emailVerificationDialog', callback);
                                                                },
                                                            },
                                                        },
                                                    } }), _jsx(Snackbar, { autoHideDuration: snackbar.autoHideDuration, message: typeof snackbar.message === 'string'
                                                        ? snackbar.message
                                                        : '', open: snackbar.open, 
                                                    // @ts-ignore
                                                    onClose: closeSnackbar, anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }, children: typeof snackbar.message !== 'string'
                                                        ? snackbar.message
                                                        : null }), _jsx(TawkMessengerReact, { propertyId: "6626e6281ec1082f04e5ad21", widgetId: "1hs3v63th", ref: tawkMessengerRef, onLoad: handleTawkHide, customStyle: {
                                                        zIndex: 1200,
                                                        visibility: {
                                                            desktop: {
                                                                yOffset: 48,
                                                                position: 'br',
                                                            },
                                                            mobile: {
                                                                position: 'br',
                                                            },
                                                        },
                                                    } })] }))] }), _jsx(LoadingMask, {}), _jsx(GlobalSnackbar, {})] }) }) }) }) }) }));
};
export default App;
