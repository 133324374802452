var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useEffect, useState, useMemo } from 'react';
import { Autocomplete, TextField, Checkbox, ListItemText, Box, Button, } from '@mui/material';
const FilterSelect = (_a) => {
    var { options: _options, getOptionLabel, onChange, label, multiple, renderOptionItem, renderCustomOption, enablePagination = false, itemsPerPage = 10, labelKey = 'label', valueKey = 'value', placeholder = 'Select options', loading = false, value } = _a, props = __rest(_a, ["options", "getOptionLabel", "onChange", "label", "multiple", "renderOptionItem", "renderCustomOption", "enablePagination", "itemsPerPage", "labelKey", "valueKey", "placeholder", "loading", "value"]);
    const [displayedOptions, setDisplayedOptions] = useState([]);
    const [page, setPage] = useState(1);
    const options = useMemo(() => {
        var _a;
        return (_a = _options === null || _options === void 0 ? void 0 : _options.map((option) => {
            const value = option[valueKey];
            const label = option[labelKey];
            return {
                value,
                label,
                data: option,
            };
        })) !== null && _a !== void 0 ? _a : [];
    }, [_options, labelKey, valueKey]);
    useEffect(() => {
        setDisplayedOptions(enablePagination ? options.slice(0, itemsPerPage) : options);
    }, [options, enablePagination, itemsPerPage, valueKey, labelKey]);
    const handleShowMore = () => {
        const nextPage = page + 1;
        const startIndex = nextPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDisplayedOptions((prevOptions) => [
            ...prevOptions,
            ...options.slice(startIndex, endIndex),
        ]);
        setPage(nextPage);
    };
    const filterOptions = (options, state) => {
        const filtered = options.filter((option) => getOptionLabel
            ? getOptionLabel(option.data, options)
                .toLowerCase()
                .includes(state.inputValue.toLowerCase())
            : option.label.toLowerCase().includes(state.inputValue.toLowerCase()));
        return enablePagination ? filtered.slice(0, page * itemsPerPage) : filtered;
    };
    return (_jsx(Autocomplete, Object.assign({ multiple: multiple, filterSelectedOptions: multiple, options: options, filterOptions: filterOptions, onChange: (event, value) => onChange(value), getOptionLabel: (option) => typeof getOptionLabel === 'function'
            ? getOptionLabel(option, options)
            : '', loading: loading, renderOption: (props, option, { selected }) => {
            return (_createElement("li", Object.assign({}, props, { key: option.value }),
                multiple && _jsx(Checkbox, { checked: selected }),
                _jsx(ListItemText, { primary: renderOptionItem
                        ? renderOptionItem(option.data)
                        : getOptionLabel
                            ? getOptionLabel(option.data, options)
                            : option.label }, option.value)));
        }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: label, placeholder: placeholder }))), ListboxComponent: (listboxProps) => (_jsxs(Box, Object.assign({}, listboxProps, { children: [renderCustomOption && renderCustomOption(), listboxProps.children, enablePagination && displayedOptions.length < options.length && (_jsx(Box, { display: "flex", justifyContent: "center", p: 2, children: _jsx(Button, { onClick: handleShowMore, children: "Show More" }) }))] }))), value: value !== null && value !== void 0 ? value : (multiple ? [] : '') }, props)));
};
export default FilterSelect;
