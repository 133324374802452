import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { BulkAddUplineDTO } from 'common/validators/contacts-bulk-add-upline';
import { ApiError, ApiResponse } from 'common/types/common';

import { API_PATHS } from './path';

export const useBulkUpdateUpline = () => {
  return useMutation<ApiResponse, ApiError, BulkAddUplineDTO[]>({
    mutationKey: [API_PATHS.bulkUpdateUpline],
    mutationFn: async (data) => {
      return axios
        .post(API_PATHS.bulkUpdateUpline, data)
        .then((res) => res.data);
    },
  });
};
