import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress, Backdrop, Typography } from '@mui/material';
import { LoadingContext } from 'contexts/LoadingContext';
import { Close } from '@mui/icons-material';
const LoadingMask = () => {
    const { loadingConfig, setLoadingConfig } = useContext(LoadingContext);
    const [open, setOpen] = useState(false);
    const timerRef = useRef(null);
    // Add delay to close
    useEffect(() => {
        if (loadingConfig.loading) {
            setOpen(true);
        }
        else {
            // @ts-ignore
            timerRef.current = setTimeout(() => {
                setOpen(false);
            }, loadingConfig.delayToClose || 0);
        }
        return () => {
            // @ts-ignore
            clearTimeout(timerRef.current);
        };
    }, [loadingConfig.loading]);
    return (_jsxs(Backdrop, { sx: { color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }, open: open, children: [loadingConfig.allowClose && (_jsx(Close, { color: "inherit", onClick: () => {
                    setLoadingConfig({ loading: false });
                }, sx: {
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    cursor: 'pointer',
                } })), _jsx(CircularProgress, { color: "inherit" }), _jsx(Typography, { variant: "subtitle2", component: "span", sx: { paddingLeft: 2 }, children: loadingConfig.message || 'Loading...' })] }));
};
export default LoadingMask;
